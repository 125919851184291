import environment from 'environment';

const config = require('../../environments/' + environment.settings);
const axios = require('axios');
const CircularJSON = require('circular-json');

async function getApplicationAvailability(domain, port) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "portal/utils/ping/" + domain + "/" + port)
        .catch(function (error) { return error.response });

    return results;
}

async function getPortalBanner(exDisable, thumb) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "portal/banner/list?excludeDisable=" + exDisable + "&thumbnail=" + thumb)
        .catch(function (error) { return error.response; });
    return results;
}

async function getPortalSingleFeature(id) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "portal/feature/" + id)
        .catch(function (error) { return error.response; });
    return results;
}

async function getPortalFeature(exDisable, thumb) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "portal/feature/list?excludeDisable=" + exDisable + "&thumbnail=" + thumb + "&pageIndex=1&pageSize=10")
        .catch(function (error) { return error.response; });
    return results;
}

async function getPortalSingleNews(id) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "portal/news/" + id)
        .catch(function (error) { return error.response; });
    return results;
}

async function getPortalNews(exDisable, thumb) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "portal/news/list?excludeDisable=" + exDisable + "&thumbnail=" + thumb + "&pageIndex=1&pageSize=10")
        .catch(function (error) { return error.response; });
    return results;
}

async function getPortalLogin(thumb) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "portal/setting/login?thumbnail=" + thumb);
    return results;
}

async function getPortalTheme(exDisable) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "portal/setting/themes/list?excludeDisable=" + exDisable)
        .catch(function (error) { return error.response; });
    return results;
}

async function getPortalThemeSetting(width, height) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "portal/setting/themes/list?image=true&width=" + width + "&height=" + height);
    return results;
}

async function getPortalThemeById(themeId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "portal/setting/themes/" + themeId + "/style");
    return results;
}

async function getPortalEmail() {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "portal/setting/email/list");
    return results;
}

async function addPortalBanner(bannerSetting) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "portal/banner", bannerSetting)
        .catch(function (error) { return error.response; });

    return results;
}

async function addPortalFeature(featureSetting) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "portal/feature", featureSetting)
        .catch(function (error) { return error.response; });

    return results;
}

async function addPortalNews(newsSetting) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "portal/news", newsSetting)
        .catch(function (error) { return error.response; });

    return results;
}

async function addPortalTheme(themeSetting) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "portal/setting/themes", themeSetting)
        .catch(function (error) { return error.response; });

    return results;
}

async function addPortalThemeFile(themeId, cssFile) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "portal/setting/themes/" + themeId + "/upload", cssFile)
        .catch(function (error) { return error.response; });

    return results;
}

async function updatePortalBanner(bannerid, bannerSetting) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "portal/banner/" + bannerid, bannerSetting)
        .catch(function (error) { return error.response; });

    return results;
}

async function updatePortalFeature(featureid, featureSetting) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "portal/feature/" + featureid, featureSetting)
        .catch(function (error) { return error.response; });

    return results;
}

async function updatePortalNews(newsid, newsSetting) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "portal/news/" + newsid, newsSetting)
        .catch(function (error) { return error.response; });

    return results;
}

async function updatePortalLogin(loginSetting) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "portal/setting/login", loginSetting).then((response) => {
        let json = CircularJSON.stringify(response);
        return JSON.parse(json);
    }).catch((error) => {
        return error.response;
    });

    return results;
}

async function updatePortalEmail(emailSetting) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "portal/setting/email", emailSetting).then((response) => {
        let json = CircularJSON.stringify(response);
        return JSON.parse(json);
    }).catch((error) => {
        return error.response;
    });

    return results;
}

async function updatePortalTheme(themeid, themeSetting) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "portal/setting/themes/" + themeid, themeSetting)
        .catch(function (error) { return error.response; });

    return results;
}

async function removePortalBanner(bannerid) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.delete(commandBaseUrl + "portal/banner/" + bannerid)
        .catch(function (error) { return error.response; });

    return results;
}

async function removePortalFeature(featureid) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.delete(commandBaseUrl + "portal/feature/" + featureid)
        .catch(function (error) { return error.response; });

    return results;
}

async function removePortalNews(newsid) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.delete(commandBaseUrl + "portal/news/" + newsid)
        .catch(function (error) { return error.response; });

    return results;
}

async function removePortalTheme(themeid) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.delete(commandBaseUrl + "portal/setting/themes/" + themeid)
        .catch(function (error) { return error.response; });

    return results;
}

export {
    getApplicationAvailability,
    getPortalBanner,
    getPortalSingleFeature,
    getPortalFeature,
    getPortalSingleNews,
    getPortalNews,
    getPortalLogin,
    getPortalTheme,
    getPortalThemeSetting,
    getPortalThemeById,
    getPortalEmail,
    addPortalBanner,
    addPortalFeature,
    addPortalNews,
    addPortalTheme,
    addPortalThemeFile,
    updatePortalFeature,
    updatePortalNews,
    updatePortalLogin,
    updatePortalEmail,
    updatePortalTheme,
    updatePortalBanner,
    removePortalBanner,
    removePortalFeature,
    removePortalNews,
    removePortalTheme
}