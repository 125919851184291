import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import GridHeader from './GridHeader';
import GridBody from './GridBody';

class GridContainer extends Component {
    static propTypes = {
        headers: PropTypes.array.isRequired,
        items: PropTypes.array.isRequired,
        pagination: PropTypes.func.isRequired
    };

    render() {
        const { headers, items } = this.props;

        return (
            <div className="row">
                <div className="tableWrap userList mb-3">
                    <div className="table-responsive">
                        <table className="table table-striped" cellSpacing="0" width="100%">
                            <GridHeader headers={headers} />
                            <GridBody items={items} pagination={this.props.pagination} />
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(GridContainer);