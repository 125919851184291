import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getWorkspacesSortingWithThunk } from '../../../api/workspacesThunk';
import { getWorkspacesSortingAction } from '../../../actions/getWorkspacesSortingAction';

class GridHeaderItem extends Component {
    static propTypes = {
        header: PropTypes.object.isRequired,
        pagination: PropTypes.object.isRequired,
        sort: PropTypes.object.isRequired,
        getWorkspaces: PropTypes.func.isRequired,
        dispatchWorkspacesSorting: PropTypes.func.isRequired,
    };

    dispatchSorting(sortBy, sortType) {
        let data = {
            sort_by: sortBy,
            sort_type: sortType
        }
        this.props.dispatchWorkspacesSorting(data);
    }

    onSortingChange = (page) => {
        const { header, pagination, sort, search } = this.props;
       
        let currentSortBy = (sort.sort_by === header.sort_column) ? sort.sort_by : header.sort_column;
        let currentSortType = (sort.sort_type === 'Ascending') ? "Descending" : "Ascending";
        
        if (currentSortBy !== sort.sort_by)
            currentSortType = "Ascending";

        this.dispatchSorting(currentSortBy, currentSortType);
        this.props.getWorkspaces(search.search_text, pagination.current_page, 20, currentSortBy, currentSortType);
    }

    render() {
        const { header, sort } = this.props;
        return (
            header.header_name === "Logo" ?
                (<th id={"grid-header-" + header.id} className="grid-no-sort">
                    {header.header_name}
                </th>) :
                (<th id={"grid-header-" + header.id} className="shortings grid-sort" onClick={this.onSortingChange}>
                    {header.header_name}                    
                    {header.sort_column === sort.sort_by ? (<span className={(sort.sort_type === 'Ascending') ? 'icon-wrd-sorting-up head-sort-span' : 'icon-wrd-sorting-down head-sort-span'}></span>) : null}                    
                </th>)
        );
    }
}

const mapStateToProps = ({ sorting, pagination, search }) => ({
    pagination: pagination.workspace,
    sort: sorting.workspace,
    search: search.workspaces,
});

const mapDispatchToProps = (dispatch) => ({
    getWorkspaces: (keyword, pageIndex, pageSize, sortingBy, sortingSize) => dispatch(getWorkspacesSortingWithThunk(keyword, pageIndex, pageSize, sortingBy, sortingSize)),
    dispatchWorkspacesSorting: (data) => dispatch(getWorkspacesSortingAction(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GridHeaderItem));