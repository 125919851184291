import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDialogCategoriesCreationErrorAction } from '../../../actions/updateDialogCategoriesCreationErrorAction';

class CategoriesCreationErrorModal extends Component {
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
    }

    static propTypes = {
        type: PropTypes.string.isRequired,
        dialog: PropTypes.object.isRequired,
        dispatchDialogCategoriesCreationError: PropTypes.func.isRequired
    };

    setDefaultDialogState() {
        let category_creation_error = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            show_dialog: false
        };

        this.props.dispatchDialogCategoriesCreationError(category_creation_error);
    }

    closeModal() {
        const { dialog } = this.props;
        this.setDefaultDialogState();

        if (dialog.status === 200 && dialog.dialog_title.toLowerCase().includes('success')) {
            this.props.history.push(dialog.name);
        }
    }

    componentDidUpdate(nextProps) {
        if (nextProps.dialog.show_dialog) {
            this.textInput.current.focus();
        }
    }

    render() {
        const { dialog } = this.props;

        return (
            <div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div className={"popupPanel popupPanel-small deleteWarning" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                    <div className="popupHeader">
                        <div className="p-4">
                            <h2 className="popupTitle">{dialog.dialog_title}</h2>
                            <button className="btn iconBtn" onClick={() => { this.closeModal(); }}>
                                <span className="icon-wrd-close"></span>
                            </button>
                        </div>
                    </div>
                    <div className="popupBody">
                        <div className="p-4">
                            <div className="summernote">
                                {(dialog.status === 200) ?
                                    <p>
                                        <b className="actionEnable">Success:</b> {dialog.dialog_type}
                                    </p>
                                    :
                                    <p>
                                        <b className="actionRemove">ERROR:</b> {dialog.dialog_type}
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="px-4 pt-4 pb-2">
                            <div className="row">
                                <div className="col text-right">
                                    {(dialog.status === 200) ?
                                        <button ref={this.textInput} className="greenBtn btn icoTextBtn mr-3 closePopup" onClick={() => { this.closeModal(); }}>
                                            Close
                                        </button>
                                        :
                                        <button ref={this.textInput} className="greyBtn btn icoTextBtn mr-3 closePopup" onClick={() => { this.closeModal(); }}>
                                            Close
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ dialog }) => ({
    dialog: dialog.category_creation_error
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogCategoriesCreationError: (dialog) => dispatch(updateDialogCategoriesCreationErrorAction(dialog)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoriesCreationErrorModal));
