import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { routeCodes } from '../../../../configurations/routes';

class WorkspacesApplicationsSettingsHeader extends Component {
	static propTypes = {
		workspace: PropTypes.object.isRequired,
		workspaceApplication: PropTypes.object.isRequired,
		role: PropTypes.object.isRequired,
	};

	render() {
		const { workspace, workspaceApplication, user } = this.props;

		if ((workspace.id === undefined || workspace.id === '') &&
			(workspaceApplication.application_id === undefined || workspaceApplication.application_id === ''))
			return (null);

		return (
			<div className="mainHead">
				<div className="row">
					<div className="col-12">
						<h2 className="float-left mt-2 mr-4">{workspaceApplication.application_name}</h2>						
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="workspaceInfo">
							<div className="row">
								<div className="workspaceName col-xl-7 offset-xl-3">
									<h3>{user.full_name}</h3>
								</div>
								<div className="userRole col-xl-7 offset-xl-3">{user.id}</div>
							</div>
							<nav className="col-lg-5 col-xl-4">
								<div className="row text-center">
									<div className="col px-0">
                                        <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/applications/' + workspaceApplication.application_name.toLowerCase().replaceAll(" ", "+") + '/settings'} title="Workspace Application Settings" className="workspaceMenu py-3 active">Settings</Link>
									</div>
								</div>
							</nav>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ detail, role }) => ({
	workspace: detail.workspace,
	workspaceApplication: detail.workspace_application,
	role,
	user: detail.user
});

const mapDispatchToProps = (dispatch) => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsSettingsHeader));