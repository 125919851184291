import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import WorkspacesCreateData from './WorkspacesCreateData';
import WorkspacesCreateForm from './WorkspacesCreateForm';

class WorkspacesCreateBody extends Component {
    static propTypes = {
        workspace: PropTypes.object.isRequired
    };

    render() {
        return (
            <div className="mainBody">
                <WorkspacesCreateData />
                <WorkspacesCreateForm />
            </div>
        );
    }
}

const mapStateToProps = ({ setup }) => ({
    workspace: setup.workspace
});

export default withRouter(connect(mapStateToProps)(WorkspacesCreateBody));
