import sort from 'fast-sort';
import { GET_DEFAULT_APPLICATIONS_SETTINGS, CLEAR_DEFAULT_APPLICATIONS_SETTINGS } from '../actions/index';

const getDefaultapplicationsSettings = (defaultapplicationsSettings = [], action) => {
	let itemsKeys = [];
	let settingsKeys = action.settings.reduce((keys, setting) => {
		itemsKeys.push(setting.id);
		keys = settingsKeys;
		return keys;
	}, []);

	let result = defaultapplicationsSettings.filter((setting) => {
		return settingsKeys.indexOf(setting.id) === -1;
	});

	const results = result.concat(action.settings);
	return sort(results).asc("group_name");
}

const clearDefaultapplicationsSettings = (action) => {
	return action.settings;
}

export const defaultApplicationsSettings = (defaultapplicationsSettings = [], action) => {
	switch (action.type) {
		case GET_DEFAULT_APPLICATIONS_SETTINGS: return getDefaultapplicationsSettings(defaultapplicationsSettings, action);
		case CLEAR_DEFAULT_APPLICATIONS_SETTINGS: return clearDefaultapplicationsSettings(action);
		default: return defaultapplicationsSettings;
	}
};