import {
    createCategoryApi,
    updateCategoryApi,
    deleteCategoryApi,
    getCategoryByIdApi,
    getCategoryListApi,
} from '../backend/domain/categories';

import {
    getApplicationListApi
} from '../backend/domain/applications';

import { loadingCreateCategoryAction } from '../actions/loadingCreateCategoryAction';
import { loadingCategoryAction } from '../actions/loadingCategoryAction';
import { loadingApplicationsAction } from '../actions/loadingApplicationsAction';
import { getCategoryAction } from '../actions/getCategoryAction';
import { getCategoriesAction } from '../actions/getCategoriesAction';
import { clearApplicationsAction } from '../actions/clearApplicationsAction';
import { clearCategoryAction } from '../actions/clearCategoryAction';
import { clearCategoriesAction } from '../actions/clearCategoriesAction';
import { routeCodes } from '../configurations/routes';
import { handleCategoryCreationError, handleCategoryUpdateError } from '../utils/errorMessages';
import { getCategoryIdByName, mergeApplicationsCategories } from '../utils/categories';

export const createCategoryWithThunk = (category) =>
    async (dispatch) => {
        dispatch(loadingCreateCategoryAction(true));
        createCategoryApi(category)
            .then(async (response) => {
                handleCategoryCreationError(dispatch, response.status, response.error_messages, routeCodes.APPLICATIONS_LIST);
            })
            .then(() => {
                dispatch(loadingCreateCategoryAction(false));
            });
    };

export const updateCategoryWithThunk = (category) =>
    async (dispatch) => {
        dispatch(loadingCategoryAction(true));
        updateCategoryApi(category)
            .then((response) => {
                dispatch(clearCategoriesAction([]));
                return response;
            })
            .then(async (response) => {
                handleCategoryUpdateError(dispatch, response.status, response.error_messages, routeCodes.APPLICATIONS_LIST);
            })
            
            .then(() => {
                dispatch(loadingCategoryAction(false));
            });
    };

export const deleteCategoryWithThunk = (categoryId, pageIndex, pageSize, sortingBy, sortingType, routeBackTo, historyProps) =>
    async (dispatch) => {
        if (routeBackTo !== "") {
            dispatch(loadingCategoryAction(true));
            deleteCategoryApi(categoryId)
                .then(() => {
                    dispatch(clearCategoriesAction([]));
                })
                .then(() => {
                    historyProps.push(routeBackTo);
                    dispatch(loadingCategoryAction(false));
                });
        }
        else {
            dispatch(loadingApplicationsAction(true));
            deleteCategoryApi(categoryId)
                .then(() => {
                    getApplicationListApi(pageIndex, pageSize, sortingBy, sortingType)
                        .then((response) => {
                            dispatch(clearCategoriesAction([]));
                            return response;
                        })
                        .then(async (response) => {
                            let apps = response.applications;

                            apps.sort(function (a, b) {
                                return a.application_name.toLowerCase().localeCompare(b.application_name.toLowerCase());
                            });

                            dispatch(clearApplicationsAction(apps));
                            let categories = await getCategoryListApi();
                            let mergeCategories = mergeApplicationsCategories(categories, response.applications);
                            dispatch(getCategoriesAction(mergeCategories));
                        })
                })
                .then(() => {
                    dispatch(loadingApplicationsAction(false));
                });
        }
    };

export const getCategoryByIdWithThunk = (categoryId) =>
    async (dispatch) => {
        let categId = await getCategoryIdByName(categoryId);

        dispatch(clearCategoryAction([]));
        dispatch(loadingCategoryAction(true));
        getCategoryByIdApi(categId)
            .then(response => {
                dispatch(getCategoryAction(response));
            })
            .then((response) => {
                dispatch(loadingCategoryAction(false));
                return response;
            });
    };
