import {   
    GET_PORTAL_LOGIN,
    CLEAR_PORTAL_LOGIN
} from '../actions/index';

const initialLoginState = {   
    portal_login: {
        welcome_text_selected: '',
        welcome_text: '',
        welcome_background_selected: '',
        welcome_background_color: '',
        welcome_background_image: '',
        welcome_background_image_aspect_ratio: '',
        login_background_selected: '',
        login_background_color: '',
        login_background_image: '',
        login_background_image_aspect_ratio: ''
    }
}

export const portalLogin = (state = initialLoginState, action) => {
    const { portal_login } = action
    switch (action.type) {       
        case GET_PORTAL_LOGIN:
            return {
                ...state,
                portal_login
            }        
        case CLEAR_PORTAL_LOGIN:
            return {
                ...state,
                portal_login: initialLoginState.portal_login
            }        
        default:
            return state;
    }
};