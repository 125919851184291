import { GET_USER_AVAILABLE_WORKSPACES, CLEAR_USER_AVAILABLE_WORKSPACES } from '../actions/index';

const initialAvailableWorkspacestate = {}

export const availableWorkspaces = (state = initialAvailableWorkspacestate, action) => {
    const { available_workspaces } = action

    switch (action.type) {
        case GET_USER_AVAILABLE_WORKSPACES:
            return {
                user_id: available_workspaces.uid,
                workspaces: available_workspaces.workspaces,
                is_opened: (available_workspaces.is_opened !== undefined) ? available_workspaces.is_opened : false
            }
        case CLEAR_USER_AVAILABLE_WORKSPACES:
            return initialAvailableWorkspacestate;
        default:
            return state;
    }
};