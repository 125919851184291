import { GET_GLOBAL_WORKSPACES_MEMBERS, CLEAR_GLOBAL_WORKSPACES_MEMBERS } from '../actions/index';

const getGlobalWorkspacesMembers = (globalWorkspacesMembers = [], action) => {
    let itemsKeys = [];
    let membersKeys = action.globalWorkspacesMembers.reduce((keys, member) => {
        itemsKeys.push(member.id);
        keys = itemsKeys;
        return keys;
    }, []);

    let result = globalWorkspacesMembers.filter((member) => {
        return membersKeys.indexOf(member.id) === -1;
    });

    return result.concat(action.globalWorkspacesMembers);
}

const clearGlobalWorkspacesMembers = (action) => {
    return action.globalWorkspacesMembers;
}

export const globalWorkspacesMembers = (globalWorkspacesMembers = [], action) => {
    switch (action.type) {
        case GET_GLOBAL_WORKSPACES_MEMBERS: return getGlobalWorkspacesMembers(globalWorkspacesMembers, action);
        case CLEAR_GLOBAL_WORKSPACES_MEMBERS: return clearGlobalWorkspacesMembers(action);
        default: return globalWorkspacesMembers;
    }
};