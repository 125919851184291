import { GET_WORKSPACES_APPLICATIONS, CLEAR_WORKSPACES_APPLICATIONS } from '../actions/index';

const getWorkspacesApplications = (workspacesApplications = [], action) => {
    let itemsKeys = [];
    let applicationsKeys = action.applications.reduce((keys, application) => {
        itemsKeys.push(application.id);
        keys = itemsKeys;
        return keys;
    }, []);

    let result = workspacesApplications.filter((application) => {
        return applicationsKeys.indexOf(application.id) === -1;
    });

    return result.concat(action.applications);
}

const clearWorkspacesApplications = (action) => {
    return action.applications;
}

export const workspacesApplications = (workspacesApplications = [], action) => {
    switch (action.type) {
        case GET_WORKSPACES_APPLICATIONS: return getWorkspacesApplications(workspacesApplications, action);
        case CLEAR_WORKSPACES_APPLICATIONS: return clearWorkspacesApplications(action);
        default: return workspacesApplications;
    }
};