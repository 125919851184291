import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ApplicationsCreateForm from './ApplicationsCreateForm';

class ApplicationsCreateBody extends Component {
    static propTypes = {
        application: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired
    };

    render() {
        const { application, role } = this.props;

        return (
            <div className="mainBody">
                <div className="creationInfo container-fluid">
                    <div className="row mb-3">
                        <div className="col-6 p-0">
                            <small className="d-block pt-3 pl-3">Date of Creation</small>
                            <strong className="d-block pt-1 pb-3 pl-3">{application.created_date}</strong>
                        </div>
                        <div className="col-6 p-0">
                            <small className="d-block pt-3 pl-3">Creation Incharge</small>
                            <strong className="d-block pt-1 pb-3 pl-3">{ role.full_name }</strong>
                        </div>
                    </div>
                </div>
                <ApplicationsCreateForm />
            </div>
        );
    }
}

const mapStateToProps = ({ setup, role }) => ({
    application: setup.application,
    role
});

export default withRouter(connect(mapStateToProps)(ApplicationsCreateBody));
