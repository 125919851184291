import { userResetPasswordApi } from '../backend/domain/users';
import { loadingResetPasswordAction } from '../actions/loadingResetPasswordAction';
import { handleResetPasswordError } from '../utils/errorMessages';
import { routeCodes } from '../configurations/routes';

export const resetPasswordWithThunk = (resetPassword, workspaceId) =>
    (dispatch) => {
        dispatch(loadingResetPasswordAction(true));
        let reset = {
            username: resetPassword.username,
            email: resetPassword.email,
            admin: false,
            workspaceId
        }   
        userResetPasswordApi(reset)            
            .then((response) => {
                handleResetPasswordError(dispatch, response.status, response.error_messages, routeCodes.USERS_LOGIN);
                return response;
            })                        
            .then((response) => {
                dispatch(loadingResetPasswordAction(false));
                return response;
            });
    };