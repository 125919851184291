import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Map, TileLayer, GeoJSON } from 'react-leaflet';
import { defaultBounds, defaultZoom } from '../../../../configurations/map';
import { updateFocusAreaGeometryAction } from '../../../../actions/updateFocusAreaGeometryAction';

class WorkspacesFocusAreasMap extends Component {
    constructor(props) {
        super(props);
        this.getStyle = this.getStyle.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.normal = { weight: 1, color: "none", fillColor: "none" };
        this.highlight = { weight: 2, color: "#ff0000", fillColor: "#bababa" };
        this.handleZoomLock = this.handleZoomLock.bind(this);
    }

    state = {
        mapHeight: window.innerHeight - 370 + 'px'
    }

    mapRef = React.createRef();
    mapRefEmpty = React.createRef();
    static propTypes = {
        workspace: PropTypes.object.isRequired,
        focusAreas: PropTypes.array.isRequired,
    };

    handleZoomLock() {
        let errorMessage = { code: 403 };
        throw errorMessage;
    }

    getStyle() {
        return this.highlight;
    }

    handleScroll() {
        const map = document.getElementById('map-focus-areas');
        const mapEmpty = document.getElementById('map-empty-focus-areas');
        const top = document.documentElement.scrollTop;

        if (map !== null) {
            map.style.top = top + 'px';
        }

        if (mapEmpty !== null) {
            mapEmpty.style.top = top + 'px';;
        }
    }

    handleResize() {
        const map = document.getElementById('map-focus-areas');
        const mapEmpty = document.getElementById('map-empty-focus-areas');
        const height = window.innerHeight - 370 + 'px';
        const width = window.innerWidth / 2 - 120 + 'px';

        if (map !== null) {
            map.style.height = height;
            map.style.width = width;
        }

        if (mapEmpty !== null) {
            mapEmpty.style.height = height;
            mapEmpty.style.width = width;
        }

        this.setState({ mapHeight: height });
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
        document.getElementById('leafletStyle').href = "https://unpkg.com/leaflet@1.5.1/dist/leaflet.css";
        document.getElementById('leafletStyle').integrity = "sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ==";
    }

    componentDidUpdate(prevProps) {
        if (this.props.map.bounds) {
            if (prevProps.map.bounds !== this.props.map.bounds) {
                const map = this.mapRef.current.leafletElement;
                setTimeout(() => {
                    map.fitBounds(this.props.map.bounds);
                }, 200)
            }
            const features = this.props.map.data.features;
            if (features.length > 0 &&
                features !== prevProps.features) {
                const map = this.mapRef.current.leafletElement;
                map.invalidateSize();
            }
            else {
                const map = this.mapRefEmpty.current.leafletElement;
                map.invalidateSize();
            }
        }
    }

    render() {
        const { map } = this.props;
        const { mapHeight } = this.state;
        const position = [map.lat, map.lng];
        const bounds = (map.bounds !== undefined && map.bounds.length === 0) ? defaultBounds : map.bounds;
        const width = window.innerWidth / 2 - 120 + 'px';
        const top = document.documentElement.scrollTop + 'px';
        const left = 10 + 'px';

        return (
            <div>
                {(map.data.features.length === 0) &&
                    <div id="map-empty-focus-areas" style={{ height: mapHeight, width: width, left: left, top: top, position: 'absolute' }}>
                        <Map
                            style={{ height: mapHeight }}
                            center={position}
                            zoom={defaultZoom}
                            onZoomStart={this.handleZoomLock}
                            zoomControl={false}
                            maxBoundsViscosity={1.0}
                            ref={this.mapRefEmpty}
                        >
                            <TileLayer url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png' />
                        </Map>
                    </div>
                }
                {(map.data.features.length > 0) &&
                    <div id="map-focus-areas" style={{ height: mapHeight, width: width, left: left, top: top, position: 'absolute' }}>
                        <Map
                            style={{ height: mapHeight }}
                            center={position}
                            zoom={map.zoom}
                            minZoom={map.minZoom} maxZoom={map.maxZoom}
                            zoomControl={false}
                            maxBoundsViscosity={1.0}
                            bounds={bounds}
                            maxBounds={bounds}
                            ref={this.mapRef}
                        >
                            <TileLayer url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png' />
                            {map.data !== undefined &&
                                map.data.features.map((ds, ix) => {
                                    return (<GeoJSON data={ds} key={ix} style={this.getStyle} />);
                                })
                            }
                        </Map>
                    </div>
                }
            </div>
        );
    }
}
const mapStateToProps = ({ detail, focusAreas, map }) => ({
    workspace: detail.workspace,
    focusAreas,
    map: map.focus_area_map,
    selectedFocusArea: map.selectedFocusArea
});

const mapDispatchToProps = (dispatch) => ({
    updateGeometry: (features) => dispatch(updateFocusAreaGeometryAction(features))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesFocusAreasMap));