import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUsersSortingWithThunk } from '../../../api/usersThunk';
import { getUsersSortingAction } from '../../../actions/getUsersSortingAction';
import { defaultUsersPageSize } from '../../../configurations/app';
import { getUsersSearchWithThunk } from '../../../api/usersThunk';

class GridHeaderItem extends Component {
    static propTypes = {
        header: PropTypes.object.isRequired,
        pagination: PropTypes.object.isRequired,
        sort: PropTypes.object.isRequired,
        search: PropTypes.object.isRequired,
        getUsers: PropTypes.func.isRequired,
        dispatchUsersSorting: PropTypes.func.isRequired,
    };

    dispatchSorting(sortBy, sortType) {
        let data = {
            sort_by: sortBy,
            sort_type: sortType
        }
        this.props.dispatchUsersSorting(data);
    }

    onSortingChange = (page) => {
        const { header, pagination, sort, search } = this.props;

        let currentSortBy = (sort.sort_by === header.sort_column) ? sort.sort_by : header.sort_column;
        let currentSortType = (sort.sort_type === 'Ascending') ? "Descending" : "Ascending";

        if (currentSortBy !== sort.sort_by)
            currentSortType = "Ascending";

        this.dispatchSorting(currentSortBy, currentSortType);
        if (search.search_text !== '') {
               this.props.getUsersSearch(1, defaultUsersPageSize, search.search_text, currentSortBy, currentSortType);
        }
        else {
            this.props.getUsers(pagination.current_page, 20, currentSortBy, currentSortType);
        }        
    }

    render() {
        const { header, sort } = this.props;
        return (
            header.header_name === "Picture" ?
                (<th id={"grid-header-" + header.id} className="grid-no-sort">
                    {header.header_name}
                </th>) :
                (<th id={"grid-header-" + header.id} className="shortings grid-sort" onClick={this.onSortingChange}>
                    {header.header_name}
                    {header.sort_column === sort.sort_by ? (<span className={(sort.sort_type === 'Ascending') ? 'icon-wrd-sorting-up head-sort-span' : 'icon-wrd-sorting-down head-sort-span'}></span>) : null}                    
                </th>)
        );
    }
}

const mapStateToProps = ({ sorting, pagination, search }) => ({
    pagination: pagination.user,
    sort: sorting.user,
    search: search.user
});

const mapDispatchToProps = (dispatch) => ({
    getUsers: (pageIndex, pageSize, sortingBy, sortingSize) => dispatch(getUsersSortingWithThunk(pageIndex, pageSize, sortingBy, sortingSize)),
    dispatchUsersSorting: (data) => dispatch(getUsersSortingAction(data)),
    getUsersSearch: (pageIndex, pageSize, searchKeyword, SortingBy, SortingType) => dispatch(getUsersSearchWithThunk(pageIndex, pageSize, searchKeyword, SortingBy, SortingType))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GridHeaderItem));