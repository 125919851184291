import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDialogApplicationsAction } from '../../../actions/updateDialogApplicationsAction';
import { updateApplicationStatusWithThunk, deleteApplicationWithThunk } from '../../../api/applicationsThunk';
import { updateApplicationDetailStatusWithThunk } from '../../../api/applicationsThunk';
import { defaultApplicationsSortingBy, defaultApplicationsSortingType } from '../../../configurations/app';

class ApplicationsModal extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        grid: PropTypes.object.isRequired,
        dialog: PropTypes.object.isRequired,
        application: PropTypes.object.isRequired,
        dispatchDialogApplications: PropTypes.func.isRequired,
        updateApplicationStatus: PropTypes.func.isRequired,
        updateApplicationDetailStatus: PropTypes.func.isRequired
    };

    setDefaultDialogState() {
        let applications = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            route_back_to: '',
            show_dialog: false
        };

        this.props.dispatchDialogApplications(applications);
    }

    updateApplication() {
        const { dialog, type } = this.props;

        this.setDefaultDialogState();
        if (dialog.dialog_type !== 'trash') {
            if (type === 'list') {
                let application = {
                    id: dialog.id,
                    status: (dialog.status === 'Enabled') ? 'Disabled' : 'Enabled'
                }

                this.props.updateApplicationStatus(application, defaultApplicationsSortingBy, defaultApplicationsSortingType);
            }
            else {
                let application = {
                    ...this.props.application,
                    status: (dialog.status === 'Enabled') ? 'Disabled' : 'Enabled'
                }

                this.props.updateApplicationDetailStatus(application);
            }
        }
        else {       
            this.props.deleteApplication(dialog.id, defaultApplicationsSortingBy, defaultApplicationsSortingType, dialog.route_back_to, this.props.history);            
        }
    }

    closeModal() {
        this.setDefaultDialogState();
    }

    render() {
        const { dialog } = this.props;
        const dialogCommand = (dialog.dialog_type === 'trash') ? 'REMOVE' : (dialog.status === 'Enabled') ? 'DISABLE' : 'ENABLE';

        return (
            <div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div className={"popupPanel popupPanel-small deleteWarning" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                    <div className="popupHeader">
                        <div className="p-4">
                            <h2 className="popupTitle">{dialog.dialog_title}</h2>
                            <button className="btn iconBtn" onClick={() => { this.closeModal(); }}>
                                <span className="icon-wrd-close"></span>
                            </button>
                        </div>
                    </div>
                    <div className="popupBody">
                        <div className="p-4">
                            <div className="summernote">
                                <p>                                    
                                    Are you sure you want to <b className={(dialog.dialog_type === 'trash') ? 'actionRemove' : ''}>{dialogCommand}</b> application 
                                    &nbsp;<b className="appName">{dialog.name}</b>&nbsp;?
                                    <br />
                                    {(dialog.dialog_type === 'trash') ? 'All data related to this Application including global and user preference will no longer be available.': ''}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="px-4 pt-4 pb-2">
                            <div className="row">
                                <div className="col">
                                    <button className="btn greyBtn icoTextBtn closePopup" onClick={() => { this.closeModal(); }}>
                                        <span className="icon-wrd-close"></span>
                                        Cancel
                                    </button>
                                </div>
                                <div className="col text-right">
                                    <button className={((dialog.dialog_type === 'trash') ? 'redBtn' : (dialog.status === 'Enabled') ? 'redBtn' : 'greenBtn') + ' btn icoTextBtn mr-3 closePopup'}
                                        onClick={() => { this.updateApplication(); }}
                                    >
                                        <span className={(dialog.dialog_type === 'trash') ? "icon-wrd-trash" : ((dialog.status === 'Enabled') ? "icon-wrd-lock" : "icon-wrd-unlock")}></span>
                                        {(dialog.dialog_type === 'trash') ? 'Remove' : (dialog.status === 'Enabled') ? 'Disable' :'Enable'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ dialog, pagination, detail }) => ({
    dialog: dialog.applications,
    grid: pagination.application,
    application: detail.application
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogApplications: (dialog) => dispatch(updateDialogApplicationsAction(dialog)),
    updateApplicationStatus: (application, sortingBy, sortingType) => dispatch(updateApplicationStatusWithThunk(application, sortingBy, sortingType)),
    updateApplicationDetailStatus: (application) => dispatch(updateApplicationDetailStatusWithThunk(application)),
    deleteApplication: (applicationId, sortingBy, sortingType, routeBackTo, historyProps) => dispatch(deleteApplicationWithThunk(applicationId, sortingBy, sortingType, routeBackTo, historyProps)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationsModal));
