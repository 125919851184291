import { userResetPasswordApi } from '../backend/domain/users';
import { loadingUserAction } from '../actions/loadingUserAction';
import { handleResetPasswordError } from '../utils/errorMessages';

export const resetPasswordAdminWithThunk = (resetPassword, workspaceId) =>
    (dispatch) => {
        dispatch(loadingUserAction(true));
        let reset = {
            username: resetPassword.uid,
            email: resetPassword.email,
            admin: true,
            workspaceId
        } 
        userResetPasswordApi(reset)
            .then((response) => {
                handleResetPasswordError(dispatch, response.status, response.error_messages, '', 'Admin', resetPassword.username);
                return response;
            }) 
            .then((response) => {
                dispatch(loadingUserAction(false));
                return response;
            });
    };