import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getWorkspacesFocusAreasWithThunk } from '../../../../api/workspacesFocusAreasThunk';
import { getWorkspacesFocusAreasByIdWithThunk } from '../../../../api/workspacesFocusAreasThunk';
import { clearWorkspacesFocusAreasAction } from '../../../../actions/clearWorkspacesFocusAreasAction';
import { getUrlWorkspaceId } from '../../../../utils/url';
import { getSessionStorage } from '../../../../utils/sessionStorage';
import Loader from 'react-loader-spinner'
import SidebarContainer from '../../../Sidebar/SidebarContainer';
import WorkspacesFocusAreasModal from '../Modal/WorkspacesFocusAreasModal';
import WorkspacesFocusAreasSetGeoModal from '../Modal/WorkspacesFocusAreasSetGeoModal';
import WorkspacesFocusAreasSetGeoErrorModal from '../Modal/WorkspacesFocusAreasSetGeoErrorModal';
import WorkspacesFocusAreasHeader from './WorkspacesFocusAreasHeader';
import WorkspacesFocusAreasBody from './WorkspacesFocusAreasBody';
import ModalAlert from './../../../Shared/ModalAlert';

class WorkspacesFocusAreasContainer extends Component {
    static propTypes = {
        workspace: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired,
        getWorkspacesFocusAreasById: PropTypes.func.isRequired,
        getWorkspacesFocusAreas: PropTypes.func.isRequired,
        clearWorkspacesFocusAreas: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired
    };

    getCurrentWorkspaceId = () => {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    componentDidMount() {
        const { workspace, role, loading } = this.props;
        const id = this.getCurrentWorkspaceId();

        if (loading === false) {
            if (id !== workspace.workspace_name.toLowerCase().replaceAll(" ", "+")) {
                let userId = role.user_id;
                if (userId === "") {
                    const sessionRole = getSessionStorage("role");

                    if (sessionRole !== "") {
                        const role = JSON.parse(sessionRole);
                        userId = role.user_id;
                    }
                }
                this.props.getWorkspacesFocusAreasById(id, userId);
            }
            else {
                this.props.getWorkspacesFocusAreas(id);
            }
        }
    }

    componentWillUnmount() {
        this.props.clearWorkspacesFocusAreas();
    }

    render() {
        const { workspace, loading, loadingTheme } = this.props;

        if (workspace.id === undefined || workspace.id === '')
            return (
                <div>
                    {(loading || loadingTheme) &&
                        <div className="loader-container">
                            <Loader
                                type="Ball-Triangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                </div>
            );

        return (
            <div>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <SidebarContainer />
                        <main>
                            <div className="mapBackground" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <WorkspacesFocusAreasHeader />
                                    <WorkspacesFocusAreasBody />
                                </div>
                            </div>
                        </main>
                        <WorkspacesFocusAreasModal type='list' />
                        <WorkspacesFocusAreasSetGeoModal />
                        <WorkspacesFocusAreasSetGeoErrorModal />
                        <ModalAlert />
                    </div>
                }
            </div>
        );
    }
}
const mapStateToProps = ({ detail, role, loading }) => ({
    workspace: detail.workspace,
    role,
    loading: loading.workspaces_focus_areas.loading,
    loadingTheme: loading.theme.loading
});

const mapDispatchToProps = (dispatch) => ({
    getWorkspacesFocusAreas: (id) => dispatch(getWorkspacesFocusAreasWithThunk(id)),
    getWorkspacesFocusAreasById: (id, userId) => dispatch(getWorkspacesFocusAreasByIdWithThunk(id, userId)),
    clearWorkspacesFocusAreas: () => dispatch(clearWorkspacesFocusAreasAction([])),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesFocusAreasContainer));