export const registrationContent = `<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="initial-scale=1.0">
    <meta name="format-detection" content="telephone=no">    

    <style type="text/css">
        body {
            margin: 0;
            padding: 0;
        }
        
        img {
            border: 0px;
            display: block;
        }
        
        .socialLinks {
            font-size: 6px;
        }
        
        .socialLinks a {
            display: inline-block;
        }
        
        .long-text p {
            margin: 1em 0px;
        }
        
        .long-text p:last-child {
            margin-bottom: 0px;
        }
        
        .long-text p:first-child {
            margin-top: 0px;
        }
    </style>
    <style type="text/css">
        /* yahoo, hotmail */
        
        .ExternalClass,
        .ExternalClass p,
        .ExternalClass span,
        .ExternalClass font,
        .ExternalClass td,
        .ExternalClass div {
            line-height: 100%;
        }
        
        .yshortcuts a {
            border-bottom: none !important;
        }
        
        .vb-outer {
            min-width: 0 !important;
        }
        
        .RMsgBdy,
        .ExternalClass {
            width: 100%;
            background-color: #3f3f3f;
            background-color: #4f81bd
        }
        /* outlook/office365 add buttons outside not-linked images and safari have 2px margin */
        
        [o365] button {
            margin: 0 !important;
        }
        /* outlook */
        
        table {
            mso-table-rspace: 0pt;
            mso-table-lspace: 0pt;
        }
        
        #outlook a {
            padding: 0;
        }
        
        img {
            outline: none;
            text-decoration: none;
            border: none;
            -ms-interpolation-mode: bicubic;
        }
        
        a img {
            border: none;
        }
        
        @media screen and (max-width: 600px) {
            table.vb-container,
            table.vb-row {
                width: 95% !important;
            }
            .mobile-hide {
                display: none !important;
            }
            .mobile-textcenter {
                text-align: center !important;
            }
            .mobile-full {
                width: 100% !important;
                max-width: none !important;
            }
        }
        /* previously used also screen and (max-device-width: 600px) but Yahoo Mail doesn't support multiple queries */
    </style>
    <style type="text/css">
        #ko_textBlock_5 .links-color a,
        #ko_textBlock_5 .links-color a:link,
        #ko_textBlock_5 .links-color a:visited,
        #ko_textBlock_5 .links-color a:hover {
            color: #3f3f3f;
            color: #3f3f3f;
            text-decoration: underline
        }
        
        #ko_textBlock_11 .links-color a,
        #ko_textBlock_11 .links-color a:link,
        #ko_textBlock_11 .links-color a:visited,
        #ko_textBlock_11 .links-color a:hover {
            color: #3f3f3f;
            color: #3f3f3f;
            text-decoration: underline
        }
        
        #ko_textBlock_7 .links-color a,
        #ko_textBlock_7 .links-color a:link,
        #ko_textBlock_7 .links-color a:visited,
        #ko_textBlock_7 .links-color a:hover {
            color: #3f3f3f;
            color: #3f3f3f;
            text-decoration: underline
        }
        
        #ko_socialBlock_9 .links-color a,
        #ko_socialBlock_9 .links-color a:link,
        #ko_socialBlock_9 .links-color a:visited,
        #ko_socialBlock_9 .links-color a:hover {
            color: #cccccc;
            color: #cccccc;
            text-decoration: underline
        }
        
        #ko_footerBlock_2 .links-color a,
        #ko_footerBlock_2 .links-color a:link,
        #ko_footerBlock_2 .links-color a:visited,
        #ko_footerBlock_2 .links-color a:hover {
            color: #cccccc;
            color: #cccccc;
            text-decoration: underline
        }
    </style>

</head>

<body style="margin: 0; padding: 0; background-color: #4f81bd; color: #1f497d;" vlink="#cccccc" text="#1f497d" bgcolor="#4f81bd" alink="#cccccc">
    <center>

        <table role="presentation" class="vb-outer" style="background-color: #f2f2f2;" id="ko_logoBlock_3" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#f2f2f2">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px; max-width: 570px; -mru-width: 0px;" class="vb-row" width="570" cellspacing="9" cellpadding="0" border="0">

                                <tbody>
                                    <tr>
                                        <td style="font-size: 0;" valign="top" align="center">
                                            <div style="vertical-align: top; width: 100%; max-width: 276px; -mru-width: 0px;">
                                                <!--
        -->
                                                <table role="presentation" class="vb-content" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px;" width="276" cellspacing="9" cellpadding="0" border="0">

                                                    <tbody>
                                                        <tr>
                                                            <td class="links-color" width="100%" valign="top" align="center">
                                                                <!--[if (lte ie 8)]><div style="display: inline-block; width: 258px; -mru-width: 0px;"><![endif]-->
                                                                <a target="_new" href="https://www.dhigroup.com/"><img alt="DHI Group" style="border: 0px; display: block; vertical-align: top; height: auto; margin: 0 auto; color: #1f497d; font-size: 8px; font-family: Arial Black, Arial Black, Gadget, sans-serif; width: 100%; max-width: 258px; height: auto;" src="https://mosaico.io/srv/f-7wlhhwv/img?src=https%3A%2F%2Fmosaico.io%2Ffiles%2F7wlhhwv%2Fdhi_logo_pos_tag_rgb.png&amp;method=resize&amp;params=258%2Cnull" width="258" vspace="0" hspace="0" border="0" align="middle"></a>
                                                                <!--[if (lte ie 8)]></div><![endif]-->
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->

                    </td>
                </tr>
            </tbody>
        </table>
        <table role="presentation" class="vb-outer" style="background-color: #f2f2f2;" id="ko_titleBlock_4" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#f2f2f2">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" class="vb-row" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 0px; border-spacing: 0px; max-width: 570px; -mru-width: 0px;" width="570" cellspacing="0" cellpadding="0" border="0" bgcolor="#ffffff">

                                <tbody>
                                    <tr>
                                        <td style="font-size: 0; padding: 0 9px;" valign="top" align="center">
                                            <div style="vertical-align: top; width: 100%; max-width: 552px; -mru-width: 0px;">
                                                <!--
        -->
                                                <table role="presentation" class="vb-content" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px;" width="552" cellspacing="9" cellpadding="0" border="0">

                                                    <tbody>
                                                        <tr>
                                                            <td style="font-weight: normal; color: #595959; font-size: 28px; font-family: Verdana, Geneva, sans-serif; text-align: center;" width="100%" valign="top" align="center"><span style="font-weight: normal;"><strong>DHI</strong> Water Tools</span></td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
        <table role="presentation" class="vb-outer" style="background-color: #f2f2f2;" id="ko_textBlock_5" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#f2f2f2">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" class="vb-container" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 18px; border-spacing: 18px; max-width: 570px; -mru-width: 0px;" width="570" cellspacing="18" cellpadding="0" border="0" bgcolor="#ffffff">

                                <tbody>
                                    <tr>
                                        <td class="long-text links-color" style="font-weight: normal; color: #3f3f3f; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: left; line-height: normal;" width="100%" valign="top" align="left">
                                            <h3 style="text-align: center;"><strong><strong>New Account Confirmation</strong></strong></h3>
                                            <hr>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
        <table role="presentation" class="vb-outer" style="background-color: #f2f2f2;" id="ko_textBlock_11" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#f2f2f2">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" class="vb-container" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 18px; border-spacing: 18px; max-width: 570px; -mru-width: 0px;" width="570" cellspacing="18" cellpadding="0" border="0" bgcolor="#ffffff">

                                <tbody>
                                    <tr>
                                        <td class="long-text links-color" style="font-weight: normal; color: #3f3f3f; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: left; line-height: normal;" width="100%" valign="top" align="left">
                                            <p style="margin: 1em 0px; margin-top: 0px;">Dear <strong>{FULLNAME}</strong>,</p>
                                            <p style="margin: 1em 0px;">&nbsp;</p>
                                            <p style="margin: 1em 0px; margin-bottom: 0px;">Your new DHI Water Tools user account has been created and requires your confirmation. Please click button confirmation below to confirm and activate your new user account.</p>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
        <table role="presentation" class="vb-outer" style="background-color: #f2f2f2;" id="ko_buttonBlock_6" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#f2f2f2">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" class="vb-container" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 18px; border-spacing: 18px; max-width: 570px; -mru-width: 0px;" width="570" cellspacing="18" cellpadding="0" border="0" bgcolor="#ffffff">

                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                            <table role="presentation" style="border-spacing: 0; mso-padding-alt: 12px 12px 12px 12px;" cellspacing="0" cellpadding="12" border="0" align="center">
                                                <tbody>
                                                    <tr>
                                                        <td style="text-align: center; font-weight: normal; padding: 12px; padding-left: 14px; padding-right: 14px; background-color: #0070c0; color: #f2f2f2; font-size: 22px; font-family: Verdana, Geneva, sans-serif; border-radius: 4px;" width="auto" valign="middle" bgcolor="#0070c0" align="center"><a style="text-decoration: none; font-weight: normal; color: #f2f2f2; font-size: 22px; font-family: Verdana, Geneva, sans-serif;" target="_new" href="{LINK}">Confirmation</a></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
        <table role="presentation" class="vb-outer" style="background-color: #f2f2f2;" id="ko_textBlock_7" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#f2f2f2">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" class="vb-container" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 18px; border-spacing: 18px; max-width: 570px; -mru-width: 0px;" width="570" cellspacing="18" cellpadding="0" border="0" bgcolor="#ffffff">

                                <tbody>
                                    <tr>
                                        <td class="long-text links-color" style="font-weight: normal; color: #3f3f3f; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: left; line-height: normal;" width="100%" valign="top" align="left">
                                            <p style="margin: 1em 0px; margin-top: 0px;">Please use login information </p>
                                            <div>&nbsp;</div>
                                            <div style="text-align: center;">Username: <strong>{USERNAME}</strong></div>
                                            <div style="text-align: center;">Password: <strong>{PASSWORD}</strong></div>
                                            <p style="margin: 1em 0px; text-align: center;">&nbsp;</p>
                                            <p style="margin: 1em 0px; text-align: center;"><em><sub><strong>For security reason, please change the automatically generated password once you've logged in.</strong></sub></em></p>
                                            <p style="margin: 1em 0px; text-align: center;">&nbsp;</p>
                                            <p style="margin: 1em 0px; text-align: center;">&nbsp;</p>
                                            <div style="text-align: right; line-height: 1em;"><em><sub>Yours Sincerely,</sub></em>
                                                <br><strong><em><sub>DHI Water Tools Team</sub></em></strong></div>
                                            <div style="text-align: center;">&nbsp;</div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
        <table role="presentation" class="vb-outer" style="background-color: #4f81bd;" id="ko_socialBlock_9" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#4f81bd">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px; max-width: 570px; -mru-width: 0px;" class="vb-row" width="570" cellspacing="9" cellpadding="0" border="0">

                                <tbody>
                                    <tr>
                                        <td style="font-size: 0;" valign="top" align="center">
                                            <div style="width: 100%; max-width: 552px; -mru-width: 0px;">
                                                <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="552"><tr><![endif]-->
                                                <!--
        -->
                                                <!--
          -->
                                                <!--[if (gte mso 9)|(lte ie 8)]><td align="left" valign="top" width="276"><![endif]-->
                                                <!--
      -->
                                                <div class="mobile-full" style="display: inline-block; vertical-align: top; width: 100%; max-width: 276px; -mru-width: 0px; min-width: calc(50%); max-width: calc(100%); width: calc(304704px - 55200%);">
                                                    <!--
        -->
                                                    <table role="presentation" class="vb-content" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px; -yandex-p: calc(2px - 3%);" width="276" cellspacing="9" cellpadding="0" border="0" align="left">

                                                        <tbody>
                                                            <tr>
                                                                <td class="long-text links-color" style="font-weight: normal; color: #1f497d; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: left;" width="100%" valign="top" align="left"></td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    <!--
      -->
                                                </div>
                                                <!--[if (gte mso 9)|(lte ie 8)]></td><![endif]-->
                                                <!--
          -->
                                                <!--[if (gte mso 9)|(lte ie 8)]><td align="left" valign="top" width="276"><![endif]-->
                                                <!--
      -->
                                                <div class="mobile-full" style="display: inline-block; vertical-align: top; width: 100%; max-width: 276px; -mru-width: 0px; min-width: calc(50%); max-width: calc(100%); width: calc(304704px - 55200%);">
                                                    <!--
        -->
                                                    <table role="presentation" class="vb-content" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px; -yandex-p: calc(2px - 3%);" width="276" cellspacing="9" cellpadding="0" border="0" align="left">

                                                        <tbody>
                                                            <tr>
                                                                <td style="font-size: 6px; font-weight: normal; text-align: right;" class="links-color socialLinks mobile-textcenter" width="100%" valign="top" align="right">

                                                                    &nbsp;
                                                                    <a target="_new" href="https://www.facebook.com/DHIgroup" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #3b5998; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/fb-colors-96.png" alt="Facebook" width="32" height="32" border="0"></a>

                                                                    &nbsp;
                                                                    <a target="_new" href="https://twitter.com/dhigroup" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #4099FF; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/tw-colors-96.png" alt="Twitter" width="32" height="32" border="0"></a>

                                                                    &nbsp;
                                                                    <a target="_new" href="https://www.linkedin.com/company/dhi" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #007bb6; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/in-colors-96.png" alt="Linkedin" width="32" height="32" border="0"></a>

                                                                    &nbsp;
                                                                    <a target="_new" href="https://www.youtube.com/user/DHIGroup" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #cd201f; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/you-colors-96.png" alt="Youtube" width="32" height="32" border="0"></a>

                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    <!--
      -->
                                                </div>
                                                <!--[if (gte mso 9)|(lte ie 8)]></td><![endif]-->
                                                <!--
        -->
                                                <!--
      -->
                                                <!--[if (gte mso 9)|(lte ie 8)]></tr></table><![endif]-->
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- footerBlock -->
        <table role="presentation" class="vb-outer" style="background-color: #4f81bd;" id="ko_footerBlock_2" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#4f81bd">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" style="border-collapse: separate; width: 100%; mso-cellspacing: 0px; border-spacing: 0px; max-width: 570px; -mru-width: 0px;" class="vb-row" width="570" cellspacing="0" cellpadding="0" border="0">
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- /footerBlock -->

    </center>
</body>

</html>`;
















export const registrationReminderContent = `<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="initial-scale=1.0">
    <meta name="format-detection" content="telephone=no">

    <style type="text/css">
        body {
            margin: 0;
            padding: 0;
        }
        
        img {
            border: 0px;
            display: block;
        }
        
        .socialLinks {
            font-size: 6px;
        }
        
        .socialLinks a {
            display: inline-block;
        }
        
        .long-text p {
            margin: 1em 0px;
        }
        
        .long-text p:last-child {
            margin-bottom: 0px;
        }
        
        .long-text p:first-child {
            margin-top: 0px;
        }
    </style>
    <style type="text/css">
        /* yahoo, hotmail */
        
        .ExternalClass,
        .ExternalClass p,
        .ExternalClass span,
        .ExternalClass font,
        .ExternalClass td,
        .ExternalClass div {
            line-height: 100%;
        }
        
        .yshortcuts a {
            border-bottom: none !important;
        }
        
        .vb-outer {
            min-width: 0 !important;
        }
        
        .RMsgBdy,
        .ExternalClass {
            width: 100%;
            background-color: #3f3f3f;
            background-color: #4f81bd
        }
        /* outlook/office365 add buttons outside not-linked images and safari have 2px margin */
        
        [o365] button {
            margin: 0 !important;
        }
        /* outlook */
        
        table {
            mso-table-rspace: 0pt;
            mso-table-lspace: 0pt;
        }
        
        #outlook a {
            padding: 0;
        }
        
        img {
            outline: none;
            text-decoration: none;
            border: none;
            -ms-interpolation-mode: bicubic;
        }
        
        a img {
            border: none;
        }
        
        @media screen and (max-width: 600px) {
            table.vb-container,
            table.vb-row {
                width: 95% !important;
            }
            .mobile-hide {
                display: none !important;
            }
            .mobile-textcenter {
                text-align: center !important;
            }
            .mobile-full {
                width: 100% !important;
                max-width: none !important;
            }
        }
        /* previously used also screen and (max-device-width: 600px) but Yahoo Mail doesn't support multiple queries */
    </style>
    <style type="text/css">
        #ko_singleArticleBlock_13 .links-color a,
        #ko_singleArticleBlock_13 .links-color a:link,
        #ko_singleArticleBlock_13 .links-color a:visited,
        #ko_singleArticleBlock_13 .links-color a:hover {
            color: #3f3f3f;
            color: #3f3f3f;
            text-decoration: underline
        }
        
        #ko_textBlock_7 .links-color a,
        #ko_textBlock_7 .links-color a:link,
        #ko_textBlock_7 .links-color a:visited,
        #ko_textBlock_7 .links-color a:hover {
            color: #3f3f3f;
            color: #3f3f3f;
            text-decoration: underline
        }
        
        #ko_socialBlock_9 .links-color a,
        #ko_socialBlock_9 .links-color a:link,
        #ko_socialBlock_9 .links-color a:visited,
        #ko_socialBlock_9 .links-color a:hover {
            color: #cccccc;
            color: #cccccc;
            text-decoration: underline
        }
        
        #ko_footerBlock_2 .links-color a,
        #ko_footerBlock_2 .links-color a:link,
        #ko_footerBlock_2 .links-color a:visited,
        #ko_footerBlock_2 .links-color a:hover {
            color: #cccccc;
            color: #cccccc;
            text-decoration: underline
        }
    </style>

</head>

<body style="margin: 0; padding: 0; background-color: #4f81bd; color: #1f497d;" vlink="#cccccc" text="#1f497d" bgcolor="#4f81bd" alink="#cccccc">
    <center>

        <table role="presentation" class="vb-outer" style="background-color: #f2f2f2;" id="ko_singleArticleBlock_13" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#f2f2f2">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" class="vb-row" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 9px; border-spacing: 9px; max-width: 570px; -mru-width: 0px;" width="570" cellspacing="9" cellpadding="0" border="0" bgcolor="#ffffff">

                                <tbody>
                                    <tr>
                                        <td style="font-size: 0;" valign="top" align="center">
                                            <div style="vertical-align: top; width: 100%; max-width: 552px; -mru-width: 0px;">
                                                <!--
        -->
                                                <table role="presentation" class="vb-content" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px;" width="552" cellspacing="9" cellpadding="0" border="0">

                                                    <tbody>
                                                        <tr>
                                                            <td class="links-color" style="padding-bottom: 9px;" width="100%" valign="top" align="center">
                                                                <!--[if (lte ie 8)]><div style="display: inline-block; width: 534px; -mru-width: 0px;"><![endif]--><img alt="DHI Group - The expert in Water Environments" style="border: 0px; display: block; vertical-align: top; height: auto; margin: 0 auto; color: #3f3f3f; font-size: 13px; font-family: Arial, Helvetica, sans-serif; width: 100%; max-width: 534px; height: auto;" src="https://mosaico.io/srv/f-7wlhhwv/img?src=https%3A%2F%2Fmosaico.io%2Ffiles%2F7wlhhwv%2Fdhi_logo_pos_tag_rgb.png&amp;method=resize&amp;params=534%2Cnull" width="534" vspace="0" hspace="0" border="0" align="middle">
                                                                <!--[if (lte ie 8)]></div><![endif]-->
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-weight: normal; color: #3f3f3f; font-size: 18px; font-family: Arial, Helvetica, sans-serif; text-align: left;" width="100%" valign="top" align="left"><span style="font-weight: normal;">Registration Reminder Confirmation</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td class="long-text links-color" style="font-weight: normal; color: #3f3f3f; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: left; line-height: normal;" width="100%" valign="top" align="left">
                                                                <p style="margin: 1em 0px; margin-top: 0px;">&nbsp;</p>
                                                                <p style="margin: 1em 0px;">Dear <strong>{FULLNAME}</strong>,</p>
                                                                <p style="margin: 1em 0px;">&nbsp;</p>
                                                                <p style="margin: 1em 0px; margin-bottom: 0px;">Your new DHI Water Tools user account has been created and requires your confirmation. Please click button confirmation below to confirm and activate your new user account.</p>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
        <table role="presentation" class="vb-outer" style="background-color: #f2f2f2;" id="ko_buttonBlock_6" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#f2f2f2">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" class="vb-container" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 18px; border-spacing: 18px; max-width: 570px; -mru-width: 0px;" width="570" cellspacing="18" cellpadding="0" border="0" bgcolor="#ffffff">

                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                            <table role="presentation" style="border-spacing: 0; mso-padding-alt: 12px 12px 12px 12px;" cellspacing="0" cellpadding="12" border="0" align="center">
                                                <tbody>
                                                    <tr>
                                                        <td style="text-align: center; font-weight: normal; padding: 12px; padding-left: 14px; padding-right: 14px; background-color: #0070c0; color: #f2f2f2; font-size: 22px; font-family: Verdana, Geneva, sans-serif; border-radius: 4px;" width="auto" valign="middle" bgcolor="#0070c0" align="center"><a style="text-decoration: none; font-weight: normal; color: #f2f2f2; font-size: 22px; font-family: Verdana, Geneva, sans-serif;" target="_new" href="{LINK}">Confirmation</a></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
        <table role="presentation" class="vb-outer" style="background-color: #f2f2f2;" id="ko_textBlock_7" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#f2f2f2">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" class="vb-container" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 18px; border-spacing: 18px; max-width: 570px; -mru-width: 0px;" width="570" cellspacing="18" cellpadding="0" border="0" bgcolor="#ffffff">

                                <tbody>
                                    <tr>
                                        <td class="long-text links-color" style="font-weight: normal; color: #3f3f3f; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: left; line-height: normal;" width="100%" valign="top" align="left">
                                            <p style="margin: 1em 0px; margin-top: 0px;">Please use login information </p>
                                            <div>&nbsp;</div>
                                            <div style="text-align: center;">Username: <strong>{USERNAME}</strong></div>
                                            <div style="text-align: center;">Password: <strong>{PASSWORD}</strong></div>
                                            <p style="margin: 1em 0px; text-align: center;">&nbsp;</p>
                                            <p style="margin: 1em 0px; text-align: center;"><em><sub><strong>For security reason, please change the automatically generated password once you've logged in.</strong></sub></em></p>
                                            <p style="margin: 1em 0px; text-align: center;">&nbsp;</p>
                                            <p style="margin: 1em 0px; text-align: center;">&nbsp;</p>
                                            <div style="text-align: right; line-height: 1em;"><em><sub>Yours Sincerely,</sub></em>
                                                <br><strong><em><sub>DHI Water Tools Team</sub></em></strong></div>
                                            <div style="text-align: center;">&nbsp;</div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
        <table role="presentation" class="vb-outer" style="background-color: #4f81bd;" id="ko_socialBlock_9" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#4f81bd">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px; max-width: 570px; -mru-width: 0px;" class="vb-row" width="570" cellspacing="9" cellpadding="0" border="0">

                                <tbody>
                                    <tr>
                                        <td style="font-size: 0;" valign="top" align="center">
                                            <div style="width: 100%; max-width: 552px; -mru-width: 0px;">
                                                <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="552"><tr><![endif]-->
                                                <!--
        -->
                                                <!--
          -->
                                                <!--[if (gte mso 9)|(lte ie 8)]><td align="left" valign="top" width="276"><![endif]-->
                                                <!--
      -->
                                                <div class="mobile-full" style="display: inline-block; vertical-align: top; width: 100%; max-width: 276px; -mru-width: 0px; min-width: calc(50%); max-width: calc(100%); width: calc(304704px - 55200%);">
                                                    <!--
        -->
                                                    <table role="presentation" class="vb-content" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px; -yandex-p: calc(2px - 3%);" width="276" cellspacing="9" cellpadding="0" border="0" align="left">

                                                        <tbody>
                                                            <tr>
                                                                <td class="long-text links-color" style="font-weight: normal; color: #1f497d; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: left;" width="100%" valign="top" align="left"></td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    <!--
      -->
                                                </div>
                                                <!--[if (gte mso 9)|(lte ie 8)]></td><![endif]-->
                                                <!--
          -->
                                                <!--[if (gte mso 9)|(lte ie 8)]><td align="left" valign="top" width="276"><![endif]-->
                                                <!--
      -->
                                                <div class="mobile-full" style="display: inline-block; vertical-align: top; width: 100%; max-width: 276px; -mru-width: 0px; min-width: calc(50%); max-width: calc(100%); width: calc(304704px - 55200%);">
                                                    <!--
        -->
                                                    <table role="presentation" class="vb-content" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px; -yandex-p: calc(2px - 3%);" width="276" cellspacing="9" cellpadding="0" border="0" align="left">

                                                        <tbody>
                                                            <tr>
                                                                <td style="font-size: 6px; font-weight: normal; text-align: right;" class="links-color socialLinks mobile-textcenter" width="100%" valign="top" align="right">

                                                                    &nbsp;
                                                                    <a target="_new" href="https://www.facebook.com/DHIgroup" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #3b5998; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/fb-colors-96.png" alt="Facebook" width="32" height="32" border="0"></a>

                                                                    &nbsp;
                                                                    <a target="_new" href="https://twitter.com/dhigroup" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #4099FF; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/tw-colors-96.png" alt="Twitter" width="32" height="32" border="0"></a>

                                                                    &nbsp;
                                                                    <a target="_new" href="https://www.linkedin.com/company/dhi" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #007bb6; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/in-colors-96.png" alt="Linkedin" width="32" height="32" border="0"></a>

                                                                    &nbsp;
                                                                    <a target="_new" href="https://www.youtube.com/user/DHIGroup" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #cd201f; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/you-colors-96.png" alt="Youtube" width="32" height="32" border="0"></a>

                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    <!--
      -->
                                                </div>
                                                <!--[if (gte mso 9)|(lte ie 8)]></td><![endif]-->
                                                <!--
        -->
                                                <!--
      -->
                                                <!--[if (gte mso 9)|(lte ie 8)]></tr></table><![endif]-->
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- footerBlock -->
        <table role="presentation" class="vb-outer" style="background-color: #4f81bd;" id="ko_footerBlock_2" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#4f81bd">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" style="border-collapse: separate; width: 100%; mso-cellspacing: 0px; border-spacing: 0px; max-width: 570px; -mru-width: 0px;" class="vb-row" width="570" cellspacing="0" cellpadding="0" border="0"></table>
                        </div>
                    </td>
                </tr>

            </tbody>
        </table>
        </div>
        <!--
    -->
        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
        </td>
        </tr>
        </tbody>
        </table>
        <!-- /footerBlock -->

    </center>
</body>

</html>`;













export const resetPasswordContent = `<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="initial-scale=1.0">
    <meta name="format-detection" content="telephone=no">

    <style type="text/css">
        body {
            margin: 0;
            padding: 0;
        }
        
        img {
            border: 0px;
            display: block;
        }
        
        .socialLinks {
            font-size: 6px;
        }
        
        .socialLinks a {
            display: inline-block;
        }
        
        .long-text p {
            margin: 1em 0px;
        }
        
        .long-text p:last-child {
            margin-bottom: 0px;
        }
        
        .long-text p:first-child {
            margin-top: 0px;
        }
    </style>
    <style type="text/css">
        /* yahoo, hotmail */
        
        .ExternalClass,
        .ExternalClass p,
        .ExternalClass span,
        .ExternalClass font,
        .ExternalClass td,
        .ExternalClass div {
            line-height: 100%;
        }
        
        .yshortcuts a {
            border-bottom: none !important;
        }
        
        .vb-outer {
            min-width: 0 !important;
        }
        
        .RMsgBdy,
        .ExternalClass {
            width: 100%;
            background-color: #3f3f3f;
            background-color: #4f81bd
        }
        /* outlook/office365 add buttons outside not-linked images and safari have 2px margin */
        
        [o365] button {
            margin: 0 !important;
        }
        /* outlook */
        
        table {
            mso-table-rspace: 0pt;
            mso-table-lspace: 0pt;
        }
        
        #outlook a {
            padding: 0;
        }
        
        img {
            outline: none;
            text-decoration: none;
            border: none;
            -ms-interpolation-mode: bicubic;
        }
        
        a img {
            border: none;
        }
        
        @media screen and (max-width: 600px) {
            table.vb-container,
            table.vb-row {
                width: 95% !important;
            }
            .mobile-hide {
                display: none !important;
            }
            .mobile-textcenter {
                text-align: center !important;
            }
            .mobile-full {
                width: 100% !important;
                max-width: none !important;
            }
        }
        /* previously used also screen and (max-device-width: 600px) but Yahoo Mail doesn't support multiple queries */
    </style>
    <style type="text/css">
        #ko_singleArticleBlock_13 .links-color a,
        #ko_singleArticleBlock_13 .links-color a:link,
        #ko_singleArticleBlock_13 .links-color a:visited,
        #ko_singleArticleBlock_13 .links-color a:hover {
            color: #3f3f3f;
            color: #3f3f3f;
            text-decoration: underline
        }
        
        #ko_textBlock_7 .links-color a,
        #ko_textBlock_7 .links-color a:link,
        #ko_textBlock_7 .links-color a:visited,
        #ko_textBlock_7 .links-color a:hover {
            color: #3f3f3f;
            color: #3f3f3f;
            text-decoration: underline
        }
        
        #ko_socialBlock_9 .links-color a,
        #ko_socialBlock_9 .links-color a:link,
        #ko_socialBlock_9 .links-color a:visited,
        #ko_socialBlock_9 .links-color a:hover {
            color: #cccccc;
            color: #cccccc;
            text-decoration: underline
        }
        
        #ko_footerBlock_2 .links-color a,
        #ko_footerBlock_2 .links-color a:link,
        #ko_footerBlock_2 .links-color a:visited,
        #ko_footerBlock_2 .links-color a:hover {
            color: #cccccc;
            color: #cccccc;
            text-decoration: underline
        }
    </style>

</head>

<body style="margin: 0; padding: 0; background-color: #4f81bd; color: #1f497d;" vlink="#cccccc" text="#1f497d" bgcolor="#4f81bd" alink="#cccccc">
    <center>

        <table role="presentation" class="vb-outer" style="background-color: #f2f2f2;" id="ko_singleArticleBlock_13" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#f2f2f2">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" class="vb-row" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 9px; border-spacing: 9px; max-width: 570px; -mru-width: 0px;" width="570" cellspacing="9" cellpadding="0" border="0" bgcolor="#ffffff">

                                <tbody>
                                    <tr>
                                        <td style="font-size: 0;" valign="top" align="center">
                                            <div style="vertical-align: top; width: 100%; max-width: 552px; -mru-width: 0px;">
                                                <!--
        -->
                                                <table role="presentation" class="vb-content" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px;" width="552" cellspacing="9" cellpadding="0" border="0">

                                                    <tbody>
                                                        <tr>
                                                            <td class="links-color" style="padding-bottom: 9px;" width="100%" valign="top" align="center">
                                                                <!--[if (lte ie 8)]><div style="display: inline-block; width: 534px; -mru-width: 0px;"><![endif]--><img alt="DHI Group - The expert in Water Environments" style="border: 0px; display: block; vertical-align: top; height: auto; margin: 0 auto; color: #3f3f3f; font-size: 13px; font-family: Arial, Helvetica, sans-serif; width: 100%; max-width: 534px; height: auto;" src="https://mosaico.io/srv/f-7wlhhwv/img?src=https%3A%2F%2Fmosaico.io%2Ffiles%2F7wlhhwv%2Fdhi_logo_pos_tag_rgb.png&amp;method=resize&amp;params=534%2Cnull" width="534" vspace="0" hspace="0" border="0" align="middle">
                                                                <!--[if (lte ie 8)]></div><![endif]-->
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-weight: normal; color: #3f3f3f; font-size: 18px; font-family: Arial, Helvetica, sans-serif; text-align: left;" width="100%" valign="top" align="left"><span style="font-weight: normal;">Reset Password Confirmation</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td class="long-text links-color" style="font-weight: normal; color: #3f3f3f; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: left; line-height: normal;" width="100%" valign="top" align="left">
                                                                <p style="margin: 1em 0px; margin-top: 0px;">&nbsp;</p>
                                                                <p style="margin: 1em 0px;">Dear <strong>{FULLNAME}</strong>,</p>
                                                                <p style="margin: 1em 0px;">&nbsp;</p>
                                                                <p style="margin: 1em 0px; margin-bottom: 0px;">The Admin request to reset your password for DHI Water Tools user account. This action will need for your confirmation. Please click button confirmation below to reset your password and login with reset password.</p>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
        <table role="presentation" class="vb-outer" style="background-color: #f2f2f2;" id="ko_buttonBlock_6" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#f2f2f2">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" class="vb-container" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 18px; border-spacing: 18px; max-width: 570px; -mru-width: 0px;" width="570" cellspacing="18" cellpadding="0" border="0" bgcolor="#ffffff">

                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                            <table role="presentation" style="border-spacing: 0; mso-padding-alt: 12px 12px 12px 12px;" cellspacing="0" cellpadding="12" border="0" align="center">
                                                <tbody>
                                                    <tr>
                                                        <td style="text-align: center; font-weight: normal; padding: 12px; padding-left: 14px; padding-right: 14px; background-color: #0070c0; color: #f2f2f2; font-size: 22px; font-family: Verdana, Geneva, sans-serif; border-radius: 4px;" width="auto" valign="middle" bgcolor="#0070c0" align="center"><a style="text-decoration: none; font-weight: normal; color: #f2f2f2; font-size: 22px; font-family: Verdana, Geneva, sans-serif;" target="_new" href="{LINK}">Confirmation</a></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
        <table role="presentation" class="vb-outer" style="background-color: #f2f2f2;" id="ko_textBlock_7" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#f2f2f2">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" class="vb-container" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 18px; border-spacing: 18px; max-width: 570px; -mru-width: 0px;" width="570" cellspacing="18" cellpadding="0" border="0" bgcolor="#ffffff">

                                <tbody>
                                    <tr>
                                        <td class="long-text links-color" style="font-weight: normal; color: #3f3f3f; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: left; line-height: normal;" width="100%" valign="top" align="left">
                                            <p style="margin: 1em 0px; margin-top: 0px;">Please use login information </p>
                                            <div>&nbsp;</div>
                                            <div style="text-align: center;">Username: <strong>{USERNAME}</strong></div>
                                            <div style="text-align: center;">Password: <strong>{PASSWORD}</strong></div>
                                            <p style="margin: 1em 0px; text-align: center;">&nbsp;</p>
                                            <p style="margin: 1em 0px; text-align: center;"><em><sub><strong>For security reason, please change the automatically generated password once you've logged in.</strong></sub></em></p>
                                            <p style="margin: 1em 0px; text-align: center;">&nbsp;</p>
                                            <p style="margin: 1em 0px; text-align: center;">&nbsp;</p>
                                            <div style="text-align: right; line-height: 1em;"><em><sub>Yours Sincerely,</sub></em>
                                                <br><strong><em><sub>DHI Water Tools Team</sub></em></strong></div>
                                            <div style="text-align: center;">&nbsp;</div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
        <table role="presentation" class="vb-outer" style="background-color: #4f81bd;" id="ko_socialBlock_9" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#4f81bd">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px; max-width: 570px; -mru-width: 0px;" class="vb-row" width="570" cellspacing="9" cellpadding="0" border="0">

                                <tbody>
                                    <tr>
                                        <td style="font-size: 0;" valign="top" align="center">
                                            <div style="width: 100%; max-width: 552px; -mru-width: 0px;">
                                                <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="552"><tr><![endif]-->
                                                <!--
        -->
                                                <!--
          -->
                                                <!--[if (gte mso 9)|(lte ie 8)]><td align="left" valign="top" width="276"><![endif]-->
                                                <!--
      -->
                                                <div class="mobile-full" style="display: inline-block; vertical-align: top; width: 100%; max-width: 276px; -mru-width: 0px; min-width: calc(50%); max-width: calc(100%); width: calc(304704px - 55200%);">
                                                    <!--
        -->
                                                    <table role="presentation" class="vb-content" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px; -yandex-p: calc(2px - 3%);" width="276" cellspacing="9" cellpadding="0" border="0" align="left">

                                                        <tbody>
                                                            <tr>
                                                                <td class="long-text links-color" style="font-weight: normal; color: #1f497d; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: left;" width="100%" valign="top" align="left"></td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    <!--
      -->
                                                </div>
                                                <!--[if (gte mso 9)|(lte ie 8)]></td><![endif]-->
                                                <!--
          -->
                                                <!--[if (gte mso 9)|(lte ie 8)]><td align="left" valign="top" width="276"><![endif]-->
                                                <!--
      -->
                                                <div class="mobile-full" style="display: inline-block; vertical-align: top; width: 100%; max-width: 276px; -mru-width: 0px; min-width: calc(50%); max-width: calc(100%); width: calc(304704px - 55200%);">
                                                    <!--
        -->
                                                    <table role="presentation" class="vb-content" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px; -yandex-p: calc(2px - 3%);" width="276" cellspacing="9" cellpadding="0" border="0" align="left">

                                                        <tbody>
                                                            <tr>
                                                                <td style="font-size: 6px; font-weight: normal; text-align: right;" class="links-color socialLinks mobile-textcenter" width="100%" valign="top" align="right">

                                                                    &nbsp;
                                                                    <a target="_new" href="https://www.facebook.com/DHIgroup" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #3b5998; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/fb-colors-96.png" alt="Facebook" width="32" height="32" border="0"></a>

                                                                    &nbsp;
                                                                    <a target="_new" href="https://twitter.com/dhigroup" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #4099FF; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/tw-colors-96.png" alt="Twitter" width="32" height="32" border="0"></a>

                                                                    &nbsp;
                                                                    <a target="_new" href="https://www.linkedin.com/company/dhi" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #007bb6; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/in-colors-96.png" alt="Linkedin" width="32" height="32" border="0"></a>

                                                                    &nbsp;
                                                                    <a target="_new" href="https://www.youtube.com/user/DHIGroup" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #cd201f; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/you-colors-96.png" alt="Youtube" width="32" height="32" border="0"></a>

                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    <!--
      -->
                                                </div>
                                                <!--[if (gte mso 9)|(lte ie 8)]></td><![endif]-->
                                                <!--
        -->
                                                <!--
      -->
                                                <!--[if (gte mso 9)|(lte ie 8)]></tr></table><![endif]-->
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- footerBlock -->
        <table role="presentation" class="vb-outer" style="background-color: #4f81bd;" id="ko_footerBlock_2" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#4f81bd">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" style="border-collapse: separate; width: 100%; mso-cellspacing: 0px; border-spacing: 0px; max-width: 570px; -mru-width: 0px;" class="vb-row" width="570" cellspacing="0" cellpadding="0" border="0"></table>
                        </div>
                    </td>
                </tr>

            </tbody>
        </table>
        </div>
        <!--
    -->
        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
        </td>
        </tr>
        </tbody>
        </table>
        <!-- /footerBlock -->

    </center>
</body>

</html>`;





















export const forgotPasswordContent = `<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="initial-scale=1.0">
    <meta name="format-detection" content="telephone=no">

    <style type="text/css">
        body {
            margin: 0;
            padding: 0;
        }
        
        img {
            border: 0px;
            display: block;
        }
        
        .socialLinks {
            font-size: 6px;
        }
        
        .socialLinks a {
            display: inline-block;
        }
        
        .long-text p {
            margin: 1em 0px;
        }
        
        .long-text p:last-child {
            margin-bottom: 0px;
        }
        
        .long-text p:first-child {
            margin-top: 0px;
        }
    </style>
    <style type="text/css">
        /* yahoo, hotmail */
        
        .ExternalClass,
        .ExternalClass p,
        .ExternalClass span,
        .ExternalClass font,
        .ExternalClass td,
        .ExternalClass div {
            line-height: 100%;
        }
        
        .yshortcuts a {
            border-bottom: none !important;
        }
        
        .vb-outer {
            min-width: 0 !important;
        }
        
        .RMsgBdy,
        .ExternalClass {
            width: 100%;
            background-color: #3f3f3f;
            background-color: #4f81bd
        }
        /* outlook/office365 add buttons outside not-linked images and safari have 2px margin */
        
        [o365] button {
            margin: 0 !important;
        }
        /* outlook */
        
        table {
            mso-table-rspace: 0pt;
            mso-table-lspace: 0pt;
        }
        
        #outlook a {
            padding: 0;
        }
        
        img {
            outline: none;
            text-decoration: none;
            border: none;
            -ms-interpolation-mode: bicubic;
        }
        
        a img {
            border: none;
        }
        
        @media screen and (max-width: 600px) {
            table.vb-container,
            table.vb-row {
                width: 95% !important;
            }
            .mobile-hide {
                display: none !important;
            }
            .mobile-textcenter {
                text-align: center !important;
            }
            .mobile-full {
                width: 100% !important;
                max-width: none !important;
            }
        }
        /* previously used also screen and (max-device-width: 600px) but Yahoo Mail doesn't support multiple queries */
    </style>
    <style type="text/css">
        #ko_singleArticleBlock_13 .links-color a,
        #ko_singleArticleBlock_13 .links-color a:link,
        #ko_singleArticleBlock_13 .links-color a:visited,
        #ko_singleArticleBlock_13 .links-color a:hover {
            color: #3f3f3f;
            color: #3f3f3f;
            text-decoration: underline
        }
        
        #ko_textBlock_7 .links-color a,
        #ko_textBlock_7 .links-color a:link,
        #ko_textBlock_7 .links-color a:visited,
        #ko_textBlock_7 .links-color a:hover {
            color: #3f3f3f;
            color: #3f3f3f;
            text-decoration: underline
        }
        
        #ko_socialBlock_9 .links-color a,
        #ko_socialBlock_9 .links-color a:link,
        #ko_socialBlock_9 .links-color a:visited,
        #ko_socialBlock_9 .links-color a:hover {
            color: #cccccc;
            color: #cccccc;
            text-decoration: underline
        }
        
        #ko_footerBlock_2 .links-color a,
        #ko_footerBlock_2 .links-color a:link,
        #ko_footerBlock_2 .links-color a:visited,
        #ko_footerBlock_2 .links-color a:hover {
            color: #cccccc;
            color: #cccccc;
            text-decoration: underline
        }
    </style>

</head>

<body style="margin: 0; padding: 0; background-color: #4f81bd; color: #1f497d;" vlink="#cccccc" text="#1f497d" bgcolor="#4f81bd" alink="#cccccc">
    <center>

        <table role="presentation" class="vb-outer" style="background-color: #f2f2f2;" id="ko_singleArticleBlock_13" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#f2f2f2">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" class="vb-row" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 9px; border-spacing: 9px; max-width: 570px; -mru-width: 0px;" width="570" cellspacing="9" cellpadding="0" border="0" bgcolor="#ffffff">

                                <tbody>
                                    <tr>
                                        <td style="font-size: 0;" valign="top" align="center">
                                            <div style="vertical-align: top; width: 100%; max-width: 552px; -mru-width: 0px;">
                                                <!--
        -->
                                                <table role="presentation" class="vb-content" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px;" width="552" cellspacing="9" cellpadding="0" border="0">

                                                    <tbody>
                                                        <tr>
                                                            <td class="links-color" style="padding-bottom: 9px;" width="100%" valign="top" align="center">
                                                                <!--[if (lte ie 8)]><div style="display: inline-block; width: 534px; -mru-width: 0px;"><![endif]--><img alt="DHI Group - The expert in Water Environments" style="border: 0px; display: block; vertical-align: top; height: auto; margin: 0 auto; color: #3f3f3f; font-size: 13px; font-family: Arial, Helvetica, sans-serif; width: 100%; max-width: 534px; height: auto;" src="https://mosaico.io/srv/f-7wlhhwv/img?src=https%3A%2F%2Fmosaico.io%2Ffiles%2F7wlhhwv%2Fdhi_logo_pos_tag_rgb.png&amp;method=resize&amp;params=534%2Cnull" width="534" vspace="0" hspace="0" border="0" align="middle">
                                                                <!--[if (lte ie 8)]></div><![endif]-->
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-weight: normal; color: #3f3f3f; font-size: 18px; font-family: Arial, Helvetica, sans-serif; text-align: left;" width="100%" valign="top" align="left"><span style="font-weight: normal;">Forgot Password Confirmation</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td class="long-text links-color" style="font-weight: normal; color: #3f3f3f; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: left; line-height: normal;" width="100%" valign="top" align="left">
                                                                <p style="margin: 1em 0px; margin-top: 0px;">&nbsp;</p>
                                                                <p style="margin: 1em 0px;">Dear <strong>{FULLNAME}</strong>,</p>
                                                                <p style="margin: 1em 0px;">&nbsp;</p>
                                                                <p style="margin: 1em 0px; margin-bottom: 0px;">You request to reset your password for DHI Water Tools user account. This action will need for your confirmation. Please click button confirmation below to reset your password and login with reset password.</p>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
        <table role="presentation" class="vb-outer" style="background-color: #f2f2f2;" id="ko_buttonBlock_6" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#f2f2f2">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" class="vb-container" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 18px; border-spacing: 18px; max-width: 570px; -mru-width: 0px;" width="570" cellspacing="18" cellpadding="0" border="0" bgcolor="#ffffff">

                                <tbody>
                                    <tr>
                                        <td valign="top" align="center">
                                            <table role="presentation" style="border-spacing: 0; mso-padding-alt: 12px 12px 12px 12px;" cellspacing="0" cellpadding="12" border="0" align="center">
                                                <tbody>
                                                    <tr>
                                                        <td style="text-align: center; font-weight: normal; padding: 12px; padding-left: 14px; padding-right: 14px; background-color: #0070c0; color: #f2f2f2; font-size: 22px; font-family: Verdana, Geneva, sans-serif; border-radius: 4px;" width="auto" valign="middle" bgcolor="#0070c0" align="center"><a style="text-decoration: none; font-weight: normal; color: #f2f2f2; font-size: 22px; font-family: Verdana, Geneva, sans-serif;" target="_new" href="{LINK}">Confirmation</a></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
        <table role="presentation" class="vb-outer" style="background-color: #f2f2f2;" id="ko_textBlock_7" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#f2f2f2">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" class="vb-container" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 18px; border-spacing: 18px; max-width: 570px; -mru-width: 0px;" width="570" cellspacing="18" cellpadding="0" border="0" bgcolor="#ffffff">

                                <tbody>
                                    <tr>
                                        <td class="long-text links-color" style="font-weight: normal; color: #3f3f3f; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: left; line-height: normal;" width="100%" valign="top" align="left">
                                            <p style="margin: 1em 0px; margin-top: 0px;">Please use login information </p>
                                            <div>&nbsp;</div>
                                            <div style="text-align: center;">Username: <strong>{USERNAME}</strong></div>
                                            <div style="text-align: center;">Password: <strong>{PASSWORD}</strong></div>
                                            <p style="margin: 1em 0px; text-align: center;">&nbsp;</p>
                                            <p style="margin: 1em 0px; text-align: center;"><em><sub><strong>For security reason, please change the automatically generated password once you've logged in.</strong></sub></em></p>
                                            <p style="margin: 1em 0px; text-align: center;">&nbsp;</p>
                                            <p style="margin: 1em 0px; text-align: center;">&nbsp;</p>
                                            <div style="text-align: right; line-height: 1em;"><em><sub>Yours Sincerely,</sub></em>
                                                <br><strong><em><sub>DHI Water Tools Team</sub></em></strong></div>
                                            <div style="text-align: center;">&nbsp;</div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
        <table role="presentation" class="vb-outer" style="background-color: #4f81bd;" id="ko_socialBlock_9" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#4f81bd">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px; max-width: 570px; -mru-width: 0px;" class="vb-row" width="570" cellspacing="9" cellpadding="0" border="0">

                                <tbody>
                                    <tr>
                                        <td style="font-size: 0;" valign="top" align="center">
                                            <div style="width: 100%; max-width: 552px; -mru-width: 0px;">
                                                <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="552"><tr><![endif]-->
                                                <!--
        -->
                                                <!--
          -->
                                                <!--[if (gte mso 9)|(lte ie 8)]><td align="left" valign="top" width="276"><![endif]-->
                                                <!--
      -->
                                                <div class="mobile-full" style="display: inline-block; vertical-align: top; width: 100%; max-width: 276px; -mru-width: 0px; min-width: calc(50%); max-width: calc(100%); width: calc(304704px - 55200%);">
                                                    <!--
        -->
                                                    <table role="presentation" class="vb-content" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px; -yandex-p: calc(2px - 3%);" width="276" cellspacing="9" cellpadding="0" border="0" align="left">

                                                        <tbody>
                                                            <tr>
                                                                <td class="long-text links-color" style="font-weight: normal; color: #1f497d; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: left;" width="100%" valign="top" align="left"></td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    <!--
      -->
                                                </div>
                                                <!--[if (gte mso 9)|(lte ie 8)]></td><![endif]-->
                                                <!--
          -->
                                                <!--[if (gte mso 9)|(lte ie 8)]><td align="left" valign="top" width="276"><![endif]-->
                                                <!--
      -->
                                                <div class="mobile-full" style="display: inline-block; vertical-align: top; width: 100%; max-width: 276px; -mru-width: 0px; min-width: calc(50%); max-width: calc(100%); width: calc(304704px - 55200%);">
                                                    <!--
        -->
                                                    <table role="presentation" class="vb-content" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px; -yandex-p: calc(2px - 3%);" width="276" cellspacing="9" cellpadding="0" border="0" align="left">

                                                        <tbody>
                                                            <tr>
                                                                <td style="font-size: 6px; font-weight: normal; text-align: right;" class="links-color socialLinks mobile-textcenter" width="100%" valign="top" align="right">

                                                                    &nbsp;
                                                                    <a target="_new" href="https://www.facebook.com/DHIgroup" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #3b5998; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/fb-colors-96.png" alt="Facebook" width="32" height="32" border="0"></a>

                                                                    &nbsp;
                                                                    <a target="_new" href="https://twitter.com/dhigroup" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #4099FF; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/tw-colors-96.png" alt="Twitter" width="32" height="32" border="0"></a>

                                                                    &nbsp;
                                                                    <a target="_new" href="https://www.linkedin.com/company/dhi" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #007bb6; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/in-colors-96.png" alt="Linkedin" width="32" height="32" border="0"></a>

                                                                    &nbsp;
                                                                    <a target="_new" href="https://www.youtube.com/user/DHIGroup" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #cd201f; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/you-colors-96.png" alt="Youtube" width="32" height="32" border="0"></a>

                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    <!--
      -->
                                                </div>
                                                <!--[if (gte mso 9)|(lte ie 8)]></td><![endif]-->
                                                <!--
        -->
                                                <!--
      -->
                                                <!--[if (gte mso 9)|(lte ie 8)]></tr></table><![endif]-->
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- footerBlock -->
        <table role="presentation" class="vb-outer" style="background-color: #4f81bd;" id="ko_footerBlock_2" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#4f81bd">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" style="border-collapse: separate; width: 100%; mso-cellspacing: 0px; border-spacing: 0px; max-width: 570px; -mru-width: 0px;" class="vb-row" width="570" cellspacing="0" cellpadding="0" border="0"></table>
                        </div>
                    </td>
                </tr>

            </tbody>
        </table>
        </div>
        <!--
    -->
        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
        </td>
        </tr>
        </tbody>
        </table>
        <!-- /footerBlock -->

    </center>
</body>

</html>`;




















export const GrantWorkspaceContent = `<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="initial-scale=1.0">
    <meta name="format-detection" content="telephone=no">

    <style type="text/css">
        body {
            margin: 0;
            padding: 0;
        }
        
        img {
            border: 0px;
            display: block;
        }
        
        .socialLinks {
            font-size: 6px;
        }
        
        .socialLinks a {
            display: inline-block;
        }
        
        .long-text p {
            margin: 1em 0px;
        }
        
        .long-text p:last-child {
            margin-bottom: 0px;
        }
        
        .long-text p:first-child {
            margin-top: 0px;
        }
    </style>
    <style type="text/css">
        /* yahoo, hotmail */
        
        .ExternalClass,
        .ExternalClass p,
        .ExternalClass span,
        .ExternalClass font,
        .ExternalClass td,
        .ExternalClass div {
            line-height: 100%;
        }
        
        .yshortcuts a {
            border-bottom: none !important;
        }
        
        .vb-outer {
            min-width: 0 !important;
        }
        
        .RMsgBdy,
        .ExternalClass {
            width: 100%;
            background-color: #3f3f3f;
            background-color: #4f81bd
        }
        /* outlook/office365 add buttons outside not-linked images and safari have 2px margin */
        
        [o365] button {
            margin: 0 !important;
        }
        /* outlook */
        
        table {
            mso-table-rspace: 0pt;
            mso-table-lspace: 0pt;
        }
        
        #outlook a {
            padding: 0;
        }
        
        img {
            outline: none;
            text-decoration: none;
            border: none;
            -ms-interpolation-mode: bicubic;
        }
        
        a img {
            border: none;
        }
        
        @media screen and (max-width: 600px) {
            table.vb-container,
            table.vb-row {
                width: 95% !important;
            }
            .mobile-hide {
                display: none !important;
            }
            .mobile-textcenter {
                text-align: center !important;
            }
            .mobile-full {
                width: 100% !important;
                max-width: none !important;
            }
        }
        /* previously used also screen and (max-device-width: 600px) but Yahoo Mail doesn't support multiple queries */
    </style>
    <style type="text/css">
        #ko_singleArticleBlock_13 .links-color a,
        #ko_singleArticleBlock_13 .links-color a:link,
        #ko_singleArticleBlock_13 .links-color a:visited,
        #ko_singleArticleBlock_13 .links-color a:hover {
            color: #3f3f3f;
            color: #3f3f3f;
            text-decoration: underline
        }
        
        #ko_textBlock_7 .links-color a,
        #ko_textBlock_7 .links-color a:link,
        #ko_textBlock_7 .links-color a:visited,
        #ko_textBlock_7 .links-color a:hover {
            color: #3f3f3f;
            color: #3f3f3f;
            text-decoration: underline
        }
        
        #ko_socialBlock_9 .links-color a,
        #ko_socialBlock_9 .links-color a:link,
        #ko_socialBlock_9 .links-color a:visited,
        #ko_socialBlock_9 .links-color a:hover {
            color: #cccccc;
            color: #cccccc;
            text-decoration: underline
        }
        
        #ko_footerBlock_2 .links-color a,
        #ko_footerBlock_2 .links-color a:link,
        #ko_footerBlock_2 .links-color a:visited,
        #ko_footerBlock_2 .links-color a:hover {
            color: #cccccc;
            color: #cccccc;
            text-decoration: underline
        }
    </style>

</head>

<body style="margin: 0; padding: 0; background-color: #4f81bd; color: #1f497d;" vlink="#cccccc" text="#1f497d" bgcolor="#4f81bd" alink="#cccccc">
    <center>

        <table role="presentation" class="vb-outer" style="background-color: #f2f2f2;" id="ko_singleArticleBlock_13" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#f2f2f2">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" class="vb-row" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 9px; border-spacing: 9px; max-width: 570px; -mru-width: 0px;" width="570" cellspacing="9" cellpadding="0" border="0" bgcolor="#ffffff">

                                <tbody>
                                    <tr>
                                        <td style="font-size: 0;" valign="top" align="center">
                                            <div style="vertical-align: top; width: 100%; max-width: 552px; -mru-width: 0px;">
                                                <!--
        -->
                                                <table role="presentation" class="vb-content" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px;" width="552" cellspacing="9" cellpadding="0" border="0">

                                                    <tbody>
                                                        <tr>
                                                            <td class="links-color" style="padding-bottom: 9px;" width="100%" valign="top" align="center">
                                                                <!--[if (lte ie 8)]><div style="display: inline-block; width: 534px; -mru-width: 0px;"><![endif]--><img alt="DHI Group - The expert in Water Environments" style="border: 0px; display: block; vertical-align: top; height: auto; margin: 0 auto; color: #3f3f3f; font-size: 13px; font-family: Arial, Helvetica, sans-serif; width: 100%; max-width: 534px; height: auto;" src="https://mosaico.io/srv/f-7wlhhwv/img?src=https%3A%2F%2Fmosaico.io%2Ffiles%2F7wlhhwv%2Fdhi_logo_pos_tag_rgb.png&amp;method=resize&amp;params=534%2Cnull" width="534" vspace="0" hspace="0" border="0" align="middle">
                                                                <!--[if (lte ie 8)]></div><![endif]-->
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-weight: normal; color: #3f3f3f; font-size: 18px; font-family: Arial, Helvetica, sans-serif; text-align: left;" width="100%" valign="top" align="left"><span style="font-weight: normal;">Granted to workspace</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td class="long-text links-color" style="font-weight: normal; color: #3f3f3f; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: left; line-height: normal;" width="100%" valign="top" align="left">
                                                                <p style="margin: 1em 0px; margin-top: 0px;">&nbsp;</p>
                                                                <p style="margin: 1em 0px;">Dear <strong>{FULLNAME}</strong>,</p>
                                                                <p style="margin: 1em 0px;">&nbsp;</p>
                                                                <p style="margin: 1em 0px; margin-bottom: 0px;"><strong>{OWNER}</strong> has granted you access to <strong>{WORKSPACE}</strong></p>
                                                                <p style="margin: 1em 0px; margin-bottom: 0px;">To access <strong>{WORKSPACE}</strong> please follow this <a href={LINK}><strong>Link</strong></a></p>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
                
        <table role="presentation" class="vb-outer" style="background-color: #4f81bd;" id="ko_socialBlock_9" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#4f81bd">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px; max-width: 570px; -mru-width: 0px;" class="vb-row" width="570" cellspacing="9" cellpadding="0" border="0">

                                <tbody>
                                    <tr>
                                        <td style="font-size: 0;" valign="top" align="center">
                                            <div style="width: 100%; max-width: 552px; -mru-width: 0px;">
                                                <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="552"><tr><![endif]-->
                                                <!--
        -->
                                                <!--
          -->
                                                <!--[if (gte mso 9)|(lte ie 8)]><td align="left" valign="top" width="276"><![endif]-->
                                                <!--
      -->
                                                <div class="mobile-full" style="display: inline-block; vertical-align: top; width: 100%; max-width: 276px; -mru-width: 0px; min-width: calc(50%); max-width: calc(100%); width: calc(304704px - 55200%);">
                                                    <!--
        -->
                                                    <table role="presentation" class="vb-content" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px; -yandex-p: calc(2px - 3%);" width="276" cellspacing="9" cellpadding="0" border="0" align="left">

                                                        <tbody>
                                                            <tr>
                                                                <td class="long-text links-color" style="font-weight: normal; color: #1f497d; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: left;" width="100%" valign="top" align="left"></td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    <!--
      -->
                                                </div>
                                                <!--[if (gte mso 9)|(lte ie 8)]></td><![endif]-->
                                                <!--
          -->
                                                <!--[if (gte mso 9)|(lte ie 8)]><td align="left" valign="top" width="276"><![endif]-->
                                                <!--
      -->
                                                <div class="mobile-full" style="display: inline-block; vertical-align: top; width: 100%; max-width: 276px; -mru-width: 0px; min-width: calc(50%); max-width: calc(100%); width: calc(304704px - 55200%);">
                                                    <!--
        -->
                                                    <table role="presentation" class="vb-content" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px; -yandex-p: calc(2px - 3%);" width="276" cellspacing="9" cellpadding="0" border="0" align="left">

                                                        <tbody>
                                                            <tr>
                                                                <td style="font-size: 6px; font-weight: normal; text-align: right;" class="links-color socialLinks mobile-textcenter" width="100%" valign="top" align="right">

                                                                    &nbsp;
                                                                    <a target="_new" href="https://www.facebook.com/DHIgroup" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #3b5998; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/fb-colors-96.png" alt="Facebook" width="32" height="32" border="0"></a>

                                                                    &nbsp;
                                                                    <a target="_new" href="https://twitter.com/dhigroup" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #4099FF; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/tw-colors-96.png" alt="Twitter" width="32" height="32" border="0"></a>

                                                                    &nbsp;
                                                                    <a target="_new" href="https://www.linkedin.com/company/dhi" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #007bb6; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/in-colors-96.png" alt="Linkedin" width="32" height="32" border="0"></a>

                                                                    &nbsp;
                                                                    <a target="_new" href="https://www.youtube.com/user/DHIGroup" style="display: inline-block; color: #cccccc; color: #cccccc; text-decoration: underline;"><img style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px; background: url(https://), #cd201f; border-radius: 50px;" src="https://mosaico.io/templates/versafix-1/img/icons/you-colors-96.png" alt="Youtube" width="32" height="32" border="0"></a>

                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    <!--
      -->
                                                </div>
                                                <!--[if (gte mso 9)|(lte ie 8)]></td><![endif]-->
                                                <!--
        -->
                                                <!--
      -->
                                                <!--[if (gte mso 9)|(lte ie 8)]></tr></table><![endif]-->
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <!--
    -->
                        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- footerBlock -->
        <table role="presentation" class="vb-outer" style="background-color: #4f81bd;" id="ko_footerBlock_2" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#4f81bd">
            <tbody>
                <tr>
                    <td class="vb-outer" style="padding-left: 9px; padding-right: 9px; font-size: 0;" valign="top" align="center">
                        <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]-->
                        <!--
      -->
                        <div style="margin: 0 auto; max-width: 570px; -mru-width: 0px;">
                            <table role="presentation" style="border-collapse: separate; width: 100%; mso-cellspacing: 0px; border-spacing: 0px; max-width: 570px; -mru-width: 0px;" class="vb-row" width="570" cellspacing="0" cellpadding="0" border="0"></table>
                        </div>
                    </td>
                </tr>

            </tbody>
        </table>
        </div>
        <!--
    -->
        <!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
        </td>
        </tr>
        </tbody>
        </table>
        <!-- /footerBlock -->

    </center>
</body>

</html>`;