import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import ImageEditor from '../../../Editor/ImageEditor';
import { modalImageEditorStyle } from '../../../../utils/modal';
import { updateWorkspaceThemeSettingAction } from '../../../../actions/updateWorkspaceThemeSettingAction';
import { updateWorkspaceThemeSettingWithThunk } from '../../../../api/workspacesThemeSettingsThunk';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../../../configurations/routes';
import { defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize } from '../../../../configurations/app';
import { getUserAvailableWorkspacesWithThunk } from '../../../../api/userAvailableWorkspacesThunk';
import UserAvailableWorkspacesContainer from '../../UserAvailableWorkspaces/UserAvailableWorkspacesContainer';
import { updateDialogWorkspaceSettingsErrorAction } from '../../../../actions/updateDialogWorkspaceSettingsErrorAction';
import { updateAvailableWorkspacesOpenedStatus } from '../../../../utils/workspaces';

class WorkspacesThemeSettingsBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            src: null,
            imageEditorIsOpen: false,
            wsListOpened: false,
            firstOpen: true
        };
        this.handleDefaultThemeChange = this.handleDefaultThemeChange.bind(this);
        this.handlePortalThemeChange = this.handlePortalThemeChange.bind(this);
        this.handleCustomThemeChange = this.handleCustomThemeChange.bind(this);
        this.onCustomThemeNameChange = this.onCustomThemeNameChange.bind(this);
        this.handleLockedThemeChange = this.handleLockedThemeChange.bind(this);
        this.onSelectFile = this.onSelectFile.bind(this);
        this.onCloseImageEditor = this.onCloseImageEditor.bind(this);
        this.onCssFileUpload = this.onCssFileUpload.bind(this);
        this.hideList = this.hideList.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        workspaceSetting: PropTypes.object.isRequired,
        dispatchWorkspaceSetting: PropTypes.func.isRequired,
        updateWorkspaceSetting: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired
    };

    availableWorkspacesSwitch() {
        this.setState({ wsListOpened: !this.state.wsListOpened });
        this.setState({ firstOpen: false });
        if (this.state.wsListOpened === false) {
            const { role } = this.props;
            this.props.getUserAvailableWorkspaces(role.user_id, role.user_role, defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize);
        }
        else {
            updateAvailableWorkspacesOpenedStatus(false);
        }
    }

    updateWorkspaceSetting() {
        const { workspaceSetting } = this.props;
        if (workspaceSetting.theme_selected === 'Custom') {
            if (!workspaceSetting.custom_theme_name) {
                let workspace_settings_update_error = {
                    status: 500,
                    dialog_title: 'Update Failed',
                    dialog_type: 'Custom theme name is required',
                    show_dialog: true
                };
                return this.props.dispatchDialogWorkspaceSettingsError(workspace_settings_update_error);
            } 
            else if (!workspaceSetting.custom_theme_css_name) {
                let workspace_settings_update_error = {
                    status: 500,
                    dialog_title: 'Update Failed',
                    dialog_type: 'Custom theme css file is required',
                    show_dialog: true
                };
                return this.props.dispatchDialogWorkspaceSettingsError(workspace_settings_update_error);
            }
        }
        this.props.updateWorkspaceSetting(workspaceSetting);
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    handleDefaultThemeChange() {
        const { workspaceSetting } = this.props;

        let workspace_setting = {
            ...workspaceSetting,
            theme_selected: "Default"
        };

        this.props.dispatchWorkspaceSetting(workspace_setting);
    }

    handlePortalThemeChange(theme_id) {
        const { workspaceSetting } = this.props;

        let workspace_setting = {
            ...workspaceSetting,
            theme_selected: "Portal",
            portal_theme_id: theme_id
        };

        this.props.dispatchWorkspaceSetting(workspace_setting);
    }

    handleCustomThemeChange() {
        const { workspaceSetting } = this.props;

        let workspace_setting = {
            ...workspaceSetting,
            theme_selected: "Custom"
        };

        this.props.dispatchWorkspaceSetting(workspace_setting);
    }

    handleLockedThemeChange() {
        const { workspaceSetting } = this.props;

        let workspace_setting = {
            ...workspaceSetting,
            is_locked: !workspaceSetting.is_locked
        };

        this.props.dispatchWorkspaceSetting(workspace_setting);
    }

    onCustomThemeNameChange(e) {
        const { workspaceSetting } = this.props;

        let workspace_setting = {
            ...workspaceSetting,
            custom_theme_name: e.target.value
        };

        this.props.dispatchWorkspaceSetting(workspace_setting);
    }

    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({
                    src: reader.result, imageEditorIsOpen: true
                })
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onCloseImageEditor(image, aspectRatio) {
        this.setState({ imageEditorIsOpen: false });

        let workspaceSetting = {
            ...this.props.workspaceSetting,
            custom_theme_image: image,
            custom_theme_image_image_aspect_ratio: aspectRatio
        };

        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    onCssFileUpload(e) {
        const { workspaceSetting } = this.props;
        const reader = new FileReader();
        const css_file_name = e.target.files[0].name;
        if (css_file_name.split('.').pop().toLowerCase() === 'css') {
            reader.addEventListener('load', () => {
                let workspace_setting = {
                    ...workspaceSetting,
                    custom_theme_css_name: css_file_name,
                    custom_theme_css: reader.result
                };

                this.props.dispatchWorkspaceSetting(workspace_setting);
            });
            reader.readAsDataURL(e.target.files[0]);
        } else {
            let workspace_settings_update_error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Please provide only css file',
                show_dialog: true
            };
            this.props.dispatchDialogWorkspaceSettingsError(workspace_settings_update_error);
        }
    }

    getAspectRatioClass() {
        const { image_aspect_ratio } = this.props.workspace;

        let aspectRatioClass = 'crop-logo43';
        if (image_aspect_ratio >= 1 && image_aspect_ratio < 1.2) {
            aspectRatioClass = 'crop-logo11';
        }
        else if (image_aspect_ratio >= 1.2 && image_aspect_ratio < 1.4) {
            aspectRatioClass = 'crop-logo43';
        }
        else if (image_aspect_ratio >= 1.4 && image_aspect_ratio < 1.6) {
            aspectRatioClass = 'crop-logo32';
        }
        else if (image_aspect_ratio >= 1.6 && image_aspect_ratio < 2.3) {
            aspectRatioClass = 'crop-logo169';
        }
        else if (image_aspect_ratio >= 2.3) {
            aspectRatioClass = 'crop-logo219';
        }

        return aspectRatioClass;
    }

    hideList() {
        this.setState({ wsListOpened: false });
    }

    render() {
        const { workspace, workspaceSetting, role } = this.props;
        const { src, imageEditorIsOpen } = this.state;

        return (
            <div className="mainBody">
                {(role.user_role === 'Admin' || (role.user_role !== 'Admin' && !workspaceSetting.is_locked)) ?
                    <div className="row mt-5">
                        <div className="col-md-12 order-lg-1 col-lg-8 col-xl-9">
                            <div className="wsHomeSection">
                                <div className="groupForm mb-0">
                                    <label className="btn iconBtn customLockCheckbox">
                                        <input type="checkbox" className="lockInput" onChange={this.handleLockedThemeChange} checked={!workspaceSetting.is_locked} />
                                        <span className="icon-wrd-unlock"></span>
                                        <span className="icon-wrd-lock"></span>
                                    </label>
                                    <label className="sectionTitle">Themes</label>
                                    <hr />
                                    <p>Choose theme you want to use</p>
                                    <div className="row">
                                        <div className="col-sm-4 col-md-4 col-xl-4 mb-5">
                                            <label className="custom-checkbox custom-pick-image">
                                                <input type="radio" name="lpwt" className="custom-control-input"
                                                    onChange={this.handleDefaultThemeChange} checked={(workspaceSetting.theme_selected === 'Default')} />
                                                <div className="imgContainer">
                                                    <img src={process.env.PUBLIC_URL + "/images/Dark-Theme.png"} className="themeImg" alt='' />
                                                </div>
                                                <p><span className="icon-wrd-checkmark"></span> Default Theme</p>
                                            </label>
                                        </div>
                                        {(workspaceSetting.themes !== undefined && workspaceSetting.themes.length > 0) ?
                                            workspaceSetting.themes.map((item) => (
                                                <div key={item.id} className="col-sm-4 col-md-4 col-xl-4 mb-5">
                                                    <label className="custom-checkbox custom-pick-image">
                                                        <input type="radio" name="lpwt" className="custom-control-input"
                                                            onChange={() => this.handlePortalThemeChange(item.id)}
                                                            checked={(workspaceSetting.theme_selected === 'Portal' && workspaceSetting.portal_theme_id === item.id)} />
                                                        <div className="imgContainer">
                                                            <img src={((item.image !== undefined) ? "data:image/png;base64," : "") + item.image}
                                                                className="themeImg" alt="" />
                                                        </div>
                                                        <p><span className="icon-wrd-checkmark"></span> {item.name}</p>
                                                    </label>
                                                </div>
                                            ))
                                            :
                                            'No match'
                                        }
                                        <div className="col-sm-4 col-md-4 col-xl-4 mb-5">
                                            <label className="custom-checkbox custom-pick-image reveal">
                                                <input type="radio" name="lpwt" className="custom-control-input"
                                                    onChange={() => this.handleCustomThemeChange()} checked={(workspaceSetting.theme_selected === 'Custom')} />
                                                <div className="imgContainer">
                                                    <img src={process.env.PUBLIC_URL + "/images/custom-theme.png"} className="themeImg" alt='' />
                                                </div>
                                                <p><span className="icon-wrd-checkmark"></span> Custom Theme
                                                </p>
                                            </label>
                                        </div>
                                    </div>
                                    {(workspaceSetting.theme_selected === 'Custom') ?
                                        <div className="row">
                                            <div className="summernotWrap mx-3 mb-5 w-100">
                                                <div className="sectionBanner p-4 mb-5">
                                                    <div className="row">
                                                        <div className="col-md-12 col-lg-7 col-xl-6">
                                                            <div className="containerBanner p-4 text-center">
                                                                <img src={((workspaceSetting.custom_theme_image !== undefined && workspaceSetting.custom_theme_image !== "") ? "data:image/jpeg;base64," + workspaceSetting.custom_theme_image : process.env.PUBLIC_URL + "/images/no-image.png")}
                                                                    className="bannerDisplay" alt="" />
                                                                <label className="btn btn-wrd_default icoTextBtn browsePhoto m-0 mt-3">
                                                                    <input type="file" onChange={this.onSelectFile} />
                                                                    <span className="icon-wrd-folder"></span>
                                                                    Browse </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 col-lg-5 col-xl-6">
                                                            <div className="formWrap mt-5">
                                                                <div className="inputForm">
                                                                    <input className="form-control" type="text" name="" onChange={this.onCustomThemeNameChange}
                                                                        defaultValue={workspaceSetting.custom_theme_name} required />
                                                                    <label>Theme Name</label>
                                                                </div>
                                                                <div className="d-block text-center">
                                                                    {(workspaceSetting.custom_theme_css_name !== undefined) ?
                                                                        <span className="text-lime">
                                                                            {workspaceSetting.custom_theme_css_name}</span> : null
                                                                    }
                                                                </div>
                                                                <div className="d-block text-center">
                                                                    <label
                                                                        className="btn btn-wrd_default icoTextBtn browsePhoto m-0 mt-3">
                                                                        <input type="file" onChange={this.onCssFileUpload} accept=".css" /><span className="icon-wrd-file-css"></span>
                                                                        Browse Stylesheet
                                                                </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-4 col-xl-3">
                            <ul className="wsLogo pb-5 text-center">
                                <div className="logoContainer wsHome text-center">
                                    <img src={process.env.PUBLIC_URL + "/images/transCircle.png"} className="insideCircle" alt="" />
                                    <img src={((workspace.url_image !== undefined) ? "data:image/png;base64," : "") + workspace.url_image}
                                        className={this.getAspectRatioClass()} alt="Logo" />
                                </div>
                                <ul className="setting-menus mt-5 w-75">
                                    <strong>Settings Options</strong>
                                    <li>
                                        <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/general'}>
                                            <span className="icon-wrd-settings"></span>General
                                    </Link>
                                    </li>
                                    <li>
                                        <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/login'} >
                                            <span className="icon-wrd-log-in"></span> Login
                                    </Link>
                                    </li>
                                    <li>
                                        <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/email'}>
                                            <span className="icon-wrd-mail-closed"></span>Email
                                    </Link>
                                    </li>
                                    <li>
                                        <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/theme'} className="active">
                                            <span className="icon-wrd-themes"></span>Themes
                                    </Link>
                                    </li>
                                </ul>
                                <button className="btn textBtn greenBtn w-75 mt-5" onClick={() => this.updateWorkspaceSetting()}>Update Changes</button>
                            </ul>
                        </div>
                        {src &&
                            <Modal
                                isOpen={imageEditorIsOpen}
                                style={modalImageEditorStyle}
                                contentLabel="Image Editor"
                            >
                                <ImageEditor srcImage={src} onClose={this.onCloseImageEditor} />
                            </Modal>
                        }
                        <div className={(this.state.firstOpen) ? "workspaceList d-none" : (this.state.wsListOpened) ? "workspaceList open animated fadeInRight" : "workspaceList open animated fadeOutRight"}>
                            <UserAvailableWorkspacesContainer hideList={this.hideList} />
                        </div>
                        <button className="btn iconBtn wsListBtn" onClick={() => this.availableWorkspacesSwitch()}>
                            <span className={(this.state.wsListOpened) ? "icon-wrd-close" : "icon-wrd-ws-list"}></span>
                        </button>
                    </div>
                    : null
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, role }) => ({
    workspaceSetting: detail.workspace_theme_setting,
    workspace: detail.workspace,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchWorkspaceSetting: (workspaceSetting) => dispatch(updateWorkspaceThemeSettingAction(workspaceSetting)),
    dispatchDialogWorkspaceSettingsError: (setting_error) => dispatch(updateDialogWorkspaceSettingsErrorAction(setting_error)),
    updateWorkspaceSetting: (workspaceSetting) => dispatch(updateWorkspaceThemeSettingWithThunk(workspaceSetting)),
    getUserAvailableWorkspaces: (userId, userRole, pageIndex, pageSize) => dispatch(getUserAvailableWorkspacesWithThunk(userId, userRole, pageIndex, pageSize))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesThemeSettingsBody));