import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearWorkspaceAction } from '../../../actions/clearWorkspaceAction';
import { getUserWorkspacePropertiesByIdWithThunk } from '../../../api/workspacesThunk';
import { getUrlWorkspaceId } from '../../../utils/url';
import { getSessionStorage } from '../../../utils/sessionStorage';
import Loader from 'react-loader-spinner'
import SidebarContainer from '../../Sidebar/SidebarContainer';
import WorkspacesDetailHeader from '../../Workspaces/Detail/WorkspacesDetailHeader';
import WorkspacesDetailBody from '../../Workspaces/Detail/WorkspacesDetailBody';
import WorkspacesModal from '../Modal/WorkspacesModal';
import WorkspaceModalConnections from '../Modal/WorkspaceModalConnections';
import WorkspacesCreationErrorModal from '../Modal/WorkspacesCreationErrorModal';

class WorkspacesDetailContainer extends Component {
    constructor(props) {
        super(props);
        this.getCurrentWorkspaceId = this.getCurrentWorkspaceId.bind(this);
        this.navigateTo = this.navigateTo.bind(this);
    }

	static propTypes = {
		workspace: PropTypes.object.isRequired,
		getWorkspaceById: PropTypes.func.isRequired,
		clearWorkspace: PropTypes.func.isRequired,
		loading: PropTypes.bool.isRequired,
        role: PropTypes.object.isRequired
	};

	getCurrentWorkspaceId = () => {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
	}

    navigateTo(url) {
        window.location.href = url;
    }

    componentDidMount() {
		const { role } = this.props;
        const id = this.getCurrentWorkspaceId();

        let userId = role.user_id;
        let isAdmin = (role.user_role === "Admin") ? true : false;
        if (userId === "") {
            const sessionRole = getSessionStorage("role");

            if (sessionRole !== "") {
                const role = JSON.parse(sessionRole);
                userId = role.user_id;
                isAdmin = (role.user_role === "Admin") ? true : false;
            }
        }

        this.props.getWorkspaceById(id, userId, isAdmin, this.props.history);
	}

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            const { role } = this.props;
            const id = this.getCurrentWorkspaceId();

            this.props.getWorkspaceById(id, role.user_id, this.props.history);
        }
    }

	render() {
        const { loading, loadingTheme, workspace } = this.props;
		return (
			<div>
                {(loading || loadingTheme || workspace.id === "") &&
					<div className="loader-container">
						<Loader
							type="Ball-Triangle"
							color="#00BFFF"
							height="100"
							width="100"
						/>
					</div>
                }
                {!loadingTheme && workspace.id !== "" &&
                    <div>
                        <SidebarContainer />
                        <main>
                            <div className="mapBackground" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <WorkspacesDetailHeader />
                                    <WorkspacesDetailBody />
                                </div>
                            </div>
                        </main>
                        <WorkspacesModal type="detail" />
                        <WorkspacesCreationErrorModal type="error" navigateTo={this.navigateTo} />
                        <WorkspaceModalConnections type="detail" />
                    </div>
                }
			</div>
		);
	}
}

const mapStateToProps = ({ detail, loading, role }) => ({
	workspace: detail.workspace,
	loading: loading.workspace.loading,
    loadingTheme: loading.theme.loading,
	role,
	loadingContactOwners: loading.contactOwners.loading
});

const mapDispatchToProps = (dispatch) => ({
    getWorkspaceById: (workspaceId, userId, isAdmin, historyProps) => dispatch(getUserWorkspacePropertiesByIdWithThunk(workspaceId, userId, isAdmin, historyProps)),
	clearWorkspace: () => dispatch(clearWorkspaceAction([]))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesDetailContainer));