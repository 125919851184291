import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import GridContainer from '../Grid/GridContainer';
import { getUserHeaders } from '../../../utils/users';
import { defaultUsersSortingBy, defaultUsersSortingType, defaultUsersPageSize } from '../../../configurations/app';
import { addWorkspacesUsersWithThunk, getWorkspacesUsersSearchWithThunk } from '../../../api/globalUsersThunk';
import { getWorkspacesMembersSearchAction } from '../../../actions/getWorkspacesMembersSearchAction';
import { clearUsersAction } from '../../../actions/clearUsersAction';

class GlobalUsersListBody extends Component {
    constructor(props) {
        super(props);
        this.backToPreviousPage = this.backToPreviousPage.bind(this);
        this.updateMembers = this.updateMembers.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        users: PropTypes.array.isRequired,
        updateWorkspacesMembers: PropTypes.func.isRequired,
        updateMode: PropTypes.func.isRequired
    };

    calculatePageSize() {
        let size = defaultUsersPageSize;

        this.setState({ page: 1, size });
        return size;
    }

    handleSearch(e) {
        const { workspace, search } = this.props;
        if (e.key === "Enter" || e.target.name !== 'searchText') {
            const size = this.calculatePageSize();
            this.props.clearUsers();
            this.props.getWorkspacesMembersSearch(workspace.id, 1, size, search.search_text, defaultUsersSortingBy, defaultUsersSortingType);
            this.props.updateMode(1, 'search');
        }
    }

    handleOnChange(e) {
        let search = {
            ...this.props.member,
            search_text: e.target.value
        };
        this.props.dispatchWorkspacesMembersSearch(search);
    }

    backToPreviousPage() {
        this.props.history.goBack()
    }

    updateMembers() {
        const { workspace, globalUsers, routeBack } = this.props;

        if (globalUsers.length > 0) {
            this.props.updateWorkspacesMembers(workspace.id, globalUsers, routeBack.url, this.props.history);
        }
        else {
            this.props.history.push(routeBack.url);
        }
    }

    componentWillUnmount(){
        let search = {
            ...this.props.member,
            search_text: ''
        };
        this.props.dispatchWorkspacesMembersSearch(search);
    }

    render() {
        const { users, search } = this.props;
        const headers = getUserHeaders();

        return (
            <div className="mainBody">
                <div className="row mt-4">
                    <div className=" col-xl-12">
                        <div className="userSection">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-9">
                                    <button className="btn icoTextBtn btn-wrd_default mr-3 greyBtn" onClick={() => this.backToPreviousPage()}>
                                        <span className="icon-wrd-arrow-left"></span>
                                        Back
                                    </button>
                                    <button className="btn icoTextBtn btn-wrd_default" onClick={() => this.updateMembers()}>
                                        <span className="icon-wrd-share"></span>
                                        Update Member(s)
                                    </button>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-3 text-right">
                                    <div className="searchInput">
                                        <span style={{ cursor: 'pointer' }} className="icon-wrd-search" onClick={this.handleSearch}></span>
                                        <input type="text" onKeyPress={this.handleSearch} name="searchText" value={search.search_text} placeholder="Search..." onChange={this.handleOnChange} />
                                    </div>
                                </div>
                            </div>
                            <GridContainer headers={headers} items={users} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, users, globalUsers, routeBack, search }) => ({
    workspace: detail.workspace,
    users,
    globalUsers,
    routeBack: routeBack.global_users,
    search: search.member
});

const mapDispatchToProps = (dispatch) => ({
    dispatchWorkspacesMembersSearch: (data) => dispatch(getWorkspacesMembersSearchAction(data)),
    updateWorkspacesMembers: (workspaceId, members, url, history) => dispatch(addWorkspacesUsersWithThunk(workspaceId, members, url, history)),
    getWorkspacesMembersSearch: (workspaceId, pageIndex, pageSize, searchKeyword, SortingBy, SortingType) => dispatch(getWorkspacesUsersSearchWithThunk(workspaceId, pageIndex, pageSize, searchKeyword, SortingBy, SortingType)),
    clearUsers: () => dispatch(clearUsersAction([]))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GlobalUsersListBody));