import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearWorkspacesApplicationsSettingsAction } from '../../../../actions/clearWorkspacesApplicationsSettingsAction';
import { getWorkspacesApplicationsSettingsWithThunk, getWorkspacesApplicationsSettingsByIdWithThunk } from '../../../../api/workspacesApplicationsSettingsThunk';
import Loader from 'react-loader-spinner'
import SidebarContainer from '../../../Sidebar/SidebarContainer';
import WorkspacesApplicationsSettingsHeader from './WorkspacesApplicationsSettingsHeader';
import WorkspacesApplicationsSettingsBody from './WorkspacesApplicationsSettingsBody';
import ErrorSuccessModal from '../../../Shared/ErrorSuccessModal';
import { getSessionStorage } from '../../../../utils/sessionStorage';

class WorkspacesApplicationsSettingsContainer extends Component {
    static propTypes = {
        workspace: PropTypes.object.isRequired,
        workspaceApplication: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired,
        getWorkspacesApplicationsSettings: PropTypes.func.isRequired,
        getWorkspacesApplicationsSettingsById: PropTypes.func.isRequired,
        clearWorkspaceApplicationsSettings: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired
    };

    componentDidMount() {
        const { workspace, role, loading } = this.props;
        if (loading === false) {
            const workspaceId = this.props.match.params.id
            const applicationId = this.props.match.params.applicationid

            if (workspaceId !== workspace.workspace_name.toLowerCase().replaceAll(" ", "+")) {
                let userId = role.user_id;
                if (userId === "") {
                    const sessionRole = getSessionStorage("role");

                    if (sessionRole !== "") {
                        const role = JSON.parse(sessionRole);
                        userId = role.user_id;
                    }
                }
                this.props.getWorkspacesApplicationsSettingsById(workspaceId, applicationId, userId);
            }
            else {
                this.props.getWorkspacesApplicationsSettings(workspaceId, applicationId);
            }
        }
    }

    render() {
        const { workspace, workspaceApplication, loading, loadingTheme } = this.props;

        if ((workspace.id === undefined || workspace.id === '') &&
            (workspaceApplication.application_id === undefined || workspaceApplication.application_id === ''))
            return (
                <div>
                    {(loading || loadingTheme) &&
                        <div className="loader-container">
                            <Loader
                                type="Ball-Triangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                </div>
            );

        return (
            <div>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <SidebarContainer />
                        <main>
                            <div className="mapBackground" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <WorkspacesApplicationsSettingsHeader />
                                    <WorkspacesApplicationsSettingsBody />
                                </div>
                            </div>
                        </main>
                        <ErrorSuccessModal type="error" />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, role, loading }) => ({
    workspace: detail.workspace,
    workspaceApplication: detail.workspace_application,
    role,
    loading: loading.workspaces_applications_settings.loading,
    loadingTheme: loading.theme.loading
});

const mapDispatchToProps = (dispatch) => ({
    getWorkspacesApplicationsSettings: (workspaceId, applicationId) => dispatch(getWorkspacesApplicationsSettingsWithThunk(workspaceId, applicationId)),
    getWorkspacesApplicationsSettingsById: (workspaceId, applicationId, userId) => dispatch(getWorkspacesApplicationsSettingsByIdWithThunk(workspaceId, applicationId, userId)),
    clearWorkspaceApplicationsSettings: () => dispatch(clearWorkspacesApplicationsSettingsAction([]))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsSettingsContainer));