import environment from 'environment';
import { v1 as uuidv1 } from 'uuid';
import { getImageSize, b64toBlob } from '../../utils/snapshot';
import { getDefaultImage, getDefaultWelcomeImage, getDefaultLoginImage} from '../../utils/image';
import { getDefaultWelcomeText } from '../../utils/text';

const config = require('../../environments/' + environment.settings);
const configImage = require('../../configurations/images');
const portalApi = require('../api/portal');
const workspaceSettings = require('../domain/workspacesSettings');
const sort = require('fast-sort');

export async function pingServer(url) {
    let paths = getPathArray(url, '/');
    let server = "";
    let port = 80;

    if (paths.length >= 3) {
        let domains = getPathArray(paths[2], ':');

        if (domains.length >= 2) {
            server = domains[0];
            port = parseInt(domains[1], 10);
        }
        else {
            server = paths[2];
        }

        if (server.includes(config.portalServer)) {
            server = paths[3];
            if (parseInt(port, 10) > 13000) {
                server = server + '-staging';
            }
        }
    }

    if (server.includes("?")) {
        let servers = getPathArray(server, '?');
        if (servers.length > 0) {
            server = servers[0];
        }
    }

    return portalApi.getApplicationAvailability(server, port);
}

function getPathArray(pathName, splitChar) {
    let pathArray = [];
    let paths = pathName.split(splitChar);

    if (paths.length >= 1) {
        pathArray = paths;
    }

    return pathArray;
}

/* Banner */
export async function getPortalSettingBannerApi() {
    const results = await portalApi.getPortalBanner(false, false);
    return await mapToPortalBanner(results.data);
}

export async function getPortalLandingBannerApi() {
    const results = await portalApi.getPortalBanner(true, false);
    return await mapToPortalBanner(results.data);
}

export async function addPortalSettingBannerApi(bannerSetting) {
    let dataMapping = await mapToPortalBannerForCreate(bannerSetting);
    const results = await portalApi.addPortalBanner(dataMapping);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        id = results.data.replace("banner:", "");
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

export async function updatePortalSettingBannerApi(bannerSetting) {
    let dataMapping = await mapToPortalBannerForUpdate(bannerSetting);
    const results = await portalApi.updatePortalBanner(bannerSetting.id, dataMapping);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        id = results.data;
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

export async function removePortalSettingBannerApi(bannerId) {
    const results = await portalApi.removePortalBanner(bannerId);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        id = results.data;
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    };

    return data;
}

/* Feature */
export async function getPortalSettingSingleFeatureApi(id) {
    const results = await portalApi.getPortalSingleFeature(id);
    return results;
}

export async function getPortalSettingFeatureApi() {
    const results = await portalApi.getPortalFeature(false, false);
    return await mapToPortalFeature(results.data);
}

export async function getPortalLandingFeatureApi() {
    const results = await portalApi.getPortalFeature(true, false);
    return await mapToPortalFeature(results.data);
}

export async function getPortalSingleFeatureApi(id) {
    const results = await portalApi.getPortalSingleFeature(id);
    return results;
}

export async function addPortalSettingFeatureApi(featureSetting) {
    let dataMapping = await mapToPortalFeatureForCreate(featureSetting);
    const results = await portalApi.addPortalFeature(dataMapping);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        id = results.data.replace("feature:", "");
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

export async function updatePortalSettingFeatureApi(featureSetting) {
    let dataMapping = await mapToPortalFeatureForUpdate(featureSetting);
    const results = await portalApi.updatePortalFeature(featureSetting.id, dataMapping);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        id = results.data;
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

export async function removePortalSettingFeatureApi(featureId) {
    const results = await portalApi.removePortalFeature(featureId);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        id = results.data;
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    };

    return data;
}

/* News */
export async function getPortalSettingSingeNewsApi(id) {
    const results = await portalApi.getPortalSingleNews(id);
    return results;
}

export async function getPortalSettingNewsApi() {
    const results = await portalApi.getPortalNews(false, false);
    return await mapToPortalNews(results.data);
}

export async function getPortalLandingNewsApi() {
    const results = await portalApi.getPortalNews(true, false);
    return await mapToPortalNews(results.data);
}

export async function getPortalSingleNewsApi(id) {
    const results = await portalApi.getPortalSingleNews(id);
    return results;
}

export async function addPortalSettingNewsApi(newsSetting) {
    let dataMapping = await mapToPortalNewsForCreate(newsSetting);
    const results = await portalApi.addPortalNews(dataMapping);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        id = results.data.replace("news:", "");
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

export async function updatePortalSettingNewsApi(newsSetting) {
    let dataMapping = await mapToPortalNewsForUpdate(newsSetting);
    const results = await portalApi.updatePortalNews(newsSetting.id, dataMapping);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        id = results.data;
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

export async function removePortalSettingNewsApi(newsId) {
    const results = await portalApi.removePortalNews(newsId);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        id = results.data;
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    };

    return data;
}

/* Login */
export async function getPortalSettingLoginApi() {
    const results = await portalApi.getPortalLogin(false);
    let resultsMapping = await mapToPortalLogin(results.data);
    return resultsMapping;
}

export async function getPortalLoginApi(id) {
    let wsSetting = {};
    let portalResults = await portalApi.getPortalLogin(false);
    let portalSetting = await mapToPortalLogin(portalResults.data);

    if (id !== '') {
        wsSetting = await workspaceSettings.getWorkspaceLoginSettingByIdApi(id);
    }

    let results = await mapToPortalLoginImplement(wsSetting, portalSetting);
    return results;
}

export async function updatePortalSettingLoginApi(loginSetting) {
    let dataMapping = await mapToPortalLoginForUpdate(loginSetting);
    const results = await portalApi.updatePortalLogin(dataMapping);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        id = results.data;
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

/* Email */
export async function getPortalSettingEmailApi() {
    const results = await portalApi.getPortalEmail();
    let resultsMapping = await mapToPortalEmailSetting(results.data);
    return resultsMapping;
}

export async function updatePortalSettingEmailApi(emailSetting) {
    let dataMapping = await mapToPortalEmailSettingBackendForUpdate(emailSetting);
    const results = await portalApi.updatePortalEmail(dataMapping);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        id = results.data;
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    };

    return data;
}

/* Theme */
export async function getPortalSettingThemeApi() {
    const results = await portalApi.getPortalTheme(false);
    return await mapToPortalTheme(results.data);
}

export async function addPortalSettingThemeApi(themeSetting) {
    let dataMapping = await mapToPortalThemeForCreate(themeSetting);
    const results = await portalApi.addPortalTheme(dataMapping);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        id = results.data.replace("theme:", "");
        if (themeSetting.theme_css) {
            let filePath = uuidv1() + ".css";
            const blob64 = b64toBlob(themeSetting.theme_css.replace('data:text/css;base64,', ''), 'text/css');
            await processPutCss(blob64, filePath, id);
        }
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

export async function updatePortalSettingThemeApi(themeSetting) {
    let dataMapping = await mapToPortalThemeForUpdate(themeSetting);
    const results = await portalApi.updatePortalTheme(themeSetting.id, dataMapping);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        id = results.data.replace("theme:", "");
        if (themeSetting.theme_css) {
            let filePath = uuidv1() + ".css";
            const blob64 = b64toBlob(themeSetting.theme_css.replace('data:text/css;base64,', ''), 'text/css');
            await processPutCss(blob64, filePath, id);
        }
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

async function processPutCss(blob64, filePath, id) {
    const form = new FormData();
    form.append('styleFile', blob64, {
        filepath: filePath,
        contentType: 'text/css',
    });

    const results = await portalApi.addPortalThemeFile(id, form);
    if (results.data !== undefined) {
        return results.data;
    }
}

export async function removePortalSettingThemeApi(themeId) {
    const results = await portalApi.removePortalTheme(themeId);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        id = results.data;
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[0];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    };

    return data;
}

async function mapToPortalBanner(data) {
    let results = {};
    let banners = [];
    if (data !== undefined) {
        sort(data).desc(['id']);
        for (let i in data) {
            let ban = data[i];

            let imageProps = (ban.image !== undefined) ? getImageSize(ban.image) : {
                'width': 1200,
                'height': 900
            };
            let banner = {
                id: ban.id.toString(),
                image: (ban.image === undefined) ? getDefaultImage() : ban.image,
                image_width: imageProps.width,
                image_height: imageProps.height,
                image_aspect_ratio: imageProps.width / imageProps.height,
                banner_title: ban.title,
                banner_caption: ban.caption,
                status: (ban.enabled) ? 'Enabled' : 'Disabled'
            };
            banners.push(banner);
        }
    }
    results.banners = banners;

    return results;
}

async function mapToPortalBannerForCreate(data) {
    let imageDimension = getImageDimension(data.image);

    let results = {
        title: data.banner_title,
        caption: data.banner_caption,
        enabled: true,
        bannerImage: {
            image: data.image,
            imageSize: {
                width: imageDimension.width,
                height: imageDimension.height
            },
            thumbnailSize: {
                width: Math.ceil(imageDimension.width / 10),
                height: Math.ceil(imageDimension.height / 10)
            },
            resultFormat: configImage.resultFormat
        }
    };

    return results;
}

async function mapToPortalBannerForUpdate(data) {
    let imageDimension = getImageDimension(data.image);

    let results = {
        title: data.banner_title,
        caption: data.banner_caption,
        enabled: (data.status === 'Enabled') ? true : false,
        bannerImage: {
            image: data.image,
            imageSize: {
                width: imageDimension.width,
                height: imageDimension.height
            },
            thumbnailSize: {
                width: Math.ceil(imageDimension.width / 10),
                height: Math.ceil(imageDimension.height / 10)
            },
            resultFormat: configImage.resultFormat
        }
    };

    return results;
}

async function mapToPortalFeature(data) {
    let results = {};
    let features = [];
    if (data !== undefined) {
        sort(data.results).desc(['id']);
        for (let i in data.results) {
            let feat = data.results[i];

            let imageProps = (feat.image !== undefined) ? getImageSize(feat.image) : {
                'width': 1200,
                'height': 900
            };
            let feature = {
                id: feat.id.toString(),
                image: (feat.image === undefined) ? getDefaultImage() : feat.image,
                image_width: imageProps.width,
                image_height: imageProps.height,
                image_aspect_ratio: imageProps.width / imageProps.height,
                feature_title: feat.title,
                feature_description: feat.description,
                status: (feat.enabled) ? 'Enabled' : 'Disabled'
            };
            features.push(feature);
        }
    }
    results.features = features;

    return results;
}

async function mapToPortalFeatureForCreate(data) {
    let imageDimension = getImageDimension(data.image);

    let results = {
        title: data.feature_title,
        description: data.feature_description,
        enabled: true,
        featureImage: {
            image: data.image,
            imageSize: {
                width: imageDimension.width,
                height: imageDimension.height
            },
            thumbnailSize: {
                width: Math.ceil(imageDimension.width / 10),
                height: Math.ceil(imageDimension.height / 10)
            },
            resultFormat: configImage.resultFormat
        }
    };

    return results;
}

async function mapToPortalFeatureForUpdate(data) {
    let imageDimension = getImageDimension(data.image);

    let results = {
        title: data.feature_title,
        description: data.feature_description,
        enabled: (data.status === 'Enabled') ? true : false,
        featureImage: {
            image: data.image,
            imageSize: {
                width: imageDimension.width,
                height: imageDimension.height
            },
            thumbnailSize: {
                width: Math.ceil(imageDimension.width / 10),
                height: Math.ceil(imageDimension.height / 10)
            },
            resultFormat: configImage.resultFormat
        }
    };

    return results;
}

async function mapToPortalNews(data) {
    let results = {};
    let newsObjectArray = [];
    if (data !== undefined) {
        sort(data.results).desc(['id']);
        for (let i in data.results) {
            let news = data.results[i];

            let imageProps = (news.image !== undefined) ? getImageSize(news.image) : {
                'width': 1200,
                'height': 900
            };
            let newsObject = {
                id: news.id.toString(),
                image: (news.image === undefined) ? getDefaultImage() : news.image,
                image_width: imageProps.width,
                image_height: imageProps.height,
                image_aspect_ratio: imageProps.width / imageProps.height,
                news_title: news.title,
                news_description: news.content,
                status: (news.enabled) ? 'Enabled' : 'Disabled'
            };
            newsObjectArray.push(newsObject);
        }
    }
    results.news = newsObjectArray;

    return results;
}

async function mapToPortalNewsForCreate(data) {
    let imageDimension = getImageDimension(data.image);

    let results = {
        title: data.news_title,
        content: data.news_description,
        enabled: true,
        newsImage: {
            image: data.image,
            imageSize: {
                width: imageDimension.width,
                height: imageDimension.height
            },
            thumbnailSize: {
                width: Math.ceil(imageDimension.width / 10),
                height: Math.ceil(imageDimension.height / 10)
            },
            resultFormat: configImage.resultFormat
        }
    };

    return results;
}

async function mapToPortalNewsForUpdate(data) {
    let imageDimension = getImageDimension(data.image);

    let results = {
        title: data.news_title,
        content: data.news_description,
        enabled: (data.status === 'Enabled') ? true : false,
        newsImage: {
            image: data.image,
            imageSize: {
                width: imageDimension.width,
                height: imageDimension.height
            },
            thumbnailSize: {
                width: Math.ceil(imageDimension.width / 10),
                height: Math.ceil(imageDimension.height / 10)
            },
            resultFormat: configImage.resultFormat
        }
    };

    return results;
}

async function mapToPortalLogin(portalData) {
    let results = {
        welcome_text_selected: portalData.welcomeTextSelected,
        welcome_text: (portalData.welcomeText !== undefined) ? portalData.welcomeText : getDefaultWelcomeText(),
        welcome_background_selected: portalData.welcomeBackgroundSelected,
        welcome_background_color: (portalData.welcomeBackgroundColor !== undefined) ? portalData.welcomeBackgroundColor : '#EEEEEE',
        welcome_background_custom_image: (portalData.welcomeBackgroundImage !== undefined) ? portalData.welcomeBackgroundImage : getDefaultWelcomeImage(),
        welcome_background_custom_image_aspect_ratio: (portalData.welcomeBackgroundImage !== undefined) ? await getImageAspecRatio(portalData.welcomeBackgroundImage) : await getImageAspecRatio(getDefaultWelcomeImage()),
        login_background_selected: portalData.loginBackgroundSelected,
        login_background_color: (portalData.loginBackgroundColor !== undefined) ? portalData.loginBackgroundColor : '#373B56',
        login_background_custom_image: (portalData.loginBackgroundImage !== undefined) ? portalData.loginBackgroundImage : getDefaultLoginImage(),
        login_background_custom_image_aspect_ratio: (portalData.loginBackgroundImage !== undefined) ? await getImageAspecRatio(portalData.loginBackgroundImage) : await getImageAspecRatio(getDefaultLoginImage())
    };

    return results;
}

async function mapToPortalLoginForUpdate(portalData) {
    let welcomeDimension = getImageDimension(portalData.welcome_background_custom_image);
    let loginDimension = getImageDimension(portalData.login_background_custom_image);

    let results = {
        welcomeTextSelected: portalData.welcome_text_selected,
        welcomeText: portalData.welcome_text,
        welcomeBackgroundSelected: portalData.welcome_background_selected,
        welcomeBackgroundColor: portalData.welcome_background_color,
        welcomeBackgroudImage: {
            image: portalData.welcome_background_custom_image,
            imageSize: {
                width: welcomeDimension.width,
                height: welcomeDimension.height
            },
            thumbnailSize: {
                width: Math.ceil(welcomeDimension.width / 10),
                height: Math.ceil(welcomeDimension.height / 10)
            },
            resultFormat: configImage.resultFormat
        },
        loginBackgroundSelected: portalData.login_background_selected,
        loginBackgroundColor: portalData.login_background_color,
        loginBackgroundImage: {
            image: portalData.login_background_custom_image,
            imageSize: {
                width: loginDimension.width,
                height: loginDimension.height
            },
            thumbnailSize: {
                width: Math.ceil(loginDimension.width / 10),
                height: Math.ceil(loginDimension.height / 10)
            },
            resultFormat: configImage.resultFormat
        }
    };

    return results;
}

async function mapToPortalEmailSetting(portalSettingData) {
    let portalData = portalSettingData;
    let results = {};
    let emails = [];
    for (let i in portalData) {
        let p = portalData[i];
        let email = {
            id: (p.type === 'Registration' ? 0 : p.type === 'RegistrationReminder' ? 1 : p.type === 'ResetPassword' ? 2 : p.type === 'ForgotPassword' ? 3 : p.type === 'GrantWorkspace' ? 4 : null),
            email_type: p.type,
            sender_from: (p.from !== undefined) ? p.from : '',
            email_subject: (p.subject !== undefined) ? p.subject : '',
            email_content: (p.content !== undefined) ? p.content : ''
        };
        emails.push(email);
    }

    sort(emails).asc(['id']);

    results.emails = emails;

    return results;
}

async function mapToPortalEmailSettingBackendForUpdate(data) {
    let emails = [];
    for (let i in data.emails) {
        let p = data.emails[i];
        let email = {};
        email = {
            type: p.email_type,
            from: p.sender_from,
            subject: p.email_subject,
            content: p.email_content
        };

        emails.push(email);
    }

    return emails;
}

async function mapToPortalTheme(data) {
    let results = {};
    let themes = [];
    if (data !== undefined) {
        sort(data).desc(['id']);
        for (let i in data) {
            let a = data[i];

            let imageProps = (a.image !== undefined) ? getImageSize(a.image) : {
                'width': 1200,
                'height': 900
            };
            let theme = {
                id: a.id.toString(),
                image: (a.image === undefined) ? getDefaultImage() : a.image,
                image_width: imageProps.width,
                image_height: imageProps.height,
                image_aspect_ratio: imageProps.width / imageProps.height,
                theme_name: a.name,
                theme_css_file: (a.filePath !== undefined) ? a.filePath : '',
                status: (a.enabled) ? 'Enabled' : 'Disabled'
            };
            themes.push(theme);
        }
    }
    results.themes = themes;

    return results;
}

async function mapToPortalThemeForCreate(data) {
    let imageDimension = getImageDimension(data.image);

    let results = {
        name: data.theme_name,
        filePath: data.theme_css_file,
        enabled: true,
        themeImage: {
            image: data.image,
            imageSize: {
                width: imageDimension.width,
                height: imageDimension.height
            },
            thumbnailSize: {
                width: Math.ceil(imageDimension.width / 1),
                height: Math.ceil(imageDimension.height / 1)
            },
            resultFormat: configImage.resultFormat
        }
    };

    return results;
}

async function mapToPortalThemeForUpdate(data) {
    let imageDimension = getImageDimension(data.image);

    let results = {
        name: data.theme_name,
        filePath: data.theme_css_file,
        enabled: (data.status === 'Enabled') ? true : false,
        themeImage: {
            image: data.image,
            imageSize: {
                width: imageDimension.width,
                height: imageDimension.height
            },
            thumbnailSize: {
                width: Math.ceil(imageDimension.width / 1),
                height: Math.ceil(imageDimension.height / 1)
            },
            resultFormat: configImage.resultFormat
        }
    };

    return results;
}

async function mapToPortalLoginImplement(wsData, portalData) {
    let results = {};
    if (wsData.id !== undefined) {
        results = {
            id: wsData.id,
            welcome_text_selected: (wsData.welcome_text_selected === 'Default') ? portalData.welcome_text_selected : wsData.welcome_text_selected,
            welcome_text: (wsData.welcome_text_selected === 'Default') ? portalData.welcome_text : wsData.welcome_text,
            welcome_background_selected: (wsData.welcome_background_selected === 'Default') ? portalData.welcome_background_selected : wsData.welcome_background_selected,
            welcome_background_color: (wsData.welcome_text_selected === 'Default') ? portalData.welcome_background_color : wsData.welcome_background_color,
            welcome_background_image: (wsData.welcome_background_selected === 'Default') ? portalData.welcome_background_custom_image : wsData.welcome_background_custom_image,
            welcome_background_image_aspect_ratio: (wsData.welcome_background_selected === 'Default') ? portalData.welcome_background_image_aspect_ratio : wsData.welcome_background_image_aspect_ratio,
            login_background_selected: (wsData.login_background_selected === 'Default') ? portalData.login_background_selected : wsData.login_background_selected,
            login_background_color: (wsData.login_background_selected === 'Default') ? portalData.login_background_color : wsData.login_background_color,
            login_background_image: (wsData.login_background_selected === 'Default') ? portalData.login_background_custom_image : wsData.login_background_custom_image,
            login_background_image_aspect_ratio: (wsData.login_background_selected === 'Default') ? portalData.login_background_image_aspect_ratio : wsData.login_background_image_aspect_ratio
        };
    } else {
        results = {
            id: '',
            welcome_text_selected: portalData.welcome_text_selected,
            welcome_text: portalData.welcome_text,
            welcome_background_selected: portalData.welcome_background_selected,
            welcome_background_color: portalData.welcome_background_color,
            welcome_background_image: portalData.welcome_background_custom_image,
            welcome_background_image_aspect_ratio: portalData.welcome_background_custom_image_aspect_ratio,
            login_background_selected: portalData.login_background_selected,
            login_background_color: portalData.login_background_color,
            login_background_image: portalData.login_background_custom_image,
            login_background_image_aspect_ratio: portalData.login_background_custom_image_aspect_ratio
        };
    }

    return results;
}

async function getImageAspecRatio(image) {
    let imageProps = (image !== undefined && image !== '') ?
        getImageSize(image) : {
            'width': 0,
            'height': 0
        };

    return imageProps.width / imageProps.height;
}

function getImageDimension(image) {
    let imageProps = (image !== undefined && image !== '') ?
        getImageSize(image) : {
            'width': 0,
            'height': 0
        };

    return imageProps;
}