import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDialogWorkspacesApplicationsAction } from '../../../../actions/updateDialogWorkspacesApplicationsAction';
import { updateWorkspaceApplicationLinkStatusWithThunk } from '../../../../api/workspacesListThunk';

class WorkspacesModal extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        dialog: PropTypes.object.isRequired,
        workspace: PropTypes.object.isRequired,
        dispatchDialogWorkspacesApplications: PropTypes.func.isRequired,
        updateWorkspaceApplicationLinkStatus: PropTypes.func.isRequired
    };

    setDefaultDialogState() {
        let workspacesApplications = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            route_back_to: '',
            show_dialog: false
        };

        this.props.dispatchDialogWorkspacesApplications(workspacesApplications);
    }

    updateWorkspacesApplications() {
        const { dialog, workspace, role } = this.props;
        let status = '';

        this.setDefaultDialogState();
        switch (dialog.dialog_type) {
            case "Enabled":
                status = 'disable';
                break;
            case "Disabled":
                status = 'enable';
                break;
            case "Linked":
                status = 'unlink';
                break;
            case "Unlinked":
                status = 'link';
                break;
            default:
                break;
        }
        const isAdmin = (role.user_role.toLowerCase() === 'admin') ? true : false;
        this.props.updateWorkspaceApplicationLinkStatus(workspace.id, dialog.id, status, true, isAdmin);
    }

    getButtonClass() {
        const { dialog } = this.props;
        let buttonClass = 'btn icoTextBtn mr-3 closePopup';

        switch (dialog.dialog_type) {
            case "Enabled":
                buttonClass += ' redBtn';
                break;
            case "Disabled":
                buttonClass += ' greenBtn';
                break;
            case "Linked":
                buttonClass += ' redBtn';
                break;
            case "Unlinked":
                buttonClass += ' greenBtn';
                break;
            default:
                break;
        }

        return buttonClass;
    }

    getIconStatusClass() {
        const { dialog } = this.props;
        let iconStatusClass = '';

        switch (dialog.dialog_type) {
            case "Enabled":
                iconStatusClass = 'icon-wrd-lock';
                break;
            case "Disabled":
                iconStatusClass = 'icon-wrd-unlock';
                break;
            case "Linked":
                iconStatusClass = 'icon-wrd-revoke';
                break;
            case "Unlinked":
                iconStatusClass = 'icon-wrd-grant';
                break;
            default:
                break;
        }

        return iconStatusClass;
    }

    getStatusClass() {
        const { dialog } = this.props;
        let statusClass = '';

        switch (dialog.dialog_type) {
            case "Enabled":
                statusClass = 'Suspend';
                break;
            case "Disabled":
                statusClass = 'Reinstate';
                break;
            case "Linked":
                statusClass = 'Unlink';
                break;
            case "Unlinked":
                statusClass = 'Link';
                break;
            default:
                break;
        }

        return statusClass;
    }

    closeModal() {
        this.setDefaultDialogState();
    }

    render() {
        const { dialog, workspace } = this.props;
        let dialogCommand = '';
        let dialogAssociation = '';

        switch (dialog.dialog_type) {
            case "Enabled":
                dialogCommand = 'SUSPEND';
                dialogAssociation = 'in';
                break;
            case "Disabled":
                dialogCommand = 'REINSTATE';
                dialogAssociation = 'in';
                break;
            case "Linked":
                dialogCommand = 'UNLINK';
                dialogAssociation = 'from';
                break;
            case "Unlinked":
                dialogCommand = 'LINK';
                dialogAssociation = 'with';
                break;
            default:
                break;
        }

        return (
            <div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div className={"popupPanel popupPanel-small deleteWarning" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                    <div className="popupHeader">
                        <div className="p-4">
                            <h2 className="popupTitle">{dialog.dialog_title}</h2>
                            <button className="btn iconBtn" onClick={() => { this.closeModal(); }}>
                                <span className="icon-wrd-close"></span>
                            </button>
                        </div>
                    </div>
                    <div className="popupBody">
                        <div className="p-4">
                            <div className="summernote">
                                <p>
                                    Are you sure you want to
                                    <b className={(dialog.dialog_type === 'Linked') ? 'actionRemove' : ''}>
                                        &nbsp;{dialogCommand}
                                    </b>
                                    &nbsp;application <b className="appName">{dialog.name}</b>
                                    &nbsp;{dialogAssociation}
                                    &nbsp;workspace <b className="appName">{workspace.workspace_name}</b>?
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="px-4 pt-4 pb-2">
                            <div className="row">
                                <div className="col">
                                    <button className="btn greyBtn icoTextBtn closePopup" onClick={() => { this.closeModal(); }}>
                                        <span className="icon-wrd-close"></span>
                                        Cancel
                                    </button>
                                </div>
                                <div className="col text-right">
                                    <button className={this.getButtonClass()} onClick={() => { this.updateWorkspacesApplications(); }}>
                                        <span className={this.getIconStatusClass()}></span>
                                        {this.getStatusClass()}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ dialog, role, detail }) => ({
    dialog: dialog.workspaces_applications,
    role,
    workspace: detail.workspace
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogWorkspacesApplications: (dialog) => dispatch(updateDialogWorkspacesApplicationsAction(dialog)),
    updateWorkspaceApplicationLinkStatus: (workspaceId, applicationId, status, isFromList, isAdmin) => dispatch(updateWorkspaceApplicationLinkStatusWithThunk(workspaceId, applicationId, status, isFromList, isAdmin))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesModal));
