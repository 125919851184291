import environment from 'environment';

const config = require('../../environments/' + environment.settings);
const axios = require('axios');

async function getWorkspaceMemberList(workspaceId, pageIndex, pageSize) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/member/list", {
        params: {
            page: pageIndex,
            pageSize: pageSize,
            picture: true
        }
    });
    return results;
}

async function getGlobalWorkspaceMemberList(workspaceId, pageIndex, pageSize) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/member/list", {
        params: {
            page: pageIndex,
            pageSize: pageSize,
            picture: false
        }
    });
    return results;
}

async function getWorkspaceMemberSearch(workspaceId, pageIndex, pageSize, searchKeyword) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/member/search", {
        params: {
            keyword: searchKeyword,
            page: pageIndex,
            pageSize: pageSize,
            picture: true
        }
    });
    return results;
}

async function getWorkspaceMemberById(workspaceId, userId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/member/" + userId);
    return results;
}

async function getUserAvailabeWorkspaces(userId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/member/" + userId);
    return results;
}

async function createWorkspaceMember(accessToken, workspaceId, member) {
    let requestHeaders = {
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    };
    const confirmationUrl = config.protocol + config.frontendServer + "/" + config.emailConfirmationPage + "/workspace";
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const results = await axios.post(commandBaseUrl + "workspace/" + workspaceId + "/member/create?confirmationUrl=" + confirmationUrl, member, requestHeaders)
        .catch(function (error) { return error.response });
    return results;
}

async function registerWorkspaceMember(workspaceId, member) {
    const confirmationUrl = config.protocol + config.frontendServer + "/" + config.emailConfirmationPage + "/workspace";
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const results = await axios.post(commandBaseUrl + "workspace/" + workspaceId + "/member/register?confirmationUrl=" + confirmationUrl, member)
        .catch(function (error) { return error.response });
    return results;
}

async function addWorkspaceMembers(accessToken, workspaceId, members) {
    let requestHeaders = {
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    };
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "workspace/" + workspaceId + "/member/add", members, requestHeaders)
        .catch(function (error) { return error.response });
    return results;
}

/* PUT */
async function updateWorkspaceMemberRole(workspaceId, userId, role) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "workspace/" + workspaceId + "/member/" + userId + "/role/" + role);
    return results;
}

async function updateWorkspaceMemberStatus(workspaceId, userId, status) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "workspace/" + workspaceId + "/member/" + userId + "/status/" + status);
    return results;
}

/* DELETE */
async function removeWorkspaceMember(workspaceId, userId) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.delete(commandBaseUrl + "workspace/" + workspaceId + "/member/" + userId);
    return results;
}

export {
    getWorkspaceMemberList,
    getGlobalWorkspaceMemberList,
    getWorkspaceMemberSearch,
    getWorkspaceMemberById,
    getUserAvailabeWorkspaces,
    createWorkspaceMember,
    registerWorkspaceMember,
    addWorkspaceMembers,
    updateWorkspaceMemberRole,
    updateWorkspaceMemberStatus,
    removeWorkspaceMember
}