import React, { Component } from 'react';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateWorkspacesFocusAreasAllAction } from '../../../../actions/updateWorkspacesFocusAreasAllAction';
import { updateSelectedItemGeometryAction } from '../../../../actions/updateSelectedItemGeometryAction';
import { routeCodes } from '../../../../configurations/routes';
import { updateStickyWorkspacesFocusAreasAction } from '../../../../actions/updateStickyWorkspacesFocusAreasAction';
import { updateWorkspacesFocusAreasAction } from '../../../../actions/updateWorkspacesFocusAreasAction';
import { removeWorkspacesFocusAreasWithThunk, getSetGeometryWithThunk, deleteSetGeometryWithThunk } from '../../../../api/workspacesFocusAreasThunk';
import { updateToasterAction } from '../../../../actions/updateToasterAction';

class GridItem extends Component {
	constructor(props) {
		super(props);
		this.onRowSelected = this.onRowSelected.bind(this);
		this.onToggleFocusArea = this.onToggleFocusArea.bind(this);
		this.closeAction = this.closeAction.bind(this);
		this.displayAction = this.displayAction.bind(this);
		this.removeFocusArea = this.removeFocusArea.bind(this);
		this.openGeoModal = this.openGeoModal.bind(this);
		this.clearGeometry = this.clearGeometry.bind(this);
	}

	static propTypes = {
		workspace: PropTypes.object.isRequired,
		item: PropTypes.object.isRequired,
		selected: PropTypes.func.isRequired,
		dispatchWorkspacesFocusAreas: PropTypes.func.isRequired,
		dispatchStickyWorkspacesFocusAreas: PropTypes.func.isRequired,
		dispatchGetWorkspacesSetGeo: PropTypes.func.isRequired,
		removeWorkspacesFocusAreas: PropTypes.func.isRequired,
		items: PropTypes.array.isRequired
	};

	onRowSelected(item) {
		this.closeAction(item.id);
		this.props.selected(item);
	}

	onToggleFocusArea(e) {
		const { focusAreas, item } = this.props;
		const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
		const { name } = e.target;
		const focusArea = {
			...this.props.item,
			[name]: value,
			is_updated: true
		}

		const updateFocusAreas = focusAreas.map(fa =>
			(fa.id === item.id)
				? { ...fa, [name]: value, is_updated: true }
				: { ...fa }
		)

		this.props.dispatchUpdateSelectedItemGeometry(focusArea)
		this.props.dispatchWorkspacesFocusAreas(focusArea);
		this.props.dispatchUpdateFocusAreasAll(updateFocusAreas);
	}

	removeFocusArea() {
		const { workspace, item, focusAreas } = this.props;
		if (focusAreas.length > 1) {
			this.props.removeWorkspacesFocusAreas(workspace.id, item.id);
		} else {
			const message = {
				status: 'Failed',
				message: '',
				error: 'Cannot remove last focus area',
				show_dialog: true,
				redirect: false,
			}
			this.props.dispatchToasterDialog(message);
		}
	}

	closeAction(id) {
		this.props.dispatchStickyWorkspacesFocusAreas({ area_id: id, show_action: false });
	}

	displayAction(id) {
		this.props.dispatchStickyWorkspacesFocusAreas({ area_id: id, show_action: !this.props.sticky.show_action });
	}

	hideAction() {
		this.props.dispatchStickyWorkspacesFocusAreas({ area_id: '', show_action: false });
	}

	openGeoModal(item) {
		const { workspace } = this.props;
		let setGeo = {
			dialog_title: 'Set Geometry',
			dialog_type: 'set_geometry',
			workspaceId: workspace.id,
			focusAreaId: item.id,
			focusAreaName: item.focus_area_name
		};

		this.props.dispatchGetWorkspacesSetGeo(setGeo);
	}

	clearGeometry(item) {
		const { workspace } = this.props;
		let deleteGeo = {
			workspaceId: workspace.id,
			focusAreaId: item.id
		};
		this.props.dispatchDeleteWorkspacesSetGeo(workspace.id, deleteGeo);
	}

	componentDidMount() {
		$('[data-toggle="tooltip"]').tooltip();
	}

	render() {
		const { workspace, item, items } = this.props;
		const { show_action, area_id } = this.props.sticky;

		return (
			<tr className={(item.is_selected) ? "focusList active" : "focusList"}>
				<td onClick={() => this.onRowSelected(item)}>
					<div className="custom-controls-stacked text-left">
						<label className="custom-control ios-switch">
							<input type="checkbox" name="is_checked" onClick={this.onToggleFocusArea} className="ios-switch-control-input" defaultChecked={item.is_checked} disabled={(item.focus_area_status !== 'Enabled') ? true : false} />
							<span className="ios-switch-control-indicator"></span>
                        </label>
                        {(item.is_selected) &&
                            <span className="text stateName" style={{ color:"#FFFFFF" }}>{item.focus_area_name}</span>
                        }
                        {(!item.is_selected) &&
                            <span className="text stateName">{item.focus_area_name}</span>
                        }
					</div>
				</td>
				<td onClick={() => this.onRowSelected(item)}>
					<div className="custom-controls-stacked">
						<span className={(item.has_geometry) ? "icon-wrd-checkmark" : "focus-area-no-geometry icon-wrd-close"}></span>
					</div>
				</td>
				<td >
					<div className="groupIconBtn">
						{item.has_geometry ?
							<React.Fragment>
								<label className="btn customPickFocusArea" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="View Geometry">
									<input type="checkbox" className="activeState" name="showGeometry" checked={item.showGeometry} onChange={this.onToggleFocusArea} />
									<span className="icon icon-wrd-view-geometry"></span>
								</label>
								<label className="btn customPickFocusArea" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Lock to Geometry">
									<input type="checkbox" className="activeState" name="lockGeometry" checked={item.lockGeometry} onChange={this.onToggleFocusArea} />
									<span className="icon icon-wrd-lock-geometry"></span>
								</label>
							</React.Fragment>
							: ''}

						<label className="btn customPickFocusArea" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Public">
							<input type="checkbox" className="activeState" name="public" checked={item.public} onChange={this.onToggleFocusArea} />
							<span className="icon icon-wrd-public"></span>
						</label>
					</div>
				</td>
				<td>
					<ul className={((area_id === item.id) && show_action) ? "action show-action" : "action hide-action"} >
						<li>
							<Link to={this.props.history.location} onMouseDown={() => this.openGeoModal(item)} className="btn popupTrigger deleteApp btn-set-geo">
								<span className="icon-wrd-edit"></span>Set Geometry
                            </Link>
						</li>
						<li>
							<Link to={this.props.history.location} onMouseDown={() => this.clearGeometry(item)} className="btn popupTrigger deleteApp btn-set-geo">
								<span className="icon-wrd-sync"></span>Clear Geometry
                            </Link>
						</li>
						<li>
							{(items.length > 1) &&
                                <Link to={routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/areas"} className="remove" onMouseDown={this.removeFocusArea}>
									<span className="icon-wrd-trash"></span>
									Remove
								</Link>
							}
							{(items.length <= 1) &&
                                <Link to={routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/areas"} className="remove isDisabledNormal" onMouseDown={(event) => event.preventDefault()}>
									<span className="icon-wrd-trash"></span>
									Remove
								</Link>
							}
						</li>
					</ul>
					<button onClick={() => this.displayAction(item.id)} onBlur={() => this.hideAction()} className="btn iconBtn tableActionBtn">
						<span className="icon-wrd-more"></span>
					</button>
				</td>
			</tr>
		);
	}
}

const mapStateToProps = ({ detail, sticky, focusAreas }) => ({
	workspace: detail.workspace,
	sticky: sticky.areas,
	focusAreas,
});

const mapDispatchToProps = (dispatch) => ({
	dispatchUpdateSelectedItemGeometry: (item) => dispatch(updateSelectedItemGeometryAction(item)),
	dispatchUpdateFocusAreasAll: (focusAreas) => dispatch(updateWorkspacesFocusAreasAllAction(focusAreas)),
	dispatchDeleteWorkspacesSetGeo: (id, data) => dispatch(deleteSetGeometryWithThunk(id, data)),
	dispatchGetWorkspacesSetGeo: (data) => dispatch(getSetGeometryWithThunk(data)),
	dispatchWorkspacesFocusAreas: (data) => dispatch(updateWorkspacesFocusAreasAction(data)),
	dispatchStickyWorkspacesFocusAreas: (data) => dispatch(updateStickyWorkspacesFocusAreasAction(data)),
	removeWorkspacesFocusAreas: (workspaceId, focusAreaId) => dispatch(removeWorkspacesFocusAreasWithThunk(workspaceId, focusAreaId)),
	dispatchToasterDialog: (message) => dispatch(updateToasterAction(message)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GridItem));