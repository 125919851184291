import environment from 'environment';
import { getSessionStorage } from '../utils/sessionStorage';
import { overrideWorkspaceId } from '../utils/workspaces';
import { sessionExpiresTime, appFolder } from '../configurations/app';
import { generatePaths } from '../utils/url';

const config = require('../environments/' + environment.settings);

const virtualFolder = "/" + appFolder + "/";

export const isCustomDomainPage = (host, location) => {
    let customDomainPage = false;
    let isIncludeDefaultDomain = (host.includes(config.domain)) ? true : false;

    if (!isIncludeDefaultDomain) {
        if (location === "/") {
            customDomainPage = true;
        }
    }

    return customDomainPage;
}

export const isPortalDomainPage = (location) => {
    let portalDomainPage = false;
    let url = location.split(virtualFolder);

    if (url.length > 0) {
        if (url[1] === undefined) {
            if (url[0] === "/") {
                portalDomainPage = true;
            }
        }
    }

    return portalDomainPage;
}

export const isContactOwnersPage = (location) => {
    let contactOwnersPage = false;
    let url = location.split(virtualFolder);

    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length > 3) {
            if (paths[1].toLowerCase() === 'workspaces' && paths[2] !== '' && paths[2] !== undefined && paths[3] === "contactOwners") {
                contactOwnersPage = true;
            }
        }
    }

    return contactOwnersPage;
}

export const isContactAdministratorsPage = (location) => {
    let contactAdministratorsPage = false;
    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length > 1) {
            if (paths[1] === "contactAdministrators") {
                contactAdministratorsPage = true;
            }
        }
    }

    return contactAdministratorsPage;
}

export const isPortalLandingPage = (location) => {
    let portalLandingPage = false;
    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length > 1) {
            if (paths[1] === "" || paths[2] === 'blog') {
                portalLandingPage = true;
            }
        }
    }

    return portalLandingPage;
}

export const isConfirmationPage = (location) => {
    let confirmationPage = false;
    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length > 4) {
            if (paths[1] === "login" && paths[2] === "users" && paths[3] === "confirmation") {
                confirmationPage = true;
            }
        }
    }

    return confirmationPage;
}

export const isLoginPage = (location) => {
    let loginPage = false;

    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length >= 3) {
            if ((paths[1] === "login" && paths[2] === "users") && (paths[3] === undefined || paths[3] === "")) {
                loginPage = true;
            }
        }
    }

    return loginPage;
}

export const isChangePasswordPage = (location) => {
    let changePasswordPage = false;

    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length >= 4) {
            if (paths[1] === "user" && paths[2] === "password" && paths[3] === "change") {
                changePasswordPage = true;
            }
        }
    }

    return changePasswordPage;
}

export const isWorkspaceLoginPage = (location) => {
    let workspaceLoginPage = false;

    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length >= 3) {
            if ((paths[1] === "login" && paths[2] === "users") && (paths[3] !== undefined || paths[3] !== "")) {
                workspaceLoginPage = true;
            }
        }
    }

    return workspaceLoginPage;
}

export const isWorkspaceRootPage = (location) => {
    let workspaceRootPage = false;

    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length >= 2) {
            if (paths[1] === "workspaces" && paths[2] !== '' && paths[2] !== undefined) {
                workspaceRootPage = true;
            }
        }
    }

    return workspaceRootPage;
}

export const isWorkspacePropertiesPage = (location) => {
    let workspacePropertiesPage = false;

    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length <= 4) {
            if (paths[1] === "workspaces" && (paths[2] !== "" && paths[2] !== undefined) && (paths[3] === "properties")) {
                workspacePropertiesPage = true;
            }
        }
    }

    return workspacePropertiesPage;
}

export const isWorkspaceLandingPage = (location) => {
    let workspaceLandingPage = false;

    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length <= 3) {
            if (paths[1] === "workspaces" && (paths[2] !== "" && paths[2] !== undefined)) {
                workspaceLandingPage = true;
            }
        }
    }

    return workspaceLandingPage;
}

export const isApplicationLandingPage = (location) => {
    let applicationLandingPage = false;

    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length <= 5) {
            if (paths[1] === "workspaces" && paths[3] === "applications"
                && (paths[2] !== "" && paths[2] !== undefined)
                && (paths[4] !== "" && paths[4] !== undefined)) {
                applicationLandingPage = true;
            }
        }
    }

    return applicationLandingPage;
}

export const isWorkspaceApplicationRootPage = (location) => {
    let applicationRootPage = false;

    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length >= 3) {
            if (paths[1] === "workspaces" && paths[3] === "applications"
                && (paths[2] !== "" && paths[2] !== undefined)
                && (paths[4] !== "" && paths[4] !== undefined)) {
                applicationRootPage = true;
            }
        }
    }

    return applicationRootPage;
}

export const isWorkspaceApplicationListPage = (location) => {
    let workspaceApplicationListPage = false;

    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length === 4) {
            if (paths[1] === "workspaces" && paths[3] === "applications"
                && (paths[2] !== "" && paths[2] !== undefined)) {
                workspaceApplicationListPage = true;
            }
        }
    }

    return workspaceApplicationListPage;
}

export const isWorkspaceMemberListPage = (location) => {
    let workspaceMemberListPage = false;

    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length === 4) {
            if (paths[1] === "workspaces" && paths[3] === "members"
                && (paths[2] !== "" && paths[2] !== undefined)) {
                workspaceMemberListPage = true;
            }
        }
    }

    return workspaceMemberListPage;
}

export const isWorkspaceMemberRegisterPage = (location) => {
    let workspaceMemberRegisterPage = false;

    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length === 5) {
            if (paths[1] === "workspaces" && paths[3] === "members" && paths[4] === "register"
                && (paths[2] !== "" && paths[2] !== undefined)) {
                workspaceMemberRegisterPage = true;
            }
        }
    }

    return workspaceMemberRegisterPage;
}

export const isWorkspaceSettingsPage = (location) => {
    let workspaceSettingsPage = false;

    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length === 5) {
            if (paths[1] === "workspaces" && paths[3] === "settings" && paths[4] === "general"
                && (paths[2] !== "" && paths[2] !== undefined)) {
                workspaceSettingsPage = true;
            }
        }
    }

    return workspaceSettingsPage;
}

export const isWorkspaceMemberDetailPage = (location) => {
    let workspaceMemberDetailPage = false;

    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length <= 6) {
            if (paths[1] === "workspaces" && paths[3] === "members" && paths[5] === "detail") {
                workspaceMemberDetailPage = true;
            }
        }
    }

    return workspaceMemberDetailPage;
}

export const isPasswordResetPage = (location) => {
    let passwordResetPage = false;

    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length <= 5) {
            if (paths[1] === "user" && paths[2] === "password" && paths[3] === "reset") {            
                passwordResetPage = true;
            }
        }
    }

    return passwordResetPage;
}

export const isUnavailablePage = (location) => {
    let disabledPage = false;

    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length >= 2) {
            if (paths[1] === "unavailable") {
                disabledPage = true;
            }
        }
    }

    return disabledPage;
}

export const isProfilePage = (location) => {
    let profilePage = false;

    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length >= 2) {
            if (paths[1] === "profile") {
                profilePage = true;
            }
        }
    }

    return profilePage;
}

export const isPortalLogoutPage = (location) => {
    let portalLogoutPage = false;

    let url = location.split(virtualFolder);
    if (url.length > 1) {
        let paths = generatePaths(url);
        if (paths.length <= 2) {
            if (paths[1] === "logout" && (paths[2] === "" || paths[2] === undefined)) {
                portalLogoutPage = true;
            }
        }
    }

    return portalLogoutPage;
}

export const isSessionExpired = () => {
    let expired = false;

    const dateNow = new Date(Date.now());
    const loginDate = getSessionStorage("login_date");

    if (loginDate === "")
        return true;

    const diff = Math.floor(dateNow - new Date(loginDate)) / 1000;

    if (parseFloat(diff) > parseFloat(sessionExpiresTime)) {
        return true;
    }

    return expired;
}

export const checkIfUserisOwner = async (workspaceId, availableWorkspacesObject) => {
    let wId = await overrideWorkspaceId(workspaceId);

    var filteredWorkspace = [];
    if (availableWorkspacesObject.workspaces !== undefined && availableWorkspacesObject.workspaces.length > 0) {
        filteredWorkspace = availableWorkspacesObject.workspaces.filter(function (itm) {
            return wId.indexOf(itm.id) > -1;
        });
    }
    return (filteredWorkspace !== undefined && filteredWorkspace.length > 0) ? filteredWorkspace[0].owner : false;
}

export const checkIfUserisMember = async (workspaceId, availableWorkspacesObject) => {
    let wId = await overrideWorkspaceId(workspaceId);

    var filteredWorkspace = [];
    if (availableWorkspacesObject.workspaces !== undefined && availableWorkspacesObject.workspaces.length > 0) {
        filteredWorkspace = availableWorkspacesObject.workspaces.filter(function (itm) {
            return wId.indexOf(itm.id) > -1;
        });
    }    
    return (filteredWorkspace !== undefined && filteredWorkspace.length > 0) ? true : false;
}

export const checkIfUserisActiveMember = async (workspaceId, availableWorkspacesObject) => {
    let wId = await overrideWorkspaceId(workspaceId);

    var filteredWorkspace = [];
    if (availableWorkspacesObject.workspaces !== undefined && availableWorkspacesObject.workspaces.length > 0) {
        filteredWorkspace = availableWorkspacesObject.workspaces.filter(function (itm) {
            return wId.indexOf(itm.id) > -1;
        });
    }
    let isActiveMember = (filteredWorkspace !== undefined && filteredWorkspace.length > 0) ? (!filteredWorkspace[0].suspend) ? true : false : false;
    return isActiveMember;
}