import { getWorkspaceApplicationIdByNameApi, getWorkspaceApplicationPropertiesByIdApi } from '../backend/domain/workspacesApplications';
import { isValidGuid } from './index';
import { routeCodes } from '../configurations/routes';

export const getWorkspaceApplicationNameById = (workspaceId, applicationId) => {
    let result = getWorkspaceApplicationPropertiesByIdApi(workspaceId, applicationId);
    return result;
}

export const getWorkspaceApplicationIdByName = (workspaceId, workspaceApplicationName) => {
    let result = getWorkspaceApplicationIdByNameApi(workspaceId, workspaceApplicationName);
    return result;
}

export const overrideWorkspaceApplicationId = async (wid, waId) => {
    let owaId = null;

    if (waId !== undefined) {
        let checkId = isValidGuid(waId);
        if (!checkId) {
            let result = await getWorkspaceApplicationIdByName(wid, waId);
            owaId = (result.workspaceApplication !== undefined) ? result.workspaceApplication.id : null;
        }
        else {
            owaId = waId;
        }
    }
    else {
        owaId = waId;
    }

    if (owaId === null) {
        window.location.href = routeCodes.UNAVAILABLE_PAGE + '?page=' + waId + "&application=true";
    }

    return owaId;
}

export const validateWorkspaceApplcationId = async (wid, waId) => {
    let owaId = null;

    if (waId !== undefined) {
        let checkId = isValidGuid(waId);
        if (!checkId) {
            let result = await getWorkspaceApplicationIdByName(wid, waId);
            owaId = (result.workspaceApplication !== undefined) ? result.workspaceApplication.id : null;
        }
    }

    return owaId;
}

export const sortWorkspacesApplications = (applications) => {
    let appsLinked = applications.filter((item) => item.is_linked);
    let appsNotLinked = applications.filter((item) => !item.is_linked);

    appsNotLinked.sort(function (a, b) {
        return a.application_name.toLowerCase().localeCompare(b.application_name.toLowerCase());
    });

    let apps = appsLinked.concat(appsNotLinked);

    return apps;
}