import environment from 'environment';

const config = require('../../environments/' + environment.settings);
const axios = require('axios');
const CircularJSON = require('circular-json');

async function deleteFocusAreaGeometry(workspaceId, focusAreaId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.delete(queryBaseUrl + "workspace/" + workspaceId + "/focus-area/" + focusAreaId + '/geometry')
        .then(res => {
            return res
        })
        .catch(error => {
            return error.response;
        });
    return results;
}

async function getFocusAreaById(workspaceId, focusAreaId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/focus-area/" + focusAreaId)
        .then(res => {
            return res
        })
        .catch(error => {
            return error.response;
        });
    return results;
}

async function getFocusAreaGeometry(workspaceId, focusAreaId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/focus-area/" + focusAreaId + '/geometry')
        .then(res => {
            return res
        })
        .catch(error => {
            return error.response;
        });
    return results;
}

async function getFocusAreas(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/focus-area/list");
    return results;
}

async function getFocusAreaList(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/focus-area/list");
    return results;
}

async function getFocusAreaConnections(workspaceId, getAll) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/focus-area/connectionstring?all=" + getAll);
    return results;
}

async function postFocusAreaGeometry(workspaceId, focusAreaId, data) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "workspace/" + workspaceId + "/focus-area/" + focusAreaId + "/geometry", data)
        .then(res => {
            return res
        })
        .catch(error => {
            return error.response;
        });

    return results;
}
async function createFocusArea(workspaceId, focusArea) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "workspace/" + workspaceId + "/focus-area", focusArea)
        .catch(function (error) { return error.response });
    return results;
}

async function updateFocusAreaBehavior(workspaceId, data) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "workspace/" + workspaceId + "/focus-area/" + data.id, data)
        .catch((error) => {
            return error.response;
        });
    return results;
}

async function updateFocusAreaStatus(workspaceId, focusAreaId, status) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "workspace/" + workspaceId + "/focus-area/" + focusAreaId + "/" + status)
        .catch((error) => {
            return error.response;
        });
    return results;
}

async function updateFocusAreaConnections(workspaceId, connections) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "workspace/" + workspaceId + "/focus-area/connectionstring", connections).then((response) => {
        let json = CircularJSON.stringify(response);
        return JSON.parse(json);
    }).catch((error) => {
        return error.response;
    });
    return results;
}

async function removeFocusArea(workspaceId, focusAreaId) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.delete(commandBaseUrl + "workspace/" + workspaceId + "/focus-area/" + focusAreaId);
    return results;
}

export {
    updateFocusAreaBehavior,
    getFocusAreas,
    postFocusAreaGeometry,
    deleteFocusAreaGeometry,
    getFocusAreaById,
    getFocusAreaGeometry,
    getFocusAreaList,
    getFocusAreaConnections,
    createFocusArea,
    updateFocusAreaStatus,
    removeFocusArea,
    updateFocusAreaConnections
}