import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import GridContainer from '../Grid/GridContainer';
import { getUserHeaders } from '../../../utils/users';

class UsersListBody extends Component {
    static propTypes = {
        users: PropTypes.array.isRequired
    };

    render() {
        const headers = getUserHeaders();
        const { users } = this.props;

        return (
            <GridContainer headers={headers} items={users} />
        );
    }
}

const mapStateToProps = ({ search }) => ({
    search: search.user
});

export default withRouter(connect(mapStateToProps)(UsersListBody));