import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { updatePortalSettingThemeAction } from '../../../../actions/updatePortalSettingThemeAction';
import Loader from 'react-loader-spinner';
import { updatePortalSettingTabAction } from '../../../../actions/updatePortalSettingTabAction';
import PortalSettingsThemeItem from './PortalSettingsThemeItem';
import { routeCodes } from '../../../../configurations/routes';
import {
      getPortalSettingThemeWithThunk
} from '../../../../api/portalSettingsThunk';

class PortalSettingsTheme extends Component {
    constructor(props) {
        super(props);
        this.addPortalSettingTheme = this.addPortalSettingTheme.bind(this);
        this.state = {
            src: null,
            imageEditorIsOpen: false,
            stateProp: null
        };

    }

    static propTypes = {
        settingTheme: PropTypes.object.isRequired,
        dispatchUpdateSettingTheme: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        activeTab: PropTypes.string.isRequired,
        dispatchActiveTab: PropTypes.func.isRequired
    };

    addPortalSettingTheme() {
        const { settingTheme } = this.props;        
        let themes = settingTheme.themes;

        let themeSetting = {
            ...settingTheme,
            themes
        };
        let tempLength = themeSetting.themes.length + 1;
        var newObj = {
            id: "temp" + tempLength,
            theme_name: "",
            image: "",
            status: ""
        }
        themeSetting.themes.unshift(newObj);

        this.props.dispatchUpdateSettingTheme(themeSetting);
    }

    switchActiveTab(activeTab) {
        this.props.dispatchActiveTab(activeTab);
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    componentDidMount() {
        this.props.getPortalSettingTheme();
    }

    render() {
        const { settingTheme, loading } = this.props;

        return (
            <div className="mainBody">
                {loading &&
                    <div className="loader-container-portal-email-setting">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                <div className="row mt-5">
                    <div className="col-md-12 order-lg-1 col-lg-8 col-xl-9">
                        <div className="wsHomeSection">
                            <div className="groupForm mb-0">
                                <label className="sectionTitle">Themes</label>
                                <button className="btn icoTextBtn btn-wrd_default mt-2 ml-2" onClick={() => this.addPortalSettingTheme()}>
                                    <span className="icon-wrd-add"></span>
                                    Add Theme</button>
                                {
                                    (settingTheme.themes !== undefined && settingTheme.themes.length > 0) ?
                                        settingTheme.themes.map((item) => (
                                        <PortalSettingsThemeItem key={item.id} item={item} />
                                    ))
                                    :
                                    null
                                }                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4 col-xl-3">
                        <div className="wsLogo pb-5 text-center">
                            <ul className="setting-menus mt-5 w-75">
                                <strong>Settings Options</strong>
                                <li>
                                    <Link to={routeCodes.PORTAL_OPTIONS} >
                                        <span className="icon-wrd-log-in"></span> Login
                                        </Link>
                                </li>
                                <li>
                                    <Link to={routeCodes.PORTAL_OPTIONS_EMAIL}>
                                        <span className="icon-wrd-mail-closed"></span>Email
                                    </Link>
                                </li>
                                <li>
                                    <Link to={routeCodes.PORTAL_OPTIONS_THEME} className="active">
                                        <span className="icon-wrd-themes"></span>Themes
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ portalSettings, loading }) => ({
    settingTheme: portalSettings.setting_theme,
    loading: loading.portal_setting_theme.loading,
    activeTab: portalSettings.active_tab
});

const mapDispatchToProps = (dispatch) => ({
    getPortalSettingTheme: () => dispatch(getPortalSettingThemeWithThunk()),
    dispatchUpdateSettingTheme: (themeSetting) => dispatch(updatePortalSettingThemeAction(themeSetting)),
    dispatchActiveTab: (active_tab) => dispatch(updatePortalSettingTabAction(active_tab))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalSettingsTheme));