import environment from 'environment';
import { updateImage } from '../../utils/snapshot';

const config = require('../../environments/' + environment.settings);
const axios = require('axios');

async function getWorkspaceApplicationById(workspaceId, applicationId, includeLockStatus) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = (includeLockStatus) ? await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId + "?includeLockStatus=true") : await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId);

    return results;
}

async function getWorkspaceApplicationIdByName(workspaceId, workspaceApplicationName) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/application/id/" + workspaceApplicationName);

    return results;
}

async function getWorkspaceApplicationConnectionUsageVerificaton(workspaceId, applicationId, connectionIds) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId + "/connectionstrings/usage-verification?ids=" + connectionIds);

    return results;
}

async function getWorkspaceApplicationPropertiesById(workspaceId, applicationId, includeLockStatus) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = (includeLockStatus) ? await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId + "?includeLockStatus=true") : await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId);
    return results;
}

async function getWorkspaceApplicationList(workspaceId, withLogo) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/application/list", {
        params: {
            logo: withLogo
        }
    });
    return results;
}

async function getWorkspaceLandingApplicationList(workspaceId, withLogo, withSnapshot, snapshotWidth, snapshotHeight) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/application/list", {
        params: {
            logo: withLogo,
            snapshot: withSnapshot,
            width: snapshotWidth,
            height: snapshotHeight
        }
    });
    return results;
}

async function getWorkspaceAvailableApplications(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/application/availables", null);
    const applications = results.data.filter((item) => item.assigned === true && item.workspaceApplicationEnabled === true && item.applicationEnabled === true);
    return applications;
}

async function getWorkspacesApplications(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/application/availables", null);
    return results;
}

async function getWorkspaceFocusAreaShortConnString(workspaceId, applicationId, focusAreaId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId + "/focus-area/" + focusAreaId + "/connectionstringinfo")
        .then(res => {
            return res
        })
        .catch(error => {
            return error.response;
        });
    return results;
}

async function getWorkspaceFocusAreas(workspaceId, applicationId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId + "/focus-area")
        .then(res => {
            return res
        })
        .catch(error => {
            return error.response;
        });
    return results;
}

async function getWorkspaceApplicationConnections(workspaceId, applicationId, usedOriginal) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId + "/focus-area?usedOriginalValues=" + usedOriginal)
        .then(res => {
            return res
        })
        .catch(error => {
            return error.response;
        });
    return results;
}

async function getWorkspaceApplicationLogo(workspaceId, applicationId, width, height) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const url = queryBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId + "/logo/" + width + "/" + height;

    let results;
    try {
        const response = await axios.get(url, { responseType: 'arraybuffer' });
        results = Buffer.from(response.data, 'binary').toString('base64');
    }
    catch (err) {
        if (err.response !== undefined) {
            if (err.response.status === 404) {
                return results;
            }
        }
    }
    return results;
}

async function getWorkspaceApplicationSnapshot(workspaceId, applicationId, width, height) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const url = queryBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId + "/snapshot/" + width + "/" + height;

    let results;
    try {
        const response = await axios.get(url, { responseType: 'arraybuffer' });
        results = Buffer.from(response.data, 'binary').toString('base64');
    }
    catch (err) {
        if (err.response !== undefined) {
            if (err.response.status === 404) {
                return results;
            }
        }
    }
    return results;
}

async function getWorkspacesApplicationsSettings(workspaceId, applicationId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + '/application/' + applicationId + '/setting');
    return results;
}

async function getWorkspacesApplicationsUsersSettings(workspaceId, applicationId, userId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + '/member/' + userId + '/application/' + applicationId + '/setting/access')
        .then(res => { return res })
        .catch(function (error) { return error.response; });
    return results;
}

async function getWorkspaceApplicationToken(params) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "appauth/token", params).catch(function (error) { return error.response });
    return results;
}

async function updateWorkspaceApplication(workspaceId, applicationId, workspaceApplication) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId, workspaceApplication);
    return results;
}

async function updateWorkspaceApplicationLogo(workspaceId, applicationId, content) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const url = commandBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId + "/logo";

    return await updateImage(content, url);;
}

async function updateWorkspaceApplicationSnapshot(workspaceId, applicationId, content) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const url = commandBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId + "/snapshot";

    return await updateImage(content, url);;
}

async function updateWorkspacesApplicationCategory(workspaceId, applicationId, categoryId) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const results = (categoryId !== "") ? await axios.put(commandBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId + "/category/" + categoryId)
        : await axios.put(commandBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId + "/category/reset");
    return results;
}

async function updateWorkspacesApplicationsStatus(workspaceId, applicationId, status) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId + "/Enable?status=" + status);
    return results;
}

async function linkWorkspacesApplications(workspaceId, applicationId) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId + "/assign", null);
    return results;
}

async function updateWorkspaceApplicationConnections(workspaceId, applicationId, connections) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId + "/focusarea/connectionstring", connections);
    return results;
}

async function updateOrderWorkspacesApplications(workspaceId, apps) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "workspace/" + workspaceId + "/application/reorder", apps);
    return results;
}

async function updateWorkspacesApplicationsSettings(workspaceId, applicationId, settings) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "workspace/" + workspaceId + '/application/' + applicationId + '/settings', settings);
    return results;
}

async function updateWorkspacesApplicationsUsersSettings(workspaceId, applicationId, userId, settings) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "workspace/" + workspaceId + '/member/' + userId + '/application/' + applicationId + '/settings', settings);
    return results;
}

async function removeLinkWorkspacesApplications(workspaceId, applicationId) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    return await axios.delete(commandBaseUrl + "workspace/" + workspaceId + "/application/" + applicationId + "/revoke", null);
}

export {
    getWorkspaceApplicationById,
    getWorkspaceApplicationIdByName,
    getWorkspaceApplicationConnectionUsageVerificaton,
    getWorkspaceApplicationPropertiesById,
    getWorkspaceApplicationList,
    getWorkspaceLandingApplicationList,
    getWorkspaceAvailableApplications,
    getWorkspacesApplications,
    getWorkspaceFocusAreaShortConnString,
    getWorkspaceFocusAreas,
    getWorkspaceApplicationConnections,
    getWorkspaceApplicationLogo,
    getWorkspaceApplicationSnapshot,
    getWorkspacesApplicationsSettings,
    getWorkspacesApplicationsUsersSettings,
    getWorkspaceApplicationToken,
    updateWorkspaceApplication,
    updateWorkspaceApplicationLogo,
    updateWorkspaceApplicationSnapshot,
    updateWorkspacesApplicationCategory,
    updateWorkspacesApplicationsStatus,
    linkWorkspacesApplications,
    updateWorkspaceApplicationConnections,
    updateOrderWorkspacesApplications,
    updateWorkspacesApplicationsSettings,
    updateWorkspacesApplicationsUsersSettings,
    removeLinkWorkspacesApplications
}