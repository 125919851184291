import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class WorkspacesApplicationsSettingsMultiSelectItem extends Component {
    static propTypes = {
        text: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    };

    render() {
        const { text, value } = this.props;

        return (
            <option value={value}> { text }</option >
        );
    }
}

export default withRouter(WorkspacesApplicationsSettingsMultiSelectItem);