import { ROUTE_BACK_CREATE_USER, ROUTE_BACK_GLOBAL_USERS } from '../actions/index';

const initialRouteBackState = {
    create_user: {
        url: '',
        source_page: ''
    },
    global_users: {
        url: '',
        source_page: ''
    }
}

export const routeBack = (state = initialRouteBackState, action) => {
    const { routeBack } = action

    switch (action.type) {
        case ROUTE_BACK_CREATE_USER:
            return {
                ...state,
                create_user: routeBack
            }
        case ROUTE_BACK_GLOBAL_USERS:
            return {
                ...state,
                global_users: routeBack
            }
        default:
            return state;
    }
};