import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearWorkspacesApplicationsUsersSettingsAction } from '../../../../actions/clearWorkspacesApplicationsUsersSettingsAction';
import { clearWorkspacesApplicationsSettingsAction } from '../../../../actions/clearWorkspacesApplicationsSettingsAction';
import { getWorkspacesApplicationsUsersSettingsByIdWithThunk } from '../../../../api/workspacesApplicationsSettingsThunk';
import Loader from 'react-loader-spinner'
import SidebarContainer from '../../../Sidebar/SidebarContainer';
import WorkspacesApplicationsSettingsHeader from './WorkspacesApplicationsUserSettingsHeader';
import WorkspacesApplicationsSettingsBody from './WorkspacesApplicationsUserSettingsBody';
import ErrorSuccessModal from '../../../Shared/ErrorSuccessModal';

class WorkspacesApplicationsUserSettingsContainer extends Component {
	static propTypes = {
		workspace: PropTypes.object.isRequired,
		workspaceApplication: PropTypes.object.isRequired,
		role: PropTypes.object.isRequired,
		getWorkspacesApplicationsSettingsById: PropTypes.func.isRequired,
		clearWorkspaceApplicationsSettings: PropTypes.func.isRequired,
		loading: PropTypes.bool.isRequired
	};

	componentWillUnmount() {
		this.props.clearWorkspaceApplicationsSettings();
		this.props.clearWorkspaceApplicationsUserSettings();
	}

	componentDidMount() {
		const { loading } = this.props;
		if (loading === false) {
			const { id, applicationid, userId } = this.props.match.params;
            const width = Math.ceil((window.innerWidth - 560) * (3 / 12));
            const height = width;
            this.props.getWorkspacesApplicationsSettingsById(id, applicationid, userId, true, width, height);
		}
	}

	render() {
        const { workspace, workspaceApplication, loading, loadingTheme } = this.props;

		if ((workspace.id === undefined || workspace.id === '') &&
			(workspaceApplication.application_id === undefined || workspaceApplication.application_id === ''))
			return (
				<div>
                    {(loading || loadingTheme) &&
						<div className="loader-container">
							<Loader
								type="Ball-Triangle"
								color="#00BFFF"
								height="100"
								width="100"
							/>
						</div>
					}
				</div>
			);

		return (
			<div>
				{loading &&
					<div className="loader-container">
						<Loader
							type="Ball-Triangle"
							color="#00BFFF"
							height="100"
							width="100"
						/>
					</div>
                }
                {!loadingTheme &&
                    <div>
                        <SidebarContainer />
                        <main>
                            <div className="mapBackground" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <WorkspacesApplicationsSettingsHeader />
                                    <WorkspacesApplicationsSettingsBody />
                                </div>
                            </div>
                        </main>
                        <ErrorSuccessModal type="error" />
                    </div>
                }
			</div>
		);
	}
}

const mapStateToProps = ({ detail, role, loading }) => ({
	workspace: detail.workspace,
	workspaceApplication: detail.workspace_application,
	role,
    loading: loading.workspaces_applications_users_settings.loading,
    loadingTheme: loading.theme.loading
});

const mapDispatchToProps = (dispatch) => ({
    getWorkspacesApplicationsSettingsById: (workspaceId, applicationId, userId, withPicture, width, height) => dispatch(getWorkspacesApplicationsUsersSettingsByIdWithThunk(workspaceId, applicationId, userId, withPicture, width, height)),
	clearWorkspaceApplicationsUserSettings: () => dispatch(clearWorkspacesApplicationsUsersSettingsAction([])),
	clearWorkspaceApplicationsSettings: () => dispatch(clearWorkspacesApplicationsSettingsAction([]))
	
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsUserSettingsContainer));