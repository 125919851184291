import sort from 'fast-sort';
import { GET_WORKSPACES_APPLICATIONS_SETTINGS, UPDATE_WORKSPACES_APPLICATIONS_SETTINGS, CLEAR_WORKSPACES_APPLICATIONS_SETTINGS } from '../actions/index';

const getWorkspacesApplicationsSettings = (workspacesApplicationsSettings = [], action) => {
    let itemsKeys = [];
    let settingsKeys = action.settings.reduce((keys, setting) => {
        itemsKeys.push(setting.id);
        keys = itemsKeys;
        return keys;
    }, []);

    let result = workspacesApplicationsSettings.filter((setting) => {
        return settingsKeys.indexOf(setting.id) === -1;
    });

    const results = result.concat(action.settings);
    return sort(results).asc("group_name");
}

const updateWorkspacesApplicationsSettings = (workspacesApplicationsSettings = [], action) => {
    const settings = workspacesApplicationsSettings.map((item) => {
        const changesSettings = action.settings.filter((change) => change.id === item.id)
        if (changesSettings.count > 0) {
            return changesSettings[0];
        }

        return item
    })

    return sort(settings).asc("group_name");
}

const clearWorkspacesApplicationsSettings = (action) => {
    return action.settings;
}

export const workspacesApplicationsSettings = (workspacesApplicationsSettings = [], action) => {
    switch (action.type) {
        case GET_WORKSPACES_APPLICATIONS_SETTINGS: return getWorkspacesApplicationsSettings(workspacesApplicationsSettings, action);
        case UPDATE_WORKSPACES_APPLICATIONS_SETTINGS: return updateWorkspacesApplicationsSettings(workspacesApplicationsSettings, action);
        case CLEAR_WORKSPACES_APPLICATIONS_SETTINGS: return clearWorkspacesApplicationsSettings(action);
        default: return workspacesApplicationsSettings;
    }
};