import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import ApplicationsListItem from './ApplicationsListItem';
import ApplicationsListCategory from './ApplicationsListCategory';
import { getApplicationsAction } from '../../../actions/getApplicationsAction';
import { getCategoriesAction } from '../../../actions/getCategoriesAction';
import { updateApplicationCategoryWithThunk } from '../../../api/applicationsThunk';
import { reOrderCategoryItems } from '../../../utils/index';
import { mergeApplicationsCategories } from '../../../utils/categories';
import { defaultApplicationsSortingBy, defaultApplicationsSortingType } from '../../../configurations/app';
import { routeCodes } from '../../../configurations/routes';

class ApplicationsListBody extends Component {
    constructor(props) {
        super(props);
        this.createCategory = this.createCategory.bind(this);
        this.setDragInfoState = this.setDragInfoState.bind(this);
        this.categoryBody = React.createRef();
        this.categoryDrop = React.createRef();

        this.state = {
            applicationId: "",
            categoryTitle: "",
        };
    }

    static propTypes = {
        items: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
        dispatchApplications: PropTypes.func.isRequired,
        dispatchCategories: PropTypes.func.isRequired,
        updateApplicationCategory: PropTypes.func.isRequired
    };

    createCategory() {
        this.props.history.push(routeCodes.CATEGORY_CREATE);
    }

    onSelect = app => {
        const { items } = this.props;
        const applications = items.map((item, i) =>
            (app.id === item.id)
                ? { ...item, selected: true }
                : { ...item, selected: false }
        )
        this.props.dispatchApplications(applications)
    };

    onCategoryItemDragOver(e, isHaveApps) {
        e.stopPropagation();
        e.preventDefault();

        this.categoryDrop.current.setAttribute("class", (isHaveApps) ? "drop-area-app drop-area-hover mb-4" : "drop-area drop-area-hover mb-4");
    }

    onCategoryItemDragLeave(e, isHaveApps) {
        e.stopPropagation();
        e.preventDefault();

        let viewportOffset = this.categoryDrop.current.getBoundingClientRect();
        let boxTop = viewportOffset.top;
        let boxBottom = boxTop + viewportOffset.height;
        let y = e.clientY;

        if (y > boxTop && y < boxBottom) {
            return;
        }
        this.categoryDrop.current.setAttribute("class", (isHaveApps) ? "drop-area-app mb-4" : "drop-area mb-4");
    }

    onCategoryItemDrop(e, isHaveApps) {
        e.stopPropagation();
        e.preventDefault();

        this.categoryDrop.current.setAttribute("class", (isHaveApps) ? "drop-area-app mb-4" : "drop-area mb-4");

        let appId = this.state.applicationId;
        let apps = this.props.items.filter((item) => item.id === appId && item.category_title !== "");
        if (apps.length === 0) {
            return;
        }

        let applicationId = e.dataTransfer.getData("application_id");

        const draggedItem = this.props.items.filter(item => item.selected)[0];
        draggedItem.category_title = "";

        const applications = reOrderCategoryItems(
            this.props.items,
            this.props.items.length - 1,
            draggedItem
        );

        this.props.dispatchApplications(applications);

        let withCategories = this.props.categories.filter((item) => item.category_title !== "");
        let mergeCategories = mergeApplicationsCategories(withCategories, applications);
        this.props.dispatchCategories(mergeCategories);

        this.props.updateApplicationCategory(applicationId, "", 1, 10000, defaultApplicationsSortingBy, defaultApplicationsSortingType);
        this.setDragInfoState("", "");
    }

    setDragInfoState(applicationId, categoryTitle) {
        this.setState({ applicationId, categoryTitle });
    }

    render() {
        const { items, categories, loading } = this.props;
        const { applicationId, categoryTitle } = this.state;

        let noCategoryApplications = items.filter((item) => item.category_title === "");
        let withCategories = categories.filter((item) => item.category_title !== "");

        if (items.length === 0) {
            return (
                <div className="main-application-list-body">
                    {loading &&
                        <div className="loader-container">
                            <Loader
                                type="Ball-Triangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                </div>
            )
        }
        return (
            <div className="mainBody">
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                <div className="row mt-5">
                    <div className="col-xl-12">
                        <div className="wsAppsSection">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-9">
                                    <button className="btn icoTextBtn btn-wrd_default mr-3" onClick={() => this.createCategory()}>
                                        <span className="icon-wrd-add"></span>
                                        Add Category
                                    </button>
                                </div>
                            </div>
                            <div ref={this.categoryBody}>
                                {(noCategoryApplications.length === 0) &&
                                    <div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="category-header mb-3">
                                                    <span className="icon-wrd-binders"></span>
                                                    <h5>No application category</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div ref={this.categoryDrop} className="drop-area mb-4" onDrop={(e) => this.onCategoryItemDrop(e, false)} onDragOver={(e) => this.onCategoryItemDragOver(e, false)} onDragLeave={(e) => this.onCategoryItemDragLeave(e, false)}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                <p>No available app(s) in this category, drag and drop an app here to add to the category</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {(noCategoryApplications.length > 0) &&
                                    <div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="category-header mb-3">
                                                    <span className="icon-wrd-binders"></span>
                                                    <h5>No application category</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div ref={this.categoryDrop} className="drop-area-app mb-4" onDrop={(e) => this.onCategoryItemDrop(e, true)} onDragOver={(e) => this.onCategoryItemDragOver(e, true)} onDragLeave={(e) => this.onCategoryItemDragLeave(e, true)}>
                                            <div className="row">
                                                {noCategoryApplications.map((application) => (
                                                    <div className="col-3 col-sm-4 col-lg-3" draggable={true} key={application.id}>
                                                        <ApplicationsListItem item={application} onSelect={this.onSelect} setDragInfoState={this.setDragInfoState} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {withCategories.map((category, index) => (
                                    <ApplicationsListCategory key={index} category={category} items={items} categories={categories} applicationId={applicationId} categoryTitle={categoryTitle} setDragInfoState={this.setDragInfoState} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ loading, categories }) => ({
    loading: loading.applications.loading,
    categories
});

const mapDispatchToProps = (dispatch) => ({
    dispatchApplications: (apps) => dispatch(getApplicationsAction(apps)),
    dispatchCategories: (categories) => dispatch(getCategoriesAction(categories)),
    updateApplicationCategory: (applicationId, categoryId, pageIndex, pageSize, sortingBy, sortingType) => dispatch(updateApplicationCategoryWithThunk(applicationId, categoryId, pageIndex, pageSize, sortingBy, sortingType))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationsListBody));