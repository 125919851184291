import { getImageSize } from '../../utils/snapshot';
import { getDefaultImage, getThumbnailDefaultImage, getDefaultPhotoPicture } from '../../utils/image';
import { normalizeTextDescription } from '../../utils/text';
import { routeWorkspacePage } from '../../utils/workspaces';

const workspacesApi = require('../api/workspaces');
const workspacesApplications = require('./workspacesApplications');
const workspacesCategories = require('./workspacesCategories');
const workspacesSettings = require('./workspacesSettings');
const workspacesMembers = require('./workspacesMembers');
const users = require('./users');
const usersApi = require('../api/users');
const moment = require('moment');

export async function getWorkspaceNameByIdApi(workspaceId) {
    try {
        const workspace = await workspacesApi.getWorkspaceById(workspaceId, false);
        return mapWorkspaceNameFrontend(workspace.data);
    }
    catch (err) {
        if (err.response !== undefined) {
            return mapToEmptyWorkspaceFrontend(err.response.status.toString());
        }
        return mapToEmptyWorkspaceFrontend("500");
    }
}

export async function getWorkspaceIdByNameApi(workspaceName) {
    try {
        const workspace = await workspacesApi.getWorkspaceByName(workspaceName);
        return mapWorkspaceIdFrontend(workspace.data, workspaceName);
    }
    catch (err) {
        if (err.response !== undefined) {
            return mapToEmptyWorkspaceFrontend(err.response.status.toString());
        }
        return mapToEmptyWorkspaceFrontend("500");
    }
}

export async function getWorkspaceListApi(pageIndex, pageSize, sortingBy, sortingType) {
    const results = await workspacesApi.getWorkspaceList(pageIndex, pageSize, sortingBy, sortingType);
    return mapToWorkspacesFrontend(results.data);
}

export async function getWorkspaceSearchListApi(keyword, pageIndex, pageSize, sortingBy, sortingType) {
    const results = await workspacesApi.getWorkspaceSearchList(keyword, pageIndex, pageSize, sortingBy, sortingType);
    return mapToWorkspacesFrontend(results.data);
}

export async function getUserWorkspaceLandingPropertiesByIdApi(workspaceId, userId, isAdmin, snaphotWidth, snapshotHeight, historyProps) {
    const workspace = await workspacesApi.getWorkspaceById(workspaceId, false);
    const categories = await workspacesCategories.getWorkspaceApplicationCategoryListApi(workspaceId);
    const applications = await workspacesApplications.getWorkspaceLandingApplicationListApi(workspaceId, snaphotWidth, snapshotHeight, categories);
    const workspaceBanner = await workspacesSettings.getWorkspaceSettingBannerByIdApi(workspaceId);
    const workspaceUser = await workspacesMembers.getWorkspaceMemberByIdApi(workspaceId, userId);
    const initials = await users.getUserInitialApi(userId, 32, 32);

    const isOwner = (workspaceUser !== undefined) ? workspaceUser.user.is_owner : false;
    const isSuspend = (workspaceUser !== undefined) ? workspaceUser.user.is_suspended : false;
    const isMember = (workspaceUser !== undefined) ? workspaceUser.user.is_member : false;
    const isActiveMember = !isSuspend;

    let userApplications = [];
    const userApps = await usersApi.getUserAccessWorkspaceApplications(workspaceId, userId);

    if (applications.applications.length > 0 && userApps.status === 200 && userApps.data.length > 0) {
        if (!isSuspend) {
            userApplications = applications.applications.filter((x) => userApps.data.find(({ applicationId }) => x.id === applicationId));
        }
        else {
            if (workspace.data.allowPublicAccess) {
                let publicApps = (applications !== undefined) ? applications.applications.filter((item) => item.allow_public_access) : [];
                userApplications = userApplications.concat(publicApps);
            }
        }
    }
    else {
        if (workspace.data.allowPublicAccess) {
            let publicApps = (applications !== undefined) ? applications.applications.filter((item) => item.allow_public_access) : [];
            userApplications = userApplications.concat(publicApps);
        }
    }

    let availableCategories = [];

    for (let i in categories) {
        let category = categories[i];
        let app = applications.applications.filter((item) => item.category_title === category.category_title);
        if (app.length > 0) {
            availableCategories.push(category);
        }
    }

    let userCategories = [];
    for (let i in categories) {
        let category = categories[i];
        let app = userApplications.filter((item) => item.category_title === category.category_title);
        if (app.length > 0) {
            userCategories.push(category);
        }
    }

    let results = await mapToUserWorkspaceLandingPropertiesFrontend(workspace.data, initials, isOwner, workspaceBanner, isSuspend);
    if (results !== undefined) {
        results.categories = availableCategories;
        results.available_applications = applications;
        results.user_categories = userCategories;
        results.user_available_applications = userApplications;

        if (!isAdmin) {
            routeWorkspacePage(results, userId, isMember, isOwner, isActiveMember, historyProps, "landing");
        }
    }

    return results;
}

export async function getUserWorkspacePropertiesByIdApi(workspaceId, userId, isAdmin, historyProps, pageToGo) {
    const workspace = await workspacesApi.getWorkspaceById(workspaceId, (pageToGo === 'properties') ? true : false);
    const applications = await workspacesApplications.getWorkspaceAvailableApplicationsApi(workspaceId);
    const workspaceUser = await workspacesMembers.getWorkspaceMemberByIdApi(workspaceId, userId);

    const isOwner = (workspaceUser !== undefined) ? workspaceUser.user.is_owner : false;
    const isSuspend = (workspaceUser !== undefined) ? workspaceUser.user.is_suspended : false;
    const isMember = (workspaceUser !== undefined) ? workspaceUser.user.is_member : false;
    const isActiveMember = !isSuspend;

    let results = await mapToUserWorkspacePropertiesFrontend(workspace.data, isOwner, isSuspend);
    if (results !== undefined) {
        results.available_applications = applications;

        if (!isAdmin) {
            routeWorkspacePage(results, userId, isMember, isOwner, isActiveMember, historyProps, pageToGo);
        }
    }

    return results;
}

export async function getUserWorkspaceByIdApi(workspaceId, userId) {
    const workspace = await workspacesApi.getWorkspaceById(workspaceId, false);
    const applications = await workspacesApplications.getWorkspaceAvailableApplicationsApi(workspaceId);
    const workspaceUser = await workspacesMembers.getWorkspaceMemberByIdApi(workspaceId, userId);

    const isOwner = (workspaceUser !== undefined) ? workspaceUser.user.is_owner : false;
    const isSuspend = (workspaceUser !== undefined) ? workspaceUser.user.is_suspended : false;

    let results = await mapToUserWorkspacePropertiesFrontend(workspace.data, isOwner, isSuspend);
    if (results !== undefined) {
        results.available_applications = applications;
    }

    return results;
}

export async function getContactOwnersApi(workspaceId) {
    const contactOwners = await workspacesApi.getContactOwnerId(workspaceId);
    if (contactOwners.status >= 400) {
        contactOwners.data = 'Contact Owners Not Found !';
        return contactOwners;
    }
    const owners = contactOwners.map(ow => ow.userId)
    const emails = await workspacesApi.getContactOwnerUserId(owners);
    return emails;
}

export async function getWorkspaceNameByCustomDomainApi(domain) {
    const customDomain = await workspacesApi.getWorkspaceNameByCustomDomain(domain);
    if (customDomain.status >= 400) {
        return null;
    }
    return customDomain;
}

export async function getWorkspaceStatusByIdApi(workspaceId) {
    const workspace = await workspacesApi.getWorkspaceById(workspaceId, false);
    let workspaceStatus = {
        enabled: false,
        status: '',
        allow_public_access: false
    }

    if (workspace.data !== undefined) {
        let w = workspace.data;
        workspaceStatus.enabled = w.enabled;
        workspaceStatus.status = w.status;
        workspaceStatus.allow_public_access = w.allowPublicAccess;
    }

    return workspaceStatus;
}

export async function getWorkspaceUrlApi(workspaceId) {
    const workspace = await workspacesApi.getWorkspaceById(workspaceId, false);
    let workspaceUrl = {
        url: '',
    }

    if (workspace.data !== undefined) {
        let w = workspace.data;
        workspaceUrl.url = (w.url !== undefined && w.url !== null) ? w.url : "";
    }

    return workspaceUrl;
}

export async function sendEmailToWorkspaceOwnersApi(workspaceId, model) {
    let results = {};
    try {
        results = await workspacesApi.sendEmailToWorkspaceOwners(workspaceId, model);
        return results;
    }
    catch (err) {
        if (err.response !== undefined) {
            return mapToEmptyWorkspaceFrontend(err.response.status.toString());
        }
        return mapToEmptyWorkspaceFrontend("500");
    }
}

export async function createWorkspaceApi(workspace) {
    const mappingWorkspace = mapToWorkspaceBackendForCreate(workspace);
    const results = await workspacesApi.createWorkspace(mappingWorkspace);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        if (results.data !== undefined) {
            if (workspace.url_image !== '') {
                await createWorkspaceLogoApi(results.data, workspace.url_image);
            }
        }
        id = results.data
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

export async function updateWorkspaceApi(workspace) {
    const mappingWorkspace = mapToWorkspaceBackendForUpdate(workspace);
    const results = await workspacesApi.updateWorkspace(mappingWorkspace);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        if (results.data !== undefined) {
            await updateWorkspaceLogoApi(workspace.id, workspace.url_image);
        }
        id = results.data
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

export async function createWorkspaceLogoApi(workspaceId, content) {
    return await workspacesApi.updateWorkspaceLogo(workspaceId, content);
}

export async function updateWorkspaceLogoApi(workspaceId, content) {
    return await workspacesApi.updateWorkspaceLogo(workspaceId, content);
}

export async function updateWorkspaceStatusApi(id, status) {
    let statusMap = (status) ? 'Enabled' : 'Disabled';
    const results = await workspacesApi.updateWorkspaceStatus(id, statusMap);
    const data = {
        status: results.data
    }

    return data;
}

export async function updateWorkspaceActiveApi(id, status) {
    const results = await workspacesApi.updateWorkspaceStatus(id, status);
    const data = {
        status: results.data
    }

    return data;
}

export async function deleteWorkspaceApi(id) {
    const results = await workspacesApi.deleteWorkspace(id);

    const data = {
        status: results.data
    }

    return data;
}
function mapWorkspaceNameFrontend(workspacenData) {
    let results = {};

    let w = workspacenData;

    results.workspace = {
        id: w.id,
        name: w.name.toLowerCase().replaceAll(" ", "+")
    }

    if (workspacenData === undefined) {
        results.error = "Workspace name is not found.";
    }

    return results;
}

function mapWorkspaceIdFrontend(workspacenData, workspaceName) {
    let results = {};

    let w = workspacenData;
;

    results.workspace = {
        id: w,
        name: workspaceName
    }

    if (workspacenData === undefined) {
        results.error = "Workspace id is not found.";
    }

    return results;
}

function mapToEmptyWorkspaceFrontend(errorStatus) {
    let results = {};

    results.error = errorStatus;

    return results;
}

function mapToWorkspacesFrontend(data) {
    let results = {};
    let workspaces = [];
    for (let i in data.results) {
        let w = data.results[i];
        let workspace = {
            id: w.id,
            url_image: (w.logo === undefined) ? getThumbnailDefaultImage() : w.logo,
            workspace_name: w.name,
            workspace_title: w.title,
            created_date: moment(w.createdDate).format("YYYY/MM/DD HH:mm"),
            status: w.status,
            enabled: w.enabled,
            owner_count: w.ownerCount,
            member_count: w.memberCount
        }
        workspaces.push(workspace);
    }

    results.workspaces = workspaces;

    return results;
}

async function mapToUserWorkspacePropertiesFrontend(workspaceData, isOwner, isSuspend) {
    let w = workspaceData;

    const width = Math.ceil((window.innerWidth - 320) * (9 / 12) * (1 / 3));
    const height = Math.ceil(0.77 * width);

    w.logo = await workspacesApi.getWorkspaceLogo(w.id, width, height);
    let imageProps = (w.logo !== undefined) ? getImageSize(w.logo) : {
        'width': 1200,
        'height': 900
    };

    let workspace = {};
    if (w !== undefined) {
        workspace = {
            id: w.id,
            url_image: (w.logo === undefined) ? getDefaultImage() : w.logo,
            image_width: imageProps.width,
            image_height: imageProps.height,
            image_aspect_ratio: imageProps.width / imageProps.height,
            workspace_name: w.name,
            workspace_title: w.title,
            due_date: moment(new Date()).format("MM-DD-YYYY"),
            created_date: moment(w.createdDate).format("YYYY/MM/DD HH:mm"),
            status: w.status,
            enabled: w.enabled,
            workspace_desc: normalizeTextDescription(w.description),
            domain_url: (w.domainUrl !== undefined && w.domainUrl !== null) ? w.domainUrl : "",
            video_url: (w.videoUrl !== undefined && w.videoUrl !== null) ? w.videoUrl : "",
            workspace_url: (w.url !== undefined && w.url !== null) ? w.url : "",
            workspace_name_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Name').map(x => x.locked)[0] : true,
            workspace_desc_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Description').map(x => x.locked)[0] : true,
            workspace_title_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Title').map(x => x.locked)[0] : true,
            logo_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Logo').map(x => x.locked)[0] : true,
            public_access_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Public_Access').map(x => x.locked)[0] : true,
            users_to_register_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Allow_Users_To_Register').map(x => x.locked)[0] : true,
            focus_area_access_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Focus_Area_Access').map(x => x.locked)[0] : true,
            application_access_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Application_Access').map(x => x.locked)[0] : true,
            domain_url_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Domain_Url').map(x => x.locked)[0] : true,
            video_url_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Video_Url').map(x => x.locked)[0] : true,
            workspace_url_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Url').map(x => x.locked)[0] : true,
            owner: isOwner,
            suspendedUser: isSuspend,
            ownerCount: w.ownerCount,
            memberCount: w.memberCount,
            allow_public_access: w.allowPublicAccess,
            allow_users_to_register: w.allowUsersToRegister,
            all_focus_area_access: (w.focusAreaAccess === 'All') ? true : false,
            all_application_access: (w.applicationAccess === 'All') ? true : false
        };
    }
    return workspace;
}

async function mapToUserWorkspaceLandingPropertiesFrontend(workspaceData, initials, isOwner, workspaceBanner, isSuspend) {
    let w = workspaceData;

    w.logo = await workspacesApi.getWorkspaceLogo(w.id, 143, 108);
    let imageProps = (w.logo !== undefined) ? getImageSize(w.logo) : {
        'width': 1200,
        'height': 900
    };

    let workspace = {};
    if (w !== undefined) {
        workspace = {
            id: w.id,
            url_image: (w.logo === undefined) ? getDefaultImage() : w.logo,
            image_width: imageProps.width,
            image_height: imageProps.height,
            image_aspect_ratio: imageProps.width / imageProps.height,
            workspace_name: w.name,
            workspace_title: w.title,
            due_date: moment(new Date()).format("MM-DD-YYYY"),
            created_date: moment(w.createdDate).format("YYYY/MM/DD HH:mm"),
            status: w.status,
            enabled: w.enabled,
            workspace_desc: normalizeTextDescription(w.description),
            domain_url: (w.domainUrl !== undefined && w.domainUrl !== null) ? w.domainUrl : "",
            video_url: (w.videoUrl !== undefined && w.videoUrl !== null) ? w.videoUrl : "",
            workspace_url: (w.url !== undefined && w.url !== null) ? w.url : "",
            workspace_name_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Name').map(x => x.locked)[0] : true,
            workspace_desc_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Description').map(x => x.locked)[0] : true,
            workspace_title_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Title').map(x => x.locked)[0] : true,
            logo_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Logo').map(x => x.locked)[0] : true,
            public_access_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Public_Access').map(x => x.locked)[0] : true,
            users_to_register_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Allow_Users_To_Register').map(x => x.locked)[0] : true,
            focus_area_access_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Focus_Area_Access').map(x => x.locked)[0] : true,
            application_access_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Application_Access').map(x => x.locked)[0] : true,
            domain_url_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Domain_Url').map(x => x.locked)[0] : true,
            video_url_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Video_Url').map(x => x.locked)[0] : true,
            workspace_url_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Url').map(x => x.locked)[0] : true,
            owner: isOwner,
            suspendedUser: isSuspend,
            ownerCount: w.ownerCount,
            memberCount: w.memberCount,
            workspace_landing_banner: workspaceBanner.image,
            workspace_landing_banner_aspect_ratio: workspaceBanner.image_aspect_ratio,
            allow_public_access: w.allowPublicAccess,
            allow_users_to_register: w.allowUsersToRegister,
            all_focus_area_access: (w.focusAreaAccess === 'All') ? true : false,
            all_application_access: (w.applicationAccess === 'All') ? true : false,
            user_picture: (initials.picture !== undefined && initials.picture !== "") ? initials.picture : (initials.initial !== "") ? "" : getDefaultPhotoPicture(),
            user_initial: initials.initial
        };
    }

    return workspace;
}

function mapToWorkspaceBackendForCreate(data) {
    let workspace = {};

    workspace.name = data.workspace_name;
    workspace.title = data.workspace_title;
    workspace.status = data.status;
    workspace.dueDate = data.due_date;
    workspace.description = data.workspace_desc;
    workspace.domainUrl = data.domain_url;
    workspace.videoUrl = data.video_url;
    workspace.url = data.workspace_url;
    workspace.allowPublicAccess = data.allow_public_access;
    workspace.allowUsersToRegister = data.allow_users_to_register;
    workspace.focusAreaAccess = (data.all_focus_area_access) ? 'All' : 'None';
    workspace.applicationAccess = (data.all_application_access) ? 'All' : 'None';

    return workspace;
}

function mapToWorkspaceBackendForUpdate(data) {
    let workspace = {};
    const setting = [{
        propertyName: 'Name',
        locked: data.workspace_name_locked
    },
    {
        propertyName: 'Description',
        locked: data.workspace_desc_locked
    },
    {
        propertyName: 'Title',
        locked: data.workspace_title_locked
    },
    {
        propertyName: 'Logo',
        locked: data.logo_locked
    },
    {
        propertyName: 'Public_Access',
        locked: data.public_access_locked
    },
    {
        propertyName: 'Allow_Users_To_Register',
        locked: data.users_to_register_locked
    },
    {
        propertyName: 'Domain_Url',
        locked: data.domain_url_locked
    },
    {
        propertyName: 'Video_Url',
        locked: data.video_url_locked
    },
    {
        propertyName: 'Url',
        locked: data.workspace_url_locked
    },
    {
        propertyName: 'Focus_Area_Access',
        locked: data.focus_area_access_locked
    },
    {
        propertyName: 'Application_Access',
        locked: data.application_access_locked
    }];

    workspace.id = data.id;
    workspace.name = data.workspace_name;
    workspace.title = data.workspace_title;
    workspace.enabled = data.enabled;
    workspace.status = data.status;
    workspace.dueDate = data.due_date;
    workspace.description = data.workspace_desc;
    workspace.domainUrl = data.domain_url;
    workspace.videoUrl = data.video_url;
    workspace.url = data.workspace_url;
    workspace.allowPublicAccess = data.allow_public_access;
    workspace.allowUsersToRegister = data.allow_users_to_register;
    workspace.focusAreaAccess = (data.all_focus_area_access) ? 'All' : 'None';
    workspace.applicationAccess = (data.all_application_access) ? 'All' : 'None';
    workspace.lockProperties = setting;

    return workspace;
}