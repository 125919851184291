exports.defaultWorkspacesPageSize=20;
exports.defaultWorkspacesSortingBy="Name";
exports.defaultWorkspacesSortingType="Ascending";
exports.defaultApplicationsPageSize=20;
exports.defaultApplicationsSortingBy="Name";
exports.defaultApplicationsSortingType="Ascending";
exports.defaultApplicationsSettingsPageSize=1000;
exports.defaultApplicationsSettingsSortingBy="Name";
exports.defaultApplicationsSettingsSortingType="Ascending";
exports.defaultWorkspacesMembersPageSize=20;
exports.defaultWorkspacesMembersSortingBy="UserName";
exports.defaultWorkspacesMembersSortingType="Ascending";
exports.defaultWorkspacesFocusAreasPageSize=100;
exports.defaultWorkspacesFocusAreasSortingBy="focusAreaName";
exports.defaultWorkspacesFocusAreasSortingType="Ascending";
exports.defaultUsersPageSize=20;
exports.defaultUsersSortingBy="UserName";
exports.defaultUsersSortingType="Ascending";
exports.defaultUsersSearchType="Contains";
exports.defaultUsersSearchFor="UserName";
exports.defaultUsersSearchSortType="Ascending";
exports.defaultAvailableWorkspacesPageIndex=1;
exports.defaultAvailableWorkspacesPageSize=5;
exports.defaultUsersSortingType="Ascending";
exports.defaultUsersSearchType="Contains";
exports.defaultUsersSearchFor="UserName";
exports.defaultUsersSearchSortType="Ascending";
exports.showSwitchButton=false; 
exports.sessionExpiresTime=172800;
exports.anonymousUserId="00000000-0000-0000-0000-000000000000";
exports.anonymousUserName="Anonymous";
exports.anonymousUserRole="User";
exports.head=170;
exports.itemHeight=100;
exports.tHead=42;
exports.ghead=330;
exports.appItemHeight=185;
exports.maximumApplications=10000;
exports.maximumWorkspaces=10000;
exports.appFolder="portal";
exports.routeFolder="portal";