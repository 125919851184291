import {
    getWorkspaceSettingLogoByIdApi
} from '../backend/domain/workspacesSettings';

import { clearPortalLogoAction } from '../actions/clearPortalLogoAction';
import { getPortalLogoAction } from '../actions/getPortalLogoAction';
import { loadingPortalLogoAction } from '../actions/loadingPortalLogoAction';
import { overrideWorkspaceId } from '../utils/workspaces';

export const getPortalLogoThunk = (id) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(id);
        dispatch(loadingPortalLogoAction(true));
        dispatch(clearPortalLogoAction([]));
        getWorkspaceSettingLogoByIdApi(wId)
            .then((response) => {                
                if (!response.error) {
                    dispatch(getPortalLogoAction(response));                    
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalLogoAction(false));
                return response;
            });
    };