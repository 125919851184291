import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUrlWorkspaceId } from '../../utils/url';
import { updateContactOwnersAction } from '../../actions/updateContactOwnersAction';
import { clearContactOwnersAction } from '../../actions/clearContactOwnersAction';
import { postEmailToOwnersThunk } from '../../api/contactOwnersApiThunk';
import Rummernote from 'rummernote/build/bs4';
import { validateEmail } from '../../utils/validations';
import { updateToasterAction } from './../../actions/updateToasterAction';

class ContactOwnersBody extends Component {

    static propTypes = {
        role: PropTypes.object.isRequired,
        portalRouter: PropTypes.object.isRequired,
        account: PropTypes.object.isRequired,
        contactOwners: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        dispatchUpdateOwners: PropTypes.func.isRequired,
        sendEmailToOwners: PropTypes.func.isRequired,

    }

    constructor(props) {
        super(props);
        this.state = {
        }

        this.handleOnChange = this.handleOnChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.keyPressed = this.keyPressed.bind(this);
        this.onEditorChange = this.onEditorChange.bind(this)
        this.textInput = React.createRef();
        this.textInputEmail = React.createRef();
    }

    resetState() {
        let contact = {};
        this.props.dispatchClearOwners(contact);
    }

    onEditorChange(text) {
        let contact = {
            ...this.props.contactOwners,
            emailContent: text
        };
        this.props.dispatchUpdateOwners(contact);
    }

    getCurrentWorkspaceId() {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    handleBack() {
        this.resetState();
        const nav = this.props.portalRouter;
        this.props.history.push(nav.name);
    }

    keyPressed(e) {
        e.preventDefault();
    }

    onSubmit(e) {
        e.preventDefault();
        const workspaceName = this.getCurrentWorkspaceId();
        const { contactOwners, account, role, sendEmailToOwners } = this.props;
        let messages;
        if (role.user_id) {
            let email_address = '';
            if (account.use_personal) {
                email_address = account.personal.email_address
            } else {
                email_address = account.public.email_address
            }
            if (contactOwners.emailContent !== "") {
                messages = {
                    email: email_address,
                    userId: role.user_id,
                    subject: contactOwners.subject,
                    content: contactOwners.emailContent,
                    workspaceName: workspaceName
                }

                sendEmailToOwners(messages);
            }
            else {
                messages = {
                    status: "Invalid Email",
                    message: '',
                    error: 'Email content cannot be empty',
                    show_dialog: true,
                    redirect: false,
                }
                this.props.updateToasterAction(messages);
            }
        } else {
            if (validateEmail(contactOwners.email)) {
                messages = {
                    email: contactOwners.email,
                    userId: '',
                    subject: contactOwners.subject,
                    content: contactOwners.emailContent,
                    workspaceName: workspaceName
                }
                sendEmailToOwners(messages);
            } else {
                messages = {
                    status: "Invalid Email",
                    message: '',
                    error: 'Invalid Email Address',
                    show_dialog: true,
                    redirect: false,
                }
                this.props.updateToasterAction(messages);
            }
        }
    }

    handleOnChange(e) {
        const { name, value } = e.target;
        let contact = {
            ...this.props.contactOwners,
            [name]: value
        };
        this.props.dispatchUpdateOwners(contact);
    }

    componentDidMount() {
        const { user_id } = this.props.role;
        if (user_id) {
            this.textInput.current.focus();
        } else {
            this.textInputEmail.current.focus();
        }
    }

    render() {
        const { user_name, user_id } = this.props.role;
        const { emailContent, subject, email } = this.props.contactOwners;
        return (
            <div className="container-fluid h-100">
                <div className="mcp">
                    <div className="mainHead">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="float-left mt-2 mr-4">Contact Owners</h2>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className="mainBody">
                        <div className="message">

                            <form className="formWrap" onSubmit={this.onSubmit} >
                                <div className="row">
                                    <div className="col-10">
                                        {user_id === '' ?
                                            <div className="inputForm">
                                                <input className="form-control" ref={this.textInputEmail}
                                                    type="text" name="email" onChange={this.handleOnChange} value={email} required />
                                                <label>From</label>
                                            </div>
                                            :
                                            <div className="mb-2 detail"><span className="label">From :</span> &nbsp;&nbsp;{user_name}
                                                <span></span>
                                            </div>}
                                        <div className="mb-2 detail"><span className="label">To :</span>&nbsp;&nbsp;
                                                <span> Workspace Owner(s)  </span>
                                        </div>
                                    </div>
                                    <div className="col-2 text-right">
                                        <button type="button" className="btn iconBtn mr-2" onClick={this.handleBack} ><span
                                            className="icon-wrd-close"></span><br />
                                            Cancel</button>
                                        <button type="submit" className="btn iconBtn" ><span
                                            className="icon-wrd-registration"></span><br />
                                            Send</button>
                                    </div>
                                </div>
                                <hr className="grey" />
                                <div className="row">
                                    <div className="col-12 mt-2">
                                        <div className="summernote">
                                            <div className="inputForm">
                                                <input className="form-control" ref={this.textInput} type="text" name="subject"
                                                    onChange={this.handleOnChange} value={subject} required />
                                                <label>Subject</label>
                                            </div>
                                            <Rummernote value={emailContent} onChange={this.onEditorChange}
                                                placeholder='Type message here'
                                                onImgUpload={this.uploadImg} options={{
                                                    toolbar: [],
                                                    airMode: true,
                                                    tabsize: 2,
                                                    height: 300
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = ({ detail, contactOwners, loading, role, portalRouter }) => ({
    role: role,
    account: detail.account,
    loading: loading.contactOwners.loading,
    contactOwners: contactOwners,
    portalRouter
});

const mapDispatchToProps = (dispatch) => ({
    updateToasterAction: (toaster) => dispatch(updateToasterAction(toaster)),
    dispatchUpdateOwners: (contactOwners) => dispatch(updateContactOwnersAction(contactOwners)),
    dispatchClearOwners: (contactOwners) => dispatch(clearContactOwnersAction(contactOwners)), 
    sendEmailToOwners: (content) => dispatch(postEmailToOwnersThunk(content)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactOwnersBody));
