import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GridContainer from '../Grid/GridContainer';
import WorkspacesFocusAreasMap from '../Map/WorkspacesFocusAreasMap';
import { updateDialogWorkspacesFocusAreasAction } from '../../../../actions/updateDialogWorkspacesFocusAreasAction';
import { updateWorkspacesFocusAreasWithThunk } from '../../../../api/workspacesFocusAreasThunk';
import { getFocusAreasHeaders } from '../../../../utils/focusAreas';
import { getSelectedFocusArea } from '../../../../utils/map';
import { routeCodes } from '../../../../configurations/routes';

class WorkspacesFocusAreasBody extends Component {
    constructor(props) {
        super(props);
        this.createFocusArea = this.createFocusArea.bind(this);
        this.saveFocusArea = this.saveFocusArea.bind(this);
        this.selectFocusArea = this.selectFocusArea.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        focusAreas: PropTypes.array.isRequired,
        updateWorkspacesFocusAreas: PropTypes.func.isRequired,
        dispatchDialogWorkspacesFocusAreas: PropTypes.func.isRequired
    };

    createFocusArea() {
        let focusAreas = {
            dialog_title: 'Create Focus Area',
            dialog_type: 'create_focus_area',
            show_dialog: true
        };

        this.props.dispatchDialogWorkspacesFocusAreas(focusAreas);
    }

    createFocusAreaShape() {
        const { workspace } = this.props;
        this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/areas/shape");
    }

    saveFocusArea() {
        const { workspace, focusAreas } = this.props;
        this.props.updateWorkspacesFocusAreas(workspace.id, focusAreas);
    }

    selectFocusArea(item) {
        getSelectedFocusArea(item);
    }

    render() {
        const { focusAreas } = this.props;
        const headers = getFocusAreasHeaders();

        return (
            <div className="mainBody">
                <div className="row mt-4">
                    <div className="col-xl-6">
                        <div id="map-focus-area-container" className="mapContainer h-100">
                            <WorkspacesFocusAreasMap />
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="row">
                            <div className="col-12">
                                <button className="btn icoTextBtn btn-wrd_default mr-3 popupTrigger" onClick={() => this.createFocusArea()} >
                                    <span className="icon-wrd-add"></span>
                                    Create
                                </button>
                                <button className="btn icoTextBtn btn-wrd_default mr-3 popupTrigger" onClick={() => this.createFocusAreaShape()}>
                                    <span className="icon-wrd-shapes"></span>
                                    Create From Shape File
                                </button>
                                <button className="btn icoTextBtn btn-wrd_default mr-3 popupTrigger" onClick={() => this.saveFocusArea()}>
                                    <span className="icon-wrd-checkmark"></span>
                                    Save
                                </button>
                            </div>
                        </div>
                        <div className="container-fluid">
                            {focusAreas.length > 0 ?
                                <GridContainer headers={headers} items={focusAreas} selected={this.selectFocusArea} />
                                : ''}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, focusAreas }) => ({
    workspace: detail.workspace,
    focusAreas
});

const mapDispatchToProps = (dispatch) => ({
    updateWorkspacesFocusAreas: (id, focusAreas) => dispatch(updateWorkspacesFocusAreasWithThunk(id, focusAreas)),
    dispatchDialogWorkspacesFocusAreas: (dialog) => dispatch(updateDialogWorkspacesFocusAreasAction(dialog))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesFocusAreasBody));