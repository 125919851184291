import sort from 'fast-sort';
import { GET_WORKSPACES_APPLICATIONS_USERS_SETTINGS, UPDATE_WORKSPACES_APPLICATIONS_USERS_SETTINGS, CLEAR_WORKSPACES_APPLICATIONS_USERS_SETTINGS } from '../actions/index';

const getWorkspacesApplicationsUsersSettings = (workspacesApplicationsUsersSettings = [], action) => {
	let itemsKeys = [];
	let settingsKeys = action.settings.reduce((keys, setting) => {
		itemsKeys.push(setting.id);
		keys = settingsKeys;
		return keys;
	}, []);

	let result = workspacesApplicationsUsersSettings.filter((setting) => {
		return settingsKeys.indexOf(setting.id) === -1;
	});

	const results = result.concat(action.settings);
	return sort(results).asc("group_name");
}

const updateWorkspacesApplicationsUsersSettings = (workspacesApplicationsUsersSettings = [], action) => {
	const settings = workspacesApplicationsUsersSettings.map((item) => {
		const changesSettings = action.settings.filter((change) => change.id === item.id)
		if (changesSettings.count > 0) {
			return changesSettings[0];
		}

		return item
	})

	return sort(settings).asc("group_name");
}

const clearWorkspacesApplicationsUsersSettings = (action) => {
	return action.settings;
}

export const workspacesApplicationsUsersSettings = (workspacesApplicationsUsersSettings = [], action) => {
	switch (action.type) {
		case GET_WORKSPACES_APPLICATIONS_USERS_SETTINGS: return getWorkspacesApplicationsUsersSettings(workspacesApplicationsUsersSettings, action);
		case UPDATE_WORKSPACES_APPLICATIONS_USERS_SETTINGS: return updateWorkspacesApplicationsUsersSettings(workspacesApplicationsUsersSettings, action);
		case CLEAR_WORKSPACES_APPLICATIONS_USERS_SETTINGS: return clearWorkspacesApplicationsUsersSettings(action);
		default: return workspacesApplicationsUsersSettings;
	}
};