import { getUserIdByNameApi, getUserNameByIdApi } from '../backend/domain/users';
import { isValidGuid } from './index';
import { store } from '../index';
import { getUsersAction } from '../actions/getUsersAction';
import { updateUsersAction } from '../actions/updateUsersAction';
import { clearUsersAction } from '../actions/clearUsersAction';

export const getUserIdByName = (userName) => {
    let result = getUserIdByNameApi(userName);
    return result;
}

export const getUserNameById = (userId) => {
    let result = getUserNameByIdApi(userId);
    return result;
}

export const overrideUserId = async (id) => {
    if (id !== undefined) {
        let checkId = isValidGuid(id);
        if (!checkId) {
            let result = await getUserIdByName(id);
            id = result.user.id;
        }
    }

    return id;
}

export const getUserHeaders = () => {
    const headers = [
        { id: 1, header_name: "Picture", sort_column: "UserName" },
        { id: 2, header_name: "User Detail", sort_column: "UserName" },
        { id: 3, header_name: "Organization", sort_column: "Organization" },
        { id: 4, header_name: "Role", sort_column: "Role" },
        { id: 5, header_name: "Contact", sort_column: "Email" },
        { id: 6, header_name: "Registration", sort_column: "RegistrationStatus" },
        { id: 7, header_name: "Status", sort_column: "UserStatus" }
    ];

    return headers;
};

export const updateUsers = (users) => {
    const currentUsers = store.getState().users;
    let u = [];

    u = u.concat(currentUsers);
    u = u.concat(users);

    store.dispatch(getUsersAction(u))
} 

export const updateUsersStatus = (userId, status) => {
    const users = store.getState().users;

    for (let i in users) {
        let user = users[i];
        if (user.uid === userId) {
            user.user_status = status;
            break;
        }
    }

    store.dispatch(updateUsersAction(users))
} 

export const updateUsersRole = (userId, role) => {
    const users = store.getState().users;

    for (let i in users) {
        let user = users[i];
        if (user.uid === userId) {
            user.user_role = role;
            break;
        }
    }

    store.dispatch(updateUsersAction(users))
} 

export const updateUsersRemove = (userId) => {
    const users = store.getState().users;
    const us = users.filter((item) => item.uid !== userId);

    store.dispatch(clearUsersAction(us))
}