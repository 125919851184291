import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import ImageEditor from '../../../Editor/ImageEditor';
import BannerModal from 'react-modal';
import BannerEditor from '../../../Editor/ImageEditor';
import { modalImageEditorStyle } from '../../../../utils/modal';
import { updateWorkspaceSettingAction } from '../../../../actions/updateWorkspaceSettingAction';
import { updateWorkspaceSettingWithThunk } from '../../../../api/workspacesSettingsThunk';
import { updateRoleAction } from '../../../../actions/updateRoleAction';
import { showSwitchButton } from '../../../../configurations/app';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../../../configurations/routes';
import { defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize } from '../../../../configurations/app';
import { getUserAvailableWorkspacesWithThunk } from '../../../../api/userAvailableWorkspacesThunk';
import UserAvailableWorkspacesContainer from '../../UserAvailableWorkspaces/UserAvailableWorkspacesContainer';
import { updateDialogWorkspaceSettingsErrorAction } from '../../../../actions/updateDialogWorkspaceSettingsErrorAction';
import { updateAvailableWorkspacesOpenedStatus } from '../../../../utils/workspaces';

class WorkspacesGeneralSettingsBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            src: null,
            bannerSrc: null,
            imageEditorIsOpen: false,
            bannerEditorIsOpen: false,
            currentUserRole: props.role.user_role,
            isLockedPortalLogo: props.workspaceSetting.portal_logo_locked,
            isLockedThemes: props.workspaceSetting.themes_locked,
            isLockedBanner: props.workspaceSetting.banner_locked,
            currentSelectedThemes: props.workspaceSetting.chosen_themes,
            currentSelectedBanner: props.workspaceSetting.chosen_banner,
            disabledTrackingId: !props.workspaceSetting.useGoogleAnalytics,
            isNoLogoChecked: false,
            isDefaultLogoChecked: false,
            isWorkspaceLogoChecked: false,
            isNewLogoChecked: false,
            isDarkThemesChecked: false,
            isLightThemesChecked: false,
            isNoBannerChecked: false,
            isCustomBannerChecked: false,
            wsListOpened: false,
            firstOpen: true
        };
        this.switchUserMode = this.switchUserMode.bind(this);
        this.roleButtonSwitchText = this.roleButtonSwitchText.bind(this);
        this.onSelectFile = this.onSelectFile.bind(this);
        this.onSelectBannerFile = this.onSelectBannerFile.bind(this);
        this.onCloseImageEditor = this.onCloseImageEditor.bind(this);
        this.onCloseBannerEditor = this.onCloseBannerEditor.bind(this);
        this.handlePortalLogoLocked = this.handlePortalLogoLocked.bind(this);
        this.handleThemesLocked = this.handleThemesLocked.bind(this);
        this.handleBannerLocked = this.handleBannerLocked.bind(this);
        this.selectedThemes = this.selectedThemes.bind(this);
        this.selectedBanner = this.selectedBanner.bind(this);
        this.handleDarkThemesChange = this.handleDarkThemesChange.bind(this);
        this.handleLightThemesChange = this.handleLightThemesChange.bind(this);
        this.handleNoBannerChange = this.handleNoBannerChange.bind(this);
        this.handleCustomBannerChange = this.handleCustomBannerChange.bind(this);
        this.onhandleChange = this.onhandleChange.bind(this);
        this.onTrackingIdChange = this.onTrackingIdChange.bind(this);
        this.hideList = this.hideList.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        workspaceSetting: PropTypes.object.isRequired,
        dispatchWorkspaceSetting: PropTypes.func.isRequired,
        updateWorkspaceSetting: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired,
        dispatchRole: PropTypes.func.isRequired
    };

    onhandleChange(e) {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const name = e.target.name;
        const workspaceSetting = {
            ...this.props.workspaceSetting,
            [name]: value
        };
        this.setState({ disabledTrackingId: !workspaceSetting.useGoogleAnalytics });
        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    onTrackingIdChange(e) {
        const value = e.target.value;
        const workspaceSetting = {
            ...this.props.workspaceSetting,
            googleAnalyticsTrackingId: value
        };
        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    roleButtonSwitchText(roleName) {
        return (roleName === 'Admin') ? 'Owner' : 'Admin';
    }

    switchUserMode(switchToRole) {
        const role = {
            ...this.props.role,
            user_role: switchToRole
        }

        this.props.dispatchRole(role);
        this.setState({ currentUserRole: switchToRole });
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({ src: reader.result, imageEditorIsOpen: true });
            }
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onSelectBannerFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size < 10000000) {
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                    this.setState({ bannerSrc: reader.result, bannerEditorIsOpen: true });
                }
                );
                reader.readAsDataURL(e.target.files[0]);
            }
            else {
                let workspace_settings_update_error = {
                    dialog_title: 'Failed',
                    dialog_type: 'Maximum allowed image size is 10 Mb',
                    show_dialog: true
                };

                this.props.dispatchDialogWorkspaceSettingsError(workspace_settings_update_error);
            }
        }
    };

    onCloseImageEditor(image, aspectRatio) {
        this.setState({ imageEditorIsOpen: false });

        const workspaceSetting = {
            ...this.props.workspaceSetting,
            new_url_image: image,
            new_image_aspect_ratio: aspectRatio
        };

        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    onCloseBannerEditor(image, aspectRatio) {
        this.setState({ bannerEditorIsOpen: false });

        const workspaceSetting = {
            ...this.props.workspaceSetting,
            custom_url_banner: image,
            custom_banner_aspect_ratio: aspectRatio
        };

        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    handlePortalLogoLocked() {
        if (this.state.isLockedPortalLogo === true) {
            let workspaceSetting = {
                ...this.props.workspaceSetting,
                portal_logo_locked: false

            };
            this.props.dispatchWorkspaceSetting(workspaceSetting);
        }
        else {
            let workspaceSetting = {
                ...this.props.workspaceSetting,
                portal_logo_locked: true
            };
            this.props.dispatchWorkspaceSetting(workspaceSetting);
        }
        this.setState({ isLockedPortalLogo: !this.state.isLockedPortalLogo });
    }

    handleThemesLocked() {
        if (this.state.isLockedThemes === true) {
            let workspaceSetting = {
                ...this.props.workspaceSetting,
                themes_locked: false

            };
            this.props.dispatchWorkspaceSetting(workspaceSetting);
        }
        else {
            let workspaceSetting = {
                ...this.props.workspaceSetting,
                themes_locked: true
            };
            this.props.dispatchWorkspaceSetting(workspaceSetting);
        }
        this.setState({ isLockedThemes: !this.state.isLockedThemes });
    }

    handleBannerLocked() {
        if (this.state.isLockedBanner === true) {
            let workspaceSetting = {
                ...this.props.workspaceSetting,
                banner_locked: false

            };
            this.props.dispatchWorkspaceSetting(workspaceSetting);
        }
        else {
            let workspaceSetting = {
                ...this.props.workspaceSetting,
                banner_locked: true
            };
            this.props.dispatchWorkspaceSetting(workspaceSetting);
        }
        this.setState({ isLockedBanner: !this.state.isLockedBanner });
    }

    selectedThemes(currentChosenThemes) {
        if (currentChosenThemes === 'Dark') {
            this.setState({ isDarkThemesChecked: true })
        } else {
            this.setState({ isLightThemesChecked: true })
        }
    }

    selectedBanner(currentChosenBanner) {
        if (currentChosenBanner === 'Custom') {
            this.setState({ isCustomBannerChecked: true })
        } else {
            this.setState({ isNoBannerChecked: true })
        }
    }

    handleDarkThemesChange() {
        let workspaceSetting = {
            ...this.props.workspaceSetting,
            chosen_themes: "Dark"
        };
        this.props.dispatchWorkspaceSetting(workspaceSetting);
        this.setState({ isDarkThemesChecked: true, isLightThemesChecked: false })
    }

    handleLightThemesChange() {
        let workspaceSetting = {
            ...this.props.workspaceSetting,
            chosen_themes: "Light"
        };
        this.props.dispatchWorkspaceSetting(workspaceSetting);
        this.setState({ isDarkThemesChecked: false, isLightThemesChecked: true })
    }

    handleNoBannerChange() {
        let workspaceSetting = {
            ...this.props.workspaceSetting,
            chosen_banner: "NoBanner"
        };
        this.props.dispatchWorkspaceSetting(workspaceSetting);
        this.setState({ isNoBannerChecked: true, isCustomBannerChecked: false })
    }

    handleCustomBannerChange() {
        let workspaceSetting = {
            ...this.props.workspaceSetting,
            chosen_banner: "Custom"
        };
        this.props.dispatchWorkspaceSetting(workspaceSetting);
        this.setState({ isNoBannerChecked: false, isCustomBannerChecked: true })
    }

    getAspectRatioClass() {
        const { workspace_image_aspect_ratio } = this.props.workspaceSetting;

        let aspectRatioClass = 'crop-logo43';
        if (workspace_image_aspect_ratio >= 1 && workspace_image_aspect_ratio < 1.2) {
            aspectRatioClass = 'crop-logo11';
        }
        else if (workspace_image_aspect_ratio >= 1.2 && workspace_image_aspect_ratio < 1.4) {
            aspectRatioClass = 'crop-logo43';
        }
        else if (workspace_image_aspect_ratio >= 1.4 && workspace_image_aspect_ratio < 1.6) {
            aspectRatioClass = 'crop-logo32';
        }
        else if (workspace_image_aspect_ratio >= 1.6 && workspace_image_aspect_ratio < 2.3) {
            aspectRatioClass = 'crop-logo169';
        }
        else if (workspace_image_aspect_ratio >= 2.3) {
            aspectRatioClass = 'crop-logo219';
        }

        return aspectRatioClass;
    }

    availableWorkspacesSwitch() {
        this.setState({ wsListOpened: !this.state.wsListOpened });
        this.setState({ firstOpen: false });
        if (this.state.wsListOpened === false) {
            const { role } = this.props;
            this.props.getUserAvailableWorkspaces(role.user_id, role.user_role, defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize);
        }
        else {
            updateAvailableWorkspacesOpenedStatus(false);
        }
    }

    updateWorkspaceSetting() {
        const { workspaceSetting } = this.props;
        this.props.updateWorkspaceSetting(workspaceSetting);
    }

    hideList() {
        this.setState({ wsListOpened: false });
    }

    componentWillMount() {
        Modal.setAppElement('body');
        BannerModal.setAppElement('body');
        this.selectedThemes(this.state.currentSelectedThemes);
        this.selectedBanner(this.state.currentSelectedBanner);
    }

    render() {
        const { workspace, workspaceSetting, role } = this.props;
        const { src, imageEditorIsOpen, bannerSrc, bannerEditorIsOpen, disabledTrackingId } = this.state;
        const buttonText = this.roleButtonSwitchText(this.state.currentUserRole);
        return (
            <div className="mainBody">
                <div className="row mt-5">
                    <div className="col-md-12 order-lg-1 col-lg-8 col-xl-9">
                        <div className="wsHomeSection">
                            {role.user_role === 'Admin' || (role.user_role !== 'Admin' && workspaceSetting.PortalAnalytics) ?
                                <div className="groupForm mb-4">
                                    {(role.user_role === 'Admin') ?
                                        <label className="btn iconBtn customLockCheckbox">
                                            <input type="checkbox" className="lockInput" name="PortalAnalytics" checked={workspaceSetting.PortalAnalytics !== undefined ? workspaceSetting.PortalAnalytics : false} onChange={this.onhandleChange} />
                                            <span className="icon-wrd-unlock cursor-pointer" ></span>
                                            <span className="icon-wrd-lock cursor-pointer" ></span>
                                        </label>
                                        : null}
                                    <label className="sectionTitle">Analytics</label>
                                    <hr />
                                    <div className="row">
                                        <div className="custom-controls-stacked">
                                            <label className="custom-control ios-switch">
                                                <input type="checkbox" name="useGoogleAnalytics" className="ios-switch-control-input" checked={workspaceSetting.useGoogleAnalytics !== undefined ? workspaceSetting.useGoogleAnalytics : false} onChange={this.onhandleChange} />
                                                <span className="ios-switch-control-indicator"></span>
                                                <span className="text">Use Google Analytics</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="inputForm">
                                                <label>Google Tracking Id</label>
                                                <input className="form-control" type="text" name="" disabled={disabledTrackingId} onChange={this.onTrackingIdChange} defaultValue={workspaceSetting.googleAnalyticsTrackingId} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {role.user_role === 'Admin' || (role.user_role !== 'Admin' && workspaceSetting.PortalProperties) ?
                                <div className="groupForm mb-4">
                                    {(role.user_role === 'Admin') ?
                                        <label className="btn iconBtn customLockCheckbox">
                                            <input type="checkbox" className="lockInput" name="PortalProperties" checked={workspaceSetting.PortalProperties !== undefined ? workspaceSetting.PortalProperties : false} onChange={this.onhandleChange} />
                                            <span className="icon-wrd-unlock cursor-pointer" ></span>
                                            <span className="icon-wrd-lock cursor-pointer" ></span>
                                        </label>
                                        : null}
                                    <label className="sectionTitle">Portal Properties</label>
                                    <hr />
                                    <div className="row">
                                        <div className="custom-controls-stacked">
                                            <label className="custom-control ios-switch">
                                                <input type="checkbox" name="showWorkspaceName" className="ios-switch-control-input" checked={workspaceSetting.showWorkspaceName !== undefined ? workspaceSetting.showWorkspaceName : false} onChange={this.onhandleChange} />
                                                <span className="ios-switch-control-indicator"></span>
                                                <span className="text">Show workspace name in apps</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="custom-controls-stacked">
                                            <label className="custom-control ios-switch">
                                                <input type="checkbox" name="showPortalLogo" className="ios-switch-control-input" checked={workspaceSetting.showPortalLogo !== undefined ? workspaceSetting.showPortalLogo : false} onChange={this.onhandleChange} />
                                                <span className="ios-switch-control-indicator"></span>
                                                <span className="text">Show DHI logo in landing pages</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="custom-controls-stacked">
                                            <label className="custom-control ios-switch">
                                                <input type="checkbox" name="showWorkspaceLogo" className="ios-switch-control-input" checked={workspaceSetting.showWorkspaceLogo !== undefined ? workspaceSetting.showWorkspaceLogo : false} onChange={this.onhandleChange} />
                                                <span className="ios-switch-control-indicator"></span>
                                                <span className="text">Show workspace logo in landing pages</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            {(role.user_role === 'Admin' || (role.user_role !== 'Admin' && !this.state.isLockedBanner)) ?
                                <div className="groupForm mb-0">
                                    {(role.user_role === 'Admin') ?
                                        <label className="btn iconBtn customLockCheckbox">
                                            <input type="checkbox" className="lockInput" onChange={this.handleBannerLocked} checked={!this.state.isLockedBanner} />
                                            <span className="icon-wrd-unlock"></span>
                                            <span className="icon-wrd-lock"></span>
                                        </label> : null}
                                    <label className="sectionTitle">Landing Page Background Image</label>
                                    <hr />
                                    <p>Select background displayed on the top of landing page.</p>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-6 col-xl-6 mb-5">
                                            <label className="custom-checkbox custom-pick-image">
                                                <input type="radio" className="custom-control-input" name="Banner"
                                                    onChange={this.handleNoBannerChange} checked={this.state.isNoBannerChecked} ></input>
                                                <div className="imgContainer">
                                                    <img src={process.env.PUBLIC_URL + "/images/no-image-wide.png"} className="themeImg" alt="" />
                                                </div>
                                                <p><span className="icon-wrd-checkmark"></span> No background/image</p>
                                            </label>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-xl-6 mb-5">
                                            <label className="custom-checkbox custom-pick-image">
                                                <input type="radio" className="custom-control-input" name="Banner"
                                                    onChange={this.handleCustomBannerChange} checked={this.state.isCustomBannerChecked} />
                                                <div className="imgContainer">
                                                    <img src={"data:image/png;base64," + workspaceSetting.custom_url_banner} className="themeImg" alt="" />
                                                </div>
                                                <p><span className="icon-wrd-checkmark"></span> Use Custom Background
                                                </p>
                                            </label>
                                        </div>
                                        <div className="col-12 mb-5 browse-new-workspace-banner-setting">
                                            <div className="uploadContainer">
                                                <p>or click browse if you want to upload new background</p>
                                                <p>Maximum image size is 10 Mb</p>
                                                <button className="btn btn-wrd_default icoTextBtn browseLogo">
                                                    <label className="custom-file-upload">
                                                        <input type="file" onClick={this.handleCustomBannerChange} onChange={this.onSelectBannerFile} />
                                                        <span className="icon-wrd-folder icon-upload-folder"></span>
                                                        Browse
                                                 </label>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null
                            }
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4 col-xl-3">
                        <div className="wsLogo pb-5 text-center">
                            <div className="logoContainer wsHome text-center d-none d-lg-block">
                                <img src={process.env.PUBLIC_URL + "/images/transCircle.png"} className="insideCircle" alt="" />
                                <img src={((workspaceSetting.workspace_url_image !== undefined) ? "data:image/png;base64," : "") + workspaceSetting.workspace_url_image}
                                    className={this.getAspectRatioClass()} alt="Logo" />
                            </div>
                            <ul className="setting-menus mt-5 w-75">
                                <strong>Settings Options</strong>
                                <li>
                                    <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/general'} className="active">
                                        <span className="icon-wrd-settings"></span>General
                                    </Link>
                                </li>
                                <li>
                                    <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/login'}>
                                        <span className="icon-wrd-log-in"></span> Login
                                    </Link>
                                </li>
                                <li>
                                    <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/email'}>
                                        <span className="icon-wrd-mail-closed"></span>Email
                                     </Link>
                                </li>
                                <li>
                                    <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/theme'}>
                                        <span className="icon-wrd-themes"></span>Themes
                                     </Link>
                                </li>
                            </ul>
                            <button className="btn textBtn greenBtn w-75 mt-5" onClick={() => this.updateWorkspaceSetting()}>Update Changes</button>
                        </div>
                    </div>
                </div>
                {src &&
                    <Modal
                        isOpen={imageEditorIsOpen}
                        style={modalImageEditorStyle}
                        contentLabel="Image Editor">
                        <ImageEditor srcImage={src} onClose={this.onCloseImageEditor} />
                    </Modal>
                }
                {bannerSrc &&
                    <BannerModal
                        isOpen={bannerEditorIsOpen}
                        style={modalImageEditorStyle}
                        contentLabel="Banner Editor">
                        <BannerEditor srcImage={bannerSrc} onClose={this.onCloseBannerEditor} imgType={'banner'} />
                    </BannerModal>
                }
                <div className={(this.state.firstOpen) ? "workspaceList d-none" : (this.state.wsListOpened) ? "workspaceList open animated fadeInRight" : "workspaceList open animated fadeOutRight"}>
                    <UserAvailableWorkspacesContainer hideList={this.hideList} />
                </div>
                <button className="btn iconBtn wsListBtn" onClick={() => this.availableWorkspacesSwitch()}>
                    <span className={(this.state.wsListOpened) ? "icon-wrd-close" : "icon-wrd-ws-list"}></span>
                </button>
                {showSwitchButton &&
                    <button className="btn customBtn" onClick={() => this.switchUserMode(buttonText)}>{buttonText} Mode</button>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, role }) => ({
    workspace: detail.workspace,
    workspaceSetting: detail.workspace_setting,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchWorkspaceSetting: (workspaceSetting) => dispatch(updateWorkspaceSettingAction(workspaceSetting)),
    updateWorkspaceSetting: (workspaceSetting) => dispatch(updateWorkspaceSettingWithThunk(workspaceSetting)),
    dispatchRole: (role) => dispatch(updateRoleAction(role)),
    getUserAvailableWorkspaces: (userId, userRole, pageIndex, pageSize) => dispatch(getUserAvailableWorkspacesWithThunk(userId, userRole, pageIndex, pageSize)),
    dispatchDialogWorkspaceSettingsError: (workspaceSetting) => dispatch(updateDialogWorkspaceSettingsErrorAction(workspaceSetting)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesGeneralSettingsBody));