import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { routeCodes } from '../../configurations/routes';
import { changeNavigationAction } from '../../actions/changeNavigationAction';
import { isWorkspaceRootPage, isUnavailablePage } from '../../utils/login';
import { getUrlWorkspaceId } from '../../utils/url';

class SidebarBody extends Component {
    constructor(props) {
        super(props);
        this.changeNavigation = this.changeNavigation.bind(this);
        this.getSidebarBodyResize = this.getSidebarBodyResize.bind(this);
        this.state = {
            workspaceRoot: false,
            workspaceId: ''
        };
    }

    static propTypes = {
        navigation: PropTypes.object.isRequired,
        dispatchNavigation: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired
    };

    changeNavigation(name) {
        let navigation = {
            name
        };
        this.props.dispatchNavigation(navigation);
    }

    getSidebarBodyResize() {
        let sidebarBottomNav = document.getElementById("sidebar-bottom-nav");
        let sidebarBottomMenu = document.getElementById("sidebar-bottom-menu");
        if (sidebarBottomNav !== null && sidebarBottomMenu !== null) {
            let topNav = sidebarBottomNav.offsetTop;
            let topMenu = sidebarBottomMenu.offsetTop;
            if (topNav < topMenu) {
                sidebarBottomNav.style.visibility = "hidden";
                sidebarBottomMenu.style.visibility = "visible";
            }
            else {
                sidebarBottomNav.style.visibility = "visible";
                sidebarBottomMenu.style.visibility = "hidden";
            }
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.getSidebarBodyResize);
        if (isWorkspaceRootPage(this.props.location.pathname)) {
            this.setState({
                workspaceRoot: true,
                workspaceId: getUrlWorkspaceId(this.props.location.pathname)
            });
        }
        else if (isUnavailablePage(this.props.location.pathname)) {
            let paramsString = window.location.search;
            let params = new URLSearchParams(paramsString);
            let location = params.get('page');
            this.setState({
                workspaceRoot: true,
                workspaceId: getUrlWorkspaceId(location)
            });
        }
        setTimeout(() => {
            this.getSidebarBodyResize();
        }, 200);
    }

    render() {
        const { role } = this.props;
        const { name } = this.props.navigation;
        const { workspaceRoot, workspaceId } = this.state;

        return (
            <div className="sidebar text-center">
                {(role.user_role === 'Admin' && role.token.userStatus === 'Active') ?
                    <nav className="top-nav">
                        <ul>
                            <li className={(name === 'Workspaces') ? 'active' : ''}>
                                <Link to={routeCodes.WORKSPACES_LIST} onClick={() => this.changeNavigation('Workspaces')} title="Go to workspace">
                                    <span className="icon-wrd-workspace"></span>Workspaces
                                </Link>
                            </li>
                            <li className={(name === 'Applications') ? 'active' : ''}>
                                <Link to={routeCodes.APPLICATIONS_LIST} onClick={() => this.changeNavigation('Applications')} title="Go to applications">
                                    <span className="icon-wrd-applications"></span>Applications
                                </Link>
                            </li>
                            <li className={(name === 'Users') ? 'active' : ''}>
                                <Link to={routeCodes.USERS_LIST} onClick={() => this.changeNavigation('Users')} title="Go to users">
                                    <span className="icon-wrd-users"></span>Users
                                </Link>
                            </li>
                            <li className={(name === 'PortalSettings') ? 'active' : ''}>
                                <Link to={routeCodes.PORTAL_SETTINGS} onClick={() => this.changeNavigation('PortalSettings')} title="Go to portal settings">
                                    <span className="icon-wrd-portal"></span>Portal Settings
                                </Link>
                            </li>
                            <div id="sidebar-bottom-menu" style={{ visibility: 'hidden'}}>
                                {role.is_logged_in &&
                                    <li className={(name === 'Profile') ? 'active' : ''}>
                                        <Link to={routeCodes.PROFILE} onClick={() => this.changeNavigation('Profile')} title="Go to profile">
                                            <span className="icon-wrd-profile"></span>Profile
                                        </Link>
                                    </li>
                                }
                                {(role.is_logged_in) &&
                                    <li className={(name === 'Logout') ? 'active' : ''}>
                                        <Link to={routeCodes.USERS_LOGOUT} onClick={() => this.changeNavigation('Logout')} title="Logout">
                                            <span className="icon-wrd-power"></span>Logout
                                        </Link>
                                    </li>
                                }
                                {!role.is_logged_in &&
                                    <li className={(name === 'Login') ? 'active' : ''}>
                                        <Link to={(workspaceRoot) ? routeCodes.USERS_LOGIN + "/" + workspaceId : routeCodes.USERS_LOGIN} onClick={() => this.changeNavigation('Login')} title="Login">
                                            <span className="icon-wrd-log-in"></span>Login
                                        </Link>
                                    </li>
                                }
                            </div>
                        </ul>
                    </nav>
                    : null
                }
                <nav id="sidebar-bottom-nav" className="bottom-nav">
                    <ul>
                        {role.is_logged_in &&
                            <li className={(name === 'Profile') ? 'active' : ''}>
                                <Link to={routeCodes.PROFILE} onClick={() => this.changeNavigation('Profile')} title="Go to profile">
                                    <span className="icon-wrd-profile"></span>Profile
                            </Link>
                            </li>
                        }
                        {(role.is_logged_in) &&
                            <li className={(name === 'Logout') ? 'active' : ''}>
                                <Link to={routeCodes.USERS_LOGOUT} onClick={() => this.changeNavigation('Logout')} title="Logout">
                                    <span className="icon-wrd-power"></span>Logout
                                </Link>
                            </li>
                        }
                        {!role.is_logged_in && 
                            <li className={(name === 'Login') ? 'active' : ''}>
                            <Link to={(workspaceRoot) ? routeCodes.USERS_LOGIN + "/" + workspaceId : routeCodes.USERS_LOGIN} onClick={() => this.changeNavigation('Login')} title="Login">
                                    <span className="icon-wrd-log-in"></span>Login
                                </Link>
                            </li>
                        }
                    </ul>
                </nav>
            </div>
        );
    }
}

const mapStateToProps = ({ navigation, role }) => ({
    navigation,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchNavigation: (navigation) => dispatch(changeNavigationAction(navigation))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarBody));
