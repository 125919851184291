import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearApplicationAction } from '../../../actions/clearApplicationAction';
import { getApplicationByIdWithThunk } from '../../../api/applicationsThunk';
import { getUrlApplicationId } from '../../../utils/url';
import Loader from 'react-loader-spinner'
import SidebarContainer from '../../Sidebar/SidebarContainer';
import ApplicationsDetailHeader from './ApplicationsDetailHeader';
import ApplicationsDetailBody from './ApplicationsDetailBody';
import ApplicationsModal from '../Modal/ApplicationsModal';
import { changeNavigationAction } from '../../../actions/changeNavigationAction';
import ApplicationsCreationErrorModal from '../Modal/ApplicationsCreationErrorModal';

class ApplicationsDetailContainer extends Component {
    static propTypes = {
        application: PropTypes.object.isRequired,
        getApplicationById: PropTypes.func.isRequired,
        clearApplication: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        dispatchNavigation: PropTypes.func.isRequired
    };

    getCurrentApplicationId = () => {
        let id = getUrlApplicationId(this.props.location.pathname);
        return id;
    }

    changeNavigation(name) {
        let navigation = {
            name
        };
        this.props.dispatchNavigation(navigation);
    }

    componentDidMount() {
        const applicationId = this.getCurrentApplicationId();
        this.props.getApplicationById(applicationId);
        this.changeNavigation('Applications');
    }

    componentWillUnmount() {
        this.props.clearApplication();
    }

    render() {
        const { application, loading, loadingTheme } = this.props;

        if (application.id === undefined || application.id === '')
            return (
                <div>
                    {(loading || loadingTheme) &&
                        <div className="loader-container">
                            <Loader
                                type="Ball-Triangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                </div>
            );

        return (
            <div>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <SidebarContainer />
                        <main>
                            <div className="mapBackground" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <ApplicationsDetailHeader />
                                    <ApplicationsDetailBody />
                                </div>
                            </div>
                        </main>
                        <ApplicationsModal type="detail" />
                        <ApplicationsCreationErrorModal type="error" />
                    </div>
                }
           </div>
        );
    }
}

const mapStateToProps = ({ detail, loading, navigation }) => ({
    application: detail.application,
    loading: loading.application.loading,
    loadingTheme: loading.theme.loading,
    navigation
});

const mapDispatchToProps = (dispatch) => ({
    getApplicationById: (applicationId) => dispatch(getApplicationByIdWithThunk(applicationId)),
    clearApplication: () => dispatch(clearApplicationAction([])),
    dispatchNavigation: (navigation) => dispatch(changeNavigationAction(navigation))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationsDetailContainer));