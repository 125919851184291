import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { getPortalLandingBlogAction } from '../../../actions/getPortalLandingBlogAction';
import { getBlogAction } from '../../../api/portalLandingThunk';
import dompurify from 'dompurify';
const sanitizer = dompurify.sanitize;

class PortalLandingBlog extends Component {

    componentWillUnmount() {
        this.props.clearBlog({})
    }

    componentDidMount() {

        const { blog, id } = this.props.match.params;
        if (blog === 'features' || blog === 'news') {
            this.props.loadBlog(id, blog)
        }
    }
    render() {
        const { loading, blog } = this.props;
        return (
            <div className="feature-container w-100" style={{backgroundColor:'#f8f9fa'}}>
                {
                    loading &&
                    <div className="loader-portal-banner">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="70"
                            width="70"
                        />
                    </div>
                }
              
                    <div className="container" >
                        <div className="py-5">
                            <h1 className="font-weight-bold mb-0">
                                <div dangerouslySetInnerHTML={{ __html: sanitizer(blog.title) }} />
                            </h1>
                            {/* <img src={((blog.image !== undefined) ? "data:image/jpeg;base64," : "") + blog.image} alt="">
                                                </img> */}
                            <div dangerouslySetInnerHTML={{ __html: blog.description ? blog.description  : blog.content }} />
                        </div>
                    </div>
                </div>
        );
    }
}

const mapStateToProps = ({ loading, portalLanding }) => ({
    loading: loading.defaultLoading.loading,
    blog: portalLanding.blog
});


const mapDispatchToProps = (dispatch) => ({
    clearBlog: (blog) => dispatch(getPortalLandingBlogAction(blog)),
    loadBlog: (id, type) => dispatch(getBlogAction(id, type)),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalLandingBlog));