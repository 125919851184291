import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { getUserAccessWithThunk } from '../../../api/usersThunk';
import { getUrlUserId, getUrlWorkspaceMemberId } from '../../../utils/url';
import { isWorkspaceRootPage } from '../../../utils/login';
import { getSessionStorage } from '../../../utils/sessionStorage';
import SidebarContainer from '../../Sidebar/SidebarContainer';
import UsersAccessHeader from '../../Users/Access/UsersAccessHeader';
import UsersAccessBody from '../../Users/Access/UsersAccessBody';
import { clearUserAccessAction } from '../../../actions/clearUserAccessAction';
import UsersModal from '../Modal/UsersModal';
import ErrorSuccessModal from '../../Shared/ErrorSuccessModal';

class UsersAccessContainer extends Component {
    static propTypes = {
        userAccess: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired,
        getUserAccess: PropTypes.func.isRequired
    };

    getCurrentUserId = () => {
        let id = '';
        if (isWorkspaceRootPage(this.props.location.pathname)) {
            id = getUrlWorkspaceMemberId(this.props.location.pathname);
        }
        else {
            id = getUrlUserId(this.props.location.pathname);
        }

        return id;
    }

    scrollToTop = () => {
        this.detailStart.current.scrollIntoView({ behavior: 'smooth' })
    }

    componentDidMount() {
		const { loading } = this.props;        
        if (loading === false) {
            let width = Math.ceil((window.innerWidth - 560) * (3 / 12));
            let height = width;
            let targetId = this.getCurrentUserId();
            if (targetId === "") {
                const sessionRole = getSessionStorage("role");
                if (sessionRole !== "") {
                    const role = JSON.parse(sessionRole);
                    targetId = role.user_id;
                }        
            }
            this.props.getUserAccess(targetId, width, height);
		}
    }    

	componentWillUnmount() {
        this.props.clearUserAccess();
    }

    render() {
        const { userAccess, loading, loadingTheme } = this.props;        

        if (userAccess.user === undefined)
            return (
                <div>
                    {(loading || loadingTheme) &&
                        <div className="loader-container">
                            <Loader
                                type="Ball-Triangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                </div>
            );

        return (
            <div>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <div ref={this.detailStart} />
                        <SidebarContainer />
                        <main>
                            <div className="mapBackground" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <UsersAccessHeader />
                                    <UsersAccessBody />
                                </div>
                            </div>
                        </main>
                        <UsersModal type="detail" />
                        <ErrorSuccessModal type="error" />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ userAccess, loading, role }) => ({
    userAccess,
    loading: loading.user.loading,
    loadingTheme: loading.theme.loading,
    role:role
});

const mapDispatchToProps = (dispatch) => ({
    getUserAccess: (targetId, width, height) => dispatch(getUserAccessWithThunk(targetId, width, height)),
    clearUserAccess: (userAccess) => dispatch(clearUserAccessAction(userAccess))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersAccessContainer));