/* NAVIGATIONS */
export const CHANGE_NAVIGATION = 'CHANGE_NAVIGATION';

/* WORKSPACES */

/* WORKSPACES LIST */
export const GET_WORKSPACES = 'GET_WORKSPACES';
export const UPDATE_WORKSPACES = 'UPDATE_WORKSPACES';
export const CLEAR_WORKSPACES = 'CLEAR_WORKSPACES';
export const GET_WORKSPACES_PAGINATION = 'GET_WORKSPACES_PAGINATION';
export const CLEAR_WORKSPACES_PAGINATION = 'CLEAR_WORKSPACES_PAGINATION';
export const LOADING_WORKSPACES = 'LOADING_WORKSPACES';
export const GET_WORKSPACES_SORTING = 'GET_WORKSPACES_SORTING';
export const UPDATE_STICKY_WORKSPACES = 'UPDATE_STICKY_WORKSPACES';

/* WORKSPACE DETAIL */
export const GET_WORKSPACE = 'GET_WORKSPACE';
export const UPDATE_WORKSPACE = 'UPDATE_WORKSPACE';
export const UPDATE_DIALOG_WORKSPACES = 'UPDATE_DIALOG_WORKSPACES';
export const UPDATE_DIALOG_WORKSPACES_CONNECTIONS = 'UPDATE_DIALOG_WORKSPACES_CONNECTIONS';
export const CLEAR_WORKSPACE = 'CLEAR_WORKSPACE';
export const LOADING_WORKSPACE_DETAIL = 'LOADING_WORKSPACE_DETAIL';
export const GET_WORKSPACE_AVAILABLE_APPLICATIONS = 'GET_WORKSPACE_AVAILABLE_APPLICATIONS';
export const CLEAR_WORKSPACE_AVAILABLE_APPLICATIONS = 'CLEAR_WORKSPACE_AVAILABLE_APPLICATIONS';
export const GET_WORKSPACE_FOCUS_AREA_CONNECTIONS = 'GET_WORKSPACE_FOCUS_AREA_CONNECTIONS';
export const CLEAR_WORKSPACE_FOCUS_AREA_CONNECTIONS = 'CLEAR_WORKSPACE_FOCUS_AREA_CONNECTIONS';
export const LOADING_WORKSPACE_FOCUS_AREA_CONNECTIONS = 'LOADING_WORKSPACE_FOCUS_AREA_CONNECTIONS';
export const UPDATE_WORKSPACE_FOCUS_AREA_CONNECTIONS = 'UPDATE_WORKSPACE_FOCUS_AREA_CONNECTIONS';

/* CREATE WORKSPACE */
export const CREATE_WORKSPACE = 'CREATE_WORKSPACE';
export const SETUP_NEW_WORKSPACE = 'SETUP_NEW_WORKSPACE';
export const CLEAR_SETUP_NEW_WORKSPACE = 'CLEAR_SETUP_NEW_WORKSPACE';
export const LOADING_CREATE_WORKSPACE = 'LOADING_CREATE_WORKSPACE';
export const UPDATE_DIALOG_WORKSPACES_CREATION_ERROR = 'UPDATE_DIALOG_WORKSPACES_CREATION_ERROR';
export const GET_WORKSPACES_SEARCH = 'GET_WORKSPACES_SEARCH';

/* DELETE WORKSPACE */
export const DELETE_WORKSPACE = 'DELETE_WORKSPACE';

/* WORKSPACES APPLICATIONS */
export const GET_WORKSPACES_APPLICATIONS = 'GET_WORKSPACES_APPLICATIONS';
export const CLEAR_WORKSPACES_APPLICATIONS = 'CLEAR_WORKSPACES_APPLICATIONS';
export const CLEAR_WORKSPACE_APPLICATION = 'CLEAR_WORKSPACE_APPLICATION';
export const LOADING_WORKSPACES_APPLICATIONS = 'LOADING_WORKSPACES_APPLICATIONS';
export const UPDATE_DIALOG_WORKSPACES_LANDING_APPLICATION = 'UPDATE_DIALOG_WORKSPACES_LANDING_APPLICATION';
export const UPDATE_DIALOG_WORKSPACES_APPLICATIONS = 'UPDATE_DIALOG_WORKSPACES_APPLICATIONS';
export const UPDATE_DIALOG_WORKSPACES_APPLICATIONS_CONNECTION = 'UPDATE_DIALOG_WORKSPACES_APPLICATIONS_CONNECTION';
export const UPDATE_DIALOG_WORKSPACES_APPLICATIONS_UPDATE_ERROR = 'UPDATE_DIALOG_WORKSPACES_APPLICATIONS_UPDATE_ERROR';
export const GET_APPLICATIONS_SEARCH = 'GET_APPLICATIONS_SEARCH';

/* WORKSPACES MEMBERS */
export const GET_WORKSPACES_MEMBERS = 'GET_WORKSPACES_MEMBERS';
export const UPDATE_WORKSPACES_MEMBERS = 'UPDATE_WORKSPACES_MEMBERS';
export const CLEAR_WORKSPACES_MEMBERS = 'CLEAR_WORKSPACES_MEMBERS';
export const GET_WORKSPACES_MEMBERS_PAGINATION = 'GET_WORKSPACES_MEMBERS_PAGINATION';
export const CLEAR_WORKSPACES_MEMBERS_PAGINATION = 'CLEAR_WORKSPACES_MEMBERS_PAGINATION';
export const LOADING_WORKSPACES_MEMBERS = 'LOADING_WORKSPACES_MEMBERS';
export const GET_WORKSPACES_MEMBERS_SORTING = 'GET_WORKSPACES_MEMBERS_SORTING';
export const UPDATE_STICKY_WORKSPACES_MEMBERS = 'UPDATE_STICKY_WORKSPACES_MEMBERS';
export const ROUTE_BACK_CREATE_USER = 'ROUTE_BACK_CREATE_USER';
export const UPDATE_DIALOG_WORKSPACES_MEMBERS = 'UPDATE_DIALOG_WORKSPACES_MEMBERS';
export const GET_WORKSPACES_MEMBERS_SEARCH = 'GET_WORKSPACES_MEMBERS_SEARCH';

/* WORKSPACES MEMBERS SHARE*/
export const GET_WORKSPACES_MEMBERS_SHARE = 'GET_WORKSPACES_MEMBERS_SHARE';
export const CLEAR_WORKSPACES_MEMBERS_SHARE = 'CLEAR_WORKSPACES_MEMBERS_SHARE';
export const UPDATE_WORKSPACES_MEMBERS_SHARE = 'UPDATE_WORKSPACES_MEMBERS_SHARE';
export const UPDATE_DIALOG_WORKSPACES_MEMBERS_SHARE = 'UPDATE_DIALOG_WORKSPACES_MEMBERS_SHARE';
export const GET_WORKSPACES_SHARE_LIST = 'GET_WORKSPACES_SHARE_LIST';
export const CLEAR_WORKSPACES_SHARE_LIST = 'CLEAR_WORKSPACES_SHARE_LIST';
export const UPDATE_WORKSPACES_SHARE_LIST = 'UPDATE_WORKSPACES_SHARE_LIST';
export const GET_WORKSPACES_SHARE_LIST_PAGINATION = 'GET_WORKSPACES_SHARE_LIST_PAGINATION';
export const CLEAR_WORKSPACES_SHARE_LIST_PAGINATION = 'CLEAR_WORKSPACES_SHARE_LIST_PAGINATION';
export const LOADING_WORKSPACES_SHARE_LIST = 'LOADING_WORKSPACES_SHARE_LIST';
export const UPDATE_DIALOG_SHARING_MEMBERS_ERROR = 'UPDATE_DIALOG_SHARING_MEMBERS_ERROR';

/* GLOBAL WORKSPACES MEMBERS */
export const GET_GLOBAL_WORKSPACES_MEMBERS = 'GET_GLOBAL_WORKSPACES_MEMBERS';
export const CLEAR_GLOBAL_WORKSPACES_MEMBERS = 'CLEAR_GLOBAL_WORKSPACES_MEMBERS';

/* WORKSPACES FOCUS AREAS */
export const GET_WORKSPACES_FOCUS_AREAS = 'GET_WORKSPACES_FOCUS_AREAS';
export const CLEAR_WORKSPACES_FOCUS_AREAS = 'CLEAR_WORKSPACES_FOCUS_AREAS';
export const GET_WORKSPACES_FOCUS_AREAS_PAGINATION = 'GET_WORKSPACES_FOCUS_AREAS_PAGINATION';
export const CLEAR_WORKSPACES_FOCUS_AREAS_PAGINATION = 'CLEAR_WORKSPACES_FOCUS_AREAS_PAGINATION';
export const LOADING_WORKSPACES_FOCUS_AREAS = 'LOADING_WORKSPACES_FOCUS_AREAS';
export const GET_WORKSPACES_FOCUS_AREAS_SORTING = 'GET_WORKSPACES_FOCUS_AREAS_SORTING';
export const UPDATE_STICKY_WORKSPACES_FOCUS_AREAS = 'UPDATE_STICKY_WORKSPACES_FOCUS_AREAS';
export const UPDATE_DIALOG_WORKSPACES_FOCUS_AREAS = 'UPDATE_DIALOG_WORKSPACES_FOCUS_AREAS';
export const UPDATE_DIALOG_WORKSPACES_SET_GEO = 'UPDATE_DIALOG_WORKSPACES_SET_GEO';
export const SETUP_NEW_WORKSPACE_FOCUS_AREAS = 'SETUP_NEW_WORKSPACE_FOCUS_AREAS';
export const UPDATE_WORKSPACES_FOCUS_AREAS = 'UPDATE_WORKSPACES_FOCUS_AREAS';
export const UPDATE_WORKSPACES_FOCUS_AREAS_ALL = 'UPDATE_WORKSPACES_FOCUS_AREAS_ALL';
export const LOADING_WORKSPACES_GET_GEOMETRY = 'LOADING_WORKSPACES_GET_GEOMETRY';
export const GET_WORKSPACES_FOCUS_AREAS_SHAPE = 'GET_WORKSPACES_FOCUS_AREAS_SHAPE';
export const UPDATE_WORKSPACES_FOCUS_AREAS_SHAPE = 'UPDATE_WORKSPACES_FOCUS_AREAS_SHAPE';
export const CLEAR_WORKSPACES_FOCUS_AREAS_SHAPE = 'CLEAR_WORKSPACES_FOCUS_AREAS_SHAPE';
export const UPDATE_WORKSPACES_FOCUS_AREAS_SHAPE_HEADER = 'UPDATE_WORKSPACES_FOCUS_AREAS_SHAPE_HEADER';
export const UPDATE_DIALOG_FOCUS_AREAS_SHAPE_ERROR = 'UPDATE_DIALOG_FOCUS_AREAS_SHAPE_ERROR';
export const UPDATE_DIALOG_FOCUS_AREAS_SHAPE_FILE = 'UPDATE_DIALOG_FOCUS_AREAS_SHAPE_FILE';
export const UPDATE_DIALOG_FOCUS_AREAS_SETGEO_ERROR = 'UPDATE_DIALOG_FOCUS_AREAS_SETGEO_ERROR';

/* WORKSPACES FOCUS AREAS MAP */
export const GET_FOCUS_AREA_MAP = 'GET_FOCUS_AREA_MAP';
export const CLEAR_FOCUS_AREA_MAP = 'CLEAR_FOCUS_AREA_MAP';
export const UPDATE_FOCUS_AREA_MAP = 'UPDATE_FOCUS_AREA_MAP';
export const UPDATE_FOCUS_AREA_GEOMETRY = 'UPDATE_FOCUS_AREA_GEOMETRY';
export const UPDATE_FOCUS_AREA_LIST_GEOMETRY = 'UPDATE_FOCUS_AREA_LIST_GEOMETRY';
export const UPDATE_SELECTED_ITEM_GEOMETRY = 'UPDATE_SELECTED_ITEM_GEOMETRY';
export const UPDATE_MAP_GEOMETRY = 'UPDATE_MAP_GEOMETRY';

/* WORKSPACES SETTINGS */
export const GET_WORKSPACE_SETTING = 'GET_WORKSPACE_SETTING';
export const CLEAR_WORKSPACE_SETTING = 'CLEAR_WORKSPACE_SETTING';
export const LOADING_WORKSPACE_SETTING = 'LOADING_WORKSPACE_SETTING';
export const UPDATE_WORKSPACE_SETTING = 'UPDATE_WORKSPACE_SETTING';
export const GET_WORKSPACE_LOGIN_SETTING = 'GET_WORKSPACE_LOGIN_SETTING';
export const CLEAR_WORKSPACE_LOGIN_SETTING = 'CLEAR_WORKSPACE_LOGIN_SETTING';
export const UPDATE_WORKSPACE_LOGIN_SETTING = 'UPDATE_WORKSPACE_LOGIN_SETTING';
export const GET_WORKSPACE_EMAIL_SETTING = 'GET_WORKSPACE_EMAIL_SETTING';
export const CLEAR_WORKSPACE_EMAIL_SETTING = 'CLEAR_WORKSPACE_EMAIL_SETTING';
export const UPDATE_WORKSPACE_EMAIL_SETTING = 'UPDATE_WORKSPACE_EMAIL_SETTING';
export const GET_WORKSPACE_THEME_SETTING = 'GET_WORKSPACE_THEME_SETTING';
export const CLEAR_WORKSPACE_THEME_SETTING = 'CLEAR_WORKSPACE_THEME_SETTING';
export const UPDATE_WORKSPACE_THEME_SETTING = 'UPDATE_WORKSPACE_THEME_SETTING';
export const UPDATE_DIALOG_WORKSPACE_SETTINGS_ERROR = 'UPDATE_DIALOG_WORKSPACE_SETTINGS_ERROR';

/* WORKSPACES APPLICATIONS SETTINGS */
export const GET_WORKSPACES_APPLICATIONS_SETTINGS = 'GET_WORKSPACES_APPLICATIONS_SETTINGS';
export const CLEAR_WORKSPACES_APPLICATIONS_SETTINGS = 'CLEAR_WORKSPACES_APPLICATIONS_SETTINGS';
export const UPDATE_WORKSPACES_APPLICATIONS_SETTINGS = 'UPDATE_WORKSPACES_APPLICATIONS_SETTINGS';
export const LOADING_WORKSPACES_APPLICATIONS_SETTINGS = 'LOADING_WORKSPACES_APPLICATIONS_SETTINGS';
export const GET_WORKSPACES_APPLICATIONS_USERS_SETTINGS = 'GET_WORKSPACES_APPLICATIONS_USERS_SETTINGS';
export const CLEAR_WORKSPACES_APPLICATIONS_USERS_SETTINGS = 'CLEAR_WORKSPACES_APPLICATIONS_USERS_SETTINGS';
export const UPDATE_WORKSPACES_APPLICATIONS_USERS_SETTINGS = 'UPDATE_WORKSPACES_APPLICATIONS_USERS_SETTINGS';
export const LOADING_WORKSPACES_APPLICATIONS_USERS_SETTINGS = 'LOADING_WORKSPACES_APPLICATIONS_USERS_SETTINGS';

export const GET_DEFAULT_APPLICATIONS_SETTINGS = 'GET_DEFAULT_APPLICATIONS_SETTINGS';
export const CLEAR_DEFAULT_APPLICATIONS_SETTINGS = 'CLEAR_DEFAULT_APPLICATIONS_SETTINGS';

/* WORKSPACES CATEGORIES */
export const SETUP_NEW_WORKSPACE_CATEGORY = 'SETUP_NEW_WORKSPACE_CATEGORY';
export const CLEAR_SETUP_NEW_WORKSPACE_CATEGORY = 'CLEAR_SETUP_NEW_WORKSPACE_CATEGORY';
export const LOADING_WORKSPACE_CATEGORY = 'LOADING_WORKSPACE_CATEGORY';
export const LOADING_CREATE_WORKSPACE_CATEGORY = 'LOADING_CREATE_WORKSPACE_CATEGORY';
export const UPDATE_DIALOG_WORKSPACES_CATEGORIES_CREATION_ERROR = 'UPDATE_DIALOG_WORKSPACES_CATEGORIES_CREATION_ERROR';
export const GET_WORKSPACES_CATEGORIES = 'GET_WORKSPACES_CATEGORIES';
export const UPDATE_WORKSPACES_CATEGORIES = 'UPDATE_WORKSPACES_CATEGORIES';
export const CLEAR_WORKSPACES_CATEGORIES = 'CLEAR_WORKSPACES_CATEGORIES'; 
export const GET_WORKSPACE_CATEGORY = 'GET_WORKSPACE_CATEGORY';
export const UPDATE_WORKSPACE_CATEGORY = 'UPDATE_WORKSPACE_CATEGORY';
export const CLEAR_WORKSPACE_CATEGORY = 'CLEAR_WORKSPACE_CATEGORY'; 

/* APPLICATIONS */

/* APPLICATIONS LIST */
export const GET_APPLICATIONS = 'GET_APPLICATIONS';
export const CLEAR_APPLICATIONS = 'CLEAR_APPLICATIONS';
export const GET_APPLICATIONS_PAGINATION = 'GET_APPLICATIONS_PAGINATION';
export const CLEAR_APPLICATIONS_PAGINATION = 'CLEAR_APPLICATIONS_PAGINATION';
export const LOADING_APPLICATIONS = "LOADING_APPLICATIONS";

/* APPLICATION DETAIL */
export const GET_APPLICATION = 'GET_APPLICATION';
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const UPDATE_DIALOG_APPLICATIONS = 'UPDATE_DIALOG_APPLICATIONS';
export const CLEAR_APPLICATION = 'CLEAR_APPLICATION';
export const LOADING_APPLICATION = "LOADING_APPLICATION";
export const GET_WORKSPACE_APPLICATION_CONNECTIONS = 'GET_WORKSPACE_APPLICATION_CONNECTIONS';
export const CLEAR_WORKSPACE_APPLICATION_CONNECTIONS = 'CLEAR_WORKSPACE_APPLICATION_CONNECTIONS';
export const LOADING_WORKSPACE_APPLICATION_CONNECTIONS = 'LOADING_WORKSPACE_APPLICATION_CONNECTIONS';
export const UPDATE_WORKSPACE_APPLICATION_CONNECTIONS = 'UPDATE_WORKSPACE_APPLICATION_CONNECTIONS';

/* CREATE APPLICATION */
export const CREATE_APPLICATION = 'CREATE_APPLICATION';
export const SETUP_NEW_APPLICATION = 'SETUP_NEW_APPLICATION';
export const CLEAR_SETUP_NEW_APPLICATION = 'CLEAR_SETUP_NEW_APPLICATION';
export const LOADING_CREATE_APPLICATION = 'LOADING_CREATE_APPLICATION';
export const GET_WORKSPACE_APPLICATION = 'GET_WORKSPACE_APPLICATION';
export const UPDATE_WORKSPACE_APPLICATION = 'UPDATE_WORKSPACE_APPLICATION';
export const LOADING_WORKSPACE_APPLICATION_DETAIL = 'LOADING_WORKSPACE_APPLICATION_DETAIL';
export const UPDATE_DIALOG_APPLICATIONS_CREATION_ERROR = 'UPDATE_DIALOG_APPLICATIONS_CREATION_ERROR';

/* APPLICATIONS SETTINGS */
export const GET_APPLICATIONS_SETTINGS = 'GET_APPLICATIONS_SETTINGS';
export const CLEAR_APPLICATIONS_SETTINGS = 'CLEAR_APPLICATIONS_SETTINGS';
export const LOADING_APPLICATIONS_SETTINGS = 'LOADING_APPLICATIONS_SETTINGS';
export const SETUP_NEW_APPLICATIONS_SETTINGS = 'SETUP_NEW_APPLICATIONS_SETTINGS';
export const UPDATE_APPLICATIONS_SETTINGS = 'UPDATE_APPLICATIONS_SETTINGS';
export const UPDATE_STICKY_APPLICATIONS_SETTINGS = 'UPDATE_STICKY_APPLICATIONS_SETTINGS';
export const UPDATE_DIALOG_APPLICATIONS_SETTINGS = 'UPDATE_DIALOG_APPLICATIONS_SETTINGS';

/* CATEGORIES */
export const SETUP_NEW_CATEGORY = 'SETUP_NEW_CATEGORY';
export const CLEAR_SETUP_NEW_CATEGORY = 'CLEAR_SETUP_NEW_CATEGORY';
export const LOADING_CATEGORY = 'LOADING_CATEGORY';
export const LOADING_CREATE_CATEGORY = 'LOADING_CREATE_CATEGORY';
export const UPDATE_DIALOG_CATEGORIES_CREATION_ERROR = 'UPDATE_DIALOG_CATEGORIES_CREATION_ERROR';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const CLEAR_CATEGORIES = 'CLEAR_CATEGORIES';
export const GET_CATEGORY = 'GET_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const CLEAR_CATEGORY = 'CLEAR_CATEGORY'; 

/* USERS */
export const GET_USERS = 'GET_USERS';
export const UPDATE_USERS = 'UPDATE_USERS';
export const CLEAR_USERS = 'CLEAR_USERS';
export const LOADING_USERS = 'LOADING_USERS';
export const GET_USERS_PAGINATION = 'GET_USERS_PAGINATION';
export const CLEAR_USERS_PAGINATION = 'CLEAR_USERS_PAGINATION';
export const GET_USERS_SORTING = 'GET_USERS_SORTING';
export const UPDATE_STICKY_USERS = 'UPDATE_STICKY_USERS';
export const GET_USERS_SEARCH = 'GET_USERS_SEARCH';

/* USER DETAIL */
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_DIALOG_USERS = 'UPDATE_DIALOG_USERS';
export const CLEAR_USER = 'CLEAR_USER';
export const LOADING_USER = 'LOADING_USER';
export const GET_USER_ACCESS = 'GET_USER_ACCESS';
export const CLEAR_USER_ACCESS = 'CLEAR_USER_ACCESS';
export const GET_USER_ACCESS_CONTACT_OWNERS = 'GET_USER_ACCESS_CONTACT_OWNERS';
export const UPDATE_USER_ACCESS = 'UPDATE_USER_ACCESS';
export const LOADING_USER_ACCESS = 'LOADING_USER_ACCESS';
export const SETUP_NEW_USER = 'SETUP_NEW_USER';
export const CLEAR_SETUP_NEW_USER = 'CLEAR_SETUP_NEW_USER';
export const LOADING_CREATE_USER = 'LOADING_CREATE_USER';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_DIALOG_USERS_CREATION_ERROR = 'UPDATE_DIALOG_USERS_CREATION_ERROR';

/* ACCOUNT */
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_DIALOG_ACCOUNT = 'UPDATE_DIALOG_ACCOUNT';
export const CLEAR_ACCOUNT = 'CLEAR_ACCOUNT';
export const LOADING_ACCOUNT = 'LOADING_ACCOUNT';

/* ROLE */
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const CLEAR_ROLE = 'CLEAR_ROLE';

/* GLOBAL USERS */
export const LOADING_GLOBAL_USERS = 'LOADING_GLOBAL_USERS';
export const ROUTE_BACK_GLOBAL_USERS = 'ROUTE_BACK_GLOBAL_USERS';
export const GET_GLOBAL_USERS = 'GET_GLOBAL_USERS';
export const DELETE_GLOBAL_USER = 'DELETE_GLOBAL_USER';
export const CREATE_GLOBAL_USER = 'CREATE_GLOBAL_USER';
export const CLEAR_GLOBAL_USERS = 'CLEAR_GLOBAL_USERS';

/* PORTAL LOGO */
export const GET_PORTAL_LOGO = 'GET_PORTAL_LOGO';
export const UPDATE_PORTAL_LOGO = 'UPDATE_PORTAL_LOGO';
export const CLEAR_PORTAL_LOGO = 'CLEAR_PORTAL_LOGO';
export const LOADING_PORTAL_LOGO = 'LOADING_PORTAL_LOGO';

/* USER AVAILABLE WORKSPACES */
export const GET_USER_AVAILABLE_WORKSPACES_PAGINATION = 'GET_USER_AVAILABLE_WORKSPACES_PAGINATION';
export const CLEAR_USER_AVAILABLE_WORKSPACES_PAGINATION = 'CLEAR_USER_AVAILABLE_WORKSPACES_PAGINATION';
export const GET_USER_AVAILABLE_WORKSPACES_SEARCH = 'GET_USER_AVAILABLE_WORKSPACES_SEARCH';
export const CLEAR_USER_AVAILABLE_WORKSPACES_SEARCH = 'CLEAR_USER_AVAILABLE_WORKSPACES_SEARCH';
export const GET_USER_AVAILABLE_WORKSPACES = 'GET_USER_AVAILABLE_WORKSPACES';
export const CLEAR_USER_AVAILABLE_WORKSPACES = 'CLEAR_USER_AVAILABLE_WORKSPACES';
export const LOADING_USER_AVAILABLE_WORKSPACES = 'LOADING_USER_AVAILABLE_WORKSPACES';

/* PORTAL LANDING */
export const GET_PORTAL_LANDING_BLOG = 'GET_PORTAL_LANDING_BLOG';
export const GET_PORTAL_LANDING_BANNER = 'GET_PORTAL_LANDING_BANNER';
export const CLEAR_PORTAL_LANDING_BANNER = 'CLEAR_PORTAL_LANDING_BANNER';
export const LOADING_PORTAL_LANDING_BANNER = 'LOADING_PORTAL_LANDING_BANNER';
export const GET_PORTAL_LANDING_FEATURE = 'GET_PORTAL_LANDING_FEATURE';
export const LOADING_PORTAL_LANDING_FEATURE = 'LOADING_PORTAL_LANDING_FEATURE';
export const GET_PORTAL_LANDING_NEWS = 'GET_PORTAL_LANDING_NEWS';
export const LOADING_PORTAL_LANDING_NEWS = 'LOADING_PORTAL_LANDING_NEWS';
export const UPDATE_DIALOG_PORTALS = 'UPDATE_DIALOG_PORTALS';

/* PORTAL SETTING */
export const GET_PORTAL_SETTING_BANNER = 'GET_PORTAL_SETTING_BANNER';
export const CLEAR_PORTAL_SETTING_BANNER = 'CLEAR_PORTAL_SETTING_BANNER';
export const UPDATE_PORTAL_SETTING_BANNER = 'UPDATE_PORTAL_SETTING_BANNER';
export const LOADING_PORTAL_SETTING_BANNER = 'LOADING_PORTAL_SETTING_BANNER';
export const GET_PORTAL_SETTING_FEATURE = 'GET_PORTAL_SETTING_FEATURE';
export const CLEAR_PORTAL_SETTING_FEATURE = 'CLEAR_PORTAL_SETTING_FEATURE';
export const UPDATE_PORTAL_SETTING_FEATURE = 'UPDATE_PORTAL_SETTING_FEATURE';
export const LOADING_PORTAL_SETTING_FEATURE = 'LOADING_PORTAL_SETTING_FEATURE';
export const GET_PORTAL_SETTING_NEWS = 'GET_PORTAL_SETTING_NEWS';
export const CLEAR_PORTAL_SETTING_NEWS = 'CLEAR_PORTAL_SETTING_NEWS';
export const UPDATE_PORTAL_SETTING_NEWS = 'UPDATE_PORTAL_SETTING_NEWS';
export const LOADING_PORTAL_SETTING_NEWS = 'LOADING_PORTAL_SETTING_NEWS';
export const GET_PORTAL_SETTING_LOGIN = 'GET_PORTAL_SETTING_LOGIN';
export const CLEAR_PORTAL_SETTING_LOGIN = 'CLEAR_PORTAL_SETTING_LOGIN';
export const UPDATE_PORTAL_SETTING_LOGIN = 'UPDATE_PORTAL_SETTING_LOGIN';
export const LOADING_PORTAL_SETTING_LOGIN = 'LOADING_PORTAL_SETTING_LOGIN';
export const GET_PORTAL_SETTING_EMAIL = 'GET_PORTAL_SETTING_EMAIL';
export const CLEAR_PORTAL_SETTING_EMAIL = 'CLEAR_PORTAL_SETTING_EMAIL';
export const UPDATE_PORTAL_SETTING_EMAIL = 'UPDATE_PORTAL_SETTING_EMAIL';
export const LOADING_PORTAL_SETTING_EMAIL = 'LOADING_PORTAL_SETTING_EMAIL';
export const GET_PORTAL_SETTING_THEME = 'GET_PORTAL_SETTING_THEME';
export const CLEAR_PORTAL_SETTING_THEME = 'CLEAR_PORTAL_SETTING_THEME';
export const UPDATE_PORTAL_SETTING_THEME = 'UPDATE_PORTAL_SETTING_THEME';
export const LOADING_PORTAL_SETTING_THEME = 'LOADING_PORTAL_SETTING_THEME';
export const UPDATE_PORTAL_SETTING_TAB = 'UPDATE_PORTAL_SETTING_TAB';
export const UPDATE_DIALOG_PORTAL_SETTINGS_ERROR = "UPDATE_DIALOG_PORTAL_SETTINGS_ERROR";

/* PORTAL LOGIN */
export const GET_PORTAL_LOGIN = 'GET_PORTAL_LOGIN';
export const CLEAR_PORTAL_LOGIN = 'CLEAR_PORTAL_LOGIN';
export const LOADING_PORTAL_LOGIN = 'LOADING_PORTAL_LOGIN';

/* LOGIN */
export const GET_LOGIN = 'GET_LOGIN';
export const UPDATE_LOGIN = 'UPDATE_LOGIN';
export const CLEAR_LOGIN = 'CLEAR_LOGIN';
export const LOADING_LOGIN = "LOADING_LOGIN";
export const UPDATE_DIALOG_USERS_LOGIN_ERROR = 'UPDATE_DIALOG_USERS_LOGIN_ERROR';

/* CHANGE PASSWORD */
export const GET_CHANGE_PASSWORD = 'GET_CHANGE_PASSWORD';
export const UPDATE_CHANGE_PASSWORD = 'UPDATE_CHANGE_PASSWORD';
export const CLEAR_CHANGE_PASSWORD = 'CLEAR_CHANGE_PASSWORD';
export const LOADING_CHANGE_PASSWORD = "LOADING_CHANGE_PASSWORD";

/* RESET PASSWORD */
export const GET_RESET_PASSWORD = 'GET_RESET_PASSWORD';
export const UPDATE_RESET_PASSWORD = 'UPDATE_RESET_PASSWORD';
export const CLEAR_RESET_PASSWORD = 'CLEAR_RESET_PASSWORD';
export const LOADING_RESET_PASSWORD = "LOADING_RESET_PASSWORD";
export const UPDATE_DIALOG_RESET_PASSWORD_ERROR = "UPDATE_DIALOG_RESET_PASSWORD_ERROR";

/* RESET PASSWORD */
export const UPDATE_DIALOG_REGISTRATION_REMINDER_ERROR = "UPDATE_DIALOG_REGISTRATION_REMINDER_ERROR";

/* REDIRECT URL */
export const UPDATE_REDIRECT_URL = 'UPDATE_REDIRECT_URL';
export const CLEAR_REDIRECT_URL = 'CLEAR_REDIRECT_URL';

/* UNAVAILABLE CONTEXT */
export const UPDATE_UNAVAILABLE_CONTEXT = 'UPDATE_UNAVAILABLE_CONTEXT';

/* PAGE REDIRECTION */
export const LOADING_PAGE_REDIRECTION = 'LOADING_PAGE_REDIRECTION';

/* THEME */
export const LOADING_THEME = 'LOADING_THEME';

/* CONTACT ADMINISTRATORS */
export const UPDATE_CONTACT_ADMINISTRATORS = 'UPDATE_CONTACT_ADMINISTRATORS';
export const CLEAR_CONTACT_ADMINISTRATORS = 'CLEAR_CONTACT_ADMINISTRATORS';
export const LOADING_CONTACT_ADMINISTRATORS = "LOADING_CONTACT_ADMINISTRATORS";
export const UPDATE_DIALOG_CONTACT_ADMINISTRATORS_ERROR = "UPDATE_DIALOG_CONTACT_ADMINISTRATORS_ERROR";

/* CONTACT OWNERS */
export const GET_CONTACTS_OWNERS = 'GET_CONTACTS_OWNERS';
export const UPDATE_CONTACTS_OWNERS = 'UPDATE_CONTACTS_OWNERS';
export const CLEAR_CONTACTS_OWNERS= 'CLEAR_CONTACTS_OWNERS';
export const LOADING_CONTACTS_OWNERS = "LOADING_CONTACTS_OWNERS";
export const SEND_EMAIL_CONTACTS_OWNERS = 'UPDATE_CONTACTS_OWNERS';

/* TOASTER */
export const UPDATE_TOASTER = 'UPDATE_TOASTER';

/* PORTAL NAVIGATIONS */
export const CHANGE_ROUTE = 'CHANGE_ROUTE';

/* BASE LOADING */
export const LOADING = "LOADING";

/* IP ADDRESSES */
export const GET_CLIENT_IP = "GET_CLIENT_IP";

/* WORKSPACE APPLICATIONS LANDING */
export const GET_WORKSPACE_APPLICATION_LANDING = 'GET_WORKSPACE_APPLICATION_LANDING';
export const CLEAR_WORKSPACE_APPLICATION_LANDING = 'CLEAR_WORKSPACE_APPLICATION_LANDING';
export const GET_APPLICATIONS_LANDING = 'GET_APPLICATIONS_LANDING';
export const GET_APPLICATIONS_LANDING_THEME = 'GET_APPLICATIONS_LANDING_THEME';