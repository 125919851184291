import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateWorkspacesShareListAction } from '../../../../../actions/updateWorkspacesShareListAction';

class WorkspacesShareListItem extends Component {
    constructor(props) {
        super(props);
        this.onWorkspaceSelected = this.onWorkspaceSelected.bind(this);
    }

    static propTypes = {
        item: PropTypes.object.isRequired
    };

    onWorkspaceSelected(e) {
        const { item, workspacesShareList } = this.props;
        let workspaces = workspacesShareList;
        let index = workspaces.findIndex(workspace => workspace.id === item.id);
        workspaces[index].selected = e.target.checked;

        this.props.dispatchWorkspacesShareList(workspaces);
    }

    render() {
        const { item } = this.props;
        return (
            <li key={item.id}>
                <label className="customPickWSCheckbox">
                    <input type="checkbox" onClick={this.onWorkspaceSelected} checked={item.selected}/>
                    <div className="imgContainer text-center mr-3">
                        <img src={((item.url_image !== undefined) ? "data:image/jpeg;base64," : "") + item.url_image} className="workspaceLogo" alt="" />
                    </div>
                    <b className="workspaceName">{item.workspace_name}</b>
                    <span className="workspaceDesc">{item.workspace_title}</span>
                    <span className="clearfix"></span>
                </label>
            </li>
        );
    }
}

const mapStateToProps = ({ workspacesShareList, pagination }) => ({
    workspacesShareList,
    grid: pagination.workspaces_share_list
});

const mapDispatchToProps = (dispatch) => ({
    dispatchWorkspacesShareList: (workspaces) => dispatch(updateWorkspacesShareListAction(workspaces))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesShareListItem));
