import { GET_WORKSPACES, UPDATE_WORKSPACES, DELETE_WORKSPACE, CREATE_WORKSPACE, CLEAR_WORKSPACES } from '../actions/index';

const getWorkspaces = (workspaces = [], action) => {
    let itemsKeys = [];
    let workspacesKeys = action.workspaces.reduce((keys, workspace) => {
            itemsKeys.push(workspace.id);
            keys = itemsKeys;
            return keys;
        }, []);

    let result = workspaces.filter((workspace) => {
        return workspacesKeys.indexOf(workspace.id) === -1;
    });

    return result.concat(action.workspaces);
}

const deleteWorkspace = (workspaces = [], action) => {
    return workspaces.filter((workspace) => {
        return workspace.id !== action.workspaceId;
    });
}

const updateWorkspaces = (workspaces = [], action) => {
    const ws = workspaces.map((item) => {
        const changesWorkspaces = action.workspaces.filter((change) => change.id === item.id)
        if (changesWorkspaces.count > 0) {
            return changesWorkspaces[0];
        }

        return item
    })

    return ws;
}

const createWorkspace = (workspaces = [], action) => {
    return workspaces.concat(action.workspace);
}

const clearWorkspaces = (action) => {
    return action.workspaces;
}

export const workspaces = (workspaces = [], action) => {
    switch (action.type) {
        case GET_WORKSPACES: return getWorkspaces(workspaces, action);
        case UPDATE_WORKSPACES: return updateWorkspaces(workspaces, action);
        case DELETE_WORKSPACE: return deleteWorkspace(workspaces, action);
        case CREATE_WORKSPACE: return createWorkspace(workspaces, action);
        case CLEAR_WORKSPACES: return clearWorkspaces(action);
        default: return workspaces;
    }
};