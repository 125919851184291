import { GET_PORTAL_LANDING_BLOG, GET_PORTAL_LANDING_BANNER, CLEAR_PORTAL_LANDING_BANNER, GET_PORTAL_LANDING_FEATURE, GET_PORTAL_LANDING_NEWS } from '../actions/index';

const initialPortalLandingState = {
    portal_banner: {},
    portal_feature: {},
    portal_news: {},
    blog:{},
}

export const portalLanding = (state = initialPortalLandingState, action) => {
    const { portal_banner, portal_feature, portal_news, blog } = action

    switch (action.type) {
        case GET_PORTAL_LANDING_BLOG:
            return {
                ...state,
                blog
            }
        case GET_PORTAL_LANDING_BANNER:
            return {
                ...state,
                portal_banner
            }
        case CLEAR_PORTAL_LANDING_BANNER:
            return initialPortalLandingState.portal_banner;
        case GET_PORTAL_LANDING_FEATURE:
            return {
                ...state,
                portal_feature
            }
        case GET_PORTAL_LANDING_NEWS:
            return {
                ...state,
                portal_news
            }
        default:
            return state;
    }
};