import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateToasterAction } from '../../actions/updateToasterAction';

class ModalAlert extends Component {
    static propTypes = {
        portalRouter: PropTypes.object.isRequired,
        dialog: PropTypes.object.isRequired,
        dispatchToaster: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            prevPath: '',

        }
        this.closeModal = this.closeModal.bind(this);
    }

    setToaster() {
        let toast = {
            status: '',
            message: '',
            error: '',
            show_dialog: false
        };

        this.props.dispatchToaster(toast);
    }

    closeModal() {
        
        this.setToaster();
        const nav = this.props.portalRouter;
        const { redirect } = this.props.dialog.toaster;
        if (redirect) {
            this.props.history.push(nav.name);
        }
    }

    render() {
        const { toaster } = this.props.dialog;
        return (
            <div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((toaster.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div className={"popupPanel popupPanel-small deleteWarning" + ((toaster.show_dialog) ? " animated bounceInDown" : "")}>
                    <div className="popupHeader">
                        <div className="p-4">
                            <h2 className="popupTitle">{toaster.message ? 'Success' : toaster.status}</h2>
                            <button className="btn iconBtn" onClick={this.closeModal}>
                                <span className="icon-wrd-close"></span>
                            </button>
                        </div>
                    </div>
                    <div className="popupBody">
                        <div className="p-4">
                            <div className="summernote">
                                {(toaster.status === 200 || toaster.message) ?
                                    <p>
                                        <b className="actionEnable">Success:</b> {toaster.message}
                                    </p>
                                    :
                                    <p>
                                        <b className="actionRemove">ERROR:</b> {toaster.error}
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="px-4 pt-4 pb-2">
                            <div className="row">
                                <div className="col text-right">
                                    <button className="greenBtn btn icoTextBtn mr-3 closePopup" onClick={this.closeModal}>
                                        Close
                                        </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ dialog, portalRouter }) => ({
    dialog: dialog,
    portalRouter
});

const mapDispatchToProps = (dispatch) => ({
    dispatchToaster: (toaster) => dispatch(updateToasterAction(toaster))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalAlert));
