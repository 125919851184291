import { capitalizeFirstLetter } from '../../utils/index';
const workspacesFocusAreasApi = require('../api/workspacesFocusAreas');

export async function getFocusAreasSetGeoApi(workspaceId, focusAreaId) {
    const results = await workspacesFocusAreasApi.getFocusAreaGeometry(workspaceId, focusAreaId);
    return mapToWorkspacesFocusAreaGeometryFrontend(workspaceId, focusAreaId, results);
}

export async function getFocusAreasApi(workspaceId) {
    const results = await workspacesFocusAreasApi.getFocusAreas(workspaceId);
    if (results.status === 200 && results.data.length > 0) {
        const focusAreas = results.data.map(fa => {
            return {
                id: fa.id,
                focus_area_name: capitalizeFirstLetter(fa.name),
                has_geometry: fa.hasGeometry,
                has_connection: fa.hasConnection,
                is_checked: fa.enabled,
                focus_area_status: 'Enabled',
                is_updated: false,
                is_selected: false,
                viewGeometry: fa.showGeometry,
                lockGeometry: fa.lockGeometry,
                publicGeometry: fa.public,
            }
        })
        results.data = focusAreas;
    }
    return results;
}

export async function getWorkspacesFocusAreasApi(workspaceId) {
    try {
        const results = await workspacesFocusAreasApi.getFocusAreaList(workspaceId);
        return mapToWorkspacesFocusAreasFrontend(results.data);
    }
    catch (err) {
        if (err.response.status === 404) {
            return mapToEmptyWorkspacesFocusAreasFrontend();
        }
    }
}

export async function getWorkspaceFocusAreaConnectionsApi(workspaceId) {
    try {
        let getAll = true;
        const results = await workspacesFocusAreasApi.getFocusAreaConnections(workspaceId, getAll);
        return await mapToWorkspacesFocusAreasConnectionsFrontend(results.data);
    }
    catch (err) {
        if (err.response.status === 404) {
            return mapToEmptyWorkspacesFocusAreasConnectionsFrontend();
        }
    }
}

export async function postFocusAreasSetGeoApi(workspaceId, focusAreaId, data) {
    const results = await workspacesFocusAreasApi.postFocusAreaGeometry(workspaceId, focusAreaId, data);
    return results;
}

export async function createWorkspacesFocusAreasApi(workspaceId, focusArea) {
    const mappingFocusArea = mapToWorkspacesFocusAreasBackendForCreate(focusArea);
    const results = await workspacesFocusAreasApi.createFocusArea(workspaceId, mappingFocusArea);

    const data = {
        id: results.data
    }

    return data;
}

export async function addWorkspaceFocusAreaShapeApi(workspaceId, workspacesFocusAreasShapeHeader, workspacesFocusAreasShape) {
    let headerName = workspacesFocusAreasShapeHeader.find(x => x.checked === true).name;
    let id = '';
    let error_messages = '';
    let createAreaResults = {};
    for (let i in workspacesFocusAreasShape) {
        let a = workspacesFocusAreasShape[i];
        if (a.selected) {
            let focusArea = {
                focus_area_name: a.properties[headerName]
            };
            let mappingFocusArea = mapToWorkspacesFocusAreasBackendForCreate(focusArea);
            createAreaResults = await workspacesFocusAreasApi.createFocusArea(workspaceId, mappingFocusArea);

            if (createAreaResults.status === 200) {
                let splitResult = createAreaResults.data.split('/').pop();
                id = splitResult.split(':').pop();
                a.properties.feature_name = a.properties[headerName];
                let featureCollection = {
                    type: "FeatureCollection",
                    features: [{
                        type: "Feature",
                        geometry: a.geometry,
                        properties: a.properties
                    }]
                };

                let geoData = {
                    format: "GeoJSON",
                    geometry: JSON.stringify(featureCollection)
                };
                await workspacesFocusAreasApi.postFocusAreaGeometry(workspaceId, id, geoData);
            }
            else {
                if (createAreaResults.status === 500 || createAreaResults.status === 404) {
                    error_messages = 'Internal server error, Please contact administrator.';
                } else {
                    let i = 0;
                    for (const value of Object.entries(createAreaResults.data)) {
                        if (i === 0) {
                            error_messages = value[1];
                        }
                        i += 1;
                    }
                }
                break;
            }
        }
    }

    const data = {
        id: id,
        status: createAreaResults.status,
        error_messages: error_messages
    };

    return data;
}

export async function updateWorkspacesFocusAreasApi(workspaceId, focusAreas) {
    for (let i in focusAreas) {
        let a = focusAreas[i];
        if (a.is_updated) {
            let area = {
                id: a.id,
                enabled: a.is_checked,
                name: a.focus_area_name,
                showGeometry: a.showGeometry,
                lockGeometry: a.lockGeometry,
                public: a.public,
            }
            await workspacesFocusAreasApi.updateFocusAreaStatus(workspaceId, area.id, area.enabled);
            await workspacesFocusAreasApi.updateFocusAreaBehavior(workspaceId, area);
        }
    }

    const data = {
        status: 'Done'
    }
    return data;
}

export async function updateWorkspaceFocusAreaConnectionsApi(workspaceId, connections) {
    const mappingFocusAreaConnections = mapToWorkspacesFocusAreasConnectionsBackendForUpdate(connections.connections);
    const results = await workspacesFocusAreasApi.updateFocusAreaConnections(workspaceId, mappingFocusAreaConnections);
    return results;
}

export async function deleteFocusAreasSetGeoApi(workspaceId, focusAreaId) {
    const results = await workspacesFocusAreasApi.deleteFocusAreaGeometry(workspaceId, focusAreaId);
    return results;
}

export async function removeWorkspacesFocusAreasApi(workspaceId, focusAreaId) {
    const results = await workspacesFocusAreasApi.removeFocusArea(workspaceId, focusAreaId);
    const data = {
        id: results.data
    }
    return data;
}

async function mapToWorkspacesFocusAreasFrontend(data) {
    let results = {};
    let areas = [];

    for (let i in data) {
        let a = data[i];
        let area = {
            id: a.id,
            focus_area_name: capitalizeFirstLetter(a.name),
            has_geometry: a.hasGeometry,
            is_checked: a.enabled,
            focus_area_status: 'Enabled',
            is_updated: false,
            is_selected: false,
            hasConnection: a.hasConnection,
            showGeometry: a.showGeometry,
            lockGeometry: a.lockGeometry,
            public: a.public,
        }
        areas.push(area);
    }

    results.areas = areas;
    return results;
}

async function mapToWorkspacesFocusAreaGeometryFrontend(workspaceId, focusAreaId, results) {
    if (results.data.format === 'GeoJSON') {
        try {
            let geoData = JSON.parse(results.data.geometry);
            if (geoData.features === undefined) {
                const result = await workspacesFocusAreasApi.getFocusAreaById(workspaceId, focusAreaId);
                const focusAreaName = result.data.name;

                let properties = {
                    name: "",
                    outlet_x: "",
                    outlet_y: "",
                    ID: focusAreaName,
                    AREA: 0,
                    feature_name: focusAreaName
                }
                let features = [{
                    type: "Feature",
                    properties,
                    geometry: geoData
                }];

                let data = {
                    ...results.data,
                    simple_geometry: JSON.stringify({
                        type: "FeatureCollection",
                        features
                    })
                };

                results.data = data;
            }
        }
        catch (err) {
            results.status = 400;
            results.statusText = "Invalid GeoJSON Format";
        }
    }
    return results;
}

async function mapToWorkspacesFocusAreasConnectionsFrontend(data) {
    let results = {};
    let connections = [];
    for (let i in data) {
        let a = data[i];
        let connection = {
            id: a.id,
            focus_area_name: a.name,
            connection_string: (a.connectionString !== undefined) ? a.connectionString : "",
            enabled: a.enabled
        }
        connections.push(connection);
    }

    results.connections = connections;

    return results;
}

function mapToEmptyWorkspacesFocusAreasFrontend() {
    let results = {};
    let areas = [];
    let geoData = [];

    results.areas = areas;
    results.focus_area_map = geoData;

    return results;
}

function mapToEmptyWorkspacesFocusAreasConnectionsFrontend() {
    let results = {};
    let connections = [];

    results.connections = connections;

    return results;
}

function mapToWorkspacesFocusAreasBackendForCreate(data) {
    let area = {};

    area.name = data.focus_area_name;
    area.enabled = true;

    return area;
}

function mapToWorkspacesFocusAreasConnectionsBackendForUpdate(data) {
    let connections = [];
    for (let i in data) {
        let a = data[i];
        let connection = {
            focusAreaId: a.id,
            connectionString: a.connection_string
        };
        connections.push(connection);
    }
    return connections;
}