import {
    updateUserChangePasswordApi
} from '../backend/domain/users';

import { clearChangePasswordAction } from '../actions/clearChangePasswordAction';
import { loadingChangePasswordAction } from '../actions/loadingChangePasswordAction';
import { updateDialogUsersLoginErrorAction } from '../actions/updateDialogUsersLoginErrorAction';

export const updateUserChangePasswordWithThunk = (changePassword, uid) =>
    (dispatch) => {
        dispatch(loadingChangePasswordAction(true));
        updateUserChangePasswordApi(uid, changePassword)
            .then((response) => {                
                if (response.status === 200) {
                    let login_error = {
                        id: uid,
                        name: '',
                        status: 200,
                        dialog_title: 'Password Changed',
                        dialog_type: 'Change Password',
                        show_dialog: true
                    };
                    dispatch(updateDialogUsersLoginErrorAction(login_error));
                    dispatch(clearChangePasswordAction([]));
                }
                else {                    
                    let login_error = {
                        id: uid,
                        name: '',
                        status: response.message,
                        dialog_title: 'Change Password Failed',
                        dialog_type: 'Change Password',
                        show_dialog: true
                    };
                    dispatch(updateDialogUsersLoginErrorAction(login_error));
                }                
                return response;
            })
            .then((response) => {
                dispatch(loadingChangePasswordAction(false));
                return response;
            });
    };