import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDialogWorkspacesApplicationsAction } from '../../../../actions/updateDialogWorkspacesApplicationsAction';
import { updateWorkspaceApplicationStatusWithThunk } from '../../../../api/workspacesApplicationsThunk';
import { updateWorkspaceApplicationLinkStatusWithThunk } from '../../../../api/workspacesListThunk';

class WorkspacesApplicationsModal extends Component {
    static propTypes = {
        dialog: PropTypes.object.isRequired,
        workspaceApplication: PropTypes.object.isRequired,
        dispatchDialogWorkspacesApplications: PropTypes.func.isRequired,
        updateWorkspaceApplicationDetailStatus: PropTypes.func.isRequired
    };

    setDefaultDialogState() {
        let workspacesApplications = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            route_back_to: '',
            show_dialog: false
        };

        this.props.dispatchDialogWorkspacesApplications(workspacesApplications);
    }

    updateWorkspaceApplication() {
        const { dialog, workspace, role } = this.props;

        this.setDefaultDialogState();

        if (dialog.dialog_type !== 'trash') {
            let workspaceApplication = {
                ...this.props.workspaceApplication,
                status: (dialog.status === 'Enabled') ? 'Disabled' : 'Enabled'
            }

            this.props.updateWorkspaceApplicationDetailStatus(workspaceApplication);
        }
        else {
            const isAdmin = (role.user_role.toLowerCase() === 'admin') ? true : false;
            this.props.updateWorkspaceApplicationLinkStatus(workspace.id, dialog.id, 'unlink', false, isAdmin);
            this.props.history.push(dialog.route_back_to);
        }
    }

    closeModal() {
        this.setDefaultDialogState();
    }

    render() {
        const { dialog, workspace } = this.props;
        const dialogCommand = (dialog.dialog_type === 'trash') ? 'REMOVE' : (dialog.status === 'Enabled') ? 'Suspend' : 'Reinstate';

        return (
            <div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div className={"popupPanel popupPanel-small deleteWarning" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                    <div className="popupHeader">
                        <div className="p-4">
                            <h2 className="popupTitle">{dialog.dialog_title}</h2>
                            <button className="btn iconBtn" onClick={() => { this.closeModal(); }}>
                                <span className="icon-wrd-close"></span>
                            </button>
                        </div>
                    </div>
                    <div className="popupBody">
                        <div className="p-4">
                            <div className="summernote">
                                <p>
                                    Are you sure you want to <b className={(dialog.dialog_type === 'trash') ? 'actionRemove' : ''}>{dialogCommand}</b> workspace application <b className="appName">{dialog.name}</b> from <b className="appName">{workspace.workspace_name}</b>?
                                    <br />
                                    {(dialog.dialog_type === 'trash') ? 'All data related to this Workspace Application including global and user preference will no longer be available.' : ''}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="px-4 pt-4 pb-2">
                            <div className="row">
                                <div className="col">
                                    <button className="btn greyBtn icoTextBtn closePopup" onClick={() => { this.closeModal(); }}>
                                        <span className="icon-wrd-close"></span>
                                        Cancel
                                    </button>
                                </div>
                                <div className="col text-right">
                                    <button className={((dialog.dialog_type === 'trash') ? 'redBtn' : (dialog.status === 'Enabled') ? 'redBtn' : 'greenBtn') + ' btn icoTextBtn mr-3 closePopup'}
                                        onClick={() => { this.updateWorkspaceApplication(); }}
                                    >
                                        <span className={(dialog.dialog_type === 'trash') ? "icon-wrd-trash" : ((dialog.status === 'Enabled') ? "icon-wrd-lock" : "icon-wrd-unlock")}></span>
                                        {(dialog.dialog_type === 'trash') ? 'Remove' : (dialog.status === 'Enabled') ? 'Suspend' : 'Reinstate'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ dialog, role, detail }) => ({
    dialog: dialog.workspaces_applications,
    role,
    workspaceApplication: detail.workspace_application,
    workspace: detail.workspace
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogWorkspacesApplications: (dialog) => dispatch(updateDialogWorkspacesApplicationsAction(dialog)),
    updateWorkspaceApplicationDetailStatus: (workspaceApplication) => dispatch(updateWorkspaceApplicationStatusWithThunk(workspaceApplication)),
    updateWorkspaceApplicationLinkStatus: (workspaceId, applicationId, status, isFromList, isAdmin) => dispatch(updateWorkspaceApplicationLinkStatusWithThunk(workspaceId, applicationId, status, isFromList, isAdmin))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsModal));
