import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner'
import UserAvailableWorkspacesBody from '../UserAvailableWorkspaces/UserAvailableWorkspacesBody';

class UserAvailableWorkspacesContainer extends Component {
    static propTypes = {
        workspace: PropTypes.object.isRequired,
        availableWorkspaces: PropTypes.object.isRequired,
        pagination: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        hideList: PropTypes.func.isRequired
    };      

    render() {
        const { availableWorkspaces, pagination, loading, loadingTheme } = this.props;
        if (availableWorkspaces.workspaces === undefined && pagination.available_workspaces === undefined)
            return (
                <div>                    
                    {(loading || loadingTheme) &&
                        <div className="loader-available-workspace-list">
                            <Loader
                                type="Ball-Triangle"
                                color="#00BFFF"
                                height="70"
                                width="70"
                            />
                        </div>
                    }
                </div>
            );    

        return (
            <div>
                {loading &&
                    <div className="loader-available-workspace-list">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="70"
                            width="70"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <UserAvailableWorkspacesBody hideList={this.props.hideList} />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, availableWorkspaces, role, pagination, loading }) => ({
    workspace: detail.workspace,
    availableWorkspaces,
    role,
    pagination: pagination.available_workspaces,
    loading: loading.user_available_workspaces.loading,
    loadingTheme: loading.theme.loading
});

export default withRouter(connect(mapStateToProps)(UserAvailableWorkspacesContainer));
