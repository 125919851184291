import { GET_WORKSPACES_FOCUS_AREAS_SHAPE, UPDATE_WORKSPACES_FOCUS_AREAS_SHAPE, CLEAR_WORKSPACES_FOCUS_AREAS_SHAPE } from '../actions/index';

const getWorkspacesFocusAreasShape = (workspacesFocusAreasShape = [], action) => {
    let itemsKeys = [];
    let featuresKeys = action.features.reduce((keys, feature) => {
        itemsKeys.push(feature.id);
        keys = itemsKeys;
        return keys;
    }, []);

    let result = workspacesFocusAreasShape.filter((feature) => {
        return featuresKeys.indexOf(feature.id) === -1;
    });

    return result.concat(action.features);
}

const updateWorkspacesFocusAreasShape = (workspacesFocusAreasShape = [], action) => {
    let itemsKeys = [];
    let featuresKeys = action.features.reduce((keys, feature) => {
        itemsKeys.push(feature.id);
        keys = itemsKeys;
        return keys;
    }, []);

    let result = workspacesFocusAreasShape.filter((feature) => {
        return featuresKeys.indexOf(feature.id) === -1;
    });

    return result.concat(action.features);
}

const clearWorkspacesFocusAreasShape = (action) => {
    return action.features;
}

export const workspacesFocusAreasShape = (workspacesFocusAreasShape = [], action) => {
    switch (action.type) {
        case GET_WORKSPACES_FOCUS_AREAS_SHAPE: return getWorkspacesFocusAreasShape(workspacesFocusAreasShape, action);
        case UPDATE_WORKSPACES_FOCUS_AREAS_SHAPE: return updateWorkspacesFocusAreasShape(workspacesFocusAreasShape, action);
        case CLEAR_WORKSPACES_FOCUS_AREAS_SHAPE: return clearWorkspacesFocusAreasShape(action);
        default: return workspacesFocusAreasShape;
    }
};
