import {
    getUserWorkspaceByIdApi
} from '../backend/domain/workspaces';

import {
    getWorkspaceApplicationByIdApi,
    getWorkspacesApplicationsSettingsApi,
    getWorkspacesApplicationsUsersSettingsApi,
    updateWorkspacesApplicationsSettingsApi,
    updateWorkspacesApplicationsUsersSettingsApi
} from '../backend/domain/workspacesApplications';

import {
    getUserByIdApi
} from '../backend/domain/users';

import { getWorkspaceAction } from '../actions/getWorkspaceAction';
import { getWorkspaceApplicationAction } from '../actions/getWorkspaceApplicationAction';
import { getWorkspaceAvailableApplicationsAction } from '../actions/getWorkspaceAvailableApplicationsAction';
import { getWorkspacesApplicationsSettingsAction } from '../actions/getWorkspacesApplicationsSettingsAction';
import { getDefaultApplicationsSettingsAction } from '../actions/getDefaultApplicationsSettingsAction';
import { clearDefaultApplicationsSettingsAction } from '../actions/clearDefaultApplicationsSettingsAction';
import { clearWorkspacesApplicationsSettingsAction } from '../actions/clearWorkspacesApplicationsSettingsAction';
import { loadingWorkspacesApplicationsSettingsAction } from '../actions/loadingWorkspacesApplicationsSettingsAction';
import { getWorkspacesApplicationsUsersSettingsAction } from '../actions/getWorkspacesApplicationsUsersSettingsAction';
import { clearWorkspacesApplicationsUsersSettingsAction } from '../actions/clearWorkspacesApplicationsUsersSettingsAction';
import { loadingWorkspacesApplicationsUsersSettingsAction } from '../actions/loadingWorkspacesApplicationsUsersSettingsAction';
import { getUserAction } from '../actions/getUserAction';
import { messageHandleUpdateError } from '../utils/errorMessages';
import { overrideWorkspaceId } from '../utils/workspaces';
import { overrideWorkspaceApplicationId } from '../utils/workspacesApplications';
import { overrideUserId } from '../utils/users';

export const getWorkspacesApplicationsSettingsWithThunk = (workspaceId, applicationId) =>
	async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);
        let appId = await overrideWorkspaceApplicationId(wId, applicationId);

        dispatch(loadingWorkspacesApplicationsSettingsAction(true));
		dispatch(clearWorkspacesApplicationsSettingsAction([]));
        getWorkspacesApplicationsSettingsApi(wId, appId)
            .then(response => {
				dispatch(getWorkspacesApplicationsSettingsAction(response.settings))
			})
			.then((response) => {
				dispatch(loadingWorkspacesApplicationsSettingsAction(false));
				return response;
			})
	};

export const getWorkspacesApplicationsSettingsByIdWithThunk = (workspaceId, applicationId, userId) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);
        let appId = await overrideWorkspaceApplicationId(wId, applicationId);

		dispatch(loadingWorkspacesApplicationsSettingsAction(true));
		dispatch(clearWorkspacesApplicationsSettingsAction([]));
        getUserWorkspaceByIdApi(wId, userId)
			.then((response) => {
                if (!response.error) {
					dispatch(getWorkspaceAction(response));

					let availableApplications = {};

					availableApplications.id = response.available_applications.id;
					availableApplications.applications = response.available_applications.applications;
					dispatch(getWorkspaceAvailableApplicationsAction(availableApplications));
				}
				return response;
			})
            .then(() => {
                getWorkspaceApplicationByIdApi(wId, appId)
					.then(response => {
						dispatch(getWorkspaceApplicationAction(response));
					})
					.then(() => {
                        getWorkspacesApplicationsSettingsApi(wId, appId)
							.then((response) => {
								dispatch(clearWorkspacesApplicationsSettingsAction([]));
								return response;
							})
							.then(response => {
								dispatch(getWorkspacesApplicationsSettingsAction(response.settings))
							})
							.then((response) => {
								dispatch(loadingWorkspacesApplicationsSettingsAction(false));
								return response;
							})
					});
			});
	};

export const updateWorkspacesApplicationsSettingsWithThunk = (workspaceId, applicationId, settings) =>
	(dispatch) => {
		dispatch(loadingWorkspacesApplicationsSettingsAction(true));
		updateWorkspacesApplicationsSettingsApi(workspaceId, applicationId, settings)
			.then(response => {
				const messages = response.status === 200 ? 'Application Settings Updated' : 'Application Settings Update Failed'
				messageHandleUpdateError(dispatch, response.status, messages);
				dispatch(loadingWorkspacesApplicationsSettingsAction(false));
			});
	};

export const getWorkspacesApplicationsUsersSettingsByIdWithThunk = (workspaceId, applicationId, userId, withPicture, width, height) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);
        let appId = await overrideWorkspaceApplicationId(wId, applicationId);
        let uId = await overrideUserId(userId);

		dispatch(loadingWorkspacesApplicationsUsersSettingsAction(true));
		dispatch(clearWorkspacesApplicationsUsersSettingsAction([]));
        getUserWorkspaceByIdApi(wId, uId)
			.then((response) => {
                if (!response.error) {
					dispatch(getWorkspaceAction(response));

					let availableApplications = {};

					availableApplications.id = response.available_applications.id;
					availableApplications.applications = response.available_applications.applications;
					dispatch(getWorkspaceAvailableApplicationsAction(availableApplications));
				}
				return response;
			})
			.then(() => {
                getUserByIdApi(uId, withPicture, width, height)
					.then((response) => {
						dispatch(getUserAction(response));
					})
			})
			.then(() => {
				getWorkspaceApplicationByIdApi(wId, appId)
					.then(response => {
						dispatch(getWorkspaceApplicationAction(response));
					})
					.then(async () => {

						let userSettings = [];

						await getWorkspacesApplicationsUsersSettingsApi(wId, appId, uId)
							.then((response) => {
								dispatch(clearDefaultApplicationsSettingsAction([]));
								dispatch(clearWorkspacesApplicationsUsersSettingsAction([]));
								return response;
							})
							.then(async response => {
								if (response.status === 200) {
									userSettings = response.data;
									await dispatch(getWorkspacesApplicationsUsersSettingsAction(response.data))
								}
							})
							.then((response) => {
								dispatch(loadingWorkspacesApplicationsUsersSettingsAction(false));
								return response;
							})

						getWorkspacesApplicationsSettingsApi(wId, appId)
							.then((response) => {
								dispatch(clearWorkspacesApplicationsSettingsAction([]));
								return response;
							})
							.then(response => {
								const appsSettings = response.settings.map(st => {
									const userSett = userSettings.filter(uSett => uSett.settingId === st.id)
									let value = st.value;
									if (userSett.length > 0) {
										value = userSett[0].settingValue;
									}
									return {
										...st,
										value
									}
								})

								dispatch(getDefaultApplicationsSettingsAction(response.settings));
								dispatch(getWorkspacesApplicationsSettingsAction(appsSettings));

							})
							.then((response) => {
								dispatch(loadingWorkspacesApplicationsSettingsAction(false));
								return response;
							})
					});
			});
	};

export const updateWorkspacesApplicationsUsersSettingsWithThunk = (workspaceId, applicationId, userId, updateSettings) =>
	(dispatch) => {

		dispatch(loadingWorkspacesApplicationsUsersSettingsAction(true));
		updateWorkspacesApplicationsUsersSettingsApi(workspaceId, applicationId, userId, updateSettings)
			.then((response) => {
				const messages = 'User Settings Updated'
				messageHandleUpdateError(dispatch, 200, messages);
				dispatch(loadingWorkspacesApplicationsUsersSettingsAction(false));
			});
	};
