import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/lab/Slider';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getCroppedImage } from '../../utils/cropImage';

class ImageEditor extends Component {
    static propTypes = {
        srcImage: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
        idEditor: PropTypes.string.isRequired,
        imgType: PropTypes.string.isRequired
    };

    state = {
        image: this.props.srcImage,
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: (this.props.imgType === 'banner') ? 32 / 10 : (this.props.imgType === 'feature') ? 3 / 2 : 4 / 3,
        croppedAreaPixels: null
    }

    onCropChange = crop => {
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels });
    }

    onZoomChange = zoom => {
        this.setState({ zoom })
    }

    onSelectChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onCloseButton = async () => {
        const croppedImage = await getCroppedImage(this.state.image, this.state.croppedAreaPixels)
        this.props.onClose(croppedImage.replace("data:image/png;base64,", ""), this.state.aspect, this.props.idEditor);
    }

    render() {
        return (
            <div>
                <div className="crop-container">
                    <Cropper
                        image={this.state.image}
                        crop={this.state.crop}
                        zoom={this.state.zoom}
                        aspect={this.state.aspect}
                        onCropChange={this.onCropChange}
                        onCropComplete={this.onCropComplete}
                        onZoomChange={this.onZoomChange}
                    />
                </div>
                <div className="crop-controls">
                    <div className="crop-components">
                        <Slider
                            value={this.state.zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e, zoom) => this.onZoomChange(zoom)}
                        />
                    </div>
                    <div className="crop-components">
                        <FormControl className="crop-form-control">
                            <InputLabel htmlFor="aspect-ratio">Aspect Ratio</InputLabel>
                            {(this.props.imgType === 'banner') ?
                                <Select
                                    value={this.state.aspect}
                                    onChange={this.onSelectChange}
                                    inputProps={{
                                        name: 'aspect',
                                        id: 'aspect-ratio',
                                    }}
                                >
                                    <MenuItem value={32 / 10}>32/10</MenuItem>
                                </Select>
                                : (this.props.imgType === 'feature') ?
                                    <Select
                                        value={this.state.aspect}
                                        onChange={this.onSelectChange}
                                        inputProps={{
                                            name: 'aspect',
                                            id: 'aspect-ratio',
                                        }}
                                    >
                                        <MenuItem value={3 / 2}>3/2</MenuItem>
                                    </Select>
                                    :
                                    <Select
                                        value={this.state.aspect}
                                        onChange={this.onSelectChange}
                                        inputProps={{
                                            name: 'aspect',
                                            id: 'aspect-ratio',
                                        }}
                                    >
                                        <MenuItem value={1 / 1}>1/1</MenuItem>
                                        <MenuItem value={3 / 2}>3/2</MenuItem>
                                        <MenuItem value={4 / 3}>4/3</MenuItem>
                                        <MenuItem value={16 / 9}>16/9</MenuItem>
                                        <MenuItem value={21 / 9}>21/9</MenuItem>
                                    </Select>
                            }
                        </FormControl>
                        <button onClick={() => { this.onCloseButton() }} className="btn btn-wrd_default icoTextBtn crop-button">
                            Close
                    </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ImageEditor);
