import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import WorkspacesCreateInput from './WorkspacesCreateInput';
import WorkspacesCreateLogo from './WorkspacesCreateLogo';
import WorkspacesCreateAction from './WorkspacesCreateAction';

class WorkspacesCreateForm extends Component {
    static propTypes = {
        workspace: PropTypes.object.isRequired
    };

    render() {
        return (
            <div className="formWrap">
                <div className="row">
                    <div className="col-md-12 mb-2">
                        <p className="text prologue mt-5">Please fill all the fields</p>
                    </div>
                    <WorkspacesCreateLogo />
                    <WorkspacesCreateInput />
                    <WorkspacesCreateAction />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ setup }) => ({
    workspace: setup.workspace
});

export default withRouter(connect(mapStateToProps)(WorkspacesCreateForm));