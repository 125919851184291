import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import sortBy from 'sort-by';
import { routeCodes } from '../../../../configurations/routes';
import { addWorkspaceFocusAreaShapeWithThunk } from '../../../../api/workspacesFocusAreasThunk';
import WorkspacesFocusAreasShapeItem from './WorkspacesFocusAreasShapeItem';
import { updateWorkspacesFocusAreasShapeHeaderAction } from '../../../../actions/updateWorkspacesFocusAreasShapeHeaderAction';
import { updateWorkspacesFocusAreasShapeAction } from '../../../../actions/updateWorkspacesFocusAreasShapeAction';
import { updateDialogFocusAreasShapeErrorAction } from '../../../../actions/updateDialogFocusAreasShapeErrorAction';
import { clearWorkspacesFocusAreasShapeAction } from '../../../../actions/clearWorkspacesFocusAreasShapeAction';
import { getFeatureData, getShpData, getDbfData } from '../../../../utils/shapeConverter';
import { updateDialogFocusAreasShapeFileAction } from '../../../../actions/updateDialogFocusAreasShapeFileAction';

class WorkspacesFocusAreasShapeBody extends Component {
    constructor(props) {
        super(props);
        this.onDbfFileUpload = this.onDbfFileUpload.bind(this);
        this.onShapeFileUpload = this.onShapeFileUpload.bind(this);
        this.onHeaderChange = this.onHeaderChange.bind(this);
        this.checkAllAreas = this.checkAllAreas.bind(this);
        this.backToFocusAreaPage = this.backToFocusAreaPage.bind(this);
        this.resetShapeFile = this.resetShapeFile.bind(this);
        this.state = {
            selectAll: true,
            dbfProps: []
        };
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        workspacesFocusAreasShape: PropTypes.array.isRequired,
        workspacesFocusAreasShapeHeader: PropTypes.array.isRequired,
        role: PropTypes.object.isRequired
    };

    onDbfFileUpload(e) {        
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            if (file.name.split('.').pop().toLowerCase() !== 'dbf') {
                let focus_area_shape_error = {
                    dialog_title: 'Failed',
                    dialog_type: 'Please provide only (.Dbf) file',
                    show_dialog: true
                };

                this.props.dispatchDialogFocusAreasShape(focus_area_shape_error);
            } else {
                let reader = new FileReader();
                reader.onload = (() => {
                    if (!reader.error) {
                        this.getDbfData(reader.result);
                    }
                })
                reader.readAsArrayBuffer(file);
            }
        }
    }


    onShapeFileUpload(e) {       
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            if (file.name.split('.').pop().toLowerCase() !== 'shp') {
                let focus_area_shape_error = {
                    dialog_title: 'Failed',
                    dialog_type: 'Please provide only (.Dbf) file',
                    show_dialog: true
                };

                this.props.dispatchDialogFocusAreasShape(focus_area_shape_error);
            } else {
                let reader = new FileReader();
                reader.onload = (() => {
                    if (!reader.error) {                        
                        this.getShpData(reader.result);
                    }
                })
                reader.readAsArrayBuffer(file);
            }
        }
    }

    getShapeData = async (file) => {
        const shapeData = await getFeatureData(file);
        this.props.dispatchWorkspacesFocusAreasShape(shapeData.features);
        this.props.dispatchWorkspacesFocusAreasShapeHeader(shapeData.featuresHeaders);
    }

    getShpData = async (file) => {        
        const shapeData = await getShpData(file);        
        for (let i in shapeData.features) {   
            var propsData = this.state.dbfProps.filter(function (event) {
                return event.id === shapeData.features[i].id;
            });            
            shapeData.features[i].properties = propsData[0].prop;            
        }
        this.props.dispatchWorkspacesFocusAreasShape(shapeData.features);
    }

    getDbfData = async (file) => {
        let dbfProps = [];
        const shapeData = await getDbfData(file);        
        this.props.dispatchWorkspacesFocusAreasShapeHeader(shapeData.featuresHeaders);
        dbfProps = dbfProps.concat(shapeData.dbfResults);
        this.setState({ dbfProps });
    }

    onHeaderChange(name) {
        const { workspacesFocusAreasShapeHeader } = this.props;
        let selectedHeaders = workspacesFocusAreasShapeHeader.filter((item) => item.name === name);
        let otherHeaders = workspacesFocusAreasShapeHeader.filter((item) => item.name !== name);
        let headers = [];

        if (selectedHeaders.length > 0) {
            selectedHeaders[0].checked = true;
        }

        for (let i in otherHeaders) {
            otherHeaders[i].checked = false;
        }

        headers = headers.concat(selectedHeaders);
        headers = headers.concat(otherHeaders);
        headers.sort(sortBy('index'));

        this.props.dispatchWorkspacesFocusAreasShapeHeader(headers);
    }

    backToFocusAreaPage() {
        const { workspace } = this.props;
        this.props.history.push(routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/areas');
    }

    checkAllAreas() {
        const { workspacesFocusAreasShape } = this.props;
        for (let i in workspacesFocusAreasShape) {
            if (this.state.selectAll) {
                workspacesFocusAreasShape[i].selected = false;
            }
            else {
                workspacesFocusAreasShape[i].selected = true;
            }
        }

        this.props.dispatchWorkspacesFocusAreasShape(workspacesFocusAreasShape);
        this.setState({ selectAll: !this.state.selectAll });
    }

    resetShapeFile() {
        this.props.dispatchWorkspacesFocusAreasShapeHeader([]);
        this.props.clearWorkspacesFocusAreasShape();
        let focusAreas = {
            show_dialog: true
        };

        this.props.dispatchDialogFocusAreaShapeFile(focusAreas);
    }

    addToFocusArea() {
        const { workspace, workspacesFocusAreasShapeHeader, workspacesFocusAreasShape } = this.props;
        if (workspacesFocusAreasShapeHeader.find(x => x.checked === true) === undefined) {
            let focus_areas_shape_error = {
                id: '',
                name: '',
                status: 500,
                dialog_title: 'Failed',
                dialog_type: 'Please select the column to be used as the focus areas names',
                show_dialog: true
            };

            this.props.dispatchDialogFocusAreasShape(focus_areas_shape_error);
        }
        else if (workspacesFocusAreasShape.find(x => x.selected === true) === undefined) {
            let focus_areas_shape_error = {
                id: '',
                name: '',
                status: 500,
                dialog_title: 'Failed',
                dialog_type: 'Please select the features that shall be added as focus areas',
                show_dialog: true
            };

            this.props.dispatchDialogFocusAreasShape(focus_areas_shape_error);
        }
        else {
            this.props.addWorkspaceFocusAreaShape(workspace.id, workspacesFocusAreasShapeHeader, workspacesFocusAreasShape);
        }
    }


    render() {
        const { workspacesFocusAreasShape, workspacesFocusAreasShapeHeader } = this.props;
        return (
            <div className="mainBody">
                <div className="row mt-4">
                    <div className="container-fluid">                                                
                        {(workspacesFocusAreasShape.length > 0 && workspacesFocusAreasShapeHeader.length > 0) ?
                            <div>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-9">
                                        <button className="btn icoTextBtn btn-wrd_default mr-3 greyBtn" onClick={() => this.backToFocusAreaPage()}>
                                            <span className="icon-wrd-arrow-left"></span>
                                            Back
                                    </button>
                                        <button className="btn icoTextBtn btn-wrd_default mr-3" onClick={() => this.checkAllAreas()}>
                                            <span className="icon-wrd-select"></span>
                                            {(this.state.selectAll) ? 'Deselect' : 'Select'} All
                                    </button>
                                        <button className="btn icoTextBtn btn-wrd_default mr-3" onClick={() => this.addToFocusArea()}>
                                            <span className="icon-wrd-add"></span>
                                            Add as Focus Area(s)
                                    </button>
                                        <button className="btn icoTextBtn btn-wrd_default" onClick={() => this.resetShapeFile()}>
                                            <span className="icon-wrd-reset"></span>
                                            Reselect Shapefile
                                    </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 my-3 d-flex">
                                        <div className="alert alert-info text-center w-100">
                                            <b>Please select the column to be used as the focus areas names and select the features that shall be added as focus areas</b>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="tableWrap2 focusAreaList mt-4 mb-3">
                                            <div className="table-responsive">
                                                <table className="table table-striped table-color text-align-center_portal table-min-width" cellSpacing="0" width="100%">
                                                    <thead>
                                                        <tr>
                                                            <th className="stickyActionLeft"></th>
                                                            {(workspacesFocusAreasShapeHeader !== undefined && workspacesFocusAreasShapeHeader.length > 0) ?
                                                                workspacesFocusAreasShapeHeader.map((item, index) => (
                                                                    <th key={index}>
                                                                        <label className="colpick">
                                                                            <input type="radio" name="colPick" className="d-none" onChange={() => this.onHeaderChange(item.name)} />
                                                                            <div className="headerName">{item.name}</div>
                                                                        </label>
                                                                    </th>
                                                                ))
                                                                : null
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(workspacesFocusAreasShape !== undefined && workspacesFocusAreasShape.length > 0) ?
                                                            workspacesFocusAreasShape.map((item) => (
                                                                <WorkspacesFocusAreasShapeItem key={item.id} item={item} />
                                                            ))
                                                            :
                                                            'No match'
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, role, workspacesFocusAreasShape, workspacesFocusAreasShapeHeader }) => ({
    workspace: detail.workspace,
    role,
    workspacesFocusAreasShape,
    workspacesFocusAreasShapeHeader
});

const mapDispatchToProps = (dispatch) => ({
    addWorkspaceFocusAreaShape: (workspaceId, workspacesFocusAreasShapeHeader, workspacesFocusAreasShape) => dispatch(addWorkspaceFocusAreaShapeWithThunk(workspaceId, workspacesFocusAreasShapeHeader, workspacesFocusAreasShape)),
    dispatchWorkspacesFocusAreasShapeHeader: (featuresHeaders) => dispatch(updateWorkspacesFocusAreasShapeHeaderAction(featuresHeaders)),
    dispatchWorkspacesFocusAreasShape: (workspacesFocusAreasShape) => dispatch(updateWorkspacesFocusAreasShapeAction(workspacesFocusAreasShape)),
    dispatchDialogFocusAreasShape: (dialog) => dispatch(updateDialogFocusAreasShapeErrorAction(dialog)),
    clearWorkspacesFocusAreasShape: () => dispatch(clearWorkspacesFocusAreasShapeAction([])),
    dispatchDialogFocusAreaShapeFile: (dialog) => dispatch(updateDialogFocusAreasShapeFileAction(dialog))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesFocusAreasShapeBody));