import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDialogUsersLoginErrorAction } from '../../../actions/updateDialogUsersLoginErrorAction';
import { routeCodes } from '../../../configurations/routes';

class UsersLoginErrorModal extends Component {
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
    }

    static propTypes = {
        type: PropTypes.string.isRequired,
        dialog: PropTypes.object.isRequired,
        dispatchDialogUsersLoginError: PropTypes.func.isRequired
    };

    setDefaultDialogState() {
        let users_confirmation = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            show_dialog: false
        };

        this.props.dispatchDialogUsersLoginError(users_confirmation);
    }

    closeModal() {
        const { dialog } = this.props;
        if (dialog.dialog_type === 'password_changed' && dialog.status === '200') {
            this.props.history.push(routeCodes.PROFILE);
        }
        if (dialog.dialog_type === 'password_reseted' && dialog.status === '200') {
            this.props.history.push(routeCodes.USERS_LOGIN);
        }
        this.setDefaultDialogState();
    }

    enterCloseModal(e) {
        if (e.key === 'Enter') {
            const { dialog } = this.props;
            if (dialog.dialog_type === 'password_changed' && dialog.status === '200') {
                this.props.history.push(routeCodes.PROFILE);
            }
            if (dialog.dialog_type === 'password_reseted' && dialog.status === '200') {
                this.props.history.push(routeCodes.USERS_LOGIN);
            }
            this.setDefaultDialogState();
        }
    }

    componentDidUpdate() {
        if (this.props.dialog.show_dialog) {
            this.textInput.current.focus();
        }
    }

    render() {
        const { dialog } = this.props;

        return (
            <div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div className={"popupPanel popupPanel-small deleteWarning" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                    <div className="popupHeader">
                        <div className="p-4">
                            <h2 className="popupTitle">{dialog.dialog_type}</h2>
                            <button className="btn iconBtn" onClick={() => { this.closeModal(); }}>
                                <span className="icon-wrd-close"></span>
                            </button>
                        </div>
                    </div>
                    <div className="popupBody" onKeyPress={() => { this.enterCloseModal(); }}>
                        <div className="p-4">
                            <div className="summernote">
                                {(dialog.status === 200) ?
                                    <p>
                                        <b className="actionEnable">Success:</b> {dialog.dialog_title}
                                    </p>
                                    :
                                    <p>
                                        <b className="actionRemove">ERROR:</b> {dialog.dialog_title}
                                    </p>
                                } 
                            </div>
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="px-4 pt-4 pb-2">
                            <div className="row">
                                <div className="col text-right">
                                    {(dialog.dialog_type === 'password_changed' || dialog.dialog_type === 'password_reseted' || dialog.dialog_type === 'password_reseted_admin' || dialog.dialog_type === 'reminder_sent') ?
                                        <button ref={this.textInput} className="greenBtn btn icoTextBtn mr-3 closePopup" onClick={() => { this.closeModal(); }}>
                                            Close
                                        </button>
                                        :
                                        <button ref={this.textInput} className="greyBtn btn icoTextBtn mr-3 closePopup" onClick={() => { this.closeModal(); }}>
                                            Close
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ dialog }) => ({
    dialog: dialog.login_error
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogUsersLoginError: (dialog) => dispatch(updateDialogUsersLoginErrorAction(dialog))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersLoginErrorModal));
