import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import SidebarContainer from '../../Sidebar/SidebarContainer';
import UserRegisterHeader from './UserRegisterHeader';
import UserRegisterBody from './UserRegisterBody';
import { setupNewUserAction } from '../../../actions/setupNewUserAction'
import { clearSetupNewUserAction } from '../../../actions/clearSetupNewUserAction';
import { changeNavigationAction } from '../../../actions/changeNavigationAction';
import { getDefaultUserPicture } from '../../../utils/cropImage';
import moment from 'moment';
import UsersCreationErrorModal from '../Modal/UsersCreationErrorModal';

class UserRegisterContainer extends Component {
    detailStart = React.createRef();

    static propTypes = {
        user: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        account: PropTypes.object.isRequired,
        navigation: PropTypes.object.isRequired,
        dispatchNavigation: PropTypes.func.isRequired
    };

    changeNavigation(name) {
        let navigation = {
            name
        };
        this.props.dispatchNavigation(navigation);
    }

    getCurrentDate() {
        let today = moment().format("MM-DD-YYYY");
        return today;
    }

    getUserName() {
        const { account } = this.props;
        let username = account.full_name;
        return username;
    }

    getUrlImage() {
        let urlImage = getDefaultUserPicture();
        return urlImage;
    }

    getStatus() {
        let status = "Active";
        return status;
    }

    dispatchNewUser(currentDate, username, urlImage, status) {
        let newUser = {
            ...this.props.user,
            default_url_image: urlImage,
            url_image: '',
            image_aspect_ratio: 4 / 3,
            created_date: currentDate,
            is_owner: false,
            username: username,
            status: status
        };
        this.props.setupNewUser(newUser);
    }

    setDefaultData() {
        const today = this.getCurrentDate();
        const username = this.getUserName();
        const urlImage = this.getUrlImage();
        const status = this.getStatus();

        this.dispatchNewUser(today, username, urlImage, status);
    }

    componentWillMount() {
        this.setDefaultData();
    }

    componentWillUnmount() {
        this.props.clearSetupNewUser();
    }

    componentDidMount() {
        this.changeNavigation('Users');
    }

    scrollToTop = () => {
        this.detailStart.current.scrollIntoView({ behavior: 'smooth' })
    }

    render() {
        const { loading, loadingTheme } = this.props;

        return (
            <div>
                <div ref={this.detailStart} />
                <div>
                    {loading &&
                        <div className="loader-container">
                            <Loader
                                type="Ball-Triangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                    {!loadingTheme &&
                        <div>
                            <SidebarContainer />
                            <main>
                                <div className="container-fluid h-100">
                                    <div className="mcp">
                                        <UserRegisterHeader />
                                        <UserRegisterBody scrollTop={this.scrollToTop} />
                                    </div>
                                </div>
                            </main>
                            <UsersCreationErrorModal type="error" />
                        </div>
                   }
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ setup, loading, detail, navigation }) => ({
    user: setup.user,
    loading: loading.create_user.loading,
    loadingTheme: loading.theme.loading,
    account: detail.account,
    navigation
});

const mapDispatchToProps = (dispatch) => ({
    setupNewUser: (user) => dispatch(setupNewUserAction(user)),
    clearSetupNewUser: () => dispatch(clearSetupNewUserAction([])),
    dispatchNavigation: (navigation) => dispatch(changeNavigationAction(navigation))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserRegisterContainer));