import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUrlWorkspaceId } from '../../utils/url';
import Rummernote from 'rummernote/build/bs4';
import { updateContactAdministratorsAction } from '../../actions/updateContactAdministratorsAction';
import { routeCodes } from '../../configurations/routes';
import { sendContactAdministratorsWithThunk } from '../../api/contactAdministratorsThunk';

class ContactAdministratorsBody extends Component {
    constructor(props) {
        super(props);
        this.handleSubjectChange = this.handleSubjectChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.onEditorChange = this.onEditorChange.bind(this);
        this.textInput = React.createRef();
    }

    static propTypes = {
        role: PropTypes.object.isRequired,
        portalRouter: PropTypes.object.isRequired,
        contactAdministrators: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        dispatchUpdateContactAdministrators: PropTypes.func.isRequired
    }

    onEditorChange(text) {
        let contact = {
            ...this.props.contactAdministrators,
            emailContent: text
        };
        this.props.dispatchUpdateContactAdministrators(contact);
    }

    getCurrentWorkspaceId() {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    handleBack() {        
        this.props.history.push(routeCodes.PROFILE);
    }

    onSubmit() {
        const { role, contactAdministrators } = this.props;
        this.props.sendContactAdministrator(role.user_id, contactAdministrators);
    }

    handleSubjectChange(e) {       
        let contact = {
            ...this.props.contactAdministrators,
            subject: e.target.value
        };
        this.props.dispatchUpdateContactAdministrators(contact);
    }

    componentDidMount() {
        this.textInput.current.focus();
    }

    render() {
        const { user_name } = this.props.role;
        const { emailContent, subject } = this.props.contactAdministrators;
        return (
            <div className="container-fluid h-100">
                <div className="mcp">
                    <div className="mainHead">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="float-left mt-2 mr-4">Contact Administrator</h2>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className="mainBody">
                        <div className="message">
                            <div className="formWrap">
                                <div className="row">
                                    <div className="col-10">
                                        <div className="mb-2 detail"><span className="label">From :</span> &nbsp;&nbsp;{user_name}
                                            <span></span>
                                        </div>
                                        <div className="mb-2 detail"><span className="label">To :</span>&nbsp;&nbsp;
                                                <span> Administrator(s)  </span>
                                        </div>
                                    </div>
                                    <div className="col-2 text-right">
                                        <button type="button" className="btn iconBtn mr-2" onClick={this.handleBack} ><span
                                            className="icon-wrd-close"></span><br />
                                            Cancel</button>
                                        <button type="submit" className="btn iconBtn" onClick={this.onSubmit}><span
                                            className="icon-wrd-registration"></span><br />
                                            Send</button>
                                    </div>
                                </div>
                                <hr className="grey" />
                                <div className="row">
                                    <div className="col-12 mt-2">
                                        <div className="summernote">
                                            <div className="inputForm">
                                                <input className="form-control" ref={this.textInput} type="text" name="subject"
                                                    onChange={this.handleSubjectChange} value={subject} required />
                                                <label>Subject</label>
                                            </div>
                                            <Rummernote value={emailContent} onChange={this.onEditorChange}
                                                placeholder='Type message here'
                                                onImgUpload={this.uploadImg} options={{
                                                    toolbar: [],
                                                    airMode: true,
                                                    tabsize: 2,
                                                    height: 300
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = ({ contactAdministrators, loading, role, portalRouter }) => ({
    role: role,
    loading: loading.contactAdministrators.loading,
    contactAdministrators: contactAdministrators,
    portalRouter
});

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateContactAdministrators: (contact) => dispatch(updateContactAdministratorsAction(contact)),
    sendContactAdministrator: (uid, contactAdministrators) => dispatch(sendContactAdministratorsWithThunk(uid, contactAdministrators)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactAdministratorsBody));
