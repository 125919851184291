import { getWorkspacesShareListApi } from '../backend/domain/workspacesMembers';
import { getWorkspacesShareListAction } from '../actions/getWorkspacesShareListAction';
import { clearWorkspacesShareListAction } from '../actions/clearWorkspacesShareListAction';
import { getWorkspacesShareListPaginationAction } from '../actions/getWorkspacesShareListPaginationAction';
import { loadingWorkspacesShareListAction } from '../actions/loadingWorkspacesShareListAction';

export const getWorkspacesShareListWithThunk = (userId, userRole, pageIndex, pageSize) =>
    (dispatch) => {
        dispatch(loadingWorkspacesShareListAction(true));
        dispatch(clearWorkspacesShareListAction([]));
        getWorkspacesShareListApi(userId, userRole, pageIndex, pageSize)
            .then((response) => {
                dispatch(getWorkspacesShareListPaginationAction(response.pagination));
                return response;
            })
            .then((response) => {
                dispatch(getWorkspacesShareListAction(response.workspaces));
                return response
            })
            .then((response) => {
                dispatch(loadingWorkspacesShareListAction(false));
                return response;
            });
    };