import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userLogout } from '../../../api/logoutThunk';
import { routeCodes } from '../../../configurations/routes';

class UsersLogoutContainer extends Component {
    static propTypes = {
        role: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired,
    };

    componentWillMount() {        
        this.props.logout();        
        this.props.history.push(routeCodes.USERS_LOGIN);
    }

    render() {
        return (null);
    }
}

const mapStateToProps = ({ role }) => ({
    role
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(userLogout())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersLogoutContainer));
