import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner'
import { getPortalLandingBannerWithThunk } from '../../../api/portalLandingThunk';

class PortalLandingBanner extends Component {
    static propTypes = {
        landingBanner: PropTypes.array,
        loading: PropTypes.bool.isRequired
    };

    componentDidMount() {
        this.props.getPortalLandingBanner();
    }
    render() {
        const { landingBanner, loading } = this.props;
        return (
            <div className="w-100" >
                {
                    loading &&
                    <div className="loader-portal-banner">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="70"
                            width="70"
                        />
                    </div>
                }
                <div className="bannerSection w-100">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col p-0">
                                {(landingBanner !== undefined && landingBanner.length > 0) ?
                                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                        <ol className="carousel-indicators">
                                            {landingBanner.map((item, index) => (
                                                <li key={item.id} data-target="#carouselExampleIndicators" data-slide-to={index} className={(index === 0) ? "active" : null}></li>
                                            ))}
                                        </ol>
                                        <div className="carousel-inner">
                                            {landingBanner.map((item, index) => (
                                                <div key={item.id} className={(index === 0) ? "carousel-item text-center active" : "carousel-item text-center "}>
                                                    <img style={{margin:'auto', display:'block'}} src={((item.image !== undefined) ? "data:image/jpeg;base64," : "") + item.image} alt={item.banner_title} />
                                                    <h2 className="portal-banner-title">{item.banner_title}</h2>
                                                    <p className="portal-banner-caption">{item.banner_caption}</p>
                                                </div>
                                            ))}
                                            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span className="sr-only">Previous</span>
                                            </a>
                                            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span className="sr-only">Next</span>
                                            </a>

                                        </div>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ portalLanding, loading }) => ({
    landingBanner: portalLanding.portal_banner.banners,
    loading: loading.portal_landing_banner.loading
});


const mapDispatchToProps = (dispatch) => ({
    getPortalLandingBanner: () => dispatch(getPortalLandingBannerWithThunk()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalLandingBanner));
