import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RichTextEditor from '../../Editor/RichTextEditor'
import { getCategoryAction } from '../../../actions/getCategoryAction';
import { updateCategoryWithThunk, deleteCategoryWithThunk } from '../../../api/categoriesThunk';
import { defaultApplicationsSortingBy, defaultApplicationsSortingType } from '../../../configurations/app';
import { routeCodes } from '../../../configurations/routes';

class CategoriesDetailBody extends Component {
    constructor(props) {
        super(props);
        this.onTitleChange = this.onTitleChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.openApplicationList = this.openApplicationList.bind(this);
        this.updateCategory = this.updateCategory.bind(this);
        this.removeCategory = this.removeCategory.bind(this);
    }

    static propTypes = {
        role: PropTypes.object.isRequired,
        category: PropTypes.object.isRequired
    };

    onTitleChange(e) {
        let category = {
            ...this.props.category,
            category_title: e.target.value,
        };

        this.props.dispatchCategory(category);
    }

    onDescriptionChange(data) {
        let category = {
            ...this.props.category,
            category_description: data
        };
        this.props.dispatchCategory(category);
    }

    openApplicationList() {
        this.props.history.push(routeCodes.APPLICATION_LIST);
    }

    updateCategory() {
        const { category } = this.props;
        this.props.updateCategory(category);
    }

    removeCategory() {
        const { category } = this.props;
        const routeBackTo = routeCodes.APPLICATION_LIST;
        this.props.deleteCategory(category.category_id, 1, 10000, defaultApplicationsSortingBy, defaultApplicationsSortingType, routeBackTo, this.props.history);
    }

    render() {
        const { category, role } = this.props;

        return (
            <div>
                <div className="mainHead">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="float-left mt-2 mr-4">Category</h2>
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="mainBody">
                    <div className="formWrap">
                        <div className="row">
                            <div className="col-7">
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" defaultValue={category.category_title} onChange={this.onTitleChange} required />
                                    <label>Title</label>
                                </div>
                                <label>Description</label>
                                <RichTextEditor value={category.category_description} onChange={this.onDescriptionChange} required />
                                <div className="col-md-12">
                                    <div className="inputForm buttonGroup mt-2 text-center">
                                        <button className="btn greyBtn icoTextBtn mx-2" onClick={this.openApplicationList}><span className="icon-wrd-close"></span>Cancel</button>
                                        <button className="btn greenBtn icoTextBtn mx-2" onClick={this.updateCategory}><span className="icon-wrd-add"></span> Update Category</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="topNavBtn">
                        {role.user_role === 'Admin' &&
                            <li>
                                <button className="btn redBtn" onClick={this.removeCategory}>
                                    <span className="icon-wrd-trash"></span>
                                    Remove Category
                            </button>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail }) => ({
    category: detail.category
});

const mapDispatchToProps = (dispatch) => ({
    dispatchCategory: (category) => dispatch(getCategoryAction(category)),
    updateCategory: (category) => dispatch(updateCategoryWithThunk(category)),
    deleteCategory: (categoryId, pageIndex, pageSize, sortingBy, sortingType, routeBackTo, historyProps) => dispatch(deleteCategoryWithThunk(categoryId, pageIndex, pageSize, sortingBy, sortingType, routeBackTo, historyProps))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoriesDetailBody));