import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDialogWorkspacesConnectionsAction } from '../../../actions/updateDialogWorkspacesConnectionsAction';
import { updateWorkspaceFocusAreaConnectionsAction } from '../../../actions/updateWorkspaceFocusAreaConnectionsAction';
import { updateWorkspaceFocusAreaConnectionWithThunk } from '../../../api/workspacesFocusAreasThunk';
import { clearWorkspaceFocusAreaConnectionsAction } from '../../../actions/clearWorkspaceFocusAreaConnectionsAction';
import Loader from 'react-loader-spinner';

class WorkspacesModalConnections extends Component {
    constructor(props) {
        super(props);        
        this.onConnectionStringChange = this.onConnectionStringChange.bind(this);
        this.onConnectionStringBlur = this.onConnectionStringBlur.bind(this);
    }

    static propTypes = {
        type: PropTypes.string.isRequired,
        dialog: PropTypes.object.isRequired,
        workspace: PropTypes.object.isRequired,
        dispatchDialogWorkspaces: PropTypes.func.isRequired
    };

    setDefaultDialogState() {
        let workspaces = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            route_back_to: '',
            show_dialog: false
        };

        this.props.dispatchDialogWorkspaces(workspaces);
    }

    onConnectionStringChange(e, id) {
        const { connections } = this.props;
        let filteredConnection = connections.filter(item => id === item.id)[0];
        filteredConnection.connection_string = e.target.value;
        let index = connections.findIndex(item => item.id === id);
        if (~index) {
            connections[index] = filteredConnection;
        }

        this.props.dispatchUpdateConnections(connections);
    }

    onConnectionStringBlur(e, id) {
        const { connections } = this.props;
        let index = connections.findIndex(item => item.id === id);
        if (index === 0) {
            for (let i in connections) {
                if (connections[i].connection_string === '') {
                    connections[i].connection_string = connections[0].connection_string;
                }
            }
        }

        this.props.dispatchUpdateConnections(connections);
    }

    closeModal() {
        this.props.clearWorkspaceFocusAreaConnections();
        this.setDefaultDialogState();
    }

    updateConnections() {
        const { workspace, connections } = this.props;

        this.props.updateWorkspaceFocusAreaConnection(workspace.id, connections);
    }

    render() {
        const { connections, dialog, loading } = this.props;
        if (dialog.dialog_type === 'updated')
            return (<div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div className={"popupPanel popupPanel-small deleteWarning" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                    <div className="popupHeader">
                        <div className="p-4">
                            <h2 className="popupTitle">{dialog.dialog_title}</h2>
                            <button className="btn iconBtn" onClick={() => { this.closeModal(); }}>
                                <span className="icon-wrd-close"></span>
                            </button>
                        </div>
                    </div>
                    <div className="popupBody">
                        <div className="p-4">
                            <div className="summernote">
                                <p>
                                    <b className="actionEnable">Success:</b> Connection Strings Updated.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="px-4 pt-4 pb-2">
                            <div className="row">
                                <div className="col text-right">
                                    <button className="greenBtn btn icoTextBtn mr-3 closePopup" onClick={() => { this.closeModal(); }}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
        else
            return (
                <div className={"popupContainer h-100 d-none align-items-center justify-content-center" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                    <div className={"popupPanel popupPanel-large" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                        <div className="popupHeader">
                            <div className="p-4">
                                <h2 className="popupTitle">Connection Strings</h2>
                                <button className="btn iconBtn" onClick={() => { this.closeModal(); }}>
                                    <span className="icon-wrd-close"></span>
                                </button>
                            </div>
                        </div>
                        {loading &&
                            <div className="loader-container-workspace-connections">
                                <Loader
                                    type="Ball-Triangle"
                                    color="#00BFFF"
                                    height="70"
                                    width="70"
                                />
                            </div>
                        }
                        <div className="popupBody modal-connection-height">
                            <div className="p-4">
                                <div className="formWrap">
                                    {(connections !== undefined && connections.length > 0) ?
                                        connections.map((item) => (
                                            (item.enabled) ?
                                                <div className="inputForm" key={item.id}>
                                                    <input className="form-control" type="text" name=""
                                                        defaultValue={item.connection_string} onChange={(e) => this.onConnectionStringChange(e, item.id)} onBlur={(e) => this.onConnectionStringBlur(e, item.id)}
                                                        required />
                                                    <label>{item.focus_area_name}</label>
                                                </div>
                                                :
                                                null
                                        ))
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="popupFooter">
                            <div className="px-4 pt-4 pb-2">
                                <div className="row">
                                    <div className="col">
                                        <button className="btn greyBtn icoTextBtn closePopup" onClick={() => { this.closeModal(); }}>
                                            <span className="icon-wrd-close"></span>
                                            Cancel </button>
                                    </div>
                                    <div className="col text-right">
                                        <button className="btn greenBtn icoTextBtn mr-3 closePopup" onClick={() => { this.updateConnections(); }}>
                                            <span className="icon-wrd-edit"></span>
                                            Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
}

const mapStateToProps = ({ dialog, detail, workspaceConnections, loading }) => ({
    dialog: dialog.workspaces_connections,
    workspace: detail.workspace,
    connections: workspaceConnections.connections,
    loading: loading.workspace_focus_area_connections.loading
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogWorkspaces: (dialog) => dispatch(updateDialogWorkspacesConnectionsAction(dialog)),
    dispatchUpdateConnections: (connections) => dispatch(updateWorkspaceFocusAreaConnectionsAction(connections)),
    updateWorkspaceFocusAreaConnection: (id, connections) => dispatch(updateWorkspaceFocusAreaConnectionWithThunk(id, connections)),
    clearWorkspaceFocusAreaConnections: () => dispatch(clearWorkspaceFocusAreaConnectionsAction())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesModalConnections));
