import environment from 'environment';
import { updateImage } from '../../utils/snapshot';

const config = require('../../environments/' + environment.settings);
const axios = require('axios');
const qs = require('qs');

async function getWorkspaceById(workspaceId, includeLockStatus) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = (includeLockStatus) ? await axios.get(queryBaseUrl + "workspace/" + workspaceId + "?includeLockStatus=true") : await axios.get(queryBaseUrl + "workspace/" + workspaceId);

    return results;
}

async function getWorkspaceByName(workspacename) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/id/" + workspacename);

    return results;
}

async function getWorkspaceWithLogoById(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId);

    return results;
}

async function getWorkspaceHeaderById(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/header/" + workspaceId);
    return results;
}

async function getWorkspaceList(pageIndex, pageSize, sortingBy, sortingType) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/list", {
        params: {
            pageIndex: pageIndex,
            pageSize: pageSize,
            sort: sortingBy,
            order: sortingType,
            logo: true
        }
    });

    return results;
}

async function getWorkspaceSearchList(keyword, pageIndex, pageSize, sortingBy, sortingType) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/search/" + pageIndex + '/' + pageSize, {
        params: {
            keyword: keyword,
            sort: sortingBy,
            order: sortingType,
            logo: true
        }
    });

    return results;
}

async function getWorkspaceHeaderList(pageIndex, pageSize, sortingBy, sortingType) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/header/list/" + pageIndex + '/' + pageSize, {
        params: {
            sort: sortingBy,
            order: sortingType
        }
    });

    return results;
}

async function getContactOwnerId(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + 'workspace/' + workspaceId + '/owner?status=OnlyActive')
        .then(res => { return res.data })
        .catch(function (error) { return error.response; });
    return results;

}

async function getWorkspaceNameByCustomDomain(domain) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + 'workspace/domain/' + domain)
        .then(res => { return res.data })
        .catch(function (error) { return error.response; });
    return results;

}

async function getContactOwnerUserId(ids) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "management/account/emails?"
        , {
            params: {
                Ids: ids
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        })
        .then(res => {
            return res
        })
        .catch(error => {
            return error.response;
        });
    return results;
}

async function getWorkspaceLogo(workspaceId, width, height) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const url = queryBaseUrl + "workspace/" + workspaceId + "/logo/" + width + "/" + height;

    let results;
    try {
        const response = await axios.get(url, { responseType: 'arraybuffer' });
        results = Buffer.from(response.data, 'binary').toString('base64');
    }
    catch (err) {
        if (err.response !== undefined) {
            if (err.response.status === 404) {
                return results;
            }
        }
    }
    return results;
}

async function createWorkspace(workspace) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "workspace", workspace)
        .catch(function (error) { return error.response });
    return results;
}

async function updateWorkspace(workspace) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "workspace", workspace)
        .catch(function (error) { return error.response });
    return results;
}

async function updateWorkspaceStatus(workspaceId, status) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "workspace/" + workspaceId + "/" + status);
    return results;
}

async function deleteWorkspace(workspaceId) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.delete(commandBaseUrl + "workspace/" + workspaceId);
    return results;
}

async function updateWorkspaceLogo(workspaceId, content) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const url = commandBaseUrl + "workspace/" + workspaceId + "/logo";

    return await updateImage(content, url);;
}

/* POST */
async function sendEmailToWorkspaceOwners(workspaceId, model) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const results = await axios.post(commandBaseUrl + "workspace/" + workspaceId + "/contactowners", model);
    return results;
}

export {
    getWorkspaceById,
    getWorkspaceByName,
    getWorkspaceWithLogoById,
    getWorkspaceHeaderById,
    getWorkspaceHeaderList,
    getWorkspaceList,
    getWorkspaceSearchList,
    getContactOwnerId,
    getWorkspaceNameByCustomDomain,
    getContactOwnerUserId,
    createWorkspace,
    updateWorkspace,
    updateWorkspaceStatus,
    deleteWorkspace,
    getWorkspaceLogo,
    updateWorkspaceLogo,
    sendEmailToWorkspaceOwners
}