import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDialogFocusAreasShapeErrorAction } from '../../../../actions/updateDialogFocusAreasShapeErrorAction';
import { updateDialogFocusAreasShapeFileAction } from '../../../../actions/updateDialogFocusAreasShapeFileAction';
import { clearWorkspacesFocusAreasShapeAction } from '../../../../actions/clearWorkspacesFocusAreasShapeAction';
import { routeCodes } from '../../../../configurations/routes';
import { getShpData, getDbfData } from '../../../../utils/shapeConverter';
import { updateWorkspacesFocusAreasShapeHeaderAction } from '../../../../actions/updateWorkspacesFocusAreasShapeHeaderAction';
import { updateWorkspacesFocusAreasShapeAction } from '../../../../actions/updateWorkspacesFocusAreasShapeAction';

class WorkspacesFocusAreasShapeFileModal extends Component {
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
        this.closeModal = this.closeModal.bind(this);
        this.onDbfFileUpload = this.onDbfFileUpload.bind(this);
        this.onShapeFileUpload = this.onShapeFileUpload.bind(this);
        this.onPrjFileUpload = this.onPrjFileUpload.bind(this);
        this.processShapeFile = this.processShapeFile.bind(this);
        this.state = {
            dbfFileName: '',
            shpFileName: '',
            prjFileName: '',
            dbfProps: [],
            shpProps: [],
            prjProps: false
        };
    }

    static propTypes = {
        dialog: PropTypes.object.isRequired,
        dispatchDialogFocusAreasShape: PropTypes.func.isRequired,
        dispatchDialogFocusAreasShapeFile: PropTypes.func.isRequired
    };

    setDefaultDialogState() {
        let users_creation = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            show_dialog: false
        };

        this.props.dispatchDialogFocusAreasShapeFile(users_creation);
    }

    closeModal() {
        const { workspace } = this.props;
        this.setDefaultDialogState();
        this.props.history.push(routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/areas');
    }

    onDbfFileUpload(e) {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            if (file.name.split('.').pop().toLowerCase() !== 'dbf') {
                let focus_area_shape_error = {
                    dialog_title: 'Failed',
                    dialog_type: 'Please provide only (.Dbf) file',
                    show_dialog: true
                };

                this.props.dispatchDialogFocusAreasShape(focus_area_shape_error);
            } else {
                let reader = new FileReader();
                reader.onload = (() => {
                    if (!reader.error) {
                        this.getDbfData(reader.result);
                    }
                })
                reader.readAsArrayBuffer(file);
                this.setState({ dbfFileName: file.name });
            }
        }
    }

    onShapeFileUpload(e) {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            if (file.name.split('.').pop().toLowerCase() !== 'shp') {
                let focus_area_shape_error = {
                    dialog_title: 'Failed',
                    dialog_type: 'Please provide only (.Shp) file',
                    show_dialog: true
                };

                this.props.dispatchDialogFocusAreasShape(focus_area_shape_error);
            } else {
                let reader = new FileReader();
                reader.onload = (() => {
                    if (!reader.error) {
                        this.getShpData(reader.result);
                    }
                })
                reader.readAsArrayBuffer(file);
                this.setState({ shpFileName: file.name });
            }
        }
    }

    onPrjFileUpload(e) {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            if (file.name.split('.').pop().toLowerCase() !== 'prj') {
                let focus_area_shape_error = {
                    dialog_title: 'Failed',
                    dialog_type: 'Please provide only (.Prj) file',
                    show_dialog: true
                };

                this.props.dispatchDialogFocusAreasShape(focus_area_shape_error);
            } else {
                this.setState({ prjProps: true });
                this.setState({ prjFileName: file.name });
            }
        }
    }

    getShpData = async (file) => {
        let shpProps = [];
        const shapeData = await getShpData(file);
        shpProps = shpProps.concat(shapeData.features);
        this.setState({ shpProps });
    }

    getDbfData = async (file) => {
        let dbfProps = [];
        const shapeData = await getDbfData(file);
        this.props.dispatchWorkspacesFocusAreasShapeHeader(shapeData.featuresHeaders);
        dbfProps = dbfProps.concat(shapeData.dbfResults);
        this.setState({ dbfProps });
    }

    processShapeFile = async () => {
        if (this.state.shpFileName.split('.').shift() === this.state.dbfFileName.split('.').shift()) {

            let shapeData = this.state.shpProps;

            for (let i in shapeData) {
                var propsData = this.state.dbfProps.filter(function (event) {
                    return event.id === shapeData[i].id;
                });
                shapeData[i].properties = propsData[0].prop;
            }

            this.props.dispatchWorkspacesFocusAreasShape(shapeData);
            this.setDefaultDialogState();
            this.setState({ dbfProps: [] });
            this.setState({ shpProps: [] });
            this.setState({ prjProps: false });
        } else {
            let focus_area_shape_error = {
                dialog_title: 'Failed',
                dialog_type: 'Please provide same name for all files',
                show_dialog: true
            };

            this.props.dispatchDialogFocusAreasShape(focus_area_shape_error);

            this.setState({ dbfProps: [] });
            this.setState({ shpProps: [] });
            this.setState({ prjProps: false });
        }

    }

    render() {
        const { dialog } = this.props;

        return (
            <div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div className={"popupPanel popupPanel-small p-4" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                    <p className="pupupName"><strong>Browse File</strong></p>
                    <div className={(this.state.shpProps.length > 0) ? "browseFileGroup complete" : "browseFileGroup"}>
                        <span className="icon-wrd-file"></span>
                        <strong>Required ".SHP" File</strong><br />
                        <span className="statusText">{(this.state.shpProps.length === 0) ? "Waiting" : this.state.shpFileName}</span>
                        <label
                            className="btn btn-wrd_default textBtn browseBtn">
                            <input type="file" onChange={this.onShapeFileUpload} accept=".shp" onClick={(event) => {
                                event.target.value = null
                            }}/>
                            Browse
                         </label>
                        <span className="icon-wrd-check-circle"></span>
                    </div>
                    <div className={(this.state.dbfProps.length > 0) ? "browseFileGroup complete" : "browseFileGroup"}>
                        <span className="icon-wrd-file"></span>
                        <strong>Required ".DBF" File</strong><br />
                        <span className="statusText">{(this.state.dbfProps.length === 0) ? "Waiting" : this.state.dbfFileName}</span>
                        <label
                            className="btn btn-wrd_default textBtn browseBtn">
                            <input type="file" onChange={this.onDbfFileUpload} accept=".dbf" onClick={(event) => {
                                event.target.value = null
                            }}/>
                            Browse
                         </label>
                        <span className="icon-wrd-check-circle"></span>
                    </div>
                    <div className={(this.state.prjProps) ? "browseFileGroup complete" : "browseFileGroup"}>
                        <span className="icon-wrd-file"></span>
                        <strong>Optional ".PRJ" File</strong><br />
                        <span className="statusText">{(!this.state.prjProps) ? "Waiting" : this.state.prjFileName}</span>
                        <label
                            className="btn btn-wrd_default textBtn browseBtn">
                            <input type="file" onChange={this.onPrjFileUpload} accept=".prj" onClick={(event) => {
                                event.target.value = null
                            }}/>
                            Browse
                         </label>
                        <span className="icon-wrd-check-circle"></span>
                    </div>
                    <div className="mt-5">
                        <div className="row">
                            <div className="col text-left"><button className="btn greyBtn icoTextBtn"
                                onClick={this.closeModal}><span
                                    className="icon-wrd-close"></span>Cancel</button>
                            </div>
                            <div className="col text-right">
                                <button className={(this.state.dbfProps.length > 0 && this.state.shpProps.length > 0) ? "btn greenBtn icoTextBtn" : "btn icoTextBtn button-disabled"}
                                    onClick={this.processShapeFile} disabled={(this.state.dbfProps.length === 0 || this.state.shpProps.length === 0)}>
                                    {(this.state.dbfProps.length > 0 && this.state.shpProps.length > 0) ?
                                        <span className="icon-wrd-check-circle"></span> : null
                                    }
                                    Process
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ dialog, detail, workspacesFocusAreasShape, workspacesFocusAreasShapeHeader }) => ({
    dialog: dialog.focus_areas_shape_file,
    workspace: detail.workspace,
    workspacesFocusAreasShape,
    workspacesFocusAreasShapeHeader
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogFocusAreasShape: (dialog) => dispatch(updateDialogFocusAreasShapeErrorAction(dialog)),
    dispatchDialogFocusAreasShapeFile: (dialog) => dispatch(updateDialogFocusAreasShapeFileAction(dialog)),
    clearWorkspacesFocusAreasShape: () => dispatch(clearWorkspacesFocusAreasShapeAction([])),
    dispatchWorkspacesFocusAreasShapeHeader: (featuresHeaders) => dispatch(updateWorkspacesFocusAreasShapeHeaderAction(featuresHeaders)),
    dispatchWorkspacesFocusAreasShape: (workspacesFocusAreasShape) => dispatch(updateWorkspacesFocusAreasShapeAction(workspacesFocusAreasShape))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesFocusAreasShapeFileModal));
