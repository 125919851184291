import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDialogApplicationsCreationErrorAction } from '../../../actions/updateDialogApplicationsCreationErrorAction';
import { createApplicationWithThunk } from '../../../api/applicationsThunk';
import { updateApplicationWithThunk } from '../../../api/applicationsThunk';

class ApplicationsCreationErrorModal extends Component {    
    constructor(props) {
        super(props);
        this.createNewApplication = this.createNewApplication.bind(this);
        this.textInput = React.createRef();
    }

    static propTypes = {
        type: PropTypes.string.isRequired,
        dialog: PropTypes.object.isRequired,
        dispatchDialogApplicationsCreationError: PropTypes.func.isRequired
    };

    setDefaultDialogState() {
        let users_creation = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            show_dialog: false
        };

        this.props.dispatchDialogApplicationsCreationError(users_creation);
    }

    createNewApplication() {
        this.setDefaultDialogState();
        const { dialog } = this.props;
        if (dialog.id === 'create') {
            const { applicationSetup } = this.props;
            this.props.createApplication(applicationSetup, this.props.history, true);
        }
        else {
            const { applicationDetail } = this.props;
            this.props.updateApplication(applicationDetail, true, this.props.history);
        }
    }

    closeModal() {
        const { dialog } = this.props;
        this.setDefaultDialogState();

        if (dialog.status === 200 && dialog.dialog_title === 'Creation Success') {
            this.props.history.push(dialog.name);
        }
    }

    componentDidUpdate(nextProps) {
        if (nextProps.dialog.show_dialog) {
            this.textInput.current.focus();
        }
    }

    render() {
        const { dialog } = this.props;

        return (
            <div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div className={"popupPanel popupPanel-small deleteWarning" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                    <div className="popupHeader">
                        <div className="p-4">
                            <h2 className="popupTitle">{dialog.dialog_title}</h2>
                            <button className="btn iconBtn" onClick={() => { this.closeModal(); }}>
                                <span className="icon-wrd-close"></span>
                            </button>
                        </div>
                    </div>
                    <div className="popupBody">
                        <div className="p-4">
                            <div className="summernote">
                                {(dialog.status === 200) ?
                                    <p>
                                        <b className="actionEnable">Success:</b> {dialog.dialog_type}
                                    </p>
                                    :
                                    <p>
                                        <b className="actionRemove">ERROR:</b> {dialog.dialog_type}
                                    </p>
                                }   
                            </div>
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="px-4 pt-4 pb-2">
                            {(dialog.status === 404) ?
                                <div className="row">
                                    <div className="col">
                                        <button className="btn greyBtn icoTextBtn closePopup width-100px" onClick={() => { this.closeModal(); }}>
                                            Cancel
                                        </button>
                                    </div>
                                    <div className="col text-right">
                                        <button ref={this.textInput} className="greenBtn btn icoTextBtn mr-3 closePopup width-100px" onClick={() => { this.createNewApplication(); }}>
                                            Yes
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className="row">
                                    <div className="col text-right">
                                        {(dialog.status === 200) ?
                                            <button ref={this.textInput} className="greenBtn btn icoTextBtn mr-3 closePopup" onClick={() => { this.closeModal(); }}>
                                                Close
                                            </button>
                                            :
                                            <button ref={this.textInput} className="greyBtn btn icoTextBtn mr-3 closePopup" onClick={() => { this.closeModal(); }}>
                                                Close
                                            </button>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ dialog, setup, detail }) => ({
    dialog: dialog.application_creation_error,
    applicationSetup: setup.application,
    applicationDetail: detail.application
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogApplicationsCreationError: (dialog) => dispatch(updateDialogApplicationsCreationErrorAction(dialog)),
    createApplication: (application, history, bypassUrl) => dispatch(createApplicationWithThunk(application, history, bypassUrl)),
    updateApplication: (application, bypassUrl, historyProps) => dispatch(updateApplicationWithThunk(application, bypassUrl, historyProps)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationsCreationErrorModal));
