import {
    getUserWorkspacePropertiesByIdApi,
    getUserWorkspaceLandingPropertiesByIdApi,
    getUserWorkspaceByIdApi,
    getWorkspaceListApi,
    getWorkspaceSearchListApi,
    getWorkspaceStatusByIdApi,
    createWorkspaceApi,
    updateWorkspaceApi,
    updateWorkspaceStatusApi,
    updateWorkspaceActiveApi,
    deleteWorkspaceApi
} from '../backend/domain/workspaces';

import {
    getWorkspaceSettingLogoByIdApi
} from '../backend/domain/workspacesSettings';

import environment from 'environment';
import { getWorkspacesAction } from '../actions/getWorkspacesAction';
import { getWorkspaceAction } from '../actions/getWorkspaceAction';
import { getWorkspaceAvailableApplicationsAction } from '../actions/getWorkspaceAvailableApplicationsAction';
import { clearWorkspacesAction } from '../actions/clearWorkspacesAction';
import { clearWorkspaceAction } from '../actions/clearWorkspaceAction';
import { updateWorkspaceAction } from '../actions/updateWorkspaceAction';
import { loadingWorkspacesAction } from '../actions/loadingWorkspacesAction';
import { loadingWorkspaceAction } from '../actions/loadingWorkspaceAction';
import { loadingCreateWorkspaceAction } from '../actions/loadingCreateWorkspaceAction';
import { getPortalLogoAction } from '../actions/getPortalLogoAction';
import { routeCodes } from '../configurations/routes';
import { handleWorkspaceCreationError, handleWorkspaceUpdateError, handleWorkspaceUpdateWarning } from '../utils/errorMessages';
import { overrideWorkspaceId, getWorkspaceNameById, updateWorkspaces, updateWorkspacesStatus, updateWorkspacesActive, updateWorkspacesRemove} from '../utils/workspaces';
import { loadingPageRedirectionAction } from '../actions/loadingPageRedirectionAction';
import { store } from '../index';

const config = require('../environments/' + environment.settings);

export const getWorkspacesSearchWithThunk = (keyword, pageIndex, pageSize, sortingBy, sortingType) =>
    (dispatch) => {
        dispatch(loadingWorkspacesAction(true));
        getWorkspaceSearchListApi(keyword, pageIndex, pageSize, sortingBy, sortingType)
           .then(response => {
                updateWorkspaces(response.workspaces);
            })
            .then((response) => {
                dispatch(loadingWorkspacesAction(false));
                return response;
            });
    };


export const getWorkspacesWithThunk = (pageIndex, pageSize, sortingBy, sortingType) =>
    (dispatch) => {
        dispatch(loadingWorkspacesAction(true));
        getWorkspaceListApi(pageIndex, pageSize, sortingBy, sortingType)
            .then(response => {
                updateWorkspaces(response.workspaces);
            })
            .then((response) => {
                dispatch(loadingWorkspacesAction(false));
                return response;
            });
    };

export const getWorkspacesSortingWithThunk = (keyword, pageIndex, pageSize, sortingBy, sortingType) =>
    (dispatch) => {
        dispatch(loadingWorkspacesAction(true));
        getWorkspaceSearchListApi(keyword, pageIndex, pageSize, sortingBy, sortingType)
            .then(async () => {
                dispatch(clearWorkspacesAction([]));
                let workspaces = [];
                for (let i = 1; i <= pageIndex; i++) {
                    let r = await getWorkspaceListApi(i, pageSize, sortingBy, sortingType)
                    workspaces = workspaces.concat(r.workspaces);
                }
                let response = { workspaces };
                return response;
            })
            .then(response => dispatch(getWorkspacesAction(response.workspaces)))
            .then((response) => {
                dispatch(loadingWorkspacesAction(false));
                return response;
            });

    };

export const getWorkspaceByIdWithThunk = (id, userId) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(id);
        dispatch(loadingWorkspaceAction(true));
        dispatch(clearWorkspaceAction([]));

        getUserWorkspaceByIdApi(wId, userId)
            .then((response) => {
                if (!response.error) {
                    dispatch(getWorkspaceAction(response));

                    if (response.available_applications !== undefined) {
                        let availableApplications = {};

                        availableApplications.id = response.available_applications.id;
                        availableApplications.applications = response.available_applications.applications;

                        dispatch(getWorkspaceAvailableApplicationsAction(availableApplications));
                    }
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingWorkspaceAction(false));
                return response;
            });
    };

export const getUserWorkspacePropertiesByIdWithThunk = (id, userId, isAdmin, historyProps) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(id);
        dispatch(loadingWorkspaceAction(true));
        dispatch(clearWorkspaceAction([]));

        getUserWorkspacePropertiesByIdApi(wId, userId, isAdmin, historyProps, "properties")
            .then((response) => {
                if (!response.error) {
                    dispatch(getWorkspaceAction(response));
                    if (response.available_applications !== undefined) {
                        let availableApplications = {};

                        availableApplications.id = response.available_applications.id;
                        availableApplications.applications = response.available_applications.applications;

                        dispatch(getWorkspaceAvailableApplicationsAction(availableApplications));
                    }
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingWorkspaceAction(false));
                return response;
            });
    };

export const getUserWorkspaceLandingPropertiesByIdApiWithThunk = (id, userId, isAdmin, snapshotWidth, snapshotHeight, historyProps) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(id);
        dispatch(loadingWorkspaceAction(true));
        dispatch(clearWorkspaceAction([]));

        getUserWorkspaceLandingPropertiesByIdApi(wId, userId, isAdmin, snapshotWidth, snapshotHeight, historyProps)
            .then((response) => {
                if (!response.error) {
                    dispatch(getWorkspaceAction(response));
                    if (response.available_applications !== undefined) {
                        let availableApplications = {};

                        availableApplications.id = response.available_applications.id;
                        availableApplications.applications = response.available_applications.applications;

                        dispatch(getWorkspaceAvailableApplicationsAction(availableApplications));
                    }
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingWorkspaceAction(false));
                return response;
            });
    };

export const getWorkspaceHeaderByIdWithThunk = (workspaceId) =>
    new Promise(async (resolve, reject) => {
        store.dispatch(loadingPageRedirectionAction(true));

        let wId = await overrideWorkspaceId(workspaceId);
        getWorkspaceStatusByIdApi(wId)
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                }
                else {
                    reject('Disabled');
                }
            })
            .then(() => {
                store.dispatch(loadingPageRedirectionAction(false));
            });
    });


export const createWorkspaceWithThunk = (workspace) =>
    (dispatch) => {
        dispatch(loadingCreateWorkspaceAction(true));
        createWorkspaceApi(workspace)
            .then((response) => {
                handleWorkspaceCreationError(dispatch, response.status, response.error_messages, routeCodes.WORKSPACES_LIST);
            })
            .then(() => {
                dispatch(loadingCreateWorkspaceAction(false));
            });
    };

export const updateWorkspaceWithThunk = (workspace, historyProps) =>
    (dispatch) => {
        let w = {};
        dispatch(loadingWorkspaceAction(true));

        if (workspace.temp_workspace_name === undefined || (workspace.temp_workspace_name !== undefined && workspace.temp_workspace_name === workspace.workspace_name)) {
            let originalWorkspaceName = workspace.workspace_name;
            workspace.workspace_name = (workspace.temp_workspace_name !== undefined) ? workspace.temp_workspace_name : originalWorkspaceName;
            updateWorkspaceApi(workspace)
                .then(async (response) => {
                    let domainUrl = "";
                    let workspaceName = "";
                    if (response.status === 200) {
                        dispatch(updateWorkspaceAction(workspace));
                        w = await getWorkspaceNameById(response.id);
                        if (window.location.host === config.domain || window.location.host === workspace.domain_url) {
                            historyProps.push(routeCodes.WORKSPACES_LIST + "/" + w.workspace.name + "/properties");
                        }
                        else {
                            domainUrl = workspace.domain_url;
                            workspaceName = w.workspace.name;
                        }
                    }
                    else {
                        workspace.workspace_name = originalWorkspaceName;
                    }
                    handleWorkspaceUpdateError(dispatch, response.status, response.error_messages, domainUrl, workspaceName);
                    return response;
                })
                .then(() => {
                    if (w.workspace !== undefined) {
                        getWorkspaceSettingLogoByIdApi(w.workspace.id)
                            .then(response => {
                                if (!response.error) {
                                    dispatch(getPortalLogoAction(response));
                                }
                                return response;
                            })
                    }
                })
                .then((response) => {
                    dispatch(loadingWorkspaceAction(false));
                    return response;
                });
        }
        else {
            dispatch(loadingWorkspaceAction(false));
            handleWorkspaceUpdateWarning(dispatch, null, "if you have a domain redirecting to this workspace, you should update the domain redirection to the new workspace name. Are you sure you want to proceed?", workspace);
        }
    };

export const updateWorkspaceStatusWithThunk = (workspace) =>
    (dispatch) => {
        dispatch(loadingWorkspaceAction(true));
        updateWorkspaceStatusApi(workspace.id, workspace.enabled)
            .then((response) => {
                dispatch(updateWorkspaceAction(workspace));
                return response;
            })
            .then((response) => {
                dispatch(loadingWorkspaceAction(false));
                return response;
            });
    };

export const updateWorkspaceActiveWithThunk = (workspace) =>
    (dispatch) => {
        dispatch(loadingWorkspaceAction(true));
        updateWorkspaceActiveApi(workspace.id, workspace.status)
            .then((response) => {
                dispatch(updateWorkspaceAction(workspace));
                return response;
            })
            .then((response) => {
                dispatch(loadingWorkspaceAction(false));
                return response;
            });
    };

export const updateStickyWorkspaceStatusWithThunk = (workspace) =>
    (dispatch) => {
        dispatch(loadingWorkspacesAction(true));
        updateWorkspaceStatusApi(workspace.id, workspace.enabled)
            .then(async () => {
                updateWorkspacesStatus(workspace.id, workspace.enabled);
            })
            .then(() => {
                dispatch(loadingWorkspacesAction(false));
            });

    };

export const updateStickyWorkspaceActiveWithThunk = (workspace) =>
    (dispatch) => {
        dispatch(loadingWorkspacesAction(true));
        updateWorkspaceActiveApi(workspace.id, workspace.status)
            .then(async () => {
                updateWorkspacesActive(workspace.id, workspace.status);
            })
            .then(() => {
                dispatch(loadingWorkspacesAction(false));
            });
    };


export const deleteStickyWorkspaceWithThunk = (workspaceId, routeBackTo, historyProps) =>
    (dispatch) => {
        if (routeBackTo !== "") {
            dispatch(loadingWorkspaceAction(true));
            deleteWorkspaceApi(workspaceId)
                .then(() => {
                    dispatch(loadingWorkspaceAction(false));
                    historyProps.push(routeBackTo);
                });
        }
        else {
            dispatch(loadingWorkspacesAction(true));
            deleteWorkspaceApi(workspaceId)
                .then(async() => {
                    updateWorkspacesRemove(workspaceId);
                })
                .then(() => {
                    dispatch(loadingWorkspacesAction(false));
                });
        }
    };