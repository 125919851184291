import { combineReducers } from 'redux';
import { workspaces } from './workspaces';
import { applications } from './applications';
import { applicationsSettings } from './applicationsSettings';
import { users } from './users';
import { globalUsers } from './globalUsers';
import { setup } from './setup';
import { detail } from './detail';
import { pagination } from './pagination';
import { sorting } from './sorting';
import { loading } from './loading';
import { sticky } from './sticky';
import { dialog } from './dialog';
import { navigation } from './navigation';
import { categories } from './categories';
import { workspacesCategories } from './workspacesCategories';
import { workspacesApplications } from './workspacesApplications';
import { workspacesApplicationsSettings } from './workspacesApplicationsSettings';
import { workspacesApplicationsUsersSettings } from './workspacesApplicationsUsersSettings';
import { workspacesMembers } from './workspacesMembers';
import { workspacesMembersShare } from './workspacesMembersShare';
import { workspacesShareList } from './workspacesShareList';
import { focusAreas } from './focusAreas';
import { workspacesFocusAreasShape } from './workspacesFocusAreasShape';
import { workspacesFocusAreasShapeHeader } from './workspacesFocusAreasShapeHeader';
import { workspaceConnections } from './workspacesFocusAreaConnections';
import { workspaceApplicationConnections } from './workspaceApplicationConnections';
import { availableApplications } from './availableApplications';
import { availableWorkspaces } from './availableWorkspaces';
import { role } from './role';
import { redirectUrl } from './redirectUrl';
import { portalLogo } from './portalLogo';
import { portalLogin } from './portalLogin';
import { routeBack } from './routeBack';
import { map } from './map';
import { search } from './search';
import { portalLanding } from './portalLanding';
import { portalSettings } from './portalSettings';
import { unavailableContext } from './unavailableContext';
import { userAccess } from './userAccess';
import { contactOwners } from './contactOwners';
import { contactAdministrators } from './contactAdministrators';
import { portalRouter } from './portalRouter';
import { defaultApplicationsSettings } from './defaultApplicationsSettings';
import { clientIp } from './clientIp';
import { workspaceApplicationLanding } from './workspaceApplicationLanding';
import { globalWorkspacesMembers } from './globalWorkspacesMembers';

export default combineReducers({
    defaultApplicationsSettings,
    workspaces,
    categories,
    workspacesCategories,
    workspacesApplications,
	workspacesApplicationsSettings,
	workspacesApplicationsUsersSettings,
    workspacesMembers,
    workspacesMembersShare,
    workspacesShareList,
    focusAreas,
    workspacesFocusAreasShape,
    workspacesFocusAreasShapeHeader,
    availableApplications,
    availableWorkspaces,
    workspaceConnections,
    workspaceApplicationConnections,
    applications,
    applicationsSettings,
    users,
    globalUsers,
    globalWorkspacesMembers,
    setup,
    detail,
    pagination,
    sorting,
    loading,
    sticky,
    dialog,    
    navigation,
    role,
    portalLogo,
    portalLogin,
    routeBack,
    map,
    search,
    portalLanding,
    portalSettings,
    redirectUrl,
    unavailableContext,
    userAccess,
    contactOwners,
    contactAdministrators,
    portalRouter,
    clientIp,
    workspaceApplicationLanding
});