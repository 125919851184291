import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SidebarContainer from '../../Sidebar/SidebarContainer';
import ApplicationsListHeader from './ApplicationsListHeader';
import ApplicationsListBody from './ApplicationsListBody';
import ApplicationsModal from '../Modal/ApplicationsModal';
import { clearApplicationsAction } from '../../../actions/clearApplicationsAction';
import { clearApplicationsPaginationAction } from '../../../actions/clearApplicationsPaginationAction';
import { getApplicationsWithThunk, getApplicationsSearchWithThunk } from '../../../api/applicationsThunk';
import { changeNavigationAction } from '../../../actions/changeNavigationAction';
import { defaultApplicationsSortingBy, defaultApplicationsSortingType } from '../../../configurations/app';


class ApplicationsListContainer extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.getApplicationList = this.getApplicationList.bind(this);
        this.updateMode = this.updateMode.bind(this);
    }

    static propTypes = {
        applications: PropTypes.array.isRequired,
        getApplications: PropTypes.func.isRequired,
        clearApplications: PropTypes.func.isRequired,
        clearApplicationsPagination: PropTypes.func.isRequired,
        navigation: PropTypes.object.isRequired,
        dispatchNavigation: PropTypes.func.isRequired
    };

    state = {
        page: 1,
        size: 0,
        mode: 'list'
    }

    changeNavigation(name) {
        let navigation = {
            name
        };
        this.props.dispatchNavigation(navigation);
    }

    getApplicationList(currentPage) {
        const { page, size, mode } = this.state;
        const { search } = this.props;

        if (page < currentPage) {
            if (mode === 'list') {
                this.props.getApplications(currentPage, size, defaultApplicationsSortingBy, defaultApplicationsSortingType);
            }
            else {
                this.props.searchList(search.search_text, currentPage, size, defaultApplicationsSortingBy, defaultApplicationsSortingType);
            }
            this.setState({ page: currentPage });
        }
    }

    updateMode(page, mode) {
        this.setState({ page, mode });
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.getApplications(1, 10000, defaultApplicationsSortingBy, defaultApplicationsSortingType);
        this.changeNavigation('Applications');
        this.setState({ page: 1, size: 10000, mode: 'list' });
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.clearApplications();
        this.props.clearApplicationsPagination();
        this.setState({ page: 1, size: 0, mode: 'list' });
    }

    render() {
        const { applications } = this.props;

        return (
            <div>
                <SidebarContainer />
                <main>
                    <div className="container-fluid h-100">
                        <div className="mcp">
                            <ApplicationsListHeader updateMode={this.updateMode} />
                            <ApplicationsListBody items={applications} />
                        </div>
                    </div>
                </main>
                <footer></footer>
                <ApplicationsModal type="list" />
            </div>
        );
    }
}

const mapStateToProps = ({ applications, navigation, search }) => ({
    applications,
    navigation,
    search: search.applications
});

const mapDispatchToProps = (dispatch) => ({
    getApplications: (pageIndex, pageSize, sortingBy, sortingType) => dispatch(getApplicationsWithThunk(pageIndex, pageSize, sortingBy, sortingType)),
    searchList: (keyword, pageIndex, pageSize, sortingBy, sortingType) => dispatch(getApplicationsSearchWithThunk(keyword, pageIndex, pageSize, sortingBy, sortingType)),
    clearApplications: () => dispatch(clearApplicationsAction([])),
    clearApplicationsPagination: () => dispatch(clearApplicationsPaginationAction([])),
    dispatchNavigation: (navigation) => dispatch(changeNavigationAction(navigation))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationsListContainer));