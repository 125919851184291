import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import WorkspacesApplicationsSettingsMultiSelectItem from './WorkspacesApplicationsUserSettingsMultiSelectItem';
import { updateWorkspacesApplicationsUsersSettingsAction } from '../../../../actions/updateWorkspacesApplicationsUsersSettingsAction';

class WorkspacesApplicationsSettingsMultiSelect extends Component {
    constructor(props) {
        super(props);
        this.handleItemLockedChange = this.handleItemLockedChange.bind(this);
        this.handleItemChange = this.handleItemChange.bind(this);
    }

    static propTypes = {
        id: PropTypes.number.isRequired,
        workspacesApplicationsSettings: PropTypes.array.isRequired,
        dispatchWorkspacesApplicationsSettings: PropTypes.func.isRequired,
        groupLocked: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired
    };

    handleItemLockedChange() {
        const { id, workspacesApplicationsSettings } = this.props;

        const items = workspacesApplicationsSettings.filter((item) => item.id === id);
        const changes = items.map((item) => {
            item.locked = !item.locked;
            return item;
        })

        this.props.dispatchWorkspacesApplicationsSettings(changes);
        this.props.groupLocked();
    }

    handleItemChange(e) {
        const { id, workspacesApplicationsSettings } = this.props;
        const items = workspacesApplicationsSettings.filter((item) => item.id === id);
        const changes = items.map((item) => {
            item.value = String(e.target.value);
            return item;
        })

        this.props.dispatchWorkspacesApplicationsSettings(changes);
    }

    render() {
        const { id, workspacesApplicationsSettings, role } = this.props;
        const item = workspacesApplicationsSettings.filter((setting) => setting.id === id)[0];
        const options = JSON.parse(item.component_property);

        return (
            <div className='groupForm settings-group-form'>
                {(role.user_role === 'Admin') &&
                    <label className="btn iconBtn customLockCheckbox">
                        <input type="checkbox" className="lockInput" checked={!item.locked} readOnly />
                    </label>
                }
                <div className="col">
                    <div className="inputForm">
                        <select defaultValue={item.value} onChange={this.handleItemChange}>
                            {options.map((option, index) => (
                                <WorkspacesApplicationsSettingsMultiSelectItem defaultValue={item.value} key={index} value={option.value} text={option.text} />
                            ))}
                        </select>
                        <label>{item.title}</label>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
	dispatchWorkspacesApplicationsSettings: (settings) => dispatch(updateWorkspacesApplicationsUsersSettingsAction(settings))
});

export default withRouter(connect(null, mapDispatchToProps)(WorkspacesApplicationsSettingsMultiSelect));