import { GET_WORKSPACES_MEMBERS, UPDATE_WORKSPACES_MEMBERS, CLEAR_WORKSPACES_MEMBERS } from '../actions/index';

const getWorkspacesMembers= (workspacesMembers = [], action) => {
    let itemsKeys = [];
    let membersKeys = action.members.reduce((keys, member) => {
        itemsKeys.push(member.id);
        keys = itemsKeys;
        return keys;
    }, []);

    let result = workspacesMembers.filter((member) => {
        return membersKeys.indexOf(member.id) === -1;
    });

    return result.concat(action.members);
}

const updateWorkspacesMembers = (workspacesMembers = [], action) => {
    const members = workspacesMembers.map((item) => {
        const changesWorkspacesMembers = action.workspacesMembers.filter((change) => change.id === item.id)
        if (changesWorkspacesMembers.count > 0) {
            return changesWorkspacesMembers[0];
        }

        return item
    })

    return members;
}

const clearWorkspacesMembers = (action) => {
    return action.members;
}

export const workspacesMembers = (workspacesMembers = [], action) => {
    switch (action.type) {
        case GET_WORKSPACES_MEMBERS: return getWorkspacesMembers(workspacesMembers, action);
        case UPDATE_WORKSPACES_MEMBERS: return updateWorkspacesMembers(workspacesMembers, action);
        case CLEAR_WORKSPACES_MEMBERS: return clearWorkspacesMembers(action);
        default: return workspacesMembers;
    }
};