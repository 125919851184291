import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Iframe from 'react-iframe';
import { changeNavigationAction } from '../../../../actions/changeNavigationAction';
import { clearWorkspaceAction } from '../../../../actions/clearWorkspaceAction';
import { updateDialogWorkspacesLandingApplicationAction } from '../../../../actions/updateDialogWorkspacesLandingApplicationAction';
import { clearWorkspaceApplicationLandingAction } from '../../../../actions/clearWorkspaceApplicationLandingAction';
import { getWorkspaceApplicationLandingSettingWithThunk } from '../../../../api/workspacesSettingsThunk';
import { getSessionStorage } from '../../../../utils/sessionStorage';
import { anonymousUserId } from '../../../../configurations/app';
import { generateAnonymousToken } from '../../../../utils/token';
import { updateGoogleAnalytics } from '../../../../utils/googleAnalytics';
import { getUrlWorkspaceId, getUrlWorkspaceApplicationId, getUrlWorkspaceApplicationFirstParameter, getUrlWorkspaceApplicationSecondParameter } from '../../../../utils/url';
import { routeCodes } from '../../../../configurations/routes';
import WorkspacesLandingApplicationModal from '../../Modal/WorkspacesLandingApplicationModal';
import { ReactComponent as AppsOutline } from './../../../../images/app-rectangle-outline.svg';
import { ReactComponent as AppsSolid } from './../../../../images/app-rectangle-solid.svg';

class WorkspacesApplicationsLandingContainer extends Component {
    static propTypes = {
        navigation: PropTypes.object.isRequired,
        workspace: PropTypes.object.isRequired,
        workspaceSetting: PropTypes.object.isRequired,
        workspaceApplication: PropTypes.object.isRequired,
        workspaceApplicationLanding: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        role: PropTypes.object.isRequired,
        dispatchNavigation: PropTypes.func.isRequired,
        applications: PropTypes.array.isRequired,
        dispatchDialogWorkspacesLandingApplication: PropTypes.func.isRequired,
    };

    state = {
        userId: "",
        portalToken: "",
        firstParam: "",
        secondParam: "",
        isHttps: false,
        checkGoogleAnalytics: false,
        url: "",
        displayApps: false,
    }

    wrapperRef = React.createRef();

    handleClickOutside = this.handleClickOutside.bind(this);

    getFirstParam = () => {
        let param = getUrlWorkspaceApplicationFirstParameter(this.props.location.pathname);
        return param;
    }

    getSecondParam = () => {
        let param = getUrlWorkspaceApplicationSecondParameter(this.props.location.pathname);
        return param;
    }

    getCurrentWorkspaceId = () => {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    getCurrentApplicationId = () => {
        let id = getUrlWorkspaceApplicationId(this.props.location.pathname);
        return id;
    }

    getApplicationLogoStyle() {
        const { workspace, workspaceSetting } = this.props;
        let width = 50;
        let height = Math.round(width / workspaceSetting.workspace_image_aspect_ratio);
        let top = (25 - (height / 2));
        let style = { width: width + 'px', paddingTop: top + 'px' };

        if (workspace.workspace_url !== '') {
            style.cursor = 'pointer';
        }
        else {
            style.cursor = 'default';
        }

        return style;
    }

    changeNavigation(name) {
        let navigation = {
            name
        };
        this.props.dispatchNavigation(navigation);
    }

    async componentDidMount() {
        const { role, getWorkspaceSettings } = this.props;
        const url = window.location.href;

        let userId = "";
        let portalToken = "";
        let firstParam = this.getFirstParam();
        let secondParam = this.getSecondParam();
        let isHttps = (url.includes('https')) ? true : false;

        if (role.user_id === "") {
            const sessionRole = getSessionStorage("role");

            if (sessionRole !== "") {
                const session = JSON.parse(sessionRole);
                userId = session.user_id;
                portalToken = session.token.accessToken;
            }
        }
        else {
            userId = role.user_id;
            portalToken = role.token.accessToken;
        }

        if (userId === "") {
            userId = anonymousUserId;
            portalToken = generateAnonymousToken();
        }

        this.setState({
            userId,
            portalToken,
            firstParam,
            secondParam,
            isHttps
        })

        this.changeNavigation('Applications');

        window.addEventListener('message', message => {
            let data = message.data;
            switch (data.type) {
                case "copy-to-clipboard":
                    navigator.clipboard.write(
                        // eslint-disable-next-line no-undef
                        [new ClipboardItem({ 'image/png': data.toCopy })]
                    )
                    break;
                case "copy-text-to-clipboard":
                    navigator.clipboard.writeText(data.toCopy)
                    break;
                default:
                    break;
            }
        });

        const workspaceId = this.getCurrentWorkspaceId();
        await getWorkspaceSettings(workspaceId, "WorkspaceName;ShowPortalLogo;GoogleAnalytic;ShowWorkspaceName;ShowWorkspaceLogo;WorkspaceLogo", userId, role.user_role);
    }

    componentDidUpdate(prevProps) {
        const { workspaceApplicationLanding, workspaceSetting, role, clientIp } = this.props;
        const { checkGoogleAnalytics } = this.state;
        const application = workspaceApplicationLanding.application;

        if ((application.workspace_id === undefined || application.workspace_id === "") && (application.application_id === undefined || application.application_id === ""))
            return;

        if (workspaceSetting.id !== "" &&
            role.user_id !== "" &&
            clientIp.ipAddress !== "" &&
            !checkGoogleAnalytics) {

            if (workspaceSetting.useGoogleAnalytics) {
                const trackingId = workspaceSetting.googleAnalyticsTrackingId;
                const workspaceName = workspaceSetting.workspace_name;
                const applicationName = application.application_name;
                const userName = role.user_name;
                const ipAddress = clientIp.ipAddress;

                updateGoogleAnalytics(trackingId, workspaceName, applicationName, userName, ipAddress, this.props.location);
            }

            this.setState({ checkGoogleAnalytics: true });
        }

        if ((prevProps.workspaceApplicationLanding.application !== workspaceApplicationLanding.application) && (workspaceApplicationLanding.application !== undefined && workspaceApplicationLanding.application.application_id !== "")) {
            let url = (application.url.includes("?")) ? application.url + "&portalToken=" + this.state.portalToken + "&appToken=" + application.application_token :
                application.url + "?portalToken=" + this.state.portalToken + "&appToken=" + application.application_token;
            url = (this.state.firstParam !== "") ? url + "&appParam1=" + this.state.firstParam : url;
            url = (this.state.secondParam !== "") ? url + "&appParam2=" + this.state.secondParam : url;
            url = url + "&isSecure=" + this.state.isHttps;
            this.setState({ url });
        }
    }

    componentWillUnmount() {
        this.setState({ url: "" });
        this.props.clearWorkspace();
        this.props.clearWorkspaceApplicationLanding();
    }

    getInitials(string) {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }

    async wsLandingPage() {
        let workspaceName = this.getCurrentWorkspaceId();
        this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + workspaceName);
    }

    logout() {
        this.props.history.push(routeCodes.USERS_LOGOUT);
    }

    loginPage() {
        let workspaceName = this.getCurrentWorkspaceId();
        this.props.history.push(routeCodes.USERS_LOGIN + "/" + workspaceName);
    }

    profilePage() {
        this.props.history.push(routeCodes.PROFILE);
    }

    openUrl(url) {
        if (url.trim() === "") {
            return;
        }

        if (!url.includes("http")) {
            url = "https://" + url;
        }

        const win = window.open(url, "_blank");
        if (win != null) {
            win.focus();
        }
    }

    handleDisplayApps() {
        const { displayApps } = this.state;

        this.setState({ displayApps: !displayApps },
            () => {
                if (!displayApps) {
                    document.addEventListener("mousedown", this.handleClickOutside);
                } else {
                    document.removeEventListener("mousedown", this.handleClickOutside);
                }
            });
    }

    handleSwithApps(e, item) {
        const { location } = this.props.history;
        const lastSlashIndex = location.pathname.lastIndexOf('/');
        const everythingAfterTheFinalSlash = location.pathname.substring(0, lastSlashIndex + 1);
        const link = everythingAfterTheFinalSlash + item.name.replace(/\s+/g, '+').toLowerCase()

        const url = window.location.href;
        const isPortalHttps = (url.includes('https')) ? true : false;
        const isApplicationHttps = (item.url.includes('https')) ? true : false;
        const isWarning = (isPortalHttps && !isApplicationHttps);

        if (!e.ctrlKey) {
            if (!isWarning) {
                this.setState({ displayApps: false })
                this.props.history.push(link);
            }
            else {
                this.showDialog(item.name, link, false);
            }
        }
        else {
            if (!isWarning) {
                const win = window.open(link, "_blank");
                if (win != null) {
                    win.focus();
                }
            }
            else {
                this.showDialog(item.name, link, true);
            }
        }

    }

    showDialog(appName, link, controlKey) {
        let workspaces_landing_application = {
            id: '',
            name: '',
            status: '',
            dialog_title: appName,
            dialog_type: '',
            link,
            control_key: controlKey,
            show_dialog: true
        };

        this.props.dispatchDialogWorkspacesLandingApplication(workspaces_landing_application);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ displayApps: false },
                () => {
                    document.removeEventListener("mousedown", this.handleClickOutside);
                });
        }
    }

    render() {
        const { role, workspace, workspaceApplicationLanding, workspaceSetting, workspace_theme, loadingTheme, applications, themeStyle } = this.props;
        const { url, displayApps } = this.state;
        const application = workspaceApplicationLanding.application;

        const { location } = this.props.history;
        const lastSlashIndex = location.pathname.lastIndexOf('/');
        const activeRouteApps = location.pathname.substring(lastSlashIndex + 1, location.pathname.length);

        if ((application !== undefined && application.application_id === "") ||
            application.available === undefined ||
            (application.application_token === undefined || application.application_token === ""))
            return (null);

        if (loadingTheme) {
            return (
                <div className="loader-container">
                    <Loader
                        type="Ball-Triangle"
                        color="#00BFFF"
                        height="100"
                        width="100"
                    />
                </div>
            )
        }

        return (
            <div>
                <WorkspacesLandingApplicationModal />
                {!loadingTheme &&
                    <main className="workspaceApp cancel-main-margin">
                        <div className="d-flex flex-column flex-md-row" style={{ minHeight: '50px', alignItems: 'center', verticalAlign: 'center' }}>
                            <div className="col-5">
                                {(workspaceSetting.showPortalLogo) &&
                                    <img className="float-left mr-2 p-1 insideCircle" style={{ cursor: 'pointer' }} onClick={() => this.openUrl("http://www.dhigroup.com")} src={process.env.PUBLIC_URL + "/images/DHI_Logo_small.png"} title="www.dhigroup.com" alt="" />
                                }
                                {(workspaceSetting.showWorkspaceLogo) &&
                                    <img style={this.getApplicationLogoStyle()} onClick={() => this.openUrl(workspace.workspace_url)} className="float-left mr-4 insideCircle " src={"data:image/png;base64," + workspaceSetting.workspace_url_image} title={workspace.workspace_url} alt="" />
                                }
                                {workspaceSetting.showWorkspaceName &&
                                    <h6 style={{ fontFamily: 'sans-serif', color: '#7E9CAE', fontSize: '14px' }} className="my-0  pt-3 font-weight-bold">{workspaceSetting.workspace_name}</h6>
                                }
                            </div>
                            <div className="col-4">
                                <h6 style={{ fontFamily: 'sans-serif', color: '#2d7198', fontSize: '18px' }} className="my-0 mx-1 ml-4 font-weight-bold">{application.application_name}</h6>
                            </div>
                            <div className="ml-auto mr-3">
                                <button className="btn iconLanding pt-1 pb-0" onClick={() => this.wsLandingPage()} title="Home">  <span className="icon-wrd-home"></span> </button>
                                <button style={{ background: 'none', border: '0px' }} onClick={(e) => this.handleDisplayApps()} title="Apps">
                                    {themeStyle === 'default' ?
                                        <AppsSolid width="25px" height="25px" fill="#8891be" />
                                        :
                                        <AppsOutline width="25px" height="25px" />
                                    }
                                </button>
                                {(role.is_logged_in) ?
                                    <>
                                        <button className="btn iconLanding pt-1 pb-0" onClick={() => this.logout()} title="Logout">  <span className="icon-wrd-power icon-ws-login-landing"></span> </button>
                                        {(workspace.user_initial !== "") ?
                                            <button className="btn iconLanding pt-1 pb-0" onClick={() => this.profilePage()} title="My Profile">  <span className={(workspace_theme.theme_selected === "Default") ? "userProfile-default" : "userProfile-white"}>{workspace.user_initial}</span> </button>
                                            :
                                            (workspace.user_picture !== "") ?
                                                <button className="btn iconLanding pt-1 pb-0">
                                                    <img className="icon-wrd-profile icon-ws-profile-landing" style={{ cursor: 'pointer', borderRadius: '50%' }} src={"data:image/png;base64," + workspace.user_picture} onClick={() => this.profilePage()} alt="My Profile" />
                                                </button>
                                                :
                                                <button className="btn iconLanding pt-1 pb-0" onClick={() => this.profilePage()} title="My Profile">  <span className="icon-wrd-profile icon-ws-profile-landing"></span> </button>
                                        }

                                    </>
                                    :
                                    <button className="btn iconLanding pt-1 pb-0" onClick={() => this.loginPage()} title="Login">  <span className="icon-wrd-power icon-ws-login-landing"></span> </button>
                                }
                            </div>
                        </div>
                        <div ref={this.wrapperRef}
                            className={
                                displayApps ? 'panel-apps-' + themeStyle + ' animated fadeIn' : "fadeOut d-none"}
                        >
                            <div style={{ display: 'flex', margin: '10px', fontSize: '18px' }}>
                                <div className="" style={{ color: themeStyle === 'default' ? '#ffffff' : '#0b4566' }} >Available Application(s)</div>
                                <button className="btn iconBtn minimizeBtn" style={{ position: 'absolute', right: '0px', top: '0px' }}
                                    onClick={(e) => this.handleDisplayApps()}
                                >  <span className="icon-wrd-close"></span></button>
                            </div>
                            <Scrollbars style={{ width: 350, height: 450 }}>
                                <ul className="">
                                    {applications.map(item => {
                                        const selectedApps = activeRouteApps.toLowerCase() === item.name.toLowerCase().replace(/ /g, "+")
                                        return (<li className={"list-apps-" + themeStyle} key={'app-' + item.id}>
                                            <button onClick={(e) => this.handleSwithApps(e, item)} className={`btn link-apps-${themeStyle}${selectedApps ? ` apps-selected-${themeStyle}` : ''}`} to={""}>
                                                <span className="appIcon">
                                                    <img className="logo-apps" src={((item.logo !== undefined) ? "data:image/jpeg;base64," : "") + item.logo} alt="" />
                                                </span>
                                                <div className="appName">{item.name}</div>
                                            </button>
                                        </li>)
                                    }
                                    )}
                                </ul>
                            </Scrollbars>
                        </div>
                        <div className="mapContainer">
                            {application.available &&
                                <Iframe url={url}
                                    id={application.application_id}
                                    width="100%"
                                    className="workspaces-application-container"
                                    display="initial"
                                    position="relative"
                                    allow="clipboard-read; clipboard-write"
                                />
                            }
                            {!application.available &&
                                <main className="workspaces-application-not-available unavailablePage">
                                    <div className="sectionError">
                                        <span className="icon-wrd-page-error"></span>
                                        <p>The application</p>
                                        <b className="d-block">{application.application_name}</b>
                                        <p>is not currently available</p>
                                    </div>
                                </main>
                            }
                        </div>
                    </main>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, loading, role, navigation, clientIp, workspaceApplicationLanding }) => ({
    workspaceSetting: detail.workspace_setting,
    workspace: detail.workspace,
    workspace_theme: detail.workspace_theme_setting,
    workspaceApplication: detail.workspace_application,
    loading: loading.workspace_application.loading,
    loadingTheme: loading.theme.loading,
    role,
    navigation,
    clientIp,
    workspaceApplicationLanding: workspaceApplicationLanding.workspace_application_landing_data,
    applications: workspaceApplicationLanding.applications,
    themeStyle: workspaceApplicationLanding.theme,
});

const mapDispatchToProps = (dispatch) => ({
    getWorkspaceSettings: (workspaceId, includeOnly, userId, role) => dispatch(getWorkspaceApplicationLandingSettingWithThunk(workspaceId, includeOnly, userId, role)),
    dispatchNavigation: (navigation) => dispatch(changeNavigationAction(navigation)),
    clearWorkspace: () => dispatch(clearWorkspaceAction([])),
    clearWorkspaceApplicationLanding: () => dispatch(clearWorkspaceApplicationLandingAction([])),
    dispatchDialogWorkspacesLandingApplication: (dialog) => dispatch(updateDialogWorkspacesLandingApplicationAction(dialog)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsLandingContainer));