import {
    sendContactAdministratorsApi
} from '../backend/domain/users';


import { clearContactAdministratorsAction } from '../actions/clearContactAdministratorsAction';
import { loadingContactAdministratorsAction } from '../actions/loadingContactAdministratorsAction';
import { handleContactAdministratorsError } from '../utils/errorMessages';

export const sendContactAdministratorsWithThunk = (uid, contactAdministrators) =>
    (dispatch) => {
        dispatch(loadingContactAdministratorsAction(true));
        sendContactAdministratorsApi(uid, contactAdministrators)
            .then((response) => {        
                handleContactAdministratorsError(dispatch, response.status, response.error_messages);
                if (response.status === 200) {
                    dispatch(clearContactAdministratorsAction([]));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingContactAdministratorsAction(false));
                return response;
            });
    };