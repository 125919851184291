import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { updatePortalSettingBannerAction } from '../../../../actions/updatePortalSettingBannerAction';
import { updatePortalSettingBannerWithThunk, addPortalSettingBannerWithThunk } from '../../../../api/portalSettingsThunk';
import { updateDialogPortalsAction } from '../../../../actions/updateDialogPortalsAction';
import ImageEditor from '../../../Editor/ImageEditorWithId';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../../../configurations/routes';
import { modalImageEditorStyle } from '../../../../utils/modal';
import Modal from 'react-modal';
import { updateDialogPortalSettingsErrorAction } from '../../../../actions/updateDialogPortalSettingsErrorAction';

class PortalSettingsBannerItem extends Component {
    constructor(props) {
        super(props);
        this.onSettingsTitleChange = this.onSettingsTitleChange.bind(this);
        this.onSettingsCaptionChange = this.onSettingsCaptionChange.bind(this);
        this.onSelectFile = this.onSelectFile.bind(this);
        this.onCloseImageEditor = this.onCloseImageEditor.bind(this);
        this.updatePortalSettingBannerChanges = this.updatePortalSettingBannerChanges.bind(this);
        this.displayAction = this.displayAction.bind(this);
        this.hideAction = this.hideAction.bind(this);
        this.updateStatus = this.updateStatus.bind(this);        
        this.removePortalSettingBanner = this.removePortalSettingBanner.bind(this);
        this.createPortalSettingBanner = this.createPortalSettingBanner.bind(this);
        this.cancelPortalSettingBanner = this.cancelPortalSettingBanner.bind(this);
        this.state = {
            src: null,
            imageEditorIsOpen: false,
            stickyActionIsOpen: false
        }
    }

    static propTypes = {
        item: PropTypes.object.isRequired,
        settingBanner: PropTypes.object.isRequired
    };

    onSettingsTitleChange(e) {
        const { item, settingBanner } = this.props;
        let banners = settingBanner.banners;
        let index = banners.findIndex(banner => banner.id === item.id);
        banners[index].banner_title = e.target.value;

        let bannerSetting = {
            ...settingBanner,
            banners
        };

        this.props.dispatchUpdateSettingBanner(bannerSetting);
    }

    onSettingsCaptionChange(e) {
        const { item, settingBanner } = this.props;
        let banners = settingBanner.banners; 
        let index = banners.findIndex(banner => banner.id === item.id);
        banners[index].banner_caption = e.target.value;     

        let bannerSetting = {
            ...settingBanner,
            banners
        };

        this.props.dispatchUpdateSettingBanner(bannerSetting);
    }

    onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({
                    src: reader.result, imageEditorIsOpen: true
                })
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onCloseImageEditor(image, aspectRatio, id) {
        this.setState({ imageEditorIsOpen: false });

        const { settingBanner } = this.props;
        let banners = settingBanner.banners; 
        let index = banners.findIndex(banner => banner.id === id);
        banners[index].image = image;
        banners[index].image_aspect_ratio = aspectRatio;

        let bannerSetting = {
            ...settingBanner,
            banners
        };

        this.props.dispatchUpdateSettingBanner(bannerSetting);
    }

    cancelPortalSettingBanner() {
        const { item, settingBanner } = this.props;
        let banners = settingBanner.banners; 
        let index = banners.findIndex(banner => banner.id === item.id);        
        banners.splice(index, 1);                

        let bannerSetting = {
            ...settingBanner,
            banners
        };

        this.props.dispatchUpdateSettingBanner(bannerSetting);
    }

    createPortalSettingBanner() {
        const { item, settingBanner } = this.props;
        let index = settingBanner.banners.findIndex(banner => banner.id === item.id);   
        if (!settingBanner.banners[index].banner_title) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Banner title is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        } else if (!settingBanner.banners[index].banner_caption) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Banner caption is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        }
        else if (!settingBanner.banners[index].image_aspect_ratio) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Banner image is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        }
        this.props.addSettingBanner(settingBanner, index);
    }

    updatePortalSettingBannerChanges() {
        const { item, settingBanner } = this.props;
        let index = settingBanner.banners.findIndex(banner => banner.id === item.id);
        if (!settingBanner.banners[index].banner_title) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Banner title is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        } else if (!settingBanner.banners[index].banner_caption) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Banner caption is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        }
        else if (!settingBanner.banners[index].image_aspect_ratio) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Banner image is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        }
        this.props.updateSettingBanner(settingBanner, index);
    }

    displayAction() {
        this.setState({ stickyActionIsOpen: !this.state.stickyActionIsOpen });
    }

    hideAction() {
        this.setState({ stickyActionIsOpen: false });
    }

    removePortalSettingBanner() {
        const { item } = this.props;

        let portals = {
            id: item.id,
            name: item.banner_title,
            status: item.status,
            dialog_title: 'Warning',
            dialog_type: 'trash',
            dialog_context: 'banner',
            show_dialog: true
        };

        this.props.dispatchDialogPortals(portals);

        this.setState({ stickyActionIsOpen: !this.state.stickyActionIsOpen });
    }

    updateStatus() {
        const { item } = this.props;

        let portals = {
            id: item.id,
            name: item.banner_title,
            status: item.status,
            dialog_title: 'Warning',
            dialog_type: (item.status === 'Enabled') ? 'disable' : 'enable',
            dialog_context: 'banner',
            show_dialog: (item.status === 'Enabled') ? true : false
        };

        this.props.dispatchDialogPortals(portals);

        if (item.status === 'Disabled') {   
            const { settingBanner } = this.props;
            let banners = settingBanner.banners;
            let index = banners.findIndex(banner => banner.id === item.id);  
            banners[index].status = 'Enabled';          

            let bannerSetting = {
                ...settingBanner,
                banners
            };

            this.props.updateSettingBanner(bannerSetting, index);
        }

        this.setState({ stickyActionIsOpen: !this.state.stickyActionIsOpen });
    }    
    
    componentWillMount() {
        Modal.setAppElement('body');
    }

    render() {
        const { item } = this.props;
        const { src, imageEditorIsOpen, stickyActionIsOpen } = this.state;
        return (
            <div className="sectionBanner p-4 mb-5">
                <div className="row">
                    <div className="col-md-12 col-lg-7 col-xl-8">
                        <div className="containerBanner p-4 text-center">
                            <img src={((item.image !== undefined && item.image !== "") ? "data:image/jpeg;base64," : process.env.PUBLIC_URL + "/images/no-banner.png") + item.image} className="bannerDisplay" alt="" />
                            <span className={(item.status === "Disabled") ? "float-left text-red mt-4 pt-2" : "float-left text-lime mt-4 pt-2"}>
                                {(item.status === "Disabled") ? "Disabled" : "Enabled"}</span>
                            <label className="btn btn-wrd_default icoTextBtn browsePhoto m-0 mt-3">
                                <input type="file" onChange={this.onSelectFile} />
                                <span className="icon-wrd-folder"></span>
                                Browse </label>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-5 col-xl-4">
                        <div className="formWrap mt-5">
                            <div className="inputForm">
                                <input className="form-control" type="text" name=""
                                    defaultValue={item.banner_title} onChange={this.onSettingsTitleChange} required />
                                <label>Title</label>
                            </div>
                            <div className="inputForm">
                                <input className="form-control" type="text" name=""
                                    defaultValue={item.banner_caption} onChange={this.onSettingsCaptionChange} required />
                                <label>Caption</label>
                            </div>
                            {(item.status !== '') ?
                                <div className="d-block text-center">
                                    <button className="btn textBtn greenBtn updateBtn" onClick={this.updatePortalSettingBannerChanges}>Update Changes</button>
                                </div>
                                :
                                <div className="row text-center">
                                    <div className="col-md-6 col-lg-6 order-md-2">
                                        <button className="btn textBtn greenBtn w-75" onClick={this.createPortalSettingBanner}>Create Banner</button>
                                    </div>
                                    <div className="col-md-6 col-lg-6 order-md-1">
                                        <button className="btn textBtn greyBtn w-75" onClick={this.cancelPortalSettingBanner}> Cancel</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {(item.status !== '') ?
                    <button onClick={this.displayAction} onBlur={this.hideAction} className="btn moreBtn"><span className="icon-wrd-more"></span></button>
                    : null
                }

                <ul className={(stickyActionIsOpen) ? "action show-action" : "action"}>
                    <li><Link to={routeCodes.PORTAL_SETTINGS} onMouseDown={this.updateStatus} className={(item.status === "Disabled") ? "greenLink" : "redLink"}><span
                        className="icon-wrd-lock"></span>{(item.status === "Disabled") ? "Enable" : "Disable"}</Link></li>
                    <li><Link to={routeCodes.PORTAL_SETTINGS} onMouseDown={this.removePortalSettingBanner} className="redLink"><span
                        className="icon-wrd-trash"></span>Remove</Link></li>
                </ul>

                {src &&
                    <Modal
                        isOpen={imageEditorIsOpen}
                        style={modalImageEditorStyle}
                        contentLabel="Image Editor">
                        <ImageEditor srcImage={src} onClose={this.onCloseImageEditor} idEditor={item.id} imgType={"banner"} />
                    </Modal>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ portalSettings }) => ({
    settingBanner: portalSettings.setting_banner
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogPortals: (dialog) => dispatch(updateDialogPortalsAction(dialog)),
    dispatchUpdateSettingBanner: (settingBanner) => dispatch(updatePortalSettingBannerAction(settingBanner)),
    updateSettingBanner: (settingBanner, index) => dispatch(updatePortalSettingBannerWithThunk(settingBanner, index)),
    addSettingBanner: (settingBanner, index) => dispatch(addPortalSettingBannerWithThunk(settingBanner, index)),
    dispatchDialogPortalSettingsError: (dialog) => dispatch(updateDialogPortalSettingsErrorAction(dialog)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalSettingsBannerItem));