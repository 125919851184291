import { getApplicationIdByNameApi, getApplicationNameByIdApi } from '../backend/domain/applications';
import { isValidGuid } from './index';
import { store } from '../index';
import { getApplicationsAction } from '../actions/getApplicationsAction';

export const getApplicationIdByName = (applicationName) => {
    let result = getApplicationIdByNameApi(applicationName);
    return result;
}

export const getApplicationNameById = (applicationId) => {
    let result = getApplicationNameByIdApi(applicationId);
    return result;
}

export const overrideApplcationId = async (id) => {
    let oId = null;

    if (id !== undefined) {
        let checkId = isValidGuid(id);
        if (!checkId) {
            let result = await getApplicationIdByName(id);
            oId = (result.application !== undefined) ? result.application.id : null;
        }
        else {
            oId = id;
        }
    }
    else {
        oId = id;
    }

    return oId;
}

export const updateApplications = (applications) => {
    const currentApplications = store.getState().applications;
    let apps = [];

    apps = apps.concat(currentApplications);
    apps = apps.concat(applications);

    store.dispatch(getApplicationsAction(apps));

    return apps;
} 

export const getApplicationSearchText = () => {
    let searchText = "";

    let searchApp = document.getElementById("application-list-search");
    if (searchApp !== null) {
        searchText = searchApp.value;
    }

    return searchText;
}