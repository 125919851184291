import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { routeCodes } from '../../../configurations/routes';
import dompurify from 'dompurify';
import { updateDialogWorkspacesLandingApplicationAction } from '../../../actions/updateDialogWorkspacesLandingApplicationAction';
import { getWorkspaceApplicationSnapshotApi } from '../../../backend/domain/workspacesApplications';
import { setTimeout } from 'timers';

const sanitizer = dompurify.sanitize;
var timeoutId;
var rtime;

class WorkspacesLandingApplicationsItem extends Component {
    constructor(props) {
        super(props);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.getDivWorkspaceAppStyle = this.getDivWorkspaceAppStyle.bind(this);
        this.getDivWorkspaceAppLogoStyle = this.getDivWorkspaceAppLogoStyle.bind(this);
        this.getDivWorkspaceAppResize = this.getDivWorkspaceAppResize.bind(this);
        this.getWorkspaceApplicationNameSize = this.getWorkspaceApplicationNameSize.bind(this);
        this.getZoomLevel = this.getZoomLevel.bind(this);

        this.divWorkspaceAppContainer = React.createRef();
        this.divWorkspaceApp = React.createRef();
        this.divWorkspaceAppLogo = React.createRef();
        this.divWorkspaceAppName = React.createRef();
        this.spanWorkspaceAppName = React.createRef();
        this.divWorkspaceAppBody = React.createRef();
        this.divWorkspaceAppButton = React.createRef();
        this.spanButtonName = React.createRef();
        this.imgSnapshot = React.createRef();
    }
    
    static propTypes = {
        workspace: PropTypes.object.isRequired,
        item: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired,
        isCategorized: PropTypes.bool.isRequired,
        isHaveCategory: PropTypes.bool.isRequired,
        containerHeight: PropTypes.number.isRequired,
        containerWidth: PropTypes.number.isRequired,
        setResizeInfo: PropTypes.func.isRequired,
        zoomLevel: PropTypes.number
    };

    state = {
        flip: false,
        isLoaded: false,
        sWidth: 0,
        sHeight: 0
    }

    onMouseEnter() {
        this.setState({ flip: true });
    }

    onMouseLeave() {
        this.setState({ flip: false });
    }

    routeToApp(e) {
        const { item, workspace } = this.props;

        const url = window.location.href;
        let isPortalHttps = (url.includes('https')) ? true : false;
        let isApplicationHttps = (item.application_url.includes('https')) ? true : false;

        let isWarning = (isPortalHttps && !isApplicationHttps);

        const link = routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/applications/" + item.application_name.toLowerCase().replaceAll(" ", "+");
        if (!e.ctrlKey) {
            if (!isWarning) {
                this.props.history.push(link);
            }
            else {
                this.showDialog(item.application_name, link, false);
            }
        }
        else {
            if (!isWarning) {
                const win = window.open(link, "_blank");
                if (win != null) {
                    win.focus();
                }
            }
            else {
                this.showDialog(item.application_name, link, true);
            }
        }
    }

    showDialog(appName, link, controlKey) {
        let workspaces_landing_application = {
            id: '',
            name: '',
            status: '',
            dialog_title: appName,
            dialog_type: '',
            link,
            control_key: controlKey,
            show_dialog: true
        };

        this.props.dispatchDialogWorkspacesLandingApplication(workspaces_landing_application);
    }

    getDivWorkspaceAppStyle() {
        const { item } = this.props;
        const { isLoaded, sWidth, sHeight, flip } = this.state;
        const factor = 0.97;

        if (flip) {
            return;
        }

        if (this.divWorkspaceAppContainer.current !== null && this.divWorkspaceApp.current !== null) {
            if (this.props.containerWidth !== 0 && this.props.containerHeight !== 0 && !this.props.isHaveCategory) {
                this.divWorkspaceApp.current.style.width = this.props.containerWidth + 'px';
                this.divWorkspaceApp.current.style.height = this.props.containerHeight * factor + 'px';
            }
            else {
                if (this.props.isCategorized) {
                    this.divWorkspaceApp.current.style.height = this.divWorkspaceAppContainer.current.offsetWidth * (287 / 383) * factor + 'px';
                }
            }

            if (this.divWorkspaceAppBody.current !== null && this.divWorkspaceAppButton.current !== null && this.divWorkspaceAppName.current !== null && this.spanWorkspaceAppName.current !== null && this.spanButtonName.current !== null) {
                let containerHeight = this.divWorkspaceApp.current.style.height;
                let bodyTop = this.divWorkspaceAppBody.current.offsetTop;
                let bodyWidth = this.divWorkspaceAppBody.current.offsetWidth;
                let bodyHeight = this.divWorkspaceAppBody.current.offsetHeight;
                let buttonWidth = this.divWorkspaceAppButton.current.offsetWidth;
                let buttonHeight = this.divWorkspaceAppButton.current.offsetHeight;

                let headerHeight = bodyTop;
                let headerWidth = bodyWidth;
                let appNameHeight = this.spanWorkspaceAppName.current.offsetHeight;

                let posTop = this.getWorkspaceApplicationNameTop();
                let zoomLevel = this.getZoomLevel();
                let isScaleChange = (window.devicePixelRatio !== 1);
                let isZoomChange = (zoomLevel !== 0);
                let appNameFontSize = (isScaleChange) ? this.getWorkspaceApplicationNameSize() : 1.6;

                if (isZoomChange) {
                    appNameFontSize = 18;
                }

                if (appNameHeight > headerHeight) {
                    this.spanWorkspaceAppName.current.style.fontSize = appNameFontSize + ((isZoomChange) ? 'px' : 'rem');
                    this.divWorkspaceAppName.current.style.top = 10 + (headerHeight / 2 - appNameHeight / 2) - posTop+ 'px';
                }
                else {
                    this.spanWorkspaceAppName.current.style.fontSize = appNameFontSize + ((isZoomChange) ? 'px' : 'rem');
                    this.divWorkspaceAppName.current.style.top = (headerHeight / 2 - appNameHeight / 2) - posTop + 'px';
                }
                this.divWorkspaceAppName.current.style.left = (headerWidth / 2 - this.divWorkspaceAppName.current.offsetWidth / 2) + 'px';

                let h = (containerHeight - (bodyTop + bodyHeight));
                let t = (bodyTop + bodyHeight);
                this.divWorkspaceAppButton.current.style.left = (bodyWidth / 2 - buttonWidth / 2) + 'px';
                this.divWorkspaceAppButton.current.style.top = t + (h / 2 - buttonHeight / 2) + 'px';

                if (isLoaded) {
                    clearTimeout(timeoutId);
                    rtime = new Date();
                    timeoutId = setTimeout(this.getSnapshot.bind(this, item.workspace_id, item.id, bodyWidth, bodyHeight, sWidth, sHeight, item.snapshot_type), 100);
                }
            }
        }
    }

    getDivWorkspaceAppResize() {
        const { item } = this.props;
        const { isLoaded, sWidth, sHeight, flip } = this.state;
        const factor = 0.97;

        if (flip) {
            return;
        }

        if (this.divWorkspaceAppContainer.current !== null && this.divWorkspaceApp.current !== null) {
            if (this.props.isHaveCategory) {
                this.props.setResizeInfo(this.divWorkspaceApp.current.offsetWidth, this.divWorkspaceAppContainer.current.offsetWidth * (287 / 383));
                this.divWorkspaceApp.current.style.height = this.divWorkspaceAppContainer.current.offsetWidth * (287 / 383) * factor + 'px';
            }
            else {
                if (this.props.isCategorized) {
                    this.divWorkspaceApp.current.style.width = this.props.containerWidth + 'px';
                    this.divWorkspaceApp.current.style.height = this.props.containerHeight * factor + 'px';
                }
            }

            if (this.divWorkspaceAppBody.current !== null && this.divWorkspaceAppButton.current !== null && this.divWorkspaceAppName.current !== null && this.spanWorkspaceAppName.current !== null && this.spanButtonName.current !== null) {
                let containerHeight = this.divWorkspaceApp.current.style.height;
                let bodyTop = this.divWorkspaceAppBody.current.offsetTop;
                let bodyWidth = this.divWorkspaceAppBody.current.offsetWidth;
                let bodyHeight = this.divWorkspaceAppBody.current.offsetHeight;
                let buttonWidth = this.divWorkspaceAppButton.current.offsetWidth;
                let buttonHeight = this.divWorkspaceAppButton.current.offsetHeight;

                let headerHeight = bodyTop;
                let headerWidth = bodyWidth;
                let appNameHeight = this.spanWorkspaceAppName.current.offsetHeight;
                let zoomLevel = this.getZoomLevel();
                let isScaleChange = (window.devicePixelRatio !== 1);
                let isZoomChange = (zoomLevel !== 0);
                let appNameFontSize = (isScaleChange) ? this.getWorkspaceApplicationNameSize() : 1.6;

                if (isZoomChange) {
                    appNameFontSize = 18;
                }

                if (appNameHeight > headerHeight) {
                    this.spanWorkspaceAppName.current.style.fontSize = appNameFontSize + ((isZoomChange) ? 'px' : 'rem');
                    this.divWorkspaceAppName.current.style.top = 10 + (headerHeight / 2 - appNameHeight / 2) + 'px';
                }
                else {
                    this.spanWorkspaceAppName.current.style.fontSize = appNameFontSize + ((isZoomChange) ? 'px' : 'rem');
                    this.divWorkspaceAppName.current.style.top = (headerHeight / 2 - appNameHeight / 2) + 'px';
                }
                this.divWorkspaceAppName.current.style.left = (headerWidth / 2 - this.divWorkspaceAppName.current.offsetWidth / 2) + 'px';

                let h = (containerHeight - (bodyTop + bodyHeight));
                let t = (bodyTop + bodyHeight);
                this.divWorkspaceAppButton.current.style.left = (bodyWidth / 2 - buttonWidth / 2) + 'px';
                this.divWorkspaceAppButton.current.style.top = t + (h / 2 - buttonHeight / 2) + 'px';

                if (isLoaded) {
                    clearTimeout(timeoutId);
                    rtime = new Date();
                    timeoutId = setTimeout(this.getSnapshot.bind(this, item.workspace_id, item.id, bodyWidth, bodyHeight, sWidth, sHeight, item.snapshot_type), 100);
                }
            }
        }
    }

    async getSnapshot(workspaceId, applicationId, bodyWidth, bodyHeight, sWidth, sHeight, snapshotType) {
        if (new Date() - rtime < 100) {
            timeoutId = setTimeout(this.getSnapshot.bind(this, workspaceId, applicationId, bodyWidth, bodyHeight, sWidth, sHeight, snapshotType), 100);
        }
        else {
            if (this.imgSnapshot.current !== null && Math.abs(bodyWidth - sWidth) > 200 && Math.abs(bodyHeight - sHeight) > 200) {
                if (sWidth !== 0 && sHeight !== 0) {
                    let snapshotImage = await getWorkspaceApplicationSnapshotApi(workspaceId, applicationId, bodyWidth, bodyHeight, snapshotType);
                    this.imgSnapshot.current.src = "data:image/png;base64," + snapshotImage;
                }
                this.setState({ sWidth: bodyWidth, sHeight: bodyHeight });
            }
            clearTimeout(timeoutId);
        }
    }

    getDivWorkspaceAppLogoStyle(aspectRatio) {
        if (this.divWorkspaceAppLogo.current !== null) {
            let height = this.divWorkspaceAppLogo.current.offsetWidth / aspectRatio;
            if (this.divWorkspaceAppLogo.current !== null) {
                this.divWorkspaceAppLogo.current.style.height = height;
                if (height < this.divWorkspaceAppLogo.current.offsetWidth) {
                    this.divWorkspaceAppLogo.current.style.top = "7%";
                }
            }
        }
    }

    getWorkspaceApplicationNameSize() {
        let size = 1.6;

        if (window.devicePixelRatio === 1) {
            size = 1.6;
        }
        else if (window.devicePixelRatio === 1.25) {
            size = 1.25;
        }
        else if (window.devicePixelRatio === 1.5) {
            size = 1.05;
        }
        else if (window.devicePixelRatio === 1.75) {
            size = 0.95;
        }
        return size;
    }

    getZoomLevel() {
        let zoomLevel = null;
        let screenCssPixelRatio = Math.ceil((window.outerWidth - 8) / window.innerWidth);

        if (screenCssPixelRatio >= .46 && screenCssPixelRatio <= .54) {
            zoomLevel = -4;
        }
        else if (screenCssPixelRatio < .64) {
            zoomLevel = -3;
        }
        else if (screenCssPixelRatio < .76) {
            zoomLevel = -2;
        }
        else if (screenCssPixelRatio < .92) {
            zoomLevel = -1;
        }
        else if (screenCssPixelRatio < 1.10) {
            zoomLevel = 0;
        }
        else if (screenCssPixelRatio < 1.32) {
            zoomLevel = 1;
        }
        else if (screenCssPixelRatio < 1.58) {
            zoomLevel = 2;
        }
        else if (screenCssPixelRatio < 1.90) {
            zoomLevel = 3;
        }
        else if (screenCssPixelRatio < 2.28) {
            zoomLevel = 4;
        }
        else if (screenCssPixelRatio < 2.70) {
            zoomLevel = 5;
        }

        return zoomLevel;
    }

    getWorkspaceApplicationNameTop() {
        let pos = 0;
        if (window.devicePixelRatio === 1) {
            pos = 0;
        }
        else if (window.devicePixelRatio === 1.25) {
            pos = 2;
        }
        else if (window.devicePixelRatio === 1.5) {
            pos = 4;
        }
        else if (window.devicePixelRatio === 1.75) {
            pos = 6;
        }
        return pos;
    }

    componentDidMount() {
        const factor = 0.97;
        window.addEventListener('resize', this.getDivWorkspaceAppResize);
        if (this.divWorkspaceAppContainer.current !== null && this.divWorkspaceApp.current !== null) {
            if (this.imgSnapshot.current !== null) {
                this.setState({ isLoaded: true });
            }
            if (this.props.isHaveCategory) {
                this.props.setResizeInfo(this.divWorkspaceApp.current.offsetWidth, this.divWorkspaceAppContainer.current.offsetWidth * (287 / 383));
                this.divWorkspaceApp.current.style.height = this.divWorkspaceAppContainer.current.offsetWidth * (287 / 383) * factor + 'px';
            }
            else {
                if (this.props.isCategorized) {
                    this.divWorkspaceApp.current.style.width = this.props.containerWidth * factor + 'px';
                    this.divWorkspaceApp.current.style.height = this.props.containerHeight * factor + 'px';
                }
                else {
                    this.divWorkspaceApp.current.style.height = this.divWorkspaceAppContainer.current.offsetWidth * (287 / 383) + 'px';
                }
            }
        }
    }

    render() {
        const { item, workspace, role, isHaveCategory, zoomLevel } = this.props;
        const { flip } = this.state;

        let template = { __html: sanitizer(item.application_description) };

        return (
            (!role.is_logged_in) ?
                (workspace.allow_public_access) ?
                    (item.allow_public_access) ?
                        <div ref={this.divWorkspaceAppContainer} className={(isHaveCategory) ? "col-md-6 col-xl-6 workspace-app-div-margin" : "col-md-6 col-xl-4 app-div-margin"} style={{ minWidth:"352px" }}>
                            <div ref={this.divWorkspaceApp} style={this.getDivWorkspaceAppStyle()} className="appOptionDiv px-4">
                                <div ref={this.divWorkspaceAppName} className="workspaceAppLandingName">
                                    <span ref={this.spanWorkspaceAppName}>{item.application_name}</span>
                                </div>
                                <div ref={this.divWorkspaceAppBody} className="workspaceAppLandingBody" onMouseEnter={() => { this.onMouseEnter(); }} onMouseLeave={() => { this.onMouseLeave(); }}>
                                    {(item.snapshot_url_image !== undefined && item.snapshot_type !== 0) &&
                                        <div className={"workspaceAppLandingSnapshot workspaceAppLandingFill " + ((flip) ? "d-none flipOutY" : "")}>
                                            <img ref={this.imgSnapshot} className="imgSize" src={"data:image/png;base64," + item.snapshot_url_image} alt="" />
                                        </div>
                                    }
                                    {(item.snapshot_url_image !== undefined && item.snapshot_type !== 0) &&
                                        <div className={((zoomLevel === 0) ? "workspaceAppLandingDesc font-style-wsappdesc animated " : "workspaceAppLandingDesc font-style-wsappdesc-zoom animated ") + ((flip) ? "flipInY" : "d-none flipOutY")} dangerouslySetInnerHTML={template}>
                                        </div>
                                    }
                                    {(item.snapshot_url_image === undefined || item.snapshot_type === 0) &&
                                        <div className={(zoomLevel === 0) ? "workspaceAppLandingDesc font-style-wsappdesc" : "workspaceAppLandingDesc font-style-wsappdesc-zoom"} dangerouslySetInnerHTML={template}>
                                        </div>
                                    }
                                </div>
                                <button ref={this.divWorkspaceAppButton} className={(zoomLevel === 0) ? "customHvrBtn font-style-wsbutton" : "customHvrBtn font-style-wsbutton-zoom"} onClick={(e) => this.routeToApp(e)} title={item.application_title}>
                                    <span ref={this.spanButtonName} className="icon-wrd-arrow-right"></span> Open
                                </button>
                            </div>
                        </div>
                        : null
                    : null
                :
                <div ref={this.divWorkspaceAppContainer} className={(isHaveCategory) ? "col-md-6 col-xl-6 workspace-app-div-margin" : "col-md-6 col-xl-4 app-div-margin"} style={{ minWidth: "352px" }}>
                    <div ref={this.divWorkspaceApp} style={this.getDivWorkspaceAppStyle()} className="appOptionDiv px-4">
                        <div ref={this.divWorkspaceAppName} className="workspaceAppLandingName">
                            <span ref={this.spanWorkspaceAppName}>{item.application_name}</span>
                        </div>
                        <div ref={this.divWorkspaceAppBody} className="workspaceAppLandingBody" onMouseEnter={() => { this.onMouseEnter(); }} onMouseLeave={() => { this.onMouseLeave(); }}>
                            {(item.snapshot_url_image !== undefined && item.snapshot_type !== 0) &&
                                <div className={"workspaceAppLandingSnapshot workspaceAppLandingFill " + ((flip) ? "d-none flipOutY" : "")}>
                                    <img ref={this.imgSnapshot} className="imgSize" src={"data:image/png;base64," + item.snapshot_url_image} alt="" />
                                </div>
                            }
                            {(item.snapshot_url_image !== undefined && item.snapshot_type !== 0) &&
                                <div className={((zoomLevel === 0) ? "workspaceAppLandingDesc font-style-wsappdesc animated " : "workspaceAppLandingDesc font-style-wsappdesc-zoom animated ") + ((flip) ? "flipInY" : "d-none flipOutY")} dangerouslySetInnerHTML={template}>
                                </div>
                            }
                            {(item.snapshot_url_image === undefined || item.snapshot_type === 0) &&
                                <div className={(zoomLevel === 0) ? "workspaceAppLandingDesc font-style-wsappdesc" : "workspaceAppLandingDesc font-style-wsappdesc-zoom"} dangerouslySetInnerHTML={template}>
                                </div>
                            }                            
                        </div>
                        <button ref={this.divWorkspaceAppButton} className={(zoomLevel === 0) ? "customHvrBtn font-style-wsbutton" : "customHvrBtn font-style-wsbutton-zoom"} onClick={(e) => this.routeToApp(e)} title={item.application_title}>
                            <span ref={this.spanButtonName} className="icon-wrd-arrow-right"></span>Open
                        </button>
                    </div>
                </div>
        );
    }
}

const mapStateToProps = ({ detail, role }) => ({
	workspace: detail.workspace,
	workspace_theme: detail.workspace_theme_setting,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogWorkspacesLandingApplication: (dialog) => dispatch(updateDialogWorkspacesLandingApplicationAction(dialog)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesLandingApplicationsItem));