import environment from 'environment';

const config = require('../../environments/' + environment.settings);
const axios = require('axios');

async function getCategoryById(categoryId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "application/categories/" + categoryId);

    return results;
}

async function getCategoryIdByName(categoryName) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "application/categories/id/" + categoryName);

    return results;
}

async function getCategoryList() {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "application/categories/list?pageIndex=1&pageSize=10000");

    return results;
}

async function createCategory(categories) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const results = await axios.post(commandBaseUrl + "application/categories", categories)
        .catch(function (error) { return error.response });
    return results;
}

async function updateCategory(categories) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const results = await axios.put(commandBaseUrl + "application/categories", categories)
        .catch(function (error) { return error.response });
    return results;
}

async function deleteCategory(categoryId) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const results = await axios.delete(commandBaseUrl + "application/categories/" + categoryId)
        .catch(function (error) { return error.response });
    return results;
}

export {
    getCategoryById,
    getCategoryIdByName,
    getCategoryList,
    updateCategory,
    createCategory,
    deleteCategory
}