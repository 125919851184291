import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import dompurify from 'dompurify';
import WorkspacesLandingApplicationsItem from './WorkspacesLandingApplicationsItem';

const sanitizer = dompurify.sanitize;

class WorkspacesLandingCategory extends Component {
    constructor(props) {
        super(props);
        this.setResizeInfo = this.setResizeInfo.bind(this);
        this.moveUp = this.moveUp.bind(this);
        this.spanCategoryName = React.createRef();

        this.state = {
            width: 0,
            height: 0
        }
    }

    static propTypes = {
        categories: PropTypes.array,
        userCategories: PropTypes.array,
        zoomLevel: PropTypes.number
    };

    setResizeInfo(width, height) {
        this.setState({ width, height });
    }

    moveUp() {
        window.scrollTo({ top: 0 });
    }

    render() {
        const { categories, userCategories, availableApplications, availableApplicationsUser, workspace, role, zoomLevel } = this.props;
        const { width, height } = this.state;

        if (categories === undefined || userCategories === undefined)
            return (null);

        let noCategoryApplications = [];
        let categoryApplications = [];
        let categs = [];

        let apps = [];
        if (role.is_logged_in) {
            if (role.user_role === 'Admin' || (workspace.owner && !workspace.suspendedUser)) {
                apps = (availableApplications.applications !== undefined) ? availableApplications.applications : [];
                categs = categories;
            }
            else {
                apps = (availableApplicationsUser !== undefined) ? availableApplicationsUser : [];
                categs = userCategories;
            }
        }
        else {
            apps = (availableApplications.applications !== undefined) ? availableApplications.applications.filter((item) => item.allow_public_access) : [];
            categs = userCategories;
        }

        if (apps.length !== 0) {
            noCategoryApplications = apps.filter((item) => item.category_title === "");
            categoryApplications = apps.filter((item) => item.category_title !== "");
        }

        return (
            <div id="body-categ">
                {(categs.length === 0) ?
                    <div id="no-categ-all" className="row workspaceAppLandingNoCategoryContainer" style={{ border: 'none'}}>
                        {apps.map((item) => (
                            <WorkspacesLandingApplicationsItem key={item.id} item={item} isCategorized={false} isHaveCategory={false} containerWidth={width} containerHeight={height} setResizeInfo={this.setResizeInfo} zoomLevel={zoomLevel} />
                        ))}
                    </div>
                    :
                    <div>
                        {(noCategoryApplications.length > 0) &&
                            <div id="no-categ" className="row workspaceAppLandingNoCategoryContainer">
                                {noCategoryApplications.map((item) => (
                                    <WorkspacesLandingApplicationsItem key={item.id} item={item} isCategorized={true} isHaveCategory={false} containerWidth={width} containerHeight={height} setResizeInfo={this.setResizeInfo} zoomLevel={zoomLevel} />
                                ))}
                            </div>
                        }
                        {(noCategoryApplications.length === 0) &&
                            <div>
                                {noCategoryApplications.map((item) => (
                                    <WorkspacesLandingApplicationsItem key={item.id} item={item} isCategorized={true} isHaveCategory={false} containerWidth={width} containerHeight={height} setResizeInfo={this.setResizeInfo} zoomLevel={zoomLevel} />
                                ))}
                            </div>
                        }
                        {categs.map((categ) => (
                            <div key={categ.category_id} id={"categ-" + categ.category_id} className="row workspaceAppLandingCategoryContainer">
                                <div id={"container-categ-" + categ.category_id} className="col-md-6 col-xl-4 app-div-margin">
                                    <div id={"desc-categ-" + categ.category_id}>
                                        <div ref={this.divCategoryNameContainer} className={(zoomLevel === 0) ? "workspaceAppLandingCategory font-style-wsapptitle" : "workspaceAppLandingCategory font-style-wsapptitle-zoom"} style={{ minWidth: "260px" }}>
                                            <span ref={this.spanCategoryName}>{categ.category_title}</span>
                                        </div>
                                        <div className="workspaceAppLandingCategoryDescriptionContainer">
                                            <span className={(zoomLevel === 0) ? "workspaceAppLandingCategoryDescription font-style-wscategdesc" : "workspaceAppLandingCategoryDescription font-style-wscategdesc-zoom"} dangerouslySetInnerHTML={{ __html: sanitizer(categ.category_description) }}></span>
                                        </div>
                                    </div>
                                    <div id={"btn-categ-" + categ.category_id} className="btn-center">
                                        <span className="icon-home-workspace-landing font-style-wsiconup icon-wrd-up" onClick={() => { this.moveUp() }}></span>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-8 app-div-margin workspaceAppLandingCategoryItem">
                                    <div className="row">
                                        {categoryApplications.filter((item) => item.category_id === categ.category_id).map((item) => (
                                            <WorkspacesLandingApplicationsItem key={item.id} item={item} isCategorized={true} isHaveCategory={true} containerWidth={width} containerHeight={height} setResizeInfo={this.setResizeInfo} zoomLevel={zoomLevel} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, role}) => ({
    workspace: detail.workspace,
    role
});


export default withRouter(connect(mapStateToProps, null)(WorkspacesLandingCategory));