import {
    getGlobalUsersApi,
    getGlobalUsersSearchApi,
    addGlobalUsersApi
} from '../backend/domain/users';
import { getUserWorkspaceByIdApi } from '../backend/domain/workspaces';
import { getWorkspaceAction } from '../actions/getWorkspaceAction';
import { getWorkspaceAvailableApplicationsAction } from '../actions/getWorkspaceAvailableApplicationsAction';
import { getUsersPaginationAction } from '../actions/getUsersPaginationAction';
import { clearGlobalUsersAction } from '../actions/clearGlobalUsersAction';
import { loadingGlobalUsersAction } from '../actions/loadingGlobalUsersAction';
import { overrideWorkspaceId } from '../utils/workspaces';
import { updateUsers } from '../utils/users';
import { getSessionStorage } from '../utils/sessionStorage';

export const getWorkspacesUsersWithThunk = (workspaceId, pageIndex, pageSize, sortingBy, sortingType) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);
        dispatch(loadingGlobalUsersAction(true));
        getGlobalUsersApi(wId, pageIndex, pageSize, sortingBy, sortingType)
            .then((response) => {
                if (response.pagination.total_count !== undefined)
                    dispatch(getUsersPaginationAction(response.pagination));
                updateUsers(response.users);
            })
            .then((response) => {
                dispatch(loadingGlobalUsersAction(false));
                return response;
            })
    };

export const getWorkspacesUsersByIdWithThunk = (workspaceId, userId, pageIndex, pageSize, sortingBy, sortingType) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);
        dispatch(loadingGlobalUsersAction(true));
        getUserWorkspaceByIdApi(wId, userId)
            .then((response) => {
                if (!response.error) {
                    dispatch(getWorkspaceAction(response));

                    let availableApplications = {};

                    availableApplications.id = response.available_applications.id;
                    availableApplications.applications = response.available_applications.applications;
                    dispatch(getWorkspaceAvailableApplicationsAction(availableApplications));
                }
                return response;
            })
            .then(() => {
                getGlobalUsersApi(wId, pageIndex, pageSize, sortingBy, sortingType)
                    .then((response) => {
                        if (response.pagination.total_count !== undefined)
                            dispatch(getUsersPaginationAction(response.pagination));
                        updateUsers(response.users);
                    })
                    .then((response) => {
                        dispatch(loadingGlobalUsersAction(false));
                        return response;
                    })
            });
    };

export const addWorkspacesUsersWithThunk = (workspaceId, users, url, history) =>
    (dispatch) => {
        dispatch(loadingGlobalUsersAction(true));
        let role = JSON.parse(getSessionStorage("role"));
        let token = role.token.accessToken;
        addGlobalUsersApi(token, workspaceId, users)
            .then(() => {
                dispatch(clearGlobalUsersAction([]));
                dispatch(loadingGlobalUsersAction(false));
                history.push(url);
            });
    };

export const getWorkspacesUsersSearchWithThunk = (workspaceId, pageIndex, pageSize, searchKeyword, SortingBy, SortingType) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);
        dispatch(loadingGlobalUsersAction(true));
        getGlobalUsersSearchApi(wId, pageIndex, pageSize, searchKeyword, SortingBy, SortingType)
            .then((response) => {
                if (response.pagination.total_count !== undefined)
                    dispatch(getUsersPaginationAction(response.pagination));
                updateUsers(response.users);
            })
            .then((response) => {
                dispatch(loadingGlobalUsersAction(false));
                return response;
            });
    };