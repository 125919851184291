import {    
    GET_PORTAL_LOGO,    
    UPDATE_PORTAL_LOGO,
    CLEAR_PORTAL_LOGO
} from '../actions/index';

const initialPortalLogoState = {       
        id: '',
        name: '',
        image: '',
        image_aspect_ratio: 0, 
        isLoaded: false
}

export const portalLogo = (state = initialPortalLogoState, action) => {
    const { portal_logo } = action
    switch (action.type) {       
        case GET_PORTAL_LOGO:
            return portal_logo                 
        case UPDATE_PORTAL_LOGO:
            return portal_logo
        case CLEAR_PORTAL_LOGO:
            return initialPortalLogoState
        default:
            return state;
    }
};