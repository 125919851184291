import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { routeCodes } from '../../../configurations/routes';
import PortalLandingHeader from '../Landing/PortalLandingHeader';
import PortalLandingMenu from '../Landing/PortalLandingMenu';
import PortalLandingBanner from '../Landing/PortalLandingBanner';
import PortalLandingFeature from '../Landing/PortalLandingFeature';
import PortalLandingNews from '../Landing/PortalLandingNews';
import PortalLandingFooter from '../Landing/PortalLandingFooter';
import PortalLandingBlog from '../Landing/PortalLandingBlog';
// import { getPortalLandingBannerWithThunk, getPortalLandingFeatureWithThunk, getPortalLandingNewsWithThunk } from '../../../api/portalLandingThunk';
//import ls from 'local-storage'

const StaticPage = () => (
    <React.Fragment>
        <PortalLandingBanner />
        <PortalLandingFeature />
        <PortalLandingNews />
    </React.Fragment>
)

class PortalLandingContainer extends Component {

    render() {
        return (
            <div className="w-100 h-100">
                <PortalLandingHeader />

                <PortalLandingMenu match={this.props.match} />

                <Route path={routeCodes.HOME} exact component={StaticPage} />
                <Route path={routeCodes.HOME_BLOG} exact component={PortalLandingBlog} />
              
                <PortalLandingFooter />

                <div className="copySection">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col text-center">
                                <p className="text">Copyright &copy; 2020 - Water Tools, all rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    // getPortalLandingBanner: () => dispatch(getPortalLandingBannerWithThunk()),
    // getPortalLandingFeature: () => dispatch(getPortalLandingFeatureWithThunk()),
});

export default withRouter(connect(null, mapDispatchToProps)(PortalLandingContainer));
