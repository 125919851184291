import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDialogWorkspacesApplicationUpdateErrorAction } from '../../../../actions/updateDialogWorkspacesApplicationUpdateErrorAction';
import { updateWorkspaceApplicationWithThunk } from '../../../../api/workspacesApplicationsThunk';

class WorkspacesApplicationsUpdateErrorModal extends Component {
    constructor(props) {
        super(props);
        this.updateWorkspaceApplication = this.updateWorkspaceApplication.bind(this);
    }

    static propTypes = {
        type: PropTypes.string.isRequired,
        dialog: PropTypes.object.isRequired,
        updateDialogWorkspacesApplicationError: PropTypes.func.isRequired
    };

    setDefaultDialogState() {
        let workspace_application_update = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            show_dialog: false
        };

        this.props.updateDialogWorkspacesApplicationError(workspace_application_update);
    }

    updateWorkspaceApplication() {
        this.setDefaultDialogState();
        const { workspaceApplication } = this.props;
        this.props.updateWorkspaceApplication(workspaceApplication, true, this.props.history);
    }

    closeModal() {       
        this.setDefaultDialogState();        
    }

    render() {
        const { dialog } = this.props;

        return (
            <div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div className={"popupPanel popupPanel-small deleteWarning" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                    <div className="popupHeader">
                        <div className="p-4">
                            <h2 className="popupTitle">{dialog.dialog_title}</h2>
                            <button className="btn iconBtn" onClick={() => { this.closeModal(); }}>
                                <span className="icon-wrd-close"></span>
                            </button>
                        </div>
                    </div>
                    <div className="popupBody">
                        <div className="p-4">
                            <div className="summernote">
                                {dialog.status === 400 &&
                                    <p>
                                        <b className="actionRemove">ERROR:</b> {dialog.dialog_type}
                                    </p>
                                }
                                {dialog.status === 404 &&
                                    <p>
                                        <b className="actionRemove">ERROR:</b> {dialog.dialog_type}
                                    </p>
                                }
                                {dialog.status === 200 &&
                                    <p>
                                        <b className="actionEnable">Success:</b> {dialog.dialog_type}
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="px-4 pt-4 pb-2">
                            {(dialog.status === 404) ?
                                <div className="row">
                                    <div className="col">
                                        <button className="btn greyBtn icoTextBtn closePopup width-100px" onClick={() => { this.closeModal(); }}>
                                            Cancel
                                                </button>
                                    </div>
                                    <div className="col text-right">
                                        <button className="greenBtn btn icoTextBtn mr-3 closePopup width-100px" onClick={() => { this.updateWorkspaceApplication(); }}>
                                            Yes
                                                </button>
                                    </div>
                                </div>
                                :
                                <div className="row">
                                    <div className="col text-right">
                                        {(dialog.status === 200) ?
                                            <button className="greenBtn btn icoTextBtn mr-3 closePopup" onClick={() => { this.closeModal(); }}>
                                                Close
                                        </button>
                                            :
                                            <button className="greyBtn btn icoTextBtn mr-3 closePopup" onClick={() => { this.closeModal(); }}>
                                                Close
                                        </button>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ dialog, detail }) => ({
    dialog: dialog.workspace_application_update_error,
    workspaceApplication: detail.workspace_application
});

const mapDispatchToProps = (dispatch) => ({
    updateDialogWorkspacesApplicationError: (dialog) => dispatch(updateDialogWorkspacesApplicationUpdateErrorAction(dialog)),
    updateWorkspaceApplication: (application, bypassUrl, historyProps) => dispatch(updateWorkspaceApplicationWithThunk(application, bypassUrl, historyProps))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsUpdateErrorModal));
