import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateWorkspacesFocusAreasShapeAction } from '../../../../actions/updateWorkspacesFocusAreasShapeAction';

class WorkspacesFocusAreasShapeItem extends Component {
    constructor(props) {
        super(props);
        this.onFeatureSelected = this.onFeatureSelected.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        workspacesFocusAreasShape: PropTypes.array.isRequired,
        workspacesFocusAreasShapeHeader: PropTypes.array.isRequired,
        role: PropTypes.object.isRequired,
        item: PropTypes.object.isRequired
    };

    onFeatureSelected(e) {
        const { item, workspacesFocusAreasShape } = this.props;
        let features = workspacesFocusAreasShape;
        let index = features.findIndex(feature => feature.id === item.id);
        features[index].selected = e.target.checked;

        this.props.dispatchWorkspacesFocusAreasShape(features);
    }

    render() {
        const { item, workspacesFocusAreasShapeHeader } = this.props;

        return (
            <tr>
                <td className="stickyActionLeft">
                    <div className="custom-controls-stacked text-left d-flex align-items-center">
                        <label className="custom-control ios-switch">
                            <input type="checkbox" name="focusArea" className="ios-switch-control-input" checked={item.selected}
                                onChange={this.onFeatureSelected} />
                            <span className="ios-switch-control-indicator"></span>
                        </label>
                    </div>
                </td>                    
                {(workspacesFocusAreasShapeHeader !== undefined && workspacesFocusAreasShapeHeader.length > 0) ?
                    workspacesFocusAreasShapeHeader.map((header, index) => (
                        <td key={index} className={(header.checked) ? "headChecked " : '' }>{item.properties[header.name]}</td>
                    ))
                    : null
                }              
            </tr>
        );
    }
}

const mapStateToProps = ({ detail, workspacesFocusAreasShape, workspacesFocusAreasShapeHeader, role }) => ({
    workspace: detail.workspace,
    workspacesFocusAreasShape,
    workspacesFocusAreasShapeHeader,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchWorkspacesFocusAreasShape: (workspacesFocusAreasShape) => dispatch(updateWorkspacesFocusAreasShapeAction(workspacesFocusAreasShape))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesFocusAreasShapeItem));