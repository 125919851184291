export const getFeatureData = async (shapeData) => {
    let data = {};
    let features = [];
    let featuresHeaders = [];

    const shapefile = require("shapefile");    
    let id = 1;
    await shapefile.open(shapeData)
        .then(source => source.read()
            .then(function log(result) {
                if (result.done) return;       
                let feature = {
                    id: id,
                    properties: result.value.properties,
                    geometry: result.value,
                    selected: true
                }
                if (id === 1) {                    
                    let j = 1;
                    for (var property in result.value.properties) {
                        let header = {
                            index: j,
                            name: property,
                            checked: false
                        }
                        j += 1
                        featuresHeaders.push(header);
                    }
                }
                id += 1;
                features.push(feature);
                return source.read().then(log);
            }))
        .catch(error => console.error(error.stack));

    data.features = features;
    data.featuresHeaders = featuresHeaders;

    return data;
}


export const getShpData = async (shapeData) => {
    let data = {};
    let features = [];  

    const shapefile = require("shapefile");
    let id = 1;
    await shapefile.openShp(shapeData)
        .then(source => source.read()
            .then(function log(result) {
                if (result.done) return;                
                let feature = {
                    id: id,
                    properties: result.value.properties,
                    geometry: result.value,
                    selected: true
                }
                id += 1;
                features.push(feature);
                return source.read().then(log);
            }))
        .catch(error => console.error(error.stack));

    data.features = features;

    return data;
}

export const getDbfData = async (shapeData) => {
    let data = {};   
    let dbfResults = [];
    let featuresHeaders = [];

    const shapefile = require("shapefile");  
    let id = 1;
    await shapefile.openDbf(shapeData)
        .then(source => source.read()
            .then(function log(result) {
                if (result.done) return;    
                let dbf = {
                    id: id,
                    prop: result.value
                }
                dbfResults.push(dbf);

                if (id === 1) {
                    let j = 1;                    
                    for (var property in result.value) {
                        let header = {
                            index: j,
                            name: property,
                            checked: false
                        }
                        j += 1
                        featuresHeaders.push(header);
                    }
                }
                id += 1;
                return source.read().then(log);
            }))
        .catch(error => console.error(error.stack));
    
    data.featuresHeaders = featuresHeaders;
    data.dbfResults = dbfResults;

    return data;
}