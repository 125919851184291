import sort from 'fast-sort';
import {
    getUserWorkspacePropertiesByIdApi
} from '../backend/domain/workspaces';
import {
    getWorkspacesMembersShareApi,
    getWorkspacesMembersSearchApi,
    getWorkspaceMemberListApi,
    getWorkspacesMembersShareSearchApi,
    createWorkspaceMemberApi,
    registerWorkspaceMemberApi,
    createWorkspacesShareListApi,
    updateWorkspaceMemberRoleApi,
    updateWorkspaceMemberStatusApi,
    removeWorkspaceMemberApi
} from '../backend/domain/workspacesMembers';

import { getWorkspaceAction } from '../actions/getWorkspaceAction';
import { clearWorkspaceAction } from '../actions/clearWorkspaceAction';
import { getWorkspaceAvailableApplicationsAction } from '../actions/getWorkspaceAvailableApplicationsAction';
import { getWorkspacesMembersAction } from '../actions/getWorkspacesMembersAction';
import { clearWorkspacesMembersAction } from '../actions/clearWorkspacesMembersAction';
import { getWorkspacesMembersShareAction } from '../actions/getWorkspacesMembersShareAction';
import { clearWorkspacesMembersShareAction } from '../actions/clearWorkspacesMembersShareAction';
import { loadingWorkspacesMembersAction } from '../actions/loadingWorkspacesMembersAction';
import { loadingCreateUserAction } from '../actions/loadingCreateUserAction';
import { handleUserCreationError, handleUserRegistrationError, handleMembersShareError } from '../utils/errorMessages';
import { overrideWorkspaceId } from '../utils/workspaces';
import { getSessionStorage } from '../utils/sessionStorage';
import { updateWorkspacesMembers, updateWorkspacesMembersRole, updateWorkspacesMembersStatus, updateWorkspacesMembersRemove } from '../utils/workspacesMembers';

export const getWorkspacesMembersWithThunk = (workspaceId, pageIndex, pageSize) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);
        dispatch(loadingWorkspacesMembersAction(true));
        getWorkspaceMemberListApi(wId, pageIndex, pageSize)
            .then((response) => {
                updateWorkspacesMembers(response.members);
            })
            .then((response) => {
                dispatch(loadingWorkspacesMembersAction(false));
                return response;
            })
    };

export const getWorkspacesMembersByIdWithThunk = (workspaceId, userId, isAdmin, pageIndex, pageSize, historyProps) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);
        dispatch(loadingWorkspacesMembersAction(true));
        dispatch(clearWorkspaceAction([]));
        dispatch(clearWorkspacesMembersAction([]));

        getUserWorkspacePropertiesByIdApi(wId, userId, isAdmin, historyProps, "members")
            .then((response) => {
                if (!response.error) {
                    dispatch(getWorkspaceAction(response));

                    let availableApplications = {};

                    availableApplications.id = response.available_applications.id;
                    availableApplications.applications = response.available_applications.applications;
                    dispatch(getWorkspaceAvailableApplicationsAction(availableApplications));
                }
                return response;
            })
            .then(() => {
                getWorkspaceMemberListApi(wId, pageIndex, pageSize)
                    .then(response => {
                        updateWorkspacesMembers(response.members);
                    })
                    .then((response) => {
                        dispatch(loadingWorkspacesMembersAction(false));
                        return response;
                    });
            });
    };

export const getWorkspacesMembersShareWithThunk = (workspaceId, userId, pageIndex, pageSize) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);
        dispatch(loadingWorkspacesMembersAction(true));
        getWorkspacesMembersShareApi(wId, userId, pageIndex, pageSize)
            .then((response) => {
                dispatch(getWorkspacesMembersShareAction(response.members));
            })
            .then((response) => {
                dispatch(loadingWorkspacesMembersAction(false));
                return response;
            })
    };

export const getWorkspacesMembersShareByIdWithThunk = (workspaceId, userId, isAdmin, pageIndex, pageSize, historyProps) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);
        dispatch(loadingWorkspacesMembersAction(true));
        dispatch(clearWorkspaceAction([]));
        dispatch(clearWorkspacesMembersShareAction([]));

        getUserWorkspacePropertiesByIdApi(wId, userId, isAdmin, historyProps, "share-members")
            .then((response) => {
                if (!response.error) {
                    dispatch(getWorkspaceAction(response));
                    let availableApplications = {};

                    availableApplications.id = response.available_applications.id;
                    availableApplications.applications = response.available_applications.applications;
                    dispatch(getWorkspaceAvailableApplicationsAction(availableApplications));
                }
                return response;
            })
            .then(() => {
                getWorkspacesMembersShareApi(wId, userId, pageIndex, pageSize)
                    .then(response => {
                        dispatch(getWorkspacesMembersShareAction(response.members));
                    })
                    .then((response) => {
                        dispatch(loadingWorkspacesMembersAction(false));
                        return response;
                    });
            });
    };

export const getWorkspacesMembersSortingWithThunk = (workspacesMembers, sortingBy, sortingType) =>
    (dispatch) => {
        dispatch(loadingWorkspacesMembersAction(true));
        let sortParam = {};
        if (sortingType === "Ascending") {
            sortParam = { asc: sortingBy }
        } else {
            sortParam = { desc: sortingBy }
        }
        sort(workspacesMembers).by([sortParam]);
        dispatch(getWorkspacesMembersAction(workspacesMembers));
        dispatch(loadingWorkspacesMembersAction(false));
    };

export const createWorkspacesMembersWithThunk = (id, member, url) =>
    (dispatch) => {
        dispatch(loadingCreateUserAction(true));
        let role = JSON.parse(getSessionStorage("role"));
        let token = role.token.accessToken;
        let data = {
            is_owner: member.is_owner,
            user_name: member.user_name,
            full_name: member.full_name,
            company: member.business_organization,
            registration_status: 'Pending',
            user_status: 'Enabled',
            use_personal: member.private_default_contact,
            picture: member.url_image,
            image_aspect_ratio: member.image_aspect_ratio,
            personal: {
                address_1: member.private_address1,
                address_2: member.private_address2,
                zip_code: member.private_zipcode,
                city: member.private_city,
                country: member.private_country,
                state: member.private_state,
                phone: member.private_phone,
                mobile_phone: member.private_mobile,
                email_address: member.private_email
            },
            public: {
                organization: member.business_organization,
                att: member.business_att,
                address_1: member.business_address1,
                address_2: member.business_address2,
                zip_code: member.business_zipcode,
                city: member.business_city,
                country:member.business_country,
                state: member.business_state,
                phone: member.business_phone,
                mobile_phone: member.business_mobile,
                email_address: member.business_email
            }
        };
        createWorkspaceMemberApi(token, id, data)
            .then((response) => {
                handleUserCreationError(dispatch, response.status, response.error_messages, url);
            })
            .then(() => {
                dispatch(loadingCreateUserAction(false));
            });
    };

export const registerWorkspacesMembersWithThunk = (id, member, url) =>
    (dispatch) => {
        dispatch(loadingCreateUserAction(true));
        let data = {
            is_owner: member.is_owner,
            user_name: member.user_name,
            full_name: member.full_name,
            company: member.business_organization,
            registration_status: 'Pending',
            user_status: 'Enabled',
            use_personal: member.private_default_contact,
            picture: member.url_image,
            image_aspect_ratio: member.image_aspect_ratio,
            personal: {
                address_1: member.private_address1,
                address_2: member.private_address2,
                zip_code: member.private_zipcode,
                city: member.private_city,
                country: member.private_country,
                state: member.private_state,
                phone: member.private_phone,
                mobile_phone: member.private_mobile,
                email_address: member.private_email
            },
            public: {
                organization: member.business_organization,
                att: member.business_att,
                address_1: member.business_address1,
                address_2: member.business_address2,
                zip_code: member.business_zipcode,
                city: member.business_city,
                country: member.business_country,
                state: member.business_state,
                phone: member.business_phone,
                mobile_phone: member.business_mobile,
                email_address: member.business_email
            }
        };
        let defaultEmail = (data.use_personal) ? data.personal.email_address : data.public.email_address;
        let message = "Your registration was successful. An email has been sent to " + defaultEmail + " to confirm your registration.";

        registerWorkspaceMemberApi(id, data)
            .then((response) => {
                handleUserRegistrationError(dispatch, response.status, message, response.error_messages, url);
            })
            .then(() => {
                dispatch(loadingCreateUserAction(false));
            });
    };

export const createWorkspacesMembersShareWithThunk = (members, workspaces) =>
    (dispatch) => {
        dispatch(loadingWorkspacesMembersAction(true));
        let role = JSON.parse(getSessionStorage("role"));
        let token = role.token.accessToken;
        let datas = { members, workspaces }
        createWorkspacesShareListApi(token, datas)
            .then((response) => {
                handleMembersShareError(dispatch, response.status, response.error_messages);
            })
            .then(() => {
                dispatch(loadingWorkspacesMembersAction(false));
            });
    };

export const updateStickyWorkspaceMemberRoleWithThunk = (workspaceId, userId, role) =>
    (dispatch) => {
        dispatch(loadingWorkspacesMembersAction(true));
        updateWorkspaceMemberRoleApi(workspaceId, userId, role)
            .then(async () => {
                updateWorkspacesMembersRole(workspaceId, userId, role)
            })
            .then(() => {
                dispatch(loadingWorkspacesMembersAction(false));
            });
    };

export const updateStickyWorkspaceMemberStatusWithThunk = (workspaceId, userId, status) =>
    (dispatch) => {
        dispatch(loadingWorkspacesMembersAction(true));
        updateWorkspaceMemberStatusApi(workspaceId, userId, status)
            .then(async () => {
                updateWorkspacesMembersStatus(workspaceId, userId, status)
            })
            .then(() => {
                dispatch(loadingWorkspacesMembersAction(false));
            });
    };

export const removeStickyWorkspaceMemberWithThunk = (workspaceId, userId) =>
    (dispatch) => {
        dispatch(loadingWorkspacesMembersAction(true));
        removeWorkspaceMemberApi(workspaceId, userId)
            .then(async () => {
                updateWorkspacesMembersRemove(workspaceId, userId);
            })
            .then(() => {
                dispatch(loadingWorkspacesMembersAction(false));
            });
    };

export const getWorkspacesMembersSearchWithThunk = (id, pageIndex, pageSize, searchKeyword) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(id);
        dispatch(loadingWorkspacesMembersAction(true));
        getWorkspacesMembersSearchApi(wId, pageIndex, pageSize, searchKeyword)
            .then((response) => {
                updateWorkspacesMembers(response.members);
            })
            .then((response) => {
                dispatch(loadingWorkspacesMembersAction(false));
                return response;
            })
    };

export const getWorkspacesMembersShareSearchWithThunk = (id, userId, pageIndex, pageSize, searchKeyword) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(id);
        dispatch(loadingWorkspacesMembersAction(true));
        getWorkspacesMembersShareSearchApi(wId, userId, pageIndex, pageSize, searchKeyword)
            .then((response) => {
                updateWorkspacesMembers(response.members);
            })
            .then((response) => {
                dispatch(loadingWorkspacesMembersAction(false));
                return response;
            })
    };