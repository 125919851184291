import { getContactOwnersApi, sendEmailToWorkspaceOwnersApi } from '../backend/domain/workspaces';
import { getContactOwnersAction } from '../actions/getContactOwnersAction';
import { getUserAccessContactOwnersAction } from '../actions/getUserAccessContactOwnersAction';
import { loadingContactOwnersAction } from './../actions/loadingContactOwnersAction';
import { updateToasterAction } from './../actions/updateToasterAction';
import { clearContactOwnersAction } from '../actions/clearContactOwnersAction';
import { overrideWorkspaceId } from '../utils/workspaces';

export const getContactOwnersThunk = (id, type) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(id);
        dispatch(loadingContactOwnersAction(true));
        getContactOwnersApi(wId)
            .then((response) => {
                if (type === 'PARENT') {
                    if (response.status >= 400) {
                        response.show_dialog = true;
                        response.redirect = true;
                        dispatch(updateToasterAction(response));
                    } else {
                        dispatch(getContactOwnersAction(response.data));
                    }
                } else if (type === 'MULTI') {
                    if (response.status >= 400) {

                    } else {
                        let data = {
                            workspaceId: wId,
                            owners:response.data
                        };
                        dispatch(getUserAccessContactOwnersAction(data))
                    }

                } else {
                    if (response.status >= 400) {

                    } else {
                        dispatch(getContactOwnersAction(response.data));
                    }
                }

                dispatch(loadingContactOwnersAction(false));
                return response;
            })
            .then((response) => {
                dispatch(loadingContactOwnersAction(false));
                return response;
            });
    };

export const postEmailToOwnersThunk = (messages) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(messages.workspaceName);
        dispatch(loadingContactOwnersAction(true));

        sendEmailToWorkspaceOwnersApi(wId, messages)
            .then((response) => {
                response.show_dialog = true;
                response.redirect = true;
                response.message = "Email has been sent succesfully.";
                dispatch(updateToasterAction(response));
                dispatch(loadingContactOwnersAction(false));

                return response;
            })
            .then((response) => {
                const contact = {}
                dispatch(clearContactOwnersAction(contact));
                dispatch(loadingContactOwnersAction(false));
                return response;
            });
    };
