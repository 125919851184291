import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import { routeCodes } from '../../../configurations/routes';
import { userLoginWithThunk } from '../../../api/loginThunk';
import { updateLoginAction } from '../../../actions/updateLoginAction';
import { encryptPassword, checkPassword } from '../../../utils/password';

class UsersLoginBody extends Component {
    constructor(props) {
        super(props);
        this.login = this.login.bind(this);
        this.enterLogin = this.enterLogin.bind(this);
        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.backButton = this.backButton.bind(this);
        this.state = {
            password_validation: '',
            autofill: -1
        };
    }

    static propTypes = {
        login: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        userLogin: PropTypes.func.isRequired,
        dispatchLogin: PropTypes.func.isRequired,
        portalLogin: PropTypes.object.isRequired
    };

    async login() {
        const { login } = this.props;
        await this.props.userLogin(login.user_name, encryptPassword(login.password), this.props.history);
    }

    async enterLogin(e) {
        if (e.key === 'Enter') {
            this.login();
        }
    }

    handleChangeUserName(e) {
        const login = {
            ...this.props.login,
            user_name: e.target.value
        }

        this.props.dispatchLogin(login);
        if (e.target.value === "") {
            this.setState({ autofill: 0 });
        }
        else {
            this.setState({ autofill: 1 });
        }
    }

    handleChangePassword(e) {
        const login = {
            ...this.props.login,
            password: e.target.value
        }
        let passwordCheck = checkPassword(login.password);
        if (passwordCheck === 'OK') {
            this.setState({ password_validation: '' })
        } else {
            this.setState({ password_validation: passwordCheck })
        }

        this.props.dispatchLogin(login);
        if (e.target.value === "") {
            this.setState({ autofill: 0 });
        }
        else {
            this.setState({ autofill: 1 });
        }
    }

    backButton() {
        this.props.history.push(routeCodes.HOME);
    }

    componentDidMount() {
        setTimeout(() => {
            var autofilled = document.querySelectorAll('input:-webkit-autofill');
            if (autofilled.length > 0) {
                this.setState({ autofill: 1 })
            }
            else {
                this.setState({ autofill: 0 })
            }
        }, 500);
    }

    render() {
        const { portalLogin, role, loading } = this.props;
        const { autofill } = this.state;

        return (
            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                {
                    (portalLogin.login_background_selected === 'NoBackground') ?
                        <div style={{ backgroundColor: portalLogin.login_background_color }} className="crop-welcome1820"> </div>
                        : <img src={"data:image/png;base64," + portalLogin.login_background_image}
                                className="crop-welcome1820" alt="" />                             
                }
                <div className="w-100 p-4 text-center">
                    {(loading || (role.is_logged_in && role.user_id !== '' && role.is_processing_login)) &&
                        <div className="loader-container">
                            <Loader
                                type="Ball-Triangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }

                    <div className="row" onKeyPress={this.enterLogin}>
                        <div className="formWrap col-10 offset-1 col-md-8 offset-md-2">
                            <h2>Login</h2>
                            <p className="mb-5">Welcome, please login to your account.</p>
                            <div className="inputForm">
                                <input className="form-control" type="text" name="" onChange={this.handleChangeUserName} required />
                                <label style={{ display: (autofill !== 0) ? 'none' : 'block' }}>Username</label>
                            </div>
                            <div className="inputForm">
                                <input className="form-control" type="password" name="" onChange={this.handleChangePassword} required />
                                <label style={{ display: (autofill !== 0) ? 'none' : 'block' }}>Password</label>
                                <span className="password-validation">{this.state.password_validation}</span>
                            </div>
                            <div className="row mt-5">
                                <div className="col-6 positionRemember">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="endDate" />
                                        <label className="custom-control-label pl-2" htmlFor="endDate">Remember me</label>
                                    </div>
                                </div>
                                <div className="col-6 positionForget">
                                    <Link to={routeCodes.USERS_RESET_PASSWORD + '/' + portalLogin.workspace_name} >Forgot Password?</Link>
                                </div>
                            </div>
                            <button className="btn textBtn updateBtn greenBtn mt-5 w-50 loginBtn" onClick={this.login}>Login</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ role, detail, loading, portalLogin }) => ({
    role,
    login: detail.login,
    loading: loading.login.loading,
    portalLogin: portalLogin.portal_login
});

const mapDispatchToProps = (dispatch) => ({
    userLogin: (userName, password, historyProps) => dispatch(userLoginWithThunk(userName, password, historyProps)),
    dispatchLogin: (login) => dispatch(updateLoginAction(login))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersLoginBody));