import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import SidebarContainer from '../Sidebar/SidebarContainer';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { checkBeforeAccess } from '../../utils/pageRedirectionRules';
import { updateAvailableWorkspacesOpenedStatus } from '../../utils/workspaces';
import UserAvailableWorkspacesContainer from '../Workspaces/UserAvailableWorkspaces/UserAvailableWorkspacesContainer';
import { defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize } from '../../configurations/app';
import { getUserAvailableWorkspacesWithThunk } from '../../api/userAvailableWorkspacesThunk';

class UnavailablePagesContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wsListOpened: false,
            firstOpen: true,
            isWorkspace: false,
            workspaceName: "",
            isApplication: false,
            applicationName: "",
            isDomain: false,
            domainName: ""
        };
        this.hideList = this.hideList.bind(this);
    }

    static propTypes = {
        unavailableContext: PropTypes.string.isRequired,
        role: PropTypes.object.isRequired
    };

    availableWorkspacesSwitch() {
        this.setState({ wsListOpened: !this.state.wsListOpened });
        this.setState({ firstOpen: false });
        if (this.state.wsListOpened === false) {
            const { role } = this.props;
            this.props.getUserAvailableWorkspaces(role.user_id, role.user_role, defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize);
        }
        else {
            updateAvailableWorkspacesOpenedStatus(false);
        }
    }

    hideList() {
        this.setState({ wsListOpened: false });
    }

    componentDidMount() {
        let paramsString = window.location.search;
        let params = new URLSearchParams(paramsString);
        let location = params.get('page');
        let isWorkspace = params.get('workspace');
        let isApplication = params.get('application');
        let isDomain = params.get('domain');

        if (isWorkspace) {
            this.setState({ isWorkspace, workspaceName: location })
        }
        else if (isApplication) {
            this.setState({ isApplication, applicationName: location })
        }
        else if (isDomain) {
            this.setState({ isDomain, domainName: location })
        }

        if (this.props.unavailableContext === '') {
            checkBeforeAccess(location, location, this.props.history, this.props.role);
        }
    }

    render() {
        const { unavailableContext, role } = this.props;
        const { isWorkspace, workspaceName, isApplication, applicationName, isDomain, domainName } = this.state;

        return (
            <div className="h-100">
                <SidebarContainer />
                <main className="h-100 pt-0 d-flex align-items-center justify-content-center unavailablePage">
                    <div className="sectionError">
                        <span className="icon-wrd-disabled-app"></span>
                        {(unavailableContext === 'disabledWorkspace') ?
                            <p>The requested url corresponds to a disabled workspace and is not currently available</p>
                            : (unavailableContext === 'suspendedWorkspace') ?
                                <p>The requested url corresponds to a suspended workspace and is not currently available</p>
                                : (unavailableContext === 'suspendedApplication') ?
                                    <p>The requested url corresponds to a suspended app and is not currently available</p>
                                    : (unavailableContext === 'disabledApplication') ?
										<p>The requested url corresponds to a disabled app and is not currently available</p>
										: (unavailableContext === 'notAuthorizeApplication') ?
											<p>The requested url corresponds to an app where you have not been granted access</p>
                                            : (isWorkspace) ?
                                                <p>The workspace <span style={{ fontWeight: 'bold' }}>{workspaceName}</span> does not exist</p>
                                                : (isApplication) ?
                                                    <p>The application <span style={{ fontWeight: 'bold' }}>{applicationName}</span> does not exist</p>
                                                    : (isDomain) ?
                                                        <p>There is no workspace defined for <span style={{ fontWeight: 'bold' }}>{domainName}</span> domain</p>
                                                        : <p>The requested url currently not available</p>
                        }
                    </div>
                    <div className={(this.state.firstOpen) ? "workspaceList d-none" : (this.state.wsListOpened) ? "workspaceList open animated fadeInRight" : "workspaceList open animated fadeOutRight"}>
                        <UserAvailableWorkspacesContainer hideList={this.hideList} />
                    </div>
                    {(role.is_logged_in) ?
                        <button className="btn iconBtn wsListBtn" onClick={() => this.availableWorkspacesSwitch()}>
                            <span className={(this.state.wsListOpened) ? "icon-wrd-close" : "icon-wrd-ws-list"}></span>
                        </button> : null
                    }
                </main>
            </div>
        )
    }
}

const mapStateToProps = ({ unavailableContext, role }) => ({
    role,
    unavailableContext
});

const mapDispatchToProps = (dispatch) => ({
    getUserAvailableWorkspaces: (userId, userRole, pageIndex, pageSize) => dispatch(getUserAvailableWorkspacesWithThunk(userId, userRole, pageIndex, pageSize))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnavailablePagesContainer));
