import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UserAvailableWorkspacesItem from './UserAvailableWorkspacesItem';
import UserAvailableWorkspacesPagination from './UserAvailableWorkspacesPagination';
import { getUserAvailableWorkspacesWithThunk, getUserAvailableWorkspacesSearchWithThunk } from '../../../api/userAvailableWorkspacesThunk';
import { getUserAvailableWorkspacesSearchAction } from '../../../actions/getUserAvailableWorkspacesSearchAction';
import { defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize } from '../../../configurations/app';

class UserAvailableWorkspacesBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wsListOpened: false,
            firstOpen: true,
            height: window.innerHeight - 80
        };
        this.handleSearchEnter = this.handleSearchEnter.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.updateHeight = this.updateHeight.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        availableWorkspaces: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired,
        pagination: PropTypes.object.isRequired,
        hideList: PropTypes.func.isRequired
    };

    availableWorkspacesSwitch() {
        this.setState({ wsListOpened: !this.state.wsListOpened });
        this.setState({ firstOpen: false });
    }

    onSearchChange(e) {
        let search = {
            ...this.props.available_workspace,
            search_text: e.target.value
        };
        this.props.dispatchUserAvailableWorkspacesSearch(search);
    }

    handleSearchEnter(e) {
        const { role } = this.props;
        if (e.key === 'Enter') {
            if (e.target.value !== '') {
                this.props.getUserAvailableWorkspacesSearch(role.user_id, role.user_role, defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize, e.target.value);
            }
            else {
                this.props.getUserAvailableWorkspaces(role.user_id, role.user_role, defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize);
            }
        }
    }

    handleSearchClick() {
        const { role, search } = this.props;
        if (search.search_text !== '') {
            this.props.getUserAvailableWorkspacesSearch(role.user_id, role.user_role, defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize, search.search_text);
        } else {
            this.props.getUserAvailableWorkspaces(role.user_id, role.user_role, defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize);
        }
    }

    updateHeight() {
        const height = window.innerHeight - 80;
        this.setState({ height });
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateHeight);
    }

    componentWillUnmount() {
        let search = {
            ...this.props.available_workspace,
            search_text: ''
        };
        this.props.dispatchUserAvailableWorkspacesSearch(search);
    }

    render() {
        const { availableWorkspaces } = this.props;
        const { search_text } = this.props.search
        const { height } = this.state;

        return (
            <div>
                <div className="relative w-100 h-100">
                    <p className="titleText mt-3">Available Workspace</p>
                    <div className="searchInput">
                        <span style={{ cursor: 'pointer' }} name="searchBtn" className="icon-wrd-search" onClick={this.handleSearchClick}></span>
                        <input type="text" name="searchText" value={search_text} placeholder="Search..." onChange={this.onSearchChange} onKeyPress={this.handleSearchEnter} />
                    </div>
                    <div style={{ height: height }}>
                        <ul className="wsGroup mt-2" id="portal-scrollbar">
                            {(availableWorkspaces.workspaces !== undefined && availableWorkspaces.workspaces.length > 0) ?
                                availableWorkspaces.workspaces.map((item) => (
                                    <UserAvailableWorkspacesItem key={item.id} item={item} hideList={this.props.hideList} />
                                ))
                                :
                                'No match'
                            }
                        </ul>
                   </div>
                </div>
                <UserAvailableWorkspacesPagination />
            </div>
        );
    }
}

const mapStateToProps = ({ detail, availableWorkspaces, role, pagination, search }) => ({
    workspace: detail.workspace,
    availableWorkspaces,
    role,
    pagination: pagination.available_workspaces,
    search: search.available_workspace
});

const mapDispatchToProps = (dispatch) => ({
    getUserAvailableWorkspaces: (userId, userRole, pageIndex, pageSize) => dispatch(getUserAvailableWorkspacesWithThunk(userId, userRole, pageIndex, pageSize)),
    getUserAvailableWorkspacesSearch: (userId, userRole, pageIndex, pageSize, keyword) => dispatch(getUserAvailableWorkspacesSearchWithThunk(userId, userRole, pageIndex, pageSize, keyword)),
    dispatchUserAvailableWorkspacesSearch: (data) => dispatch(getUserAvailableWorkspacesSearchAction(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserAvailableWorkspacesBody));