import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import ImageEditor from '../../Editor/ImageEditor';
import { modalImageEditorStyle } from '../../../utils/modal';
import { updateAccountAction } from '../../../actions/updateAccountAction';
import { updateAccountWithThunk } from '../../../api/accountThunk';
import { getCountries } from '../../../utils/country';
import { routeCodes } from '../../../configurations/routes';
import { defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize } from '../../../configurations/app';
import { getUserAvailableWorkspacesWithThunk } from '../../../api/userAvailableWorkspacesThunk';
import UserAvailableWorkspacesContainer from '../../Workspaces/UserAvailableWorkspaces/UserAvailableWorkspacesContainer';
import { updateAvailableWorkspacesOpenedStatus } from '../../../utils/workspaces';

class ProfileBody extends Component {
    constructor(props) {
        super(props);
        this.onCloseImageEditor = this.onCloseImageEditor.bind(this);
        this.onSelectFile = this.onSelectFile.bind(this);
        this.onToggleButton = this.onToggleButton.bind(this);
        this.onTogglePersonal = this.onTogglePersonal.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.state = {
            src: null,
            imageEditorIsOpen: false,
            showAdvanced: false,
            countries: getCountries(),
            wsListOpened: false,
            firstOpen: true
        };
        this.onDefaultPhoneChange = this.onDefaultPhoneChange.bind(this);
        this.onDefaultEmailChange = this.onDefaultEmailChange.bind(this);
        this.onDefaultOrganizationChange = this.onDefaultOrganizationChange.bind(this);
        this.onPersonalAddress1Change = this.onPersonalAddress1Change.bind(this);
        this.onPersonalAddress2Change = this.onPersonalAddress2Change.bind(this);
        this.onPersonalZipCodeChange = this.onPersonalZipCodeChange.bind(this);
        this.onPersonalCountryChange = this.onPersonalCountryChange.bind(this);
        this.onPersonalCityChange = this.onPersonalCityChange.bind(this);
        this.onPersonalStateChange = this.onPersonalStateChange.bind(this);
        this.onPersonalPhoneChange = this.onPersonalPhoneChange.bind(this);
        this.onPersonalMobilePhoneChange = this.onPersonalMobilePhoneChange.bind(this);
        this.onPersonalEmailAddressChange = this.onPersonalEmailAddressChange.bind(this);
        this.onPublicOrganizationChange = this.onPublicOrganizationChange.bind(this);
        this.onPublicAttChange = this.onPublicAttChange.bind(this);
        this.onPublicAddress1Change = this.onPublicAddress1Change.bind(this);
        this.onPublicAddress2Change = this.onPublicAddress2Change.bind(this);
        this.onPublicZipCodeChange = this.onPublicZipCodeChange.bind(this);
        this.onPublicCountryChange = this.onPublicCountryChange.bind(this);
        this.onPublicCityChange = this.onPublicCityChange.bind(this);
        this.onPublicStateChange = this.onPublicStateChange.bind(this);
        this.onPublicPhoneChange = this.onPublicPhoneChange.bind(this);
        this.onPublicMobilePhoneChange = this.onPublicMobilePhoneChange.bind(this);
        this.onPublicEmailAddressChange = this.onPublicEmailAddressChange.bind(this);
        this.updateUserDetail = this.updateUserDetail.bind(this);
        this.changePasswordPage = this.changePasswordPage.bind(this);
        this.contactAdministrators = this.contactAdministrators.bind(this);
        this.hideList = this.hideList.bind(this);
    }
    detailEnd = React.createRef()

    static propTypes = {
        account: PropTypes.object.isRequired,
        scrollTop: PropTypes.func.isRequired,
        dispatchAccount: PropTypes.func.isRequired,
        updateAccount: PropTypes.func.isRequired
    };

    async onToggleButton() {
        const status = !this.state.showAdvanced;
        await this.setState({ showAdvanced: status })

        this.scrollToTop();
    }

    onTogglePersonal() {
        const { account } = this.props;
        const status = (account.use_personal) ? false : true;

        const user_detail = {
            ...this.props.account,
            use_personal: status
        }

        this.props.dispatchAccount(user_detail);
    }

    scrollToTop() {
        this.props.scrollTop();
    }

    scrollToBottom() {
        this.detailEnd.current.scrollIntoView({ behavior: 'smooth' })
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({ src: reader.result, imageEditorIsOpen: true });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onCloseImageEditor(image, aspectRatio) {
        this.setState({ imageEditorIsOpen: false });

        const account = {
            ...this.props.account,
            picture: image,
            image_aspect_ratio: aspectRatio,
            is_picture_changed: true
        };

        this.props.dispatchAccount(account);
    }

    onDefaultPhoneChange(e) {
        let account = this.props.account;

        if (account.use_personal) {
            account = {
                ...this.props.account,
                personal: {
                    ...this.props.account.personal,
                    phone: e.target.value
                }
            };
        }
        else {
            account = {
                ...this.props.account,
                public: {
                    ...this.props.account.public,
                    phone: e.target.value
                }
            };
        }


        this.props.dispatchAccount(account);
    }

    onDefaultEmailChange(e) {
        let account = this.props.account;

        if (account.use_personal) {
            account = {
                ...this.props.account,
                personal: {
                    ...this.props.account.personal,
                    email_address: e.target.value
                }
            };
        }
        else {
            account = {
                ...this.props.account,
                public: {
                    ...this.props.account.public,
                    email_address: e.target.value
                }
            };
        }


        this.props.dispatchAccount(account);
    }

    onDefaultOrganizationChange(e) {
        let account = {
            ...this.props.account,
            public: {
                ...this.props.account.public,
                organization: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPersonalAddress1Change(e) {
        let account = {
            ...this.props.account,
            personal: {
                ...this.props.account.personal,
                address_1: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPersonalAddress2Change(e) {
        let account = {
            ...this.props.account,
            personal: {
                ...this.props.account.personal,
                address_2: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPersonalZipCodeChange(e) {
        let account = {
            ...this.props.account,
            personal: {
                ...this.props.account.personal,
                zip_code: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPersonalCountryChange(e) {
        let account = {
            ...this.props.account,
            personal: {
                ...this.props.account.personal,
                country: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPersonalCityChange(e) {
        let account = {
            ...this.props.account,
            personal: {
                ...this.props.account.personal,
                city: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPersonalStateChange(e) {
        let account = {
            ...this.props.account,
            personal: {
                ...this.props.account.personal,
                state: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPersonalPhoneChange(e) {
        let account = {
            ...this.props.account,
            personal: {
                ...this.props.account.personal,
                phone: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPersonalMobilePhoneChange(e) {
        let account = {
            ...this.props.account,
            personal: {
                ...this.props.account.personal,
                mobile_phone: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPersonalEmailAddressChange(e) {
        let account = {
            ...this.props.account,
            personal: {
                ...this.props.account.personal,
                email_address: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPublicOrganizationChange(e) {
        let account = {
            ...this.props.account,
            public: {
                ...this.props.account.public,
                organization: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPublicAttChange(e) {
        let account = {
            ...this.props.account,
            public: {
                ...this.props.account.public,
                att: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPublicAddress1Change(e) {
        let account = {
            ...this.props.account,
            public: {
                ...this.props.account.public,
                address_1: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPublicAddress2Change(e) {
        let account = {
            ...this.props.account,
            public: {
                ...this.props.account.public,
                address_2: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPublicZipCodeChange(e) {
        let account = {
            ...this.props.account,
            public: {
                ...this.props.account.public,
                zip_code: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPublicCountryChange(e) {
        let account = {
            ...this.props.account,
            public: {
                ...this.props.account.public,
                country: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPublicCityChange(e) {
        let account = {
            ...this.props.account,
            public: {
                ...this.props.account.public,
                city: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPublicStateChange(e) {
        let account = {
            ...this.props.account,
            public: {
                ...this.props.account.public,
                state: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPublicPhoneChange(e) {
        let account = {
            ...this.props.account,
            public: {
                ...this.props.account.public,
                phone: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPublicMobilePhoneChange(e) {
        let account = {
            ...this.props.account,
            public: {
                ...this.props.account.public,
                mobile_phone: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    onPublicEmailAddressChange(e) {
        let account = {
            ...this.props.account,
            public: {
                ...this.props.account.public,
                email_address: e.target.value
            }
        };

        this.props.dispatchAccount(account);
    }

    availableWorkspacesSwitch() {
        this.setState({ wsListOpened: !this.state.wsListOpened });
        this.setState({ firstOpen: false });
        if (this.state.wsListOpened === false) {
            const { role } = this.props;
            this.props.getUserAvailableWorkspaces(role.user_id, role.user_role, defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize);
        }
        else {
            updateAvailableWorkspacesOpenedStatus(false);
        }
    }

    updateUserDetail() {
        const { account, role } = this.props;

        this.props.updateAccount(account, role);
    }

    contactAdministrators() {
        this.props.history.push(routeCodes.CONTACT_ADMINISTRATORS);
    }

    changePasswordPage() {
        this.props.history.push(routeCodes.USERS_CHANGE_PASSWORD);
    }

    hideList() {
        this.setState({ wsListOpened: false });
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    render() {
        const { account, role } = this.props;
        const { src, imageEditorIsOpen, showAdvanced, countries } = this.state;

        return (
            <div className="mainBody">
                <div className="row mt-5">
                    <div className="col-md-7 order-md-1 col-lg-8 col-xl-7">
                        <div className="wsHomeSection">
                            <div className="desc">
                                <div className="formWrap">
                                    {(role.token.userStatus !== 'Active') ?
                                        <div className="notification text-center py-3 mb-5"><span
                                            className="icon-wrd-alert-triangle"></span> You have currently no access. Please
                                        contact the admin for further inquiries</div>
                                        : null
                                    }
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div>
                                                <label className="label-user-detail">Status</label>
                                                <label className={(account.user_status === "Disabled") ? "label-status status-disabled" : "label-status status-enabled"}>{account.user_status}</label>
                                            </div>
                                        </div>
                                    </div>
                                    {!showAdvanced &&
                                        <div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" required onChange={this.onDefaultPhoneChange} defaultValue={(account.use_personal) ? account.personal.phone : account.public.phone} />
                                                        <label>Phone</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" required onChange={this.onDefaultEmailChange} defaultValue={(account.use_personal) ? account.personal.email_address : account.public.email_address} />
                                                        <label>Email</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" required onChange={this.onDefaultOrganizationChange} defaultValue={account.public.organization} />
                                                        <label>Organization</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {showAdvanced &&
                                        <div>
                                            <p className="subLabel px-3 py-4 mt-5">Private Address</p>
                                            <div className="inputForm">
                                                <input className="form-control" type="text" name="" required onChange={this.onPersonalAddress1Change} defaultValue={account.personal.address_1} />
                                                <label>Address 1</label>
                                            </div>
                                            <div className="inputForm">
                                                <input className="form-control" type="text" name="" required onChange={this.onPersonalAddress2Change} defaultValue={account.personal.address_2} />
                                                <label>Address 2</label>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPersonalZipCodeChange} required defaultValue={account.personal.zip_code} />
                                                        <label>Zip Code</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="inputForm">
                                                        <select defaultValue={account.personal.country} onChange={this.onPersonalCountryChange} >
                                                            {countries.map((country) => (
                                                                <option key={country.code} value={country.code}>{country.name}</option>
                                                            ))}
                                                        </select>
                                                        <label>Country</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPersonalCityChange} required defaultValue={account.personal.city} />
                                                        <label>City</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPersonalStateChange} required defaultValue={account.personal.state} />
                                                        <label>State</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPersonalPhoneChange} required defaultValue={account.personal.phone} />
                                                        <label>Phone</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPersonalMobilePhoneChange} required defaultValue={account.personal.mobile_phone} />
                                                        <label>Mobile</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 pb-5">
                                                    <div className="inputForm mb-3">
                                                        <input className="form-control" type="text" name="" onChange={this.onPersonalEmailAddressChange} required defaultValue={account.personal.email_address} />
                                                        <label>Email</label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" onChange={() => { this.onTogglePersonal() }} className="custom-control-input" name="defaultPersonalEmail" id="defaultPersonalEmail" checked={(account.use_personal) ? true : false} />
                                                        <label className="custom-control-label pl-2 orangeText" htmlFor="defaultPersonalEmail">Use private email and phone as default contact</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="subLabel px-3 py-4 mt-5">Business Address</p>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicOrganizationChange} required defaultValue={account.public.organization} />
                                                        <label>Organization</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicAttChange} required defaultValue={account.public.att} />
                                                        <label>Att</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicAddress1Change} required defaultValue={account.public.address_1} />
                                                        <label>Address 1</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicAddress2Change} required defaultValue={account.public.address_2} />
                                                        <label>Address 2</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicZipCodeChange} required defaultValue={account.public.zip_code} />
                                                        <label>Zip Code</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="inputForm">
                                                        <select defaultValue={account.public.country} onChange={this.onPublicCountryChange}>
                                                            {countries.map((country) => (
                                                                <option key={country.code} value={country.code}>{country.name}</option>
                                                            ))}
                                                        </select>
                                                        <label>Country</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicCityChange} required defaultValue={account.public.city} />
                                                        <label>City</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicStateChange} required defaultValue={account.public.state} />
                                                        <label>State</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicPhoneChange} required defaultValue={account.public.phone} />
                                                        <label>Phone</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicMobilePhoneChange} required defaultValue={account.public.mobile_phone} />
                                                        <label>Mobile</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="inputForm mb-3">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicEmailAddressChange} required defaultValue={account.public.email_address} />
                                                        <label>Email</label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" onChange={() => { this.onTogglePersonal() }} className="custom-control-input" name="defaultPublicEmail" id="defaultPublicEmail" checked={(account.use_personal) ? false : true} />
                                                        <label className="custom-control-label pl-2" htmlFor="defaultPublicEmail">Use business email and phone as default contact</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row mt-5">
                                        <div className="col-12 text-center">
                                            <button onClick={() => { this.onToggleButton() }} className="advanceBtn">
                                                <b>Advanced</b>
                                                <span className={(showAdvanced) ? "icon-wrd-sorting-up" : "icon-wrd-sorting-down"}></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-4 col-xl-3">
                        <div className="wsPhoto text-center">
                            <div className="photoContainer userPhoto mtMin text-center mb-4">
                                <img src={process.env.PUBLIC_URL + "/images/transCircle.png"} className="insideCircle" alt="" />
                                <img src={((account.picture !== undefined) ? "data:image/jpeg;base64," : "") + account.picture} className="photo" alt="" />
                            </div>
                            {(role.token.userStatus === 'Active') ?
                                <div>
                                    <label className="btn btn-wrd_default icoTextBtn browsePhoto">
                                        <input type="file" onChange={this.onSelectFile} />
                                        <span className="icon-wrd-folder"></span>
                                        Browse
                                    </label>
                                    <span className="d-block mb-5 font-italic text-greyLight">Browse to change Picture</span>
                                    <button className="btn mt-4 textBtn greenBtn w-75" onClick={this.updateUserDetail}>Update Changes</button>
                                    <button className="btn textBtn greenBtn w-75" onClick={this.changePasswordPage}>Change Password</button>
                                </div>
                                : <div> &nbsp; </div>
                            }
                            <button className="btn textBtn greenBtn w-75" onClick={this.contactAdministrators}>Contact Administrator</button>
                        </div>
                    </div>
                </div>
                <div className={(this.state.firstOpen) ? "workspaceList d-none" : (this.state.wsListOpened) ? "workspaceList open animated fadeInRight" : "workspaceList open animated fadeOutRight"}>
                    <UserAvailableWorkspacesContainer hideList={this.hideList} />
                </div>
                <button className="btn iconBtn wsListBtn" onClick={() => this.availableWorkspacesSwitch()}>
                    <span className={(this.state.wsListOpened) ? "icon-wrd-close" : "icon-wrd-ws-list"}></span>
                </button>
                {src &&
                    <Modal
                        isOpen={imageEditorIsOpen}
                        style={modalImageEditorStyle}
                        contentLabel="Image Editor"
                    >
                        <ImageEditor srcImage={src} onClose={this.onCloseImageEditor} />
                    </Modal>
                }
                <div ref={this.detailEnd} />
            </div>
        );
    }
}

const mapStateToProps = ({ role, detail }) => ({
    role,
    account: detail.account
});

const mapDispatchToProps = (dispatch) => ({
    dispatchAccount: (account) => dispatch(updateAccountAction(account)),
    updateAccount: (account, role) => dispatch(updateAccountWithThunk(account, role)),
    getUserAvailableWorkspaces: (userId, userRole, pageIndex, pageSize) => dispatch(getUserAvailableWorkspacesWithThunk(userId, userRole, pageIndex, pageSize))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileBody));