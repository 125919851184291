import { getImageSize } from '../../utils/snapshot';
import { getDefaultImage } from '../../utils/image';
import { pingServer } from './portal';

const applicationsApi = require('../api/applications');

export async function getApplicationNameByIdApi(applicationId) {
    try {
        const application = await applicationsApi.getApplicationById(applicationId);
        return mapApplicationNameFrontend(application.data);
    }
    catch (err) {
        if (err.response !== undefined) {
            return mapToEmptyApplicationFrontend(err.response.status.toString());
        }
        return mapToEmptyApplicationFrontend("500");
    }
}

export async function getApplicationIdByNameApi(applicationName) {
    try {
        const application = await applicationsApi.getApplicationByName(applicationName);
        return mapApplicationIdFrontend(application.data, applicationName);
    }
    catch (err) {
        if (err.response !== undefined) {
            return mapToEmptyApplicationFrontend(err.response.status.toString());
        }
        return mapToEmptyApplicationFrontend("500");
    }
}

export async function getApplicationPropertiesByIdApi(applicationId) {
    try {
        const application = await applicationsApi.getApplicationById(applicationId);
        return mapApplicationPropertiesFrontend(application.data);
    }
    catch (err) {
        if (err.response !== undefined) {
            return mapToEmptyApplicationFrontend(err.response.status.toString());
        }
        return mapToEmptyApplicationFrontend("500");
    }
}

export async function updateApplicationPropertiesApi(application, bypassCheckUrl) {
    let data = {};
    let id = '';
    let error_messages = '';
    let bypassUrl = false;
    let appAvailable = false;

    if (application.application_url !== "") {
        if (!bypassCheckUrl) {
            let result = await pingApplication(application.id);
            appAvailable = result.data;
            bypassUrl = (application.application_name === '') ? true : appAvailable;
        }
        else {
            bypassUrl = true;
        }
    }
    else {
        bypassUrl = true;
    }

    if (bypassUrl) {
        const mappingApplication = await mapToApplicationBackendForUpdate(application, appAvailable);
        const results = await applicationsApi.updateApplication(mappingApplication);

        if (results.status === 200) {
            if (results.data !== undefined) {
                if (application.is_logo_changed) {
                    await updateApplicationLogoApi(application.id, application.url_image);
                }
                if (application.is_snapshot_changed) {
                    await updateApplicationSnapshotApi(application.id, application.snapshot_url_image);
                }
            }
            id = results.data
        }
        else if (results.status === 500) {
            error_messages = results.data;
        }
        else {
            let i = 0;
            for (const value of Object.entries(results.data)) {
                if (i === 0) {
                    error_messages = value[1];
                }
                i += 1;
            }
        }
        data = {
            id: id,
            status: results.status,
            error_messages: error_messages
        }
    }
    else {
        data = {
            id: id,
            status: 404,
            error_messages: 'The URL is currently not accessible. Are you sure you want to proceed?'
        }
    }
    return data;
}

export async function updateApplicationCategoryApi(applicationId, categoryId) {
    let results = await applicationsApi.updateApplicationCategory(applicationId, categoryId);
    return results;
}

export async function createApplicationApi(application, bypassCheckUrl) {
    let data = {};
    let id = '';
    let error_messages = '';
    let bypassUrl = false;
    let appAvailable = false;

    if (application.url !== "") {
        if (!bypassCheckUrl) {
            let result = await pingServer(application.url);
            appAvailable = result.data;
            bypassUrl = (application.application_name === '') ? true : appAvailable;
        }
        else {
            bypassUrl = true;
        }
    }
    else {
        bypassUrl = true;
    }

    if (bypassUrl) {
        const mappingApplication = mapToApplicationBackendForCreate(application);
        const results = await applicationsApi.createApplication(mappingApplication);

        if (results.status === 200) {
            if (results.data !== undefined && application.url_image !== undefined) {
                application.id = results.data;
                await updateApplicationLogoApi(application.id, application.url_image);
                await updateApplicationSnapshotApi(application.id, application.snapshot_url_image);
            }
            id = results.data
        }
        else if (results.status === 500) {
            error_messages = 'Internal server error, Please contact administrator.';
        }
        else {
            let i = 0;
            for (const value of Object.entries(results.data)) {
                if (i === 0) {
                    error_messages = value[1];
                }
                i += 1;
            }
        }
        data = {
            id: id,
            status: results.status,
            error_messages: error_messages
        }
    }
    else {
        data = {
            id: id,
            status: 404,
            error_messages: 'The URL is currently not accessible. Are you sure you want to proceed?'
        }
    }

    return data;
}

export async function updateApplicationStatusApi(applicationId, status) {
    const results = await applicationsApi.updateApplicationStatus(applicationId, status);

    const data = {
        status: results.data
    }

    return data;
}

export async function deleteApplicationApi(applicationId) {
    const results = await applicationsApi.deleteApplication(applicationId);

    const data = {
        status: results.data
    }

    return data;
}

export async function updateApplicationSnapshotApi(applicationId, content) {
    const result = await applicationsApi.updateSnapshot(applicationId, content);
    return result;
}

export async function createApplicationLogoApi(applicationId, content) {
    return await applicationsApi.createLogo(applicationId, content);
}

export async function updateApplicationLogoApi(applicationId, content) {
    return await applicationsApi.updateLogo(applicationId, content);
}

export async function getApplicationSearchListApi(keyword, pageIndex, pageSize, sortingBy, sortingType) {
    const results = await applicationsApi.getApplicationSearchList(keyword, pageIndex, pageSize, sortingBy, sortingType);
    return mapToApplicationsFrontend(results.data);
}

export async function getApplicationListApi(pageIndex, pageSize, sortingBy, sortingType) {
    const results = await applicationsApi.getApplicationList(pageIndex, pageSize, sortingBy, sortingType);
    return mapToApplicationsFrontend(results.data);
}

export async function pingApplication(applicationId) {
    return applicationsApi.getApplicationAvailability(applicationId);
}

function mapApplicationNameFrontend(applicationData) {
    let results = {};

    let a = applicationData;

    results.application = {
        id: a.id,
        name: a.name.toLowerCase().replaceAll(" ", "+")
    }

    if (applicationData === undefined) {
        results.error = "Application name is not found.";
    }

    return results;
}

function mapApplicationIdFrontend(applicationData, applicationName) {
    let results = {};

    let a = applicationData;

    results.application = {
        id: a,
        name: applicationName
    }

    if (applicationData === undefined) {
        results.error = "Application id is not found.";
    }

    return results;
}

async function mapApplicationPropertiesFrontend(applicationData) {
    let app = applicationData;

    app.logo = await applicationsApi.getApplicationLogo(app.id, 305, 237);
    let imageProps = (app.logo !== undefined) ? getImageSize(app.logo) : {
        'width': 1200,
        'height': 900
    };

    let application = {
        id: app.id,
        url_image: (app.logo === undefined) ? getDefaultImage() : app.logo,
        image_width: imageProps.width,
        image_height: imageProps.height,
        image_aspect_ratio: imageProps.width / imageProps.height,
        application_name: app.name,
        application_title: app.title,
        application_desc: app.description,
        application_url: app.url,
        status: (app.enabled) ? "Enabled" : "Disabled",
        snapshot_type: (app.snapshotType.toLowerCase() === "nosnapshot") ? 0 : 1,
        keywords: app.keywords,
        allow_public_access: app.publicAccess,
        is_new: true,
        is_deleted: false,
        is_logo_changed: false,
        is_snapshot_changed: false
    }

    application.snapshot_url_image = await applicationsApi.getApplicationSnapshot(app.id, 281, 129);
    return application;
}

function mapToApplicationBackendForCreate(data) {
    let application = {};

    application.name = data.application_name;
    application.title = data.application_title;
    application.description = data.application_desc;
    application.url = data.url;
    application.allowPublicAccess = data.allow_public_access;
    application.keywords = data.keywords;
    application.snapshotType = (data.snapshot_type === 0) ? "NoSnapshot" : "Default";

    return application;
}

async function mapToApplicationBackendForUpdate(data, validUrl) {
    let application = {};

    application.id = data.id;
    application.name = data.application_name;
    application.title = data.application_title;
    application.description = data.application_desc;
    application.url = data.application_url;
    application.allowPublicAccess = data.allow_public_access;
    application.keywords = data.keywords;
    application.snapshotType = (data.snapshot_type === 0) ? "NoSnapshot" : "Default";
    return application;
}

function mapToEmptyApplicationFrontend(errorStatus) {
    let results = {};

    results.error = errorStatus;

    return results;
}

function mapToApplicationsFrontend(data) {
    let results = {};
    let applications = [];
    for (let i in data.results) {
        let app = data.results[i];
        let application = {
            id: app.id,
            url_image: (app.logo === undefined) ? getDefaultImage() : app.logo,
            application_name: app.name,
            application_title: app.title,
            status: (app.enabled) ? "Enabled" : "Disabled",
            category_title: app.category,
            keywords: app.keywords,
            is_new: app.new
        }
        applications.push(application);
    }
    let pagination = {
        current_page: data.currentPage,
        page_size: data.pageSize,
        page_count: data.pageCount,
        start_row_number: data.startRowNumber,
        end_row_number: data.endRowNumber,
        total_count: data.rowCount
    }
    results.applications = applications;
    results.pagination = pagination;

    return results;
}