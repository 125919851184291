import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import ImageEditor from '../../../Editor/ImageEditor';
import { modalImageEditorStyle } from '../../../../utils/modal';
import { updatePortalSettingLoginAction } from '../../../../actions/updatePortalSettingLoginAction';
import { updatePortalSettingLoginWithThunk } from '../../../../api/portalSettingsThunk';
import { Link } from 'react-router-dom';
import RichTextEditor from '../../../Editor/RichTextEditor';
import Loader from 'react-loader-spinner';
import { updatePortalSettingTabAction } from '../../../../actions/updatePortalSettingTabAction';
import { routeCodes } from '../../../../configurations/routes';
import {  
    getPortalSettingLoginWithThunk
} from '../../../../api/portalSettingsThunk';
class PortalSettingsLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            src: null,
            imageEditorIsOpen: false,
            stateProp: null
        };
        this.handleWelcomeNoTextChange = this.handleWelcomeNoTextChange.bind(this);
        this.handleWelcomeCustomChange = this.handleWelcomeCustomChange.bind(this);
        this.handleWelcomeLogoNoBackgroundChange = this.handleWelcomeLogoNoBackgroundChange.bind(this);
        this.handleWelcomeLogoCustomChange = this.handleWelcomeLogoCustomChange.bind(this);
        this.onWelcomeTextChange = this.onWelcomeTextChange.bind(this);
        this.handleLoginLogoNoBackgroundChange = this.handleLoginLogoNoBackgroundChange.bind(this);
        this.handleLoginLogoCustomChange = this.handleLoginLogoCustomChange.bind(this);
        this.onSelectFile = this.onSelectFile.bind(this);
        this.onCloseImageEditor = this.onCloseImageEditor.bind(this);
    }

    static propTypes = {
        portalSettingLogin: PropTypes.object.isRequired,
        dispatchPortalSettingLogin: PropTypes.func.isRequired,
        updatePortalSettingLogin: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        activeTab: PropTypes.string.isRequired,
        dispatchActiveTab: PropTypes.func.isRequired
    };

    switchActiveTab(activeTab) {
        this.props.dispatchActiveTab(activeTab);
    }

    handleWelcomeNoTextChange() {
        let portalSettingLogin = {
            ...this.props.portalSettingLogin,
            welcome_text_selected: "NoText"
        };
        this.props.dispatchPortalSettingLogin(portalSettingLogin);
    }

    handleWelcomeCustomChange() {
        let portalSettingLogin = {
            ...this.props.portalSettingLogin,
            welcome_text_selected: "Custom"
        };
        this.props.dispatchPortalSettingLogin(portalSettingLogin);
    }

    handleWelcomeLogoNoBackgroundChange() {
        let portalSettingLogin = {
            ...this.props.portalSettingLogin,
            welcome_background_selected: "NoBackground"
        };
        this.props.dispatchPortalSettingLogin(portalSettingLogin);
    }

    handleWelcomeLogoCustomChange() {
        let portalSettingLogin = {
            ...this.props.portalSettingLogin,
            welcome_background_selected: "Custom"
        };
        this.props.dispatchPortalSettingLogin(portalSettingLogin);
    }

    onWelcomeTextChange(data) {
        let portalSettingLogin = {
            ...this.props.portalSettingLogin,
            welcome_text: data
        };
        this.props.dispatchPortalSettingLogin(portalSettingLogin);
    }

    handleLoginLogoNoBackgroundChange() {
        let portalSettingLogin = {
            ...this.props.portalSettingLogin,
            login_background_selected: "NoBackground"
        };
        this.props.dispatchPortalSettingLogin(portalSettingLogin);
    }

    handleLoginLogoCustomChange() {
        let portalSettingLogin = {
            ...this.props.portalSettingLogin,
            login_background_selected: "Custom"
        };
        this.props.dispatchPortalSettingLogin(portalSettingLogin);
    }

    onSelectFile = (e, stateProp) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({ src: reader.result, imageEditorIsOpen: true, stateProp: stateProp });
            }
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onSelectColor = (e, stateProp) => {
        let portalSettingLogin = {};
        if (stateProp === 'welcome_background') {
            portalSettingLogin = {
                ...this.props.portalSettingLogin,
                welcome_background_color: e.target.value
            };
        } else {
            portalSettingLogin = {
                ...this.props.portalSettingLogin,
                login_background_color: e.target.value
            };
        }
        this.props.dispatchPortalSettingLogin(portalSettingLogin);
    };

    onCloseImageEditor(image, aspectRatio) {
        this.setState({ imageEditorIsOpen: false });
        let portalSettingLogin = {};
        if (this.state.stateProp === 'welcome_background') {
            portalSettingLogin = {
                ...this.props.portalSettingLogin,
                welcome_background_custom_image: image,
                welcome_background_custom_image_aspect_ratio: aspectRatio
            };
        } else {
            portalSettingLogin = {
                ...this.props.portalSettingLogin,
                login_background_custom_image: image,
                login_background_custom_image_aspect_ratio: aspectRatio
            };
        }

        this.props.dispatchPortalSettingLogin(portalSettingLogin);
    }

    updatePortalSettingLogin() {
        const { portalSettingLogin } = this.props;
        this.props.updatePortalSettingLogin(portalSettingLogin);
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    componentDidMount() {
        this.props.getPortalSettingLogin();
    }

    render() {
        const { portalSettingLogin, loading } = this.props;
        const { src, imageEditorIsOpen } = this.state;

        return (
            <div className="mainBody">
                {loading &&
                    <div className="loader-container-portal-login-setting">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                <div className="row mt-5">
                    <div className="col-md-12 order-lg-1 col-lg-8 col-xl-9">
                        <div className="wsHomeSection">
                            <div className="groupForm mb-0">
                                <label className="sectionTitle">Login Page Welcome Text</label>
                                <hr />
                                <p>Choose how to configure welcome text on login page.</p>
                                <div className="row">
                                    <div className="col-sm-4 col-md-4 col-xl-4 mb-5">
                                        <label className="custom-checkbox custom-pick-image">
                                            <input type="radio" name="lpwt" className="custom-control-input"
                                                onChange={this.handleWelcomeNoTextChange} checked={(portalSettingLogin.welcome_text_selected === 'NoText')} />
                                            <div className="imgContainer">
                                                <img src={process.env.PUBLIC_URL + "/images/no-text.png"} className="themeImg" alt="" />
                                            </div>
                                            <p><span className="icon-wrd-checkmark"></span> No Text</p>
                                        </label>
                                    </div>
                                    <div className="col-sm-4 col-md-4 col-xl-4 mb-5">
                                        <label className="custom-checkbox custom-pick-image reveal">
                                            <input type="radio" name="lpwt" className="custom-control-input"
                                                onChange={this.handleWelcomeCustomChange} checked={(portalSettingLogin.welcome_text_selected === 'Custom')} />
                                            <div className="imgContainer">
                                                <img src={process.env.PUBLIC_URL + "/images/custom-text.png"} className="themeImg" alt="" />
                                            </div>
                                            <p><span className="icon-wrd-checkmark"></span> Custom Text
                                                </p>
                                        </label>
                                    </div>
                                </div>
                                {(portalSettingLogin.welcome_text_selected === 'Custom') ?
                                    <div className="row">
                                        <div className="summernotWrap mx-3 mb-5 w-100 ">
                                            <RichTextEditor value={portalSettingLogin.welcome_text} onChange={this.onWelcomeTextChange} />
                                        </div>
                                    </div>
                                    : null}
                            </div>
                            <div className="groupForm mb-0">
                                <label className="sectionTitle">Login Page Welcome Background</label>
                                <hr />
                                <p>Choose background to be used for welcome text on login page</p>
                                <div className="row">
                                    <div className="col-sm-4 col-md-4 col-xl-4 mb-5">
                                        <label className="custom-checkbox custom-pick-image reveal">
                                            <input type="radio" name="lpwb" className="custom-control-input"
                                                onChange={this.handleWelcomeLogoNoBackgroundChange} checked={(portalSettingLogin.welcome_background_selected === 'NoBackground')} />
                                            <div className="imgContainer">
                                                <img src={process.env.PUBLIC_URL + "/images/no-image-wide.png"} className="themeImg" alt="" />
                                            </div>
                                            <p><span className="icon-wrd-checkmark"></span> No background image</p>
                                            {(portalSettingLogin.welcome_background_selected === 'NoBackground') ?
                                                <div className="col-12 mb-5">
                                                    <label>Base background color : </label>&nbsp;
                                                <input type="color" value={portalSettingLogin.welcome_background_color} onChange={(e) => this.onSelectColor(e, 'welcome_background')} />
                                                </div>
                                                : null
                                            }
                                        </label>
                                    </div>
                                    <div className="col-sm-4 col-md-4 col-xl-4 mb-5">
                                        <label className="custom-checkbox custom-pick-image">
                                            <input type="radio" name="lpwb" className="custom-control-input"
                                                onChange={this.handleWelcomeLogoCustomChange} checked={(portalSettingLogin.welcome_background_selected === 'Custom')} />
                                            <div className="imgContainer">
                                                <img src={"data:image/png;base64," + portalSettingLogin.welcome_background_custom_image} className="themeImg" alt="" />
                                            </div>
                                            <p><span className="icon-wrd-checkmark"></span> Custom portal background </p>
                                            <p> or click browse if you want to upload new background</p>
                                            <button className="btn btn-wrd_default icoTextBtn browseLogo">
                                                <label className="custom-file-upload">
                                                    <input type="file" onClick={this.handleWelcomeLogoCustomChange} onChange={(e) => this.onSelectFile(e, 'welcome_background')} />
                                                    <span className="icon-wrd-folder"></span>
                                                    Browse
                                                        </label>
                                            </button>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="groupForm mb-0">
                                <label className="sectionTitle">Login Page Background</label>
                                <hr />
                                <p>Choose background to be used for login form on login page</p>
                                <div className="row">
                                    <div className="col-sm-4 col-md-4 col-xl-4 mb-5">
                                        <label className="custom-checkbox custom-pick-image reveal">
                                            <input type="radio" name="lpb" className="custom-control-input"
                                                onChange={this.handleLoginLogoNoBackgroundChange} checked={(portalSettingLogin.login_background_selected === 'NoBackground')} />
                                            <div className="imgContainer">
                                                <img src={process.env.PUBLIC_URL + "/images/no-image-wide.png"} className="themeImg" alt="" />
                                            </div>
                                            <p><span className="icon-wrd-checkmark"></span> No background image</p>
                                            {(portalSettingLogin.login_background_selected === 'NoBackground') ?
                                                <div className="col-12 mb-5">
                                                    <label>Base background color : </label>&nbsp;
                                            <input type="color" value={portalSettingLogin.login_background_color} onChange={(e) => this.onSelectColor(e, 'login_background')} />
                                                </div>
                                                : null
                                            }
                                        </label>
                                    </div>
                                    <div className="col-sm-4 col-md-4 col-xl-4 mb-5">
                                        <label className="custom-checkbox custom-pick-image">
                                            <input type="radio" name="lpb" className="custom-control-input"
                                                onChange={this.handleLoginLogoCustomChange} checked={(portalSettingLogin.login_background_selected === 'Custom')} />
                                            <div className="imgContainer">
                                                <img src={"data:image/png;base64," + portalSettingLogin.login_background_custom_image} className="themeImg" alt="" />
                                            </div>
                                            <p><span className="icon-wrd-checkmark"></span> Custom portal background </p>
                                            <p> or click browse if you want to upload new background</p>
                                            <button className="btn btn-wrd_default icoTextBtn browseLogo">
                                                <label className="custom-file-upload">
                                                    <input type="file" onClick={this.handleLoginLogoCustomChange} onChange={(e) => this.onSelectFile(e, 'login_background')} />
                                                    <span className="icon-wrd-folder"></span>
                                                    Browse
                                                        </label></button>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4 col-xl-3">
                        <div className="wsLogo pb-5 text-center">
                            <ul className="setting-menus mt-5 w-75">
                                <strong>Settings Options</strong>
                                <li>
                                    <Link to={routeCodes.PORTAL_OPTIONS} className="active">
                                        <span className="icon-wrd-log-in"></span> Login
                                        </Link>
                                </li>
                                <li>
                                    <Link to={routeCodes.PORTAL_OPTIONS_EMAIL}>
                                        <span className="icon-wrd-mail-closed"></span>Email
                                    </Link>
                                </li>
                                <li>
                                    <Link to={routeCodes.PORTAL_OPTIONS_THEME}>
                                        <span className="icon-wrd-themes"></span>Themes
                                    </Link>
                                </li>
                            </ul>
                            <button className="btn textBtn greenBtn w-75 mt-5" onClick={() => this.updatePortalSettingLogin()}>Update Changes</button>
                        </div>
                    </div>
                </div>
                {src &&
                    <Modal
                        isOpen={imageEditorIsOpen}
                        style={modalImageEditorStyle}
                        contentLabel="Image Editor">
                        <ImageEditor srcImage={src} onClose={this.onCloseImageEditor} imgType={'potrait'} />
                    </Modal>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ portalSettings, loading }) => ({
    portalSettingLogin: portalSettings.setting_login,
    loading: loading.portal_setting_login.loading,
    activeTab: portalSettings.active_tab
});

const mapDispatchToProps = (dispatch) => ({
    
    getPortalSettingLogin: () => dispatch(getPortalSettingLoginWithThunk()),
    dispatchPortalSettingLogin: (loginSetting) => dispatch(updatePortalSettingLoginAction(loginSetting)),
    updatePortalSettingLogin: (loginSetting) => dispatch(updatePortalSettingLoginWithThunk(loginSetting)),
    dispatchActiveTab: (active_tab) => dispatch(updatePortalSettingTabAction(active_tab))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalSettingsLogin));