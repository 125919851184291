import { routeFolder } from './app';
const virtualFolder = "/" + routeFolder + "/";

export const routeCodes = {
    HOME: virtualFolder,
    HOME_BLOG: `${virtualFolder}:id/blog/:blog`,
    WORKSPACES_LIST: `${virtualFolder}workspaces`,
    WORKSPACE_CREATE: `${virtualFolder}workspace/create`,
    WORKSPACE_DETAIL: `${virtualFolder}workspaces/:id/properties`,
    WORKSPACE_LANDING: `${virtualFolder}workspaces/:id`,
    WORKSPACE_LANDING_HOME: `${virtualFolder}workspaces/:id/landing`,
    CONTACT_OWNERS: `${virtualFolder}workspaces/:id/contactOwners`,
    CONTACT_ADMINISTRATORS: `${virtualFolder}contactAdministrators`,
    APPLICATIONS_LIST: `${virtualFolder}applications`,
    APPLICATIONS_SETTINGS_LIST: `${virtualFolder}applications/settings/list`,
    APPLICATION_DETAIL: `${virtualFolder}applications/:id`,
    WORKSPACES_APPLICATIONS_LIST: `${virtualFolder}workspaces/:id/applications`,
    WORKSPACES_MEMBERS_LIST: `${virtualFolder}workspaces/:id/members`,
    WORKSPACES_MEMBERS_DETAIL: `${virtualFolder}workspaces/:id/members/:userId/detail`,
    WORKSPACES_MEMBERS_ACCESS: `${virtualFolder}workspaces/:id/members/:userId/access`,
    WORKSPACES_MEMBERS_APPLICATION_SETTINGS: `${virtualFolder}workspaces/:id/applications/:applicationid/users/:userId/settings`,
    WORKSPACES_MEMBERS_CREATE: `${virtualFolder}workspaces/:id/members/create`,
    WORKSPACES_MEMBERS_REGISTER: `${virtualFolder}workspaces/:id/members/register`,
    WORKSPACES_MEMBERS_SHARE: `${virtualFolder}workspaces/:id/members/share`,
    WORKSPACES_USERS_LIST: `${virtualFolder}workspaces/:id/users`,
    WORKSPACES_GENERAL_SETTINGS: `${virtualFolder}workspaces/:id/settings/general`,
    WORKSPACES_LOGIN_SETTINGS: `${virtualFolder}workspaces/:id/settings/login`,
    WORKSPACES_EMAIL_SETTINGS: `${virtualFolder}workspaces/:id/settings/email`,
    WORKSPACES_THEME_SETTINGS: `${virtualFolder}workspaces/:id/settings/theme`,
	WORKSPACES_APPLICATIONS_SETTINGS: `${virtualFolder}workspaces/:id/applications/:applicationid/settings`,
	WORKSPACES_APPLICATIONS_USER_SETTINGS: `${virtualFolder}profile/workspaces/:id/applications/:applicationid/users/:userId/settings`,
    WORKSPACES_APPLICATIONS_LANDING: `${virtualFolder}workspaces/:id/applications/:applicationid`,
    WORKSPACES_APPLICATIONS_LANDING_FOCUS_AREA_MAP: `${virtualFolder}workspaces/:id/applications/:applicationid/landing/:focusArea/:map`,
    WORKSPACES_FOCUS_AREAS_LIST: `${virtualFolder}workspaces/:id/areas`,
    WORKSPACES_FOCUS_AREAS_SHAPE: `${virtualFolder}workspaces/:id/areas/shape`,
    WORKSPACES_CATEGORIES_CREATE: `${virtualFolder}workspaces/:id/categories/create`,
    WORKSPACES_LOGIN_USERS: `${virtualFolder}login/users/:id`,
    WORKSPACES_USERS_RESET_PASSWORD: `${virtualFolder}user/password/reset/:id`,
    APPLICATION_LIST: `${virtualFolder}applications`,
    APPLICATION_CREATE: `${virtualFolder}application/create`,
    CATEGORY_LIST: `${virtualFolder}categories`,
    CATEGORY_CREATE: `${virtualFolder}category/create`,
    CATEGORY_DETAIL: `${virtualFolder}categories/:categoryid`,
    WORKSPACE_APPLICATION_DETAIL: `${virtualFolder}workspaces/:id/applications/:applicationid/properties`,
    WORKSPACE_CATEGORY_DETAIL: `${virtualFolder}workspaces/:id/categories/:categoryid`,
    USERS: `${virtualFolder}users`,
    USERS_LIST: `${virtualFolder}users`,
    USER_DETAIL: `${virtualFolder}users/:id`,
    USER_ACCESS: `${virtualFolder}users/:id/access`,
    USER_CREATE: `${virtualFolder}user/create`,
    USERS_LOGIN_CONFIRMATION: `${virtualFolder}login/users/confirmation/:id`,
    USERS_CHANGE_PASSWORD: `${virtualFolder}user/password/change`,
    USERS_RESET_PASSWORD: `${virtualFolder}user/password/reset`,    
    USERS_LOGIN: `${virtualFolder}login/users`,    
    PROFILE: `${virtualFolder}profile`,
    PROFILE_ACCESS: `${virtualFolder}profile/access`,
    USERS_LOGOUT: `${virtualFolder}logout`,
    PORTAL_SETTINGS: `${virtualFolder}settings`,
    PORTAL_FEATURES: `${virtualFolder}settings/features`,
    PORTAL_NEWS: `${virtualFolder}settings/news`,
    PORTAL_OPTIONS: `${virtualFolder}settings/settings`,
    PORTAL_OPTIONS_EMAIL: `${virtualFolder}settings/email`,
    PORTAL_OPTIONS_THEME: `${virtualFolder}settings/theme`,
    UNAVAILABLE_PAGE: `${virtualFolder}unavailable`,
};