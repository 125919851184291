import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner'
import PortalInfoContainer from '../../Portal/Info/PortalInfoContainer';
import UsersConfirmationLoginBody from './UsersConfirmationLoginBody';
import UsersInfoWorkspacesContainer from '../Info/UsersInfoWorkspacesContainer';
import UsersLoginErrorModal from '../Modal/UsersLoginErrorModal';
import { clearPortalLoginAction } from '../../../actions/clearPortalLoginAction';
import { getPortalLoginWithThunk } from '../../../api/portalLoginThunk';
import { getUrlLoginConfirmationUserId } from '../../../utils/url';


class UsersConfirmationContainer extends Component {
    static propTypes = {
        portalLogin: PropTypes.object.isRequired,
        getPortalLogin: PropTypes.func.isRequired,
        clearPortalLogin: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        role: PropTypes.object.isRequired
    };

    state = {
        userId: "",
        token: "",
        actionType: ""
    };

    getUserId = () => {
        let id = getUrlLoginConfirmationUserId(this.props.location.pathname);
        return id;
    }

    componentDidMount() {        
        let paramsString = window.location.search;
        let params = new URLSearchParams(paramsString);
        let token = params.get('token');
        let actionType = params.get('type');
        let userId = (params.get('userId') !== undefined && params.get('userId') !== null && params.get('userId') !== '') ? params.get('userId') : this.getUserId();
        let workspaceId = (params.get('workspaceId') !== null && params.get('workspaceId') !== undefined) ? params.get('workspaceId') : '';

        this.setState({
            userId,
            token,
            actionType
        })
        this.props.getPortalLogin(workspaceId);
    }

    componentWillUnmount() {
        this.props.clearPortalLogin();
    }

    render() {
        const { role } = this.props;        
        const { portalLogin, loading } = this.props;
        const { userId, token, actionType } = this.state;

        if (portalLogin.welcome_text_selected === undefined || portalLogin.welcome_text_selected === '')
            return (
                <div>
                    {loading &&
                        <div className="loader-container">
                            <Loader
                                type="Ball-Triangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                </div>
            );

        return (
            <div className="container-fluid h-100">
                <div className="row h-100">
                    <div className="col-lg-6 d-none d-lg-block p-0 left">
                        <PortalInfoContainer />
                    </div>
                    {!role.is_logged_in &&
                        <div className="col-lg-6 p-0 right">
                            <UsersConfirmationLoginBody userId={userId} token={token} actionType={actionType} />
                            <UsersLoginErrorModal type="error" />
                        </div>
                    }
                    {role.is_logged_in && role.user_id !== '' &&
                        <div className="col-lg-6 p-0 right">
                            <UsersInfoWorkspacesContainer />
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ role, portalLogin, loading }) => ({
    role,
    portalLogin: portalLogin.portal_login,
    loading: loading.portal_login.loading
});

const mapDispatchToProps = (dispatch) => ({
    getPortalLogin: (workspaceId) => dispatch(getPortalLoginWithThunk(workspaceId)),
    clearPortalLogin: () => dispatch(clearPortalLoginAction([]))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersConfirmationContainer));