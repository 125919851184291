import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import RichTextEditor from '../../Editor/RichTextEditor';
import ImageEditor from '../../Editor/ImageEditor';
import { routeCodes } from '../../../configurations/routes';
import { modalImageEditorStyle } from '../../../utils/modal';
import { updateApplicationAction } from '../../../actions/updateApplicationAction';
import { updateDialogApplicationsAction } from '../../../actions/updateDialogApplicationsAction';
import { updateApplicationWithThunk } from '../../../api/applicationsThunk';
import { updateApplicationDetailStatusWithThunk } from '../../../api/applicationsThunk';
import { getDefaultImage } from '../../../utils/cropImage';

class ApplicationsDetailBody extends Component {
    constructor(props) {
        super(props);
        this.onApplicationTitleChange = this.onApplicationTitleChange.bind(this);
        this.onApplicationDescriptionChange = this.onApplicationDescriptionChange.bind(this);
        this.updateApplicationDetail = this.updateApplicationDetail.bind(this);
        this.onApplicationUrlChange = this.onApplicationUrlChange.bind(this);
        this.onToggleIsAllowPublicAccess = this.onToggleIsAllowPublicAccess.bind(this);
        this.onKeywordsChange = this.onKeywordsChange.bind(this);
        this.onSelectFile = this.onSelectFile.bind(this);
        this.onCloseImageEditor = this.onCloseImageEditor.bind(this);
        this.handleNoSnapshotChange = this.handleNoSnapshotChange.bind(this);
        this.handleSnapshotChange = this.handleSnapshotChange.bind(this);
        this.removeApplication = this.removeApplication.bind(this);
        this.onSelectSnapshot = this.onSelectSnapshot.bind(this);
        this.getSnapshotImageStyle = this.getSnapshotImageStyle.bind(this);
        this.imgSnapshot = React.createRef();
        this.imgNoSnapshot = React.createRef();
        this.imgSnapshotRadioButton = React.createRef();
        this.imgNoSnapshotRadioButton = React.createRef();
        this.imgLogoContainer = React.createRef();
        this.imgLogo = React.createRef();
    }

    static propTypes = {
        dialog: PropTypes.object.isRequired,
        application: PropTypes.object.isRequired,
        dispatchApplication: PropTypes.func.isRequired,
        updateApplication: PropTypes.func.isRequired,
        dispatchDialogApplications: PropTypes.func.isRequired,
        updateApplicationStatus: PropTypes.func.isRequired
    };

    state = {
        src: null,
        imageEditorIsOpen: false
    };

    onApplicationTitleChange(e) {
        const application = {
            ...this.props.application,
            application_title: e.target.value
        };

        this.props.dispatchApplication(application);
    }

    onApplicationDescriptionChange(data) {
        const application = {
            ...this.props.application,
            application_desc: data
        };

        this.props.dispatchApplication(application);
    }

    onApplicationUrlChange(e) {
        const application = {
            ...this.props.application,
            application_url: e.target.value
        };

        this.props.dispatchApplication(application);
    }

    onToggleIsAllowPublicAccess(e) {
        const application = {
            ...this.props.application,
            allow_public_access: e.target.checked
        }

        this.props.dispatchApplication(application);
    }

    onKeywordsChange(e) {
        const data = e.target.value.split(',');
        let keywords = [];

        for (let i in data) {
            let item = data[i].trim();
            keywords.push(item);
        }

        const application = {
            ...this.props.application,
            keywords: keywords
        };

        this.props.dispatchApplication(application);
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({ src: reader.result, imageEditorIsOpen: true });
            }
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onCloseImageEditor(image, aspectRatio) {
        this.setState({ imageEditorIsOpen: false });
        let application = {
            ...this.props.application,
            url_image: image,
            image_aspect_ratio: aspectRatio,
            is_logo_changed: true
        };
        this.props.dispatchApplication(application);
    }

    handleNoSnapshotChange() {
        let application = {
            ...this.props.application,
            snapshot_type: 0,
        };
        this.props.dispatchApplication(application);
    }

    handleSnapshotChange() {
        let application = {
            ...this.props.application,
            snapshot_type: 1,
        };
        this.props.dispatchApplication(application);
    }

    updateApplicationDetail() {
        const { application } = this.props;

        this.props.updateApplication(application, false, this.props.history);
    }

    updateApplicationStatus() {
        if (this.props.application.status === 'Enabled') {
            let applications = {
                id: this.props.application.id,
                name: this.props.application.application_name,
                status: this.props.application.status,
                dialog_title: 'Warning',
                dialog_type: (this.props.application.status === 'Enabled') ? 'disable' : 'enable',
                route_back_to: '',
                show_dialog: (this.props.application.status === 'Enabled') ? true : false
            };

            this.props.dispatchDialogApplications(applications);
        }
        else {
            const application = {
                ...this.props.application,
                status: (this.props.application.status === 'Enabled') ? 'Disabled' : 'Enabled'
            };

            this.props.updateApplicationStatus(application);
        }
    }

    getAspectRatioClass() {
        const { image_aspect_ratio } = this.props.application;

        let aspectRatioClass = 'crop-logo43';

        if (image_aspect_ratio >= 1 && image_aspect_ratio < 1.2) {
            aspectRatioClass = 'crop-logo11';
        }
        else if (image_aspect_ratio >= 1.2 && image_aspect_ratio < 1.4) {
            aspectRatioClass = 'crop-logo43';
        }
        else if (image_aspect_ratio >= 1.4 && image_aspect_ratio < 1.6) {
            aspectRatioClass = 'crop-logo32';
        }
        else if (image_aspect_ratio >= 1.6 && image_aspect_ratio < 2.3) {
            aspectRatioClass = 'crop-logo169';
        }
        else if (image_aspect_ratio >= 2.3) {
            aspectRatioClass = 'crop-logo219';
        }
        else {
            if (this.imgLogoContainer.current !== null && this.imgLogo.current !== null) {
                let containerWidth = this.imgLogoContainer.current.offsetWidth;
                let logoWidth = this.imgLogo.current.offsetWidth;
                let containerHeight = this.imgLogoContainer.current.offsetHeight;
                let logoHeight = this.imgLogo.current.offsetHeight;

                this.imgLogo.current.style.left = (containerWidth / 2 - logoWidth / 2) + 'px';
                this.imgLogo.current.style.top = (1.127 * containerHeight) + (containerHeight / 2 - logoHeight / 2) + 'px';
                this.imgLogo.current.style.position = 'absolute';
            }
        }
        return aspectRatioClass;
    }

    setDefaultImage() {
        const { url_image } = this.props.application;

        if (url_image === undefined || url_image === '') {
            const application = {
                ...this.props.application,
                url_image: getDefaultImage().replace("data:image/png;base64,", ""),
                image_aspect_ratio: 4 / 3
            };

            this.props.dispatchApplication(application);
        }
    }

    removeApplication() {
        let applications = {
            id: this.props.application.id,
            name: this.props.application.application_name,
            status: this.props.application.status,
            dialog_title: 'Warning',
            dialog_type: 'trash',
            route_back_to: routeCodes.APPLICATIONS_LIST,
            show_dialog: true
        };

        this.props.dispatchDialogApplications(applications);
    }

    onSelectSnapshot = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                let imgs = reader.result.split("base64,");
                if (imgs.length > 0) {
                    let application = {
                        ...this.props.application,
                        snapshot_type: 1,
                        snapshot_url_image: imgs[1],
                        is_snapshot_changed: true
                    };
                    this.imgSnapshotRadioButton.current.checked = true;
                    this.props.dispatchApplication(application);
                }
            }
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    getSnapshotImageStyle() {
        if (this.imgSnapshot.current !== null && this.imgNoSnapshot.current !== null) {
            this.imgSnapshot.current.style.height = this.imgSnapshot.current.offsetWidth * (175/381) + 'px';
            this.imgNoSnapshot.current.style.height = this.imgNoSnapshot.current.offsetWidth * (175 / 381) + 'px';
        }
    }

    componentDidMount() {
        this.setDefaultImage();
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    render() {
        const { application, role } = this.props;
        const { src, imageEditorIsOpen } = this.state;
        let keywords = '';

        application.keywords.forEach((item) => {
            keywords += ((keywords !== '') ? ", " : "") + item;
        });

        return (
            <div className="mainBody">
                <div className="row mt-5">
                    <div className="col-md-7 order-md-1 col-lg-8 col-xl-7">
                        <div className="wsHomeSection">
                            <div className="desc pr-4">
                                <div className="formWrap">
                                    <div className="inputForm">
                                        <input className="form-control" type="text" name="" required onChange={this.onApplicationTitleChange} defaultValue={application.application_title} />
                                        <label>Title</label>
                                    </div>
                                    <div>
                                        <label>Status</label>&nbsp;
                                        <span className={(application.status === "Disabled") ? "status-disabled" : "status-enabled"}>{application.status}</span>
                                    </div>
                                    <div>
                                        <label>Description</label>
                                    </div>
                                    <div className="summernote">
                                        <RichTextEditor value={application.application_desc} required onChange={this.onApplicationDescriptionChange} />
                                    </div>
                                    <div className="inputForm mt-5">
                                        <input className="form-control" type="text" name="" required onChange={this.onApplicationUrlChange} defaultValue={application.application_url}  />
                                        <label>URL</label>
                                    </div>
                                    <div className="groupAppForm mt-5">
                                        <label className="sectionTitle">Application Snapshot</label>
                                        <p>Choose snapshot</p>
                                        <div className="row">
                                            <div className="col-sm-4 col-md-4 col-xl-5">
                                                <label className="custom-checkbox custom-pick-image reveal">
                                                    {application.snapshot_type === 0 &&
                                                        <input ref={this.imgNoSnapshotRadioButton} type="radio" name="lpwb" className="custom-control-input" defaultChecked />                                                    
                                                    }
                                                    {application.snapshot_type === 1 &&
                                                        <input ref={this.imgNoSnapshotRadioButton} type="radio" name="lpwb" className="custom-control-input" />                                         
                                                    }
                                                    <div className="imgContainer" onClick={this.handleNoSnapshotChange} >
                                                        <img ref={this.imgNoSnapshot} src={process.env.PUBLIC_URL + "/images/no-image-wide.png"} style={this.getSnapshotImageStyle()}  className="themeAppImg" alt="" />
                                                    </div>
                                                    <p><span className="icon-wrd-checkmark"></span> No snapshot</p>
                                                 </label>
                                            </div>
                                            <div className="col-sm-4 col-md-4 col-xl-5">
                                                <label className="custom-checkbox custom-pick-image">
                                                    {application.snapshot_type === 0 &&
                                                        <input ref={this.imgSnapshotRadioButton} type="radio" name="lpwb" className="custom-control-input" />                                                    
                                                    }
                                                    {application.snapshot_type === 1 &&
                                                        <input ref={this.imgSnapshotRadioButton} type="radio" name="lpwb" className="custom-control-input" defaultChecked />
                                                    }
                                                    <div className="imgContainer" onClick={this.handleSnapshotChange} >
                                                        {(application.snapshot_url_image !== undefined) &&
                                                            <img ref={this.imgSnapshot} className="themeAppImg" style={this.getSnapshotImageStyle()} src={"data:image/png;base64," + application.snapshot_url_image} alt="" />
                                                        }
                                                        {(application.snapshot_url_image === undefined) &&
                                                            <img ref={this.imgSnapshot} className="themeAppImg" style={this.getSnapshotImageStyle()} alt="" />
                                                        }
                                                    </div>
                                                    <p>Click browse if you want to upload new snapshot</p>
                                                    <button className="btn btn-wrd_default icoTextBtn browseLogo">
                                                        <label className="custom-file-upload">
                                                            <input type="file" onChange={this.onSelectSnapshot} />
                                                            <span className="icon-wrd-folder"></span>
                                                            Browse
                                                        </label>
                                                    </button>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-none">
                                        <div className="custom-controls-stacked">
                                            <label className="custom-control ios-switch">
                                                <input type="checkbox" onClick={this.onToggleIsAllowPublicAccess} className="ios-switch-control-input" defaultChecked={application.allow_public_access} />
                                                <span className="ios-switch-control-indicator"></span>
                                                <span className="text">Allow Public Access</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="inputForm">
                                        <input className="form-control" type="text" name="" required onChange={this.onKeywordsChange} defaultValue={keywords} />
                                        <label>Keyword(s)</label>
                                        <p className="mt-2">
                                            <span className="icon-wrd-alert-triangle"></span>&nbsp; 
                                            Use coma ( , ) if using more than one. eg: rainfall, price, etc
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-4 col-xl-3">
                        <div className="wsLogo pb-5 text-center">
                            <div ref={this.imgLogoContainer} className="logoContainer wsHome text-center">
                                <img src={process.env.PUBLIC_URL + "/images/transCircle.png"} className="insideCircle" alt="" />
                                <img ref={this.imgLogo} src={((application.url_image !== undefined) ? "data:image/png;base64," : "") + application.url_image}
                                    className={this.getAspectRatioClass()} alt=""
                                />
                            </div>
                            <button className="btn btn-wrd_default icoTextBtn browseLogo">
                                <label className="custom-file-upload">
                                    <input type="file" onChange={this.onSelectFile} />
                                    <span className="icon-wrd-folder icon-upload-folder"></span>
                                    Browse
                                </label>
                            </button>
                            <span className="d-block mt-1 mb-5 font-italic text-grey">Browse to change logo</span>
                            <button className="btn mt-4 textBtn w-75 greenBtn" onClick={() => this.updateApplicationDetail()}>
                                Update Changes
                            </button>
                            <button className={(application.status === 'Enabled') ? 'btn textBtn w-75 redBtn' : 'btn textBtn w-75 greenBtn'}
                                onClick={() => this.updateApplicationStatus()}>{(application.status === 'Enabled') ? 'Disable' : 'Enable'}
                            </button>
                        </div>
                    </div>
                </div>
                <ul className="topNavBtn">
                    {role.user_role === 'Admin' &&
                        <li>
                            <button className="btn redBtn" onClick={this.removeApplication}>
                                <span className="icon-wrd-trash"></span>
                                Remove Application
                            </button>
                        </li>
                    }
                </ul>
                {src &&
                    <Modal
                        isOpen={imageEditorIsOpen}
                        style={modalImageEditorStyle}
                        contentLabel="Image Editor"
                    >
                        <ImageEditor srcImage={src} onClose={this.onCloseImageEditor} />
                    </Modal>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, dialog, role }) => ({
    application: detail.application,
    dialog: dialog.applications,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogApplications: (dialog) => dispatch(updateDialogApplicationsAction(dialog)),
    dispatchApplication: (application) => dispatch(updateApplicationAction(application)),
    updateApplication: (application, bypassUrl, historyProps) => dispatch(updateApplicationWithThunk(application, bypassUrl, historyProps)),
    updateApplicationStatus: (application) => dispatch(updateApplicationDetailStatusWithThunk(application))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationsDetailBody));