import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { updatePortalSettingEmailAction } from '../../../../actions/updatePortalSettingEmailAction';
import RichTextEditor from '../../../Editor/RichTextEditor';
import { registrationContent, registrationReminderContent, resetPasswordContent, forgotPasswordContent, GrantWorkspaceContent } from '../../../../utils/emailContent';

class PortalSettingsEmailItem extends Component {
    constructor(props) {
        super(props);
        this.onEmailContentChange = this.onEmailContentChange.bind(this);
        this.onEmailSenderChange = this.onEmailSenderChange.bind(this);
        this.onEmailSubjectChange = this.onEmailSubjectChange.bind(this);
    }

    static propTypes = {
        item: PropTypes.object.isRequired,
        portalSettingEmail: PropTypes.object.isRequired
    };

    onEmailContentChange(data) {
        const { item, portalSettingEmail } = this.props;
        let emails = [];

        for (let i in portalSettingEmail.emails) {
            let email = portalSettingEmail.emails[i];
            if (email.id === item.id) {
                email.email_content = data;
            }
            emails.push(email);
        }

        let emailSetting = {
            ...portalSettingEmail,
            emails
        };

        this.props.dispatchPortalSettingEmail(emailSetting);
    }

    onEmailSenderChange(e) {
        const { item, portalSettingEmail } = this.props;
        let emails = [];

        for (let i in portalSettingEmail.emails) {
            let email = portalSettingEmail.emails[i];
            if (email.id === item.id) {
                email.sender_from = e.target.value;
            }
            emails.push(email);
        }

        let emailSetting = {
            ...portalSettingEmail,
            emails
        };

        this.props.dispatchPortalSettingEmail(emailSetting);
    }

    onEmailSubjectChange(e) {
        const { item, portalSettingEmail } = this.props;
        let emails = [];

        for (let i in portalSettingEmail.emails) {
            let email = portalSettingEmail.emails[i];
            if (email.id === item.id) {
                email.email_subject = e.target.value;
            }
            emails.push(email);
        }

        let emailSetting = {
            ...portalSettingEmail,
            emails
        };

        this.props.dispatchPortalSettingEmail(emailSetting);
    }

    componentWillMount() {
        const { portalSettingEmail } = this.props;
        let emails = [];
        for (let i in portalSettingEmail.emails) {
            let email = portalSettingEmail.emails[i];
            if (email.email_type === 'Registration') {
                if (email.email_content === '') {
                    email.email_content = registrationContent;
                }
            } else if (email.email_type === 'RegistrationReminder') {
                if (email.email_content === '') {
                    email.email_content = registrationReminderContent;
                }
            }
            else if (email.email_type === 'ResetPassword') {
                if (email.email_content === '') {
                    email.email_content = resetPasswordContent;
                }
            }
            else if (email.email_type === 'ForgotPassword') {
                if (email.email_content === '') {
                    email.email_content = forgotPasswordContent;
                }
            }
            else if (email.email_type === 'GrantWorkspace') {
                if (email.email_content === '') {
                    email.email_content = GrantWorkspaceContent;
                }
            }
            emails.push(email);
        }

        let emailSetting = {
            ...portalSettingEmail,
            emails
        };

        this.props.dispatchPortalSettingEmail(emailSetting);
    }

    render() {
        const { item } = this.props;

        return (
            <div>
                <div className="groupForm mb-0">
                    <label className="sectionTitle">{(item.email_type === 'Registration') ? 'Registration Email' : (item.email_type === 'RegistrationReminder') ? 'Registration Reminder Email' : (item.email_type === 'ResetPassword') ? 'Reset Password Email' : (item.email_type === 'ForgotPassword') ? 'Forgot Password Email' : (item.email_type === 'GrantWorkspace') ? 'Grant Workspace Email' : null}</label>
                    {(item.email_type === 'Registration') ? <p>Choose how to configure email response related to registration.</p> :
                        (item.email_type === 'RegistrationReminder') ?
                            <p>Choose how to configure email response related to registration reminder.</p> :
                            (item.email_type === 'ResetPassword') ?
                                <p>Choose how to configure email response related to password reset.</p> :
                                (item.email_type === 'ForgotPassword') ?
                                    <p>Choose how to configure email response related to forgot password.</p> :
                                    (item.email_type === 'GrantWorkspace') ?
                                        <p>Choose how to configure email response related to grant user to workspace.</p> :
                                    null
                    }
                    <hr />
                    <div className="row">
                        <div className="summernotWrap mx-3 mb-5 w-100 container">
                            <div className="formWrap row">
                                <div className="inputForm col-md-6 px-2">
                                    <input className="form-control" type="text" name="" value={item.sender_from} onChange={this.onEmailSenderChange}
                                        required />
                                    <label>From</label>
                                </div>
                                <div className="inputForm col-md-6 px-2">
                                    <input className="form-control" type="text" name="" onChange={this.onEmailSubjectChange}
                                        value={item.email_subject} required />
                                    <label>Subject</label>
                                </div>
                                <div className="inputForm">
                                    <RichTextEditor className="summernote" value={item.email_content} onChange={this.onEmailContentChange} />
                                    <div id="email-confirmation" className="summernote"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ portalSettings }) => ({
    portalSettingEmail: portalSettings.setting_email
});

const mapDispatchToProps = (dispatch) => ({
    dispatchPortalSettingEmail: (emailSetting) => dispatch(updatePortalSettingEmailAction(emailSetting))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalSettingsEmailItem));