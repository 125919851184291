import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { routeCodes } from '../configurations/routes';
import { checkBeforeAccess, redirectToPage } from '../utils/pageRedirectionRules';
import { isCustomDomainPage } from '../utils/login';
import { getWorkspaceNameByCustomDomainApi } from '../backend/domain/workspaces';

import PortalLandingContainer from './Portal/Landing/PortalLandingContainer';
import PortalSettingsContainer from './Portal/Settings/PortalSettingsContainer';
import WorkspacesListContainer from './Workspaces/List/WorkspacesListContainer';
import WorkspacesCreateContainer from './Workspaces/Create/WorkspacesCreateContainer';
import WorkspacesDetailContainer from './Workspaces/Detail/WorkspacesDetailContainer';
import WorkspacesLandingContainer from './Workspaces/Landing/WorkspacesLandingContainer';
import ApplicationsListContainer from './Applications/List/ApplicationsListContainer';
import ApplicationsDetailContainer from './Applications/Detail/ApplicationsDetailContainer';
import WorkspacesApplicationsContainer from './Workspaces/Applications/List/WorkspacesApplicationsContainer';
import WorkspacesMembersContainer from './Workspaces/Members/List/WorkspacesMembersContainer';
import WorkspacesMembersShareContainer from './Workspaces/Members/Share/WorkspacesMembersShareContainer';
import WorkspacesGeneralSettingsContainer from './Workspaces/Settings/General/WorkspacesGeneralSettingsContainer';
import WorkspacesLoginSettingsContainer from './Workspaces/Settings/Login/WorkspacesLoginSettingsContainer';
import WorkspacesEmailSettingsContainer from './Workspaces/Settings/Email/WorkspacesEmailSettingsContainer';
import WorkspacesThemeSettingsContainer from './Workspaces/Settings/Theme/WorkspacesThemeSettingsContainer';
import WorkspacesApplicationsSettingsContainer from './Workspaces/Applications/Settings/WorkspacesApplicationsSettingsContainer';
import WorkspacesApplicationsUserSettingsContainer from './Workspaces/Applications/UserSettings/WorkspacesApplicationsUserSettingsContainer';
import WorkspacesApplicationsLandingContainer from './Workspaces/Applications/Landing/WorkspacesApplicationsLandingContainer'
import WorkspacesFocusAreasContainer from './Workspaces/FocusArea/List/WorkspacesFocusAreasContainer';
import WorkspacesFocusAreasShapeContainer from './Workspaces/FocusArea/Shape/WorkspacesFocusAreasShapeContainer';
import ApplicationsCreateContainer from './Applications/Create/ApplicationsCreateContainer';
import WorkspacesApplicationsDetailContainer from './Workspaces/Applications/Detail/WorkspacesApplicationsDetailContainer';
import WorkspacesCategoriesCreateContainer from './Workspaces/Categories/Create/WorkspacesCategoriesCreateContainer';
import WorkspacesCategoriesDetailContainer from './Workspaces/Categories/Detail/WorkspacesCategoriesDetailContainer';
import CategoriesCreateContainer from './Categories/Create/CategoriesCreateContainer';
import CategoriesDetailContainer from './Categories/Detail/CategoriesDetailContainer';
import UsersListContainer from './Users/List/UsersListContainer';
import UsersDetailContainer from './Users/Detail/UsersDetailContainer';
import UsersAccessContainer from './Users/Access/UsersAccessContainer';
import UserCreateContainer from './Users/Create/UserCreateContainer';
import ProfileContainer from './Profile/Detail/ProfileContainers';
import ProfileAccessContainer from './Profile/Access/ProfileAccessContainer';
import GlobalUsersListContainer from './GlobalUsers/List/GlobalUsersListContainer';
import UsersConfirmationContainer from './Users/Confirmation/UsersConfirmationContainer';
import UsersLoginContainer from './Users/Login/UsersLoginContainer';
import UsersLogoutContainer from './Users/Logout/UsersLogoutContainer';
import UsersChangePasswordContainer from './Users/ChangePassword/UsersChangePasswordContainer';
import UsersResetPasswordContainer from './Users/ResetPassword/UsersResetPasswordContainer';
import UnavailablePagesContainer from './UnavailablePages/UnavailablePagesContainer';
import ContactOwnersContainer from './ContactOwners/ContactOwnersContainer';
import ContactAdministratorsContainer from './ContactAdministrators/ContactAdministratorsContainer';
import UserRegisterContainer from './Users/Register/UserRegisterContainer';

class App extends Component {
    static propTypes = {
        role: PropTypes.object.isRequired,
        availableWorkspaces: PropTypes.object.isRequired
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            const prevLocation = this.props.location.pathname;
            const location = nextProps.location.pathname;
            const paramsString = window.location.search;
            const params = new URLSearchParams(paramsString);
            const role = params.get('role');

            redirectToPage(location, this.props.redirectUrl, this.props.role);
            checkBeforeAccess(prevLocation, location, role, this.props.history, this.props.role);
        }
    }

    async componentDidMount() {
        const location = this.props.location.pathname;
        const paramsString = window.location.search;
        const params = new URLSearchParams(paramsString);
        const role = params.get('role');

        if (isCustomDomainPage(window.location.host, location)) {
            let results = await getWorkspaceNameByCustomDomainApi(window.location.host);
            if (results !== null && results.length === 2) {
                let workspaceName = results[1].toLowerCase().replaceAll(" ", "+");
                this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + workspaceName);
            }
            else {
                window.location.href = routeCodes.UNAVAILABLE_PAGE + '?page=' + window.location.host + "&domain=true";
            }
        }
        else {
            redirectToPage(location, this.props.redirectUrl, this.props.role);
            checkBeforeAccess(location, location, role, this.props.history, this.props.role);
        }
    }

    render() {
        return (
            <div className="h-100">
                <Route exact path={routeCodes.HOME} component={PortalLandingContainer} />
                <Route exact path={routeCodes.HOME_BLOG} component={PortalLandingContainer} />
                <Route exact path={routeCodes.WORKSPACES_LIST} component={WorkspacesListContainer} />
                <Route exact path={routeCodes.WORKSPACE_CREATE} component={WorkspacesCreateContainer} />
                <Route exact path={routeCodes.WORKSPACE_DETAIL} component={WorkspacesDetailContainer} />
                <Route exact path={routeCodes.WORKSPACE_LANDING} component={WorkspacesLandingContainer} />
                <Route exact path={routeCodes.WORKSPACE_LANDING_HOME} component={WorkspacesLandingContainer} />
                <Route exact path={routeCodes.WORKSPACES_APPLICATIONS_LIST} component={WorkspacesApplicationsContainer} />
                <Route exact path={routeCodes.WORKSPACES_MEMBERS_LIST} component={WorkspacesMembersContainer} />
                <Route exact path={routeCodes.WORKSPACES_MEMBERS_DETAIL} component={UsersDetailContainer} />
                <Route exact path={routeCodes.WORKSPACES_MEMBERS_ACCESS} component={UsersAccessContainer} />
                <Route exact path={routeCodes.WORKSPACES_MEMBERS_APPLICATION_SETTINGS} component={WorkspacesApplicationsUserSettingsContainer} />
                <Route exact path={routeCodes.WORKSPACES_MEMBERS_CREATE} component={UserCreateContainer} />
                <Route exact path={routeCodes.WORKSPACES_MEMBERS_REGISTER} component={UserRegisterContainer} />
                <Route exact path={routeCodes.WORKSPACES_CATEGORIES_CREATE} component={WorkspacesCategoriesCreateContainer} />
                <Route exact path={routeCodes.WORKSPACES_MEMBERS_SHARE} component={WorkspacesMembersShareContainer} />
                <Route exact path={routeCodes.WORKSPACES_USERS_LIST} component={GlobalUsersListContainer} />
                <Route exact path={routeCodes.WORKSPACES_GENERAL_SETTINGS} component={WorkspacesGeneralSettingsContainer} />
                <Route exact path={routeCodes.WORKSPACES_LOGIN_SETTINGS} component={WorkspacesLoginSettingsContainer} />
                <Route exact path={routeCodes.WORKSPACES_EMAIL_SETTINGS} component={WorkspacesEmailSettingsContainer} />
                <Route exact path={routeCodes.WORKSPACES_THEME_SETTINGS} component={WorkspacesThemeSettingsContainer} />
				<Route exact path={routeCodes.WORKSPACES_APPLICATIONS_SETTINGS} component={WorkspacesApplicationsSettingsContainer} />
				<Route exact path={routeCodes.WORKSPACES_APPLICATIONS_USER_SETTINGS} component={WorkspacesApplicationsUserSettingsContainer} />
                <Route exact path={routeCodes.WORKSPACES_APPLICATIONS_LANDING} component={WorkspacesApplicationsLandingContainer} />
                <Route exact path={routeCodes.WORKSPACES_APPLICATIONS_LANDING_FOCUS_AREA_MAP} component={WorkspacesApplicationsLandingContainer} />
                <Route exact path={routeCodes.WORKSPACES_FOCUS_AREAS_LIST} component={WorkspacesFocusAreasContainer} />
                <Route exact path={routeCodes.WORKSPACES_FOCUS_AREAS_SHAPE} component={WorkspacesFocusAreasShapeContainer} />
                <Route exact path={routeCodes.WORKSPACES_LOGIN_USERS} component={UsersLoginContainer} />
                <Route exact path={routeCodes.WORKSPACES_USERS_RESET_PASSWORD} component={UsersResetPasswordContainer} />
                <Route exact path={routeCodes.APPLICATIONS_LIST} component={ApplicationsListContainer} />
                <Route exact path={routeCodes.APPLICATION_CREATE} component={ApplicationsCreateContainer} />
                <Route exact path={routeCodes.APPLICATION_DETAIL} component={ApplicationsDetailContainer} />
                <Route exact path={routeCodes.WORKSPACE_APPLICATION_DETAIL} component={WorkspacesApplicationsDetailContainer} />
                <Route exact path={routeCodes.WORKSPACE_CATEGORY_DETAIL} component={WorkspacesCategoriesDetailContainer} />
                <Route exact path={routeCodes.CATEGORY_CREATE} component={CategoriesCreateContainer} />
                <Route exact path={routeCodes.CATEGORY_DETAIL} component={CategoriesDetailContainer} />
                <Route exact path={routeCodes.USERS_LIST} component={UsersListContainer} />
                <Route exact path={routeCodes.USER_DETAIL} component={UsersDetailContainer} />
                <Route exact path={routeCodes.USER_ACCESS} component={UsersAccessContainer} />
                <Route exact path={routeCodes.USER_CREATE} component={UserCreateContainer} />
                <Route exact path={routeCodes.USERS_LOGIN_CONFIRMATION} component={UsersConfirmationContainer} />
                <Route exact path={routeCodes.USERS_LOGIN} component={UsersLoginContainer} />
                <Route exact path={routeCodes.USERS_LOGOUT} component={UsersLogoutContainer} />
                <Route exact path={routeCodes.USERS_CHANGE_PASSWORD} component={UsersChangePasswordContainer} />
                <Route exact path={routeCodes.USERS_RESET_PASSWORD} component={UsersResetPasswordContainer} />
                <Route exact path={routeCodes.PROFILE} component={ProfileContainer} />
                <Route exact path={routeCodes.PROFILE_ACCESS} component={ProfileAccessContainer} />
                <Route exact path={routeCodes.PORTAL_SETTINGS} component={PortalSettingsContainer} />
                <Route exact path={routeCodes.PORTAL_FEATURES} component={PortalSettingsContainer} />
                <Route exact path={routeCodes.PORTAL_NEWS} component={PortalSettingsContainer} />
                <Route exact path={routeCodes.PORTAL_OPTIONS} component={PortalSettingsContainer} />
                <Route exact path={routeCodes.PORTAL_OPTIONS_EMAIL} component={PortalSettingsContainer} />
                <Route exact path={routeCodes.PORTAL_OPTIONS_THEME} component={PortalSettingsContainer} />
                <Route exact path={routeCodes.UNAVAILABLE_PAGE} component={UnavailablePagesContainer} />
                <Route exact path={routeCodes.CONTACT_OWNERS} component={ContactOwnersContainer} />
                <Route exact path={routeCodes.CONTACT_ADMINISTRATORS} component={ContactAdministratorsContainer} />
            </div>
        );
    }
}

const mapStateToProps = ({ role, redirectUrl, availableWorkspaces, loading }) => ({
    role,
    redirectUrl,
    availableWorkspaces,
    loading: loading.page_redirection.loading
});

export default withRouter(connect(mapStateToProps, null)(App));