import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { routeCodes } from '../../../configurations/routes';

class WorkspacesDetailApplicationsItem extends Component {
    static propTypes = {
        workspace: PropTypes.object.isRequired,
        item: PropTypes.object.isRequired
    };

    render() {
        const { item, workspace } = this.props;

        return (
            <li className="clearfix">
                <Link className="detailLink" to={routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/applications/" + item.application_name.toLowerCase().replaceAll(" ", "+") + "/properties"}>
                    <span className="appIcon">
                        <img src={((item.url_image !== undefined) ? "data:image/jpeg;base64," : "") + item.url_image} alt="" />
                    </span>
                    <span className="appName">{item.application_name}</span>
                    <span className="appShortDesc">{item.application_title}</span>
                </Link>
            </li>
        );
    }
}

const mapStateToProps = ({ detail }) => ({
    workspace: detail.workspace
});

export default withRouter(connect(mapStateToProps)(WorkspacesDetailApplicationsItem));