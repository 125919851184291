import {
    getPortalLandingBannerApi,
    getPortalLandingFeatureApi,
    getPortalLandingNewsApi,
    getPortalSingleFeatureApi,
    getPortalSingleNewsApi
} from '../backend/domain/portal';

import { getPortalLandingBlogAction } from '../actions/getPortalLandingBlogAction';
import { getPortalLandingBannerAction } from '../actions/getPortalLandingBannerAction';
import { loadingPortalLandingBannerAction } from '../actions/loadingPortalLandingBannerAction';
import { getPortalLandingFeatureAction } from '../actions/getPortalLandingFeatureAction';
import { loadingPortalLandingFeatureAction } from '../actions/loadingPortalLandingFeatureAction';
import { getPortalLandingNewsAction } from '../actions/getPortalLandingNewsAction';
import { loadingPortalLandingNewsAction } from '../actions/loadingPortalLandingNewsAction';
import { loadingDefaultAction } from '../actions/loadingDefaultAction';


export const getBlogAction = (id, type) =>
    (dispatch) => {
        dispatch(loadingDefaultAction(true));
        if (type === 'features') {
            getPortalSingleFeatureApi(id)
                .then((response) => {
                    if (!response.error) {
                        dispatch(getPortalLandingBlogAction(response.data));
                    }
                    dispatch(loadingDefaultAction(false));
                    return response;
                })
        }
        else if (type === 'news') {
            getPortalSingleNewsApi(id)
                .then((response) => {
                    if (!response.error) {
                        dispatch(getPortalLandingBlogAction(response.data));
                    }
                    dispatch(loadingDefaultAction(false));
                    return response;
                })
        }

    };

export const getPortalLandingBannerWithThunk = () =>
    (dispatch) => {
        dispatch(loadingPortalLandingBannerAction(true));
        getPortalLandingBannerApi()
            .then((response) => {
                if (!response.error) {
                    dispatch(getPortalLandingBannerAction(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalLandingBannerAction(false));
                return response;
            });
    };

export const getPortalLandingFeatureWithThunk = () =>
    (dispatch) => {
        dispatch(loadingPortalLandingFeatureAction(true));
        getPortalLandingFeatureApi()
            .then((response) => {
                if (!response.error) {
                    dispatch(getPortalLandingFeatureAction(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalLandingFeatureAction(false));
                return response;
            });
    };

export const getPortalLandingNewsWithThunk = () =>
    (dispatch) => {
        dispatch(loadingPortalLandingNewsAction(true));
        getPortalLandingNewsApi()
            .then((response) => {
                if (!response.error) {
                    dispatch(getPortalLandingNewsAction(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalLandingNewsAction(false));
                return response;
            });
    };