import { UPDATE_UNAVAILABLE_CONTEXT } from '../actions/index';

const initialContextstate = '';

export const unavailableContext = (state = initialContextstate, action) => {
    const { unavailableContext } = action

    switch (action.type) {
        case UPDATE_UNAVAILABLE_CONTEXT:
            return unavailableContext       
        default:
            return state;
    }
};