import environment from 'environment';

const config = require('../../environments/' + environment.settings);
const axios = require('axios');

async function getWorkspaceCategoryById(workspaceId, categoryId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/application/categories/" + categoryId);

    return results;
}

async function getWorkspaceCategoryIdByName(workspaceId, categoryName) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/application/categories/id/" + categoryName);

    return results;
}

async function getWorkspaceApplicationCategoryList(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/application/categories/list?pageIndex=1&pageSize=10000");

    return results;
}

async function createWorkspaceCategory(workspaceId, categories) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const results = await axios.post(commandBaseUrl + "workspace/" + workspaceId + "/application/categories", categories)
        .catch(function (error) { return error.response });
    return results;
}

async function updateWorkspaceCategory(workspaceId, categories) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const results = await axios.put(commandBaseUrl + "workspace/" + workspaceId + "/application/categories", categories)
        .catch(function (error) { return error.response });
    return results;
}

async function updateOrderWorkspacesCategories(workspaceId, categoryOrders) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const results = await axios.post(commandBaseUrl + "workspace/" + workspaceId + "/application/categories/reorder", categoryOrders)
        .catch(function (error) { return error.response });
    return results;
}

async function deleteWorkspaceCategory(workspaceId, categoryId) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const results = await axios.delete(commandBaseUrl + "workspace/" + workspaceId + "/application/categories/" + categoryId)
        .catch(function (error) { return error.response });
    return results;
}

export {
    getWorkspaceCategoryById,
    getWorkspaceCategoryIdByName,
    getWorkspaceApplicationCategoryList,
    updateWorkspaceCategory,
    updateOrderWorkspacesCategories,
    createWorkspaceCategory,
    deleteWorkspaceCategory
}