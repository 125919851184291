import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearWorkspacesAction } from '../../../actions/clearWorkspacesAction';
import { clearWorkspacesPaginationAction } from '../../../actions/clearWorkspacesPaginationAction';
import { getWorkspacesWithThunk, getWorkspacesSearchWithThunk } from '../../../api/workspacesThunk';
import { changeNavigationAction } from '../../../actions/changeNavigationAction';
import { head, itemHeight, tHead, defaultWorkspacesPageSize } from '../../../configurations/app';
import SidebarContainer from '../../Sidebar/SidebarContainer';
import WorkspacesListHeader from './WorkspacesListHeader';
import WorkspacesListBody from './WorkspacesListBody';
import WorkspacesModal from '../Modal/WorkspacesModal'
import { getWorkspaceHeaders } from '../../../utils/workspaces';

class WorkspacesListContainer extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleGridResize = this.handleGridResize.bind(this);
        this.getWorkspaceList = this.getWorkspaceList.bind(this);
        this.updateMode = this.updateMode.bind(this);
        this.getPagination = this.getPagination.bind(this);
    }

    static propTypes = {
        workspaces: PropTypes.array.isRequired,
        getWorkspaces: PropTypes.func.isRequired,
        clearWorkspaces: PropTypes.func.isRequired,
        clearWorkspacesPagination: PropTypes.func.isRequired,
        navigation: PropTypes.object.isRequired,
        dispatchNavigation: PropTypes.func.isRequired
    };

    state = {
        page: 1,
        size: 0,
        widthLimit: 0,
        mode: 'list'
    }

    changeNavigation(name) {
        let navigation = {
            name
        };
        this.props.dispatchNavigation(navigation);
    }

    calculatePageSize() {
        let size = defaultWorkspacesPageSize;

        this.setState({ page: 1, size });
        return size;
    }

    getWorkspaceList(currentPage) {
        const { sort_by, sort_type } = this.props.sort
        const { sort, search } = this.props;
        const { page, size, mode } = this.state;

        if (page < currentPage) {
            if (mode === 'list') {
                this.props.getWorkspaces(currentPage, size, sort_by, sort_type);
            }
            else {
                let currentSortBy = sort.sort_by;
                let currentSortType = sort.sort_type
                this.props.getWorkspacesSearch(search.search_text, currentPage, size, currentSortBy, currentSortType);
            }
            this.setState({ page: currentPage });
        }

        this.handleResize();
    }

    handleScroll() {
        const { workspaces } = this.props;

        let { size } = this.state;
        let top = document.documentElement.scrollTop;
        let height = document.documentElement.scrollHeight;
        let h = itemHeight;
        if (workspaces.length > 0) {
            const workspacesListId = document.getElementById('workspaces-list-id-' + workspaces[0].id);
            if (workspacesListId !== null) {
                h = workspacesListId.offsetHeight;
            }
        }

        let page = (height - head - tHead) / (size * h);
        page = (Math.abs(page - Math.floor(page)) > 0.2) ? Math.ceil(page) : Math.floor(page);

        if (Math.ceil(top + window.innerHeight) >= height) {
            page = page + 1;
            this._isMounted && this.getWorkspaceList(page);
        }
    }

    handleResize() {
        const { workspaces } = this.props;

        for (let i in workspaces) {
            let workspace = workspaces[i];
            const workspacesListId = document.getElementById('workspaces-list-id-' + workspace.id);
            const workspacesListStickyAction = document.getElementById('workspaces-list-sticky-action-' + workspace.id);
            if (workspacesListId !== null && workspacesListStickyAction !== null) {
                if (workspacesListId.offsetHeight !== workspacesListStickyAction.offsetHeight) {
                    workspacesListStickyAction.style.height = workspacesListId.offsetHeight + 'px';
                }
            }
        }

        this.handleGridResize();
    }

    handleGridResize() {
        const headers = getWorkspaceHeaders();
        const { workspaces } = this.props;
        const { widthLimit } = this.state;
        const items = workspaces;
        const gridResponsive = document.getElementById('grid-responsive');
        const gridStriped = document.getElementById('grid-striped');

        let limit = (widthLimit === 0) ? gridStriped.offsetWidth : widthLimit;
        if (gridResponsive !== null && gridStriped !== null) {
            if (gridResponsive.offsetWidth < limit) {
                for (let i in headers) {
                    let header = headers[i];
                    if (parseInt(i, 10) !== 0) {
                        const gridHeader = document.getElementById('grid-header-' + header.id);
                        gridHeader.style.display = "none";
                    }
                }
                const gridHeaderProperties = document.getElementById('grid-header-properties');
                gridHeaderProperties.style.display = null;

                for (let i in items) {
                    let item = items[i];
                    const gridRow = document.getElementById('grid-row-' + item.id);
                    for (let j in gridRow.childNodes) {
                        let n = parseInt(j, 10);
                        if (n <= headers.length - 1 && n !== 0) {
                            let node = gridRow.childNodes[j];
                            node.style.display = "none";
                        }
                    }
                    const gridItemroperties = document.getElementById('grid-item-properties-' + item.id);
                    gridItemroperties.style.display = null;
                }

                this.setState({ widthLimit: limit });
            }
            else {
                for (let i in headers) {
                    let header = headers[i];
                    if (parseInt(i, 10) !== 0) {
                        const gridHeader = document.getElementById('grid-header-' + header.id);
                        gridHeader.style.display = null;
                    }
                }
                const gridHeaderProperties = document.getElementById('grid-header-properties');
                gridHeaderProperties.style.display = "none";

                for (let i in items) {
                    let item = items[i];
                    const gridRow = document.getElementById('grid-row-' + item.id);
                    for (let j in gridRow.childNodes) {
                        let n = parseInt(j, 10);
                        if (n <= headers.length - 1 && n !== 0) {
                            let node = gridRow.childNodes[j];
                            node.style.display = null;
                        }
                    }
                    const gridItemroperties = document.getElementById('grid-item-properties-' + item.id);
                    gridItemroperties.style.display = "none";
                }
                this.setState({ widthLimit: 0 });
            }
        }
    }

    updateMode(page, mode) {
        this.setState({ page, mode });
    }

    getPagination() {
        const { page, size } = this.state;
        return {
            page,
            size
        }
    }

    componentDidMount() {
        this._isMounted = true;
        const { sort_by, sort_type } = this.props.sort
        const size = this.calculatePageSize();

        this.props.getWorkspaces(1, size, sort_by, sort_type);
        this.changeNavigation('Workspaces');
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("resize", this.handleResize);
        this.setState({ page: 1, size, mode: 'list' });
        setTimeout(() => {
            this.handleResize();
        }, 1000);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.workspaces !== this.props.workspaces && this.props.workspaces.length > 0) {
            setTimeout(() => {
                this.handleResize();
            }, 200);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.clearWorkspaces();
        this.props.clearWorkspacesPagination();
        this.setState({ page: 1, size: 0, mode: 'list' });
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener("resize", this.handleResize);
    }

    render() {
        return (
            <div>
                <SidebarContainer />
                <main>
                    <div className="container-fluid h-100">
                        <div className="mcp">
                            <WorkspacesListHeader updateMode={this.updateMode} />
                            <WorkspacesListBody pagination={this.getPagination} />
                        </div>
                    </div>
                </main>
                <WorkspacesModal type="list" pagination={this.getPagination} />
            </div>
        );
    }
}

const mapStateToProps = ({ workspaces, navigation, sorting, search}) => ({
    workspaces,
    navigation,
    sort: sorting.workspace,
    search: search.workspaces
});

const mapDispatchToProps = (dispatch) => ({
    getWorkspaces: (pageIndex, pageSize, sortingBy, sortingType) => dispatch(getWorkspacesWithThunk(pageIndex, pageSize, sortingBy, sortingType)),
    getWorkspacesSearch: (keyword, pageIndex, pageSize, sortingBy, sortingType) => dispatch(getWorkspacesSearchWithThunk(keyword, pageIndex, pageSize, sortingBy, sortingType)),
    clearWorkspaces: () => dispatch(clearWorkspacesAction([])),
    clearWorkspacesPagination: () => dispatch(clearWorkspacesPaginationAction([])),
    dispatchNavigation: (navigation) => dispatch(changeNavigationAction(navigation))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesListContainer));
