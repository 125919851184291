import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import WorkspacesApplicationsSettingsCardContainer from './WorkspacesApplicationsUserSettingsCardContainer';
import { updateWorkspacesApplicationsUsersSettingsWithThunk } from '../../../../api/workspacesApplicationsSettingsThunk';
import { updateRoleAction } from '../../../../actions/updateRoleAction';
import { showSwitchButton } from '../../../../configurations/app';

class WorkspacesApplicationsSettingsBody extends Component {
    constructor(props) {
        super(props);
        this.saveSettings = this.saveSettings.bind(this);
        this.switchUserMode = this.switchUserMode.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        workspaceApplication: PropTypes.object.isRequired,
        workspacesApplicationsUsersSettings: PropTypes.array.isRequired,
        updateWorkspacesApplicationsSettings: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired,
        dispatchRole: PropTypes.func.isRequired
    };

    switchUserMode(e) {
        const userRole = (this.props.role.user_role === 'Admin') ? 'Owner' : 'Admin';
        const role = {
            ...this.props.role,
            user_role: userRole
        }

        this.props.dispatchRole(role);
    }

    getAspectRatioClass() {
        const { image_aspect_ratio } = this.props.workspaceApplication;

        let aspectRatioClass = 'crop-logo43';
        if (image_aspect_ratio >= 1 && image_aspect_ratio < 1.2) {
            aspectRatioClass = 'crop-logo11';
        }
        else if (image_aspect_ratio >= 1.2 && image_aspect_ratio < 1.4) {
            aspectRatioClass = 'crop-logo43';
        }
        else if (image_aspect_ratio >= 1.4 && image_aspect_ratio < 1.6) {
            aspectRatioClass = 'crop-logo32';
        }
        else if (image_aspect_ratio >= 1.6 && image_aspect_ratio < 2.3) {
            aspectRatioClass = 'crop-logo169';
        }
        else if (image_aspect_ratio >= 2.3) {
            aspectRatioClass = 'crop-logo219';
        }

        return aspectRatioClass;
    }

    saveSettings() {
        const { workspace, workspaceApplication, workspacesApplicationsSettings, defaultApplicationsSettings } = this.props;
        const { userId } = this.props.match.params;
        let updatedAppSettings = [];
        for (let i = 0; i < defaultApplicationsSettings.length; i++) {
            const defSetting = defaultApplicationsSettings[i];
            const uSett = workspacesApplicationsSettings.filter(userSett => userSett.id === defSetting.id);
            if(uSett[0].value !== defSetting.value) {
                updatedAppSettings.push(uSett[0])
            }
        }
        this.props.updateWorkspacesApplicationsSettings(workspace.id, workspaceApplication.application_id, userId, updatedAppSettings);
    }

    render() {
        const { workspacesApplicationsSettings, role, user } = this.props;
        const groups = [...new Set(workspacesApplicationsSettings.map(item => item.group_name))];
        if (groups.length === 0)
            return (null);

        return (
            <div className="mainBody">
                <div className="row mt-5">
                    <div className="col-md-7 order-md-1 col-lg-8 col-xl-9">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card-columns">
                                        {groups.map((group, index) => (
                                            <WorkspacesApplicationsSettingsCardContainer key={index} header={group} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4 col-xl-3">
                        <div className="wsPhoto text-center">
                            <div className="photoContainer userPhoto mtMin text-center mb-5 d-none d-lg-block">
                                <img src={process.env.PUBLIC_URL + "/images/transCircle.png"} className="insideCircle" alt="" />
                                <img src={((user.picture !== undefined) ? "data:image/jpeg;base64," : "") + user.picture} className="photo" alt="" />
                            </div>
                            <button className="btn mt-5 textBtn w-75 greenBtn" onClick={this.saveSettings}>Save Settings</button>
                        </div>
                    </div>
                </div>
                {showSwitchButton &&
                    <button className="btn customBtn" onClick={() => this.switchUserMode()}>{(role.user_role === 'Admin') ? 'Owner' : 'Admin'} Mode</button>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, workspacesApplicationsUsersSettings, role, workspacesApplicationsSettings, defaultApplicationsSettings }) => ({
    workspace: detail.workspace,
    workspaceApplication: detail.workspace_application,
    workspacesApplicationsUsersSettings,
    role,
    workspacesApplicationsSettings,
    user: detail.user,
    defaultApplicationsSettings
});

const mapDispatchToProps = (dispatch) => ({
    updateWorkspacesApplicationsSettings: (workspaceId, applicationId, userId, settings) => dispatch(updateWorkspacesApplicationsUsersSettingsWithThunk(workspaceId, applicationId, userId, settings)),
    dispatchRole: (role) => dispatch(updateRoleAction(role))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsSettingsBody));