import environment from 'environment';
import { getImageSize } from '../../utils/snapshot';
import { getDefaultPersonImage, getThumbnailDefaultImage, getDefaultPhotoPicture } from '../../utils/image';
import { saveSessionStorage } from '../../utils/sessionStorage';
import { getGlobalWorkspacesMembersAction } from '../../actions/getGlobalWorkspacesMembersAction';
import { decode } from '../../utils/jwtToken';
import { store } from '../../index';

const usersApi = require('../api/users');
const workspacesApi = require('../api/workspaces');
const workspacesMembers = require('./workspacesMembers');
const moment = require('moment');
const sort = require('fast-sort');
const config = require('../../environments/' + environment.settings);

export async function getUserNameByIdApi(userId) {
    try {
        const user = await usersApi.getUserById(userId);
        return mapUserNameFrontend(user.data, userId);
    }
    catch (err) {
        if (err.response !== undefined) {
            return mapToEmptyUserFrontend(err.response.status.toString());
        }
        return mapToEmptyUserFrontend("500");
    }
}

export async function getUserIdByNameApi(userName) {
    try {
        const user = await usersApi.getUserByName(userName);
        return mapUserIdFrontend(user.data, userName);
    }
    catch (err) {
        if (err.response !== undefined) {
            return mapToEmptyUserFrontend(err.response.status.toString());
        }
        return mapToEmptyUserFrontend("500");
    }
}

export async function getUsersApi(pageIndex, pageSize, sortingBy, sortingType) {
    try {
        const results = await usersApi.getUserHeaderList(pageIndex, pageSize, sortingBy, sortingType);
        return mapToUsersFrontend(results.data);
    }
    catch (err) {
        if (err.response.status === 404) {
            return mapToEmptyUsersFrontend();
        }
    }
}

export async function getUsersSearchApi(pageIndex, pageSize, SearchKeyword, sortingBy, sortingType) {
    try {
        const results = await usersApi.getUserSearchList(pageIndex, pageSize, SearchKeyword, sortingBy, sortingType);
        return mapToUsersFrontend(results.data);
    }
    catch (err) {
        if (err.response.status === 404) {
            return mapToEmptyUsersFrontend();
        }
    }
}

export async function getUserByIdApi(userId, withPicture, width, height) {
    const results = await usersApi.getUserById(userId);
    return mapToUserFrontend(userId, results.data, withPicture, width, height);
}

export async function getUserAccessByIdApi(userId, withPicture, width, height) {
    const results = await usersApi.getUserAccessById(userId);
    return mapToUserAccessByIdFrontend(userId, results.data, withPicture, width, height);
}

export async function getUserAvailableWorkspacesAllApi(userId) {
    try {
        const wsIds = await usersApi.getUserAvailabeWorkspaces(userId);
        const results = await usersApi.getUserAvailabeWorkspacesDetail(wsIds.data);
        return mapToUserWorkspacesAllFrontend(userId, wsIds.data, results);
    }
    catch (err) {
        if (err.response.status === 404) {
            return mapToEmptyUserWorkspacesAllFrontend(userId);
        }
    }
}

export async function getUserAvailableWorkspacesApi(userId, userRole, pageIndex, pageSize) {
    if (userRole === 'Admin') {
        const results = await workspacesApi.getWorkspaceList(pageIndex, pageSize, 'Name', 'Ascending');
        return mapToUserWorkspacesFrontendAdminMode(userId, results.data);
    }
    else {
        try {
            const wsIds = await usersApi.getUserAvailabeWorkspaces(userId);
            const results = await usersApi.getUserAvailabeWorkspacesDetail(wsIds.data, pageIndex, pageSize);
            let availableWorkspaces = mapToUserWorkspacesFrontend(userId, wsIds.data, results);

            const availableWorkspacesJson = JSON.stringify(availableWorkspaces);
            await saveSessionStorage("allAvailableWorkspaces", availableWorkspacesJson);

            return availableWorkspaces;
        }
        catch (err) {
            if (err.response.status === 404) {
                return mapToEmptyUserWorkspacesFrontend(userId);
            }
        }
    }
}

export async function getUserAvailableWorkspacesSearchApi(userId, userRole, pageIndex, pageSize, keyword) {
    if (userRole === 'Admin') {
        const results = await workspacesApi.getWorkspaceSearchList(keyword, pageIndex, pageSize, 'Name', 'Ascending');
        return mapToUserWorkspacesFrontendAdminMode(userId, results.data);
    } else {
        const wsIds = await usersApi.getUserAvailabeWorkspaces(userId);
        const results = await usersApi.getUserAvailabeWorkspacesDetail(wsIds.data, pageIndex, pageSize, keyword);
        return mapToUserWorkspacesFrontend(userId, wsIds.data, results);
    }
}

export async function checkUserAvailableApplicationApi(userId, workspaceId, appId) {
    let appAccess = false;
    const userApplications = await usersApi.getUserAccessWorkspaceApplications(workspaceId, userId);
    if (userApplications.status === 200 && userApplications.data.length > 0) {
        let userApps = userApplications.data.filter(x => x.applicationId === appId);
        if (userApps.length > 0) {
            appAccess = true;
        }
    }
    return appAccess;
}

export async function getUserInitialApi(userId, width, height) {
    let user;
    let picture = await usersApi.getUserPhoto(userId, width, height);
    if (picture === undefined) {
        let results = await usersApi.getUserNameById(userId);
        if (results.data !== undefined) {
            user = {
                picture: (results.data.fullName !== undefined && results.data.fullName === "") ? getDefaultPhotoPicture() : "",
                initial: (results.data.fullName !== undefined && results.data.fullName !== "") ? getInitials(results.data.fullName) : ""
            }
        }
    }
    else {
        user = {
            picture,
            initial:''
        }
    }

    return user;
}

export async function getGlobalUsersApi(workspaceId, pageIndex, pageSize, sortingBy, sortingType) {
    const dataUsers = await getUsersApi(pageIndex, pageSize, sortingBy, sortingType);

    const globalWorkspacesMembers = store.getState().globalWorkspacesMembers;
    let dataWorkspaceMembers = (globalWorkspacesMembers.length === 0) ? await workspacesMembers.getGlobalWorkspaceMemberListApi(workspaceId) : globalWorkspacesMembers;

    let members = [];
    if (globalWorkspacesMembers.length === 0) {
        members = dataWorkspaceMembers.members;
        store.dispatch(getGlobalWorkspacesMembersAction(dataWorkspaceMembers.members));
    }
    else {
        members = dataWorkspaceMembers;
    }

    return getUsers(dataUsers.users, members, dataUsers.pagination);
}

export async function getGlobalUsersSearchApi(workspaceId, pageIndex, pageSize, SearchKeyword, sortingBy, sortingType) {
    const dataUsers = await getUsersSearchApi(pageIndex, pageSize, SearchKeyword, sortingBy, sortingType);

    const globalWorkspacesMembers = store.getState().globalWorkspacesMembers;
    let dataWorkspaceMembers = (globalWorkspacesMembers.length === 0) ? await workspacesMembers.getGlobalWorkspaceMemberListApi(workspaceId) : globalWorkspacesMembers;

    let members = [];
    if (globalWorkspacesMembers.length === 0) {
        members = dataWorkspaceMembers.members;
        store.dispatch(getGlobalWorkspacesMembersAction(dataWorkspaceMembers.members));
    }
    else {
        members = dataWorkspaceMembers;
    }

    return getUsers(dataUsers.users, members, dataUsers.pagination);
}

export async function createUserApi(user) {
    const mappingUser = mapToUserBackendForCreate(user);
    const results = await usersApi.createUser(mappingUser, true);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        if (results.data !== undefined) {
            let resultsArray = results.data.split("/");
            const id = resultsArray[0].replace("id:", "");
            if (user.is_admin) {
                await updateUserRoleApi(id, "Administrator");
            }
            await updateUserPhotoApi(id, user.picture);
        }
        id = results.data
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

export async function addGlobalUsersApi(accessToken, workspaceId, users) {
    let members = {
        users
    };
    const results = await workspacesMembers.addWorkspaceMembersApi(accessToken, workspaceId, members);

    const data = {
        id: workspaceId,
        data: results.data
    }

    return data;
}

export async function updateUserApi(user) {
    const mappingUser = mapToUserBackendForUpdate(user);
    const results = await usersApi.updateUser(mappingUser);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        if (results.data !== undefined) {
            if (user.is_picture_changed) {
                await updateUserPhotoApi(user.uid, user.picture);
            }
        }
        id = results.data
    }
    else if (results.status === 500) {
        error_messages = results.data;
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

export async function updateUserPhotoApi(userId, content) {
    return await usersApi.updatePhoto(userId, content);
}

export async function updateUserChangePasswordApi(userId, passwords) {
    let mappingAccount = await mapToChangePasswordBackendForUpdate(passwords);
    const results = await usersApi.updateUserChangePassword(userId, mappingAccount);
    let error_messages = '';

    if (results.status !== 200) {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }

    const data = {
        status: results.status,
        message: error_messages
    }

    return data;
}

export async function removeUserAccesApis(id, dataRemove) {
    const results = await usersApi.removeUserAccess(id, dataRemove.workspaceId);
    return results;
}

export async function updateUserStatusApi(id, status) {
    const results = await usersApi.updateUserStatus(id, mapUserStatusBackend(status));
    const data = {
        status: results.data
    }
    return data;
}

export async function updateUserRoleApi(id, role) {
    const results = await usersApi.updateUserRole(id, mapUserRoleBackend(role));
    const data = {
        role: results.data
    }
    return data;
}

export async function removeUserApi(id) {
    const results = await usersApi.removeUser(id);
    const data = {
        id: results.data
    }
    return data;
}

export async function userLoginConfirmationApi(userId, userToken, actionType, userPayload) {
    let token = {};
    let user = {};
    let workspaces = {};
    let isLoggedIn = false;
    let isProcessingLogin = false;
    let mappingUserPayload = await mapToUserLoginBackendForCreate(userPayload);
    let error_messages = "";
    let results = {};

    if (actionType === 'ResetPassword') {
        results = await usersApi.resetConfirmation(userId, userToken, mappingUserPayload);
    }
    else {
        results = await usersApi.loginConfirmation(userId, userToken, mappingUserPayload);
    }

    if (results.status === 200) {
        let loginPayload = {
            userName: mappingUserPayload.userName,
            password: mappingUserPayload.newPassword
        };

        const login = await usersApi.login(loginPayload);
        token.accessToken = login.data;
        isLoggedIn = true;
        isProcessingLogin = true;

        const tokenDecode = await decode(token.accessToken.accessToken);
        user = {
            uid: tokenDecode.nameid,
            id: tokenDecode.unique_name,
            user_role: (tokenDecode.role === "Administrator") ? "Admin" : "User"
        }
    }
    else if (results.status === 500) {
        error_messages = results.data;
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }

    const data = {
        user_id: userId,
        user_name: (user.id !== undefined) ? user.id : "",
        user_role: (user.user_role !== undefined) ? user.user_role : "",
        full_name: (user.full_name !== undefined) ? user.full_name : "",
        token: token.accessToken,
        is_logged_in: isLoggedIn,
        is_processing_login: isProcessingLogin,
        workspaces: workspaces,
        error: error_messages
    }

    return data;
}

export async function userLoginApi(userPayload) {
    let token = {};
    let user = {};
    let isLoggedIn = false;
    let isProcessingLogin = false;
    let mappingUserPayload = await mapToUserLoginBackendForCreate(userPayload);
    let error_messages = "";
    let results = {};

    results = await usersApi.login(mappingUserPayload);
    if (results.status >= 400) {
        const data = {
            user_id: '',
            user_name: '',
            user_role: '',
            full_name: '',
            token: '',
            is_logged_in: false,
            is_processing_login: false,
            workspaces: '',
            status: results.status,
            error: (results.status <= 500) ? results.data : "Application service is currently unavailable. Please try again later."
        }

        return data;
    }
    else {
        token.accessToken = results.data;
        isLoggedIn = true;
        isProcessingLogin = true;
    }

    const tokenDecode = await decode(token.accessToken.accessToken);
    user = {
        uid: tokenDecode.nameid,
        id: tokenDecode.unique_name,
        user_role: (tokenDecode.role === "Administrator") ? "Admin" : "User"
    }
    
    const data = {
        user_id: user.uid,
        user_name: (user.id !== undefined) ? user.id : "",
        user_role: (user.user_role !== undefined) ? user.user_role : "",
        full_name: (user.full_name !== undefined) ? user.full_name : "",
        token: token.accessToken,
        is_logged_in: isLoggedIn,
        is_processing_login: isProcessingLogin,
        error: error_messages
    }

    return data;
}

export async function userResetPasswordApi(reset) {
    const confirmationUrl = config.protocol + config.frontendServer + "/" + config.emailConfirmationPage;
    let mappingResetPassword = await mapToResetPasswordBackendForUpdate(reset, confirmationUrl);
    let results = {};
    if (reset.workspaceId !== '' && reset.workspaceId !== undefined) {
        if (reset.admin) {
            results = await usersApi.resetPasswordWorkspaceAdmin(reset.username, reset.workspaceId, mappingResetPassword);
        }
        else {
            let dataParam = {
                userName: reset.username,
                email: reset.email
            }
            let idResults = await usersApi.getUserIdByEmailName(dataParam);
            if (idResults.status === 200) {
                results = await usersApi.resetPasswordWorkspace(idResults.data, reset.workspaceId, mappingResetPassword);
            }
            else {
                return {
                    id: reset.username,
                    status: 404,
                    error_messages: "Username or Email not found"
                }
            }
        }
    }
    else {
        if (reset.admin) {
            results = await usersApi.resetPasswordPortalAdmin(reset.username, mappingResetPassword);
        }
        else {
            results = await usersApi.resetPasswordPortal(mappingResetPassword);
        }
    }

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        id = results.data
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else if (results.status === 400) {
        if (reset.workspaceId !== '' && reset.workspaceId !== undefined) {
            error_messages = 'The user is not member of this workspace';
        }
        else {
            let i = 0;
            for (const value of Object.entries(results.data)) {
                if (i === 0) {
                    error_messages = value[1];
                }
                i += 1;
            }
        }
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }

    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

export async function userResendConfirmationApi(userId, workspaceId) {
    const confirmationUrl = config.protocol + config.frontendServer + "/" + config.emailConfirmationPage;

    let results = {};
    if (workspaceId !== undefined && workspaceId !== '' && workspaceId !== null) {
        const urlParam = {
            confirmationUrl: confirmationUrl + "/workspace"
        }
        results = await usersApi.resendConfirmationWorkspace(userId, workspaceId, urlParam);
    }
    else {
        const urlParam = {
            confirmationUrl: confirmationUrl + "/global"
        }
        results = await usersApi.resendConfirmation(userId, urlParam);
    }

    let error_messages = '';
    if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else if (results.status === 404) {
        error_messages = results.data;
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }

    const data = {
        id: userId,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

export async function sendContactAdministratorsApi(userId, contactAdministrators) {
    const bodyData = {
        subject: contactAdministrators.subject,
        body: contactAdministrators.emailContent
    }
    let results = await usersApi.sendContactAdministrators(userId, bodyData);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        id = userId
    }
    else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else if (results.status === 404) {
        error_messages = results.data;
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }

    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

export async function getUserAccessApi(targetId, width, height) {
    const results = await getUserAccessByIdApi(targetId, true, width, height);
    return results;
}

export async function getProfileAccessApi(userId, width, height) {
    const results = await getUserAccessByIdApi(userId, true, width, height);
    return results;
}

export async function updateUserAccessApi(userAccess) {
    const mappingAccess = mapToUserAccessBackendForUpdate(userAccess);

    let results = {};
    let id = '';
    let error_messages = '';

    if (mappingAccess.workspaces.length > 0) {
        let data = mappingAccess.workspaces;
        results = await usersApi.updateUserAccess(userAccess.user.uid, data);

        if (results.status === 200) {
            id = userAccess.user.uid
            await usersApi.updateUserApplicationAccess(userAccess.user.uid, data);
        }
        else if (results.status === 500) {
            error_messages = 'Internal server error, Please contact administrator.';
        }
        else {
            let i = 0;
            for (const value of Object.entries(results.data)) {
                if (i === 0) {
                    error_messages = value[1];
                }
                i += 1;
            }
        }
    }

    const data = {
        id: id,
        status: (results.status === undefined) ? 200 : results.status,
        error_messages: error_messages
    }

    return data;

}

export async function updateUserWorkspaceAccessApi(userId, dataStatus) {
    const results = await usersApi.updateUserAccessStatus(userId, dataStatus.workspaceId, dataStatus.status);
    return results;
}

export async function removeUserWorkspaceAccessApi(userId, dataRemove) {
    const results = await usersApi.removeUserAccess(userId, dataRemove.workspaceId);
    return results;
}

function getInitials(string) {
    var names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

function mapUserNameFrontend(userData, userId) {
    let results = {};

    let a = userData;

    results.user = {
        id: userId,
        name: a.userName.toLowerCase()
    }

    if (userData === undefined) {
        results.error = "User name is not found.";
    }

    return results;
}

function mapUserIdFrontend(userData, userName) {
    let results = {};

    let a = userData;

    results.user = {
        id: a,
        name: userName
    }

    if (userData === undefined) {
        results.error = "User id is not found.";
    }

    return results;
}

function mapToEmptyUserFrontend(errorStatus) {
    let results = {};

    results.error = errorStatus;

    return results;
}

function mapUserStatusBackend(status) {
    let userStatus = status;

    switch (status) {
        case "Enabled":
            userStatus = "Active"
            break;
        case "Disabled":
            userStatus = "Suspend"
            break;
        default:
            break;
    }

    return userStatus;
}

function mapUserRoleBackend(role) {
    let userRole = role;

    switch (role) {
        case "Admin":
            userRole = "Administrator"
            break;
        case "User":
            userRole = "User"
            break;
        default:
            break;
    }

    return userRole;
}

function mapToUsersFrontend(data) {
    let results = {};
    let users = [];
    for (let i in data.results) {
        let u = data.results[i];
        let user = {
            uid: u.id,
            id: u.userName,
            picture: (u.photo === undefined) ? getDefaultPersonImage() : u.photo,
            full_name: u.fullName,
            registration_status: (u.accountConfirmed) ? "Confirmed" : "Pending",
            user_status: (u.userStatus === "Active") ? "Enabled" : "Disabled",
            user_role: (u.role !== undefined) ? ((u.role === "Administrator") ? "Admin" : "User") : "User",
            email_address: u.email,
            phone: u.phone,
            organization: u.organization
        }
        users.push(user);
    }
    let pagination = {
        current_page: data.currentPage,
        page_size: data.pageSize,
        page_count: data.pageCount,
        start_row_number: data.startRowNumber,
        end_row_number: data.endRowNumber,
        total_count: data.rowCount
    }
    results.users = users;
    results.pagination = pagination;

    return results;
}

function mapToEmptyUsersFrontend() {
    let results = {};

    const pagination = {
        current_page: 1,
        page_size: 0,
        page_count: 1,
        start_row_number: 1,
        end_row_number: 1,
        total_count: 1
    }
    results.users = [];
    results.pagination = pagination;

    return results;
}

async function mapToUserFrontend(userId, data, withPicture, width, height) {
    let u = data;
    u.photo = (withPicture) ? await usersApi.getUserPhoto(userId, width, height) : null;
    let imageProps = (u.photo !== undefined && u.photo !== null) ? getImageSize(u.photo) : {
        'width': 500,
        'height': 500
    };

    let user = {
        uid: userId,
        id: u.userName,
        picture: (u.photo === undefined && u.photo !== null) ? getDefaultPersonImage() : u.photo,
        image_width: imageProps.width,
        image_height: imageProps.height,
        image_aspect_ratio: imageProps.width / imageProps.height,
        full_name: u.fullName,
        registration_status: (u.accountConfirmed) ? "Confirmed" : "Pending",
        user_status: (u.userStatus === "Active") ? "Enabled" : "Disabled",
        user_role: (u.role !== undefined) ? ((u.role === "Administrator") ? "Admin" : "User") : "User",
        use_personal: (u.defaultContact === 'Personal') ? true : false
    }

    let personal = {
        address_1: (u.personalInfo !== undefined) ? ((u.personalInfo.address !== undefined) ? u.personalInfo.address.address1 : "") : "",
        address_2: (u.personalInfo !== undefined) ? ((u.personalInfo.address !== undefined) ? u.personalInfo.address.address2 : "") : "",
        zip_code: (u.personalInfo !== undefined) ? ((u.personalInfo.address !== undefined) ? u.personalInfo.address.zipCode : "") : "",
        country: (u.personalInfo !== undefined) ? ((u.personalInfo.address !== undefined) ? u.personalInfo.address.country : "") : "",
        city: (u.personalInfo !== undefined) ? ((u.personalInfo.address !== undefined) ? u.personalInfo.address.city : "") : "",
        state: (u.personalInfo !== undefined) ? ((u.personalInfo.address !== undefined) ? u.personalInfo.address.state : "") : "",
        phone: (u.personalInfo !== undefined) ? u.personalInfo.phone : "",
        mobile_phone: (u.personalInfo !== undefined) ? u.personalInfo.mobile : "",
        email_address: (u.personalInfo !== undefined) ? u.personalInfo.email : ""
    }

    let publicUser = {
        organization: (u.businessInfo !== undefined) ? u.businessInfo.organization : "",
        att: (u.businessInfo !== undefined) ? u.businessInfo.att : "",
        address_1: (u.businessInfo !== undefined) ? ((u.businessInfo.address !== undefined) ? u.businessInfo.address.address1 : "") : "",
        address_2: (u.businessInfo !== undefined) ? ((u.businessInfo.address !== undefined) ? u.businessInfo.address.address2 : "") : "",
        zip_code: (u.businessInfo !== undefined) ? ((u.businessInfo.address !== undefined) ? u.businessInfo.address.zipCode : "") : "",
        country: (u.businessInfo !== undefined) ? ((u.businessInfo.address !== undefined) ? u.businessInfo.address.country : "") : "",
        city: (u.businessInfo !== undefined) ? ((u.businessInfo.address !== undefined) ? u.businessInfo.address.city : "") : "",
        state: (u.businessInfo !== undefined) ? ((u.businessInfo.address !== undefined) ? u.businessInfo.address.state : "") : "",
        phone: (u.businessInfo !== undefined) ? u.businessInfo.phone : "",
        mobile_phone: (u.businessInfo !== undefined) ? u.businessInfo.mobile : "",
        email_address: (u.businessInfo !== undefined) ? u.businessInfo.email : ""
    }

    user.personal = personal;
    user.public = publicUser;
    
    return user;
}

async function mapToUserAccessByIdFrontend(userId, data, withPicture, width, height) {
    let u = data;
    u.photo = (withPicture) ? await usersApi.getUserPhoto(userId, width, height) : null;
    let imageProps = (u.photo !== undefined && u.photo !== null) ? getImageSize(u.photo) : {
        'width': 500,
        'height': 500
    };

    let user = {
        uid: userId,
        id: u.userName,
        picture: (u.photo === undefined && u.photo !== null) ? getDefaultPersonImage() : u.photo,
        image_width: imageProps.width,
        image_height: imageProps.height,
        image_aspect_ratio: imageProps.width / imageProps.height,
        full_name: u.fullName,
        registration_status: (u.accountConfirmed) ? "Confirmed" : "Pending",
        user_status: (u.userStatus === "Active") ? "Enabled" : "Disabled",
        user_role: (u.role !== undefined) ? ((u.role === "Administrator") ? "Admin" : "User") : "User",
        use_personal: (u.defaultContact === 'Personal') ? true : false
    }

    let workspaces = [];
    for (let i in data.access.workspaces) {
        let w = data.access.workspaces[i];

        let applications = [];
        for (let j in w.applications) {
            let a = w.applications[j];
            let application = {
                id: a.id,
                allow_access: a.access,
                application_image: a.logo,
                application_name: a.name,
                application_title: a.title,
                global_status: (a.portalEnabled) ? "Enabled" : "Disabled",
                keyword: a.keywords,
                status: (a.enabled) ? "Enabled" : "Disabled"
            }
            applications.push(application);
        }

        sort(applications).asc(['application_name']);

        let focusAreas = [];
        for (let j in w.focusAreas) {
            let f = w.focusAreas[j];
            let focusArea = {
                allow_access: f.access,
                id: f.focusAreaId,
                name: f.focusAreaName
            }
            focusAreas.push(focusArea);
        }

        sort(focusAreas).asc(['id']);

        let workspace = {
            id: w.id,
            name: w.name,
            title: w.title,
            status: w.status,
            enabled: w.enabled,
            owner: w.owner,
            suspend: w.suspend,
            edited: false,
            applications,
            focusAreas
        }
        workspaces.push(workspace);
    }

    sort(workspaces).asc(['name', 'id']);

    let result = {
        user,
        access: workspaces
    }

    return result;
}

function mapToUserWorkspacesAllFrontend(userId, wsIdsData, wsDetailRes) {
    let results = {};
    let workspaces = [];

    if (wsDetailRes.status !== 404) {
        let wsDetailData = wsDetailRes.data;
        let wsDetailArray = wsDetailData.results;

        for (let i in wsDetailArray) {
            let ws = wsDetailArray[i];
            let workspace = {
                id: ws.id,
                owner: wsIdsData.filter(item => ws.id === item.workspaceId)[0].owner,
                status: ws.status,
                enabled: ws.enabled,
                suspend: wsIdsData.filter(item => ws.id === item.workspaceId)[0].suspend
            };
            workspaces.push(workspace);
        }
    }
    results.uid = userId;
    results.workspaces = workspaces;

    return results;
}

function mapToEmptyUserWorkspacesFrontend(userId) {
    let results = {};

    const pagination = {
        current_page: 1,
        page_size: 0,
        page_count: 1,
        start_row_number: 1,
        end_row_number: 1,
        total_count: 1
    }
    results.uid = userId;
    results.workspaces = [];
    results.pagination = pagination;

    return results;
}

function mapToEmptyUserWorkspacesAllFrontend(userId) {
    let results = {};
    let workspaces = [];

    results.uid = userId;
    results.workspaces = workspaces;

    return results;
}

function mapToUserWorkspacesFrontendAdminMode(userId, data) {
    let results = {};
    let workspaces = [];

    for (let i in data.results) {
        let w = data.results[i];
        let workspace = {
            id: w.id,
            url_image: (w.logo === undefined) ? getThumbnailDefaultImage() : w.logo,
            workspace_name: w.name,
            workspace_title: w.title,
            created_date: moment(w.createdDate).format("MM-DD-YYYY"),
            due_date: moment(w.dueDate).format("MM-DD-YYYY"),
            status: w.status,
            enabled: w.enabled
        }
        workspaces.push(workspace);
    }
    let pagination = {
        current_page: data.currentPage,
        page_size: data.pageSize,
        page_count: data.pageCount,
        start_row_number: data.startRowNumber,
        end_row_number: data.endRowNumber,
        total_count: data.rowCount
    }
    results.uid = userId;
    results.workspaces = workspaces;
    results.pagination = pagination;

    return results;
}

function mapToUserWorkspacesFrontend(userId, wsIdsData, wsDetailRes) {
    let results = {};
    let workspaces = [];
    let pagination = {};

    if (wsDetailRes.status !== 404) {
        let wsDetailData = wsDetailRes.data;
        let wsDetailArray = wsDetailData.results;

        for (let i in wsDetailArray) {
            let ws = wsDetailArray[i];

            let imageProps = (ws.logo !== undefined) ? getImageSize(ws.logo) : {
                'width': 1200,
                'height': 900
            };
            let workspace = {
                id: ws.id,
                url_image: (ws.logo === undefined) ? getThumbnailDefaultImage() : ws.logo,
                image_width: imageProps.width,
                image_height: imageProps.height,
                image_aspect_ratio: imageProps.width / imageProps.height,
                workspace_name: ws.name,
                workspace_title: ws.title,
                status: ws.status,
                enabled: ws.enabled,
                owner: wsIdsData.filter(item => ws.id === item.workspaceId)[0].owner,
                suspend: wsIdsData.filter(item => ws.id === item.workspaceId)[0].suspend
            };
            workspaces.push(workspace);
        }

        sort(workspaces).asc(['workspace_name', 'id']);

        pagination = {
            current_page: wsDetailData.currentPage,
            page_size: wsDetailData.pageSize,
            page_count: wsDetailData.pageCount,
            start_row_number: wsDetailData.startRowNumber,
            end_row_number: wsDetailData.endRowNumber,
            total_count: wsDetailData.rowCount,
            has_more: ((wsDetailData.rowCount - (wsDetailData.pageSize * wsDetailData.currentPage)) > 0) ? true : false,
            next_page: wsDetailData.currentPage + 1
        };

    }
    results.uid = userId;
    results.workspaces = workspaces;
    results.pagination = pagination;

    return results;
}

function mapToUserAccessBackendForUpdate(data) {
    let result = {};
    let workspaces = [];

    for (let i in data.access) {
        let ws = data.access[i];
        if (ws.edited) {
            let focusAreaAccess = [];
            let applicationAccess = [];
            let workspace = {
                workspaceId: ws.id
            }
            for (let j in ws.focusAreas) {
                let ar = ws.focusAreas[j];
                let area = {
                    focusAreaId: ar.id,
                    access: ar.allow_access
                }
                focusAreaAccess.push(area);
            }
            for (let j in ws.applications) {
                let app = ws.applications[j];
                let application = {
                    applicationId: app.id,
                    access: app.allow_access
                }
                applicationAccess.push(application);
            }
            workspace.focusAreaAccess = focusAreaAccess;
            workspace.applicationAccess = applicationAccess;
            workspaces.push(workspace);
        }
    }
    result.workspaces = workspaces;

    return result;
}


function mapToUserBackendForCreate(data) {
    const profile = {
        fullName: data.full_name,
        email: data.personal.email_address,
        phone: data.personal.phone,
        mobile: data.personal.mobile_phone,
        defaultContact: (data.use_personal) ? 'Personal' : 'Business',
        userStatus: 'Active',
        address: {
            address1: data.personal.address_1,
            address2: data.personal.address_2,
            zipCode: data.personal.zip_code,
            city: data.personal.city,
            country: data.personal.country,
            state: data.personal.state
        },
        businessInfo: {
            organization: data.public.organization,
            email: data.public.email_address,
            phone: data.public.phone,
            mobile: data.public.mobile_phone,
            att: data.public.att,
            address: {
                address1: data.public.address_1,
                address2: data.public.address_2,
                zipCode: data.public.zip_code,
                city: data.public.city,
                country: data.public.country,
                state: data.public.state
            }
        }
    };

    let user = {};
    user.userName = data.user_name;
    user.profile = profile;

    return user;
}

function mapToUserBackendForUpdate(data) {
    let user = {};

    user.id = data.uid;
    user.userName = data.id;
    user.email = data.personal.email_address;
    user.fullName = data.full_name;
    user.gender = 'Unspecified';
    user.phone = data.personal.phone;
    user.mobile = data.personal.mobile_phone;
    user.defaultContact = (data.use_personal === true) ? "Personal" : "Business";
    user.userStatus = (data.user_status === "Enabled") ? "Active" : "Suspend";
    user.role = (data.user_role === "Admin") ? "Administrator" : "User";

    let address = {}
    address.address1 = data.personal.address_1;
    address.address2 = data.personal.address_2;
    address.zipCode = data.personal.zip_code;
    address.country = data.personal.country;
    address.city = data.personal.city;
    address.state = data.personal.state;

    let businessAddress = {}
    businessAddress.address1 = data.public.address_1;
    businessAddress.address2 = data.public.address_2;
    businessAddress.zipCode = data.public.zip_code;
    businessAddress.country = data.public.country;
    businessAddress.city = data.public.city;
    businessAddress.state = data.public.state;

    let businessInfo = {}
    businessInfo.organization = data.public.organization;
    businessInfo.email = data.public.email_address;
    businessInfo.phone = data.public.phone;
    businessInfo.mobile = data.public.mobile_phone;
    businessInfo.att = data.public.att;
    businessInfo.address = businessAddress;

    user.address = address;
    user.businessInfo = businessInfo;

    return user;
}

function mapToChangePasswordBackendForUpdate(data) {
    let passwords = {};

    passwords.oldPassword = data.current_password;
    passwords.newPassword = data.new_password;
    passwords.confirmNewPassword = data.confirmation_new_password;

    return passwords;
}

async function mapToUserLoginBackendForCreate(data) {
    let payload = data;
    let userPayload = {
        userName: payload.user_name,
        password: payload.password
    };

    if (payload.new_password !== undefined &&
        payload.new_password !== null &&
        payload.new_password !== "") {

        const password = payload.password;
        const newPassword = payload.new_password;

        userPayload.oldPassword = password;
        userPayload.newPassword = newPassword;
        userPayload.confirmNewPassword = newPassword;
    }

    return userPayload;
}

function mapToResetPasswordBackendForUpdate(data, confirmUrl) {
    let reset = {};

    reset.username = data.username;
    reset.email = data.email;
    reset.resetUrl = (data.workspaceId !== "") ? confirmUrl + "/workspace" : confirmUrl + "/global";

    return reset;
}

function getUsers(users, members, pagination) {
    const dataUsers = users.filter((u) => !members.find(({ uid }) => u.uid === uid));

    pagination.total_count = dataUsers.length;

    let results = {};
    results.users = dataUsers;
    results.pagination = pagination;

    return results;
}