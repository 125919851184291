import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import ImageEditor from '../../Editor/ImageEditor';
import { modalImageEditorStyle } from '../../../utils/modal';
import { updateUserAction } from '../../../actions/updateUserAction';
import { updateUserWithThunk } from '../../../api/usersThunk';
import { updateUserStatusWithThunk } from '../../../api/usersThunk';
import { registrationReminderWithThunk } from '../../../api/registrationReminderThunk';
import { getCountries } from '../../../utils/country';
import { updateRoleAction } from '../../../actions/updateRoleAction';
import { updateDialogUsersAction } from '../../../actions/updateDialogUsersAction';
import { showSwitchButton } from '../../../configurations/app';
import { getUrlWorkspaceId } from '../../../utils/url';

class UsersDetailBody extends Component {
    constructor(props) {
        super(props);
        this.onToggleIsAdmin = this.onToggleIsAdmin.bind(this);
        this.onCloseImageEditor = this.onCloseImageEditor.bind(this);
        this.onSelectFile = this.onSelectFile.bind(this);
        this.onToggleButton = this.onToggleButton.bind(this);
        this.onTogglePersonal = this.onTogglePersonal.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.state = {
            src: null,
            imageEditorIsOpen: false,
            showAdvanced: false,
            countries: getCountries(),
            currentUserRole: props.role.user_role
        };
        this.onDefaultPhoneChange = this.onDefaultPhoneChange.bind(this);
        this.onDefaultEmailChange = this.onDefaultEmailChange.bind(this);
        this.onDefaultOrganizationChange = this.onDefaultOrganizationChange.bind(this);
        this.onPersonalAddress1Change = this.onPersonalAddress1Change.bind(this);
        this.onPersonalAddress2Change = this.onPersonalAddress2Change.bind(this);
        this.onPersonalZipCodeChange = this.onPersonalZipCodeChange.bind(this);
        this.onPersonalCountryChange = this.onPersonalCountryChange.bind(this);
        this.onPersonalCityChange = this.onPersonalCityChange.bind(this);
        this.onPersonalStateChange = this.onPersonalStateChange.bind(this);
        this.onPersonalPhoneChange = this.onPersonalPhoneChange.bind(this);
        this.onPersonalMobilePhoneChange = this.onPersonalMobilePhoneChange.bind(this);
        this.onPersonalEmailAddressChange = this.onPersonalEmailAddressChange.bind(this);
        this.onPublicOrganizationChange = this.onPublicOrganizationChange.bind(this);
        this.onPublicAttChange = this.onPublicAttChange.bind(this);
        this.onPublicAddress1Change = this.onPublicAddress1Change.bind(this);
        this.onPublicAddress2Change = this.onPublicAddress2Change.bind(this);
        this.onPublicZipCodeChange = this.onPublicZipCodeChange.bind(this);
        this.onPublicCountryChange = this.onPublicCountryChange.bind(this);
        this.onPublicCityChange = this.onPublicCityChange.bind(this);
        this.onPublicStateChange = this.onPublicStateChange.bind(this);
        this.onPublicPhoneChange = this.onPublicPhoneChange.bind(this);
        this.onPublicMobilePhoneChange = this.onPublicMobilePhoneChange.bind(this);
        this.onPublicEmailAddressChange = this.onPublicEmailAddressChange.bind(this);
        this.updateUserDetail = this.updateUserDetail.bind(this);
        this.updateUserStatus = this.updateUserStatus.bind(this);
        this.sendRegistratinReminder = this.sendRegistratinReminder.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.switchUserMode = this.switchUserMode.bind(this);
        this.roleButtonSwitchText = this.roleButtonSwitchText.bind(this);
        this.resetUserPassword = this.resetUserPassword.bind(this);
    }
    detailEnd = React.createRef()

    static propTypes = {
        user: PropTypes.object.isRequired,
        scrollTop: PropTypes.func.isRequired,
        dispatchUser: PropTypes.func.isRequired,
        updateUser: PropTypes.func.isRequired,
        updateUserStatus: PropTypes.func.isRequired,
        dispatchDialogUsers: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired,
        dispatchRole: PropTypes.func.isRequired
    };

    onToggleIsAdmin(e) {
        const user_detail = {
            ...this.props.user,
            user_role: (e.target.checked) ? "Admin" : "User"
        }

        this.props.dispatchUser(user_detail);
    }

    roleButtonSwitchText(roleName) {
        return (roleName === 'Admin') ? 'Owner' : (roleName === 'Owner') ? 'User' : (roleName === 'User') ? 'Admin' : null;
    }

    switchUserMode(switchToRole) {
        const role = {
            ...this.props.role,
            user_role: switchToRole
        }

        this.props.dispatchRole(role);
        this.setState({ currentUserRole: switchToRole });
    }

    async onToggleButton() {
        const status = !this.state.showAdvanced;
        await this.setState({ showAdvanced: status })

        this.scrollToTop();
    }

    onTogglePersonal() {
        const { user } = this.props;        

        const user_detail = {
            ...user,
            use_personal: !user.use_personal
        }

        this.props.dispatchUser(user_detail);
    }

    scrollToTop() {
        this.props.scrollTop();
    }

    scrollToBottom() {
        this.detailEnd.current.scrollIntoView({ behavior: 'smooth' })
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({ src: reader.result, imageEditorIsOpen: true });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onCloseImageEditor(image, aspectRatio) {
        this.setState({ imageEditorIsOpen: false });

        const user = {
            ...this.props.user,
            picture: image,
            image_aspect_ratio: aspectRatio,
            is_picture_changed: true
        };

        this.props.dispatchUser(user);
    }

    onDefaultPhoneChange(e) {
        let user = this.props.user;

        if (user.use_personal) {
            user = {
                ...this.props.user,
                personal: {
                    ...this.props.user.personal,
                    phone: e.target.value
                }
            };
        }
        else {
            user = {
                ...this.props.user,
                public: {
                    ...this.props.user.public,
                    phone: e.target.value
                }
            };
        }


        this.props.dispatchUser(user);
    }

    onDefaultEmailChange(e) {
        let user = this.props.user;

        if (user.use_personal) {
            user = {
                ...this.props.user,
                personal: {
                    ...this.props.user.personal,
                    email_address: e.target.value
                }
            };
        }
        else {
            user = {
                ...this.props.user,
                public: {
                    ...this.props.user.public,
                    email_address: e.target.value
                }
            };
        }


        this.props.dispatchUser(user);
    }

    onDefaultOrganizationChange(e) {
        let user = {
            ...this.props.user,
            public: {
                ...this.props.user.public,
                organization: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPersonalAddress1Change(e) {
        let user = {
            ...this.props.user,
            personal: {
                ...this.props.user.personal,
                address_1: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPersonalAddress2Change(e) {
        let user = {
            ...this.props.user,
            personal: {
                ...this.props.user.personal,
                address_2: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPersonalZipCodeChange(e) {
        let user = {
            ...this.props.user,
            personal: {
                ...this.props.user.personal,
                zip_code: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPersonalCountryChange(e) {
        let user = {
            ...this.props.user,
            personal: {
                ...this.props.user.personal,
                country: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPersonalCityChange(e) {
        let user = {
            ...this.props.user,
            personal: {
                ...this.props.user.personal,
                city: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPersonalStateChange(e) {
        let user = {
            ...this.props.user,
            personal: {
                ...this.props.user.personal,
                state: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPersonalPhoneChange(e) {
        let user = {
            ...this.props.user,
            personal: {
                ...this.props.user.personal,
                phone: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPersonalMobilePhoneChange(e) {
        let user = {
            ...this.props.user,
            personal: {
                ...this.props.user.personal,
                mobile_phone: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPersonalEmailAddressChange(e) {
        let user = {
            ...this.props.user,
            personal: {
                ...this.props.user.personal,
                email_address: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPublicOrganizationChange(e) {
        let user = {
            ...this.props.user,
            public: {
                ...this.props.user.public,
                organization: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPublicAttChange(e) {
        let user = {
            ...this.props.user,
            public: {
                ...this.props.user.public,
                att: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPublicAddress1Change(e) {
        let user = {
            ...this.props.user,
            public: {
                ...this.props.user.public,
                address_1: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPublicAddress2Change(e) {
        let user = {
            ...this.props.user,
            public: {
                ...this.props.user.public,
                address_2: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPublicZipCodeChange(e) {
        let user = {
            ...this.props.user,
            public: {
                ...this.props.user.public,
                zip_code: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPublicCountryChange(e) {
        let user = {
            ...this.props.user,
            public: {
                ...this.props.user.public,
                country: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPublicCityChange(e) {
        let user = {
            ...this.props.user,
            public: {
                ...this.props.user.public,
                city: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPublicStateChange(e) {
        let user = {
            ...this.props.user,
            public: {
                ...this.props.user.public,
                state: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPublicPhoneChange(e) {
        let user = {
            ...this.props.user,
            public: {
                ...this.props.user.public,
                phone: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPublicMobilePhoneChange(e) {
        let user = {
            ...this.props.user,
            public: {
                ...this.props.user.public,
                mobile_phone: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    onPublicEmailAddressChange(e) {
        let user = {
            ...this.props.user,
            public: {
                ...this.props.user.public,
                email_address: e.target.value
            }
        };

        this.props.dispatchUser(user);
    }

    updateUserDetail() {
        const { user } = this.props;

        this.props.updateUser(user);
    }

    updateUserStatus() {
        let user = {
            id: this.props.user.uid,
            name: this.props.user.full_name,
            status: this.props.user.user_status,
            dialog_title: 'Warning',
            dialog_type: (this.props.user.user_status === 'Enabled') ? 'disable' : 'enable',
            show_dialog: (this.props.user.user_status === 'Enabled') ? true : false
        };

        this.props.dispatchDialogUsers(user);

        if (this.props.user.user_status === 'Disabled') {
            let user = {
                ...this.props.user,
                uid: this.props.user.uid,
                user_status: (this.props.user.user_status === 'Enabled') ? 'Disabled' : 'Enabled'
            };

            this.props.updateUserStatus(user);
        }
    }

    resetUserPassword() {
        const workspaceId = this.getCurrentWorkspaceId();
        let user = {
            id: (this.props.user.use_personal) ? this.props.user.personal.email_address : this.props.user.public.email_address,
            uid: this.props.user.uid,
            name: this.props.user.id,
            status: workspaceId,
            dialog_title: 'Confirmation',
            dialog_type: 'reset',
            show_dialog: true
        };

        this.props.dispatchDialogUsers(user);
    }

    getCurrentWorkspaceId() {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    sendRegistratinReminder() {
        const workspaceId = this.getCurrentWorkspaceId();
        const uid = this.props.user.uid;
        const uname = this.props.user.id;
        this.props.registrationRemind(uid, workspaceId, uname);
    }

    removeUser() {
        let users = {
            id: this.props.user.uid,
            name: this.props.user.full_name,
            status: this.props.user.user_status,
            dialog_title: 'Warning',
            dialog_type: 'trash',
            show_dialog: true
        };

        this.props.dispatchDialogUsers(users);
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    render() {
        const { user, workspace, role } = this.props;
        const { src, imageEditorIsOpen, showAdvanced, countries } = this.state;
        const buttonText = this.roleButtonSwitchText(this.state.currentUserRole);

        return (
            <div className="mainBody">
                <div className="row mt-5">
                    <div className="col-md-7 order-md-1 col-lg-8 col-xl-7">
                        <div className="wsHomeSection">
                            <div className="desc">
                                <div className="formWrap">
                                    {(role.user_role === 'Admin') &&
                                        <div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="custom-controls-stacked">
                                                        <label className="custom-control ios-switch">
                                                            <input type="checkbox" onClick={this.onToggleIsAdmin} className="ios-switch-control-input" defaultChecked={(user.user_role === 'Admin') ? true : false} />
                                                            <span className="ios-switch-control-indicator"></span>
                                                            <span className="text">Set as Admin</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div>
                                                    <label className="label-user-detail">Status</label>
                                                    <label className={(user.user_status === "Disabled") ? "label-status status-disabled" : "label-status status-enabled"}>{user.user_status}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {!showAdvanced &&
                                        <div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" required onChange={this.onDefaultPhoneChange} defaultValue={(user.use_personal) ? user.personal.phone : user.public.phone} disabled={role.user_role === 'Owner'} />
                                                        <label>Phone</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" required onChange={this.onDefaultEmailChange} defaultValue={(user.use_personal) ? user.personal.email_address : user.public.email_address} disabled={role.user_role === 'Owner'} />
                                                        <label>Email</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" required onChange={this.onDefaultOrganizationChange} defaultValue={user.public.organization} disabled={role.user_role === 'Owner'} />
                                                        <label>Organization</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {showAdvanced &&
                                        <div>
                                            <p className="subLabel px-3 py-4 mt-5">Private Address</p>
                                            <div className="inputForm">
                                                <input className="form-control" type="text" name="" required onChange={this.onPersonalAddress1Change} defaultValue={user.personal.address_1} disabled={role.user_role === 'Owner'} />
                                                <label>Address 1</label>
                                            </div>
                                            <div className="inputForm">
                                                <input className="form-control" type="text" name="" required onChange={this.onPersonalAddress2Change} defaultValue={user.personal.address_2} disabled={role.user_role === 'Owner'} />
                                                <label>Address 2</label>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPersonalZipCodeChange} required defaultValue={user.personal.zip_code} disabled={role.user_role === 'Owner'} />
                                                        <label>Zip Code</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="inputForm">
                                                        <select className="form-control" defaultValue={user.personal.country} onChange={this.onPersonalCountryChange} >
                                                            {countries.map((country) => (
                                                                <option key={country.code} value={country.code} disabled={role.user_role === 'Owner'}>{country.name}</option>
                                                            ))}
                                                        </select>
                                                        <label>Country</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPersonalCityChange} required defaultValue={user.personal.city} disabled={role.user_role === 'Owner'} />
                                                        <label>City</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPersonalStateChange} required defaultValue={user.personal.state} disabled={role.user_role === 'Owner'} />
                                                        <label>State</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPersonalPhoneChange} required defaultValue={user.personal.phone} disabled={role.user_role === 'Owner'} />
                                                        <label>Phone</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPersonalMobilePhoneChange} required defaultValue={user.personal.mobile_phone} disabled={role.user_role === 'Owner'} />
                                                        <label>Mobile</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 pb-5">
                                                    <div className="inputForm mb-3">
                                                        <input className="form-control" type="text" name="" onChange={this.onPersonalEmailAddressChange} required defaultValue={user.personal.email_address} disabled={role.user_role === 'Owner'} />
                                                        <label>Email</label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" onClick={() => { this.onTogglePersonal() }} className="custom-control-input" name="defaultPersonalEmail" id="defaultPersonalEmail" checked={(user.use_personal) ? true : false} disabled={role.user_role === 'Owner'} />
                                                        <label className="custom-control-label pl-2 orangeText" htmlFor="defaultPersonalEmail">Use private email and phone as default contact</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="subLabel px-3 py-4 mt-5">Business Address</p>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicOrganizationChange} required defaultValue={user.public.organization} disabled={role.user_role === 'Owner'} />
                                                        <label>Organization</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicAttChange} required defaultValue={user.public.att} disabled={role.user_role === 'Owner'} />
                                                        <label>Att</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicAddress1Change} required defaultValue={user.public.address_1} disabled={role.user_role === 'Owner'} />
                                                        <label>Address 1</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicAddress2Change} required defaultValue={user.public.city} disabled={role.user_role === 'Owner'} />
                                                        <label>Address 2</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicZipCodeChange} required defaultValue={user.public.zip_code} disabled={role.user_role === 'Owner'} />
                                                        <label>Zip Code</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="inputForm">
                                                        <select defaultValue={user.public.country} onChange={this.onPublicCountryChange}>
                                                            {countries.map((country) => (
                                                                <option key={country.code} value={country.code} disabled={role.user_role === 'Owner'}>{country.name}</option>
                                                            ))}
                                                        </select>
                                                        <label>Country</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicCityChange} required defaultValue={user.public.city} disabled={role.user_role === 'Owner'} />
                                                        <label>City</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicStateChange} required defaultValue={user.public.state} disabled={role.user_role === 'Owner'} />
                                                        <label>State</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicPhoneChange} required defaultValue={user.public.phone} disabled={role.user_role === 'Owner'} />
                                                        <label>Phone</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="inputForm">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicMobilePhoneChange} required defaultValue={user.public.mobile_phone} disabled={role.user_role === 'Owner'} />
                                                        <label>Mobile</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="inputForm mb-3">
                                                        <input className="form-control" type="text" name="" onChange={this.onPublicEmailAddressChange} required defaultValue={user.public.email_address} disabled={role.user_role === 'Owner'} />
                                                        <label>Email</label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" onClick={() => { this.onTogglePersonal() }} className="custom-control-input" name="defaultPublicEmail" id="defaultPublicEmail" checked={(user.use_personal) ? false : true} disabled={role.user_role === 'Owner'} />
                                                        <label className="custom-control-label pl-2" htmlFor="defaultPublicEmail">Use business email and phone as default contact</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row mt-5">
                                        <div className="col-12 text-center">
                                            <button onClick={() => { this.onToggleButton() }} className="advanceBtn">
                                                <b>Advanced</b>
                                                <span className={(showAdvanced) ? "icon-wrd-sorting-up" : "icon-wrd-sorting-down"}></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-4 col-xl-3">
                        <div className="wsPhoto text-center">
                            <div className="photoContainer userPhoto mtMin text-center mb-4">
                                <img src={process.env.PUBLIC_URL + "/images/transCircle.png"} className="insideCircle" alt="" />
                                <img src={((user.picture !== undefined) ? "data:image/jpeg;base64," : "") + user.picture} className="photo" alt="" />
                            </div>
                            <label className="btn btn-wrd_default icoTextBtn browsePhoto">
                                <input type="file" onChange={this.onSelectFile} />
                                <span className="icon-wrd-folder"></span>
                                Browse
                            </label>
                            <span className="d-block mb-5 font-italic text-greyLight">Browse to change Picture</span>
                            {(role.user_role === 'Admin' || role.user_role === 'User') ?
                                <button className="btn mt-4 textBtn greenBtn w-75" onClick={this.updateUserDetail}>Update Changes</button>
                                : null
                            }
                            {(role.user_role === 'Admin' || (role.user_role === 'User' && workspace.owner)) ?
                                <button className={(user.user_status === 'Enabled') ? 'btn textBtn w-75 redBtn' : 'btn textBtn w-75 greenBtn'}
                                    onClick={() => this.updateUserStatus()}>
                                    {(user.user_status === 'Enabled') ? 'Disable' : 'Enable'}
                                </button>
                                : null
                            }
                            {((role.user_role === 'Admin' || (role.user_role === 'User' && workspace.owner)) && user.registration_status !== 'Pending') ?
                                <button onClick={this.resetUserPassword} className="btn textBtn greenBtn w-75"> Reset Password </button> : null
                            }
                            {(role.user_role === 'Admin' || (role.user_role === 'User' && workspace.owner)) ?
                                <button onClick={() => this.sendRegistratinReminder()} className={(user.registration_status === 'Pending') ? 'btn textBtn greenBtn w-75' : 'btn textBtn greenBtn w-75 hide-registration-status'}>Registration Reminder</button>
                                : null
                            }
                        </div>
                    </div>
                </div>
                {src &&
                    <Modal
                        isOpen={imageEditorIsOpen}
                        style={modalImageEditorStyle}
                        contentLabel="Image Editor"
                    >
                        <ImageEditor srcImage={src} onClose={this.onCloseImageEditor} />
                    </Modal>
                }
                <div ref={this.detailEnd} />
                <ul className="topNavBtn">
                    {showSwitchButton &&
                        <li>
                            <button className="btn" onClick={() => this.switchUserMode(buttonText)}>{buttonText} Mode</button>
                        </li>
                    }
                    {role.user_role === 'Admin' &&
                        <li>
                            <button className="btn redBtn" onClick={this.removeUser}>
                                <span className="icon-wrd-trash"></span>
                                Remove User
                            </button>
                        </li>
                    }
                </ul>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, role }) => ({
    user: detail.user,
    workspace: detail.workspace,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchUser: (user) => dispatch(updateUserAction(user)),
    updateUser: (user) => dispatch(updateUserWithThunk(user)),
    updateUserStatus: (user) => dispatch(updateUserStatusWithThunk(user)),
    registrationRemind: (user, workspaceId, uname) => dispatch(registrationReminderWithThunk(user, workspaceId, uname)),
    dispatchDialogUsers: (dialog) => dispatch(updateDialogUsersAction(dialog)),
    dispatchRole: (role) => dispatch(updateRoleAction(role))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersDetailBody));
