import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import Modal from 'react-modal';
import ReactGA from 'react-ga';
import { updateWorkspaceAction } from '../../../actions/updateWorkspaceAction';
import { updateDialogWorkspacesAction } from '../../../actions/updateDialogWorkspacesAction';
import { updateRoleAction } from '../../../actions/updateRoleAction';
import WorkspacesLandingCategory from './WorkspacesLandingCategory';
import { routeCodes } from '../../../configurations/routes';
import { getWorkspaceByIdWithThunk } from '../../../api/workspacesThunk';
import { defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize } from '../../../configurations/app';
import { getUserAvailableWorkspacesWithThunk } from '../../../api/userAvailableWorkspacesThunk';
import { changeRouteAction } from '../../../actions/changeRouteAction';
import { getDHILogoTransparentImage } from '../../../utils/image';
import { getSessionStorage } from '../../../utils/sessionStorage';
import { updateAvailableWorkspacesOpenedStatus } from '../../../utils/workspaces';

const hexRgb = require('hex-rgb');

class WorkspacesLandingBody extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentUserRole: props.role.user_role,
			wsListOpened: false,
			firstOpen: true,
            diffHeader: 0,
            diffMargin: 0,
            headerHeight: 0,
            zoomLevel: 0,
            scaleSettings: 1
		};
		this.switchUserMode = this.switchUserMode.bind(this);
		this.roleButtonSwitchText = this.roleButtonSwitchText.bind(this);
		this.contactOwners = this.contactOwners.bind(this);
        this.setImage = this.setImage.bind(this);
        this.getDivMainBodyMaskResize = this.getDivMainBodyMaskResize.bind(this);
        this.generateGradientColors = this.generateGradientColors.bind(this);
        this.moveUp = this.moveUp.bind(this);
        this.openUrl = this.openUrl.bind(this);
        this.loadWorkspaceLandingLayout = this.loadWorkspaceLandingLayout.bind(this);
        this.getZoomLevel = this.getZoomLevel.bind(this);

        this.divMainBody = React.createRef();
        this.divMainBodyMask = React.createRef();

		// GA needs
		const workspaceSetting = this.props.workspaceSetting;
		let loc = this.props.location;
		if (workspaceSetting.useGoogleAnalytics) {
			const trackingId = workspaceSetting.googleAnalyticsTrackingId;
			ReactGA.initialize(trackingId);

			loc = '/workspaces' + loc.pathname.split('/workspaces')[1];

			const workspaceName = this.props.workspace.workspace_name;
			const userName = this.props.role.user_name;
			const ipAddress = this.props.clientIp.ipAddress

			let datetime = new Date();
			datetime = datetime.toUTCString(); 

			ReactGA.set({ page: loc });
			ReactGA.pageview(`Landing page of ${workspaceName}`);

			// for event
			ReactGA.event({
				category: `Workspace ${workspaceName} landing page`,
				action: 'Display workspace landing page',
				label: `${workspaceName} - ${ipAddress} - ${userName} - ${datetime}`
			});
		}
	}

	static propTypes = {
		workspace: PropTypes.object.isRequired,
		workspaceSetting: PropTypes.object.isRequired,
		availableApplications: PropTypes.object.isRequired,
		availableWorkspaces: PropTypes.object.isRequired,
		dispatchWorkspace: PropTypes.func.isRequired,
		dispatchDialogWorkspaces: PropTypes.func.isRequired,
		role: PropTypes.object.isRequired,
		dispatchRole: PropTypes.func.isRequired,
		clientIp: PropTypes.object.isRequired
	};

	workspaceEditPage() {
		const { workspace } = this.props;
        this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/properties");
	}

	profilePage() {
		this.props.history.push(routeCodes.PROFILE);
	}

	loginPage() {
		const { workspace } = this.props;
        this.props.history.push(routeCodes.USERS_LOGIN + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+"));
	}

	roleButtonSwitchText(roleName) {
		return (roleName === 'Admin') ? 'User' : 'Admin';
	}

	switchUserMode(switchToRole) {
		const role = {
			...this.props.role,
			user_role: switchToRole
		}

		this.props.dispatchRole(role);
		this.setState({ currentUserRole: switchToRole });
		this.getSwitchAvailableWorkspaces();
	}

	getSwitchAvailableWorkspaces() {
		const { workspace, role } = this.props;
        let userId = role.user_id;
        if (userId === "") {
            const sessionRole = getSessionStorage("role");

            if (sessionRole !== "") {
                const role = JSON.parse(sessionRole);
                userId = role.user_id;
            }
        }
		this.props.getWorkspaceById(workspace.id, userId);
	}

	availableWorkspacesSwitch() {
		this.setState({ wsListOpened: !this.state.wsListOpened });
		this.setState({ firstOpen: false });
		if (this.state.wsListOpened === false) {
			const { role } = this.props;
			this.props.getUserAvailableWorkspaces(role.user_id, role.user_role, defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize);
        }
        else {
            updateAvailableWorkspacesOpenedStatus(false);
        }
	}

	changeRoute(name) {
		let navigation = {
			name
		};
		this.props.dispatchRoute(navigation);
	}

	contactOwners() {
		const { workspace } = this.props;
        this.changeRoute(routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+"));
        this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/contactOwners");
	}

    async setImage() {
        if (this.divMainBody.current === null) {
            return;
        }

        const shading = 0.8;
        const showBorders = false;
        const { categories, userCategories, workspace, role } = this.props;

        let categs = [];
        if (role.is_logged_in) {
            if (role.user_role === 'Admin' || (workspace.owner && !workspace.suspendedUser)) {
                categs = categories;
            }
            else {
                categs = userCategories;
            }
        }
        else {
            categs = userCategories;
        }

        let colors = await this.generateGradientColors();
        let idx = -1;
        let headerMask = document.getElementById("header-mask");
        let noCategMask = document.getElementById("no-categ-mask");
        let noCateg = document.getElementById("no-categ");

        if (headerMask !== null && noCategMask !== null && noCateg !== null) {
            noCateg.style.paddingBottom = '24px';
            noCateg.style.borderColor = "#f7f7f6";

            let categ = document.getElementById("categ-" + categs[0].category_id);
            let diffTop = noCateg.offsetTop - (headerMask.offsetTop + headerMask.offsetHeight);
            let noCategTop = noCateg.offsetTop - (diffTop) - 8;
            let noCategHeight = categ.offsetTop - noCategTop;

            noCategMask.style.top = noCategTop + 'px';
            noCategMask.style.height = noCategHeight + 'px';
            idx = idx + 1;
            let maskColor = hexRgb(colors[idx]);

            noCategMask.style.background = "rgba(" + maskColor.red + "," + maskColor.green + "," + maskColor.blue + "," + shading + ")";;
            noCategMask.style.webkitMaskImage = "linear-gradient(107.76deg, #FFFFFF 67.31%, rgba(255, 255, 255, 0) 98.33%)";
        }
        else {
            if (headerMask !== null && noCategMask !== null && categs.length === 0) {
                let noCateg = document.getElementById("no-categ-all");
                let diffTop = noCateg.offsetTop - (headerMask.offsetTop + headerMask.offsetHeight);
                let noCategTop = noCateg.offsetTop - (diffTop) - 8;
                let noCategHeight = noCateg.offsetHeight + diffTop;

                noCateg.style.borderColor = "#f7f7f6";
                noCategMask.style.top = noCategTop + 'px';
                noCategMask.style.height = noCategHeight + 'px';
                idx = idx + 1;
                let maskColor = hexRgb(colors[idx]);

                noCategMask.style.background = "rgba(" + maskColor.red + "," + maskColor.green + "," + maskColor.blue + "," + shading + ")";;
                noCategMask.style.webkitMaskImage = "linear-gradient(107.76deg, #FFFFFF 67.31%, rgba(255, 255, 255, 0) 98.33%)";
            }
        }

        for (let i in categs) {
            let category = categs[i];
            let categ = document.getElementById("categ-" + category.category_id);
            let btnCateg = document.getElementById("btn-categ-" + category.category_id);

            if (btnCateg !== null && categ !== null && noCateg === null) {
                if (parseInt(i, 10) === 0) {
                    categ.style.paddingTop = '4px';
                }
                btnCateg.style.height = '0px';
            }
            if (categ !== null) {
                categ.style.borderColor = "#f7f7f6";
            }
        }

        for (let i in categs) {
            let category = categs[i];
            let nextCategory = categs[parseInt(i, 10) + 1];
            let nextCategTop;
            if (nextCategory !== undefined) {
                let nextCateg = document.getElementById("categ-" + nextCategory.category_id);
                nextCategTop = nextCateg.offsetTop;
            }
            else {
                nextCategory = document.getElementById("bottom-info");
                nextCategTop = nextCategory.offsetTop;
            }

            let categMask = document.getElementById("categ-mask-" + category.category_id);
            let categ = document.getElementById("categ-" + category.category_id);
            if (categMask !== null && categ !== null) {
                let categTop = categ.offsetTop;
                let categHeight = nextCategTop - categTop;
                if (noCateg !== null) {
                    categMask.style.top = categTop + 'px';
                    categMask.style.height = categHeight + 'px';
                }
                else {
                    if (parseInt(i, 10) === 0) {
                        categMask.style.top = categTop - 44 + 'px';
                        categMask.style.height = categHeight + 44 + 'px';
                        categ.style.color = "#0B4566 !important";
                    }
                    else {
                        categMask.style.top = categTop + 'px';
                        categMask.style.height = categHeight + 'px';
                    }
                }

                idx = idx + 1;
                let maskColor = hexRgb(colors[idx]);

                if (idx % 2 === 0) {
                    categMask.style.background = "rgba(" + maskColor.red + "," + maskColor.green + "," + maskColor.blue + "," + shading + ")";;
                    categMask.style.webkitMaskImage = "linear-gradient(107.76deg, #FFFFFF 74.79%, rgba(255, 255, 255, 0) 98.63%)";
                    categ.style.color = "#0B4566";
                }
                else {
                    categMask.style.background = "rgba(" + maskColor.red + "," + maskColor.green + "," + maskColor.blue + "," + shading + ")";;
                    categMask.style.webkitMaskImage = "linear-gradient(107.76deg, #FFFFFF 63.77%, rgba(255, 255, 255, 0) 98.21%)";
                    categ.style.color = "#FFFFFF";
               }
            }
            setTimeout(() => {
                let containerCateg = document.getElementById("container-categ-" + category.category_id);
                let descCateg = document.getElementById("desc-categ-" + category.category_id);
                let btnCateg = document.getElementById("btn-categ-" + category.category_id);

                if (containerCateg !== null && descCateg !== null && btnCateg !== null) {
                    let btnHeight = containerCateg.offsetHeight - (descCateg.offsetHeight + 65);
                    btnCateg.style.height = btnHeight + 'px';
                }
            }, 200);
        }

        let bottomInfo = document.getElementById("bottom-info");
        let bottomInfoMask = document.getElementById("bottom-info-mask");

        if (bottomInfoMask !== null && bottomInfo !== null) {
            let bottomInfoTop = bottomInfo.offsetTop;

            bottomInfoMask.style.top = bottomInfoTop + 'px';
            bottomInfoMask.style.height = '200px';
            idx = idx + 1;
            let maskColor = hexRgb(colors[idx]);

            bottomInfoMask.style.background = "rgba(" + maskColor.red + "," + maskColor.green + "," + maskColor.blue + ")";;
            bottomInfoMask.style.opacity = "0.8";;
            let fromColor = ",rgba(0,0,0,1)";
            let toColor = fromColor;
            bottomInfoMask.style.webkitMaskImage = "linear-gradient(to right" + fromColor + toColor;

            let top = this.divMainBody.current.offsetTop - 22;
            let diffBody = (this.divMainBody.current.offsetTop + this.divMainBody.current.offsetHeight) - (bottomInfoTop + 200);
            let height = this.divMainBody.current.offsetHeight - diffBody + 22;
            let themeImage = "";
            let img = "url('data:image/png;base64, " + themeImage + "')";
            this.divMainBodyMask.current.style.backgroundImage = img;
            this.divMainBodyMask.current.style.backgroundSize = "100% 100%";
            this.divMainBodyMask.current.style.top = top + 'px';
            this.divMainBodyMask.current.style.height = height + 'px';
        }

        let panelCategory = document.getElementById("panel-category");
        panelCategory.style.zIndex = (showBorders) ? 100 : null;
    }

    getDivMainBodyMaskResize() {
        const { categories, userCategories, workspace, role, workspaceSetting } = this.props;

        if (categories === undefined && userCategories === undefined) {
            return;
        }

        let categs = [];
        if (role.is_logged_in) {
            if (role.user_role === 'Admin' || (workspace.owner && !workspace.suspendedUser)) {
                categs = categories;
            }
            else {
                categs = userCategories;
            }
        }
        else {
            categs = userCategories;
        }

        let headerImage = document.getElementById("header-image");
        let headerMask = document.getElementById("header-mask");
        let fullHeaderWorkspaceLanding = document.getElementById("full-header-workspace-landing");
        let headerWorkspaceLanding = document.getElementById("header-workspace-landing");
        let headerWorkspaceLandingSeparator = document.getElementById("header-workspace-landing-separator");
        let bodyWorkspaceLanding = document.getElementById("body-workspace-landing");

        let headerHeight = 0;
        let devicePixelRatioMargin = this.getDevicePixelRatioMargin();
        let linkMargin = this.getLinkMargin();
        let zoomLevel = this.getZoomLevel();
        let isScaleChange = (window.devicePixelRatio !== 1);
        let isZoomChange = (zoomLevel !== 0);

        if (headerImage !== null && headerMask !== null && fullHeaderWorkspaceLanding !== null && headerWorkspaceLanding !== null && headerWorkspaceLandingSeparator !== null && bodyWorkspaceLanding !== null) {
            if (categs.length > 0) {
                let scaleMargin = (isScaleChange) ? devicePixelRatioMargin : 0;
                let scaleLinkMargin = (isScaleChange) ? linkMargin : 0;

                if (isZoomChange) {
                    scaleMargin = 0;
                    scaleLinkMargin = 0;
                }

                headerHeight = this.state.headerHeight - scaleMargin;
                headerImage.style.height = (421 - scaleMargin) + 'px';
                headerMask.style.height = headerHeight - scaleLinkMargin + 'px';
                fullHeaderWorkspaceLanding.style.height = headerHeight - scaleLinkMargin + 'px';

                let diffMargin = headerHeight - (52 + headerWorkspaceLanding.offsetHeight + this.state.diffHeader);
                headerWorkspaceLanding.style.marginBottom = diffMargin + 'px';

                headerWorkspaceLandingSeparator.style.position = 'absolute';
                headerWorkspaceLandingSeparator.style.top = 98 + headerWorkspaceLanding.offsetHeight + diffMargin + 'px';
            }
            else {
                let diffHeader = this.state.diffHeader;
                let diffMargin = this.state.diffMargin;
                let height = this.state.headerHeight;
                headerHeight = (headerWorkspaceLanding.offsetHeight + diffHeader + diffMargin > height + 8) ? headerWorkspaceLanding.offsetHeight + diffHeader + diffMargin : height + 8;
                headerHeight = headerHeight + 40;
                headerImage.style.height = headerHeight + 'px';
                headerMask.style.height = headerHeight + 'px';
                fullHeaderWorkspaceLanding.style.height = headerHeight + 'px';
            }
        }

        let noCategMask = document.getElementById("no-categ-mask");
        let bodyCateg = document.getElementById("body-categ");
        let noCateg = document.getElementById("no-categ");
        if (headerMask !== null && noCategMask !== null && bodyCateg !== null && noCateg !== null) {
            let categ = document.getElementById("categ-" + categs[0].category_id);
            if (categ !== null) {
                let diffTop = noCateg.offsetTop - (headerMask.offsetTop + headerMask.offsetHeight);
                let noCategTop = noCateg.offsetTop - (diffTop) - 8;
                let noCategHeight = categ.offsetTop - noCategTop;

                noCategMask.style.top = noCategTop + 'px';
                noCategMask.style.height = noCategHeight + 'px';
            }
        }
        else {
            if (headerMask !== null && noCategMask !== null && categs.length === 0) {
                let categ = document.getElementById("bottom-info");
                let noCateg = document.getElementById("no-categ-all");
                let diffTop = bodyCateg.offsetTop - (headerMask.offsetTop + headerHeight);
                let noCategTop = noCateg.offsetTop - (diffTop) - 8;
                let noCategHeight = categ.offsetTop - noCategTop;

                noCategMask.style.top = noCategTop + 'px';
                noCategMask.style.height = noCategHeight + 'px';
            }
        }

        for (let i in categs) {
            let category = categs[i];
            let btnCateg = document.getElementById("btn-categ-" + category.category_id);

            if (btnCateg !== null) {
                btnCateg.style.height = '0px';
            }
        }

        for (let i in categs) {
            let category = categs[i];
            let nextCategory = categs[parseInt(i, 10) + 1];
            let nextCategTop;
            if (nextCategory !== undefined) {
                let nextCateg = document.getElementById("categ-" + nextCategory.category_id);
                if (nextCateg !== null) {
                    nextCategTop = nextCateg.offsetTop;
                }
            }
            else {
                nextCategory = document.getElementById("bottom-info");
                if (nextCategory !== null) {
                    nextCategTop = nextCategory.offsetTop;
                }
            }

            let categMask = document.getElementById("categ-mask-" + category.category_id);
            let categ = document.getElementById("categ-" + category.category_id);
            if (categMask !== null && categ !== null) {
                let categTop = categ.offsetTop;
                let categHeight = nextCategTop - categTop;
                if (noCateg !== null) {
                    categMask.style.top = categTop + 'px';
                    categMask.style.height = categHeight + 'px';
                }
                else {
                    if (parseInt(i, 10) === 0) {
                        categMask.style.top = categTop - 44 + 'px';
                        categMask.style.height = categHeight + 44 + 'px';
                    }
                    else {
                        categMask.style.top = categTop + 'px';
                        categMask.style.height = categHeight + 'px';
                    }
                }
            }
            setTimeout(() => {
                let containerCateg = document.getElementById("container-categ-" + category.category_id);
                let descCateg = document.getElementById("desc-categ-" + category.category_id);
                let btnCateg = document.getElementById("btn-categ-" + category.category_id);

                if (containerCateg !== null && descCateg !== null && btnCateg !== null) {
                    let btnHeight = containerCateg.offsetHeight - (descCateg.offsetHeight + 65);
                    btnCateg.style.height = btnHeight + 'px';
                }
            }, 200);
        }

        let bottomInfo = document.getElementById("bottom-info");
        let bottomInfoMask = document.getElementById("bottom-info-mask");
        let bottomLinkContainer = document.getElementById("bottom-link-container");
        let bottomDHILogo = document.getElementById("bottom-dhi-logo");
        let bottomWorkspaceLogo = document.getElementById("bottom-workspace-logo");

        if (bottomInfoMask !== null && bottomInfo !== null && this.divMainBody.current !== null && this.divMainBodyMask.current !== null) {
            setTimeout(() => {
                let bottomInfoTop = bottomInfo.offsetTop;
                bottomInfoMask.style.top = bottomInfoTop + 'px';
                bottomInfoMask.style.height = '200px';

                let top = this.divMainBody.current.offsetTop - 30;
                let diffBody = (this.divMainBody.current.offsetTop + this.divMainBody.current.offsetHeight) - (bottomInfoTop + 200);
                let height = this.divMainBody.current.offsetHeight - diffBody + 30;
                this.divMainBodyMask.current.style.top = top + 'px';
                this.divMainBodyMask.current.style.height = height + 'px';

                if (bottomLinkContainer !== null && bottomDHILogo !== null && bottomWorkspaceLogo !== null) {
                    let diffBottom = (bottomInfoMask.offsetTop + bottomInfoMask.offsetHeight) - (bottomInfo.offsetTop + bottomInfo.offsetHeight);

                    bottomLinkContainer.style.position = 'absolute';
                    bottomLinkContainer.style.top = (100 - bottomLinkContainer.offsetHeight / 2) + diffBottom + 'px';
                    bottomLinkContainer.style.right = '36px';

                    bottomDHILogo.style.display = (workspaceSetting.showPortalLogo) ? "block" : "none";
                    bottomDHILogo.style.position = 'absolute';
                    bottomDHILogo.style.top = (100 - bottomDHILogo.offsetHeight / 2) + diffBottom + 'px';
                    bottomDHILogo.style.left = '26px';

                    bottomWorkspaceLogo.style.position = 'absolute';
                    bottomWorkspaceLogo.style.top = (100 - bottomWorkspaceLogo.offsetHeight / 2) + diffBottom + 'px';
                    bottomWorkspaceLogo.style.left = (workspaceSetting.showPortalLogo) ? bottomDHILogo.offsetWidth + 25 + 'px' : '36px';
                }
            }, 200);
        }


        let headerPlayerContainer = document.getElementById("header-player-container");
        let headerPlayer = document.getElementById("header-player");
        if (headerPlayerContainer !== null && headerPlayer !== null) {
            setTimeout(() => {
                headerPlayer.style.position = 'absolute';
                headerPlayer.style.width = headerPlayerContainer.offsetWidth * 0.98 + 'px';
                headerPlayer.style.height = (headerPlayerContainer.offsetWidth * 0.98) * (9 / 16) + 'px';
                headerPlayer.style.top = ((headerPlayerContainer.offsetHeight) / 2 - headerPlayer.offsetHeight / 2) + 'px';
                headerPlayer.style.left = (headerPlayerContainer.offsetWidth / 2 - headerPlayerContainer.offsetWidth * 0.98 / 2) - 50 + 'px';
            }, 500);
        }

        setTimeout(() => {
            let zoomLevel = this.getZoomLevel();
            let scaleSettings = window.devicePixelRatio;
            this.setState({ zoomLevel, scaleSettings });
        }, 200);
    }

    async generateGradientColors() {
        const { categories, userCategories, workspace, role } = this.props;

        let categs = [];
        if (role.is_logged_in) {
            if (role.user_role === 'Admin' || (workspace.owner && !workspace.suspendedUser)) {
                categs = categories;
            }
            else {
                categs = userCategories;
            }
        }
        else {
            categs = userCategories;
        }

        let colors = [];

        let noCateg = document.getElementById("no-categ");
        let notCaegory = (noCateg === null) ? 0 : 1;

        let maxIndex = categs.length + notCaegory;
        for (let i = 0; i <= maxIndex - 1; i++) {
            if (i % 2 === 0) {
                let color = "#FFFFFF";
                colors.push(color);
            }
            else if (i % 2 === 1) {
                let color = "#0B4566";
                colors.push(color);
            }
         }
        colors.push("#0B4566");
        return colors;
    }

    moveUp() {
        window.scrollTo({ top: 0 });
    }

    loadWorkspaceLandingLayout() {
        const { categories, userCategories, workspace, role } = this.props;

        if (categories === undefined || userCategories === undefined)
            return;

        let categs = [];
        if (role.is_logged_in) {
            if (role.user_role === 'Admin' || (workspace.owner && !workspace.suspendedUser)) {
                categs = categories;
            }
            else {
                categs = userCategories;
            }
        }
        else {
            categs = userCategories;
        }

        if (categs === undefined)
            return;

        let headerImage = document.getElementById("header-image");
        let headerMask = document.getElementById("header-mask");
        let fullHeaderWorkspaceLanding = document.getElementById("full-header-workspace-landing");
        let headerWorkspaceLanding = document.getElementById("header-workspace-landing");
        let bodyWorkspaceLanding = document.getElementById("body-workspace-landing");

        if (headerImage !== null && headerMask !== null && fullHeaderWorkspaceLanding !== null && headerWorkspaceLanding !== null && bodyWorkspaceLanding !== null && this.divMainBody.current !== null) {
            let diffHeader = 166;
            let height = 0;
            let diffMargin = 0;

            if (categs.length > 0) {
                setTimeout(() => {
                    height = 589;
                    diffMargin = height - (52 + headerWorkspaceLanding.offsetHeight + diffHeader);
                    headerWorkspaceLanding.style.marginBottom = diffMargin + 'px';
                    this.setState({ diffHeader, diffMargin, headerHeight: height });
                }, 200);
            }
            else {
                setTimeout(() => {
                    height = headerWorkspaceLanding.offsetHeight + 190;
                    diffMargin = (height - 30) - headerWorkspaceLanding.offsetHeight;
                    this.setState({ diffMargin });
                }, 200);
            }

            setTimeout(() => {
                headerImage.style.height = height + 'px';
                headerMask.style.height = height + 'px';
                fullHeaderWorkspaceLanding.style.height = height + 'px';

                if (categs.length > 0) {
                    this.setImage();
                }

                let bottomLogoContainer = document.getElementById("bottom-logo-container");
                let bottomDHILogo = document.getElementById("bottom-dhi-logo");
                let bottomWorkspaceLogo = document.getElementById("bottom-workspace-logo");

                if (bottomDHILogo !== null && bottomWorkspaceLogo !== null && bottomLogoContainer !== null) {
                    bottomDHILogo.style.width = bottomLogoContainer.offsetWidth * 0.21 + 'px';
                    bottomDHILogo.style.minWidth = bottomLogoContainer.offsetWidth * 0.17 + 'px';
                    bottomWorkspaceLogo.style.width = bottomLogoContainer.offsetWidth * 0.17 + 'px';
                    bottomWorkspaceLogo.style.minWidth = bottomLogoContainer.offsetWidth * 0.12 + 'px';
                }

                let headerPlayerContainer = document.getElementById("header-player-container");
                let headerPlayer = document.getElementById("header-player");
                let headerReactPlayer = document.getElementById("header-react-player");
                if (headerPlayerContainer !== null && headerPlayer !== null && headerReactPlayer !== null) {
                    headerPlayer.style.position = 'absolute';
                    headerPlayer.style.width = headerPlayerContainer.offsetWidth * 0.98 + 'px';
                    headerPlayer.style.height = (headerPlayerContainer.offsetWidth * 0.98) * (9 / 16) + 'px';
                    headerPlayer.style.top = ((headerPlayerContainer.offsetHeight) / 2 - headerPlayer.offsetHeight / 2) + 'px';
                    headerPlayer.style.left = (headerPlayerContainer.offsetWidth / 2 - headerPlayerContainer.offsetWidth * 0.98 / 2) - 50 + 'px';
                }

                this.getDivMainBodyMaskResize();
            }, 200);
        }
    }

    openUrl(url) {
        if (url.trim() === "") {
            return;
        }

        if (!url.includes("http")) {
            url = "https://" + url;
        }

        const win = window.open(url, "_blank");
        if (win != null) {
            win.focus();
        }
    }

    getDevicePixelRatioMargin() {
        let margin = 0;
        if (window.devicePixelRatio === 1) {
            margin = 0;
        }
        else if (window.devicePixelRatio === 1.25) {
            margin = 90;
        }
        else if (window.devicePixelRatio === 1.5) {
            margin = 110;
        }
        else if (window.devicePixelRatio === 1.75) {
            margin = 130;
        }
        return margin;
    }

    getLinkMargin() {
        let margin = 0;
        if (window.devicePixelRatio === 1) {
            margin = 0;
        }
        else if (window.devicePixelRatio === 1.25) {
            margin = 35;
        }
        else if (window.devicePixelRatio === 1.5) {
            margin = 42;
        }
        else if (window.devicePixelRatio === 1.75) {
            margin = 50;
        }
        return margin;
    }

    getZoomLevel() {
        let zoomLevel = null;
        let screenCssPixelRatio = Math.ceil((window.outerWidth - 8) / window.innerWidth);

        if (screenCssPixelRatio >= .46 && screenCssPixelRatio <= .54) {
            zoomLevel = -4;
        }
        else if (screenCssPixelRatio < .64) {
            zoomLevel = -3;
        }
        else if (screenCssPixelRatio < .76) {
            zoomLevel = -2;
        }
        else if (screenCssPixelRatio < .92) {
            zoomLevel = -1;
        }
        else if (screenCssPixelRatio < 1.10) {
            zoomLevel = 0;
        }
        else if (screenCssPixelRatio < 1.32) {
            zoomLevel = 1;
        }
        else if (screenCssPixelRatio < 1.58) {
            zoomLevel = 2;
        }
        else if (screenCssPixelRatio < 1.90) {
            zoomLevel = 3;
        }
        else if (screenCssPixelRatio < 2.28) {
            zoomLevel = 4;
        }
        else if (screenCssPixelRatio < 2.70) {
            zoomLevel = 5;
        }

        return zoomLevel;
    }

    componentDidMount() {
        window.addEventListener('resize', this.getDivMainBodyMaskResize);
        this.loadWorkspaceLandingLayout();

        setTimeout(() => {
            let zoomLevel = this.getZoomLevel();
            let scaleSettings = window.devicePixelRatio;
            this.setState({ zoomLevel, scaleSettings });
        }, 500);
    }

    componentDidUpdate() {
        const { workspaceSetting } = this.props;

        if (workspaceSetting !== undefined) {
            let bottomInfo = document.getElementById("bottom-info");
            let bottomInfoMask = document.getElementById("bottom-info-mask");
            let bottomLinkContainer = document.getElementById("bottom-link-container");
            let bottomDHILogo = document.getElementById("bottom-dhi-logo");
            let bottomWorkspaceLogo = document.getElementById("bottom-workspace-logo");

            if (bottomInfo !== null && bottomInfoMask !== null && bottomLinkContainer !== null && bottomDHILogo !== null && bottomWorkspaceLogo !== null) {
                setTimeout(() => {
                    let diffBottom = (bottomInfoMask.offsetTop + bottomInfoMask.offsetHeight) - (bottomInfo.offsetTop + bottomInfo.offsetHeight);

                    bottomLinkContainer.style.position = 'absolute';
                    bottomLinkContainer.style.top = (100 - bottomLinkContainer.offsetHeight / 2) + diffBottom + 'px';
                    bottomLinkContainer.style.right = '36px';

                    bottomDHILogo.style.visibility = 'hidden';
                    bottomDHILogo.style.display = (workspaceSetting.showPortalLogo) ? "block" : "none";
                    bottomDHILogo.style.position = 'absolute';
                    bottomDHILogo.style.top = (100 - bottomDHILogo.offsetHeight / 2) + diffBottom + 'px';
                    bottomDHILogo.style.left = '26px';
                    bottomDHILogo.style.visibility = 'visible';

                    bottomWorkspaceLogo.style.visibility = 'hidden';
                    bottomWorkspaceLogo.style.position = 'absolute';
                    bottomWorkspaceLogo.style.top = (100 - bottomWorkspaceLogo.offsetHeight / 2) + diffBottom + 'px';
                    bottomWorkspaceLogo.style.left = (workspaceSetting.showPortalLogo) ? bottomDHILogo.offsetWidth + 25 + 'px' : '36px';
                    bottomWorkspaceLogo.style.visibility = 'visible';
                }, 200);
            }

            let headerWorkspaceLogo = document.getElementById("header-workspace-logo");
            if (headerWorkspaceLogo !== null) {
                headerWorkspaceLogo.style.visibility = (workspaceSetting.showWorkspaceLogo) ? "visible" : "hidden";
            }
        }
    }

    componentWillMount() {
		Modal.setAppElement('body');
    }

	render() {
        const { workspace, workspace_theme, workspaceSetting, categories, userCategories, availableApplications, availableApplicationsUser, role, loading } = this.props;
        const { zoomLevel } = this.state;
        const template = { __html: workspace.workspace_desc };
        const dhiImage = getDHILogoTransparentImage();

        let categs = [];
        if (role.is_logged_in) {
            if (role.user_role === 'Admin' || (workspace.owner && !workspace.suspendedUser)) {
                categs = categories;
            }
            else {
                categs = userCategories;
            }
        }
        else {
            categs = userCategories;
        }

        if (categories === undefined || userCategories === undefined)
            return (null);

        if (categs === undefined)
            return (null);

        if (loading) {
            return (null);
        }

		return (
            <div>
                <div ref={this.divMainBodyMask} style={{ position: 'absolute', width: '100%', left: '0px' }}></div>
                <div id="no-categ-mask" style={{ position: 'absolute', width: '100%', left: '0px' }}></div>
                <div id="bottom-info-mask" style={{ position: 'absolute', width: '100%', left: '0px' }}></div>
                {(categs !== undefined) &&
                    <div>
                        {categs.map((categ) => (
                            <div key={categ.category_id} id={"categ-mask-" + categ.category_id} style={{ position: 'absolute', width: '100%', left: '0px' }}></div>
                        ))}
                    </div>
                }
                <div ref={this.divMainBody} style={{ backgroundImage: "url('data:image/png;base64,')" }} className="mainBody mainbody-margin-top">
                    {(role.user_role !== 'Admin' && workspace.suspendedUser && availableApplicationsUser.length === 0) ?
					    <div className="row mt-5">
						    <div className="col-md-7 order-md-1 col-lg-8 col-xl-12">
							    <div className="wsHomeSection">
								    <div className="desc">
									    <div className="formWrap">
										    <div className="notification text-center py-3 mb-5"><span
											    className="icon-wrd-alert-triangle"></span> You have currently no access. Please
                                            contact the Owner(s) for further inquiries</div>
									    </div>
								    </div>
							    </div>
						    </div>
					    </div>
					    : null
				    }
                    <div id="body-workspace-landing" className="row">
					    <div id="panel-category" className="panel">
						    <div className="container-fluid">
                                <p className={(workspace_theme.theme_selected === "Default") ? "text-color-b" : "text-color-a"}></p>
                                <WorkspacesLandingCategory categories={categories} userCategories={userCategories} availableApplications={availableApplications} availableApplicationsUser={availableApplicationsUser} zoomLevel={zoomLevel} />
						    </div>
					    </div>
				    </div>
                    <div id="bottom-info">
                        {(categs !== undefined && categs.length === 0) &&
                            <div className="row">
                                <div className="col">
                                    <div className="wsHomeSection">
                                        <div className="desc py-2 pr-4">
                                            <p className={(workspace_theme.theme_selected === "Default") ? "text-color-b" : "text-color-a"}>{"About " + workspace.workspace_name}</p>
                                            <div dangerouslySetInnerHTML={template} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {(categs !== undefined && categs.length === 0 && workspace.ownerCount > 0) &&
				            <div className="row">
                                <div className="col col pb-5 text-center" style={{ textDecoration: 'underline' }}>
                                    <Link className={(workspace_theme.theme_selected === "Default") ? "text-color-b" : "text-color-a"} onClick={this.contactOwners} to={routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/contactOwners"}>Contact and support</Link>
					            </div>
				            </div>
                        }
                        {(categs !== undefined && categs.length > 0) &&
                            <div className="row">
                                <div className="col bottom-center-left">
                                    <div id="bottom-logo-container">
                                    <img id="bottom-dhi-logo" src={"data:image/png;base64," + dhiImage} style={{ paddingRight: "30px", visibility: 'hidden', cursor: 'pointer' }} onClick={() => this.openUrl("http://www.dhigroup.com")} title="www.dhigroup.com" alt="" />
                                        {(workspaceSetting.showWorkspaceLogo) &&
                                        <img id="bottom-workspace-logo" onClick={() => this.openUrl(workspace.workspace_url)} src={((workspace.url_image !== undefined) ? "data:image/png;base64," : "") + workspace.url_image} style={{ visibility: 'hidden', cursor: (workspace.workspace_url !== '') ? 'pointer' : 'default' }} title={workspace.workspace_url} alt="" />
                                        }
                                        {(!workspaceSetting.showWorkspaceLogo) &&
                                            <img id="bottom-workspace-logo" src="data:image/png;base64," alt="" />
                                        }
                                    </div>
                                </div>
                                <div className="col bottom-center-right">
                                    <div id="bottom-link-container">
                                        <div><Link className={(zoomLevel === 0) ? "bottom-text font-style-bottomtext" : "bottom-text font-style-bottomtext-zoom"} onClick={() => { this.moveUp() }} to="#">Home</Link></div>
                                        {workspace.ownerCount > 0 &&
                                            <div><Link className={(zoomLevel === 0) ? "bottom-text font-style-bottomtext" : "bottom-text font-style-bottomtext-zoom"} onClick={this.contactOwners} to={routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/contactOwners"}>Contact</Link></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
			    </div>
            </div>
		);
	}
}

const mapStateToProps = ({ detail, availableWorkspaces, availableApplications, role, clientIp, loading }) => ({
	workspace: detail.workspace,
	workspaceSetting: detail.workspace_setting,
	workspace_theme: detail.workspace_theme_setting,
	availableApplications,
    availableApplicationsUser: detail.workspace.user_available_applications,
    categories: detail.workspace.categories,
    userCategories: detail.workspace.user_categories,
	availableWorkspaces,
	role,
    clientIp,
    loading: loading.workspace.loading
});

const mapDispatchToProps = (dispatch) => ({
	dispatchWorkspace: (workspace) => dispatch(updateWorkspaceAction(workspace)),
	dispatchDialogWorkspaces: (dialog) => dispatch(updateDialogWorkspacesAction(dialog)),
	dispatchRole: (role) => dispatch(updateRoleAction(role)),
	getWorkspaceById: (workspaceId, userId) => dispatch(getWorkspaceByIdWithThunk(workspaceId, userId)),
	getUserAvailableWorkspaces: (userId, userRole, pageIndex, pageSize) => dispatch(getUserAvailableWorkspacesWithThunk(userId, userRole, pageIndex, pageSize)),
	dispatchRoute: (navigation) => dispatch(changeRouteAction(navigation)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesLandingBody));