import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { routeCodes } from '../../../configurations/routes';
import { setupNewApplicationAction } from '../../../actions/setupNewApplicationAction'
import { createApplicationWithThunk } from '../../../api/applicationsThunk';

class ApplicationsCreateAction extends Component {
    constructor(props) {
        super(props);
        this.openApplicationsList = this.openApplicationsList.bind(this);
        this.createNewApplication = this.createNewApplication.bind(this);
    }

    static propTypes = {
        application: PropTypes.object.isRequired,
        setupNewApplication: PropTypes.func.isRequired,
        createApplication: PropTypes.func.isRequired
    };

    openApplicationsList() {
        this.props.history.push(routeCodes.APPLICATIONS_LIST);
    }

    createNewApplication() {
        const { application } = this.props;
        this.props.createApplication(application, false);        
    }

    render() {
        return (
            <div className="col-md-12">
                <div className="inputForm buttonGroup mt-2 text-center">
                    <button className="btn greyBtn icoTextBtn mx-2" onClick={this.openApplicationsList}><span className="icon-wrd-close"></span>Cancel</button>
                    <button className="btn greenBtn icoTextBtn mx-2" onClick={this.createNewApplication}><span className="icon-wrd-add"></span> Create Application</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ setup }) => ({
    application: setup.application
});

const mapDispatchToProps = (dispatch) => ({
    setupNewApplication: (application) => dispatch(setupNewApplicationAction(application)),
    createApplication: (application, bypassUrl) => dispatch(createApplicationWithThunk(application, bypassUrl))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationsCreateAction));