import { store } from '../index';
import sortBy from 'sort-by';
import { getWorkspaceCategoryIdByNameApi } from '../backend/domain/workspacesCategories';
import { getCategoryListApi } from '../backend/domain/categories';
import { clearWorkspacesCategoriesAction } from '../actions/clearWorkspacesCategoriesAction';
import { clearWorkspacesApplicationsAction } from '../actions/clearWorkspacesApplicationsAction';
import { sortWorkspacesApplications } from './workspacesApplications';

const sort = require('fast-sort');

export const getWorkspaceCategoryIdByName = async (workspaceId, workspaceCategoryName) => {
    let result = await getWorkspaceCategoryIdByNameApi(workspaceId, workspaceCategoryName);
    return result;
}

export const mergeWorkspacesApplicationsCategories = async (categories, applications) => {
    let workspaceCategories = [];
    let applicationCategories = [];

    let categs = [];
    let categoryApps = applications.filter((item) => item.category_title !== "" && !item.is_linked);
    for (let i in categoryApps) {
        let categoryApp = categoryApps[i];
        if (!categs.includes(categoryApp.category_title)) {
            let isInCategory = false;
            for (let j in categories) {
                let category = categories[j];
                if (category.category_title === categoryApp.category_title) {
                    isInCategory = true;
                    break;
                }
            }
            if (!isInCategory) {
                categs.push(categoryApp.category_title);
                applicationCategories.push({
                    category_id: categoryApp.category_id,
                    category_title: categoryApp.category_title,
                    category_description: categoryApp.category_description
                })
            }
        }
    }

    let appCategories = await getCategoryListApi();
    if (appCategories.length > 0) {
        for (let i in appCategories) {
            let appCategory = appCategories[i];
            let isExists = false;
            for (let j in applicationCategories) {
                let applicationCategory = applicationCategories[j];
                if (applicationCategory.category_title === appCategory.category_title) {
                    applicationCategory.category_id = appCategory.category_id;
                    applicationCategory.category_description = appCategory.category_description;
                    applicationCategory.is_workspace_category = false;

                    isExists = true;
                    break;
                }
            }
            if (!isExists) {
                applicationCategories.push({
                    category_id: appCategory.category_id,
                    category_title: appCategory.category_title,
                    category_description: appCategory.category_description,
                    is_workspace_category: false
                })
            }
        }
    }

    sort(applicationCategories).asc(['category_title']);

    for (let i in applicationCategories) {
        let category = applicationCategories[i];
        let filterCategories = categories.filter((item) => item.category_title === category.category_title);
        if (filterCategories.length > 0) {
            category.redundant = true;
            filterCategories[0].redundant = true;
        }
        else {
            category.redundant = false;
        }
    }

    for (let i in categories) {
        let category = categories[i];
        category.is_workspace_category = true;
        category.redundant = (category.redundant === undefined) ? false : category.redundant;
    }
    categories = categories.concat(applicationCategories);

    let noCategoryAppsLinked = applications.filter((item) => item.category_title === "" && item.is_linked);
    let noCategoryAppsNotLinked = applications.filter((item) => item.category_title === "" && !item.is_linked);

    noCategoryAppsNotLinked.sort(function (a, b) {
        return a.application_name.toLowerCase().localeCompare(b.application_name.toLowerCase());
    });

    let noCategoryApps = noCategoryAppsLinked.concat(noCategoryAppsNotLinked).concat(categoryApps);

    if (noCategoryApps.length > 0) {
        workspaceCategories.push({
            category_id: 0,
            category_title: "",
            category_description: "",
            applications: noCategoryApps,
            count: noCategoryApps.length,
            order: 0,
            redundant: false
        });
    }
    else {
        workspaceCategories.push({
            category_id: 0,
            category_title: "",
            category_description: "",
            applications: [],
            count: 0,
            order: 0,
            redundant: false
        });
    }

    for (let i in categories) {
        let category = categories[i];
        let categoryApps = applications.filter((item) => item.category_title === category.category_title);
        categoryApps.sort(sortBy('index'));

        if (!category.redundant) {
            if (category.is_workspace_category || (!category.is_workspace_category && categoryApps.length !== 0)) {
                workspaceCategories.push({
                    category_id: category.category_id,
                    category_title: category.category_title,
                    category_description: category.category_description,
                    applications: categoryApps,
                    is_workspace_category: category.is_workspace_category,
                    count: categoryApps.length,
                    order: category.order,
                    redundant: category.redundant
                });
            }
        }
        else {
            if (category.is_workspace_category) {
                let linkedApps = categoryApps.filter((item) => item.is_linked);
                if (linkedApps.length > 0) {
                    workspaceCategories.push({
                        category_id: category.category_id,
                        category_title: category.category_title,
                        category_description: category.category_description,
                        applications: linkedApps,
                        is_workspace_category: category.is_workspace_category,
                        count: linkedApps.length,
                        order: category.order,
                        redundant: category.redundant
                    });
                }
                else {
                    workspaceCategories.push({
                        category_id: category.category_id,
                        category_title: category.category_title,
                        category_description: category.category_description,
                        applications: [],
                        is_workspace_category: category.is_workspace_category,
                        count: 0,
                        order: category.order,
                        redundant: category.redundant
                    });
                }
            }
            else {
                let notLinkedApps = categoryApps.filter((item) => !item.is_linked);
                if (notLinkedApps.length > 0) {
                    workspaceCategories.push({
                        category_id: category.category_id,
                        category_title: category.category_title,
                        category_description: category.category_description,
                        applications: notLinkedApps,
                        is_workspace_category: category.is_workspace_category,
                        count: notLinkedApps.length,
                        order: category.order,
                        redundant: category.redundant
                    });
                }

            }
        }
    }

    sort(workspaceCategories).asc(['order']);
    return workspaceCategories;
}

export const updateWorkspacesApplicationsCategoriesStatus = async (workspaceId, applicationId, status) => {
    const workspacesCategories = store.getState().workspacesCategories;
    const workspacesApplications = store.getState().workspacesApplications;

    let appIndex = 0;
    let apps = workspacesApplications.filter((item) => item.index !== undefined && item.index != null);
    if (apps.length > 0) {
        for (let i in apps) {
            let app = apps[i];
            if (parseInt(app.index, 10) > appIndex) {
                appIndex = parseInt(app.index, 10);
            }
        }
    }

    for (let i in workspacesCategories) {
        let isFound = false;
        let workspaceCategory = workspacesCategories[i];
        if (workspaceCategory.applications.length > 0) {
            for (let j in workspaceCategory.applications) {
                let application = workspaceCategory.applications[j];
                if (application.id === applicationId && application.workspace_id === workspaceId) {
                    if (status === "Enabled" || status === "enable") {
                        application.is_enabled = true;
                    }
                    else if (status === "Disabled" || status === "disable") {
                        application.is_enabled = false;
                    }
                    else if (status === "link") {
                        application.is_linked = true;
                        application.is_enabled = true;
                        application.index = appIndex + 1;
                   }
                    else if (status === "unlink") {
                        application.is_linked = false;
                        application.is_enabled = false;
                        delete application.index;
                    }

                    let apps = workspacesApplications.filter((item) => item.id === applicationId && item.workspace_id === workspaceId);
                    if (apps.length > 0) {
                        let app = apps[0];
                        if (status === "Enabled" || status === "enable") {
                            app.is_enabled = true;
                        }
                        else if (status === "Disabled" || status === "disable") {
                            app.is_enabled = false;
                        }
                        else if (status === "link") {
                            app.is_linked = true;
                            app.is_enabled = true;
                            app.index = appIndex + 1;
                        }
                        else if (status === "unlink") {
                            app.is_linked = false;
                            app.is_enabled = false;
                            delete app.index;
                        }
                    }
                    isFound = true;
                    break;
                }
            }

            let applicationsLinked = workspaceCategory.applications.filter((item) => item.is_linked);
            let applicationsNotLinked = workspaceCategory.applications.filter((item) => !item.is_linked);

            sort(applicationsLinked).asc(['index', 'application_name']);
            sort(applicationsNotLinked).asc(['application_name']);
            let applications = applicationsLinked.concat(applicationsNotLinked);
            workspaceCategory.applications = applications;
        }
        if (isFound) {
            break;
        }
    }

    store.dispatch(clearWorkspacesApplicationsAction(sortWorkspacesApplications(workspacesApplications)));
    store.dispatch(clearWorkspacesCategoriesAction(workspacesCategories));
}