import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../../configurations/routes';

class ProfileAccessHeader extends Component {    
    static propTypes = {
        user: PropTypes.object.isRequired
    };    

    render() {
        const { user } = this.props;

        return (
            <div className="mainHead">
                <div className="row">
                    <div className="col-12">
                        <h2 className="float-left mt-2 mr-4">User Access</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="workspaceInfo">
                            <div className="row">
                                <div className="workspaceName col-xl-7 offset-xl-3">
                                    <h3>{user.full_name}</h3>
                                </div>
                                <div className="userRole col-xl-7 offset-xl-3">{user.id}</div>
                            </div>
                            <nav className="col-lg-5 col-xl-4">
                                <div className="row text-center">
                                    <div className="col px-0">
                                        <Link to={routeCodes.PROFILE}
                                            className="workspaceMenu py-3">Detail
                                        </Link>
                                    </div>
                                    <div className="col px-0">
                                        <Link to={'#'}
                                            className="workspaceMenu py-3 active">Access
                                        </Link>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ userAccess }) => ({
    user: userAccess.user
});

export default withRouter(connect(mapStateToProps)(ProfileAccessHeader));