import { v1 as uuidv1 } from 'uuid';
import { getImageSize, b64toBlob } from '../../utils/snapshot';
import { getDefaultImage, getDefaultNoImage, getDefaultWorkspaceBanner } from '../../utils/image';
import { store } from '../../index';

const configImage = require('../../configurations/images');
const workspacesApi = require('../api/workspaces');
const workspacesSettingsApi = require('../api/workspacesSettings');
const portalSettingsApi = require('../api/portal');
const sort = require('fast-sort');

export async function getWorkspaceSettingByIdApi(workspaceId) {
    const includeOnly = "WorkspaceName;Banner;LockProperties;BannerSelected;GoogleAnalytic;ShowWorkspaceName;ShowPortalLogo;ShowWorkspaceLogo";
    const workspaceSetting = await workspacesSettingsApi.getWorkspaceSettingIncludeById(workspaceId, includeOnly);

    let results = mapToWorkspaceSettingIncludeFrontend(workspaceId, workspaceSetting.data, true);
    return results;
}

export async function getWorkspaceThemeSettingImplementationByIdApi(workspaceId) {
    const theme = await workspacesSettingsApi.getWorkspaceThemeSettingImplementationById(workspaceId);
    return theme;
}

export async function getWorkspaceSettingBannerByIdApi(workspaceId) {
    const workspaceSetting = await workspacesSettingsApi.getWorkspaceSettingBannerById(workspaceId);
    let results = mapToWorkspaceSettingBannerFrontend(workspaceId, workspaceSetting.data);
    return results;
}

export async function getWorkspaceSettingIncludeByIdApi(workspaceId, includeOnly) {
    let isGetWorkspaceLogo = false;
    if (includeOnly.includes(";WorkspaceLogo")) {
        isGetWorkspaceLogo = true;
        includeOnly = includeOnly.replace(";WorkspaceLogo", "").replace("WorkspaceLogo;", "");
    }
    const workspaceSetting = await workspacesSettingsApi.getWorkspaceSettingIncludeById(workspaceId, includeOnly);

    let results = mapToWorkspaceSettingIncludeFrontend(workspaceId, workspaceSetting.data, isGetWorkspaceLogo);
    return results;
}

export async function getWorkspaceLoginSettingByIdApi(workspaceId) {
    let backgroundMapping = {};
    let welcomeBackgroundMapping = {};
    let results = {};

    try {
        const workspaceSetting = await workspacesSettingsApi.getWorkspaceLoginSettingById(workspaceId);

        const background = await workspacesSettingsApi.getWorkspaceLoginBackgroundSettingById(workspaceId);
        backgroundMapping = mapToBackgroundMappingFrontend(background.data);

        const welcomeBackground = await workspacesSettingsApi.getWorkspaceLoginWelcomeBackgroundSettingById(workspaceId);
        welcomeBackgroundMapping = mapToBackgroundMappingFrontend(welcomeBackground.data);

        results = mapToWorkspaceLoginSettingFrontend(workspaceId, workspaceSetting.data, backgroundMapping, welcomeBackgroundMapping);
    } catch (ex) {
        results = mapToEmptyWorkspaceLoginSettingFrontend(workspaceId);
    }

    return results;
}

export async function getWorkspaceEmailSettingByIdApi(workspaceId) {
    const workspaceSetting = await workspacesSettingsApi.getWorkspaceEmailSettingById(workspaceId);
    const portalSetting = await portalSettingsApi.getPortalEmail();
    let results = mapToWorkspaceEmailSettingFrontend(workspaceId, workspaceSetting.data, portalSetting.data);

    return results;
}

export async function getWorkspaceThemeSettingByIdApi(workspaceId, width, height) {
    const portalSetting = await portalSettingsApi.getPortalThemeSetting(width, height);
    let workspaceData = {};

    const includeOnly = "Theme;LockProperties";
    const workspaceSetting = await workspacesSettingsApi.getWorkspaceSettingIncludeById(workspaceId, includeOnly);
    if (workspaceSetting.status === 200) {
        workspaceData = workspaceSetting.data.theme;
    }

    let results = mapToWorkspaceThemeSettingFrontend(workspaceId, portalSetting.data, workspaceData);
    return results;
}

export async function getWorkspaceSettingLogoByIdApi(workspaceId) {
    let results = mapToWorkspaceSettingLogoFrontend(workspaceId, "Workspace");
    return results;
}

export async function updateWorkspaceSettingApi(workspaceSetting) {
    const mappingWorkspaceSetting = mapToWorkspaceSettingBackendForUpdate(workspaceSetting);
    const results = await workspacesSettingsApi.updateWorkspaceSetting(mappingWorkspaceSetting);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        if (results.data !== undefined) {
            await workspacesSettingsApi.updateWorkspaceSettingLogo(workspaceSetting.id, workspaceSetting.new_url_image);
            await workspacesSettingsApi.updateWorkspaceSettingBanner(workspaceSetting.id, workspaceSetting.custom_url_banner);
        }
        id = results.data
    } else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    } else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    };

    return data;
}

export async function updateWorkspaceLoginSettingApi(workspaceSetting) {
    const mappingWorkspaceLoginSetting = mapToWorkspaceLoginSettingBackendForUpdate(workspaceSetting);
    const results = await workspacesSettingsApi.updateWorkspaceLoginSetting(workspaceSetting.id, mappingWorkspaceLoginSetting);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        if (results.data !== undefined) {
            if (workspaceSetting.login_background_custom_image !== '') {
                await workspacesSettingsApi.updateWorkspaceLoginSettingBackground(workspaceSetting.id, workspaceSetting.login_background_custom_image);
            }
            if (workspaceSetting.welcome_background_custom_image !== '') {
                await workspacesSettingsApi.updateWorkspaceWelcomeSettingBackground(workspaceSetting.id, workspaceSetting.welcome_background_custom_image);
            }
        }
        id = results.data
    } else if (results.status === 500 || results.status === 404) {
        error_messages = 'Internal server error, Please contact administrator.';
    } else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    };

    return data;
}

export async function updateWorkspaceEmailSettingApi(workspaceSetting) {
    const mappingWorkspaceEmailSetting = mapToWorkspaceEmailSettingBackendForUpdate(workspaceSetting);
    const results = await workspacesSettingsApi.updateWorkspaceEmailSetting(workspaceSetting.id, mappingWorkspaceEmailSetting);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        id = results.data;
    } else if (results.status === 500) {
        error_messages = 'Internal server error, Please contact administrator.';
    } else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    };

    return data;
}

export async function updateWorkspaceThemeSettingApi(workspaceSetting) {
    const mappingWorkspaceThemeSetting = mapToWorkspaceThemeSettingBackendForUpdate(workspaceSetting);
    const results = await workspacesSettingsApi.updateWorkspaceThemeSetting(workspaceSetting.id, mappingWorkspaceThemeSetting);
    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        id = results.data;
        if (workspaceSetting.custom_theme_css) {
            let filePath = uuidv1() + ".css";
            const blob64 = b64toBlob(workspaceSetting.custom_theme_css.replace('data:text/css;base64,', ''), 'text/css');
            await processPutCss(blob64, filePath, workspaceSetting.id);
        }
    }
    else if (results.status === 500 || results.status === 404) {
        error_messages = 'Internal server error, Please contact administrator.';
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }
    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    };

    return data;
}

async function processPutCss(blob64, filePath, id) {
    const form = new FormData();
    form.append('styleFile', blob64, {
        filepath: filePath,
        contentType: 'text/css',
    });

    const results = await workspacesSettingsApi.addWorkspaceCustomThemeFile(id, form);
    if (results.data !== undefined) {
        return results.data;
    }
}

async function mapToWorkspaceSettingIncludeFrontend(wid, workspaceSettingData, isGetWorkspaceLogo) {
    const wsData = workspaceSettingData;
    const currentWorkspaceSetting = store.getState().detail.workspace_setting;

    let workspaceSetting = {};
    if (wsData !== undefined) {
        workspaceSetting = {
            ...currentWorkspaceSetting,
            id: wid
        };

        if (wsData.workspaceName !== undefined) {
            workspaceSetting.workspace_name = wsData.workspaceName;
        }

        if (wsData.showPortalLogo !== undefined) {
            workspaceSetting.showPortalLogo = wsData.showPortalLogo;
        }

        if (wsData.googleAnalytic !== undefined) {
            workspaceSetting.useGoogleAnalytics = wsData.googleAnalytic.enabled;
            workspaceSetting.googleAnalyticsTrackingId = (wsData.googleAnalytic.trackId === undefined || wsData.googleAnalytic.trackId === null) ? '' : wsData.googleAnalytic.trackId
        }

        if (wsData.showWorkspaceLogo !== undefined) {
            workspaceSetting.showWorkspaceLogo = wsData.showWorkspaceLogo;
        }

        if (wsData.showWorkspaceName !== undefined) {
            workspaceSetting.showWorkspaceName = wsData.showWorkspaceName;
        }

        if (wsData.theme !== undefined) {
            workspaceSetting.themeSelection = wsData.theme.themeSelection;
        }

        if (wsData.banner !== undefined) {
            let customBannerProps = (wsData.banner !== '') ? getImageSize(wsData.banner) : getImageSize(getDefaultWorkspaceBanner());

            workspaceSetting.custom_url_banner = (wsData.banner !== '') ? wsData.banner : getDefaultWorkspaceBanner(); 
            workspaceSetting.custom_banner_aspect_ratio = customBannerProps.width / customBannerProps.height;
        }

        if (wsData.customLogo !== undefined) {
            let customImageProps = (wsData.customLogo !== '') ? getImageSize(wsData.customLogo) : {
                'width': 1200,
                'height': 900
            };

            workspaceSetting.new_url_image = (wsData.customLogo !== '') ? wsData.customLogo : getDefaultImage()
            workspaceSetting.new_image_aspect_ratio = customImageProps.width / customImageProps.height;
        }

        if (wsData.lockProperties !== undefined) {
            workspaceSetting.themes_locked = wsData.lockProperties.filter(x => x.propertyName === 'Theme').map(x => x.locked)[0];
            workspaceSetting.banner_locked = wsData.lockProperties.filter(x => x.propertyName === 'BannerSelected').map(x => x.locked)[0];
            workspaceSetting.PortalProperties = wsData.lockProperties.filter(x => x.propertyName === 'PortalProperties').map(x => x.locked)[0];
            workspaceSetting.PortalAnalytics = wsData.lockProperties.filter(x => x.propertyName === 'PortalAnalytics').map(x => x.locked)[0];
        }

        if (wsData.theme !== undefined) {
            workspaceSetting.chosen_themes = wsData.theme;
        }

        if (wsData.bannerSelected !== undefined) {
            workspaceSetting.chosen_banner = wsData.bannerSelected;
        }

        if (isGetWorkspaceLogo) {
            let workspaceLogo = await workspacesApi.getWorkspaceLogo(wid, 143, 108);
            let workspaceImageProps = (workspaceLogo !== undefined) ? getImageSize(workspaceLogo) : {
                'width': 1200,
                'height': 900
            };

            workspaceSetting.workspace_image_aspect_ratio = workspaceImageProps.width / workspaceImageProps.height;
            workspaceSetting.workspace_url_image = (workspaceLogo === undefined) ? getDefaultImage() : workspaceLogo;
        }
    }

    return workspaceSetting;
}

function mapToWorkspaceThemeSettingFrontend(id, portalData, workspaceData) {
    let wsData = workspaceData;
    let workspaceSetting = {};
    let themes = [];
    for (let i in portalData) {
        let p = portalData[i];
        let theme = {
            id: p.id,
            name: p.name,
            image: p.image
        };
        themes.push(theme);
    }

    if (wsData.themeSelection !== undefined) {
        workspaceSetting = {
            id: id,
            theme_selected: wsData.themeSelection,
            portal_theme_id: (wsData.portalThemeId !== undefined) ? wsData.portalThemeId : '',
            custom_theme_name: (wsData.customTheme !== undefined) ? wsData.customTheme.name : '',
            custom_theme_css_name: (wsData.customTheme !== undefined && wsData.customTheme.filePath !== undefined) ? wsData.customTheme.filePath.split('/').pop() : '',
            custom_theme_css_filepath: (wsData.customTheme !== undefined) ? wsData.customTheme.filePath : '',
            custom_theme_image: (wsData.customTheme !== undefined) ? wsData.customTheme.preview : getDefaultNoImage(),
            is_locked: (wsData.lockProperties !== undefined && wsData.lockProperties.length > 0) ? wsData.lockProperties.filter(x => x.propertyName === 'ThemeSetting').map(x => x.locked)[0] : false
        };
    }
    else {
        workspaceSetting = {
            id: id,
            theme_selected: 'Default'
        };
    }

    workspaceSetting.themes = themes;
    return workspaceSetting;
}

function mapToWorkspaceSettingBackendForUpdate(data) {
    let workspaceSetting = {};

    const setting = [{
        propertyName: 'Theme',
        locked: data.themes_locked
    },
    {
        propertyName: 'PortalProperties',
        locked: data.PortalProperties
    },
    {
        propertyName: 'PortalAnalytics',
        locked: data.PortalAnalytics
    },
    {
        propertyName: 'BannerSelected',
        locked: data.banner_locked
    }];

    const googleAnalytics = {
        enabled: data.useGoogleAnalytics,
        trackId: data.googleAnalyticsTrackingId
    }

    workspaceSetting.id = data.id;
    workspaceSetting.bannerSelected = data.chosen_banner;
    workspaceSetting.theme = data.chosen_themes;
    workspaceSetting.lockProperties = setting;
    workspaceSetting.showPortalLogo = data.showPortalLogo
    workspaceSetting.showWorkspaceLogo = data.showWorkspaceLogo
    workspaceSetting.showWorkspaceName = data.showWorkspaceName
    workspaceSetting.googleAnalytic = googleAnalytics;

    return workspaceSetting;
}

function mapToWorkspaceLoginSettingBackendForUpdate(data) {
    let workspaceSetting = {};
    const setting = [{
        propertyName: 'WelcomeText',
        locked: data.welcome_text_locked
    },
    {
        propertyName: 'WelcomeBackground',
        locked: data.welcome_background_locked
    },
    {
        propertyName: 'LoginBackground',
        locked: data.login_background_locked
    }];

    workspaceSetting.welcomeText = data.welcome_text;
    workspaceSetting.welcomeTextSelected = data.welcome_text_selected;
    workspaceSetting.welcomeBackgroundSelected = data.welcome_background_selected;
    workspaceSetting.welcomeBackgroundColor = data.welcome_background_color;
    workspaceSetting.loginBackgroundSelected = data.login_background_selected;
    workspaceSetting.loginBackgroundColor = data.login_background_color;
    workspaceSetting.lockProperties = setting;

    return workspaceSetting;
}

function mapToWorkspaceEmailSettingBackendForUpdate(data) {
    let emails = [];
    for (let i in data.emails) {
        let w = data.emails[i];
        let email = {};
        if (w.email_selected === 'Default') {
            email = {
                emailType: w.email_type,
                emailSelection: w.email_selected,
                lockProperties: [{
                    propertyName: w.email_type,
                    locked: w.is_locked
                }]
            };
        }
        else {
            email = {
                emailType: w.email_type,
                emailSelection: w.email_selected,
                from: w.sender_from,
                subject: w.email_subject,
                content: w.email_content,
                lockProperties: [{
                    propertyName: w.email_type,
                    locked: w.is_locked
                }]
            }
        }
        emails.push(email);
    }

    return emails;
}

function mapToWorkspaceThemeSettingBackendForUpdate(data) {
    let workspaceSetting = {};
    let ImageProps = (data.custom_theme_image !== undefined && data.custom_theme_image !== '') ? getImageSize(data.custom_theme_image) : {
        'width': 1200,
        'height': 900
    };
    const setting = [{
        propertyName: 'ThemeSetting',
        locked: data.is_locked
    }];

    const customTheme = {
        name: data.custom_theme_name,
        filePath: data.custom_theme_css_name,
        themePreview: {
            image: data.custom_theme_image,
            imageSize: {
                width: ImageProps.width,
                height: ImageProps.height
            },
            thumbnailSize: {
                width: ImageProps.width / 1,
                height: ImageProps.height / 1
            },
            resultFormat: configImage.resultFormat
        }
    };

    workspaceSetting.themeSelection = data.theme_selected;
    if (data.theme_selected === 'Portal' && data.portal_theme_id !== undefined) {
        workspaceSetting.portalThemeId = data.portal_theme_id;
    }
    workspaceSetting.customTheme = customTheme;
    workspaceSetting.lockProperties = setting;

    return workspaceSetting;
}

function mapToWorkspaceSettingBannerFrontend(wid, workspaceSettingData) {
    let wsData = workspaceSettingData;
    let ImageProps = (wsData !== null && wsData !== "") ? getImageSize(wsData) : getImageSize(getDefaultWorkspaceBanner());

    let workspaceSetting = {};

    if (wsData !== undefined) {
        workspaceSetting = {
            id: wid,
            image: (wsData !== null && wsData !== "") ? wsData : '',
            image_aspect_ratio: ImageProps.width / ImageProps.height
        };
    }

    return workspaceSetting;
}

function mapToBackgroundMappingFrontend(background) {
    let bgi = background;
    let loginBackground = {};
    if (bgi !== undefined && bgi !== null) {
        if (bgi.image !== undefined && bgi.image !== '') {
            let ImageProps = getImageSize(bgi.image);
            loginBackground = {
                image: bgi.image,
                image_aspect_ratio: ImageProps.width / ImageProps.height
            };
        }
    }

    return loginBackground;
}

function mapToWorkspaceLoginSettingFrontend(wid, workspaceSettingData, background, welcomeBackground) {
    let wsData = workspaceSettingData;

    let workspaceSetting = {};

    if (wsData !== undefined) {
        workspaceSetting = {
            id: wid,
            welcome_text_selected: (wsData.welcomeTextSelected !== undefined) ? wsData.welcomeTextSelected : 'Default',
            welcome_text: wsData.welcomeText,
            welcome_background_selected: wsData.welcomeBackgroundSelected,
            welcome_background_color: (wsData.welcomeBackgroundColor !== '') ? wsData.welcomeBackgroundColor : '#8891be',
            welcome_background_custom_image: (welcomeBackground.image !== undefined) ? welcomeBackground.image : '',
            welcome_background_custom_image_aspect_ratio: (welcomeBackground.image_aspect_ratio !== undefined) ? welcomeBackground.image_aspect_ratio : '',
            login_background_selected: wsData.loginBackgroundSelected,
            login_background_color: (wsData.loginBackgroundColor !== '') ? wsData.loginBackgroundColor : '#8891be',
            login_background_custom_image: (background.image !== undefined) ? background.image : '',
            login_background_custom_image_aspect_ratio: (background.image_aspect_ratio !== undefined) ? background.image_aspect_ratio : '',
            welcome_text_locked: (wsData.lockProperties !== undefined) ? wsData.lockProperties.filter(x => x.propertyName === 'WelcomeText').map(x => x.locked)[0] : false,
            welcome_background_locked: (wsData.lockProperties !== undefined) ? wsData.lockProperties.filter(x => x.propertyName === 'WelcomeBackground').map(x => x.locked)[0] : false,
            login_background_locked: (wsData.lockProperties !== undefined) ? wsData.lockProperties.filter(x => x.propertyName === 'LoginBackground').map(x => x.locked)[0] : false
        };
    }

    return workspaceSetting;
}

async function mapToWorkspaceSettingLogoFrontend(wid, logoSelected) {
    let workspaceSetting = {};

    if (logoSelected === "Workspace") {
        let workspaceLogo = await workspacesApi.getWorkspaceLogo(wid, 128, 107);
        let ImageProps = (workspaceLogo !== undefined) ? getImageSize(workspaceLogo) : {
            'width': 1200,
            'height': 900
        };

        workspaceSetting = {
            id: wid,
            image: (workspaceLogo !== undefined) ? workspaceLogo : 'Default',
            image_aspect_ratio: ImageProps.width / ImageProps.height,
            isLoaded: true
        };
    }
    else {
        let ImageProps = { 'width': 1200, 'height': 900 };

        workspaceSetting = {
            id: wid,
            image: logoSelected,
            image_aspect_ratio: ImageProps.width / ImageProps.height,
            isLoaded: true
        };
    }

    return workspaceSetting;
}

function mapToEmptyWorkspaceLoginSettingFrontend(wid) {
    let workspaceSetting = {
        id: wid,
        welcome_text_selected: 'Default',
        welcome_text: '',
        welcome_background_selected: 'Default',
        welcome_background_color: '#8891be',
        welcome_background_custom_image: '',
        welcome_background_custom_image_aspect_ratio: '',
        login_background_selected: 'Default',
        login_background_color: '#8891be',
        login_background_custom_image: '',
        login_background_custom_image_aspect_ratio: '',
        welcome_text_locked: false,
        welcome_background_locked: false,
        login_background_locked: false
    };

    return workspaceSetting;
}

function mapToWorkspaceEmailSettingFrontend(wid, workspaceSettingData, portalSettingData) {
    let wsData = workspaceSettingData;
    let workspaceSetting = {};
    let emails = [];
    for (let i in wsData) {
        let w = wsData[i];
        let email = {
            id: (w.emailType === 'Registration' ? 0 : w.emailType === 'RegistrationReminder' ? 1 : w.emailType === 'ResetPassword' ? 2 : w.emailType === 'ForgotPassword' ? 3 : w.emailType === 'GrantWorkspace' ? 4 : null),
            email_selected: w.emailSelection,
            email_type: w.emailType,
            sender_from: (w.from !== undefined) ? w.from : '',
            email_subject: (w.subject !== undefined) ? w.subject : '',
            email_content: (w.content !== undefined) ? w.content : '',
            sender_from_default: portalSettingData.filter(x => x.type === w.emailType).map(x => x.from)[0],
            email_subject_default: portalSettingData.filter(x => x.type === w.emailType).map(x => x.subject)[0],
            email_content_default: portalSettingData.filter(x => x.type === w.emailType).map(x => x.content)[0],
            is_locked: (w.lockProperties !== undefined && w.lockProperties.length > 0) ? w.lockProperties.filter(x => x.propertyName === w.emailType).map(x => x.locked)[0] : false
        }
        emails.push(email);
    }

    sort(emails).asc(['id']);

    if (wsData !== undefined) {
        workspaceSetting = {
            id: wid
        };
    }

    workspaceSetting.emails = emails;
    return workspaceSetting;
}