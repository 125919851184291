import {
    UPDATE_WORKSPACES_FOCUS_AREAS_SHAPE_HEADER
} from '../actions/index';

const initialFocusAreaShapeHeaderState = []

export const workspacesFocusAreasShapeHeader = (state = initialFocusAreaShapeHeaderState, action) => {
    const { focus_area_shape_header } = action
    switch (action.type) {        
        case UPDATE_WORKSPACES_FOCUS_AREAS_SHAPE_HEADER:
            return focus_area_shape_header   
        default:
            return state;
    }
};
