import { getWorkspaceIdByNameApi, getWorkspaceNameByIdApi } from '../backend/domain/workspaces';
import { isValidGuid } from './index';
import { getSessionStorage, saveSessionStorage } from './sessionStorage';
import { routeCodes } from '../configurations/routes';
import { anonymousUserId } from '../configurations/app';
import { store } from '../index';
import { getWorkspacesAction } from '../actions/getWorkspacesAction';
import { updateWorkspacesAction } from '../actions/updateWorkspacesAction';
import { clearWorkspacesAction } from '../actions/clearWorkspacesAction';
import { getWorkspaceAction } from '../actions/getWorkspaceAction';
import { clearWorkspaceAction } from '../actions/clearWorkspaceAction';
import { updateUnavailableContextAction } from '../actions/updateUnavailableContextAction';
import { getUserAvailableWorkspacesAction } from '../actions/getUserAvailableWorkspacesAction';

const moment = require('moment');

export const getWorkspaceIdByName = async (workspaceName) => {
    let result = await getWorkspaceIdByNameApi(workspaceName);
    return result;
}

export const getWorkspaceNameById = async (workspaceId) => {
    let result = await getWorkspaceNameByIdApi(workspaceId);
    return result;
}

export const overrideWorkspaceId = async (id) => {
    let oId = null;

    if (id !== undefined && id !== "") {
        oId = getSessionStorage(id);
        if (oId !== undefined && oId !== null && oId !== "" && oId !== "null") {
            return oId;
        }
        let checkId = isValidGuid(id);
        if (!checkId) {
            let result = await getWorkspaceIdByName(id);
            oId = (result.workspace !== undefined) ? result.workspace.id : null;
            saveSessionStorage(id, oId);
        }
        else {
            oId = id;
        }
    }
    else {
        oId = id;
    }
    if (oId === null) {
        window.location.href = routeCodes.UNAVAILABLE_PAGE + '?page=' + id + "&workspace=true";
    }
    return oId;
}

export const validateWorkspaceId = async (id) => {
    let oId = null;

    if (id !== undefined && id !== "") {
        oId = getSessionStorage(id);
        if (oId !== undefined && oId !== null && oId !== "") {
            return oId;
        }
        let checkId = isValidGuid(id);
        if (!checkId) {
            let result = await getWorkspaceIdByName(id);
            oId = (result.workspace !== undefined) ? result.workspace.id : null;

            if (oId !== null) {
                saveSessionStorage(id, oId);
            }
        }
    }

    return oId;
}

export const getWorkspaceHeaders = () => {
    const headers = [
        { id: 1, header_name: "Logo", sort_column: "logo" },
        { id: 2, header_name: "Description", sort_column: "Name" },
        { id: 3, header_name: "Created On", sort_column: "CreatedDate" },
        { id: 5, header_name: "Status", sort_column: "Status" },
        { id: 6, header_name: "Access", sort_column: "access" }
    ];

    return headers;
};

export const filterByValue = (array, text) => {
    text = text.split(' ');
    return array.filter(function (item) {
        return text.every(function (el) {
            return item.id.toLowerCase().indexOf(el) > -1 ||
                item.full_name.toLowerCase().indexOf(el) > -1 ||
                item.registration_status.toLowerCase().indexOf(el) > -1 ||
                item.user_status.toLowerCase().indexOf(el) > -1 ||
                item.user_role.toLowerCase().indexOf(el) > -1 ||
                item.email_address.toLowerCase().indexOf(el) > -1 ||
                item.phone.indexOf(el) > -1 ||
                item.organization.toLowerCase().indexOf(el) > -1
        });
    });
}

export const updateWorkspaces = (workspaces) => {
    const currentWorspaces = store.getState().workspaces;
    let ws = [];

    ws = ws.concat(currentWorspaces);
    ws = ws.concat(workspaces);

    store.dispatch(getWorkspacesAction(ws))
} 

export const updateWorkspacesStatus = (workspaceId, enabled) => {
    const workspaces = store.getState().workspaces;

    for (let i in workspaces) {
        let workspace = workspaces[i];
        if (workspace.id === workspaceId) {
            workspace.enabled = enabled;
            break;
        }
    }

    store.dispatch(updateWorkspacesAction(workspaces))
} 

export const updateWorkspacesActive = (workspaceId, status) => {
    const workspaces = store.getState().workspaces;

    for (let i in workspaces) {
        let workspace = workspaces[i];
        if (workspace.id === workspaceId) {
            workspace.status = status;
            break;
        }
    }

    store.dispatch(updateWorkspacesAction(workspaces))
} 

export const updateWorkspacesRemove = (workspaceId) => {
    const workspaces = store.getState().workspaces;
    const ws = workspaces.filter((item) => item.id !== workspaceId);

    store.dispatch(clearWorkspacesAction(ws))
}

export const updateWorkspaceProperties = (user, url) => {
    const currentWorspace = store.getState().detail.workspace;

    let workspace = {
        ...currentWorspace,
        workspace_url: url,
        user_picture: user.picture,
        user_initial: user.initial
    }
    store.dispatch(getWorkspaceAction(workspace))
} 

export const updateWorkspace = async (workspaceData) => {
    const currentWorspace = store.getState().detail.workspace;
    const w = workspaceData;

    let workspace = {
        ...currentWorspace,
        id: w.id,
        workspace_name: w.name,
        workspace_title: w.title,
        due_date: moment(new Date()).format("MM-DD-YYYY"),
        created_date: moment(w.createdDate).format("YYYY/MM/DD HH:mm"),
        status: w.status,
        enabled: w.enabled,
        workspace_desc: w.description,
        domain_url: (w.domainUrl !== undefined && w.domainUrl !== null) ? w.domainUrl : "",
        video_url: (w.videoUrl !== undefined && w.videoUrl !== null) ? w.videoUrl : "",
        workspace_url: (w.url !== undefined && w.url !== null) ? w.url : "",
        workspace_name_locked: w.lockProperties.filter(x => x.propertyName === 'Name').map(x => x.locked)[0],
        workspace_desc_locked: w.lockProperties.filter(x => x.propertyName === 'Description').map(x => x.locked)[0],
        workspace_title_locked: w.lockProperties.filter(x => x.propertyName === 'Title').map(x => x.locked)[0],
        logo_locked: w.lockProperties.filter(x => x.propertyName === 'Logo').map(x => x.locked)[0],
        public_access_locked: w.lockProperties.filter(x => x.propertyName === 'Public_Access').map(x => x.locked)[0],
        focus_area_access_locked: w.lockProperties.filter(x => x.propertyName === 'Focus_Area_Access').map(x => x.locked)[0],
        application_access_locked: w.lockProperties.filter(x => x.propertyName === 'Application_Access').map(x => x.locked)[0],
        domain_url_locked: w.lockProperties.filter(x => x.propertyName === 'Domain_Url').map(x => x.locked)[0],
        video_url_locked: w.lockProperties.filter(x => x.propertyName === 'Video_Url').map(x => x.locked)[0],
        workspace_url_locked: w.lockProperties.filter(x => x.propertyName === 'Url').map(x => x.locked)[0],
        ownerCount: w.ownerCount,
        memberCount: w.memberCount,
        allow_public_access: w.allowPublicAccess,
        all_focus_area_access: (w.focusAreaAccess === 'All') ? true : false,
        all_application_access: (w.applicationAccess === 'All') ? true : false
    }

    store.dispatch(getWorkspaceAction(workspace))
} 

export const routeWorkspacePage = async (workspace, userId, isMember, isOwner, isActiveMember, historyProps, pageToGo) => {
    let name = workspace.workspace_name.toLowerCase().replaceAll(" ", "+");
    if (workspace.enabled && workspace.status === 'Active') {
        if (isMember) {
            if (!isOwner || (isOwner && !isActiveMember)) {
                if (workspace.allow_public_access || isActiveMember) {
                    await store.dispatch(clearWorkspaceAction([]))
                    historyProps.push(routeCodes.WORKSPACES_LIST + '/' + name);
                }
                else {
                    historyProps.push(routeCodes.PROFILE);
                }
            }
            else {
                let url = getUrlPage(name, pageToGo);
                historyProps.push(url);
            }
        }
        else {
            if (workspace.allow_public_access) {
                if (userId !== anonymousUserId && userId !== "") {
                    await store.dispatch(clearWorkspaceAction([]))
                    historyProps.push(routeCodes.WORKSPACES_LIST + '/' + name);
                }
                else {
                    saveSessionStorage('anonymous_direct_access', getUrlPage(name, pageToGo));
                }
            }
            else {
                if (userId !== anonymousUserId && userId !== "") {
                    historyProps.push(routeCodes.PROFILE);
                }
                else {
                    saveSessionStorage('anonymous_direct_access', getUrlPage(name, pageToGo));
                }
            }
        }
    }
    else {
        if (!workspace.enabled) {
            await store.dispatch(updateUnavailableContextAction('disabledWorkspace'));
            historyProps.push(routeCodes.UNAVAILABLE_PAGE + '?page=' + routeCodes.WORKSPACES_LIST + '/' + name + "/properties");
        }
        else if (workspace.status !== 'Active') {
            if (isOwner) {
                historyProps.push(routeCodes.WORKSPACES_LIST + '/' + name + "/properties");
            }
            else {
                await store.dispatch(updateUnavailableContextAction('suspendedWorkspace'));
                historyProps.push(routeCodes.UNAVAILABLE_PAGE + '?page=' + routeCodes.WORKSPACES_LIST + '/' + name + "/properties");
            }
        }
    }
}

export const updateAvailableWorkspacesOpenedStatus = (isOpened) => {
    const availableWorkspaces = store.getState().availableWorkspaces;
    availableWorkspaces.uid = availableWorkspaces.user_id;
    availableWorkspaces.is_opened = isOpened;

    store.dispatch(getUserAvailableWorkspacesAction(availableWorkspaces))
}

const getUrlPage = (name, pageToGo) => {
    if (pageToGo === "properties") {
        return routeCodes.WORKSPACES_LIST + '/' + name + "/properties";
    }
    else if (pageToGo === "landing") {
        return routeCodes.WORKSPACES_LIST + '/' + name;
    }
    else if (pageToGo === "applications") {
        return routeCodes.WORKSPACES_LIST + '/' + name + '/applications';
    }
    else if (pageToGo === "members") {
        return routeCodes.WORKSPACES_LIST + '/' + name + '/members';
    }
    else if (pageToGo === "share-members") {
        return routeCodes.WORKSPACES_LIST + '/' + name + '/members/share';
    }
    else if (pageToGo === "general") {
        return routeCodes.WORKSPACES_LIST + '/' + name + '/settings/general';
    }
    else if (pageToGo === "login") {
        return routeCodes.WORKSPACES_LIST + '/' + name + '/settings/login';
    }
    else if (pageToGo === "email") {
        return routeCodes.WORKSPACES_LIST + '/' + name + '/settings/email';
    }
    else if (pageToGo === "theme") {
        return routeCodes.WORKSPACES_LIST + '/' + name + '/settings/theme';
    }
}