import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import SidebarContainer from '../../Sidebar/SidebarContainer';
import ApplicationsCreateHeader from './ApplicationsCreateHeader';
import ApplicationsCreateBody from './ApplicationsCreateBody';
import { setupNewApplicationAction } from '../../../actions/setupNewApplicationAction';
import { clearSetupNewApplicationAction } from '../../../actions/clearSetupNewApplicationAction';
import { changeNavigationAction } from '../../../actions/changeNavigationAction';
import { getDefaultImage } from '../../../utils/cropImage';
import moment from 'moment';
import ApplicationsCreationErrorModal from '../Modal/ApplicationsCreationErrorModal';

class ApplicationsCreateContainer extends Component {
    static propTypes = {
        application: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        navigation: PropTypes.object.isRequired,
        dispatchNavigation: PropTypes.func.isRequired
    };

    getCurrentDate() {
        let today = moment().format("MM-DD-YYYY");
        return today;
    }

    getUserName() {
        let username = "John Doe";
        return username;
    }

    getUrlImage() {
        let urlImage = getDefaultImage();
        return urlImage;
    }

    getStatus() {
        let status = "Active";
        return status;
    }

    changeNavigation(name) {
        let navigation = {
            name
        };
        this.props.dispatchNavigation(navigation);
    }

    dispatchNewApplication(currentDate, username, urlImage, status) {
        let newApplication = {
            ...this.props.application,
            default_url_image: urlImage,
            url_image: '',
            image_aspect_ratio: 4 / 3,
            created_date: currentDate,
            username: username,
            status: status
        };
        this.props.setupNewApplication(newApplication);
    }

    setDefaultData() {
        const today = this.getCurrentDate();
        const username = this.getUserName();
        const urlImage = this.getUrlImage();
        const status = this.getStatus();

        this.dispatchNewApplication(today, username, urlImage, status);
    }

    componentWillMount() {
        this.setDefaultData();
    }

    componentWillUnmount() {
        this.props.clearSetupNewApplication();
    }

    componentDidMount() {
        this.changeNavigation('Applications');
    }

    render() {
        const { loading, loadingTheme } = this.props;

        return (
            <div>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <SidebarContainer />
                        <main>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <ApplicationsCreateHeader />
                                    <ApplicationsCreateBody />
                                </div>
                            </div>
                        </main>
                        <ApplicationsCreationErrorModal type="error" />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ setup, loading, navigation }) => ({
    application: setup.application,
    loading: loading.create_application.loading,
    loadingTheme: loading.theme.loading,
    navigation
});

const mapDispatchToProps = (dispatch) => ({
    setupNewApplication: (application) => dispatch(setupNewApplicationAction(application)),    
    clearSetupNewApplication: () => dispatch(clearSetupNewApplicationAction([])),
    dispatchNavigation: (navigation) => dispatch(changeNavigationAction(navigation))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationsCreateContainer));