import L from 'leaflet';
import proj4 from 'proj4';
import { updateFocusAreaMapAction } from '../actions/updateFocusAreaMapAction';
import { updateMapGeometryAction } from '../actions/updateMapGeometryAction';
import { store } from '../index';
import { zoom, minZoom, maxZoom } from '../configurations/map'
import { getGeometrybyIdWithThunk } from './../api/workspacesFocusAreasThunk';
import { updateWorkspacesFocusAreasAllAction } from '../actions/updateWorkspacesFocusAreasAllAction';

export const setInitialSelectedFocusArea = (focusAreas) => {
    if (focusAreas.length > 0) {
        focusAreas.sort(function (a, b) {
            var nameA = a.focus_area_name.toUpperCase();
            var nameB = b.focus_area_name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        })
        focusAreas[0].is_selected = true;
    }

    return focusAreas;
}

export const setSelectedFocusArea = async (focusAreaId, focusAreas) => {
    for (let i in focusAreas) {
        let a = focusAreas[i];
        if (a.id === focusAreaId) {
            a.is_selected = true;
        }
        else {
            a.is_selected = false;
        }
    }

    return focusAreas;
}


export const drawMap = async () => {

    const map = store.getState().map.focus_area_map;
    const features = map.data.features;

    await store.dispatch(updateMapGeometryAction([]));

    let focus_area_map = {};
    if (features.length > 0) {
        const multipolygon = L.geoJson(features);
        const bounds = multipolygon.getBounds();
        const centroids = bounds.getCenter();
        focus_area_map = {
            ...map,
            lat: centroids.lat,
            lng: centroids.lng,
            bounds,
            zoom: zoom,
            minZoom: minZoom,
            maxZoom: maxZoom,
        }
        await store.dispatch(updateFocusAreaMapAction(focus_area_map));
    }
}

export const getSelectedFocusArea = async (item) => {

    const focusAreas = store.getState().focusAreas;
    const workspaceId = store.getState().detail.workspace.id;
    let selectedItem = '';
    if (item) {
        const prevSelectedItem = focusAreas.filter(fa => fa.id === item.id)[0];
        if(prevSelectedItem.is_selected) return;
        selectedItem = item;
        const updateFocusArea = focusAreas.map(fa => 
            (fa.id === item.id)
                ? { ...fa, is_selected: true }
                : { ...fa, is_selected: false }
        )

        await store.dispatch(updateWorkspacesFocusAreasAllAction(updateFocusArea))
    } else {
        selectedItem = focusAreas.filter((a) => a.is_selected === true)[0];
    }

    if (selectedItem.has_geometry === true) {
        const data = {
            focusAreaId: selectedItem.id,
            workspaceId: workspaceId
        }
        await store.dispatch(getGeometrybyIdWithThunk(data))
    } else {
        await store.dispatch(updateMapGeometryAction([]));
    }
}

export const setFeaturesCoordinates = async (feature, projection) => {
    let geometryTypeIndex = getGeometryType(feature);

    if (geometryTypeIndex === 1) {
        feature = await setPolygonCoordinates(feature, projection);
    }
    else if (geometryTypeIndex === 2) {
        feature = await setLineCoordinates(feature, projection);
    }
    else if (geometryTypeIndex === 3) {
        feature = await setPointCoordinates(feature, projection);
    }

    return feature
}


async function setPolygonCoordinates(feature, projection) {
    const featureCoordinates = getSingleOrMultipleCoordinates(feature);

    let coordinates = [];
    if (!isNaN(featureCoordinates[0][0][0][0])) {
        coordinates = featureCoordinates[0];
    }
    else {
        coordinates = featureCoordinates;
    }

    let hasBreak = false;
    for (let i in coordinates) {
        const ps = coordinates[i];
        for (let j in ps) {
            let p = ps[j];
            if (p[0] >= 200 || p[1] > 200) {
                let point = await convertUtmToLatLng(p, projection);

                p[0] = point[0];
                p[1] = point[1];
            }
            else {
                hasBreak = true;
                break;
            }
        }
        if (hasBreak) {
            break;
        }
    }

    return {
        ...feature
    }
}

async function setLineCoordinates(feature, projection) {
    const coordinates = getSingleOrMultipleCoordinates(feature);
    for (let i in coordinates) {
        let p = coordinates[i];
        if (p[0] >= 200 || p[1] > 200) {
            let point = await convertUtmToLatLng(p, projection);
            p[0] = point[0];
            p[1] = point[1];
        }
        else {
            break;
        }
    }
    return {
        ...feature
    }
}

async function setPointCoordinates(feature, projection) {
    const coordinates = getSingleOrMultipleCoordinates(feature);

    let p = coordinates;
    if (p[0] >= 200 || p[1] > 200) {
        let point = await convertUtmToLatLng(p, projection);
        p[0] = point[0];
        p[1] = point[1];
    }

    return {
        ...feature
    }
}

async function convertUtmToLatLng(coordinate, projection) {
    const points = [];
    const point = await proj4(projection).inverse(coordinate);

    points.push(point[0]);
    points.push(point[1]);

    return points;
}

export const getSingleOrMultipleCoordinates = (feature) => {
    const geometryTypeIndex = getGeometryType(feature);
    const geometryType = feature.geometry.type;
    const isFocusArea = !feature.properties.feature_name.includes("_$$_");

    if (geometryTypeIndex === 1) {
        if (isFocusArea) {
            if (geometryType.toLowerCase().includes('multi')) {
                return feature.geometry.coordinates;
            }
            else {
                const coordinates = [];
                coordinates.push(feature.geometry.coordinates);

                return coordinates;
            }
        }
        else {
            return feature.geometry.coordinates;
        }
    }
    else if (geometryTypeIndex === 2 || geometryTypeIndex === 3) {
        return geometryType.toLowerCase().includes('multi') ? feature.geometry.coordinates[0] : feature.geometry.coordinates;
    }
}

export const getGeometryType = (feature) => {
    let geometryType = feature.geometry.type;
    let geometryTypeIndex = (geometryType.toLowerCase().includes("polygon")) ? 1 :
        (geometryType.toLowerCase().includes("line")) ? 2 :
            (geometryType.toLowerCase().includes("point")) ? 3 : 0;

    return geometryTypeIndex;
}
