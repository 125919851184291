import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../../configurations/routes';
import { updateStickyUsersAction } from '../../../actions/updateStickyUsersAction';
import { updateStickyUserStatusWithThunk, updateStickyUserRoleWithThunk } from '../../../api/usersThunk';
import { updateDialogUsersAction } from '../../../actions/updateDialogUsersAction';

class GridItem extends Component {
    constructor(props) {
        super(props);
        this.displayUser = this.displayUser.bind(this);
        this.displayAction = this.displayAction.bind(this);
        this.hideAction = this.hideAction.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.updateRole = this.updateRole.bind(this);
        this.getDetail = this.getDetail.bind(this);
    }

    static propTypes = {
        headers: PropTypes.array.isRequired,
        item: PropTypes.object.isRequired,
        sticky: PropTypes.object.isRequired,
        pagination: PropTypes.object.isRequired,
        sorting: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired,
        dispatchStickyUsers: PropTypes.func.isRequired,
        dispatchDialogUsers: PropTypes.func.isRequired,
        updateUserStatus: PropTypes.func.isRequired,
        updateUserRole: PropTypes.func.isRequired
    };

    displayUser(id) {
        const { role } = this.props;
        if (role.user_name.toLowerCase() === id) {
            this.props.history.push(routeCodes.PROFILE);
        } else {
            this.props.history.push(routeCodes.USERS_LIST + "/" + id);
        }
    }

    displayAction(id) {
        this.props.dispatchStickyUsers({ user_id: id, show_action: !this.props.sticky.show_action });
    }

    hideAction() {
        this.props.dispatchStickyUsers({ user_id: '', show_action: false });
    }

    getDetail() {
        this.hideAction();
        const { item } = this.props;
        this.props.history.push(routeCodes.USERS_LIST + "/" + item.id.toLowerCase())
    }


    updateStatus() {
        const users = {
            id: this.props.item.uid,
            name: this.props.item.id,
            status: this.props.item.user_status,
            dialog_title: 'Warning',
            dialog_type: (this.props.item.user_status === 'Enabled') ? 'disable' : 'enable',
            show_dialog: (this.props.item.user_status === 'Enabled') ? true : false
        };

        this.props.dispatchDialogUsers(users);

        if (this.props.item.user_status === 'Disabled') {
            const user = {
                uid: this.props.item.uid,
                user_status: (this.props.item.user_status === 'Enabled') ? "Disabled" : "Enabled"
            }

            this.props.updateUserStatus(user);
        }
    }

    updateRole() {
        const user = {
            uid: this.props.item.uid,
            user_role: (this.props.item.user_role === 'Admin') ? "User" : "Admin"
        }

        this.props.updateUserRole(user);
    }

    removeUser() {
        let users = {
            id: this.props.item.uid,
            name: this.props.item.id,
            status: this.props.item.user_status,
            dialog_title: 'Warning',
            dialog_type: 'trash',
            show_dialog: true
        };

        this.props.dispatchDialogUsers(users);       
    }

    getRegistrationStatusClass() {
        const { item } = this.props;

        let registrationStatusClass = '';

        if (item.registration_status === 'Confirmed') {
            registrationStatusClass = 'statusActive';
        }
        else if (item.registration_status === 'Pending') {
            registrationStatusClass = 'statusPending';
        }

        return registrationStatusClass;
    }

    getUserStatusClass() {
        const { item } = this.props;

        let userStatusClass = '';

        if (item.user_status === 'Enabled') {
            userStatusClass = 'statusActive';
        }
        else if (item.user_status === 'Disabled') {
            userStatusClass = 'statusSuspend';
        }

        return userStatusClass;
    }

    render() {
        const { headers, item, role } = this.props;
        const { show_action, user_id } = this.props.sticky;
        
        return (
            <tr id={"grid-row-" + item.id}>
                <td id={"users-list-id-" + item.uid} onClick={() => this.displayUser(item.id.toLowerCase())}>
                    <img src={((item.picture !== undefined) ? "data:image/jpeg;base64," : "") + item.picture} className="userPhoto" alt="" />
                </td>
                <td onClick={() => this.displayUser(item.id.toLowerCase())}>
                    <b className="userName">{item.id}</b>
                    <p className="fullName">{item.full_name}</p>
                </td>
                <td onClick={() => this.displayUser(item.id.toLowerCase())}>
                    <span className="icon icon-wrd-building"></span>
                    <span className="date">{item.organization}</span>
                </td>
                <td onClick={() => this.displayUser(item.id.toLowerCase())}>
                    <span className="icon icon-wrd-bookmark"></span>
                    <span className="date">{item.user_role}</span>
                </td>
                <td onClick={() => this.displayUser(item.id.toLowerCase())}>
                    <div>
                        <p>
                            <span className="icon icon-wrd-mail-closed"></span>
                            <span>{item.email_address}</span>
                        </p>
                        <p>
                            <span className="icon icon-wrd-phone"></span>
                            <span>{item.phone}</span>
                        </p>
                    </div>
                </td>
                <td onClick={() => this.displayUser(item.id.toLowerCase())}>
                    <span className="icon icon-wrd-registration"></span>
                    <span className={this.getRegistrationStatusClass()}>{item.registration_status}</span>
                </td>
                <td onClick={() => this.displayUser(item.id.toLowerCase())}>
                    <span className={this.getUserStatusClass()}>{item.user_status}</span>
                </td>
                <td id={"grid-item-properties-" + item.id} onClick={() => this.displayUser(item.id.toLowerCase())} style={{ display: 'none' }}>
                    {headers.filter((header, i) => i !== 0).map((header, i) => (
                        <div key={header.id}>
                            <div className="grid-properties-header">{header.header_name}
                                {(i === 0) &&
                                    <div className="grid-properties-item">
                                        <b className="userName">{item.id}</b>
                                        <p className="fullName">{item.full_name}</p>
                                    </div>
                                }
                                {(i === 1) &&
                                    <div className="grid-properties-item">
                                        <span className="icon icon-wrd-building"></span>
                                        <span className="date">{item.organization}</span>
                                    </div>
                                }
                                {(i === 2) &&
                                    <div className="grid-properties-item">
                                        <span className="icon icon-wrd-bookmark"></span>
                                        <span className="date">{item.user_role}</span>
                                    </div>
                                }
                                {(i === 3) &&
                                    <div className="grid-properties-item">
                                        <div>
                                            {(item.email_address !== "") &&
                                                <p>
                                                    <span className="icon icon-wrd-mail-closed"></span>
                                                    <span>{item.email_address}</span>
                                                </p>
                                            }
                                            {(item.phone !== "") &&
                                                <p>
                                                    <span className="icon icon-wrd-phone"></span>
                                                    <span>{item.phone}</span>
                                                </p>
                                            }
                                        </div>
                                    </div>
                                }
                                {(i === 4) &&
                                    <div className="grid-properties-item">
                                        <span className="icon icon-wrd-registration"></span>
                                        <span className={this.getRegistrationStatusClass()}>{item.registration_status}</span>
                                    </div>
                                }
                                {(i === 5) &&
                                    <div className="grid-properties-item-last">
                                        <span className={this.getUserStatusClass()}>{item.user_status}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                </td>
                <td id={"users-list-sticky-action-" + item.uid} onClick={() => this.displayAction(item.uid)} onBlur={() => this.hideAction()} className="stickyAction">
                    <div>
                        <button className="tableActionBtn">
                            <span className="icon-wrd-more"></span>
                        </button>
                        <ul className={((user_id === item.uid) && show_action) ? "action show-action" : "action hide-action"}>
                            <li>
                                <Link to={routeCodes.USERS_LIST} onMouseDown={() => this.getDetail()}>
                                    <span className="icon-wrd-eye"></span> 
                                    <label>View Details</label>
                                </Link>
                            </li>
                            {(role.user_role === 'Admin') &&
                                <li>
                                <Link to={routeCodes.USERS_LIST} className="suspend" onMouseDown={() => this.updateRole()}>
                                        <span className="icon-wrd-bookmark"></span>
                                        <label>{(item.user_role === 'Admin') ? "Set as Normal":"Set as Admin"}</label>
                                    </Link>
                                </li>
                            }
                            <li>
                                <Link to={routeCodes.USERS_LIST} onMouseDown={() => this.updateStatus()}>
                                    <span className={((item.user_status === 'Enabled') ? 'icon-lock icon-wrd-lock' : 'icon-unlock icon-wrd-unlock')}></span>
                                    <label className={(item.user_status === "Disabled") ? "sticky-action-enable" : "sticky-action-disable"}>{(item.user_status === "Disabled") ? "Enable" : "Disable"}</label>
                                </Link>
                            </li>
                            <li>
                                <Link to={routeCodes.USERS_LIST} className="remove" onMouseDown={() => this.removeUser()}>
                                    <span className="icon-wrd-trash"></span>
                                    <label className="sticky-action-disable">Remove</label>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
        );
    }
}

const mapStateToProps = ({ sticky, pagination, sorting, search, role }) => ({
    sticky: sticky.users,
    pagination: pagination.user,
    search: search.user,
    sorting: sorting.user,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchStickyUsers: (data) => dispatch(updateStickyUsersAction(data)),
    dispatchDialogUsers: (dialog) => dispatch(updateDialogUsersAction(dialog)),
    updateUserStatus: (user) => dispatch(updateStickyUserStatusWithThunk(user)),
    updateUserRole: (user) => dispatch(updateStickyUserRoleWithThunk(user))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GridItem));