import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner'
import PortalSettingsNewsItem from './PortalSettingsNewsItem';
import { updatePortalSettingNewsAction } from '../../../../actions/updatePortalSettingNewsAction';
import {
    getPortalSettingNewsWithThunk
} from '../../../../api/portalSettingsThunk';
class PortalSettingsNews extends Component {
    constructor(props) {
        super(props);
        this.addPortalSettingNews = this.addPortalSettingNews.bind(this);
    }

    static propTypes = {
        settingNews: PropTypes.object.isRequired,
        dispatchUpdateSettingNews: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired
    };

    addPortalSettingNews() {
        const { settingNews } = this.props;
        let news = settingNews.news;

        let newsSetting = {
            ...settingNews,
            news
        };
        let tempLength = newsSetting.news.length + 1;
        var newObj = {
            id: "temp" + tempLength,
            news_title: "",
            news_description: "",
            image: "",
            status: ""
        }
        newsSetting.news.unshift(newObj);

        this.props.dispatchUpdateSettingNews(newsSetting);
    }

    componentDidMount() {
        this.props.getPortalSettingNews();
    }

    render() {
        const { settingNews, loading } = this.props;

        return (
            <div className="row mt-5">
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                <div className="col-md-12">
                    <div className="wsHomeSection">
                        <h2 className="float-left mt-2 mr-4">News</h2>
                        <button className="btn icoTextBtn btn-wrd_default mb-4" onClick={() => this.addPortalSettingNews()}><span className="icon-wrd-add"></span>
                            Add News</button>
                        {(settingNews.news !== undefined && settingNews.news.length > 0) ?
                            settingNews.news.map((item) => (
                                <PortalSettingsNewsItem key={item.id} item={item} />
                            ))
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ portalSettings, loading }) => ({
    settingNews: portalSettings.setting_news,
    loading: loading.portal_setting_news.loading,
});

const mapDispatchToProps = (dispatch) => ({
    
    getPortalSettingNews: () => dispatch(getPortalSettingNewsWithThunk()),
    dispatchUpdateSettingNews: (settingNews) => dispatch(updatePortalSettingNewsAction(settingNews)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalSettingsNews));