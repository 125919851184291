import { GET_CATEGORIES, UPDATE_CATEGORIES, CLEAR_CATEGORIES } from '../actions/index';

const getCategories = (categories = [], action) => {
    let itemsKeys = [];
    let results = [];

    if (action.categories.length > 0) {
        let categoriesKeys = action.categories.reduce((keys, category) => {
            itemsKeys.push(category.category_id);
            keys = itemsKeys;
            return keys;
        }, []);

        let result = categories.filter((category) => {
            return categoriesKeys.indexOf(category.category_id) === -1;
        });

        results = result.concat(action.categories);
        return results;
    }
    else {
        return results;
    }
}

const updateCategories = (categories = [], action) => {
    const categs = categories.map((item) => {
        const changesCategories = action.categories.filter((change) => change.category_id === item.category_id)
        if (changesCategories.count > 0) {
            return changesCategories[0];
        }

        return item
    })

    return categs;
}

const clearCategories = (action) => {
    return action.categories;
}

export const categories = (categories = [], action) => {
    switch (action.type) {
        case GET_CATEGORIES: return getCategories(categories, action);
        case UPDATE_CATEGORIES: return updateCategories(categories, action);
        case CLEAR_CATEGORIES: return clearCategories(action);
        default: return categories;
    }
};