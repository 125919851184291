import {
    GET_PORTAL_SETTING_BANNER, CLEAR_PORTAL_SETTING_BANNER, UPDATE_PORTAL_SETTING_BANNER,
    GET_PORTAL_SETTING_FEATURE, CLEAR_PORTAL_SETTING_FEATURE, UPDATE_PORTAL_SETTING_FEATURE,
    GET_PORTAL_SETTING_NEWS, CLEAR_PORTAL_SETTING_NEWS, UPDATE_PORTAL_SETTING_NEWS,
    GET_PORTAL_SETTING_LOGIN, CLEAR_PORTAL_SETTING_LOGIN, UPDATE_PORTAL_SETTING_LOGIN,
    GET_PORTAL_SETTING_EMAIL, CLEAR_PORTAL_SETTING_EMAIL, UPDATE_PORTAL_SETTING_EMAIL,
    GET_PORTAL_SETTING_THEME, CLEAR_PORTAL_SETTING_THEME, UPDATE_PORTAL_SETTING_THEME,
    UPDATE_PORTAL_SETTING_TAB
} from '../actions/index';

const initialPortalSettingState = {
    setting_banner: {},
    setting_feature: {},
    setting_news: {},
    setting_login: {},
    setting_email: {},
    setting_theme: {},
    active_tab: "Banners"
}

export const portalSettings = (state = initialPortalSettingState, action) => {
    const { setting_banner, setting_feature, setting_news, setting_login, setting_email, setting_theme, active_tab } = action

    switch (action.type) {
        case GET_PORTAL_SETTING_BANNER:
            return {
                ...state,
                setting_banner
            }
        case CLEAR_PORTAL_SETTING_BANNER:
            return {
                ...state,
                setting_banner: initialPortalSettingState.setting_banner
            }
        case UPDATE_PORTAL_SETTING_BANNER:
            return {
                ...state,
                setting_banner
            }
        case GET_PORTAL_SETTING_FEATURE:
            return {
                ...state,
                setting_feature
            }
        case CLEAR_PORTAL_SETTING_FEATURE:
            return {
                ...state,
                setting_feature: initialPortalSettingState.setting_feature
            }
        case UPDATE_PORTAL_SETTING_FEATURE:
            return {
                ...state,
                setting_feature
            }
        case GET_PORTAL_SETTING_NEWS:
            return {
                ...state,
                setting_news
            }
        case CLEAR_PORTAL_SETTING_NEWS:
            return {
                ...state,
                setting_news: initialPortalSettingState.setting_news
            }
        case UPDATE_PORTAL_SETTING_NEWS:
            return {
                ...state,
                setting_news
            }
        case GET_PORTAL_SETTING_LOGIN:
            return {
                ...state,
                setting_login
            }
        case UPDATE_PORTAL_SETTING_LOGIN:
            return {
                ...state,
                setting_login
            }
        case CLEAR_PORTAL_SETTING_LOGIN:
            return {
                ...state,
                setting_login: initialPortalSettingState.setting_login
            }       
        case GET_PORTAL_SETTING_EMAIL:
            return {
                ...state,
                setting_email
            }
        case UPDATE_PORTAL_SETTING_EMAIL:
            return {
                ...state,
                setting_email
            }
        case CLEAR_PORTAL_SETTING_EMAIL:
            return {
                ...state,
                setting_email: initialPortalSettingState.setting_email
            }
        case GET_PORTAL_SETTING_THEME:
            return {
                ...state,
                setting_theme
            }
        case UPDATE_PORTAL_SETTING_THEME:
            return {
                ...state,
                setting_theme
            }
        case CLEAR_PORTAL_SETTING_THEME:
            return {
                ...state,
                setting_theme: initialPortalSettingState.setting_theme
            }
        case UPDATE_PORTAL_SETTING_TAB:
            return {
                ...state,
                active_tab
            }
        default:
            return state;
    }
};