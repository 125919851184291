import {
    getUserByIdApi,
    updateUserApi
} from '../backend/domain/users';

import { getAccountAction } from '../actions/getAccountAction';
import { clearAccountAction } from '../actions/clearAccountAction';
import { updateAccountAction } from '../actions/updateAccountAction';
import { loadingAccountAction } from '../actions/loadingAccountAction';
import { loadingChangePasswordAction } from '../actions/loadingChangePasswordAction';
import { getSessionStorage } from '../utils/sessionStorage';
import { handleUserUpdateError } from '../utils/errorMessages';

export const getAccountWithThunk = (userId, withPicture, width, height) =>
    (dispatch) => {
        dispatch(loadingAccountAction(true));
        dispatch(loadingChangePasswordAction(true));
        dispatch(clearAccountAction([]));

        if (userId === "") {
            const sessionRole = getSessionStorage("role");

            if (sessionRole !== "") {
                const role = JSON.parse(sessionRole);
                userId = role.user_id;
            }
            else {
                return;
            }
        }

        getUserByIdApi(userId, withPicture,  width, height)
            .then((response) => {
                if (response !== undefined && !response.error) {
                    dispatch(getAccountAction(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingAccountAction(false));
                dispatch(loadingChangePasswordAction(false));
                return response;
            });
    };

export const updateAccountWithThunk = (account) =>
    (dispatch) => {
        dispatch(loadingAccountAction(true));

        let user = account;
        let data = {
            uid: String(user.uid),
            id: String(user.id),
            full_name: String(user.full_name),
            role: String(user.role),
            registration_status: String(user.registration_status),
            user_status: String(user.user_status),
            user_role: String(user.user_role),
            use_personal: user.use_personal,
            picture: String(user.picture),
            is_picture_changed: user.is_picture_changed,
            image_aspect_ratio: String(user.image_aspect_ratio),
        }

        const user_personal = {
            address_1: String(user.personal.address_1),
            address_2: String(user.personal.address_2),
            zip_code: String(user.personal.zip_code),
            country: String(user.personal.country),
            city: String(user.personal.city),
            state: String(user.personal.state),
            phone: String(user.personal.phone),
            mobile_phone: String(user.personal.mobile_phone),
            email_address: String(user.personal.email_address)
        }

        let user_public = {
            organization: String(user.public.organization),
            att: String(user.public.att),
            address_1: String(user.public.address_1),
            address_2: String(user.public.address_2),
            zip_code: String(user.public.zip_code),
            country: String(user.public.country),
            city: String(user.public.city),
            state: String(user.public.state),
            phone: String(user.public.phone),
            mobile_phone: String(user.public.mobile_phone),
            email_address: String(user.public.email_address)
        }

        data.personal = user_personal;
        data.public = user_public;

        updateUserApi(data)
            .then((response) => {
                dispatch(updateAccountAction(account));
                return response;
            })
            .then((response) => {
                handleUserUpdateError(dispatch, response.status, response.error_messages, true);
                return response;
            })
            .then((response) => {
                dispatch(loadingAccountAction(false));
                return response;
            });
    };