import { getClientIp } from './ipAddressApi';

import { getClientIpAction } from '../actions/getClientIpAction';

export const getClientIpWithThunk = () =>
    (dispatch) => {
        getClientIp()
            .then((response) => {
                if (!response.error) {
                    dispatch(getClientIpAction(response));
                }
                return response;
            })
            .then((response) => {
                return response;
            });
    };
