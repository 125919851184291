import environment from 'environment';
import { createImage, updateImage } from '../../utils/snapshot';

const configApp = require('../../configurations/app');
const config = require('../../environments/' + environment.settings);
const axios = require('axios');

async function getApplicationById(applicationId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "application/" + applicationId);

    return results;
}

async function getApplicationByName(applicationName) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "application/id/" + applicationName);

    return results;
}

async function getApplicationAvailability(applicationId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "application/" + applicationId + "/ping")
        .catch(function (error) { return error.response });

    return results;
}

async function createApplication(application) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "application", application)
        .catch(function (error) { return error.response });
    return results;
}

async function updateApplication(application) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "application", application)
        .catch(function (error) { return error.response });
    return results;
}

async function updateApplicationStatus(applicationId, status) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "application/" + applicationId + "/Enable?status=" + ((status === 'Enabled') ? 'true' : 'false'));
    return results;
}

async function updateApplicationCategory(applicationId, categoryId) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const results = (categoryId !== "") ? await axios.put(commandBaseUrl + "application/" + applicationId + "/category/" + categoryId)
        : await axios.put(commandBaseUrl + "application/" + applicationId + "/category/reset");
    return results;
}

async function deleteApplication(applicationId) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.delete(commandBaseUrl + "application/" + applicationId);
    return results;
}

async function createLogo(applicationId, content) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const url = commandBaseUrl + "application/logo/" + applicationId;

    return await createImage(content, url);
}

async function updateLogo(applicationId, content) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const url = commandBaseUrl + "application/logo/" + applicationId;

    return await updateImage(content, url);;
}

async function updateSnapshot(applicationId, content) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const url = commandBaseUrl + "application/snapshot/" + applicationId;

    return await updateImage(content, url);;
}

async function getApplicationLogo(id, width, height) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const url = queryBaseUrl + "application/logo/" + id + "/" + width + "/" + height;

    let results;
    try {
        const response = await axios.get(url, { responseType: 'arraybuffer' });
        results = Buffer.from(response.data, 'binary').toString('base64');
    }
    catch (err) {
        if (err.response !== undefined) {
            if (err.response.status === 404) {
                return results;
            }
        }
    }
    return results;
}

async function getApplicationSnapshot(id, width, height) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const url = queryBaseUrl + "application/snapshot/" + id + "/" + width + "/" + height;

    let results;
    try {
        const response = await axios.get(url, { responseType: 'arraybuffer' });
        results = Buffer.from(response.data, 'binary').toString('base64');
    }
    catch (err) {
        if (err.response !== undefined) {
            if (err.response.status === 404) {
                return results;
            }
        }
    }
    return results;
}

async function getApplicationSearchList(keyword, pageIndex, pageSize) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "application/search", {
        params: {
            keyword: keyword,
            pageIndex: pageIndex,
            pageSize: pageSize,
            logo: true
        }
    });

    return results;
}

async function getApplicationList(pageIndex, pageSize) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "application/list", {
        params: {
            pageIndex: pageIndex,
            pageSize: pageSize,
            logo: true
        }
    });

    return results;
}

async function getAvailableApplicationList(withLogo) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "application/list", {
        params: {
            pageIndex: 1,
            pageSize: configApp.maximumApplications,
            logo: withLogo
        }
    });

    return results;
}

export {
    getApplicationById,
    getApplicationByName,
    getApplicationAvailability,
    createApplication,
    updateApplication,
    updateApplicationStatus,
    updateApplicationCategory,
    deleteApplication,
    createLogo,
    updateLogo,
    updateSnapshot,
    getApplicationLogo,
    getApplicationSnapshot,
    getApplicationSearchList,
    getApplicationList,
    getAvailableApplicationList
}