import environment from 'environment';
import { updateImage } from '../../utils/snapshot';

const config = require('../../environments/' + environment.settings);
const axios = require('axios');

async function getWorkspaceSettings(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/setting/" + workspaceId);

    return results;
}

async function getWorkspaceSettingLogoById(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/setting/logo/" + workspaceId);

    return results;
}

async function getWorkspaceThemeSettings(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/setting/theme");

    return results;
}

async function getWorkspaceSettingById(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/settings")
        .catch(function (error) { return error.response; });
    return results;

}

async function getWorkspaceThemeSettingImplementationById(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/theme")
        .catch(function (error) { return error.response; });
    return results;
}

async function getWorkspaceCustomThemeFile(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/setting/theme/custom/style")
        .catch(function (error) { return error.response; });
    return results;
}

async function getWorkspaceSettingBannerById(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/banner")
        .catch(function (error) { return error.response; });
    return results;
}

async function getWorkspaceSettingIncludeById(workspaceId, includeOnly) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/settings?includeOnly=" + includeOnly)
        .catch(function (error) { return error.response; });
    return results;
}

async function getWorkspaceLoginSettingById(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/setting/login")
        .catch(function (error) { return error.response; });
    return results;
}

async function getWorkspaceLoginBackgroundSettingById(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/setting/login/background?thumbnail=false")
        .catch(function (error) { return error.response; });
    return results;
}

async function getWorkspaceLoginWelcomeBackgroundSettingById(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/setting/login/welcome-background?thumbnail=false")
        .catch(function (error) { return error.response; });
    return results;
}

async function getWorkspaceEmailSettingById(workspaceId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/setting/email")
        .catch(function (error) { return error.response; });
    return results;
}

async function getWorkspaceThemeSettingById(id) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + id + "/setting/theme")
        .catch(function (error) { return error.response; });
    return results;
}

async function updateWorkspaceSetting(workspaceSetting) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "workspace/setting/" + workspaceSetting.id, workspaceSetting)
        .catch(function (error) { return error.response; });
    return results;
}

async function updateWorkspaceSettingLogo(workspaceId, content) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const url = commandBaseUrl + "workspace/setting/image/" + workspaceId;

    return await updateImage(content, url);
}

async function updateWorkspaceSettingBanner(workspaceId, content) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const url = commandBaseUrl + "workspace/setting/banner/" + workspaceId;

    return await updateImage(content, url);
}

async function updateWorkspaceLoginSetting(workspaceId, workspaceSetting) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "workspace/" + workspaceId + "/setting/login/", workspaceSetting)
        .catch(function (error) { return error.response; });
    return results;
}

async function updateWorkspaceLoginSettingBackground(workspaceId, content) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const url = commandBaseUrl + "workspace/" + workspaceId + "/setting/login/background";

    return await updateImage(content, url);;
}

async function updateWorkspaceWelcomeSettingBackground(workspaceId, content) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const url = commandBaseUrl + "workspace/" + workspaceId + "/setting/login/welcome-background";

    return await updateImage(content, url);;
}

async function updateWorkspaceEmailSetting(workspaceId, workspaceSetting) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "workspace/" + workspaceId + "/setting/email/", workspaceSetting)
        .catch(function (error) { return error.response; });
    return results;
}

async function updateWorkspaceThemeSetting(workspaceId, workspaceSetting) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const results = await axios.put(commandBaseUrl + "workspace/" + workspaceId + "/setting/theme/", workspaceSetting)
        .catch(function (error) { return error.response; });
    return results;
}

export {
    getWorkspaceSettings,
    getWorkspaceSettingLogoById,
    getWorkspaceThemeSettings,
    getWorkspaceThemeSettingImplementationById,
    getWorkspaceCustomThemeFile,
    getWorkspaceSettingById,
    getWorkspaceSettingBannerById,
    getWorkspaceSettingIncludeById,
    getWorkspaceLoginSettingById,
    getWorkspaceLoginBackgroundSettingById,
    getWorkspaceLoginWelcomeBackgroundSettingById,
    getWorkspaceEmailSettingById,
    getWorkspaceThemeSettingById,
    updateWorkspaceSetting,
    updateWorkspaceSettingLogo,
    updateWorkspaceSettingBanner,
    updateWorkspaceLoginSetting,
    updateWorkspaceLoginSettingBackground,
    updateWorkspaceWelcomeSettingBackground,
    updateWorkspaceEmailSetting,
    updateWorkspaceThemeSetting
}