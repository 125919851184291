import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateWorkspacesApplicationsSettingsAction } from '../../../../actions/updateWorkspacesApplicationsSettingsAction';

class WorkspacesApplicationsSettingsSlider extends Component {
    constructor(props) {
        super(props);
        this.handleItemLockedChange = this.handleItemLockedChange.bind(this);
        this.handleItemChange = this.handleItemChange.bind(this);
    }

    static propTypes = {
        id: PropTypes.number.isRequired,
        workspacesApplicationsSettings: PropTypes.array.isRequired,
        dispatchWorkspacesApplicationsSettings: PropTypes.func.isRequired,     
        groupLocked: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired        
    };

    handleItemLockedChange() {
        const { id, workspacesApplicationsSettings } = this.props;

        const items = workspacesApplicationsSettings.filter((item) => item.id === id);
        const changes = items.map((item) => {
            item.locked = !item.locked;
            return item;
        })

        this.props.dispatchWorkspacesApplicationsSettings(changes);
        this.props.groupLocked();
    }

    handleItemChange(e) {
        const { id, workspacesApplicationsSettings } = this.props;

        const items = workspacesApplicationsSettings.filter((item) => item.id === id);
        const changes = items.map((item) => {
            item.value = e.target.value;
            return item;
        })

        this.props.dispatchWorkspacesApplicationsSettings(changes);
    }

    render() {
        const { id, workspacesApplicationsSettings,role } = this.props;
        const item = workspacesApplicationsSettings.filter((setting) => setting.id === id)[0];
        const itemProperty = item.component_property;
        const itemValue = item.value;

        return (
            <div className={(role.user_role === 'Admin') ? 'groupForm' : 'groupForm settings-group-form'}>
                {(role.user_role === 'Admin') &&
                    <label className="btn iconBtn customLockCheckbox">
                        <input type="checkbox" className="lockInput" checked={!item.locked} readOnly />
                        <span className="icon-wrd-unlock cursor-pointer" onClick={this.handleItemLockedChange}></span>
                        <span className="icon-wrd-lock cursor-pointer" onClick={this.handleItemLockedChange}></span>
                    </label>
                }
                <label>{item.title}</label>
                <input type="range" className="custom-range" min={itemProperty.Min} max={itemProperty.Max} value={itemValue} onChange={this.handleItemChange} />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    dispatchWorkspacesApplicationsSettings: (settings) => dispatch(updateWorkspacesApplicationsSettingsAction(settings))
});

export default withRouter(connect(null, mapDispatchToProps)(WorkspacesApplicationsSettingsSlider));