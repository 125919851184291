import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { getProfileAccessWithThunk } from '../../../api/usersThunk';
import SidebarContainer from '../../Sidebar/SidebarContainer';
import { changeNavigationAction } from '../../../actions/changeNavigationAction';
import ProfileAccessHeader from './ProfileAccessHeader';
import ProfileAccessBody from './ProfileAccessBody';
import { clearUserAccessAction } from '../../../actions/clearUserAccessAction';
import ErrorSuccessModal from '../../Shared/ErrorSuccessModal';
import { getSessionStorage } from '../../../utils/sessionStorage';

class ProfileAccessContainer extends Component {
    static propTypes = {
        userAccess: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired,
        getProfileAccess: PropTypes.func.isRequired
    };    

    changeNavigation(name) {
        let navigation = {
            name
        };
        this.props.dispatchNavigation(navigation);
    }

    scrollToTop = () => {
        this.detailStart.current.scrollIntoView({ behavior: 'smooth' })
    }

    componentDidMount() {        
		const { role, loading } = this.props;		
        if (loading === false) {
            let width = Math.ceil((window.innerWidth - 560) * (3 / 12));
            let height = width;
            let userId = role.user_id;
            if (userId === "") {
                const sessionRole = getSessionStorage("role");

                if (sessionRole !== "") {
                    const role = JSON.parse(sessionRole);
                    userId = role.user_id;
                }
            }
            if (userId !== "") {
                this.props.getProfileAccess(userId, width, height);
            }
		}
        this.changeNavigation('Profile');
    }

    componentWillUnmount() {
        this.props.clearUserAccess();
    }

    render() {
        const { userAccess, loading, loadingTheme } = this.props;

        if (userAccess.user === undefined)
            return (
                <div>
                    {(loading || loadingTheme) &&
                        <div className="loader-container">
                            <Loader
                                type="Ball-Triangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                </div>
            );

        return (
            <div>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <div ref={this.detailStart} />
                        <SidebarContainer />
                        <main>
                            <div className="mapBackground" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <ProfileAccessHeader />
                                    <ProfileAccessBody />
                                </div>
                            </div>
                        </main>
                        <ErrorSuccessModal type="error" />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ navigation, userAccess, loading, role }) => ({
    navigation,
    userAccess,
    loading: loading.user.loading,
    loadingTheme: loading.theme.loading,
    role: role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchNavigation: (navigation) => dispatch(changeNavigationAction(navigation)),
    getProfileAccess: (id, width, height) => dispatch(getProfileAccessWithThunk(id, width, height)),
    clearUserAccess: (userAccess) => dispatch(clearUserAccessAction(userAccess))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileAccessContainer));