import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner'
import UsersInfoWorkspacesBody from './UsersInfoWorkspacesBody';
import { getUserAvailableWorkspacesWithThunk } from '../../../api/userAvailableWorkspacesThunk';
import { defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize } from '../../../configurations/app';
import { saveLocalStorage, getLocalStorage } from '../../../utils/localStorage';
import { getSessionStorage } from '../../../utils/sessionStorage';

class UsersInfoWorkspacesContainer extends Component {
    static propTypes = {
        availableWorkspaces: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        role: PropTypes.object.isRequired,
        getUserAvailableWorkspaces: PropTypes.func.isRequired
    };

    componentWillMount() {
        const { role } = this.props;
        const { redirectUrl } = this.props;

        let userId = role.user_id;
        let userRole = role.user_role;
        if (userId === undefined || userId === "") {
            const sessionRole = getSessionStorage("role");
            if (sessionRole !== "") {
                const role = JSON.parse(sessionRole);
                userId = role.user_id;
                userRole = role.user_role;
            }
        }

        saveLocalStorage(userId + '-direct_access', redirectUrl.url_redirect);
        if (userRole.toLowerCase() !== 'admin') {
            const directAccess = getLocalStorage(userId + '-direct_access');
            const lastUsedWorkspace = getLocalStorage(userId + '-last_used_workspace');
            if (directAccess === "" && lastUsedWorkspace === "") {
                this.props.getUserAvailableWorkspaces(userId, userRole, defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize);
            }
        }
    }

    render() {
        const { availableWorkspaces, role, loading, loadingTheme, portalLogin } = this.props;
        const directAccess = getLocalStorage(role.user_id + '-direct_access');
        const lastUsedWorkspace = getLocalStorage(role.user_id + '-last_used_workspace');

        if ((availableWorkspaces === undefined || availableWorkspaces.user_id === undefined) && ((role.user_role.toLowerCase() !== 'admin') && (directAccess === "" && lastUsedWorkspace === "")))
            return (
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                    {
                        (portalLogin.login_background_selected === 'Custom' && portalLogin.login_background_custom_image !== undefined && portalLogin.login_background_custom_image !== '') ?
                            <img src={"data:image/png;base64," + portalLogin.login_background_custom_image}
                                className="crop-welcome1820" alt="" />
                            : (portalLogin.login_background_selected === 'NoBackground' && portalLogin.login_background_color !== '') ?
                                <div style={{ backgroundColor: portalLogin.login_background_color }} className="crop-welcome1820"> </div>
                                : null

                    }
                    <div>
                        {(loading || loadingTheme) &&
                            <div className="loader-container">
                                <Loader
                                    type="Ball-Triangle"
                                    color="#00BFFF"
                                    height="100"
                                    width="100"
                                />
                            </div>
                        }
                    </div>
                </div>
            );

        return (
            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                {
                    (portalLogin.login_background_selected === 'Custom' && portalLogin.login_background_custom_image !== undefined && portalLogin.login_background_custom_image !== '') ?
                        <img src={"data:image/png;base64," + portalLogin.login_background_custom_image}
                            className="crop-welcome1820" alt="" />
                        : (portalLogin.login_background_selected === 'NoBackground' && portalLogin.login_background_color !== '') ?
                            <div style={{ backgroundColor: portalLogin.login_background_color }} className="crop-welcome1820"> </div>
                            : null

                }
                <div>
                    {loading &&
                        <div className="loader-container">
                            <Loader
                                type="Ball-Triangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                    {!loadingTheme &&
                        <UsersInfoWorkspacesBody />
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ availableWorkspaces, loading, role, redirectUrl, portalLogin }) => ({
    availableWorkspaces,
    role,
    loading: loading.user_available_workspaces.loading,
    loadingTheme: loading.theme.loading,
    redirectUrl,
    portalLogin: portalLogin.portal_login
});

const mapDispatchToProps = (dispatch) => ({
    getUserAvailableWorkspaces: (userId, userRole, pageIndex, pageSize) => dispatch(getUserAvailableWorkspacesWithThunk(userId, userRole, pageIndex, pageSize))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersInfoWorkspacesContainer));