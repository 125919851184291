import { normalizeTextDescription } from '../../utils/text';

const sort = require('fast-sort');
const workspacesCategoriesApi = require('../api/workspaceCategories');

export async function getWorkspaceApplicationCategoryListApi(workspaceId) {
    const results = await workspacesCategoriesApi.getWorkspaceApplicationCategoryList(workspaceId);
    return mapToWorkspaceApplicationCategoryFrontend(results.data.results);
}

export async function getWorkspaceCategoryByIdApi(workspaceId, categoryId) {
    const results = await workspacesCategoriesApi.getWorkspaceCategoryById(workspaceId, categoryId);
    return mapToWorkspaceCategoryFrontend(results.data);
}

export async function getWorkspaceCategoryIdByNameApi(workspaceId, categoryName) {
    const results = await workspacesCategoriesApi.getWorkspaceCategoryIdByName(workspaceId, categoryName);
    return results.data;
}

export async function createWorkspaceCategoryApi(workspaceId, category) {
    let data = {};
    let id = '';
    let error_messages = '';

    const mappingCategory = await mapToWorkspaceCategoryBackendForCreate(category);
    const results = await workspacesCategoriesApi.createWorkspaceCategory(workspaceId, mappingCategory);

    if (results.status === 200) {
        id = results.data
    }
    else {
        error_messages = results.data;
    }

    data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

export async function updateWorkspaceCategoryApi(workspaceId, category) {
    let data = {};
    let id = '';
    let error_messages = '';

    const mappingCategory = await mapToWorkspaceCategoryBackendForUpdate(category);
    const results = await workspacesCategoriesApi.updateWorkspaceCategory(workspaceId, mappingCategory);

    if (results.status === 200) {
        id = results.data
    }
    else {
        error_messages = results.data;
    }

    data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

export async function updateOrderWorkspacesCategoriesApi(workspaceId, categoryOrders) {
    const res = await workspacesCategoriesApi.updateOrderWorkspacesCategories(workspaceId, categoryOrders);
    return res;
}

export async function deleteWorkspaceCategoryApi(workspaceId, categoryId) {
    const results = await workspacesCategoriesApi.deleteWorkspaceCategory(workspaceId, categoryId);

    const data = {
        status: results.data
    }

    return data;
}

function mapToWorkspaceCategoryBackendForCreate(data) {
    let category = {};

    category.name = data.category_title;
    category.description = data.category_description;

    return category;
}

function mapToWorkspaceCategoryBackendForUpdate(data) {
    let category = {};

    category.id = data.category_id;
    category.name = data.category_title;
    category.description = data.category_description;

    return category;
}

function mapToWorkspaceApplicationCategoryFrontend(data) {
    let categories = [];
    for (let i in data) {
        let c = data[i];

        let category = {
            category_id: c.id,
            category_title: c.name,
            category_description: normalizeTextDescription(c.description),
            order: c.order
        }
        categories.push(category);
    }

    sort(categories).asc(['order']);
    return categories;
}

function mapToWorkspaceCategoryFrontend(data) {
    let category = {
        category_id: data.id,
        category_title: data.name,
        category_description: normalizeTextDescription(data.description)
    }

    return category;
}