import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { routeCodes } from '../../../configurations/routes';
import { updateWorkspaceAction } from '../../../actions/updateWorkspaceAction';

class WorkspacesDetailHeader extends Component {
    constructor(props) {
        super(props);
        this.onWorkspaceNameChange = this.onWorkspaceNameChange.bind(this);
        this.state = {
            isWorkspaceNameChecked: props.workspace.workspace_name_locked
        };
        this.handleWorkspaceNameChange = this.handleWorkspaceNameChange.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        dispatchWorkspace: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired
    };

    onWorkspaceNameChange(e) {
        let workspace = {
            ...this.props.workspace,
            temp_workspace_name: e.target.value
        };

        this.props.dispatchWorkspace(workspace);
    }

    handleWorkspaceNameChange() {
        if (this.state.isWorkspaceNameChecked === true) {
            let workspace = {
                ...this.props.workspace,
                workspace_name_locked: false
            };
            this.props.dispatchWorkspace(workspace);
        }
        else {
            let workspace = {
                ...this.props.workspace,
                workspace_name_locked: true
            };
            this.props.dispatchWorkspace(workspace);
        }
        this.setState({ isWorkspaceNameChecked: !this.state.isWorkspaceNameChecked });
    }

    render() {
        const { workspace, role } = this.props;

        return (
            <div className="mainHead">
                <div className="row">
                    <div className="col-12">
                        <h2 className="float-left mt-2 mr-4">Workspace</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="workspaceInfo">
                            <div className="row">
                                <div className="workspaceName col-xl-9 offset-xl-3">
                                    <div className="formWrap">
                                        <div className={(role.user_role === 'Admin') ? 'groupForm m-0' : null}>
                                            {
                                                (role.user_role === 'Admin') ?
                                                    <label className="btn iconBtn customLockCheckbox">
                                                        <input type="checkbox" className="lockInput" onChange={this.handleWorkspaceNameChange} checked={!this.state.isWorkspaceNameChecked} />
                                                        <span className="icon-wrd-unlock"></span>
                                                        <span className="icon-wrd-lock"></span>
                                                    </label>
                                                    : null
                                            }
                                            <div className="inputForm wsNameEdit">
                                                <input style={{ width: '100%' }} id="detail-workspace-name" className="form-control wsNameEditWider" type="text" name="" required defaultValue={(workspace.temp_workspace_name !== undefined || workspace.temp_workspace_name !== undefined) ? workspace.temp_workspace_name : workspace.workspace_name} onChange={this.onWorkspaceNameChange} disabled={role.user_role !== 'Admin' && this.state.isWorkspaceNameChecked} />
                                                <label>Name</label>
                                            </div>
                                        </div>
                                        <div className={(role.user_role === 'Admin') ? "userRole col-xl-7 ml-5" : "userRole col-xl-7"}>{(role.user_role === 'Admin') ? "Administrator" : "Workspace Owner"}</div>
                                    </div>
                                </div>
                            </div>
                            {(role.user_role === 'Admin' || (role.user_role === 'User' && workspace.owner)) ?
                                <nav className="col-lg-5 col-xl-4">
                                    <div className="row text-center">
                                        <div className="col px-0">
                                            <Link
                                                to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/properties"}
                                                title="Workspace"
                                                className="workspaceMenu py-3 active">Workspace
                                            </Link>
                                        </div>
                                        <div className="col px-0">
                                            <Link
                                                to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/applications'}
                                                title="Workspace Applications"
                                                className="workspaceMenu py-3">Applications
                                            </Link>
                                        </div>
                                        <div className="col px-0">
                                            <Link
                                                to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/members'}
                                                title="Workspace Members"
                                                className="workspaceMenu py-3">Members
                                            </Link>
                                        </div>
                                        <div className="col px-0">
                                            <Link
                                                to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/general'}
                                                title="Workspace Settings"
                                                className="workspaceMenu py-3">Settings
                                            </Link>
                                        </div>
                                    </div>
                                </nav> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, role }) => ({
    workspace: detail.workspace,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchWorkspace: (workspace) => dispatch(updateWorkspaceAction(workspace))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesDetailHeader));