import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearWorkspaceAction } from '../../../actions/clearWorkspaceAction';
import { getUserWorkspaceLandingPropertiesByIdApiWithThunk } from '../../../api/workspacesThunk';
import { getWorkspaceLandingSettingWithThunk } from '../../../api/workspacesSettingsThunk';
import { getAccountWithThunk } from '../../../api/accountThunk';
import { getUrlWorkspaceId } from '../../../utils/url';
import Loader from 'react-loader-spinner';
import WorkspacesLandingHeader from '../../Workspaces/Landing/WorkspacesLandingHeader';
import WorkspacesLandingBody from '../../Workspaces/Landing/WorkspacesLandingBody';
import { userLogout } from '../../../api/logoutThunk';
import { getClientIpWithThunk } from '../../../api/ipAddressThunk';
import WorkspacesLandingApplicationModal from '../Modal/WorkspacesLandingApplicationModal';
import { anonymousUserId } from '../../../configurations/app';
import { getSessionStorage } from '../../../utils/sessionStorage';

class WorkspacesLandingContainer extends Component {
    static propTypes = {
        workspace: PropTypes.object.isRequired,
        getUserWorkspaceLandingPropertiesById: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        role: PropTypes.object.isRequired,
        account: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired
    };

    getCurrentWorkspaceId = () => {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    loadWorkspaceLandingProperty = async () => {
        const { workspace, role, loading, getUserWorkspaceLandingPropertiesById } = this.props;
        const workspaceId = this.getCurrentWorkspaceId();

        let userId = "";
        let isAdmin = false;
        if (role.user_id === "") {
            const sessionRole = getSessionStorage("role");

            if (sessionRole !== "") {
                const role = JSON.parse(sessionRole);
                userId = role.user_id;
                isAdmin = (role.user_role === "Admin") ? true : false;
            }
        }
        else {
            userId = role.user_id;
            isAdmin = (role.user_role === "Admin") ? true : false;
        }

        if (userId === "") {
            userId = anonymousUserId;
        }

        if (workspace.id === "" || userId !== "" || (!loading && workspace.id !== "")) {
            await getUserWorkspaceLandingPropertiesById(workspaceId, userId, isAdmin, 339, 156, this.props.history);
        }
    }

    async componentDidMount() {
        await this.loadWorkspaceLandingProperty();
    }

    componentDidUpdate(prevProps) {
        const { role, loading, account } = this.props;
        const { getWorkspaceSettings, getClientIp } = this.props;
        const workspaceId = this.getCurrentWorkspaceId();

        if (prevProps.loading !== loading && !loading) {
            getWorkspaceSettings(workspaceId, "ShowPortalLogo;GoogleAnalytic;ShowWorkspaceLogo");
            getClientIp();
            if (account.id === "") {
                this.props.getAccount(role.user_id, false, 0, 0);
            }
        }

        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.loadWorkspaceLandingProperty();
        }
    }

    componentWillUnmount() {
        this.props.clearWorkspace();
    }

    render() {
        const { workspace, categories, userCategories, loading, loadingTheme } = this.props;
        return (
            <div>
                {(loading || loadingTheme || workspace.id === "" || categories === undefined || userCategories === undefined) &&
                    <div className="loader-container">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loading && !loadingTheme && workspace.id !== "" && categories !== undefined && userCategories !== undefined &&
                    <main className="cancel-main-margin">
                        <div className="container-fluid h-100">
                            <div id="full-body" className="mcp">
                                <WorkspacesLandingHeader />
                                <WorkspacesLandingBody />
                                <WorkspacesLandingApplicationModal />
                            </div>
                        </div>
                    </main>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, loading, role }) => ({
    workspace: detail.workspace,
    categories: detail.workspace.categories,
    userCategories: detail.workspace.user_categories,
    account: detail.account,
    loading: loading.workspace.loading,
    loadingTheme: loading.theme.loading,
    role
});

const mapDispatchToProps = (dispatch) => ({
    getWorkspaceSettings: (workspaceId, includeOnly) => dispatch(getWorkspaceLandingSettingWithThunk(workspaceId, includeOnly)),
    getUserWorkspaceLandingPropertiesById: (workspaceId, userId, isAdmin, snapshotWidth, snapshotHeight, historyProps) => dispatch(getUserWorkspaceLandingPropertiesByIdApiWithThunk(workspaceId, userId, isAdmin, snapshotWidth, snapshotHeight, historyProps)),
    logout: (role, history) => dispatch(userLogout(role, history)),
    getClientIp: () => dispatch(getClientIpWithThunk()),
    getAccount: (userId, withPcture, width, height) => dispatch(getAccountWithThunk(userId, withPcture, width, height)),
    clearWorkspace: () => dispatch(clearWorkspaceAction([]))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesLandingContainer));