import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUrlWorkspaceId } from '../../../utils/url';
import { getSessionStorage } from '../../../utils/sessionStorage';
import { ghead, itemHeight, tHead, defaultUsersSortingBy, defaultUsersSortingType, defaultUsersPageSize } from '../../../configurations/app';
import { clearUsersAction } from '../../../actions/clearUsersAction';
import { clearUsersPaginationAction } from '../../../actions/clearUsersPaginationAction';
import { clearGlobalUsersAction } from '../../../actions/clearGlobalUsersAction';
import { clearGlobalWorkspacesMembersAction } from '../../../actions/clearGlobalWorkspacesMembersAction';
import { getWorkspacesUsersWithThunk, getWorkspacesUsersByIdWithThunk, getWorkspacesUsersSearchWithThunk } from '../../../api/globalUsersThunk';
import Loader from 'react-loader-spinner';
import SidebarContainer from '../../Sidebar/SidebarContainer';
import GlobalUsersListHeader from './GlobalUsersListHeader';
import GlobalUsersListBody from './GlobalUsersListBody';

class GlobalUsersListContainer extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.getUserList = this.getUserList.bind(this);
        this.calculatePageSize = this.calculatePageSize.bind(this);
        this.updateMode = this.updateMode.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        users: PropTypes.array.isRequired,
        role: PropTypes.object.isRequired,
        getWorkspacesUsers: PropTypes.func.isRequired,
        getWorkspacesUsersById: PropTypes.func.isRequired,
        getWorkspacesUsersSearch: PropTypes.func.isRequired,
        clearUsers: PropTypes.func.isRequired,
        clearUsersPagination: PropTypes.func.isRequired,
        clearGlobalUsers: PropTypes.func.isRequired,
        clearGlobalWorkspacesMembers: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired
    };

    state = {
        page: 1,
        size: 0,
        mode: 'list'
    }

    calculatePageSize() {
        let size = defaultUsersPageSize;

        this.setState({ page: 1, size });
        return size;
    }

    getUserList(currentPage) {
        const { page, size, mode } = this.state;
        const { workspace, role, search } = this.props;
        const id = this.getCurrentWorkspaceId();

        if (page < currentPage) {
            if (mode === 'list') {
                if (id !== workspace.workspace_name.toLowerCase().replaceAll(" ", "+")) {
                    let userId = role.user_id;
                    if (userId === "") {
                        const sessionRole = getSessionStorage("role");

                        if (sessionRole !== "") {
                            const role = JSON.parse(sessionRole);
                            userId = role.user_id;
                        }
                    }

                    this.props.getWorkspacesUsersById(id, userId, currentPage, size, defaultUsersSortingBy, defaultUsersSortingType);
                }
                else {
                    this.props.getWorkspacesUsers(id, currentPage, size, defaultUsersSortingBy, defaultUsersSortingType);
                }
            }
            else {
                this.props.getWorkspacesUsersSearch(workspace.id, currentPage, size, search.search_text, defaultUsersSortingBy, defaultUsersSortingType);
            }
            this.setState({ page: currentPage });
        }
    }

    handleScroll() {
        let { size } = this.state;
        if (size === 0)
            return;

        let top = document.documentElement.scrollTop;
        let height = document.documentElement.scrollHeight;
        let page = (height - ghead - tHead - 71) / (size * itemHeight);
        page = (Math.abs(page - Math.floor(page)) > 0.2) ? Math.ceil(page) : Math.floor(page);

        if (Math.ceil(top + window.innerHeight) >= height) {
            page = page + 1;
            this._isMounted && this.getUserList(page);
        }
    }

    handleResize() {
        const { users } = this.props;

        for (let i in users) {
            let user = users[i];
            const usersListId = document.getElementById('global-users-list-id-' + user.uid);
            const usersListStickyAction = document.getElementById('global-users-list-sticky-action-' + user.uid);
            if (usersListId !== null && usersListStickyAction !== null) {
                if (usersListId.offsetHeight !== usersListStickyAction.offsetHeight) {
                    usersListStickyAction.style.height = usersListId.offsetHeight + 'px';
                }
            }
        }
    }

    updateMode(page, mode) {
        this.setState({ page, mode });
    }

    getCurrentWorkspaceId() {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    componentDidMount() {
        this._isMounted = true;
        const { workspace, role, loading } = this.props;
        const id = this.getCurrentWorkspaceId();
        const size = this.calculatePageSize();

        if (loading === false) {
            if (id !== workspace.workspace_name.toLowerCase().replaceAll(" ", "+")) {
                let userId = role.user_id;
                if (userId === "") {
                    const sessionRole = getSessionStorage("role");

                    if (sessionRole !== "") {
                        const role = JSON.parse(sessionRole);
                        userId = role.user_id;
                    }
                }
                this.props.getWorkspacesUsersById(id, userId, 1, size, defaultUsersSortingBy, defaultUsersSortingType);
            }
            else {
                this.props.getWorkspacesUsers(id, 1, size, defaultUsersSortingBy, defaultUsersSortingType);
            }
        }
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("resize", this.handleResize);
        this.setState({ page: 1, size, mode: 'list' });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.users.length !== this.props.users.length && this.props.users.length > 0) {
            setTimeout(() => {
                this.handleResize();
            }, 200);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.clearUsers();
        this.props.clearGlobalUsers();
        this.props.clearGlobalWorkspacesMembers();
        this.props.clearUsersPagination();
        this.setState({ page: 1, size: 0, mode: 'list' });
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener("resize", this.handleResize);
    }

    render() {
        const { workspace, users, loading, loadingTheme } = this.props;
        if (workspace.id === undefined || workspace.id === '')
            return (
                <div>
                    {(loading || loadingTheme) &&
                        <div className="loader-container">
                            <Loader
                                type="Ball-Triangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                </div>
            );

        return (
            <div>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <SidebarContainer />
                        <main>
                            <div className="mapBackground" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <GlobalUsersListHeader />
                                    <GlobalUsersListBody items={users} updateMode={this.updateMode} />
                                </div>
                            </div>
                        </main>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, users, role, loading, search }) => ({
    workspace: detail.workspace,
    users,
    role,
    loading: loading.global_users.loading,
    loadingTheme: loading.theme.loading,
    search: search.member
});

const mapDispatchToProps = (dispatch) => ({
    getWorkspacesUsers: (workspaceId, pageIndex, pageSize, sortingBy, sortingType) => dispatch(getWorkspacesUsersWithThunk(workspaceId, pageIndex, pageSize, sortingBy, sortingType)),
    getWorkspacesUsersById: (id, userId, pageIndex, pageSize, sortingBy, sortingType) => dispatch(getWorkspacesUsersByIdWithThunk(id, userId, pageIndex, pageSize, sortingBy, sortingType)),
    getWorkspacesUsersSearch: (workspaceId, pageIndex, pageSize, searchKeyword, SortingBy, SortingType) => dispatch(getWorkspacesUsersSearchWithThunk(workspaceId, pageIndex, pageSize, searchKeyword, SortingBy, SortingType)),
    clearUsers: () => dispatch(clearUsersAction([])),
    clearGlobalUsers: () => dispatch(clearGlobalUsersAction([])),
    clearGlobalWorkspacesMembers: () => dispatch(clearGlobalWorkspacesMembersAction([])),
    clearUsersPagination: () => dispatch(clearUsersPaginationAction([]))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GlobalUsersListContainer));