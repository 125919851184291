import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { routeCodes } from '../../../../configurations/routes';
import WorkspacesMembersShareItem from './WorkspacesMembersShareItem';
import { updateWorkspacesMembersShareAction } from '../../../../actions/updateWorkspacesMembersShareAction';
import { updateDialogWorkspacesMembersShareAction } from '../../../../actions/updateDialogWorkspacesMembersShareAction';
import { defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize } from '../../../../configurations/app';
import { getWorkspacesShareListWithThunk } from '../../../../api/workspacesShareListThunk';

class WorkspacesMembersShareBody extends Component {
    constructor(props) {
        super(props);
        this.checkAllMembers = this.checkAllMembers.bind(this);
        this.shareSelectedMembers = this.shareSelectedMembers.bind(this);
        this.state = {
            selectAll: false
        };
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        workspacesMembersShare: PropTypes.array.isRequired,
        role: PropTypes.object.isRequired
    };

    backToMemberPage() {
        const { workspace } = this.props;
        this.props.history.push(routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/members');
    }

    checkAllMembers() {
        const { workspacesMembersShare } = this.props;
        for (let i in workspacesMembersShare) {
            if (this.state.selectAll) {
                workspacesMembersShare[i].selected = false;
            }
            else {
                workspacesMembersShare[i].selected = true;
            }
        }

        this.props.dispatchWorkspacesMembersShare(workspacesMembersShare);
        this.setState({ selectAll: !this.state.selectAll });
    }

    shareSelectedMembers() {
        const { role } = this.props;
        this.props.getWorkspacesShareList(role.user_id, role.user_role, defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize);

        let workspace_members_share = {            
            show_dialog: true
        };

        this.props.dispatchDialogWorkspacesMembersShare(workspace_members_share);
    }

    render() {
        const { workspacesMembersShare } = this.props;

        return (
            <div className="mainBody">
                <div className="row mt-4">
                    <div className=" col-xl-12">
                        <div className="wsSection">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-9">
                                    <button className="btn icoTextBtn btn-wrd_default mr-3 greyBtn" onClick={() => this.backToMemberPage()}>
                                        <span className="icon-wrd-arrow-left"></span>
                                        Back</button>
                                    <button className="btn icoTextBtn btn-wrd_default mr-3 toggleBtn" onClick={() => this.checkAllMembers()}><span
                                        className="icon-wrd-select"></span>
                                        {(this.state.selectAll) ? 'Deselect' : 'Select'} All</button>
                                    <button className="btn icoTextBtn btn-wrd_default popupTrigger" onClick={() => this.shareSelectedMembers()}>
                                        <span className="icon-wrd-share"></span>
                                        Share Selected
                                        </button>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-3 text-right">
                                    <div className="searchInput">
                                        <span className="icon-wrd-search"></span>
                                        <input type="text" placeholder="Search..." />
                                    </div>
                                </div>
                            </div>
                            <div className="tableWrap userList mb-3">
                                <div className="table-responsive tableSharingUser">
                                    <table className="table table-striped" cellSpacing="0" width="100%">
                                        <thead>
                                            <tr>
                                                <th>Picture</th>
                                                <th>User Detail</th>
                                                <th>Role</th>
                                                <th>Registration</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(workspacesMembersShare !== undefined && workspacesMembersShare.length > 0) ?
                                                workspacesMembersShare.map((item, index) => (
                                                    <WorkspacesMembersShareItem key={item.uid} item={item} index={index} />
                                                ))
                                                :
                                                'No match'
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, workspacesMembersShare, role }) => ({
    workspace: detail.workspace,
    workspacesMembersShare,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchWorkspacesMembersShare: (workspacesMembersShare) => dispatch(updateWorkspacesMembersShareAction(workspacesMembersShare)),
    dispatchDialogWorkspacesMembersShare: (dialog) => dispatch(updateDialogWorkspacesMembersShareAction(dialog)),
    getWorkspacesShareList: (userId, userRole, pageIndex, pageSize) => dispatch(getWorkspacesShareListWithThunk(userId, userRole, pageIndex, pageSize))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesMembersShareBody));