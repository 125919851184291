import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { routeCodes } from '../../../configurations/routes';
import { setupNewWorkspaceAction } from '../../../actions/setupNewWorkspaceAction'
import { createWorkspaceWithThunk } from '../../../api/workspacesThunk';

class WorkspacesCreateAction extends Component {
    constructor(props) {
        super(props);
        this.createNewWorkspace = this.createNewWorkspace.bind(this);
        this.openWorkspacesList = this.openWorkspacesList.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        setupNewWorkspace: PropTypes.func.isRequired,
        createWorkspace: PropTypes.func.isRequired
    };

    openWorkspacesList() {
        this.props.history.push(routeCodes.WORKSPACES_LIST)
    }

    createNewWorkspace() {
        const { workspace } = this.props;
        this.props.createWorkspace(workspace);
    }

    render() {
        return (
            <div className="col-md-12">
                <div className="inputForm buttonGroup mt-2 text-center">
                    <button className="btn greyBtn icoTextBtn mx-2" onClick={this.openWorkspacesList}><span className="icon-wrd-close"></span>Cancel</button>
                    <button className="btn greenBtn icoTextBtn mx-2" onClick={this.createNewWorkspace}><span className="icon-wrd-add"></span> Create Workspace</button>                    
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ setup }) => ({
    workspace: setup.workspace
});

const mapDispatchToProps = (dispatch) => ({
    setupNewWorkspace: (workspace) => dispatch(setupNewWorkspaceAction(workspace)),
    createWorkspace: (workspace) => dispatch(createWorkspaceWithThunk(workspace))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesCreateAction));