const categoriesApi = require('../api/categories');

export async function getCategoryListApi() {
    const results = await categoriesApi.getCategoryList();
    return mapToCategoriesFrontend(results.data.results);
}

export async function getCategoryByIdApi(categoryId) {
    const results = await categoriesApi.getCategoryById(categoryId);
    return mapToCategoryFrontend(results.data);
}

export async function getCategoryIdByNameApi(categoryName) {
    const results = await categoriesApi.getCategoryIdByName(categoryName);
    return results.data;
}

export async function createCategoryApi(category) {
    let data = {};
    let id = '';
    let error_messages = '';

    const mappingCategory = await mapToCategoryBackendForCreate(category);
    const results = await categoriesApi.createCategory(mappingCategory);

    if (results.status === 200) {
        id = results.data
    }
    else {
        error_messages = results.data;
    }

    data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

export async function updateCategoryApi(category) {
    let data = {};
    let id = '';
    let error_messages = '';

    const mappingCategory = await mapToCategoryBackendForUpdate(category);
    const results = await categoriesApi.updateCategory(mappingCategory);

    if (results.status === 200) {
        id = results.data
    }
    else {
        error_messages = results.data;
    }

    data = {
        id: id,
        status: results.status,
        error_messages: error_messages
    }

    return data;
}

export async function deleteCategoryApi(categoryId) {
    const results = await categoriesApi.deleteCategory(categoryId);

    const data = {
        status: results.data
    }

    return data;
}

function mapToCategoryBackendForCreate(data) {
    let category = {};

    category.name = data.category_title;
    category.description = data.category_description;

    return category;
}

function mapToCategoryBackendForUpdate(data) {
    let category = {};

    category.id = data.category_id;
    category.name = data.category_title;
    category.description = data.category_description;

    return category;
}

function mapToCategoriesFrontend(data) {
    let categories = [];
    for (let i in data) {
        let c = data[i];

        let category = {
            category_id: c.id,
            category_title: c.name,
            category_description: c.description,
        }
        categories.push(category);
    }

    return categories;
}

function mapToCategoryFrontend(data) {
    let category = {
        category_id: data.id,
        category_title: data.name,
        category_description: data.description
    }

    return category;
}