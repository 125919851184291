import React, { Component } from 'react';
import { Route, withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SidebarContainer from '../../Sidebar/SidebarContainer';

import { changeNavigationAction } from '../../../actions/changeNavigationAction';
import PortalSettingsHeader from './PortalSettingsHeader';
import PortalSettingsBanner from './Banner/PortalSettingsBanner';
import PortalSettingsFeature from './Feature/PortalSettingsFeature';
import PortalSettingsNews from './News/PortalSettingsNews';
import PortalSettingsLogin from './Login/PortalSettingsLogin';
import PortalSettingsEmail from './Email/PortalSettingsEmail';
import PortalSettingsTheme from './Theme/PortalSettingsTheme';
import PortalsModal from '../Modal/PortalsModal'
import PortalSettingsErrorModal from '../Modal/PortalSettingsErrorModal';
import { routeCodes } from '../../../configurations/routes';

class PortalSettingsContainer extends Component {
    static propTypes = {
        navigation: PropTypes.object.isRequired,
        activeTab: PropTypes.string.isRequired
    };

    changeNavigation(name) {
        let navigation = {
            name
        };
        this.props.dispatchNavigation(navigation);
    }

    componentDidMount() {
        this.changeNavigation('PortalSettings');
    }

    render() {
        return (
            <div>
                <SidebarContainer />
                <main>a
                    <div className="mapBackground" id="testId" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                    <div className="container-fluid h-100">
                        <div className="mcp">
                            <PortalSettingsHeader />
                            <div className="mainBody">
                                <Route path={routeCodes.PORTAL_SETTINGS} exact component={PortalSettingsBanner} />
                                <Route path={routeCodes.PORTAL_FEATURES} component={PortalSettingsFeature} />
                                <Route path={routeCodes.PORTAL_NEWS} component={PortalSettingsNews} />
                                <Route path={routeCodes.PORTAL_OPTIONS} component={PortalSettingsLogin} />
                                <Route path={routeCodes.PORTAL_OPTIONS_EMAIL} component={PortalSettingsEmail} />
                                <Route path={routeCodes.PORTAL_OPTIONS_THEME} component={PortalSettingsTheme} />
                            </div>
                        </div>
                    </div>
                </main>
                <PortalsModal type="detail" />
                <PortalSettingsErrorModal type="error" />
            </div>
        );
    }
}

const mapStateToProps = ({ portalSettings, navigation }) => ({
    activeTab: portalSettings.active_tab,
    navigation
});

const mapDispatchToProps = (dispatch) => ({
    dispatchNavigation: (navigation) => dispatch(changeNavigationAction(navigation))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalSettingsContainer));
