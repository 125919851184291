import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner'
import SidebarContainer from '../../Sidebar/SidebarContainer';
import ProfileHeader from './ProfileHeader';
import ProfileBody from './ProfileBody';
import { getAccountWithThunk } from '../../../api/accountThunk';
import { changeNavigationAction } from '../../../actions/changeNavigationAction';
import UsersCreationErrorModal from '../../Users/Modal/UsersCreationErrorModal';

class ProfileContainer extends Component {
    detailStart = React.createRef()
    static propTypes = {
        navigation: PropTypes.object.isRequired,
        account: PropTypes.object.isRequired,
        getAccount: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired,
        dispatchNavigation: PropTypes.func.isRequired
    };

    changeNavigation(name) {
        let navigation = {
            name
        };
        this.props.dispatchNavigation(navigation);
    }

    scrollToTop = () => {
        this.detailStart.current.scrollIntoView({ behavior: 'smooth' })
    }

    componentDidMount() {
        const { role } = this.props;
        let width = Math.ceil((window.innerWidth - 560) * (3 / 12));
        if (width <= 100) {
            width = Math.ceil(window.innerWidth * 0.767);
        }
        const height = width;
        this.props.getAccount(role.user_id, true, width, height);
        this.changeNavigation('Profile');
    }

    render() {
        const { account, loading, loadingTheme } = this.props;

        if (account === undefined || account.id === undefined || account.id === '')
            return (
                <div>
                    {(loading || loadingTheme) &&
                        <div className="loader-container">
                            <Loader
                                type="Ball-Triangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                    <SidebarContainer />
                </div>
            );

        return (
            <div>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <div ref={this.detailStart} />
                        <SidebarContainer />
                        <main>
                            <div className="mapBackground" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <ProfileHeader />
                                    <ProfileBody scrollTop={this.scrollToTop} />
                                </div>
                            </div>
                        </main>
                        <UsersCreationErrorModal type="error" />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ navigation, detail, role, loading }) => ({
    navigation,
    account: detail.account,
    role,
    loading: loading.account.loading,
    loadingTheme: loading.theme.loading
});

const mapDispatchToProps = (dispatch) => ({
    dispatchNavigation: (navigation) => dispatch(changeNavigationAction(navigation)),
    getAccount: (userId, withPicture, width, height) => dispatch(getAccountWithThunk(userId, withPicture, width, height))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileContainer));
