import { UPDATE_ROLE, CLEAR_ROLE } from '../actions/index';

const initialRoleState = {    
    user_id: '',
    user_name: '',
    user_role: '',
    full_name: '',
    token: '',
    is_logged_in: false,
    is_processing_login: false
}

export const role = (state = initialRoleState, action) => {
    const { role } = action

    switch (action.type) {
        case UPDATE_ROLE:
            return role
        case CLEAR_ROLE:
            return initialRoleState
        default:
            return state;
    }
};