import { UPDATE_REDIRECT_URL, CLEAR_REDIRECT_URL } from '../actions/index';

const initialUrlstate = {    
    url_redirect: '',
}

export const redirectUrl = (state = initialUrlstate, action) => {
    const { redirectUrl } = action

    switch (action.type) {
        case UPDATE_REDIRECT_URL:
            return redirectUrl
        case CLEAR_REDIRECT_URL:
            return initialUrlstate
        default:
            return state;
    }
};