import { GET_WORKSPACE_FOCUS_AREA_CONNECTIONS, CLEAR_WORKSPACE_FOCUS_AREA_CONNECTIONS, UPDATE_WORKSPACE_FOCUS_AREA_CONNECTIONS } from '../actions/index';

const initialWorkspaceFocusAreaConnections = {}

export const workspaceConnections = (state = initialWorkspaceFocusAreaConnections, action) => {
    const { workspace_connections } = action

    switch (action.type) {
        case GET_WORKSPACE_FOCUS_AREA_CONNECTIONS:
            return {                
                connections: workspace_connections.connections
            }
        case CLEAR_WORKSPACE_FOCUS_AREA_CONNECTIONS:
            return initialWorkspaceFocusAreaConnections;
        case UPDATE_WORKSPACE_FOCUS_AREA_CONNECTIONS:
            return {                
                connections: workspace_connections.map((el, index) => (
                    action.workspace_connections.id === index ? { ...el, workspace_connections: action.workspace_connections } : el
                ))
            }
        default:
            return state;
    }
};