import {
    getUserByIdApi,
    userLoginConfirmationApi,
    userLoginApi
} from '../backend/domain/users';

import { getAccountAction } from '../actions/getAccountAction';
import { clearAccountAction } from '../actions/clearAccountAction';
import { updateRoleAction } from '../actions/updateRoleAction';
import { loadingLoginAction } from '../actions/loadingLoginAction';
import { handleUserConfirmationError, handleUserLoginError } from '../utils/errorMessages';
import { saveSessionStorage } from '../utils/sessionStorage';
import { overrideUserId } from '../utils/users';
import { checkAfterLoggedIn } from '../utils/pageRedirectionRules';
import { store } from '../index';

export const userLoginConfirmationWithThunk = (userId, userName, token, password, newPassword, actionType, historyProps) =>
    async (dispatch) => {
        let uId = await overrideUserId(userId);
        dispatch(loadingLoginAction(true));

        let userPayload = { user_name: userName, password, new_password: newPassword };
        userLoginConfirmationApi(uId, token, actionType, userPayload)
            .then(async (response) => {
                dispatch(loadingLoginAction(false));
                if (response.error) {
                    handleUserConfirmationError(dispatch, uId, response.error);
                }
                else {
                    dispatch(updateRoleAction(response));
                    const role = JSON.stringify(store.getState().role);

                    await saveSessionStorage("login_date", new Date(Date.now()));
                    await saveSessionStorage("role", role);
                    await checkAfterLoggedIn(historyProps, store.getState().role);
                }
                return response;
            });
    };

export const userLoginWithThunk = (userName, password, historyProps) =>
    (dispatch) => {
        dispatch(loadingLoginAction(true));

        let userPayload = { user_name: userName, password };
        userLoginApi(userPayload)
            .then(async (response) => {
                dispatch(loadingLoginAction(false));
                if (response.error) {
                    handleUserLoginError(dispatch, response.user_id, response);
                }
                else {
                    dispatch(updateRoleAction(response));
                    const role = JSON.stringify(store.getState().role);

                    await saveSessionStorage("login_date", new Date(Date.now()));
                    await saveSessionStorage("role", role);
                    await checkAfterLoggedIn(historyProps, store.getState().role);
                }
                return response;
            })
    };

export const userReloginWithThunk = (role) =>
    (dispatch) => {
        dispatch(updateRoleAction(role))
        dispatch(clearAccountAction([]));
        getUserByIdApi(role.user_id, false, 0, 0)
            .then((response) => {
                if (!response.error) {
                    dispatch(getAccountAction(response));
                }
                return response;
            })
    };