import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import GridContainer from '../Grid/GridContainer';
import { getWorkspaceHeaders } from '../../../utils/workspaces';

class WorkspacesListBody extends Component {
    static propTypes = {
        workspaces: PropTypes.array.isRequired,
        pagination: PropTypes.func.isRequired
    };

    render() {
        const { workspaces } = this.props;
        const headers = getWorkspaceHeaders();

        return (
            <GridContainer headers={headers} items={workspaces} pagination={this.props.pagination} />
        );
    }
}

const mapStateToProps = ({ workspaces }) => ({
    workspaces
});

export default withRouter(connect(mapStateToProps)(WorkspacesListBody));