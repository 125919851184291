exports.settings = "develop";
exports.server = "wrddev";
exports.domain = "wrddev";
exports.port = 3100;
exports.portalServer = "wrddev";
exports.portalPort = 80;
exports.protocol = "http://";
exports.useContainer = true;
exports.anonymous = "00000000-0000-0000-0000-000000000000";
exports.emptyConnectionString = "_$$_";
exports.spatialServer = "epsg.io";
exports.protocol = "http://";
exports.gatewayServerQuery = "wrddev";
exports.gatewayPortQuery = 3001;
exports.gatewayAPiVersionQuery = "v1";
exports.gatewayAPiVersionQueryV2 = "v2";
exports.gatewayServerCommand = "wrddev";
exports.gatewayPortCommand = 3001;
exports.gatewayAPiVersionCommand = "v1";
exports.gatewayAPiVersionCommandV2 = "v2";
exports.frontendServer = "wrddev";
exports.emailConfirmationPage = "portal/login/users/confirmation";
exports.resetPasswordLoginPage = "portal/login/users";
exports.portalServerAppFolder = "";