import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarContainer from '../Sidebar/SidebarContainer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import ContactOwnersBody from './ContactOwnersBody';
import ModalAlert from '../Shared/ModalAlert';

class ContactOwnersContainer extends Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
    }

    render() {
        const { loading, loadingTheme } = this.props;
        
        return (
            <React.Fragment>
                {(loading || loadingTheme) ?
                    <div className="loader-container">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                    :
                    <div>
                        <SidebarContainer />
                        <main>
                            <ContactOwnersBody />
                        </main>
                        <ModalAlert />
                    </div>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ loading }) => ({
    loading: loading.contactOwners.loading,
    loadingTheme: loading.theme.loading
});

export default withRouter(connect(mapStateToProps, null)(ContactOwnersContainer));
