import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getWorkspacesMembersSortingWithThunk } from '../../../../api/workspacesMembersThunk';
import { getWorkspacesMembersSortingAction } from '../../../../actions/getWorkspacesMembersSortingAction';
import { getWorkspacesMembersAction } from '../../../../actions/getWorkspacesMembersAction';

class GridHeaderItem extends Component {
    static propTypes = {
        workspace: PropTypes.object.isRequired,
        header: PropTypes.object.isRequired,
        pagination: PropTypes.object.isRequired,
        sorting: PropTypes.object.isRequired,
        search: PropTypes.object.isRequired,
        getWorkspacesMembers: PropTypes.func.isRequired,
        dispatchWorkspacesMembersSorting: PropTypes.func.isRequired,
    };

    dispatchSorting(sortBy, sortType) {
        let data = {
            sort_by: sortBy,
            sort_type: sortType
        }
        this.props.dispatchWorkspacesMembersSorting(data);
    }

    onSortingChange = () => {
        const { updateMembers, header, sorting } = this.props;
        let workspacesMembers = this.props.workspacesMembers;
        let currentSortBy = (sorting.sort_by === header.sort_column) ? sorting.sort_by : header.sort_column;
        let currentSortType = (sorting.sort_type === 'Ascending') ? "Descending" : "Ascending";

        if (currentSortType === 'Ascending') {
            if (currentSortBy === 'UserName') {
                workspacesMembers = workspacesMembers.sort(function (a, b) {
                    return a.id.toLowerCase().localeCompare(b.id.toLowerCase());
                })
            } else if (currentSortBy === 'organization') {
                workspacesMembers = workspacesMembers.sort(function (a, b) {
                    return a.organization.toLowerCase().localeCompare(b.organization.toLowerCase());
                })
            } else if (currentSortBy === 'role') {
                workspacesMembers = workspacesMembers.sort(function (a, b) {
                    return a.user_role.toLowerCase().localeCompare(b.user_role.toLowerCase());
                })
            } else if (currentSortBy === 'registration_status') {
                workspacesMembers = workspacesMembers.sort(function (a, b) {
                    return a.registration_status.toLowerCase().localeCompare(b.registration_status.toLowerCase());
                })
            } else if (currentSortBy === 'user_status') {
                workspacesMembers = workspacesMembers.sort(function (a, b) {
                    return a.user_status.toLowerCase().localeCompare(b.user_status.toLowerCase());
                })
            }
            else if (currentSortBy === 'member_status') {
                workspacesMembers = workspacesMembers.sort(function (a, b) {
                    return a.member_status.toLowerCase().localeCompare(b.member_status.toLowerCase());
                })
            }
        }
        else {
            if (currentSortBy === 'UserName') {
                workspacesMembers = workspacesMembers.sort(function (a, b) {
                    return b.id.toLowerCase().localeCompare(a.id.toLowerCase());
                })
            } else if (currentSortBy === 'organization') {
                workspacesMembers = workspacesMembers.sort(function (a, b) {
                    return b.organization.toLowerCase().localeCompare(a.organization.toLowerCase());
                })
            } else if (currentSortBy === 'role') {
                workspacesMembers = workspacesMembers.sort(function (a, b) {
                    return b.user_role.toLowerCase().localeCompare(a.user_role.toLowerCase());
                })
            } else if (currentSortBy === 'registration_status') {
                workspacesMembers = workspacesMembers.sort(function (a, b) {
                    return b.registration_status.toLowerCase().localeCompare(a.registration_status.toLowerCase());
                })
            } else if (currentSortBy === 'user_status') {
                workspacesMembers = workspacesMembers.sort(function (a, b) {
                    return b.user_status.toLowerCase().localeCompare(a.user_status.toLowerCase());
                })
            }
            else if (currentSortBy === 'member_status') {
                workspacesMembers = workspacesMembers.sort(function (a, b) {
                    return b.member_status.toLowerCase().localeCompare(a.member_status.toLowerCase());
                })
            }
        }

        updateMembers(workspacesMembers);
        this.dispatchSorting(currentSortBy, currentSortType);
    }

    render() {
        const { header, sorting } = this.props;
        return (
            header.header_name === "Picture" ?
                (<th className="grid-no-sort">
                    {header.header_name}
                </th>) :
                (<th className="shortings grid-sort" onClick={this.onSortingChange}>
                    {header.header_name}
                    {header.sort_column === sorting.sort_by ? (<span className={(sorting.sort_type === 'Ascending') ? 'icon-wrd-sorting-up head-sort-span' : 'icon-wrd-sorting-down head-sort-span'}></span>) : null}
                </th>)
        );
    }
}

const mapStateToProps = ({ detail, sorting, pagination, search, workspacesMembers }) => ({
    workspace: detail.workspace,
    pagination: pagination.member,
    sorting: sorting.member,
    search: search.member,
    workspacesMembers
});

const mapDispatchToProps = (dispatch) => ({
    updateMembers: (members) => dispatch(getWorkspacesMembersAction(members)),
    getWorkspacesMembers: (workspacesMembers, sortingBy, sortingType) => dispatch(getWorkspacesMembersSortingWithThunk(workspacesMembers, sortingBy, sortingType)),
    dispatchWorkspacesMembersSorting: (data) => dispatch(getWorkspacesMembersSortingAction(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GridHeaderItem));