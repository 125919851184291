import sort from 'fast-sort';
import { GET_APPLICATIONS_SETTINGS, UPDATE_APPLICATIONS_SETTINGS, CLEAR_APPLICATIONS_SETTINGS } from '../actions/index';

const getApplicationsSettings = (applicationsSettings = [], action) => {
    let itemsKeys = [];
    let settingsKeys = action.settings.reduce((keys, setting) => {
        itemsKeys.push(setting.id);
        keys = settingsKeys;
        return keys;
    }, []);

    let result = applicationsSettings.filter((setting) => {
        return settingsKeys.indexOf(setting.id) === -1;
    });

    const results = result.concat(action.settings);
    return sort(results).asc([
        u => u.group_settings,
        u => u.title
    ]);
}

const updateApplicationsSettings = (applicationsSettings = [], action) => {
    const settings = applicationsSettings.map((item) => {
        const changesSettings = action.settings.filter((change) => change.id === item.id)
        if (changesSettings.count > 0) {
            return changesSettings[0];
        }

        return item
    })

    return sort(settings).asc("group_settings");
}

const clearApplicationsSettings = (action) => {
    return action.settings;
}

export const applicationsSettings = (applicationsSettings = [], action) => {
    switch (action.type) {
        case GET_APPLICATIONS_SETTINGS: return getApplicationsSettings(applicationsSettings, action);
        case UPDATE_APPLICATIONS_SETTINGS: return updateApplicationsSettings(applicationsSettings, action);
        case CLEAR_APPLICATIONS_SETTINGS: return clearApplicationsSettings(action);
        default: return applicationsSettings;
    }
};