import React, { Component } from 'react';
import { Element } from 'react-scroll'

class PortalLandingFooter extends Component {
    render() {
        return (
            <Element name="footer">
                <div className="footerSection p-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="title">Contact Us</div>
                                <p className="phone">Phone: +45 4516 9200</p>
                                <p className="email">Email: WaterTools@dhigroup.com </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Element>
        );
    }
}


export default (PortalLandingFooter);