import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { routeCodes } from '../../../configurations/routes';
import { updateStickyUsersAction } from '../../../actions/updateStickyUsersAction';
import { updateStickyUserStatusWithThunk } from '../../../api/usersThunk';
import { updateDialogUsersAction } from '../../../actions/updateDialogUsersAction';
import { createGlobalUserAction } from '../../../actions/createGlobalUserAction';
import { deleteGlobalUserAction } from '../../../actions/deleteGlobalUserAction';

class GridItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            selectRow: false,
        }
        this.onUserSelected = this.onUserSelected.bind(this);
        this.handlePopUp = this.handlePopUp.bind(this);
        this.handleOutsidePopUpClick = this.handleOutsidePopUpClick.bind(this);
    }

    static propTypes = {
        item: PropTypes.object.isRequired,
        sticky: PropTypes.object.isRequired,
        pagination: PropTypes.object.isRequired,
        sorting: PropTypes.object.isRequired,
        dispatchStickyUsers: PropTypes.func.isRequired,
        dispatchDialogUsers: PropTypes.func.isRequired,
        updateUserStatus: PropTypes.func.isRequired,
        addUser: PropTypes.func.isRequired,
        removeUser: PropTypes.func.isRequired
    };

    refPopUp = React.createRef();

    onUserSelected(e, uid) {
        this.setState(prevState => ({
            selectRow: !prevState.selectRow,
        }), () => {
            if (this.state.selectRow) {
                const user = {
                    uid: uid
                }
                this.props.addUser(user);
            } else {
                this.props.removeUser(uid);
            }
        });
    }

    getRegistrationStatusClass() {
        const { item } = this.props;

        let registrationStatusClass = '';

        if (item.registration_status === 'Confirmed') {
            registrationStatusClass = 'statusActive';
        }
        else if (item.registration_status === 'Pending') {
            registrationStatusClass = 'statusPending';
        }

        return registrationStatusClass;
    }

    getUserStatusClass() {
        const { item } = this.props;

        let userStatusClass = '';

        if (item.user_status === 'Enabled') {
            userStatusClass = 'statusActive';
        }
        else if (item.user_status === 'Disabled') {
            userStatusClass = 'statusSuspend';
        }

        return userStatusClass;
    }

    handlePopUp() {
        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }), () => {
            document.addEventListener('click', this.handleOutsidePopUpClick, false);
        });

    }

    handleOutsidePopUpClick(e) {
        if (this.state.popupVisible && e.target.baseURI === undefined) {
            this.setState(prevState => ({
                popupVisible: !prevState.popupVisible,
            }));
            document.removeEventListener('click', this.handleOutsidePopUpClick, false);
        } else if(this.state.popupVisible) {
            document.removeEventListener('click', this.handleOutsidePopUpClick, false);
        }
    }

    render() {
        const { item } = this.props;
        return (
            <tr>
                <td id={"global-users-list-id-" + item.uid}>
                    <img src={((item.picture !== undefined) ? "data:image/jpeg;base64," : "") + item.picture} className="userPhoto" alt="" />
                    <label className="btn iconBtn customPickExtUsrCheckbox">
                        <input type="checkbox" className="pickExtUsr" onChange={e => this.onUserSelected(e, item.uid)} checked={this.state.selectRow} />
                        <span className="greenBlockChecked"></span>
                        <span className="darkBlockUncheck"></span>
                    </label>
                </td>
                <td onClick={e => this.onUserSelected(e, item.uid)}>
                    <b className="userName">{item.id}</b>
                    <p className="fullName">{item.full_name}</p>
                </td>
                <td onClick={e => this.onUserSelected(e, item.uid)}>
                    <span className="icon icon-wrd-building"></span>
                    <span className="date">{item.organization}</span>
                </td>
                <td onClick={e => this.onUserSelected(e, item.uid)}>
                    <span className="icon icon-wrd-bookmark"></span>
                    <span className="date">{item.user_role}</span>
                </td>
                <td onClick={e => this.onUserSelected(e, item.uid)}>
                    <div>
                        <p>
                            <span className="icon icon-wrd-mail-closed"></span>
                            <span>{item.email_address}</span>
                        </p>
                        <p>
                            <span className="icon icon-wrd-phone"></span>
                            <span>{item.phone}</span>
                        </p>
                    </div>
                </td>
                <td onClick={e => this.onUserSelected(e, item.uid)}>
                    <span className="icon icon-wrd-registration"></span>
                    <span className={this.getRegistrationStatusClass()}>{item.registration_status}</span>
                </td>
                <td onClick={e => this.onUserSelected(e, item.uid)}>
                    <span className={this.getUserStatusClass()}>{item.user_status}</span>
                </td>
                <td id={"global-users-list-sticky-action-" + item.uid} className="stickyAction">
                    <button className="tableActionBtn" onClick={this.handlePopUp} ><span className="icon-wrd-more"></span></button>
                    <ul ref={this.refPopUp} className="action" style={{ display: this.state.popupVisible ? 'block' : 'none' }}>
                        <li>
                            <Link to={routeCodes.USERS_LIST + "/" + item.uid} className="detail">
                                <span className="icon-wrd-profile"></span>
                                <label style={{ paddingTop:'4px' }}>View Profile</label>
                            </Link>
                        </li>

                    </ul>
                </td>
            </tr>
        );
    }
}

const mapStateToProps = ({ sticky, pagination, sorting }) => ({
    sticky: sticky.users,
    pagination: pagination.user,
    sorting: sorting.user
});

const mapDispatchToProps = (dispatch) => ({
    dispatchStickyUsers: (data) => dispatch(updateStickyUsersAction(data)),
    dispatchDialogUsers: (dialog) => dispatch(updateDialogUsersAction(dialog)),
    addUser: (user) => dispatch(createGlobalUserAction(user)),
    removeUser: (uid) => dispatch(deleteGlobalUserAction(uid)),
    updateUserStatus: (user, pageIndex, pageSize, sortingBy, sortingType) => dispatch(updateStickyUserStatusWithThunk(user, pageIndex, pageSize, sortingBy, sortingType))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GridItem));