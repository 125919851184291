import { getCategoryIdByNameApi } from '../backend/domain/categories';

export const getCategoryIdByName = async (categoryName) => {
    let result = await getCategoryIdByNameApi(categoryName);
    return result;
}

export const mergeApplicationsCategories = (categories, applications) => {
    let categs = [];

    let noCategoryApps = applications.filter((item) => item.category_title === "");
    if (noCategoryApps.length > 0) {
        categs.push({
            category_id: 0,
            category_title: "",
            category_description: "",
            applications: noCategoryApps,
            count: noCategoryApps.length,
        });
    }
    else {
        categs.push({
            category_id: 0,
            category_title: "",
            category_description: "",
            applications: [],
            count: 0,
        });
    }

    for (let i in categories) {
        let category = categories[i];
        let categoryApps = applications.filter((item) => item.category_title === category.category_title);

        categoryApps.sort(function (a, b) {
            return a.application_name.toLowerCase().localeCompare(b.application_name.toLowerCase());
        });

        categs.push({
            category_id: category.category_id,
            category_title: category.category_title,
            category_description: category.category_description,
            applications: categoryApps,
            count: categoryApps.length,
        });
    }

    return categs;
}