import { updateRoleAction } from '../actions/updateRoleAction';
import { store } from '../index';

export const updateRoleProperties = async (isProcessingLogin) => {
    const currentRole = store.getState().role;

    let role = {
        ...currentRole,
        is_processing_login: isProcessingLogin
    }
    await store.dispatch(updateRoleAction(role))
} 