import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import { updateWorkspaceEmailSettingAction } from '../../../../actions/updateWorkspaceEmailSettingAction';
import { updateWorkspaceEmailSettingWithThunk } from '../../../../api/workspacesEmailSettingsThunk';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../../../configurations/routes';
import WorkspacesEmailSettingsItem from './WorkspacesEmailSettingsItem';
import { defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize } from '../../../../configurations/app';
import { getUserAvailableWorkspacesWithThunk } from '../../../../api/userAvailableWorkspacesThunk';
import UserAvailableWorkspacesContainer from '../../UserAvailableWorkspaces/UserAvailableWorkspacesContainer';
import { updateAvailableWorkspacesOpenedStatus } from '../../../../utils/workspaces';

class WorkspacesEmailSettingsBody extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            wsListOpened: false,
            firstOpen: true
        };       
        this.hideList = this.hideList.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        workspaceSetting: PropTypes.object.isRequired,
        dispatchWorkspaceSetting: PropTypes.func.isRequired,
        updateWorkspaceSetting: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired
    };

    getAspectRatioClass() {
        const { image_aspect_ratio } = this.props.workspace;

        let aspectRatioClass = 'crop-logo43';
        if (image_aspect_ratio >= 1 && image_aspect_ratio < 1.2) {
            aspectRatioClass = 'crop-logo11';
        }
        else if (image_aspect_ratio >= 1.2 && image_aspect_ratio < 1.4) {
            aspectRatioClass = 'crop-logo43';
        }
        else if (image_aspect_ratio >= 1.4 && image_aspect_ratio < 1.6) {
            aspectRatioClass = 'crop-logo32';
        }
        else if (image_aspect_ratio >= 1.6 && image_aspect_ratio < 2.3) {
            aspectRatioClass = 'crop-logo169';
        }
        else if (image_aspect_ratio >= 2.3) {
            aspectRatioClass = 'crop-logo219';
        }

        return aspectRatioClass;
    } 

    availableWorkspacesSwitch() {
        this.setState({ wsListOpened: !this.state.wsListOpened });
        this.setState({ firstOpen: false });
        if (this.state.wsListOpened === false) {
            const { role } = this.props;
            this.props.getUserAvailableWorkspaces(role.user_id, role.user_role, defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize);
        }
        else {
            updateAvailableWorkspacesOpenedStatus(false);
        }
    }

    updateWorkspaceSetting() {
        const { workspaceSetting } = this.props;
        this.props.updateWorkspaceSetting(workspaceSetting);
    }

    hideList() {
        this.setState({ wsListOpened: false });
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    render() {
        const { workspace, workspaceSetting } = this.props;

        return (
            <div className="mainBody">
                <div className="row mt-5">
                    <div className="col-md-12 order-lg-1 col-lg-8 col-xl-9">
                        <div className="wsHomeSection">
                            {(workspaceSetting.emails !== undefined && workspaceSetting.emails.length > 0) ?
                                workspaceSetting.emails.map((item) => (
                                    <WorkspacesEmailSettingsItem key={item.id} item={item} />
                                ))
                                :
                                'No match'
                            }                                                       
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-4 col-xl-3">
                        <div className="wsLogo pb-5 text-center">
                            <div className="logoContainer wsHome text-center">
                                <img src={process.env.PUBLIC_URL + "/images/transCircle.png"} className="insideCircle" alt="" />
                                <img src={((workspace.url_image !== undefined) ? "data:image/png;base64," : "") + workspace.url_image}
                                    className={this.getAspectRatioClass()} alt="Logo" />
                            </div>
                            <ul className="setting-menus mt-5 w-75">
                                <strong>Settings Options</strong>
                                <li>
                                    <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/general'}>
                                        <span className="icon-wrd-settings"></span>General
                                    </Link>
                                </li>
                                <li>
                                    <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/login'} >
                                        <span className="icon-wrd-log-in"></span> Login
                                    </Link>
                                </li>
                                <li>
                                    <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/email'} className="active">
                                        <span className="icon-wrd-mail-closed"></span>Email
                                    </Link>
                                </li>
                                <li>
                                    <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/theme'}>
                                        <span className="icon-wrd-themes"></span>Themes
                                    </Link>
                                </li>
                            </ul>
                            <button className="btn textBtn greenBtn w-75 mt-5" onClick={() => this.updateWorkspaceSetting()}>Update Changes</button>
                        </div>
                    </div>
                </div>
                <div className={(this.state.firstOpen) ? "workspaceList d-none" : (this.state.wsListOpened) ? "workspaceList open animated fadeInRight" : "workspaceList open animated fadeOutRight"}>
                    <UserAvailableWorkspacesContainer hideList={this.hideList} />
                </div>
                <button className="btn iconBtn wsListBtn" onClick={() => this.availableWorkspacesSwitch()}>
                    <span className={(this.state.wsListOpened) ? "icon-wrd-close" : "icon-wrd-ws-list"}></span>
                </button>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, role }) => ({
    workspaceSetting: detail.workspace_email_setting,
    workspace: detail.workspace,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchWorkspaceSetting: (workspaceSetting) => dispatch(updateWorkspaceEmailSettingAction(workspaceSetting)),
    updateWorkspaceSetting: (workspaceSetting) => dispatch(updateWorkspaceEmailSettingWithThunk(workspaceSetting)),
    getUserAvailableWorkspaces: (userId, userRole, pageIndex, pageSize) => dispatch(getUserAvailableWorkspacesWithThunk(userId, userRole, pageIndex, pageSize))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesEmailSettingsBody));