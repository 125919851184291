import {
    UPDATE_MAP_GEOMETRY,
    GET_FOCUS_AREA_MAP,
    CLEAR_FOCUS_AREA_MAP,
    UPDATE_FOCUS_AREA_MAP,
    UPDATE_FOCUS_AREA_GEOMETRY,
    UPDATE_FOCUS_AREA_LIST_GEOMETRY,
} from '../actions/index';
import { minZoom, maxZoom, zoom, defaultLat, defaultLng, defaultBounds } from '../configurations/map';

const initialMaplState = {
    listGeometry: {},
    focus_area_map: {
        lat: defaultLat,
        lng: defaultLng,
        zoom: zoom,
        minZoom: minZoom,
        maxZoom: maxZoom,
        bounds: defaultBounds,
        data: {
            features: []
        },
        default: {}
    }
}

export const map = (state = initialMaplState, action) => {
    const { focus_area_map, selectedFocusArea, listGeometry, features } = action;
    switch (action.type) {
        case UPDATE_MAP_GEOMETRY:
            return {
                ...state,
                focus_area_map: {
                    ...state.focus_area_map,
                    data: {
                        ...state.focus_area_map.data,
                        features
                        // features: state.focus_area_map.data.features.concat(features)
                    }
                }
            }
        case UPDATE_FOCUS_AREA_LIST_GEOMETRY:
            return {
                ...state,
                listGeometry
            }
        case UPDATE_FOCUS_AREA_GEOMETRY:
            return {
                ...state,
                selectedFocusArea
            }
        case GET_FOCUS_AREA_MAP:
            return {
                ...state,
                focus_area_map
            }
        case UPDATE_FOCUS_AREA_MAP:
            return {
                ...state,
                focus_area_map
            }
        case CLEAR_FOCUS_AREA_MAP:
            return {
                ...state,
                focus_area_map: initialMaplState.focus_area_map
            }
        default:
            return state;
    }
};