import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { updatePortalSettingThemeAction } from '../../../../actions/updatePortalSettingThemeAction';
import { updatePortalSettingThemeWithThunk, addPortalSettingThemeWithThunk } from '../../../../api/portalSettingsThunk';
import { updateDialogPortalsAction } from '../../../../actions/updateDialogPortalsAction';
import ImageEditor from '../../../Editor/ImageEditorWithId';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../../../configurations/routes';
import { modalImageEditorStyle } from '../../../../utils/modal';
import Modal from 'react-modal';
import { updateDialogPortalSettingsErrorAction } from '../../../../actions/updateDialogPortalSettingsErrorAction';

class PortalSettingsThemeItem extends Component {
    constructor(props) {
        super(props);
        this.onSettingsNameChange = this.onSettingsNameChange.bind(this);
        this.onSettingsCaptionChange = this.onSettingsCaptionChange.bind(this);
        this.onSelectFile = this.onSelectFile.bind(this);
        this.onCssFileUpload = this.onCssFileUpload.bind(this);
        this.onCloseImageEditor = this.onCloseImageEditor.bind(this);
        this.updatePortalSettingThemeChanges = this.updatePortalSettingThemeChanges.bind(this);
        this.displayAction = this.displayAction.bind(this);
        this.hideAction = this.hideAction.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.removePortalSettingTheme = this.removePortalSettingTheme.bind(this);
        this.createPortalSettingTheme = this.createPortalSettingTheme.bind(this);
        this.cancelPortalSettingTheme = this.cancelPortalSettingTheme.bind(this);
        this.state = {
            src: null,
            imageEditorIsOpen: false,
            stickyActionIsOpen: false
        }
    }

    static propTypes = {
        item: PropTypes.object.isRequired,
        settingTheme: PropTypes.object.isRequired
    };

    onSettingsNameChange(e) {
        const { item, settingTheme } = this.props;
        let themes = settingTheme.themes;
        let index = themes.findIndex(theme => theme.id === item.id);
        themes[index].theme_name = e.target.value;

        let themeSetting = {
            ...settingTheme,
            themes
        };

        this.props.dispatchUpdateSettingTheme(themeSetting);
    }

    onSettingsCaptionChange(e) {
        const { item, settingTheme } = this.props;
        let themes = settingTheme.themes;
        let index = themes.findIndex(theme => theme.id === item.id);
        themes[index].theme_caption = e.target.value;

        let themeSetting = {
            ...settingTheme,
            themes
        };

        this.props.dispatchUpdateSettingTheme(themeSetting);
    }

    onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({
                    src: reader.result, imageEditorIsOpen: true
                })
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onCloseImageEditor(image, aspectRatio, id) {
        this.setState({ imageEditorIsOpen: false });

        const { settingTheme } = this.props;
        let themes = settingTheme.themes;
        let index = themes.findIndex(theme => theme.id === id);
        themes[index].image = image;
        themes[index].image_aspect_ratio = aspectRatio;

        let themeSetting = {
            ...settingTheme,
            themes
        };

        this.props.dispatchUpdateSettingTheme(themeSetting);
    }

    cancelPortalSettingTheme() {
        const { item, settingTheme } = this.props;
        let themes = settingTheme.themes;
        let index = themes.findIndex(theme => theme.id === item.id);
        themes.splice(index, 1);

        let themeSetting = {
            ...settingTheme,
            themes
        };

        this.props.dispatchUpdateSettingTheme(themeSetting);
    }

    createPortalSettingTheme() {
        const { item, settingTheme } = this.props;
        let index = settingTheme.themes.findIndex(theme => theme.id === item.id);
        if (!settingTheme.themes[index].theme_name) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Custom theme name is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        } else if (!settingTheme.themes[index].image) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Custom theme image preview is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        }
        else if (!settingTheme.themes[index].theme_css_file) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Custom theme css file is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        }

        this.props.addSettingTheme(settingTheme, index);
    }

    updatePortalSettingThemeChanges() {
        const { item, settingTheme } = this.props;
        let index = settingTheme.themes.findIndex(theme => theme.id === item.id);

        if (!settingTheme.themes[index].theme_name) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Custom theme name is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        } else if (!settingTheme.themes[index].image) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Custom theme image preview is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        }
        else if (!settingTheme.themes[index].theme_css_file) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Custom theme css file is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        }

        this.props.updateSettingTheme(settingTheme, index);
    }

    onCssFileUpload(e) {
        const { item, settingTheme } = this.props;
        let themes = settingTheme.themes;
        let index = themes.findIndex(theme => theme.id === item.id);
        const css_file_name = e.target.files[0].name;
        if (css_file_name.split('.').pop().toLowerCase() === 'css') {
            themes[index].theme_css_file = css_file_name;

            const reader = new FileReader();
            reader.addEventListener('load', () => {
                themes[index].theme_css = reader.result;
                let themeSetting = {
                    ...settingTheme,
                    themes
                };

                this.props.dispatchUpdateSettingTheme(themeSetting);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
        else {
            let setting_error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Please provide only css file',
                show_dialog: true
            };
            this.props.dispatchDialogPortalSettingsError(setting_error);
        }
    }

    displayAction() {
        this.setState({ stickyActionIsOpen: !this.state.stickyActionIsOpen });
    }

    hideAction() {
        this.setState({ stickyActionIsOpen: false });
    }

    removePortalSettingTheme() {
        const { item } = this.props;

        let portals = {
            id: item.id,
            name: item.theme_name,
            status: item.status,
            dialog_title: 'Warning',
            dialog_type: 'trash',
            dialog_context: 'theme',
            show_dialog: true
        };

        this.props.dispatchDialogPortals(portals);

        this.setState({ stickyActionIsOpen: !this.state.stickyActionIsOpen });
    }

    updateStatus() {
        const { item } = this.props;

        let portals = {
            id: item.id,
            name: item.theme_name,
            status: item.status,
            dialog_title: 'Warning',
            dialog_type: (item.status === 'Enabled') ? 'disable' : 'enable',
            dialog_context: 'theme',
            show_dialog: (item.status === 'Enabled') ? true : false
        };

        this.props.dispatchDialogPortals(portals);

        if (item.status === 'Disabled') {
            const { settingTheme } = this.props;
            let themes = settingTheme.themes;
            let index = themes.findIndex(theme => theme.id === item.id);
            themes[index].status = 'Enabled';

            let themeSetting = {
                ...settingTheme,
                themes
            };

            this.props.updateSettingTheme(themeSetting, index);
        }

        this.setState({ stickyActionIsOpen: !this.state.stickyActionIsOpen });
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    render() {
        const { item } = this.props;
        const { src, imageEditorIsOpen, stickyActionIsOpen } = this.state;
        return (
            <div className="sectionBanner p-4 mb-5">
                <div className="row">
                    <div className="col-md-12 col-lg-7 col-xl-6">
                        <div className="containerBanner p-4 text-center">
                            <img src={((item.image !== undefined && item.image !== "") ? "data:image/jpeg;base64," : process.env.PUBLIC_URL + "/images/no-image.png") + item.image} className="bannerDisplay" alt="" />
                            <span className={(item.status === "Disabled") ? "float-left text-red mt-4 pt-2" : "float-left text-lime mt-4 pt-2"}>
                                {(item.status === "Disabled") ? "Disabled" : "Enabled"}</span>
                            <label className="btn btn-wrd_default icoTextBtn browsePhoto m-0 mt-3">
                                <input type="file" onChange={this.onSelectFile} />
                                <span className="icon-wrd-folder"></span>
                                Browse </label>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-5 col-xl-6">
                        <div className="formWrap mt-5">
                            <div className="inputForm">
                                <input className="form-control" type="text" name=""
                                    defaultValue={item.theme_name} onChange={this.onSettingsNameChange} required />
                                <label>Theme Name</label>
                            </div>
                            <div className="d-block text-center">
                                {(item.theme_css_file !== undefined) ?
                                    <span className="text-lime">
                                        {item.theme_css_file}</span> : null
                                }
                            </div>
                            <div className="d-block text-center">
                                <label
                                    className="btn btn-wrd_default icoTextBtn browsePhoto m-0 mt-3">
                                    <input type="file" onChange={this.onCssFileUpload} accept=".css" /><span className="icon-wrd-file-css"></span>
                                    Browse Stylesheet
                                </label>
                            </div>
                            {(item.status !== '') ?
                                <div className="d-block text-center mt-5">
                                    <button className="btn textBtn greenBtn updateBtn" onClick={this.updatePortalSettingThemeChanges}>Update Changes</button>
                                </div>
                                :
                                <div className="row text-center mt-5">
                                    <div className="col-md-6 col-lg-6 order-md-2">
                                        <button className="btn textBtn greenBtn w-75" onClick={this.createPortalSettingTheme}>Create Theme</button>
                                    </div>
                                    <div className="col-md-6 col-lg-6 order-md-1">
                                        <button className="btn textBtn greyBtn w-75" onClick={this.cancelPortalSettingTheme}> Cancel</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {(item.status !== '' && item.theme_name !== 'Default Theme') ?
                    <button onClick={this.displayAction} onBlur={() => this.hideAction()} className="btn moreBtn"><span className="icon-wrd-more"></span></button>
                    : null
                }

                <ul className={(stickyActionIsOpen) ? "action show-action" : "action"}>
                    <li><Link to={routeCodes.PORTAL_SETTINGS} onMouseDown={this.updateStatus} className={(item.status === "Disabled") ? "greenLink" : "redLink"}><span
                        className="icon-wrd-lock"></span>{(item.status === "Disabled") ? "Enable" : "Disable"}</Link></li>
                    <li><Link to={routeCodes.PORTAL_SETTINGS} onMouseDown={this.removePortalSettingTheme} className="redLink"><span
                        className="icon-wrd-trash"></span>Remove</Link></li>
                </ul>

                {src &&
                    <Modal
                        isOpen={imageEditorIsOpen}
                        style={modalImageEditorStyle}
                        contentLabel="Image Editor">
                        <ImageEditor srcImage={src} onClose={this.onCloseImageEditor} idEditor={item.id} imgType={"other"} />
                    </Modal>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ portalSettings }) => ({
    settingTheme: portalSettings.setting_theme
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogPortals: (dialog) => dispatch(updateDialogPortalsAction(dialog)),
    dispatchDialogPortalSettingsError: (dialog) => dispatch(updateDialogPortalSettingsErrorAction(dialog)),
    dispatchUpdateSettingTheme: (settingTheme) => dispatch(updatePortalSettingThemeAction(settingTheme)),
    updateSettingTheme: (settingTheme, index) => dispatch(updatePortalSettingThemeWithThunk(settingTheme, index)),
    addSettingTheme: (settingTheme, index) => dispatch(addPortalSettingThemeWithThunk(settingTheme, index))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalSettingsThemeItem));