import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { updatePortalSettingEmailAction } from '../../../../actions/updatePortalSettingEmailAction';
import { updatePortalSettingEmailWithThunk } from '../../../../api/portalSettingsThunk';
import Loader from 'react-loader-spinner';
import { updatePortalSettingTabAction } from '../../../../actions/updatePortalSettingTabAction';
import PortalSettingsEmailItem from './PortalSettingsEmailItem';
import { routeCodes } from '../../../../configurations/routes';
import { 
    getPortalSettingEmailWithThunk
} from '../../../../api/portalSettingsThunk';

class PortalSettingsEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            src: null,
            imageEditorIsOpen: false,
            stateProp: null
        };
        
    }

    static propTypes = {
        portalSettingEmail: PropTypes.object.isRequired,
        dispatchPortalSettingEmail: PropTypes.func.isRequired,
        updatePortalSettingEmail: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        activeTab: PropTypes.string.isRequired,
        dispatchActiveTab: PropTypes.func.isRequired
    };

    switchActiveTab(activeTab) {
        this.props.dispatchActiveTab(activeTab);
    }
    
    updatePortalSettingEmail() {
        const { portalSettingEmail } = this.props;
        this.props.updatePortalSettingEmail(portalSettingEmail);
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    componentDidMount() {
        this.props.getPortalSettingEmail();
    }
    render() {
        const { portalSettingEmail, loading } = this.props;
        
        return (
            <div className="mainBody">
                {loading &&
                    <div className="loader-container-portal-email-setting">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                <div className="row mt-5">
                    <div className="col-md-12 order-lg-1 col-lg-8 col-xl-9">
                        <div className="wsHomeSection">
                            {(portalSettingEmail.emails !== undefined && portalSettingEmail.emails.length > 0) ?
                                portalSettingEmail.emails.map((item) => (
                                    <PortalSettingsEmailItem key={item.id} item={item} />
                                ))
                                :
                                'No match'
                            }
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4 col-xl-3">
                        <div className="wsLogo pb-5 text-center">
                            <ul className="setting-menus mt-5 w-75">
                                <strong>Settings Options</strong>
                                <li>
                                    <Link to={routeCodes.PORTAL_OPTIONS} >
                                        <span className="icon-wrd-log-in"></span> Login
                                        </Link>
                                </li>
                                <li>
                                    <Link to={routeCodes.PORTAL_OPTIONS_EMAIL} className="active">
                                        <span className="icon-wrd-mail-closed"></span>Email
                                    </Link>
                                </li>
                                <li>
                                    <Link to={routeCodes.PORTAL_OPTIONS_THEME} >
                                        <span className="icon-wrd-themes"></span>Themes
                                    </Link>
                                </li>
                            </ul>
                            <button className="btn textBtn greenBtn w-75 mt-5" onClick={() => this.updatePortalSettingEmail()}>Update Changes</button>
                        </div>
                    </div>
                </div>                
            </div>
        );
    }
}

const mapStateToProps = ({ portalSettings, loading }) => ({
    portalSettingEmail: portalSettings.setting_email,
    loading: loading.portal_setting_email.loading,
    activeTab: portalSettings.active_tab
});

const mapDispatchToProps = (dispatch) => ({
    
    getPortalSettingEmail: () => dispatch(getPortalSettingEmailWithThunk()),
    dispatchPortalSettingEmail: (emailSetting) => dispatch(updatePortalSettingEmailAction(emailSetting)),
    updatePortalSettingEmail: (emailSetting) => dispatch(updatePortalSettingEmailWithThunk(emailSetting)),
    dispatchActiveTab: (active_tab) => dispatch(updatePortalSettingTabAction(active_tab))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalSettingsEmail));