exports.settings="cloud";
exports.server="watertools-portal-server.azurewebsites.net";
exports.domain="watertools-portal.azurewebsites.net";
exports.port=80;
exports.portalServer ="watertools-portal.azurewebsites.net";
exports.portalPort=80;
exports.protocol="https://";
exports.useContainer=true;
exports.anonymous="00000000-0000-0000-0000-000000000000";
exports.emptyConnectionString="_$$_";
exports.spatialServer="epsg.io";
exports.protocol="https://";
exports.gatewayServerQuery="watertools-gateway-api.azurewebsites.net";
exports.gatewayPortQuery="80";
exports.gatewayAPiVersionQuery="v1";
exports.gatewayAPiVersionQueryV2="v2";
exports.gatewayServerCommand="watertools-gateway-api.azurewebsites.net";
exports.gatewayPortCommand="80";
exports.gatewayAPiVersionCommand="v1";
exports.gatewayAPiVersionCommandV2="v2";
exports.frontendServer="watertools-portal.azurewebsites.net";
exports.emailConfirmationPage="portal/login/users/confirmation";
exports.resetPasswordLoginPage = "portal/login/users";
exports.portalServerAppFolder="";
