import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class GridHeaderItem extends Component {
    static propTypes = {
        workspace: PropTypes.object.isRequired,
        header: PropTypes.object.isRequired
    };

    render() {
        const { header } = this.props;
        return (
            <th className="grid-no-sort">
                <div className="headerName">
                    {header.header_name}
                </div>
            </th>
        )
    }
}

const mapStateToProps = ({ detail }) => ({
    workspace: detail.workspace
});

const mapDispatchToProps = (dispatch) => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GridHeaderItem));