import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import RichTextEditor from '../../Editor/RichTextEditor';
import ImageEditor from '../../Editor/ImageEditor';
import { modalImageEditorStyle } from '../../../utils/modal';
import { updateAvailableWorkspacesOpenedStatus } from '../../../utils/workspaces';
import { getSessionStorage } from '../../../utils/sessionStorage';
import { updateWorkspaceAction } from '../../../actions/updateWorkspaceAction';
import { updateDialogWorkspacesAction } from '../../../actions/updateDialogWorkspacesAction';
import { updateDialogWorkspacesConnectionsAction } from '../../../actions/updateDialogWorkspacesConnectionsAction';
import { updateWorkspaceWithThunk } from '../../../api/workspacesThunk';
import { updateWorkspaceStatusWithThunk, updateWorkspaceActiveWithThunk } from '../../../api/workspacesThunk';
import { updateRoleAction } from '../../../actions/updateRoleAction';
import WorkspacesDetailApplicationsItem from './WorkspacesDetailApplicationsItem';
import { routeCodes } from '../../../configurations/routes';
import UserAvailableWorkspacesContainer from '../UserAvailableWorkspaces/UserAvailableWorkspacesContainer';
import { getWorkspaceByIdWithThunk } from '../../../api/workspacesThunk';
import { defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize } from '../../../configurations/app';
import { showSwitchButton } from '../../../configurations/app';
import { getWorkspacesFocusAreasConnectionsWithThunk } from '../../../api/workspacesFocusAreasThunk';
import { getUserAvailableWorkspacesWithThunk } from '../../../api/userAvailableWorkspacesThunk';
import { changeRouteAction } from '../../../actions/changeRouteAction';
import { clearContactOwnersAction } from '../../../actions/clearContactOwnersAction';

class WorkspacesDetailBody extends Component {
    constructor(props) {
        super(props);
        this.onWorkspaceTitleChange = this.onWorkspaceTitleChange.bind(this);
        this.onWorkspaceDescriptionChange = this.onWorkspaceDescriptionChange.bind(this);
        this.onDomainUrlChange = this.onDomainUrlChange.bind(this);
        this.onVideoUrlChange = this.onVideoUrlChange.bind(this);
        this.onWorkspaceUrlChange = this.onWorkspaceUrlChange.bind(this);
        this.updateWorkspaceDetail = this.updateWorkspaceDetail.bind(this);
        this.onWorkspaceAllowPublicAccessChange = this.onWorkspaceAllowPublicAccessChange.bind(this);
        this.onWorkspaceAllowUsersToRegisterChange = this.onWorkspaceAllowUsersToRegisterChange.bind(this);
        this.onSelectFile = this.onSelectFile.bind(this);
        this.onCloseImageEditor = this.onCloseImageEditor.bind(this);
        this.state = {
            src: null,
            imageEditorIsOpen: false,
            currentUserRole: props.role.user_role,
            wsListOpened: false,
            firstOpen: true,
            descriptions: '',
            original_workspace_name: ''
        };
        this.handleChangeWorkspaceTitle = this.handleChangeWorkspaceTitle.bind(this);
        this.handleChangeWorkspaceDesc = this.handleChangeWorkspaceDesc.bind(this);
        this.handleChangeDomainUrl = this.handleChangeDomainUrl.bind(this);
        this.handleChangeVideoUrl = this.handleChangeVideoUrl.bind(this);
        this.handleChangeWorkspaceUrl = this.handleChangeWorkspaceUrl.bind(this);
        this.handleChangeWorkspaceLogo = this.handleChangeWorkspaceLogo.bind(this);
        this.handleChangeWorkspacePublicAccess = this.handleChangeWorkspacePublicAccess.bind(this);
        this.handleChangeWorkspaceAllowUsersToRegister = this.handleChangeWorkspaceAllowUsersToRegister.bind(this);
        this.switchUserMode = this.switchUserMode.bind(this);
        this.roleButtonSwitchText = this.roleButtonSwitchText.bind(this);
        this.handleChangeWorkspaceDefaultAreaAccess = this.handleChangeWorkspaceDefaultAreaAccess.bind(this);
        this.handleChangeWorkspaceDefaultApplicationAccess = this.handleChangeWorkspaceDefaultApplicationAccess.bind(this);
        this.onToggleNoAreaAccess = this.onToggleNoAreaAccess.bind(this);
        this.onToggleNoApplicationAccess = this.onToggleNoApplicationAccess.bind(this);
        this.contactOwners = this.contactOwners.bind(this);
        this.uploadImg = this.uploadImg.bind(this);
        this.removeWorkspace = this.removeWorkspace.bind(this);
        this.hideList = this.hideList.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        availableApplications: PropTypes.object.isRequired,
        availableWorkspaces: PropTypes.object.isRequired,
        dispatchWorkspace: PropTypes.func.isRequired,
        dispatchDialogWorkspaces: PropTypes.func.isRequired,
        dispatchDialogWorkspacesConnections: PropTypes.func.isRequired,
        updateWorkspace: PropTypes.func.isRequired,
        updateWorkspaceStatus: PropTypes.func.isRequired,
        updateWorkspaceActive: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired,
        dispatchRole: PropTypes.func.isRequired
    };

    uploadImg(data, insertImage) {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
            insertImage(reader.result, image => {
                if (image.width() <= 400) {
                    image.css('width', image.width());
                } else {
                    image.css('width', '100%');
                }
            });

            const workspace = {
                ...this.props.workspace,
                workspace_desc: reader.result
            };

            this.props.dispatchWorkspace(workspace);
        }

    }

    changeRoute(name) {
        let navigation = {
            name
        };
        this.props.dispatchRoute(navigation);
    }

    contactOwners() {
        const { workspace } = this.props;
        this.changeRoute(routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+"));
        this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/contactOwners");
    }

    enterWorkspace() {
        const { workspace } = this.props;
        this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+"));
    }

    roleButtonSwitchText(roleName) {
        return (roleName === 'Admin') ? 'Owner' : (roleName === 'Owner') ? 'Admin' : null;
    }

    switchUserMode(switchToRole) {
        const role = {
            ...this.props.role,
            user_role: switchToRole
        }

        this.props.dispatchRole(role);
        this.setState({ currentUserRole: switchToRole });
        this.getSwitchAvailableWorkspaces();
    }

    getSwitchAvailableWorkspaces(requestRole) {
        const { workspace, role } = this.props;
        let userId = role.user_id;
        if (userId === "") {
            const sessionRole = getSessionStorage("role");

            if (sessionRole !== "") {
                const role = JSON.parse(sessionRole);
                userId = role.user_id;
            }
        }
        this.props.getWorkspaceById(workspace.id, userId);
    }

    onWorkspaceTitleChange(e) {
        const workspace = {
            ...this.props.workspace,
            workspace_title: e.target.value
        };

        this.props.dispatchWorkspace(workspace);
    }

    onWorkspaceDescriptionChange(data) {
        const workspace = {
            ...this.props.workspace,
            workspace_desc: data
        };

        this.props.dispatchWorkspace(workspace);
    }

    onDomainUrlChange(e) {
        const workspace = {
            ...this.props.workspace,
            domain_url: e.target.value
        };

        this.props.dispatchWorkspace(workspace);
    }

    onVideoUrlChange(e) {
        const workspace = {
            ...this.props.workspace,
            video_url: e.target.value
        };

        this.props.dispatchWorkspace(workspace);
    }

    onWorkspaceUrlChange(e) {
        const workspace = {
            ...this.props.workspace,
            workspace_url: e.target.value
        };

        this.props.dispatchWorkspace(workspace);
    }

    onWorkspaceAllowPublicAccessChange() {
        const { workspace } = this.props;

        let tobe = !workspace.allow_public_access;
        let workspaceDetail = {
            ...this.props.workspace,
            allow_public_access: !workspace.allow_public_access,
            allow_users_to_register: (!tobe) ? false : workspace.allow_users_to_register
        };
        this.props.dispatchWorkspace(workspaceDetail);
    }

    onWorkspaceAllowUsersToRegisterChange() {
        const { workspace } = this.props;

        let workspaceDetail = {
            ...this.props.workspace,
            allow_users_to_register: !workspace.allow_users_to_register
        };
        this.props.dispatchWorkspace(workspaceDetail);
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({ src: reader.result, imageEditorIsOpen: true });
            }
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onCloseImageEditor(image, aspectRatio) {
        this.setState({ imageEditorIsOpen: false });

        const workspace = {
            ...this.props.workspace,
            url_image: image,
            image_aspect_ratio: aspectRatio
        };

        this.props.dispatchWorkspace(workspace);
    }

    updateWorkspaceDetail() {
        const { workspace } = this.props;

        this.props.updateWorkspace(workspace, this.props.history);
    }

    updateWorkspaceStatus() {
        let workspaces = {
            id: this.props.workspace.id,
            name: this.props.workspace.workspace_name,
            status: (this.props.workspace.enabled) ? 'Enabled' : 'Disabled',
            dialog_title: 'Warning',
            dialog_type: (this.props.workspace.enabled) ? 'disable' : 'enable',
            route_back_to: '',
            show_dialog: (this.props.workspace.enabled) ? true : false
        };

        this.props.dispatchDialogWorkspaces(workspaces);
        if (!this.props.workspace.enabled) {
            let workspace = {
                ...this.props.workspace,
                enabled: true
            };

            this.props.updateWorkspaceStatus(workspace);
        }
    }

    updateWorkspaceActive() {
        let workspaces = {
            id: this.props.workspace.id,
            name: this.props.workspace.workspace_name,
            status: this.props.workspace.status,
            dialog_title: 'Warning',
            dialog_type: (this.props.workspace.status === 'Active') ? 'suspend' : 'reinstate',
            route_back_to: '',
            show_dialog: (this.props.workspace.status === 'Active') ? true : false
        };

        this.props.dispatchDialogWorkspaces(workspaces);
        if (this.props.workspace.status === 'Suspend') {
            let workspace = {
                ...this.props.workspace,
                status: (this.props.workspace.status === 'Active') ? 'Suspend' : 'Active'
            };

            this.props.updateWorkspaceActive(workspace);
        }
    }

    updateWorkspaceConnections() {
        this.props.getWorkspaceFocusAreaConnection(this.props.workspace.id);

        let workspaces_connections = {
            id: this.props.workspace.id,
            name: this.props.workspace.workspace_name,
            status: this.props.workspace.status,
            dialog_title: 'Connection Strings',
            dialog_type: (this.props.workspace.status === 'Enabled') ? 'disable' : 'enable',
            show_dialog: true
        };

        this.props.dispatchDialogWorkspacesConnections(workspaces_connections);

    }

    handleChangeWorkspaceTitle() {
        const { workspace } = this.props;

        let workspaceDetail = {
            ...this.props.workspace,
            workspace_title_locked: !workspace.workspace_title_locked

        };
        this.props.dispatchWorkspace(workspaceDetail);
    }

    handleChangeWorkspaceDesc() {
        const { workspace } = this.props;

        let workspaceDetail = {
            ...this.props.workspace,
            workspace_desc_locked: !workspace.workspace_desc_locked

        };
        this.props.dispatchWorkspace(workspaceDetail);
    }

    handleChangeDomainUrl() {
        const { workspace } = this.props;

        let workspaceDetail = {
            ...this.props.workspace,
            domain_url_locked: !workspace.domain_url_locked

        };
        this.props.dispatchWorkspace(workspaceDetail);
    }

    handleChangeVideoUrl() {
        const { workspace } = this.props;

        let workspaceDetail = {
            ...this.props.workspace,
            video_url_locked: !workspace.video_url_locked

        };
        this.props.dispatchWorkspace(workspaceDetail);
    }

    handleChangeWorkspaceUrl() {
        const { workspace } = this.props;

        let workspaceDetail = {
            ...this.props.workspace,
            workspace_url_locked: !workspace.workspace_url_locked

        };
        this.props.dispatchWorkspace(workspaceDetail);
    }

    handleChangeWorkspaceLogo() {
        const { workspace } = this.props;

        let workspaceDetail = {
            ...this.props.workspace,
            logo_locked: !workspace.logo_locked

        };
        this.props.dispatchWorkspace(workspaceDetail);
    }

    handleChangeWorkspacePublicAccess() {
        const { workspace } = this.props;

        let workspaceDetail = {
            ...this.props.workspace,
            public_access_locked: !workspace.public_access_locked

        };
        this.props.dispatchWorkspace(workspaceDetail);
    }

    handleChangeWorkspaceAllowUsersToRegister() {
        const { workspace } = this.props;

        let workspaceDetail = {
            ...this.props.workspace,
            users_to_register_locked: !workspace.users_to_register_locked

        };
        this.props.dispatchWorkspace(workspaceDetail);
    }

    handleChangeWorkspaceDefaultAreaAccess() {
        const { workspace } = this.props;

        let workspaceDetail = {
            ...this.props.workspace,
            focus_area_access_locked: !workspace.focus_area_access_locked

        };
        this.props.dispatchWorkspace(workspaceDetail);
    }

    handleChangeWorkspaceDefaultApplicationAccess() {
        const { workspace } = this.props;

        let workspaceDetail = {
            ...this.props.workspace,
            application_access_locked: !workspace.application_access_locked

        };
        this.props.dispatchWorkspace(workspaceDetail);
    }

    onToggleNoAreaAccess() {
        const workspaceDetail = {
            ...this.props.workspace,
            all_focus_area_access: !this.props.workspace.all_focus_area_access
        }

        this.props.dispatchWorkspace(workspaceDetail);
    }

    onToggleNoApplicationAccess() {
        const workspaceDetail = {
            ...this.props.workspace,
            all_application_access: !this.props.workspace.all_application_access
        }

        this.props.dispatchWorkspace(workspaceDetail);
    }  

    getAspectRatioClass() {
        const { image_aspect_ratio } = this.props.workspace;

        let aspectRatioClass = 'crop-logo43';
        if (image_aspect_ratio >= 1 && image_aspect_ratio < 1.2) {
            aspectRatioClass = 'crop-logo11';
        }
        else if (image_aspect_ratio >= 1.2 && image_aspect_ratio < 1.4) {
            aspectRatioClass = 'crop-logo43';
        }
        else if (image_aspect_ratio >= 1.4 && image_aspect_ratio < 1.6) {
            aspectRatioClass = 'crop-logo32';
        }
        else if (image_aspect_ratio >= 1.6 && image_aspect_ratio < 2.3) {
            aspectRatioClass = 'crop-logo169';
        }
        else if (image_aspect_ratio >= 2.3) {
            aspectRatioClass = 'crop-logo219';
        }

        return aspectRatioClass;
    }

    removeWorkspace() {
        let workspaces = {
            id: this.props.workspace.id,
            name: this.props.workspace.workspace_name,
            status: this.props.workspace.status,
            dialog_title: 'Warning',
            dialog_type: 'trash',
            route_back_to: routeCodes.WORKSPACES_LIST,
            show_dialog: true
        };

        this.props.dispatchDialogWorkspaces(workspaces);
    }

    availableWorkspacesSwitch() {
        this.setState({ wsListOpened: !this.state.wsListOpened });
        this.setState({ firstOpen: false });
        if (this.state.wsListOpened === false) {
            const { role } = this.props;
            this.props.getUserAvailableWorkspaces(role.user_id, role.user_role, defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize);
        }
        else {
            updateAvailableWorkspacesOpenedStatus(false);
        }
    }

    hideList() {
        this.setState({ wsListOpened: false });
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }    

    render() {
        const { workspace, availableApplications, role } = this.props;
        const { src, imageEditorIsOpen } = this.state;
        const buttonText = this.roleButtonSwitchText(this.state.currentUserRole);
        return (
            <div className="mainBody">
                <div className="row mt-5">
                    <div className="col-md-12 order-md-1 col-lg-4 col-xl-5">
                        <div className="wsHomeSection">
                            <b>Details</b>
                            <button className="btn iconBtn"><span className="icon-wrd-eye-off"></span></button>
                            <table style={{ width: '100%'}}>
                                <tbody>
                                    <tr>
                                        <th>Name</th>
                                        <td>{workspace.workspace_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td>
                                            <span className={(workspace.enabled && workspace.status === 'Active') ? "status-enabled" : "status-disabled"}>{(!workspace.enabled) ? 'Disabled' : (workspace.status !== 'Active') ? 'Suspended' :  'Enabled'}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Created On</th>
                                        <td>{workspace.created_date}</td>
                                    </tr>
                                    <tr>
                                        <th>Owner(s)</th>
                                        <td>{workspace.ownerCount}</td>
                                    </tr>
                                    <tr>
                                        <th>Member(s)</th>
                                        <td>{workspace.memberCount}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="wsHomeSection">
                            <b className="subTitle">Title</b>
                            <button className="btn iconBtn"><span className="icon-wrd-eye-off"></span></button>
                            <div className="desc pr-4">
                                <div className="formWrap">
                                    <div className={(role.user_role === 'Admin') ? 'groupForm' : null}>
                                        {
                                            (role.user_role === 'Admin') ?
                                                < label className="btn iconBtn customLockCheckbox">
                                                    <input type="checkbox" className="lockInput" onChange={this.handleChangeWorkspaceTitle} checked={!workspace.workspace_title_locked} />
                                                    <span className="icon-wrd-unlock"></span>
                                                    <span className="icon-wrd-lock"></span>
                                                </label> : null
                                        }
                                        <div className="inputForm">
                                            <input className="form-control" type="text" name="" required defaultValue={workspace.workspace_title} onChange={this.onWorkspaceTitleChange} disabled={(role.user_role !== 'Admin' && workspace.workspace_title_locked)} />
                                            <label>Title</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wsHomeSection">
                            <div className={(role.user_role === 'Admin') ? 'groupForm mb-0' : null}>
                                {
                                    (role.user_role === 'Admin') ?
                                        <label className="btn iconBtn customLockCheckbox">
                                            <input type="checkbox" className="lockInput" onChange={this.handleChangeWorkspaceDesc} checked={!workspace.workspace_desc_locked} />
                                            <span className="icon-wrd-unlock"></span>
                                            <span className="icon-wrd-lock"></span>
                                        </label> : null
                                }
                                <b className="subTitle">Description</b>
                                <button className="btn iconBtn"><span className="icon-wrd-eye-off"></span></button>
                            </div>
                            <div className="summernote">
                                <RichTextEditor value={workspace.workspace_desc} onChange={this.onWorkspaceDescriptionChange}
                                    onImgUpload={this.uploadImg}
                                    disabled={(role.user_role !== 'Admin' && workspace.workspace_desc_locked)}
                                />
                            </div>
                        </div>
                        <div className="wsHomeSection">
                            <b className="subTitle">Domain Url</b>
                            <button className="btn iconBtn"><span className="icon-wrd-eye-off"></span></button>
                            <div className="desc pr-4">
                                <div className="formWrap">
                                    <div className={(role.user_role === 'Admin') ? 'groupForm' : null}>
                                        {
                                            (role.user_role === 'Admin') ?
                                                < label className="btn iconBtn customLockCheckbox">
                                                    <input type="checkbox" className="lockInput" onChange={this.handleChangeDomainUrl} checked={!workspace.domain_url_locked} />
                                                    <span className="icon-wrd-unlock"></span>
                                                    <span className="icon-wrd-lock"></span>
                                                </label> : null
                                        }
                                        <div className="inputForm">
                                            <input className="form-control" type="text" name="" required defaultValue={workspace.domain_url} onChange={this.onDomainUrlChange} />
                                            <label>Domain Url</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wsHomeSection">
                            <b className="subTitle">Video Url</b>
                            <button className="btn iconBtn"><span className="icon-wrd-eye-off"></span></button>
                            <div className="desc pr-4">
                                <div className="formWrap">
                                    <div className={(role.user_role === 'Admin') ? 'groupForm' : null}>
                                        {
                                            (role.user_role === 'Admin') ?
                                                < label className="btn iconBtn customLockCheckbox">
                                                    <input type="checkbox" className="lockInput" onChange={this.handleChangeVideoUrl} checked={!workspace.video_url_locked} />
                                                    <span className="icon-wrd-unlock"></span>
                                                    <span className="icon-wrd-lock"></span>
                                                </label> : null
                                        }
                                        <div className="inputForm">
                                            <input className="form-control" type="text" name="" required defaultValue={workspace.video_url} onChange={this.onVideoUrlChange} />
                                            <label>Video Url</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wsHomeSection">
                            <b className="subTitle">Workspace Url</b>
                            <button className="btn iconBtn"><span className="icon-wrd-eye-off"></span></button>
                            <div className="desc pr-4">
                                <div className="formWrap">
                                    <div className={(role.user_role === 'Admin') ? 'groupForm' : null}>
                                        {
                                            (role.user_role === 'Admin') ?
                                                < label className="btn iconBtn customLockCheckbox">
                                                    <input type="checkbox" className="lockInput" onChange={this.handleChangeWorkspaceUrl} checked={!workspace.workspace_url_locked} />
                                                    <span className="icon-wrd-unlock"></span>
                                                    <span className="icon-wrd-lock"></span>
                                                </label> : null
                                        }
                                        <div className="inputForm">
                                            <input className="form-control" type="text" name="" required defaultValue={workspace.workspace_url} onChange={this.onWorkspaceUrlChange} />
                                            <label>Workspace Url</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wsHomeSection">
                            <div className={(role.user_role === 'Admin') ? 'groupForm mb-0' : null}>
                                {
                                    (role.user_role === 'Admin') ?
                                        <label className="btn iconBtn customLockCheckbox">
                                            <input type="checkbox" className="lockInput" onChange={this.handleChangeWorkspacePublicAccess} checked={!workspace.public_access_locked} />
                                            <span className="icon-wrd-unlock"></span>
                                            <span className="icon-wrd-lock"></span>
                                        </label> : null
                                }
                            </div>
                            <div className="custom-controls-stacked custom-controls-left-padding">
                                <label className="custom-control ios-switch">
                                    <input type="checkbox" onChange={this.onWorkspaceAllowPublicAccessChange} className="ios-switch-control-input" checked={workspace.allow_public_access} disabled={(role.user_role !== 'Admin' && workspace.public_access_locked)} />
                                    <span className="ios-switch-control-indicator"></span>
                                    <span className="text">Allow Public Access</span>
                                </label>
                            </div>
                            <div className={(role.user_role === 'Admin') ? 'groupForm mb-0' : null}>
                                {
                                    (role.user_role === 'Admin') ?
                                        <label className="btn iconBtn customLockCheckbox">
                                            <input type="checkbox" className="lockInput" onChange={this.handleChangeWorkspaceAllowUsersToRegister} checked={!workspace.users_to_register_locked} />
                                            <span className="icon-wrd-unlock"></span>
                                            <span className="icon-wrd-lock"></span>
                                        </label> : null
                                }
                            </div>
                            <div className="custom-controls-stacked custom-controls-left-padding">
                                <label className="custom-control ios-switch">
                                    <input type="checkbox" onChange={this.onWorkspaceAllowUsersToRegisterChange} className="ios-switch-control-input" checked={workspace.allow_users_to_register} disabled={(role.user_role !== 'Admin' && workspace.users_to_register_locked) || (!workspace.allow_public_access)} />
                                    <span className="ios-switch-control-indicator"></span>
                                    <span className="text">Allow Users to Register</span>
                                </label>
                            </div>
                        </div>
                        <div className="wsHomeSection">
                            <div className={(role.user_role === 'Admin') ? 'groupForm mb-0' : null}>
                                {
                                    (role.user_role === 'Admin') ?
                                        <label className="btn iconBtn customLockCheckbox">
                                            <input type="checkbox" className="lockInput" onChange={this.handleChangeWorkspaceDefaultAreaAccess} checked={!workspace.focus_area_access_locked} />
                                            <span className="icon-wrd-unlock"></span>
                                            <span className="icon-wrd-lock"></span>
                                        </label> : null
                                }
                                <b className="subTitle">Default member access to focus areas</b>
                                <button className="btn iconBtn"><span className="icon-wrd-eye-off"></span></button>
                            </div>

                            <div className="custom-control custom-control-radio custom-checkbox">
                                <input onChange={() => { this.onToggleNoAreaAccess() }} type="checkbox" className="custom-control-input" name="noFocusArea" id="noFocusArea" checked={!workspace.all_focus_area_access} disabled={(role.user_role !== 'Admin' && workspace.focus_area_access_locked)} />
                                <label className="custom-control-label pl-2 orangeText" htmlFor="noFocusArea">No focus area</label>
                            </div>  
                            <div className="custom-control custom-control-radio custom-checkbox">
                                <input onChange={() => { this.onToggleNoAreaAccess() }} type="checkbox" className="custom-control-input" name="allFocusArea" id="allFocusArea" checked={workspace.all_focus_area_access} disabled={(role.user_role !== 'Admin' && workspace.focus_area_access_locked)} />
                                <label className="custom-control-label pl-2 orangeText" htmlFor="allFocusArea" >All focus areas</label>
                            </div>                                                      
                        </div>                        

                        <div className="wsHomeSection">
                            <div className={(role.user_role === 'Admin') ? 'groupForm mb-0' : null}>
                                {
                                    (role.user_role === 'Admin') ?
                                        <label className="btn iconBtn customLockCheckbox">
                                            <input type="checkbox" className="lockInput" onChange={this.handleChangeWorkspaceDefaultApplicationAccess} checked={!workspace.application_access_locked} />
                                            <span className="icon-wrd-unlock"></span>
                                            <span className="icon-wrd-lock"></span>
                                        </label> : null
                                }
                                <b className="subTitle">Default member access to applications</b>
                                <button className="btn iconBtn"><span className="icon-wrd-eye-off"></span></button>
                            </div>

                            <div className="custom-control custom-control-radio custom-checkbox">
                                <input onChange={() => { this.onToggleNoApplicationAccess() }} type="checkbox" className="custom-control-input" name="noApplication" id="noApplication" checked={!workspace.all_application_access} disabled={(role.user_role !== 'Admin' && workspace.application_access_locked)} />
                                <label className="custom-control-label pl-2 orangeText" htmlFor="noApplication">No application</label>
                            </div>   
                            <div className="custom-control custom-control-radio custom-checkbox">
                                <input onChange={() => { this.onToggleNoApplicationAccess() }} type="checkbox" className="custom-control-input" name="allApplications" id="allApplications" checked={workspace.all_application_access} disabled={(role.user_role !== 'Admin' && workspace.application_access_locked)} />
                                <label className="custom-control-label pl-2 orangeText" htmlFor="allApplications" >All applications</label>
                            </div>                                                     
                        </div>

                    </div>
                    <div className="col-md-12 order-md-1 col-lg-4 col-xl-4">
                        <div className="wsHomeSection">
                            <b>Focus Area</b>
                            <div className="minimapContainer mt-4">
                                <img src={process.env.PUBLIC_URL + "/images/world-minimap.png"} alt="" />
                                <span className="faStatus">{workspace.focusAreasCount}</span>
                                <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/areas'} className="faSetup">Setup Area</Link>
                            </div>
                        </div>
                        <div className="wsHomeSection">
                            <b>Configuration</b>
                            <ul className="wsSettingMenu">
                                <li>
                                    <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/properties"} className="popupTrigger" onClick={() => this.updateWorkspaceConnections()}>
                                        <span className="icon-wrd-settings"></span>Connection(s)
                                    </Link>
                                </li>
                                <li>
                                    <div
                                        className="popupContainer h-100 d-none align-items-center justify-content-center">
                                        <div className="popupPanel popupPanel-small">
                                            <div className="popupHeader">
                                                <div className="p-4">
                                                    <h4 className="popupTitle">Properties</h4>
                                                    <button className="btn iconBtn"
                                                        targeturl="workspace-home.html"><span
                                                            className="icon-wrd-close"></span></button>
                                                </div>
                                            </div>
                                            <div className="popupBody">
                                                <div className="p-4">
                                                    <p className="text">Default Color</p>
                                                    <input type="color" defaultValue="#ff0000" />
                                                </div>
                                            </div>
                                            <div className="popupFooter">
                                                <div className="px-4 pt-4 pb-2">
                                                    <div className="row">
                                                        <div className="col"><button
                                                            className="btn greyBtn icoTextBtn closePopup"
                                                            targeturl="workspace-home.html"><span
                                                                className="icon-wrd-close"></span>Cancel</button>
                                                        </div>
                                                        <div className="col text-right"><button
                                                            className="btn greenBtn icoTextBtn mr-3 closePopup"
                                                            targeturl="workspace-home.html"><span
                                                                className="icon-wrd-edit"></span>
                                                            Update</button></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="wsHomeSection">
                            <b>Available Application(s)</b>
                            <button className="btn iconBtn"><span className="icon-wrd-eye-off"></span></button>
                            <ul className="wsAvailableApp">
                                {availableApplications.applications !== undefined && availableApplications.applications.map((item) => (
                                    <WorkspacesDetailApplicationsItem key={item.id} item={item} />
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-4 col-xl-3">
                        <div className="wsLogo pb-5 text-center">
                            <div className="logoContainer wsHome text-center">
                                <img src={process.env.PUBLIC_URL + "/images/transCircle.png"} className="insideCircle" alt="" />
                                <img src={((workspace.url_image !== undefined) ? "data:image/png;base64," : "") + workspace.url_image}
                                    className={this.getAspectRatioClass()} alt=""
                                />
                                {
                                    (role.user_role === 'Admin') ?
                                        <div className="logoLockContainer">

                                            <label className="btn iconBtn customLockCheckbox pt-4 pl-4">
                                                <input type="checkbox" className="lockInput" onChange={this.handleChangeWorkspaceLogo} checked={!workspace.logo_locked} />
                                                <span className="icon-wrd-unlock"></span>
                                                <span className="icon-wrd-lock"></span>
                                            </label>
                                        </div> : null
                                }
                            </div>
                            <button className="btn btn-wrd_default icoTextBtn browseLogo" disabled={(role.user_role !== 'Admin' && workspace.logo_locked)}>
                                <label className={(role.user_role === 'Admin' || !workspace.logo_locked) ? 'custom-file-upload' : null}>
                                    {
                                        (role.user_role === 'Admin' || !workspace.logo_locked) ?
                                            <input type="file" onChange={this.onSelectFile} />
                                            : null
                                    }
                                    <span className="icon-wrd-folder icon-upload-folder"></span>
                                    Browse
                                </label>
                            </button>
                            <span className="d-block mt-1 mb-5 font-italic text-grey">{(role.user_role === 'Admin' || !workspace.logo_locked) ? 'Browse to change logo' : null}</span>
                            <button className="btn mt-4 textBtn w-75 orangeBtn" onClick={() => this.enterWorkspace()}>Enter Workspace</button>

                            <button className="btn textBtn w-75 greenBtn" onClick={() => this.updateWorkspaceDetail()}>Update Changes</button>
                            {(role.user_role === 'Admin') ?
                                <button className={(workspace.enabled) ? 'btn textBtn w-75 redBtn' : 'btn textBtn w-75 greenBtn'}
                                    onClick={() => this.updateWorkspaceStatus()}>{(workspace.enabled) ? 'Disable' : 'Enable'}
                                </button>
                                : null
                            }
                            {(role.user_role === 'Admin' || workspace.owner) ?
                                <button className={(workspace.status === 'Active') ? 'btn textBtn w-75 redBtn' : 'btn textBtn w-75 greenBtn'}
                                    onClick={() => this.updateWorkspaceActive()}>{(workspace.status === 'Active') ? 'Suspend' : 'Reinstate'}
                                </button>
                                : null
                            }                            
                            <button className="btn textBtn  w-75 greenBtn" onClick={this.contactOwners} disabled={workspace.ownerCount === 0}>Contact Owners</button>
                        </div>
                    </div>
                </div>
                {src &&
                    <Modal
                        isOpen={imageEditorIsOpen}
                        style={modalImageEditorStyle}
                        contentLabel="Image Editor"
                    >
                        <ImageEditor srcImage={src} onClose={this.onCloseImageEditor} />
                    </Modal>
                }
                <div className={(this.state.firstOpen) ? "workspaceList d-none" : (this.state.wsListOpened) ? "workspaceList open animated fadeInRight" : "workspaceList open animated fadeOutRight"}>
                    <UserAvailableWorkspacesContainer hideList={this.hideList} />
                </div>
                <button className="btn iconBtn wsListBtn" onClick={() => this.availableWorkspacesSwitch()}>
                    <span className={(this.state.wsListOpened) ? "icon-wrd-close" : "icon-wrd-ws-list"}></span>
                </button>
                {showSwitchButton &&
                    <button className="btn customBtn workspaceHome d-none d-md-block" onClick={() => this.switchUserMode(buttonText)}>{buttonText} Mode</button>
                }
                <ul className="topNavBtn" style={{ right: '70px' }}>
                    {role.user_role === 'Admin' &&
                        <li>
                            <button className="btn redBtn" onClick={this.removeWorkspace}>
                                <span className="icon-wrd-trash"></span>
                                Remove Workspace
                            </button>
                        </li>
                    }
                </ul>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, availableApplications, availableWorkspaces, role, portalRouter }) => ({
    workspace: detail.workspace,
    availableApplications, availableWorkspaces,
    role,
    portalRouter
});

const mapDispatchToProps = (dispatch) => ({
    dispatchClearOwners: (contactOwners) => dispatch(clearContactOwnersAction(contactOwners)),     
    dispatchRoute: (navigation) => dispatch(changeRouteAction(navigation)),
    dispatchWorkspace: (workspace) => dispatch(updateWorkspaceAction(workspace)),
    dispatchDialogWorkspaces: (dialog) => dispatch(updateDialogWorkspacesAction(dialog)),
    dispatchDialogWorkspacesConnections: (dialog) => dispatch(updateDialogWorkspacesConnectionsAction(dialog)),
    updateWorkspace: (workspace, historyProps) => dispatch(updateWorkspaceWithThunk(workspace, historyProps)),
    updateWorkspaceStatus: (workspace) => dispatch(updateWorkspaceStatusWithThunk(workspace)),
    updateWorkspaceActive: (workspace) => dispatch(updateWorkspaceActiveWithThunk(workspace)),
    dispatchRole: (role) => dispatch(updateRoleAction(role)),
    getWorkspaceById: (workspaceId, userId) => dispatch(getWorkspaceByIdWithThunk(workspaceId, userId)),
    getWorkspaceFocusAreaConnection: (workspaceId) => dispatch(getWorkspacesFocusAreasConnectionsWithThunk(workspaceId)),
    getUserAvailableWorkspaces: (userId, userRole, pageIndex, pageSize) => dispatch(getUserAvailableWorkspacesWithThunk(userId, userRole, pageIndex, pageSize)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesDetailBody));