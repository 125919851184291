import environment from 'environment';
import { updateImage } from '../../utils/snapshot';

const config = require('../../environments/' + environment.settings);
const axios = require('axios');
const CircularJSON = require('circular-json');

async function getUserById(userId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "account/" + userId)
        .catch(function (error) { return error.response });
    return results;
}

async function getUserByName(userName) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "account/id/" + userName)
        .catch(function (error) { return error.response });
    return results;
}

async function getUserNameById(userId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "account/" + userId + "/username")
        .catch(function (error) { return error.response });
    return results;
}

async function getUserHeaderList(pageIndex, pageSize, sortingBy, sortingType) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "account/list", {
        params: {
            page: pageIndex,
            pageSize: pageSize,
            sort: sortingBy,
            order: sortingType
        }
    });

    return results;
}

async function getUserSearchList(pageIndex, pageSize, searchKeyword, sortingBy, sortingType) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "account/search", {
        params: {
            page: pageIndex,
            pageSize: pageSize,
            keyword: searchKeyword,
            sort: sortingBy,
            order: sortingType,
            photo: true
        }
    });
    return results;
}

async function getUserPhoto(userId, width, height) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const url = queryBaseUrl + "account/" + userId + "/picture/" + width + "/" + height;

    let results;
    try {
        const response = await axios.get(url);
        results = response.data;
    }
    catch (err) {
        if (err.response !== undefined) {
            if (err.response.status === 404) {
                return results;
            }
        }
    }
    return results;
}

async function getUserAccessById(userId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQueryV2 + "/";
    const results = await axios.get(queryBaseUrl + "account/" + userId + "/access")
        .catch(function (error) { return error.response });
    return results;
}

async function getUserAvailabeWorkspaces(userId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/member/" + userId);
    return results;
}

async function getUserAvailabeWorkspacesDetail(wsIds, pageIndex, pageSize, keyword) {
    let workspaceIds = "";
    for (let i in wsIds) {
        workspaceIds += "ids=" + wsIds[i].workspaceId + "&";
    }

    let keywords = (keyword !== undefined) ? "&keyword=" + keyword : "";
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";

    const results = await axios.get(queryBaseUrl + "workspace/search/available-workspaces?" + workspaceIds + "pageIndex=" + pageIndex + "&pageSize=" + pageSize + keywords)
        .catch(function (error) {
            if (error.response) {
                return error.response;
            }
        });

    return results;
}

async function getUserAccess(userId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/member/" + userId + "/focus-area/access")
        .catch(function (error) { return error.response });
    return results;
}

async function getUserAccessApplications(userId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/member/" + userId + "/application/access?all=true")
        .catch(function (error) { return error.response });
    return results;
}

async function getUserAccessWorkspaceApplications(workspaceId, userId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/" + workspaceId + "/member/" + userId + "/application/access?all=false")
        .catch(function (error) { return error.response });
    return results;
}

async function getWorkspaceRole(userId) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "workspace/member/" + userId)
        .catch(function (error) { return error.response });
    return results;
}

async function createUser(user, sendEmail) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const confirmationUrl = config.protocol + config.frontendServer + "/" + config.emailConfirmationPage + "/global";
    const results = await axios.post(commandBaseUrl + "management/account?confirmationUrl=" + confirmationUrl + "&sendingEmail=" + sendEmail, user)
        .catch(function (error) { return error.response });
    return results;
}

async function getUserIdByEmailName(data) {
    const queryBaseUrl = config.protocol + config.gatewayServerQuery + (config.gatewayPortQuery === "80" ? "" : ":" + config.gatewayPortQuery) + "/" + config.gatewayAPiVersionQuery + "/";
    const results = await axios.get(queryBaseUrl + "management/account/id?userName=" + data.userName + "&email=" + data.email)
        .catch(function (error) { return error.response });
    return results;
}

async function updateUser(user) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const results = await axios.put(commandBaseUrl + "account/" + user.id, user)
        .catch(function (error) { return error.response });
    return results;
}

async function updateUserAccess(userId, userAccess) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "workspace/member/" + userId + "/focus-area", userAccess)
        .catch(function (error) { return error.response });
    return results;
}

async function updateUserApplicationAccess(userId, userAccess) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "workspace/member/" + userId + "/applications", userAccess)
        .catch(function (error) { return error.response });
    return results;
}

async function updateUserAccessStatus(userId, workspaceId, status) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "workspace/" + workspaceId + "/member/" + userId + "/status/" + status)
        .then((response) => {
            let json = CircularJSON.stringify(response);
            return JSON.parse(json);
        }).catch((error) => {
            return error.response;
        });
    return results;
}

async function updateUserChangePassword(userId, passwords) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "account/password/" + userId, passwords)
        .catch(function (error) { return error.response });
    return results;
}

async function removeUserAccess(userId, workspaceId) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.delete(commandBaseUrl + "workspace/" + workspaceId + "/member/" + userId)
        .then((response) => {
            let json = CircularJSON.stringify(response);
            return JSON.parse(json);
        }).catch((error) => {
            return error.response;
        });
    return results;
}

async function updateUserStatus(id, status) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "management/account/" + id + "/status/" + status);
    return results;
}

async function updateUserRole(id, role) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.put(commandBaseUrl + "management/account/" + id + "/role/" + role);
    return results;
}

async function removeUser(id) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.delete(commandBaseUrl + "management/account/" + id, null);
    return results;
}

async function updatePhoto(userId, content) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommandV2 + "/";
    const url = commandBaseUrl + "account/" + userId + "/picture";

    return await updateImage(content, url);;
}

async function login(userPayload) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "authentication/login", userPayload)
        .then(res => {
            return res
        }).catch(function (error) { return error.response });
    return results;
}

async function loginConfirmation(userId, token, userPayload) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "authentication/confirmation/" + userId + "?token=" + token, userPayload)
        .catch(function (error) { return error.response });
    return results;
}

async function resetConfirmation(userId, token, userPayload) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "authentication/reset/" + userId + "?token=" + token, userPayload)
        .catch(function (error) { return error.response; });
    return results;
}

async function resetPasswordPortal(reset) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "authentication/forgot/", reset)
        .catch(function (error) { return error.response });
    return results;
}

async function resetPasswordPortalAdmin(uid, reset) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "management/account/" + uid + "/reset-password?resetUrl=" + reset.resetUrl)
        .catch(function (error) { return error.response });
    return results;
}

async function resetPasswordWorkspace(userId, workspaceId, reset) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "workspace/" + workspaceId + "/member/" + userId + "/forgot?resetUrl=" + reset.resetUrl)
        .catch(function (error) { return error.response });
    return results;
}

async function resetPasswordWorkspaceAdmin(uid, workspaceId, reset) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "workspace/" + workspaceId + "/member/" + uid + "/reset-password?resetUrl=" + reset.resetUrl)
        .catch(function (error) { return error.response });
    return results;
}

async function resendConfirmation(userId, urlParam) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "management/account/" + userId + "/confirmation-resend/", urlParam)
        .catch(function (error) { return error.response });
    return results;
}

async function resendConfirmationWorkspace(userId, workspaceId, urlParam) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "workspace/" + workspaceId + "/member/" + userId + "/confirmation-resend?confirmationUrl=" + urlParam.confirmationUrl)
        .catch(function (error) { return error.response; });
    return results;
}

async function sendContactAdministrators(uid, contactAdministrators) {
    const commandBaseUrl = config.protocol + config.gatewayServerCommand + (config.gatewayPortCommand === "80" ? "" : ":" + config.gatewayPortCommand) + "/" + config.gatewayAPiVersionCommand + "/";
    const results = await axios.post(commandBaseUrl + "account/" + uid + "/email/admin", contactAdministrators)
        .catch(function (error) { return error.response; });
    return results;
}

export {
    getUserById,
    getUserByName,
    getUserNameById,
    getUserHeaderList,
    getUserSearchList,
    getUserPhoto,
    getUserAccessById,
    getUserAvailabeWorkspaces,
    getUserAvailabeWorkspacesDetail,
    getUserAccess,
    getUserAccessApplications,
    getWorkspaceRole,
    getUserAccessWorkspaceApplications,
    getUserIdByEmailName,
    createUser,
    updateUser,
    updateUserAccess,
    updateUserApplicationAccess,
    updateUserAccessStatus,
    updateUserChangePassword,
    removeUserAccess,
    updateUserStatus,
    updateUserRole,
    removeUser,
    updatePhoto,
    login,
    loginConfirmation,
    resetConfirmation,
    resetPasswordPortal,
    resetPasswordPortalAdmin,
    resetPasswordWorkspace,
    resetPasswordWorkspaceAdmin,
    resendConfirmation,
    resendConfirmationWorkspace,
    sendContactAdministrators
}