import { GET_WORKSPACES_CATEGORIES, UPDATE_WORKSPACES_CATEGORIES, CLEAR_WORKSPACES_CATEGORIES } from '../actions/index';

const getWorkspacesCategories = (workspacesCategories = [], action) => {
    let itemsKeys = [];
    let results = [];

    if (action.workspacesCategories.length > 0) {
        let workspacesCategoriesKeys = action.workspacesCategories.reduce((keys, workspaceCategory) => {
            itemsKeys.push(workspaceCategory.category_id);
            keys = itemsKeys;
            return keys;
        }, []);

        let result = workspacesCategories.filter((workspaceCategory) => {
            return workspacesCategoriesKeys.indexOf(workspaceCategory.category_id) === -1;
        });

        results = result.concat(action.workspacesCategories);
        return results;
    }
    else {
        return results;
    }
}

const updateWorkspacesCategories = (workspacesCategories = [], action) => {
    const categories = workspacesCategories.map((item) => {
        const changesWorkspacesCategories = action.workspacesCategories.filter((change) => change.category_id === item.category_id)
        if (changesWorkspacesCategories.count > 0) {
            return changesWorkspacesCategories[0];
        }

        return item
    })

    return categories;
}

const clearWorkspacesCategories = (action) => {
    return action.workspacesCategories;
}

export const workspacesCategories = (workspacesCategories = [], action) => {
    switch (action.type) {
        case GET_WORKSPACES_CATEGORIES: return getWorkspacesCategories(workspacesCategories, action);
        case UPDATE_WORKSPACES_CATEGORIES: return updateWorkspacesCategories(workspacesCategories, action);
        case CLEAR_WORKSPACES_CATEGORIES: return clearWorkspacesCategories(action);
        default: return workspacesCategories;
    }
};