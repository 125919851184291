import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDialogWorkspacesSetGeoAction } from '../../../../actions/updateDialogWorkspacesSetGeoAction';
import { getSetGeometryWithThunk, postSetGeometryWithThunk } from '../../../../api/workspacesFocusAreasThunk';

class WorkspacesFocusAreasSetGeoModal extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }

        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        dispatchUpdateSetGeo: PropTypes.func.isRequired,
        workspace: PropTypes.object.isRequired,
        dialog: PropTypes.object.isRequired,
    };

    handleChange(e) {
        const { name, value } = e.target;

        let set_geo = {
            ...this.props.dialog,
            [name]: value,
        };
        this.props.dispatchUpdateSetGeo(set_geo);
    }

    handleSubmit() {
        const geoData = this.props.dialog;
        const { workspace } = this.props;

        let geometry = geoData.geometry.trim();
        if (geometry !== "") {
            try {
                let geoJSON = JSON.parse(geoData.geometry.trim());
                if (geoJSON.features !== undefined && geoJSON.features.length > 0) {
                    if (geoJSON.features[0].properties !== undefined) {
                        geoJSON.features[0].properties.feature_name = geoData.name;
                        geometry = JSON.stringify(geoJSON);
                    }
                }
            }
            catch (err) {
                geometry = geoData.geometry.trim();
            }
        }
        else {
            geometry = geoData.geometry.trim();
        }

        const data = {
            focusAreaId: geoData.id,
            workspaceId: workspace.id,
            format: geoData.format,
            geometry
        }

        this.props.dispatchPostSetGeo(workspace.id, data);
    }

    closeModal() {
        let set_Geometry = {
            id: '',
            name: '',
            status: '',
            format: 'GeoJSON',
            geometry: '',
            enabled: '',
            dialog_title: '',
            dialog_type: '',
            show_dialog: false
        };

        this.props.dispatchUpdateSetGeo(set_Geometry);
    }

    render() {
        const { dialog } = this.props;
        return (
            <div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div className={"popupPanel popupPanel-small deleteWarning" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                    <div className="popupHeader">
                        <div className="p-4">
                            <h2 className="popupTitle">{dialog.dialog_title}</h2>
                            <button className="btn iconBtn" onClick={this.closeModal}><span className="icon-wrd-close"></span>
                            </button>
                        </div>
                    </div>
                    <div className="popupBody">
                        <div className="container-fluid">
                            <div className="desc">
                                <div className="formWrap">
                                    <div className="px-2 py-3">
                                        <div className="inputForm">
                                            <select className="form-control" name="format" value={dialog.format} onChange={this.handleChange}>
                                                <option value="GeoJSON">GeoJSON</option>
                                                <option value="WKT">WKT Format</option>
                                            </select>
                                            <label>Choose Geometry Type</label>
                                        </div>
                                    </div>
                                    <div className="px-2 py-3">
                                        <p>{dialog.format} </p>
                                        <div className="inputForm">
                                            <textarea placeholder="Input you GeoJson Or WKT Format here" className="form-control" value={dialog.geometry} type="text"
                                                name="geometry" rows="7" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="px-4 pt-4 pb-3">
                            <div className="row">
                                <div className="col-md-6">
                                    <button className="btn greyBtn icoTextBtn closePopup" onClick={this.closeModal}>
                                        <span className="icon-wrd-close"></span>
                                        Cancel
                                    </button>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button className="btn textBtn greenBtn" onClick={this.handleSubmit}>Save Geometry</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, dialog, loading }) => ({
    workspace: detail.workspace,
    dialog: dialog.workspaces_set_geo,
    loading: loading.geometry.loading,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchPostSetGeo: (id, data) => dispatch(postSetGeometryWithThunk(id, data)),
    dispatchUpdateSetGeo: (data) => dispatch(updateDialogWorkspacesSetGeoAction(data)),
    getSetGeometry: (workspaceId, focusAreaId) => dispatch(getSetGeometryWithThunk(workspaceId, focusAreaId))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesFocusAreasSetGeoModal));