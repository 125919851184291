import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { updateWorkspaceEmailSettingAction } from '../../../../actions/updateWorkspaceEmailSettingAction';
import RichTextEditor from '../../../Editor/RichTextEditor';

class WorkspacesEmailSettingsItem extends Component {
	constructor(props) {
		super(props);
		this.handleDefaultEmailChange = this.handleDefaultEmailChange.bind(this);
		this.handleCustomEmailChange = this.handleCustomEmailChange.bind(this);
		this.handleLockedEmailChange = this.handleLockedEmailChange.bind(this);
		this.onEmailContentChange = this.onEmailContentChange.bind(this);
		this.onEmailSenderChange = this.onEmailSenderChange.bind(this);
		this.onEmailSubjectChange = this.onEmailSubjectChange.bind(this);
	}

	static propTypes = {
		workspace: PropTypes.object.isRequired,
		item: PropTypes.object.isRequired,
		role: PropTypes.object.isRequired,
		workspaceSetting: PropTypes.object.isRequired
	};

	handleDefaultEmailChange() {
		const { item, workspaceSetting } = this.props;
		let emails = [];

		for (let i in this.props.workspaceSetting.emails) {
			let email = this.props.workspaceSetting.emails[i];
			if (email.id === item.id) {
				email.email_selected = "Default";
			}
			emails.push(email);
		}

		let workspace_setting = {
			...workspaceSetting,
			emails
		};

		this.props.dispatchWorkspaceSetting(workspace_setting);
	}

	handleCustomEmailChange() {
		const { item, workspaceSetting } = this.props;
		let emails = [];

		for (let i in this.props.workspaceSetting.emails) {
			let email = this.props.workspaceSetting.emails[i];
			if (email.id === item.id) {
				email.email_selected = "Custom";
			}
			emails.push(email);
		}

		let workspace_setting = {
			...workspaceSetting,
			emails
		};

		this.props.dispatchWorkspaceSetting(workspace_setting);
	}

	handleLockedEmailChange() {
		const { item, workspaceSetting } = this.props;
		let emails = [];

		for (let i in this.props.workspaceSetting.emails) {
			let email = this.props.workspaceSetting.emails[i];
			if (email.id === item.id) {
				email.is_locked = !email.is_locked;
			}
			emails.push(email);
		}

		let workspace_setting = {
			...workspaceSetting,
			emails
		};

		this.props.dispatchWorkspaceSetting(workspace_setting);
	}

	onEmailContentChange(data) {
		const { item, workspaceSetting } = this.props;
		let emails = [];

		for (let i in this.props.workspaceSetting.emails) {
			let email = this.props.workspaceSetting.emails[i];
			if (email.id === item.id) {
				email.email_content = data;
			}
			emails.push(email);
		}

		let workspace_setting = {
			...workspaceSetting,
			emails
		};

		this.props.dispatchWorkspaceSetting(workspace_setting);
	}

	onEmailSenderChange(e) {
		const { item, workspaceSetting } = this.props;
		let emails = [];

		for (let i in this.props.workspaceSetting.emails) {
			let email = this.props.workspaceSetting.emails[i];
			if (email.id === item.id) {
				email.sender_from = e.target.value;
			}
			emails.push(email);
		}

		let workspace_setting = {
			...workspaceSetting,
			emails
		};

		this.props.dispatchWorkspaceSetting(workspace_setting);
	}

	onEmailSubjectChange(e) {
		const { item, workspaceSetting } = this.props;
		let emails = [];

		for (let i in this.props.workspaceSetting.emails) {
			let email = this.props.workspaceSetting.emails[i];
			if (email.id === item.id) {
				email.email_subject = e.target.value;
			}
			emails.push(email);
		}

		let workspace_setting = {
			...workspaceSetting,
			emails
		};

		this.props.dispatchWorkspaceSetting(workspace_setting);
	}

	render() {
		const { item, role } = this.props;

		return (
			<div>
				{(role.user_role === 'Admin' || (role.user_role !== 'Admin' && !item.is_locked)) ?
					<div className="groupForm mb-0">
						{(role.user_role === 'Admin') ?
							<label className="btn iconBtn customLockCheckbox">
								<input type="checkbox" className="lockInput" onChange={this.handleLockedEmailChange} checked={!item.is_locked} />
								<span className="icon-wrd-unlock"></span>
								<span className="icon-wrd-lock"></span>
							</label>
							: null
						}
						<label className="sectionTitle">{(item.email_type === 'Registration') ? 'Registration Email' : (item.email_type === 'RegistrationReminder') ? 'Registration Reminder Email' : (item.email_type === 'ResetPassword') ? 'Reset Password Email' : (item.email_type === 'ForgotPassword') ? 'Forgot Password Email' : (item.email_type === 'GrantWorkspace') ? 'Grant Workspace Email' : null}</label>
						<hr />
						{(item.email_type === 'Registration') ? <p>Choose how to configure email response related to registration.</p> :
							(item.email_type === 'RegistrationReminder') ?
								<p>Choose how to configure email response related to registration reminder.</p> :
								(item.email_type === 'ResetPassword') ?
									<p>Choose how to configure email response related to password reset.</p> :
									(item.email_type === 'ForgotPassword') ?
										<p>Choose how to configure email response related to forgot password.</p> :
										(item.email_type === 'GrantWorkspace') ?
											<p>Choose how to configure email response related to user assignment to workspace.</p> :
											null
						}

						<div className="row">
							<div className="col-sm-4 col-md-4 col-xl-4 mb-5">
								<label className="custom-checkbox custom-pick-image disable-email-confirmation">
									<input type="radio" name={item.email_type} className="custom-control-input"
										onChange={this.handleDefaultEmailChange} checked={(item.email_selected === 'Default')} />
									<div className="imgContainer">
                                        <img src={process.env.PUBLIC_URL + "/images/default-email.jpg"} className="themeImg" alt="" />
									</div>
									<p><span className="icon-wrd-checkmark"></span> Default Portal Email
                                                </p>
								</label>
							</div>
							<div className="col-sm-4 col-md-4 col-xl-4 mb-5">
								<label className="custom-checkbox custom-pick-image enable-email-confirmation">
									<input type="radio" name={item.email_type} className="custom-control-input"
										onChange={this.handleCustomEmailChange} checked={(item.email_selected === 'Custom')} />
									<div className="imgContainer">
                                        <img src={process.env.PUBLIC_URL + "/images/custom-email.jpg"} className="themeImg" alt="" />
									</div>
									<p><span className="icon-wrd-checkmark"></span> Custom Email
                                                </p>
								</label>
							</div>
						</div>
						<div className="row">
							<div className="summernotWrap mx-3 mb-5 w-100 container">
								<div className="formWrap row">
									<div className="inputForm col-md-6 px-2">
										{(item.email_selected === 'Custom') &&
											<input className="form-control" type="text" name="" value={item.sender_from} onChange={this.onEmailSenderChange}
											disabled={(item.email_selected !== 'Custom')} required />
										}
										{(item.email_selected === 'Default') &&
											<input className="form-control" type="text" name="" value={item.sender_from_default} onChange={this.onEmailSenderChange}
											disabled={(item.email_selected !== 'Custom')} required />
										}
										<label>From</label>
									</div>
									<div className="inputForm col-md-6 px-2">
										{(item.email_selected === 'Custom') &&
											<input className="form-control" type="text" name="" onChange={this.onEmailSubjectChange}
											value={item.email_subject} disabled={(item.email_selected !== 'Custom')} required />
										}
										{(item.email_selected === 'Default') &&
											<input className="form-control" type="text" name="" onChange={this.onEmailSubjectChange}
											value={item.email_subject_default} disabled={(item.email_selected !== 'Custom')} required />
										}
										
										<label>Subject</label>
									</div>
									<div className="inputForm">
										{(item.email_selected === 'Custom') &&
											<RichTextEditor className="summernote" value={item.email_content} onChange={this.onEmailContentChange} disabled={(item.email_selected !== 'Custom')} />
										}
										{(item.email_selected === 'Default') &&
											<RichTextEditor className="summernote" value={item.email_content_default} onChange={this.onEmailContentChange} disabled={(item.email_selected !== 'Custom')} />
										}
										<div id="email-confirmation" className="summernote"></div>
									</div>									
								</div>
							</div>
						</div>
					</div>
					: null
				}
			</div>
		);
	}
}

const mapStateToProps = ({ detail, role }) => ({
	workspaceSetting: detail.workspace_email_setting,
	workspace: detail.workspace,
	role
});

const mapDispatchToProps = (dispatch) => ({
	dispatchWorkspaceSetting: (workspaceSetting) => dispatch(updateWorkspaceEmailSettingAction(workspaceSetting))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesEmailSettingsItem));