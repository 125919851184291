import {
    UPDATE_TOASTER,
    UPDATE_DIALOG_WORKSPACES,
    UPDATE_DIALOG_WORKSPACES_CONNECTIONS,
    UPDATE_DIALOG_WORKSPACES_LANDING_APPLICATION,
    UPDATE_DIALOG_APPLICATIONS,
    UPDATE_DIALOG_APPLICATIONS_SETTINGS,
    UPDATE_DIALOG_CATEGORIES_CREATION_ERROR,
    UPDATE_DIALOG_WORKSPACES_APPLICATIONS,
    UPDATE_DIALOG_WORKSPACES_APPLICATIONS_CONNECTION,
    UPDATE_DIALOG_WORKSPACES_MEMBERS,
    UPDATE_DIALOG_USERS,
    UPDATE_DIALOG_USERS_LOGIN_ERROR,
    UPDATE_DIALOG_ACCOUNT,
    UPDATE_DIALOG_WORKSPACES_FOCUS_AREAS,
    UPDATE_DIALOG_WORKSPACES_SET_GEO,
    UPDATE_DIALOG_PORTALS,
    UPDATE_DIALOG_USERS_CREATION_ERROR,
    UPDATE_DIALOG_WORKSPACES_CREATION_ERROR,
    UPDATE_DIALOG_APPLICATIONS_CREATION_ERROR,
    UPDATE_DIALOG_WORKSPACES_APPLICATIONS_UPDATE_ERROR,
    UPDATE_DIALOG_RESET_PASSWORD_ERROR,
    UPDATE_DIALOG_REGISTRATION_REMINDER_ERROR,
    UPDATE_DIALOG_WORKSPACE_SETTINGS_ERROR,
    UPDATE_DIALOG_PORTAL_SETTINGS_ERROR,
    UPDATE_DIALOG_WORKSPACES_MEMBERS_SHARE,
    UPDATE_DIALOG_SHARING_MEMBERS_ERROR,
    UPDATE_DIALOG_CONTACT_ADMINISTRATORS_ERROR,
    UPDATE_DIALOG_FOCUS_AREAS_SHAPE_ERROR,
    UPDATE_DIALOG_FOCUS_AREAS_SHAPE_FILE,
    UPDATE_DIALOG_FOCUS_AREAS_SETGEO_ERROR,
    UPDATE_DIALOG_WORKSPACES_CATEGORIES_CREATION_ERROR
} from '../actions/index';

const initialDialogState = {
    toaster: {
        status: '',
        message: '',
        error:'',
        show_dialog: false,
        redirect: false,
        dialog_title: '',
        dialog_type: ''
    },
    workspaces: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        route_back_to: '',
        show_dialog: false
    },
    workspaces_connections: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    workspaces_landing_application: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        link: '',
        control_key: false,
        show_dialog: false
    },
    applications: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        route_back_to: '',
        show_dialog: false
    },
    applications_settings: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    workspaces_applications: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        route_back_to: '',
        show_dialog: false
    },
    workspaces_applications_connection: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    workspaces_members: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    users: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    login_error: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    workspaces_focus_areas: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    workspaces_set_geo: {
        id: '',
        name: '',
        status: '',
        format:'GeoJSON',
        geometry:'',
        enabled:'',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    portals: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        dialog_context: '',
        show_dialog: false
    },
    user_creation_error: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: ''
    },
    workspace_creation_error: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false,
        domain_url: '',
        workspace_name: '',
        history: null
    },
    application_creation_error: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    workspace_application_update_error: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    reset_password_error: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    registration_reminder_error: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    workspace_settings_error: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    portal_settings_error: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    workspaces_members_share: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    sharing_members_error: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    contact_administrators_error: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    focus_areas_shape_error: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    focus_areas_shape_file: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    focus_areas_setgeo_error: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    category_creation_error: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    },
    workspace_category_creation_error: {
        id: '',
        name: '',
        status: '',
        dialog_title: '',
        dialog_type: '',
        show_dialog: false
    }
}

export const dialog = (state = initialDialogState, action) => {
    const { workspaces_set_geo, toaster, workspaces, workspaces_connections, workspaces_landing_application, applications, applications_settings, category_creation_error,
        workspaces_applications, workspaces_applications_connection, workspaces_members, users, login_error, account, workspaces_focus_areas,
        portals, user_creation_error, workspace_creation_error, application_creation_error, workspace_application_update_error,
        reset_password_error, registration_reminder_error, workspace_settings_error, portal_settings_error, workspaces_members_share, sharing_members_error,
        contact_administrators_error, focus_areas_shape_error, focus_areas_shape_file, focus_areas_setgeo_error, workspace_category_creation_error} = action

    switch (action.type) {
        case UPDATE_TOASTER:
            return {
                ...state,
                toaster
            }
        case UPDATE_DIALOG_WORKSPACES:
            return {
                ...state,
                workspaces
            }
        case UPDATE_DIALOG_WORKSPACES_CONNECTIONS:
            return {
                ...state,
                workspaces_connections
            }
        case UPDATE_DIALOG_WORKSPACES_LANDING_APPLICATION:
            return {
                ...state,
                workspaces_landing_application
            }
        case UPDATE_DIALOG_APPLICATIONS:
            return {
                ...state,
                applications
            }
        case UPDATE_DIALOG_APPLICATIONS_SETTINGS:
            return {
                ...state,
                applications_settings
            }
        case UPDATE_DIALOG_CATEGORIES_CREATION_ERROR:
            return {
                ...state,
                category_creation_error
            }
        case UPDATE_DIALOG_WORKSPACES_APPLICATIONS:
            return {
                ...state,
                workspaces_applications
            }
        case UPDATE_DIALOG_WORKSPACES_APPLICATIONS_CONNECTION:
            return {
                ...state,
                workspaces_applications_connection
            }
        case UPDATE_DIALOG_WORKSPACES_MEMBERS:
            return {
                ...state,
                workspaces_members
            }
        case UPDATE_DIALOG_USERS:
            return {
                ...state,
                users
            }
        case UPDATE_DIALOG_USERS_LOGIN_ERROR:
            return {
                ...state,
                login_error
            }
        case UPDATE_DIALOG_ACCOUNT:
            return {
                ...state,
                account
            }
        case UPDATE_DIALOG_WORKSPACES_FOCUS_AREAS:
            return {
                ...state,
                workspaces_focus_areas
            }
        case UPDATE_DIALOG_WORKSPACES_SET_GEO:
            return {
                ...state,
                workspaces_set_geo
            }
        case UPDATE_DIALOG_PORTALS:
            return {
                ...state,
                portals
            }
        case UPDATE_DIALOG_USERS_CREATION_ERROR:
            return {
                ...state,
                user_creation_error
            }
        case UPDATE_DIALOG_WORKSPACES_CREATION_ERROR:
            return {
                ...state,
                workspace_creation_error
            }
        case UPDATE_DIALOG_APPLICATIONS_CREATION_ERROR:
            return {
                ...state,
                application_creation_error
            }
        case UPDATE_DIALOG_WORKSPACES_APPLICATIONS_UPDATE_ERROR:
            return {
                ...state,
                workspace_application_update_error
            }
        case UPDATE_DIALOG_RESET_PASSWORD_ERROR:
            return {
                ...state,
                reset_password_error
            }
        case UPDATE_DIALOG_REGISTRATION_REMINDER_ERROR:
            return {
                ...state,
                registration_reminder_error
            }
        case UPDATE_DIALOG_WORKSPACE_SETTINGS_ERROR:
            return {
                ...state,
                workspace_settings_error
            }
        case UPDATE_DIALOG_PORTAL_SETTINGS_ERROR:
            return {
                ...state,
                portal_settings_error
            }
        case UPDATE_DIALOG_WORKSPACES_MEMBERS_SHARE:
            return {
                ...state,
                workspaces_members_share
            }
        case UPDATE_DIALOG_SHARING_MEMBERS_ERROR:
            return {
                ...state,
                sharing_members_error
            }
        case UPDATE_DIALOG_CONTACT_ADMINISTRATORS_ERROR:
            return {
                ...state,
                contact_administrators_error
            }
        case UPDATE_DIALOG_FOCUS_AREAS_SHAPE_ERROR:
            return {
                ...state,
                focus_areas_shape_error
            }
        case UPDATE_DIALOG_FOCUS_AREAS_SHAPE_FILE:
            return {
                ...state,
                focus_areas_shape_file
            }
        case UPDATE_DIALOG_FOCUS_AREAS_SETGEO_ERROR:
            return {
                ...state,
                focus_areas_setgeo_error
            }
        case UPDATE_DIALOG_WORKSPACES_CATEGORIES_CREATION_ERROR:
            return {
                ...state,
                workspace_category_creation_error
            }
        default:
            return state;
    }
};