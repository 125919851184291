import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Rummernote from 'rummernote/build/bs4';

class RichTextEditor extends Component {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        disabled: PropTypes.bool
    };

    onTextChange = (data) => {
        this.props.onChange(data);
    }

    uploadImageCallBack = (img, insertImage) => {
        this.props.onImgUpload(img, insertImage);
    }


    render() {
        const { value, disabled } = this.props;
        return (
            <Rummernote
                value={value}
                onChange={(e) => { this.onTextChange(e); }}
                onImageUpload={this.uploadImageCallBack}
                options={{ tooltip: false, followingToolbar: false }}
                disabled={disabled}
            />
        );
    }
}

export default withRouter(RichTextEditor);