import React, { Component } from 'react';
import { Link } from 'react-router-dom'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { routeCodes } from '../../../configurations/routes';
import { updateDialogApplicationsAction } from '../../../actions/updateDialogApplicationsAction';
import { updateApplicationStatusWithThunk } from '../../../api/applicationsThunk';
import { defaultApplicationsSortingBy, defaultApplicationsSortingType } from '../../../configurations/app';

class ApplicationsListItem extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        pagination: PropTypes.object.isRequired,
        dialog: PropTypes.object.isRequired,
        dispatchDialogApplications: PropTypes.func.isRequired,
        updateApplicationStatus: PropTypes.func.isRequired
    };

    state = {
        flip: false
    }

    onMouseEnter() {
        this.setState({ flip:true });
    }

    onMouseLeave() {
        this.setState({ flip:false });
    }

    getApplicationClassName() {
        const { item } = this.props;
        let applicationClass = "appPanel";

        applicationClass += (item.status === 'Enabled') ? " enable" : " disable";
        applicationClass += (item.is_new) ? " new" : "";

        return applicationClass;
    }

    async showApplicationDetail() {
        let name = this.props.item.application_name.toLowerCase().replaceAll(" ", "+");
        this.props.history.push(routeCodes.APPLICATIONS_LIST + "/" + name);
    }

    onLockApplication() {
        let applications = {
            id: this.props.item.id,
            name: this.props.item.application_name,
            status: this.props.item.status,
            dialog_title: 'Warning',
            dialog_type: (this.props.item.status === 'Enabled') ? 'disable' : 'enable',
            route_back_to: '',
            show_dialog: (this.props.item.status === 'Enabled') ? true : false
        };

        this.props.dispatchDialogApplications(applications);
        if (this.props.item.status === 'Disabled') {
            this.updateApplication();
        }
    }

    setDefaultDialogState() {
        let applications = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            route_back_to: '',
            show_dialog: false
        };

        this.props.dispatchDialogApplications(applications);
    }

    updateApplication() {
        const { item } = this.props;
        this.setDefaultDialogState();
        let application = {
            id: item.id,
            status: (item.status === 'Enabled') ? 'Disabled' : 'Enabled'
        }
        this.props.updateApplicationStatus(application, defaultApplicationsSortingBy, defaultApplicationsSortingType);
    }

    onTrashApplication() {
        let applications = {
            id: this.props.item.id,
            name: this.props.item.application_name,
            status: this.props.item.status,
            dialog_title: 'Warning',
            dialog_type: 'trash',
            route_back_to: '',
            show_dialog: true
        };

        this.props.dispatchDialogApplications(applications);
    }

    onDragStart = (e, item) => {
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData("text/html", e.target.parentNode);
        e.dataTransfer.setData("application_id", item.id);
        e.dataTransfer.setData("category_title", item.category_title);
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
        this.props.onSelect(item);
        this.props.setDragInfoState(item.id, item.category_title, null);
    };

    onDragEnd = () => {
        this.draggedIdx = null;
    };

    render() {
        const { flip } = this.state;
        const { item } = this.props;

        return (
            <div className={this.getApplicationClassName()} draggable={ true } onDragStart={e => this.onDragStart(e, item)} onDragEnd={this.onDragEnd} onMouseEnter={() => { this.onMouseEnter(); }} onMouseLeave={() => { this.onMouseLeave(); }}>
                <div className="appLogo"><img src={((item.url_image !== undefined) ? "data:image/jpeg;base64," + item.url_image : "")} alt="" /></div>
                <div className="appName d-flex align-items-center justify-content-center">
                    <span>{item.application_name}</span>
                </div>
                <span className="activeLabel">{item.status}</span>
                <div className="newApp">
                    <img src={process.env.PUBLIC_URL + "/images/newApp.png"} alt="" />
                </div>
                <div className={"flipInfo animated " + ((flip) ? "flipInY" : "d-none flipOutY")}>
                    <div className="position-relative h-100 w-100">
                        <Link className="detailLink" to={routeCodes.APPLICATIONS_LIST + "/" + item.application_name.toLowerCase().replaceAll(" ", "+")}>
                            <div className="bodyTooltip">
                                <h3>{item.application_name}</h3>
                                <p>
                                    <b>Title</b>
                                    <span className="text">{item.application_title}</span>
                                </p>
                                <p>
                                    <b>Keywords</b>
                                    {item.keywords.map((keyword) => (
                                        <span key={keyword} className="badge badge-pill">{keyword} </span>
                                    ))}
                                </p>
                            </div>
                        </Link>
                        <div className="actTooltip">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col p-0 text-center">
                                        <button title="Edit" className="btn iconBtn" onClick={() => { this.showApplicationDetail() }}>
                                            <span className="icon-wrd-edit"></span>
                                        </button>
                                    </div>
                                    <div className="col p-0 text-center">
                                        <button title={(item.status === 'Enabled') ? 'Disable' : 'Enable'} className="btn iconBtn popupTrigger" onClick={() => { this.onLockApplication() }}>
                                            <span className={((item.status === 'Enabled') ? 'icon-lock icon-wrd-lock' : 'icon-unlock icon-wrd-unlock')}></span>
                                        </button>
                                    </div>
                                    <div className="col p-0 text-center">
                                        <button title="Remove" className="btn iconBtn popupTrigger deleteApp">
                                            <span className="icon-wrd-trash" onClick={() => { this.onTrashApplication() }}></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ pagination, dialog }) => ({
    pagination: pagination.application,
    dialog: dialog.applications
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogApplications: (dialog) => dispatch(updateDialogApplicationsAction(dialog)),
    updateApplicationStatus: (application, sortingBy, sortingType) => dispatch(updateApplicationStatusWithThunk(application, sortingBy, sortingType))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationsListItem));
