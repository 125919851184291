import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getWorkspacesApplicationsWithThunk } from '../../../../api/workspacesListThunk';
import { getWorkspacesApplicationsByIdWithThunk } from '../../../../api/workspacesListThunk';
import { clearWorkspacesApplicationsAction } from '../../../../actions/clearWorkspacesApplicationsAction';
import { getUrlWorkspaceId } from '../../../../utils/url';
import { getSessionStorage } from '../../../../utils/sessionStorage';
import Loader from 'react-loader-spinner'
import SidebarContainer from '../../../Sidebar/SidebarContainer';
import WorkspacesApplicationsHeader from './WorkspacesApplicationsHeader';
import WorkspacesApplicationsBody from './WorkspacesApplicationsBody';
import WorkspacesApplicationsModal from '../Modal/WorkspacesApplicationsModal';

class WorkspacesApplicationsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isApplicationsLoad: false
        }
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired,
        applications: PropTypes.array.isRequired,
        getWorkspacesApplicationsById: PropTypes.func.isRequired,
        getWorkspacesApplications: PropTypes.func.isRequired,
        clearWorkspacesApplications: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired
    };

    getCurrentWorkspaceId = () => {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    componentDidUpdate(prevProps) {
        const { workspace, applications, role, loading } = this.props;
        const id = this.getCurrentWorkspaceId();
        let isAdmin = (role.user_role === "Admin") ? true : false;

        if (prevProps.applications !== applications && applications.length === 0) {
            if (loading === false) {
                if (id !== workspace.workspace_name.toLowerCase().replaceAll(" ", "+")) {
                    let userId = role.user_id;
                    if (userId === "") {
                        const sessionRole = getSessionStorage("role");

                        if (sessionRole !== "") {
                            const role = JSON.parse(sessionRole);
                            userId = role.user_id;
                            isAdmin = (role.user_role === "Admin") ? true : false;
                        }
                    }
                    this.props.getWorkspacesApplicationsById(id, userId, isAdmin, this.props.history);
                    this.setState({ isApplicationsLoad: true });
                }
                else {
                    this.props.getWorkspacesApplications(id, isAdmin);
                }
            }
        }
    }

    componentWillMount() {
        this.props.clearWorkspacesApplications();
    }

    render() {
        const { workspace, applications, loading, loadingTheme } = this.props;

        if (workspace.id === undefined || workspace.id === '') {
            return (
                <div>
                    {(loading || loadingTheme) &&
                        <div className="loader-container">
                            <Loader
                                type="Ball-Triangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                </div>
            );
        }

        return (
            <div>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <SidebarContainer />
                        <main>
                            <div className="mapBackground" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <WorkspacesApplicationsHeader />
                                    <WorkspacesApplicationsBody items={applications} workspace={workspace} />
                                </div>
                            </div>
                        </main>
                        <WorkspacesApplicationsModal type='list' />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, workspacesApplications, role, loading }) => ({
    workspace: detail.workspace,
    applications: workspacesApplications,
    role,
    loading: loading.workspaces_applications.loading,
    loadingTheme: loading.theme.loading
});

const mapDispatchToProps = (dispatch) => ({
    getWorkspacesApplications: (id, isAdmin) => dispatch(getWorkspacesApplicationsWithThunk(id, isAdmin)),
    getWorkspacesApplicationsById: (id, userId, isAdmin, historyProps) => dispatch(getWorkspacesApplicationsByIdWithThunk(id, userId, isAdmin, historyProps)),
    clearWorkspacesApplications: () => dispatch(clearWorkspacesApplicationsAction([])),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsContainer));
