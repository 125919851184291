import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner'
import PortalSettingsBannerItem from './PortalSettingsBannerItem';
import { updatePortalSettingBannerAction } from '../../../../actions/updatePortalSettingBannerAction';
import {
    getPortalSettingBannerWithThunk,
} from '../../../../api/portalSettingsThunk';
class PortalSettingsBanner extends Component {
    constructor(props) {
        super(props);
        this.addPortalSettingBanner = this.addPortalSettingBanner.bind(this);
    }

    static propTypes = {
        settingBanner: PropTypes.object.isRequired,
        dispatchUpdateSettingBanner: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired
    };

    addPortalSettingBanner() {
        const { settingBanner } = this.props;
        let banners = settingBanner.banners;

        let bannerSetting = {
            ...settingBanner,
            banners
        };
        let tempLength = bannerSetting.banners.length + 1;
        var newObj = {
            id: "temp" + tempLength,
            banner_title: "",
            banner_caption: "",
            image: "",
            status: ""
        }
        bannerSetting.banners.unshift(newObj);

        this.props.dispatchUpdateSettingBanner(bannerSetting);
    }
    componentDidMount() {

        this.props.getPortalSettingBanner();
    }

    render() {
        const { settingBanner, loading } = this.props;

        return (
            <div className="row mt-5">
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="Ball-Triangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                <div className="col-md-12">
                    <div className="wsHomeSection">
                        <h2 className="float-left mt-2 mr-4">Banners</h2>
                        <button className="btn icoTextBtn btn-wrd_default mb-4" onClick={() => this.addPortalSettingBanner()}><span className="icon-wrd-add"></span>
                            Add Banner</button>
                        {(settingBanner.banners !== undefined && settingBanner.banners.length > 0) ?
                            settingBanner.banners.map((item) => (
                                <PortalSettingsBannerItem key={item.id} item={item} />
                            ))
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ portalSettings, loading }) => ({
    settingBanner: portalSettings.setting_banner,
    loading: loading.portal_setting_banner.loading,
});

const mapDispatchToProps = (dispatch) => ({
    getPortalSettingBanner: () => dispatch(getPortalSettingBannerWithThunk()),
    dispatchUpdateSettingBanner: (settingBanner) => dispatch(updatePortalSettingBannerAction(settingBanner)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalSettingsBanner));