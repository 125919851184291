import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../../configurations/routes';
import { getUrlWorkspaceId, getUrlUserId, getUrlWorkspaceMemberId } from '../../../utils/url';
import { isWorkspaceRootPage } from '../../../utils/login';

class UserAccessHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            linkTo: ''
        }
    }

    static propTypes = {
        user: PropTypes.object.isRequired        
    };

    componentDidMount() {
        const location = this.props.location.pathname;

        if (isWorkspaceRootPage(location)) {
            let workspaceId = getUrlWorkspaceId(this.props.location.pathname);
            let userId = getUrlWorkspaceMemberId(this.props.location.pathname);
            this.setState({ linkTo: routeCodes.WORKSPACES_LIST + '/' + workspaceId + '/members/' + userId + '/detail'});
        }
        else {
            let id = getUrlUserId(this.props.location.pathname);
            this.setState({ linkTo: routeCodes.USERS_LIST + '/' + id});
        }
    }

    render() {
        const { user } = this.props;

        return (
            <div className="mainHead">
                <div className="row">
                    <div className="col-12">
                        <h2 className="float-left mt-2 mr-4">User Access</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="workspaceInfo">
                            <div className="row">
                                <div className="workspaceName col-xl-7 offset-xl-3">
                                    <h3>{user.full_name}</h3>
                                </div>
                                <div className="userRole col-xl-7 offset-xl-3">{user.id}</div>
                            </div>
                            <nav className="col-lg-5 col-xl-4">
                                <div className="row text-center">
                                    <div className="col px-0">
                                        <Link to={this.state.linkTo}
                                            className="workspaceMenu py-3">Detail
                                        </Link>
                                    </div>
                                    <div className="col px-0">
                                        <Link to={'#'}
                                            className="workspaceMenu py-3 active">Access
                                        </Link>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ userAccess }) => ({
    user: userAccess.user
});

export default withRouter(connect(mapStateToProps)(UserAccessHeader));