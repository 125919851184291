import { CHANGE_NAVIGATION } from '../actions/index';

const initialNavigationState = {
    name: 'Workspaces'
}

export const navigation = (state = initialNavigationState, action) => {
    const { navigation } = action
    switch (action.type) {
        case CHANGE_NAVIGATION:
            return navigation
        default:
            return state;
    }
};