import { GET_CONTACTS_OWNERS, UPDATE_CONTACTS_OWNERS, CLEAR_CONTACTS_OWNERS, GET_USER_ACCESS_CONTACT_OWNERS } from '../actions/index';

const initialNavigationState = {
    ownersAccess: [],
    owners: [],
    emailContent: '',
    subject: '',
    email: '',
}

export const contactOwners = (state = initialNavigationState, action) => {
    const { contactOwners, owners, ownersAccess } = action
    switch (action.type) {
        case GET_USER_ACCESS_CONTACT_OWNERS:
            return {
                ...state,
                ownersAccess: [...state.ownersAccess, ownersAccess]
            }
        case GET_CONTACTS_OWNERS:
            return {
                ...state,
                owners: owners
            }
        case UPDATE_CONTACTS_OWNERS:
            return contactOwners
        case CLEAR_CONTACTS_OWNERS:
            return initialNavigationState
        default:
            return state;
    }
};