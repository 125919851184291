import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { routeCodes } from '../../../../configurations/routes';
import { updateDialogWorkspacesApplicationsAction } from '../../../../actions/updateDialogWorkspacesApplicationsAction';
import { updateWorkspaceApplicationLinkStatusWithThunk } from '../../../../api/workspacesListThunk';

class WorkspacesApplicationsItem extends Component {
    constructor(props) {
        super(props);

        this.showWorkspaceApplicationDetail = this.showWorkspaceApplicationDetail.bind(this);
    }

    static propTypes = {
        role: PropTypes.object.isRequired,
        item: PropTypes.object.isRequired,
        dispatchDialogWorkspacesApplications: PropTypes.func.isRequired,
        updateWorkspaceApplicationLinkStatus: PropTypes.func.isRequired
    };

    state = {
        flip: false
    }

    getApplicationClassName() {
        const { item } = this.props;
        let applicationClass = "appPanel";

        applicationClass += (item.is_enabled_global) ? " enable" : " disable";
        applicationClass += (item.is_linked) ? " active" : "";
        applicationClass += (item.is_new) ? " new" : "";

        return applicationClass;
    }

    onMouseEnter() {
        this.setState({ flip: true });
    }

    onMouseLeave() {
        this.setState({ flip: false });
    }

    onEnableApplication() {
        const { item, role } = this.props;

        if (!item.is_linked)
            return;

        let workspacesApplications = {
            id: item.id,
            name: item.application_name,
            status: item.status,
            dialog_title: 'Warning',
            dialog_type: (item.is_enabled) ? 'Enabled' : 'Disabled',
            route_back_to: '',
            show_dialog: (item.is_enabled) ? true : false
        };
        this.props.dispatchDialogWorkspacesApplications(workspacesApplications);

        if (!item.is_enabled) {
            const isAdmin = (role.user_role.toLowerCase() === 'admin') ? true : false;
            this.props.updateWorkspaceApplicationLinkStatus(item.workspace_id, item.id, 'enable', true, isAdmin);
        }
    }

    onLinkApplication() {
        const { item, role } = this.props;

        let workspacesApplications = {
            id: item.id,
            name: item.application_name,
            status: item.status,
            dialog_title: 'Warning',
            dialog_type: (item.is_linked) ? 'Linked' : 'Unlinked',
            route_back_to: '',
            show_dialog: (item.is_linked) ? true : false
        };
        this.props.dispatchDialogWorkspacesApplications(workspacesApplications);

        if (!item.is_linked) {
            const isAdmin = (role.user_role.toLowerCase() === 'admin') ? true : false;
            this.props.updateWorkspaceApplicationLinkStatus(item.workspace_id, item.id, 'link', true, isAdmin);
        }
    }

    showWorkspaceApplicationDetail(e) {
        const { item } = this.props;

        if (!item.is_linked || !item.is_enabled_global) {
            return e.preventDefault();
        }
    }

    async showWorkspaceApplicationProperties(e, wName, appName) {
        this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + wName + "/applications/" + appName + "/properties");
    }

    async showWorkspaceApplicationSettings(e, wName, appName) {
        this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + wName + "/applications/" + appName + "/settings");
    }

    onDragStart = (e, item) => {
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData("text/html", e.target.parentNode);
        e.dataTransfer.setData("application_id", item.id);
        e.dataTransfer.setData("category_title", item.category_title);
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
        this.props.onSelect(item);
        this.props.setDragInfoState(item.id, item.category_title, null);
    };

    onDragEnd = () => {
        this.draggedIdx = null;
    };

    render() {
        const { flip } = this.state;
        const { item } = this.props;

        if (!item.is_enabled_global && !item.is_linked) {
            return null;
        } else {
        return (
            <div draggable={item.is_linked} onDrop={(e) => this.props.onDrop(e)} onDragStart={e => this.onDragStart(e, item)} onDragEnd={this.onDragEnd} className={this.getApplicationClassName()} onMouseEnter={() => { this.onMouseEnter(); }} onMouseLeave={() => { this.onMouseLeave(); }} targeturl="app-data-management.html">
                <div className="appLogo"><img src={((item.url_image !== undefined) ? "data:image/jpeg;base64," + item.url_image : "")} alt="" /></div>
                <div className="appName d-flex align-items-center justify-content-center">
                    <span>{item.application_name}</span>
                </div>
                <span className={(item.is_enabled_global && item.is_linked && !item.is_enabled) ? 'activeLabel status-disabled-important' : 'activeLabel'}>{(!item.is_enabled_global && item.is_linked) ? 'Disabled' : (item.is_enabled_global && item.is_linked && item.is_enabled) ? 'Active' : (item.is_enabled_global && item.is_linked && !item.is_enabled) ? 'Suspended' : null}</span>
                <div className="newApp">
                    <img src={process.env.PUBLIC_URL + "/images/newApp.png"} alt="" />
                </div>
                <div className={"flipInfo animated " + ((flip) ? "flipInY" : "d-none flipOutY")}>
                    <div className="position-relative h-100 w-100">
                        <Link className={(item.is_linked && item.is_enabled_global) ? "detailLink" : "detailLink link-disabled"}
                            onClick={this.showWorkspaceApplicationDetail}
                            to={routeCodes.WORKSPACES_LIST + "/" + item.workspace_name.toLowerCase() + "/applications/" + item.application_name.toLowerCase().replaceAll(" ", "+") + "/properties"}>
                            <div className="bodyTooltip">
                                <h3 className="mb-4">{item.application_name}</h3>
                                <p>
                                    <b>Title</b>
                                    <span className="text">{item.application_title}</span>
                                </p>
                                <p>
                                    <b>Keywords</b>
                                    {(item.keywords !== undefined) && item.keywords.map((keyword, index) => (
                                        <span key={index} className="badge badge-pill">{keyword} </span>
                                    ))}
                                </p>
                            </div>
                        </Link>
                        <div className="actTooltip">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col p-0 text-center">
                                        {(item.is_linked && item.is_enabled_global) ?
                                            <button title="Edit" className="btn iconBtn popupTrigger" onClick={(e) => this.showWorkspaceApplicationProperties(e, item.workspace_name.toLowerCase(), item.application_name.toLowerCase().replaceAll(" ", "+"))}>
                                                <span className="icon-wrd-edit"></span>
                                            </button>
                                            : null}
                                    </div>
                                    <div className="col p-0 text-center">
                                        {(item.is_linked && item.is_enabled_global) ?
                                            <button title="Settings" className="btn iconBtn popupTrigger" onClick={(e) => this.showWorkspaceApplicationSettings(e, item.workspace_name.toLowerCase(), item.application_name.toLowerCase().replaceAll(" ", "+"))}>
                                                <span className="icon-wrd-tune"></span>
                                            </button>
                                            : null}
                                    </div>
                                    <div className="col p-0 text-center">
                                        {(item.is_linked && item.is_enabled_global) ?
                                            <button title={(item.is_enabled) ? 'Suspend' : 'Reinstate'} className="btn iconBtn popupTrigger" onClick={() => { this.onEnableApplication(); }}>
                                                <span className={((item.is_linked) ? ((item.is_enabled) ? 'icon-lock icon-wrd-lock' : 'icon-unlock icon-wrd-unlock') : 'icon-wrd-lock')} ></span>
                                            </button>
                                            : null}
                                    </div>
                                    <div className="col p-0 text-center">
                                        <button title={(item.is_linked) ? 'Revoke' : 'Grant'} className={'btn iconBtn popupTrigger' + ((item.is_linked) ? ' revokeApp' : ' grantApp')} onClick={() => { this.onLinkApplication(); }}>
                                            <span className={(item.is_linked) ? 'icon-wrd-revoke' : 'icon-wrd-grant'}></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        }
    }
}


const mapDispatchToProps = (dispatch) => ({
    dispatchDialogWorkspacesApplications: (dialog) => dispatch(updateDialogWorkspacesApplicationsAction(dialog)),
    updateWorkspaceApplicationLinkStatus: (workspaceId, applicationId, status, isFromList, isAdmin) => dispatch(updateWorkspaceApplicationLinkStatusWithThunk(workspaceId, applicationId, status, isFromList, isAdmin))
});

export default withRouter(connect(null, mapDispatchToProps)(WorkspacesApplicationsItem));