import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import ImageEditor from '../../../Editor/ImageEditor';
import { modalImageEditorStyle } from '../../../../utils/modal';
import { updateWorkspaceLoginSettingAction } from '../../../../actions/updateWorkspaceLoginSettingAction';
import { updateWorkspaceLoginSettingWithThunk } from '../../../../api/workspacesLoginSettingsThunk';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../../../configurations/routes';
import RichTextEditor from '../../../Editor/RichTextEditor';
import { defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize } from '../../../../configurations/app';
import { getUserAvailableWorkspacesWithThunk } from '../../../../api/userAvailableWorkspacesThunk';
import UserAvailableWorkspacesContainer from '../../UserAvailableWorkspaces/UserAvailableWorkspacesContainer';
import { updateAvailableWorkspacesOpenedStatus } from '../../../../utils/workspaces';

class WorkspacesLoginSettingsBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            src: null,
            imageEditorIsOpen: false,
            stateProp: null,
            wsListOpened: false,
            firstOpen: true
        };
        this.handleWelcomeNoTextChange = this.handleWelcomeNoTextChange.bind(this);
        this.handleWelcomeDefaultChange = this.handleWelcomeDefaultChange.bind(this);
        this.handleWelcomeCustomChange = this.handleWelcomeCustomChange.bind(this);
        this.handleWelcomeLogoNoBackgroundChange = this.handleWelcomeLogoNoBackgroundChange.bind(this);
        this.handleWelcomeLogoDefaultChange = this.handleWelcomeLogoDefaultChange.bind(this);
        this.handleWelcomeLogoCustomChange = this.handleWelcomeLogoCustomChange.bind(this);
        this.onWelcomeTextChange = this.onWelcomeTextChange.bind(this);
        this.handleLoginLogoNoBackgroundChange = this.handleLoginLogoNoBackgroundChange.bind(this);
        this.handleLoginLogoDefaultChange = this.handleLoginLogoDefaultChange.bind(this);
        this.handleLoginLogoCustomChange = this.handleLoginLogoCustomChange.bind(this);
        this.handleWelcomeTextLocked = this.handleWelcomeTextLocked.bind(this);
        this.handleWelcomeLogoLocked = this.handleWelcomeLogoLocked.bind(this);
        this.handleLoginLogoLocked = this.handleLoginLogoLocked.bind(this);
        this.onSelectFile = this.onSelectFile.bind(this);
        this.onCloseImageEditor = this.onCloseImageEditor.bind(this);
        this.hideList = this.hideList.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        workspaceSetting: PropTypes.object.isRequired,
        dispatchWorkspaceSetting: PropTypes.func.isRequired,
        updateWorkspaceSetting: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired
    };

    handleWelcomeNoTextChange() {
        let workspaceSetting = {
            ...this.props.workspaceSetting,
            welcome_text_selected: "NoText"
        };
        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    handleWelcomeDefaultChange() {
        let workspaceSetting = {
            ...this.props.workspaceSetting,
            welcome_text_selected: "Default"
        };
        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    handleWelcomeCustomChange() {
        let workspaceSetting = {
            ...this.props.workspaceSetting,
            welcome_text_selected: "Custom"
        };
        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    handleWelcomeLogoNoBackgroundChange() {
        let workspaceSetting = {
            ...this.props.workspaceSetting,
            welcome_background_selected: "NoBackground"
        };
        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    handleWelcomeLogoDefaultChange() {
        let workspaceSetting = {
            ...this.props.workspaceSetting,
            welcome_background_selected: "Default"
        };
        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    handleWelcomeLogoCustomChange() {
        let workspaceSetting = {
            ...this.props.workspaceSetting,
            welcome_background_selected: "Custom"
        };
        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    onWelcomeTextChange(data) {
        let workspaceSetting = {
            ...this.props.workspaceSetting,
            welcome_text: data
        };
        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    handleLoginLogoNoBackgroundChange() {
        let workspaceSetting = {
            ...this.props.workspaceSetting,
            login_background_selected: "NoBackground"
        };
        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    handleLoginLogoDefaultChange() {
        let workspaceSetting = {
            ...this.props.workspaceSetting,
            login_background_selected: "Default"
        };
        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    handleLoginLogoCustomChange() {
        let workspaceSetting = {
            ...this.props.workspaceSetting,
            login_background_selected: "Custom"
        };
        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    handleWelcomeTextLocked() {
        let workspaceSetting = {
            ...this.props.workspaceSetting,
            welcome_text_locked: !this.props.workspaceSetting.welcome_text_locked

        };
        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    handleWelcomeLogoLocked() {
        let workspaceSetting = {
            ...this.props.workspaceSetting,
            welcome_background_locked: !this.props.workspaceSetting.welcome_background_locked

        };
        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    handleLoginLogoLocked() {
        let workspaceSetting = {
            ...this.props.workspaceSetting,
            login_background_locked: !this.props.workspaceSetting.login_background_locked

        };
        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    onSelectFile = (e, stateProp) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({ src: reader.result, imageEditorIsOpen: true, stateProp: stateProp });
            }
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onSelectColor = (e, stateProp) => {
        let workspaceSetting = {};
        if (stateProp === 'welcome_background') {
            workspaceSetting = {
                ...this.props.workspaceSetting,
                welcome_background_color: e.target.value
            };
        } else {
            workspaceSetting = {
                ...this.props.workspaceSetting,
                login_background_color: e.target.value
            };
        }
        this.props.dispatchWorkspaceSetting(workspaceSetting);
    };

    onCloseImageEditor(image, aspectRatio) {
        this.setState({ imageEditorIsOpen: false });
        let workspaceSetting = {};
        if (this.state.stateProp === 'welcome_background') {
            workspaceSetting = {
                ...this.props.workspaceSetting,
                welcome_background_custom_image: image,
                welcome_background_custom_image_aspect_ratio: aspectRatio
            };
        } else {
            workspaceSetting = {
                ...this.props.workspaceSetting,
                login_background_custom_image: image,
                login_background_custom_image_aspect_ratio: aspectRatio
            };
        }

        this.props.dispatchWorkspaceSetting(workspaceSetting);
    }

    getAspectRatioClass() {
        const { image_aspect_ratio } = this.props.workspace;

        let aspectRatioClass = 'crop-logo43';
        if (image_aspect_ratio >= 1 && image_aspect_ratio < 1.2) {
            aspectRatioClass = 'crop-logo11';
        }
        else if (image_aspect_ratio >= 1.2 && image_aspect_ratio < 1.4) {
            aspectRatioClass = 'crop-logo43';
        }
        else if (image_aspect_ratio >= 1.4 && image_aspect_ratio < 1.6) {
            aspectRatioClass = 'crop-logo32';
        }
        else if (image_aspect_ratio >= 1.6 && image_aspect_ratio < 2.3) {
            aspectRatioClass = 'crop-logo169';
        }
        else if (image_aspect_ratio >= 2.3) {
            aspectRatioClass = 'crop-logo219';
        }

        return aspectRatioClass;
    }

    availableWorkspacesSwitch() {
        this.setState({ wsListOpened: !this.state.wsListOpened });
        this.setState({ firstOpen: false });
        if (this.state.wsListOpened === false) {
            const { role } = this.props;
            this.props.getUserAvailableWorkspaces(role.user_id, role.user_role, defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize);
        }
        else {
            updateAvailableWorkspacesOpenedStatus(false);
        }
    }

    updateWorkspaceSetting() {
        const { workspaceSetting } = this.props;
        this.props.updateWorkspaceSetting(workspaceSetting);
    }

    hideList() {
        this.setState({ wsListOpened: false });
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    render() {
        const { workspace, workspaceSetting, role } = this.props;
        const { src, imageEditorIsOpen } = this.state;

        return (
            <div className="mainBody">
                <div className="row mt-5">
                    <div className="col-md-12 order-lg-1 col-lg-8 col-xl-9">
                        <div className="wsHomeSection">
                            {(role.user_role === 'Admin' || (role.user_role !== 'Admin' && !workspaceSetting.welcome_text_locked)) ?
                                <div className="groupForm mb-0">
                                    {(role.user_role === 'Admin') ?
                                        <label className="btn iconBtn customLockCheckbox">
                                            <input type="checkbox" className="lockInput" onChange={this.handleWelcomeTextLocked} checked={!workspaceSetting.welcome_text_locked} />
                                            <span className="icon-wrd-unlock"></span>
                                            <span className="icon-wrd-lock"></span>
                                        </label>
                                        : null
                                    }
                                    <label className="sectionTitle">Login Page Welcome Text</label>
                                    <hr />
                                    <p>Choose how to configure welcome text on login page.</p>
                                    <div className="row">
                                        <div className="col-sm-4 col-md-4 col-xl-4 mb-5">
                                            <label className="custom-checkbox custom-pick-image">
                                                <input type="radio" name="lpwt" className="custom-control-input"
                                                    onChange={this.handleWelcomeNoTextChange} checked={(workspaceSetting.welcome_text_selected === 'NoText')} />
                                                <div className="imgContainer">
                                                    <img src={process.env.PUBLIC_URL + "/images/no-text.png"} className="themeImg" alt="" />
                                                </div>
                                                <p><span className="icon-wrd-checkmark"></span> No Text</p>
                                            </label>
                                        </div>
                                        <div className="col-sm-4 col-md-4 col-xl-4 mb-5">
                                            <label className="custom-checkbox custom-pick-image">
                                                <input type="radio" name="lpwt" className="custom-control-input"
                                                    onChange={this.handleWelcomeDefaultChange} checked={(workspaceSetting.welcome_text_selected === 'Default')} />
                                                <div className="imgContainer">
                                                    <img src={process.env.PUBLIC_URL + "/images/default-portal-text.png"} className="themeImg" alt="" />
                                                </div>
                                                <p><span className="icon-wrd-checkmark"></span> Default Portal Text
                                                </p>
                                            </label>
                                        </div>
                                        <div className="col-sm-4 col-md-4 col-xl-4 mb-5">
                                            <label className="custom-checkbox custom-pick-image reveal">
                                                <input type="radio" name="lpwt" className="custom-control-input"
                                                    onChange={this.handleWelcomeCustomChange} checked={(workspaceSetting.welcome_text_selected === 'Custom')} />
                                                <div className="imgContainer">
                                                    <img src={process.env.PUBLIC_URL + "/images/custom-text.png"} className="themeImg" alt="" />
                                                </div>
                                                <p><span className="icon-wrd-checkmark"></span> Custom Text
                                                </p>
                                            </label>
                                        </div>
                                    </div>
                                    {(workspaceSetting.welcome_text_selected === 'Custom') ?
                                        <div className="row">
                                            <div className="summernotWrap mx-3 mb-5 w-100 ">
                                                <RichTextEditor value={workspaceSetting.welcome_text} onChange={this.onWelcomeTextChange} />
                                            </div>
                                        </div>
                                        : null}
                                </div>
                                : null}
                            {(role.user_role === 'Admin' || (role.user_role !== 'Admin' && !workspaceSetting.welcome_background_locked)) ?
                                <div className="groupForm mb-0">
                                    {(role.user_role === 'Admin') ?
                                        <label className="btn iconBtn customLockCheckbox">
                                            <input type="checkbox" className="lockInput" onChange={this.handleWelcomeLogoLocked} checked={!workspaceSetting.welcome_background_locked} />
                                            <span className="icon-wrd-unlock"></span>
                                            <span className="icon-wrd-lock"></span>
                                        </label>
                                        : null
                                    }
                                    <label className="sectionTitle">Login Page Welcome Background</label>
                                    <hr />
                                    <p>Choose background to be used for welcome text on login page</p>
                                    <div className="row">
                                        <div className="col-sm-4 col-md-4 col-xl-4 mb-5">
                                            <label className="custom-checkbox custom-pick-image reveal">
                                                <input type="radio" name="lpwb" className="custom-control-input"
                                                    onChange={this.handleWelcomeLogoNoBackgroundChange} checked={(workspaceSetting.welcome_background_selected === 'NoBackground')} />
                                                <div className="imgContainer">
                                                    <img src={process.env.PUBLIC_URL + "/images/no-image-wide.png"} className="themeImg" alt="" />
                                                </div>
                                                <p><span className="icon-wrd-checkmark"></span> No background image</p>
                                            </label>
                                        </div>
                                        <div className="col-sm-4 col-md-4 col-xl-4 mb-5">
                                            <label className="custom-checkbox custom-pick-image">
                                                <input type="radio" name="lpwb" className="custom-control-input"
                                                    onChange={this.handleWelcomeLogoDefaultChange} checked={(workspaceSetting.welcome_background_selected === 'Default')} />
                                                <div className="imgContainer">
                                                    <img src={process.env.PUBLIC_URL + "/images/default-welcome-login-background.png"}
                                                        className="themeImg" alt="" />
                                                </div>
                                                <p><span className="icon-wrd-checkmark"></span> Default portal
                                                    background
                                                </p>
                                            </label>
                                        </div>
                                    </div>
                                    {(workspaceSetting.welcome_background_selected === 'NoBackground') ?
                                        <div className="row">
                                            <div className="col-12 mb-5">
                                                <label>Base background color : </label>&nbsp;
                                            <input type="color" value={workspaceSetting.welcome_background_color} onChange={(e) => this.onSelectColor(e, 'welcome_background')} />
                                            </div>
                                        </div>
                                        : null
                                    }
                                    <div className="row">
                                        <div className="col-sm-4 col-md-4 col-xl-4 mb-5">
                                            <label className="custom-checkbox custom-pick-image">
                                                <input type="radio" name="lpwb" className="custom-control-input"
                                                    onChange={this.handleWelcomeLogoCustomChange} checked={(workspaceSetting.welcome_background_selected === 'Custom')} />
                                                {(workspaceSetting.welcome_background_custom_image !== undefined && workspaceSetting.welcome_background_custom_image !== '') ?
                                                    <div className="imgContainer">
                                                        <img src={"data:image/png;base64," + workspaceSetting.welcome_background_custom_image} className="themeImg" alt="" />
                                                    </div>
                                                    : null
                                                }
                                                <p><span className="icon-wrd-checkmark"></span> or click browse if you want to upload new background</p>
                                                <button className="btn btn-wrd_default icoTextBtn browseLogo">
                                                    <label className="custom-file-upload">
                                                        <input type="file" onClick={this.handleWelcomeLogoCustomChange} onChange={(e) => this.onSelectFile(e, 'welcome_background')} />
                                                        <span className="icon-wrd-folder"></span>
                                                        Browse
                                                        </label></button>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {(role.user_role === 'Admin' || (role.user_role !== 'Admin' && !workspaceSetting.login_background_locked)) ?
                                <div className="groupForm mb-0">
                                    {(role.user_role === 'Admin') ?
                                        <label className="btn iconBtn customLockCheckbox">
                                            <input type="checkbox" className="lockInput" onChange={this.handleLoginLogoLocked} checked={!workspaceSetting.login_background_locked} />
                                            <span className="icon-wrd-unlock"></span>
                                            <span className="icon-wrd-lock"></span>
                                        </label>
                                        : null
                                    }
                                    <label className="sectionTitle">Login Page Background</label>
                                    <hr />
                                    <p>Choose background to be used for login form on login page</p>
                                    <div className="row">
                                        <div className="col-sm-4 col-md-4 col-xl-4 mb-5">
                                            <label className="custom-checkbox custom-pick-image reveal">
                                                <input type="radio" name="lpb" className="custom-control-input"
                                                    onChange={this.handleLoginLogoNoBackgroundChange} checked={(workspaceSetting.login_background_selected === 'NoBackground')} />
                                                <div className="imgContainer">
                                                    <img src={process.env.PUBLIC_URL + "/images/no-image-wide.png"} className="themeImg" alt="" />
                                                </div>
                                                <p><span className="icon-wrd-checkmark"></span> No background image</p>
                                            </label>
                                        </div>
                                        <div className="col-sm-4 col-md-4 col-xl-4 mb-5">
                                            <label className="custom-checkbox custom-pick-image">
                                                <input type="radio" name="lpb" className="custom-control-input"
                                                    onChange={this.handleLoginLogoDefaultChange} checked={(workspaceSetting.login_background_selected === 'Default')} />
                                                <div className="imgContainer">
                                                    <img src={process.env.PUBLIC_URL + "/images/default-login-background.png"} className="themeImg" alt="" />
                                                </div>
                                                <p><span className="icon-wrd-checkmark"></span> Default portal
                                                    background
                                                </p>
                                            </label>
                                        </div>
                                    </div>
                                    {(workspaceSetting.login_background_selected === 'NoBackground') ?
                                        <div className="row">
                                            <div className="col-12 mb-5">
                                                <label>Base background color : </label>&nbsp;
                                            <input type="color" value={workspaceSetting.login_background_color} onChange={(e) => this.onSelectColor(e, 'login_background')} />
                                            </div>
                                        </div>
                                        : null
                                    }
                                    <div className="row">
                                        <div className="col-sm-4 col-md-4 col-xl-4 mb-5">
                                            <label className="custom-checkbox custom-pick-image">
                                                <input type="radio" name="lpb" className="custom-control-input"
                                                    onChange={this.handleLoginLogoCustomChange} checked={(workspaceSetting.login_background_selected === 'Custom')} />
                                                {(workspaceSetting.login_background_custom_image !== undefined && workspaceSetting.login_background_custom_image !== '') ?
                                                    <div className="imgContainer">
                                                        <img src={"data:image/png;base64," + workspaceSetting.login_background_custom_image} className="themeImg" alt="" />
                                                    </div>
                                                    : null
                                                }
                                                <p><span className="icon-wrd-checkmark"></span> or click browse if you want to upload new background</p>
                                                <button className="btn btn-wrd_default icoTextBtn browseLogo">
                                                    <label className="custom-file-upload">
                                                        <input type="file" onClick={this.handleLoginLogoCustomChange} onChange={(e) => this.onSelectFile(e, 'login_background')} />
                                                        <span className="icon-wrd-folder"></span>
                                                        Browse
                                                        </label></button>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-4 col-xl-3">
                        <div className="wsLogo pb-5 text-center">
                            <div className="logoContainer wsHome text-center d-none d-lg-block">
                                <img src={process.env.PUBLIC_URL + "/images/transCircle.png"} className="insideCircle" alt="" />
                                <img src={((workspace.url_image !== undefined) ? "data:image/png;base64," : "") + workspace.url_image}
                                    className={this.getAspectRatioClass()} alt="Logo" />
                            </div>
                            <ul className="setting-menus mt-5 w-75">
                                <strong>Settings Options</strong>
                                <li>
                                    <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/general'}>
                                        <span className="icon-wrd-settings"></span>General
                                    </Link>
                                </li>
                                <li>
                                    <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/login'} className="active">
                                        <span className="icon-wrd-log-in"></span> Login
                                    </Link>
                                </li>
                                <li>
                                    <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/email'}>
                                        <span className="icon-wrd-mail-closed"></span>Email
                                    </Link>
                                </li>
                                <li>
                                    <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/theme'}>
                                        <span className="icon-wrd-themes"></span>Themes
                                    </Link>
                                </li>
                            </ul>
                            <button className="btn textBtn greenBtn w-75 mt-5" onClick={() => this.updateWorkspaceSetting()}>Update Changes</button>
                        </div>
                    </div>
                </div>
                <div className={(this.state.firstOpen) ? "workspaceList d-none" : (this.state.wsListOpened) ? "workspaceList open animated fadeInRight" : "workspaceList open animated fadeOutRight"}>
                    <UserAvailableWorkspacesContainer hideList={this.hideList} />
                </div>
                <button className="btn iconBtn wsListBtn" onClick={() => this.availableWorkspacesSwitch()}>
                    <span className={(this.state.wsListOpened) ? "icon-wrd-close" : "icon-wrd-ws-list"}></span>
                </button>
                {src &&
                    <Modal
                        isOpen={imageEditorIsOpen}
                        style={modalImageEditorStyle}
                        contentLabel="Image Editor">
                        <ImageEditor srcImage={src} onClose={this.onCloseImageEditor} imgType={'potrait'} />
                    </Modal>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, role }) => ({
    workspaceSetting: detail.workspace_login_setting,
    workspace: detail.workspace,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchWorkspaceSetting: (workspaceSetting) => dispatch(updateWorkspaceLoginSettingAction(workspaceSetting)),
    updateWorkspaceSetting: (workspaceSetting) => dispatch(updateWorkspaceLoginSettingWithThunk(workspaceSetting)),
    getUserAvailableWorkspaces: (userId, userRole, pageIndex, pageSize) => dispatch(getUserAvailableWorkspacesWithThunk(userId, userRole, pageIndex, pageSize))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesLoginSettingsBody));