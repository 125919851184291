import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { updatePortalSettingNewsAction } from '../../../../actions/updatePortalSettingNewsAction';
import { updatePortalSettingNewsWithThunk, addPortalSettingNewsWithThunk } from '../../../../api/portalSettingsThunk';
import { updateDialogPortalsAction } from '../../../../actions/updateDialogPortalsAction';
import ImageEditor from '../../../Editor/ImageEditorWithId';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../../../configurations/routes';
import { modalImageEditorStyle } from '../../../../utils/modal';
import RichTextEditor from '../../../Editor/RichTextEditor';
import Modal from 'react-modal';
import { updateDialogPortalSettingsErrorAction } from '../../../../actions/updateDialogPortalSettingsErrorAction';

class PortalSettingsNewsItem extends Component {
    constructor(props) {
        super(props);
        this.onSettingsTitleChange = this.onSettingsTitleChange.bind(this);
        this.onSettingsDescriptionChange = this.onSettingsDescriptionChange.bind(this);
        this.onSelectFile = this.onSelectFile.bind(this);
        this.onCloseImageEditor = this.onCloseImageEditor.bind(this);
        this.updatePortalSettingNewsChanges = this.updatePortalSettingNewsChanges.bind(this);
        this.displayAction = this.displayAction.bind(this);
        this.hideAction = this.hideAction.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.removePortalSettingNews = this.removePortalSettingNews.bind(this);
        this.createPortalSettingNews = this.createPortalSettingNews.bind(this);
        this.cancelPortalSettingNews = this.cancelPortalSettingNews.bind(this);
        this.uploadImg = this.uploadImg.bind(this);

        this.state = {
            src: null,
            imageEditorIsOpen: false,
            stickyActionIsOpen: false
        }
    }

    static propTypes = {
        item: PropTypes.object.isRequired,
        settingNews: PropTypes.object.isRequired
    };

    onSettingsTitleChange(e) {
        const { item, settingNews } = this.props;
        let news = settingNews.news;
        let index = news.findIndex(news => news.id === item.id);
        news[index].news_title = e.target.value;

        let newsSetting = {
            ...settingNews,
            news
        };

        this.props.dispatchUpdateSettingNews(newsSetting);
    }

    uploadImg(data, insertImage) {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
            insertImage(reader.result, image => {
                if (image.width() <= 400) {
                    image.css('width', image.width());
                } else {
                    image.css('width', '100%');
                }
            });

            const { item, settingNews } = this.props;
            let news = settingNews.news;
            let index = news.findIndex(news => news.id === item.id);
            news[index].news_description = reader.result;;
    
            let newsSetting = {
                ...settingNews,
                news
            };
    
            this.props.dispatchUpdateSettingNews(newsSetting);
        }

    }

    onSettingsDescriptionChange(data) {
        const { item, settingNews } = this.props;
        let news = settingNews.news;
        let index = news.findIndex(news => news.id === item.id);
        news[index].news_description = data;

        let newsSetting = {
            ...settingNews,
            news
        };

        this.props.dispatchUpdateSettingNews(newsSetting);
    }

    onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({
                    src: reader.result, imageEditorIsOpen: true
                })
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onCloseImageEditor(image, aspectRatio, id) {
        this.setState({ imageEditorIsOpen: false });

        const { settingNews } = this.props;
        let news = settingNews.news;
        let index = news.findIndex(news => news.id === id);
        news[index].image = image;
        news[index].image_aspect_ratio = aspectRatio;

        let newsSetting = {
            ...settingNews,
            news
        };

        this.props.dispatchUpdateSettingNews(newsSetting);
    }

    cancelPortalSettingNews() {
        const { item, settingNews } = this.props;
        let news = settingNews.news;
        let index = news.findIndex(news => news.id === item.id);
        news.splice(index, 1);

        let newsSetting = {
            ...settingNews,
            news
        };

        this.props.dispatchUpdateSettingNews(newsSetting);
    }

    createPortalSettingNews() {
        const { item, settingNews } = this.props;
        let index = settingNews.news.findIndex(news => news.id === item.id);
        if (!settingNews.news[index].news_title) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'News title is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        } else if (!settingNews.news[index].news_description) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'News description is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        }
        else if (!settingNews.news[index].image_aspect_ratio) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'News image is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        }
        this.props.addSettingNews(settingNews, index);
    }

    updatePortalSettingNewsChanges() {
        const { item, settingNews } = this.props;
        let index = settingNews.news.findIndex(news => news.id === item.id);
        if (!settingNews.news[index].news_title) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'News title is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        } else if (!settingNews.news[index].news_description) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'News description is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        }
        else if (!settingNews.news[index].image_aspect_ratio) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'News image is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        }
        this.props.updateSettingNews(settingNews, index);
    }

    displayAction() {
        this.setState({ stickyActionIsOpen: !this.state.stickyActionIsOpen });
    }

    hideAction() {
        this.setState({ stickyActionIsOpen: false });
    }

    removePortalSettingNews() {
        const { item } = this.props;

        let portals = {
            id: item.id,
            name: item.news_title,
            status: item.status,
            dialog_title: 'Warning',
            dialog_type: 'trash',
            dialog_context: 'news',
            show_dialog: true
        };

        this.props.dispatchDialogPortals(portals);

        this.setState({ stickyActionIsOpen: !this.state.stickyActionIsOpen });
    }

    updateStatus() {
        const { item } = this.props;

        let portals = {
            id: item.id,
            name: item.news_title,
            status: item.status,
            dialog_title: 'Warning',
            dialog_type: (item.status === 'Enabled') ? 'disable' : 'enable',
            dialog_context: 'news',
            show_dialog: (item.status === 'Enabled') ? true : false
        };

        this.props.dispatchDialogPortals(portals);

        if (item.status === 'Disabled') {
            const { settingNews } = this.props;
            let news = settingNews.news;
            let index = news.findIndex(news => news.id === item.id);
            news[index].status = 'Enabled';

            let newsSetting = {
                ...settingNews,
                news
            };

            this.props.updateSettingNews(newsSetting, index);
        }

        this.setState({ stickyActionIsOpen: !this.state.stickyActionIsOpen });
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    render() {
        const { item } = this.props;
        const { src, imageEditorIsOpen, stickyActionIsOpen } = this.state;
        return (
            <div className="sectionBanner p-4 mb-5">
                <div className="row">
                    <div className="col-xl-5">
                        <div className="containerBanner p-4 text-center">
                            <img src={((item.image !== undefined && item.image !== "") ? "data:image/jpeg;base64," : process.env.PUBLIC_URL + "/images/no-banner.png") + item.image} className="bannerDisplay" alt="" />
                            <span className={(item.status === "Disabled") ? "float-left text-red mt-4 pt-2" : "float-left text-lime mt-4 pt-2"}>
                                {(item.status === "Disabled") ? "Disabled" : "Enabled"}</span>
                            <label className="btn btn-wrd_default icoTextBtn browsePhoto m-0 mt-3">
                                <input type="file" onChange={this.onSelectFile} />
                                <span className="icon-wrd-folder"></span>
                                Browse </label>
                        </div>
                    </div>
                    <div className="col-xl-7">
                        <div className="formWrap mt-5">
                            <div className="inputForm">
                                <input className="form-control" type="text" name=""
                                    defaultValue={item.news_title} onChange={this.onSettingsTitleChange} required />
                                <label>Title</label>
                            </div>
                            <div className="inputForm">
                                <div className="summernote">
                                    <RichTextEditor value={item.news_description}   onImgUpload={this.uploadImg}
                                    onChange={this.onSettingsDescriptionChange} disabled={false} />
                                </div>
                            </div>
                            {(item.status !== undefined && item.status !== "") ?
                                <div className="d-block text-center">
                                    <button className="btn textBtn greenBtn updateBtn" onClick={this.updatePortalSettingNewsChanges}>Update Changes</button>
                                </div>
                                :
                                <div className="row text-center">
                                    <div className="col-md-6 col-lg-6 order-md-2">
                                        <button className="btn textBtn greenBtn w-75" onClick={this.createPortalSettingNews}>Create News</button>
                                    </div>
                                    <div className="col-md-6 col-lg-6 order-md-1">
                                        <button className="btn textBtn greyBtn w-75" onClick={this.cancelPortalSettingNews}> Cancel</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {(item.status !== undefined && item.status !== "") ?
                    <button onClick={this.displayAction} onBlur={() => this.hideAction()} className="btn moreBtn"><span className="icon-wrd-more"></span></button>
                    : null
                }

                <ul className={(stickyActionIsOpen) ? "action show-action" : "action"}>
                    <li><Link to={routeCodes.PORTAL_SETTINGS} onMouseDown={this.updateStatus} className={(item.status === "Disabled") ? "greenLink" : "redLink"}><span
                        className="icon-wrd-lock"></span>{(item.status === "Disabled") ? "Enable" : "Disable"}</Link></li>
                    <li><Link to={routeCodes.PORTAL_SETTINGS} onMouseDown={this.removePortalSettingNews} className="redLink"><span
                        className="icon-wrd-trash"></span>Remove</Link></li>
                </ul>

                {src &&
                    <Modal
                        isOpen={imageEditorIsOpen}
                        style={modalImageEditorStyle}
                        contentLabel="Image Editor">
                        <ImageEditor srcImage={src} onClose={this.onCloseImageEditor} idEditor={item.id} imgType={"other"} />
                    </Modal>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ portalSettings }) => ({
    settingNews: portalSettings.setting_news
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogPortals: (dialog) => dispatch(updateDialogPortalsAction(dialog)),
    dispatchUpdateSettingNews: (settingNews) => dispatch(updatePortalSettingNewsAction(settingNews)),
    updateSettingNews: (settingNews, index) => dispatch(updatePortalSettingNewsWithThunk(settingNews, index)),
    addSettingNews: (settingNews, index) => dispatch(addPortalSettingNewsWithThunk(settingNews, index)),
    dispatchDialogPortalSettingsError: (dialog) => dispatch(updateDialogPortalSettingsErrorAction(dialog)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalSettingsNewsItem));